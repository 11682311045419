import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_wave_creation_grid_ComponentContextService } from './FootprintManager.wave_creation_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_wave_creation_gridComponentEntity {
  Id?: number, AvailableDate?: string, Cartonized?: boolean, CreatedSysDateTime?: string, DateNeeded?: string, DatePromised?: string, DeliveredDate?: string, DockDate?: string, ExpectedDate?: string, ExpectedWarehouseId?: number, LoadingStartDate?: string, LookupCode?: string, WaveId?: number, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, CarrierServiceType?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Id?: number, Name?: string }, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }[], ShipmentLines?: { Id?: number, StatusId?: number }[], ExpectedWarehouse?: { Id?: number, Name?: string }}

interface IFootprintManager_wave_creation_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseIds?: number[], waveId?: number, showCreate?: boolean, carrierId?: number, carrierServiceId?: number}


class FootprintManager_wave_creation_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_wave_creation_gridComponent;
  entity: IFootprintManager_wave_creation_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    order_lookup: null,
    lookupcode: null,
    create_date: null,
    Carrier_Name: null,
    CarrierServiceType_Name: null,
    date_needed: null,
    order_owner_reference: null,
    vendor_reference: null,
    warehouse: null,
  }



  constructor(
    grid: FootprintManager_wave_creation_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_wave_creation_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.order_lookup = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.order_lookup,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.create_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.create_date,
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      );
    
    this.cells.Carrier_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Carrier_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.CarrierServiceType_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.CarrierServiceType_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.date_needed = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.date_needed,
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      );
    
    this.cells.order_owner_reference = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.order_owner_reference,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendor_reference = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendor_reference,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouse = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouse,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_wave_creation_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_wave_creation_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouseId:  $grid.inParams.warehouseIds ,
      projectId:  $grid.inParams.projectId ,
      ownerId:  $grid.inParams.ownerId ,
      fullTextSearch:  $grid.fullTextSearch ,
      carrierId:  $grid.inParams.carrierId ,
      carrierServiceId:  $grid.inParams.carrierServiceId ,
    };
    const data = await this.$datasources.Waves.ds_get_orders_ready_to_wave.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_wave_creation_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.order_lookup.displayControl as TextModel).text = $row.entity.OrderLookups[0]?.Order?.LookupCode.toString();;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.create_date.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.Carrier_Name.displayControl as TextModel).text = $row.entity.Carrier?.Name;
    (this.cells.CarrierServiceType_Name.displayControl as TextModel).text = $row.entity.CarrierServiceType?.Name;
    (this.cells.date_needed.displayControl as TextModel).text = $row.entity.DateNeeded;
    (this.cells.order_owner_reference.displayControl as TextModel).text = $row.entity.OrderLookups[0]?.Order?.OwnerReference;
    (this.cells.vendor_reference.displayControl as TextModel).text = $row.entity.OrderLookups[0]?.Order?.VendorReference;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.ExpectedWarehouse.Name;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'order_lookup' : {
        this.on_order_code_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_order_code_clicked(event = null) {
    return this.on_order_code_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_code_clickedInternal(
    $row: FootprintManager_wave_creation_gridComponentRowModel,
  $grid: FootprintManager_wave_creation_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_wave_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Check order class type
  const order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $row.entity.OrderLookups[0].OrderId })).result;
  if ($utils.isDefined(order)) {
      const orderClassTypeId = order.OrderClass.OrderClassTypeId;
      if (orderClassTypeId == 5) {
          $shell.FootprintManager.opensales_order_editor({ orderId: order.Id });
      }
      else {
          throw new Error('Editor for selected order class type under development!')
      }
  }
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-wave_creation_grid',
  templateUrl: './FootprintManager.wave_creation_grid.component.html'
})
export class FootprintManager_wave_creation_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_wave_creation_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_wave_creation_gridComponentInParams = { ownerId: null, projectId: null, warehouseIds: [], waveId: null, showCreate: null, carrierId: null, carrierServiceId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     order_lookup: new GridHeaderModel(new HeaderStyles(null, null), 'order_lookup', 'Order', false, false, null, false),       lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Shipment', false, false, null, false),       create_date: new GridHeaderModel(new HeaderStyles(null, null), 'create_date', 'Create date', false, false, null, false),       Carrier_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier_Name', 'Carrier name', false, false, null, false),       CarrierServiceType_Name: new GridHeaderModel(new HeaderStyles(null, null), 'CarrierServiceType_Name', 'Carrier service name', false, false, null, false),       date_needed: new GridHeaderModel(new HeaderStyles(null, null), 'date_needed', 'Date needed', false, false, null, false),       order_owner_reference: new GridHeaderModel(new HeaderStyles(null, null), 'order_owner_reference', 'Order reference', false, false, null, false),       vendor_reference: new GridHeaderModel(new HeaderStyles(null, null), 'vendor_reference', 'Vendor reference', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_wave_creation_gridComponentRowModel[] = [];

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('waveId') set $inParams_waveId(value: any) {
    this.inParams['waveId'] = value;
  }
  get $inParams_waveId(): any {
    return this.inParams['waveId'] ;
  }
  @Input('showCreate') set $inParams_showCreate(value: any) {
    this.inParams['showCreate'] = value;
  }
  get $inParams_showCreate(): any {
    return this.inParams['showCreate'] ;
  }
  @Input('carrierId') set $inParams_carrierId(value: any) {
    this.inParams['carrierId'] = value;
  }
  get $inParams_carrierId(): any {
    return this.inParams['carrierId'] ;
  }
  @Input('carrierServiceId') set $inParams_carrierServiceId(value: any) {
    this.inParams['carrierServiceId'] = value;
  }
  get $inParams_carrierServiceId(): any {
    return this.inParams['carrierServiceId'] ;
  }

  topToolbar = {
      create_wave: new ToolModel(new ButtonModel('create_wave', new ButtonStyles(null, null), false, false, false, 'Group into wave', 'icon-datex-Wave', null)
    , false),
      assign_to_wave: new ToolModel(new ButtonModel('assign_to_wave', new ButtonStyles(null, null), false, false, false, 'Assign to wave', 'ms-Icon ms-Icon--AddTo', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_wave_creation_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Create wave';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 500;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseId:  $grid.inParams.warehouseIds ,
      projectId:  $grid.inParams.projectId ,
      ownerId:  $grid.inParams.ownerId ,
      fullTextSearch:  $grid.fullTextSearch ,
      carrierId:  $grid.inParams.carrierId ,
      carrierServiceId:  $grid.inParams.carrierServiceId ,
    };
    try {
      const data = await this.$datasources.Waves.ds_get_orders_ready_to_wave.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_wave_creation_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_wave_creation_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_wave_creation_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_clicked(event = null) {
    return this.on_create_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_clickedInternal(
    $grid: FootprintManager_wave_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_wave_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var shipmentIds = [];
  
  for (let row of $grid.selectedRows) {
      shipmentIds.push(row.entity.Id)
  }
  
  if ($utils.isDefined(shipmentIds)) {
      const wavecreated = (await $flows.Waves.create_wave_flow({ shipmentIds: shipmentIds }))
      $grid.refresh();
      if ($utils.isDefined(wavecreated.reason)) {
          throw Error(wavecreated.reason)
      } else {
          await $shell.FootprintManager.openInfoDialog('Success.', `Wave ${wavecreated.WaveId} has been created successfully.`);
      }
  }
  
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_wave_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_wave_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'wave_creation_grid.on_grid_data_loaded');
  for (let row of $grid.rows) {
      row.cells.order_lookup.displayControl.text = row.entity.OrderLookups[0]?.Order?.LookupCode
      row.cells.vendor_reference.displayControl.text = row.entity.OrderLookups[0]?.Order?.VendorReference
      row.cells.order_owner_reference.displayControl.text = row.entity.OrderLookups[0]?.Order?.OwnerReference
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_wave_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_wave_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  if ($grid.inParams.showCreate) {
      $grid.topToolbar.assign_to_wave.hidden = true;
      $grid.topToolbar.create_wave.hidden = false;
  } else {
      $grid.topToolbar.assign_to_wave.hidden = false;
      $grid.topToolbar.create_wave.hidden = true;
  }
  }
  on_assign_to_wave_clicked(event = null) {
    return this.on_assign_to_wave_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assign_to_wave_clickedInternal(
    $grid: FootprintManager_wave_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_wave_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var shipments: number[] = [];
  var waveId: number = $grid.inParams.waveId; 
  
  for(let shipment of $grid.selectedRows){
      shipments.push(shipment.entity.Id)
  }
  
  let result = (await $flows.Waves.associate_order_to_wave_flow({waveId: waveId,shipmentIds: shipments})).reason;
  
  if($utils.isDefined(result)){
      $shell.Waves.openErrorDialog('Error assigning order to wave', result);
  } else {
      $shell.Waves.openInfoDialog(`Orders addded to wave ${waveId}`, `${shipments.join(` ,`)} have been successfully added to the wave`);
      $grid.refresh();
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_wave_creation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_wave_creation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if($grid.selectedRows.length > 0){
      $grid.topToolbar.create_wave.control.readOnly = false;
  } else {
      $grid.topToolbar.create_wave.control.readOnly = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
