import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_invoicing_instructionsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { search?: string, billingContractId?: number, projectId?: number, active?: boolean, excludeRuleIds?: string[], ruleId?: string, $top?: number, $skip?: number }): Promise<{ result?: { instructionId?: string, billingContractId?: number, projectId?: number, active?: boolean, instructionType?: string, instructionName?: string, billingAggregationStrategyIds?: number[], oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, termId?: number, taxScheduleId?: number, createdOn?: string, createdBy?: string, modifiedOn?: string, modifiedBy?: string, useExistingInvoice?: boolean, priority?: number, isTemplateRule?: boolean, priorityId?: string, billingCodeIds?: number[], schedule?: { date_option?: string, date_interval?: string[], time_option?: string, time_interval?: string, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } }, cronExpression?: string, schedule_name?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_invoicing_instructions/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { search?: string, billingContractId?: number, projectId?: number, active?: boolean, excludeRuleIds?: string[], ruleId?: string, $keys: string[] }): Promise<{ result?: { instructionId?: string, billingContractId?: number, projectId?: number, active?: boolean, instructionType?: string, instructionName?: string, billingAggregationStrategyIds?: number[], oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, termId?: number, taxScheduleId?: number, createdOn?: string, createdBy?: string, modifiedOn?: string, modifiedBy?: string, useExistingInvoice?: boolean, priority?: number, isTemplateRule?: boolean, priorityId?: string, billingCodeIds?: number[], schedule?: { date_option?: string, date_interval?: string[], time_option?: string, time_interval?: string, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } }, cronExpression?: string, schedule_name?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_invoicing_instructions/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      search?: string, billingContractId?: number, projectId?: number, active?: boolean, excludeRuleIds?: string[], ruleId?: string    }) {
      return false;
    }
}
