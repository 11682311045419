import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Materials_ds_allocation_strategy_ddService } from './Materials.datasource.index';
import { Materials_ds_allocation_strategy_workflows_ddService } from './Materials.datasource.index';
import { Materials_ds_check_licenseplatecontents_by_material_idsService } from './Materials.datasource.index';
import { Materials_ds_check_orderlines_by_material_idsService } from './Materials.datasource.index';
import { Materials_ds_check_tasks_by_material_idsService } from './Materials.datasource.index';
import { Materials_ds_get_allocation_strategy_by_nameService } from './Materials.datasource.index';
import { Materials_ds_get_allocation_strategy_workflowsService } from './Materials.datasource.index';
import { Materials_ds_get_components_by_material_id_packaging_idService } from './Materials.datasource.index';
import { Materials_ds_get_kit_typesService } from './Materials.datasource.index';
import { Materials_ds_get_licenseplatecontents_by_materialId_top1Service } from './Materials.datasource.index';
import { Materials_ds_get_lots_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_aliasesService } from './Materials.datasource.index';
import { Materials_ds_get_material_by_lookupcode_and_projectIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_details_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_group_by_materialGroupIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_group_by_nameService } from './Materials.datasource.index';
import { Materials_ds_get_material_gtin_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_inventory_measurements_by_shortnameService } from './Materials.datasource.index';
import { Materials_ds_get_material_inventory_measurements_by_unitIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_packaging_by_materialId_and_packagingIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_packagings_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_packagings_details_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_material_status_by_nameService } from './Materials.datasource.index';
import { Materials_ds_get_materials_basepackaging_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_materials_by_gtinService } from './Materials.datasource.index';
import { Materials_ds_get_materials_by_lookupcodeService } from './Materials.datasource.index';
import { Materials_ds_get_materials_by_materialIdsService } from './Materials.datasource.index';
import { Materials_ds_get_materials_by_ownerIdService } from './Materials.datasource.index';
import { Materials_ds_get_materials_by_project_idService } from './Materials.datasource.index';
import { Materials_ds_get_materials_defaultpackaging_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_measurement_unit_by_shortnameService } from './Materials.datasource.index';
import { Materials_ds_get_non_primarypick_totals_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_orderlines_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_orderlines_by_materialId_top1Service } from './Materials.datasource.index';
import { Materials_ds_get_packagings_by_material_lookupcode_packaging_names_projectIdService } from './Materials.datasource.index';
import { Materials_ds_get_packagings_by_materials_projects_packagingsService } from './Materials.datasource.index';
import { Materials_ds_get_primarypick_totals_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_project_by_projectIdService } from './Materials.datasource.index';
import { Materials_ds_get_status_by_statusIdService } from './Materials.datasource.index';
import { Materials_ds_get_substitutions_by_material_id_packaging_idService } from './Materials.datasource.index';
import { Materials_ds_get_tasks_by_materialId_top1Service } from './Materials.datasource.index';
import { Materials_ds_get_vendorlot_by_lookupcode_and_materialIdService } from './Materials.datasource.index';
import { Materials_ds_get_vendorlots_by_materialIdService } from './Materials.datasource.index';
import { Materials_ds_inventory_measurement_units_gridService } from './Materials.datasource.index';
import { Materials_ds_material_editorService } from './Materials.datasource.index';
import { Materials_ds_material_groups_ddService } from './Materials.datasource.index';
import { Materials_ds_material_packagings_by_project_gridService } from './Materials.datasource.index';
import { Materials_ds_material_packagings_ddService } from './Materials.datasource.index';
import { Materials_ds_material_packagings_gridService } from './Materials.datasource.index';
import { Materials_ds_material_statuses_ddService } from './Materials.datasource.index';
import { Materials_ds_materials_by_base_packaging_gridService } from './Materials.datasource.index';
import { Materials_ds_materials_by_count_widgetService } from './Materials.datasource.index';
import { Materials_ds_materials_by_project_ddService } from './Materials.datasource.index';
import { Materials_ds_materials_by_project_excl_material_ddService } from './Materials.datasource.index';
import { Materials_ds_materials_by_project_multi_ddService } from './Materials.datasource.index';
import { Materials_ds_materials_navigational_gridService } from './Materials.datasource.index';
import { Materials_ds_measurement_units_ddService } from './Materials.datasource.index';
import { Materials_ds_owners_ddService } from './Materials.datasource.index';
import { Materials_ds_packages_excl_materials_ddService } from './Materials.datasource.index';
import { Materials_ds_projects_ddService } from './Materials.datasource.index';
import { Materials_ds_storage_category_rules_ddService } from './Materials.datasource.index';

@Injectable({ providedIn: 'root' })
export class Materials_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Materials: Materials_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_allocation_strategy_dd: Materials_ds_allocation_strategy_ddService;
  public get ds_allocation_strategy_dd(): Materials_ds_allocation_strategy_ddService {
    if(!this._ds_allocation_strategy_dd) {
      this._ds_allocation_strategy_dd = this.injector.get(Materials_ds_allocation_strategy_ddService);
    }
    return this._ds_allocation_strategy_dd;
  }
  private _ds_allocation_strategy_workflows_dd: Materials_ds_allocation_strategy_workflows_ddService;
  public get ds_allocation_strategy_workflows_dd(): Materials_ds_allocation_strategy_workflows_ddService {
    if(!this._ds_allocation_strategy_workflows_dd) {
      this._ds_allocation_strategy_workflows_dd = this.injector.get(Materials_ds_allocation_strategy_workflows_ddService);
    }
    return this._ds_allocation_strategy_workflows_dd;
  }
  private _ds_check_licenseplatecontents_by_material_ids: Materials_ds_check_licenseplatecontents_by_material_idsService;
  public get ds_check_licenseplatecontents_by_material_ids(): Materials_ds_check_licenseplatecontents_by_material_idsService {
    if(!this._ds_check_licenseplatecontents_by_material_ids) {
      this._ds_check_licenseplatecontents_by_material_ids = this.injector.get(Materials_ds_check_licenseplatecontents_by_material_idsService);
    }
    return this._ds_check_licenseplatecontents_by_material_ids;
  }
  private _ds_check_orderlines_by_material_ids: Materials_ds_check_orderlines_by_material_idsService;
  public get ds_check_orderlines_by_material_ids(): Materials_ds_check_orderlines_by_material_idsService {
    if(!this._ds_check_orderlines_by_material_ids) {
      this._ds_check_orderlines_by_material_ids = this.injector.get(Materials_ds_check_orderlines_by_material_idsService);
    }
    return this._ds_check_orderlines_by_material_ids;
  }
  private _ds_check_tasks_by_material_ids: Materials_ds_check_tasks_by_material_idsService;
  public get ds_check_tasks_by_material_ids(): Materials_ds_check_tasks_by_material_idsService {
    if(!this._ds_check_tasks_by_material_ids) {
      this._ds_check_tasks_by_material_ids = this.injector.get(Materials_ds_check_tasks_by_material_idsService);
    }
    return this._ds_check_tasks_by_material_ids;
  }
  private _ds_get_allocation_strategy_by_name: Materials_ds_get_allocation_strategy_by_nameService;
  public get ds_get_allocation_strategy_by_name(): Materials_ds_get_allocation_strategy_by_nameService {
    if(!this._ds_get_allocation_strategy_by_name) {
      this._ds_get_allocation_strategy_by_name = this.injector.get(Materials_ds_get_allocation_strategy_by_nameService);
    }
    return this._ds_get_allocation_strategy_by_name;
  }
  private _ds_get_allocation_strategy_workflows: Materials_ds_get_allocation_strategy_workflowsService;
  public get ds_get_allocation_strategy_workflows(): Materials_ds_get_allocation_strategy_workflowsService {
    if(!this._ds_get_allocation_strategy_workflows) {
      this._ds_get_allocation_strategy_workflows = this.injector.get(Materials_ds_get_allocation_strategy_workflowsService);
    }
    return this._ds_get_allocation_strategy_workflows;
  }
  private _ds_get_components_by_material_id_packaging_id: Materials_ds_get_components_by_material_id_packaging_idService;
  public get ds_get_components_by_material_id_packaging_id(): Materials_ds_get_components_by_material_id_packaging_idService {
    if(!this._ds_get_components_by_material_id_packaging_id) {
      this._ds_get_components_by_material_id_packaging_id = this.injector.get(Materials_ds_get_components_by_material_id_packaging_idService);
    }
    return this._ds_get_components_by_material_id_packaging_id;
  }
  private _ds_get_kit_types: Materials_ds_get_kit_typesService;
  public get ds_get_kit_types(): Materials_ds_get_kit_typesService {
    if(!this._ds_get_kit_types) {
      this._ds_get_kit_types = this.injector.get(Materials_ds_get_kit_typesService);
    }
    return this._ds_get_kit_types;
  }
  private _ds_get_licenseplatecontents_by_materialId_top1: Materials_ds_get_licenseplatecontents_by_materialId_top1Service;
  public get ds_get_licenseplatecontents_by_materialId_top1(): Materials_ds_get_licenseplatecontents_by_materialId_top1Service {
    if(!this._ds_get_licenseplatecontents_by_materialId_top1) {
      this._ds_get_licenseplatecontents_by_materialId_top1 = this.injector.get(Materials_ds_get_licenseplatecontents_by_materialId_top1Service);
    }
    return this._ds_get_licenseplatecontents_by_materialId_top1;
  }
  private _ds_get_lots_by_materialId: Materials_ds_get_lots_by_materialIdService;
  public get ds_get_lots_by_materialId(): Materials_ds_get_lots_by_materialIdService {
    if(!this._ds_get_lots_by_materialId) {
      this._ds_get_lots_by_materialId = this.injector.get(Materials_ds_get_lots_by_materialIdService);
    }
    return this._ds_get_lots_by_materialId;
  }
  private _ds_get_material_aliases: Materials_ds_get_material_aliasesService;
  public get ds_get_material_aliases(): Materials_ds_get_material_aliasesService {
    if(!this._ds_get_material_aliases) {
      this._ds_get_material_aliases = this.injector.get(Materials_ds_get_material_aliasesService);
    }
    return this._ds_get_material_aliases;
  }
  private _ds_get_material_by_lookupcode_and_projectId: Materials_ds_get_material_by_lookupcode_and_projectIdService;
  public get ds_get_material_by_lookupcode_and_projectId(): Materials_ds_get_material_by_lookupcode_and_projectIdService {
    if(!this._ds_get_material_by_lookupcode_and_projectId) {
      this._ds_get_material_by_lookupcode_and_projectId = this.injector.get(Materials_ds_get_material_by_lookupcode_and_projectIdService);
    }
    return this._ds_get_material_by_lookupcode_and_projectId;
  }
  private _ds_get_material_by_materialId: Materials_ds_get_material_by_materialIdService;
  public get ds_get_material_by_materialId(): Materials_ds_get_material_by_materialIdService {
    if(!this._ds_get_material_by_materialId) {
      this._ds_get_material_by_materialId = this.injector.get(Materials_ds_get_material_by_materialIdService);
    }
    return this._ds_get_material_by_materialId;
  }
  private _ds_get_material_details_by_materialId: Materials_ds_get_material_details_by_materialIdService;
  public get ds_get_material_details_by_materialId(): Materials_ds_get_material_details_by_materialIdService {
    if(!this._ds_get_material_details_by_materialId) {
      this._ds_get_material_details_by_materialId = this.injector.get(Materials_ds_get_material_details_by_materialIdService);
    }
    return this._ds_get_material_details_by_materialId;
  }
  private _ds_get_material_group_by_materialGroupId: Materials_ds_get_material_group_by_materialGroupIdService;
  public get ds_get_material_group_by_materialGroupId(): Materials_ds_get_material_group_by_materialGroupIdService {
    if(!this._ds_get_material_group_by_materialGroupId) {
      this._ds_get_material_group_by_materialGroupId = this.injector.get(Materials_ds_get_material_group_by_materialGroupIdService);
    }
    return this._ds_get_material_group_by_materialGroupId;
  }
  private _ds_get_material_group_by_name: Materials_ds_get_material_group_by_nameService;
  public get ds_get_material_group_by_name(): Materials_ds_get_material_group_by_nameService {
    if(!this._ds_get_material_group_by_name) {
      this._ds_get_material_group_by_name = this.injector.get(Materials_ds_get_material_group_by_nameService);
    }
    return this._ds_get_material_group_by_name;
  }
  private _ds_get_material_gtin_by_materialId: Materials_ds_get_material_gtin_by_materialIdService;
  public get ds_get_material_gtin_by_materialId(): Materials_ds_get_material_gtin_by_materialIdService {
    if(!this._ds_get_material_gtin_by_materialId) {
      this._ds_get_material_gtin_by_materialId = this.injector.get(Materials_ds_get_material_gtin_by_materialIdService);
    }
    return this._ds_get_material_gtin_by_materialId;
  }
  private _ds_get_material_inventory_measurements_by_shortname: Materials_ds_get_material_inventory_measurements_by_shortnameService;
  public get ds_get_material_inventory_measurements_by_shortname(): Materials_ds_get_material_inventory_measurements_by_shortnameService {
    if(!this._ds_get_material_inventory_measurements_by_shortname) {
      this._ds_get_material_inventory_measurements_by_shortname = this.injector.get(Materials_ds_get_material_inventory_measurements_by_shortnameService);
    }
    return this._ds_get_material_inventory_measurements_by_shortname;
  }
  private _ds_get_material_inventory_measurements_by_unitId: Materials_ds_get_material_inventory_measurements_by_unitIdService;
  public get ds_get_material_inventory_measurements_by_unitId(): Materials_ds_get_material_inventory_measurements_by_unitIdService {
    if(!this._ds_get_material_inventory_measurements_by_unitId) {
      this._ds_get_material_inventory_measurements_by_unitId = this.injector.get(Materials_ds_get_material_inventory_measurements_by_unitIdService);
    }
    return this._ds_get_material_inventory_measurements_by_unitId;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: Materials_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): Materials_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(Materials_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_material_packagings_by_materialId: Materials_ds_get_material_packagings_by_materialIdService;
  public get ds_get_material_packagings_by_materialId(): Materials_ds_get_material_packagings_by_materialIdService {
    if(!this._ds_get_material_packagings_by_materialId) {
      this._ds_get_material_packagings_by_materialId = this.injector.get(Materials_ds_get_material_packagings_by_materialIdService);
    }
    return this._ds_get_material_packagings_by_materialId;
  }
  private _ds_get_material_packagings_details_by_materialId: Materials_ds_get_material_packagings_details_by_materialIdService;
  public get ds_get_material_packagings_details_by_materialId(): Materials_ds_get_material_packagings_details_by_materialIdService {
    if(!this._ds_get_material_packagings_details_by_materialId) {
      this._ds_get_material_packagings_details_by_materialId = this.injector.get(Materials_ds_get_material_packagings_details_by_materialIdService);
    }
    return this._ds_get_material_packagings_details_by_materialId;
  }
  private _ds_get_material_status_by_name: Materials_ds_get_material_status_by_nameService;
  public get ds_get_material_status_by_name(): Materials_ds_get_material_status_by_nameService {
    if(!this._ds_get_material_status_by_name) {
      this._ds_get_material_status_by_name = this.injector.get(Materials_ds_get_material_status_by_nameService);
    }
    return this._ds_get_material_status_by_name;
  }
  private _ds_get_materials_basepackaging_by_materialId: Materials_ds_get_materials_basepackaging_by_materialIdService;
  public get ds_get_materials_basepackaging_by_materialId(): Materials_ds_get_materials_basepackaging_by_materialIdService {
    if(!this._ds_get_materials_basepackaging_by_materialId) {
      this._ds_get_materials_basepackaging_by_materialId = this.injector.get(Materials_ds_get_materials_basepackaging_by_materialIdService);
    }
    return this._ds_get_materials_basepackaging_by_materialId;
  }
  private _ds_get_materials_by_gtin: Materials_ds_get_materials_by_gtinService;
  public get ds_get_materials_by_gtin(): Materials_ds_get_materials_by_gtinService {
    if(!this._ds_get_materials_by_gtin) {
      this._ds_get_materials_by_gtin = this.injector.get(Materials_ds_get_materials_by_gtinService);
    }
    return this._ds_get_materials_by_gtin;
  }
  private _ds_get_materials_by_lookupcode: Materials_ds_get_materials_by_lookupcodeService;
  public get ds_get_materials_by_lookupcode(): Materials_ds_get_materials_by_lookupcodeService {
    if(!this._ds_get_materials_by_lookupcode) {
      this._ds_get_materials_by_lookupcode = this.injector.get(Materials_ds_get_materials_by_lookupcodeService);
    }
    return this._ds_get_materials_by_lookupcode;
  }
  private _ds_get_materials_by_materialIds: Materials_ds_get_materials_by_materialIdsService;
  public get ds_get_materials_by_materialIds(): Materials_ds_get_materials_by_materialIdsService {
    if(!this._ds_get_materials_by_materialIds) {
      this._ds_get_materials_by_materialIds = this.injector.get(Materials_ds_get_materials_by_materialIdsService);
    }
    return this._ds_get_materials_by_materialIds;
  }
  private _ds_get_materials_by_ownerId: Materials_ds_get_materials_by_ownerIdService;
  public get ds_get_materials_by_ownerId(): Materials_ds_get_materials_by_ownerIdService {
    if(!this._ds_get_materials_by_ownerId) {
      this._ds_get_materials_by_ownerId = this.injector.get(Materials_ds_get_materials_by_ownerIdService);
    }
    return this._ds_get_materials_by_ownerId;
  }
  private _ds_get_materials_by_project_id: Materials_ds_get_materials_by_project_idService;
  public get ds_get_materials_by_project_id(): Materials_ds_get_materials_by_project_idService {
    if(!this._ds_get_materials_by_project_id) {
      this._ds_get_materials_by_project_id = this.injector.get(Materials_ds_get_materials_by_project_idService);
    }
    return this._ds_get_materials_by_project_id;
  }
  private _ds_get_materials_defaultpackaging_by_materialId: Materials_ds_get_materials_defaultpackaging_by_materialIdService;
  public get ds_get_materials_defaultpackaging_by_materialId(): Materials_ds_get_materials_defaultpackaging_by_materialIdService {
    if(!this._ds_get_materials_defaultpackaging_by_materialId) {
      this._ds_get_materials_defaultpackaging_by_materialId = this.injector.get(Materials_ds_get_materials_defaultpackaging_by_materialIdService);
    }
    return this._ds_get_materials_defaultpackaging_by_materialId;
  }
  private _ds_get_measurement_unit_by_shortname: Materials_ds_get_measurement_unit_by_shortnameService;
  public get ds_get_measurement_unit_by_shortname(): Materials_ds_get_measurement_unit_by_shortnameService {
    if(!this._ds_get_measurement_unit_by_shortname) {
      this._ds_get_measurement_unit_by_shortname = this.injector.get(Materials_ds_get_measurement_unit_by_shortnameService);
    }
    return this._ds_get_measurement_unit_by_shortname;
  }
  private _ds_get_non_primarypick_totals_by_materialId: Materials_ds_get_non_primarypick_totals_by_materialIdService;
  public get ds_get_non_primarypick_totals_by_materialId(): Materials_ds_get_non_primarypick_totals_by_materialIdService {
    if(!this._ds_get_non_primarypick_totals_by_materialId) {
      this._ds_get_non_primarypick_totals_by_materialId = this.injector.get(Materials_ds_get_non_primarypick_totals_by_materialIdService);
    }
    return this._ds_get_non_primarypick_totals_by_materialId;
  }
  private _ds_get_orderlines_by_materialId: Materials_ds_get_orderlines_by_materialIdService;
  public get ds_get_orderlines_by_materialId(): Materials_ds_get_orderlines_by_materialIdService {
    if(!this._ds_get_orderlines_by_materialId) {
      this._ds_get_orderlines_by_materialId = this.injector.get(Materials_ds_get_orderlines_by_materialIdService);
    }
    return this._ds_get_orderlines_by_materialId;
  }
  private _ds_get_orderlines_by_materialId_top1: Materials_ds_get_orderlines_by_materialId_top1Service;
  public get ds_get_orderlines_by_materialId_top1(): Materials_ds_get_orderlines_by_materialId_top1Service {
    if(!this._ds_get_orderlines_by_materialId_top1) {
      this._ds_get_orderlines_by_materialId_top1 = this.injector.get(Materials_ds_get_orderlines_by_materialId_top1Service);
    }
    return this._ds_get_orderlines_by_materialId_top1;
  }
  private _ds_get_packagings_by_material_lookupcode_packaging_names_projectId: Materials_ds_get_packagings_by_material_lookupcode_packaging_names_projectIdService;
  public get ds_get_packagings_by_material_lookupcode_packaging_names_projectId(): Materials_ds_get_packagings_by_material_lookupcode_packaging_names_projectIdService {
    if(!this._ds_get_packagings_by_material_lookupcode_packaging_names_projectId) {
      this._ds_get_packagings_by_material_lookupcode_packaging_names_projectId = this.injector.get(Materials_ds_get_packagings_by_material_lookupcode_packaging_names_projectIdService);
    }
    return this._ds_get_packagings_by_material_lookupcode_packaging_names_projectId;
  }
  private _ds_get_packagings_by_materials_projects_packagings: Materials_ds_get_packagings_by_materials_projects_packagingsService;
  public get ds_get_packagings_by_materials_projects_packagings(): Materials_ds_get_packagings_by_materials_projects_packagingsService {
    if(!this._ds_get_packagings_by_materials_projects_packagings) {
      this._ds_get_packagings_by_materials_projects_packagings = this.injector.get(Materials_ds_get_packagings_by_materials_projects_packagingsService);
    }
    return this._ds_get_packagings_by_materials_projects_packagings;
  }
  private _ds_get_primarypick_totals_by_materialId: Materials_ds_get_primarypick_totals_by_materialIdService;
  public get ds_get_primarypick_totals_by_materialId(): Materials_ds_get_primarypick_totals_by_materialIdService {
    if(!this._ds_get_primarypick_totals_by_materialId) {
      this._ds_get_primarypick_totals_by_materialId = this.injector.get(Materials_ds_get_primarypick_totals_by_materialIdService);
    }
    return this._ds_get_primarypick_totals_by_materialId;
  }
  private _ds_get_project_by_projectId: Materials_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): Materials_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(Materials_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_status_by_statusId: Materials_ds_get_status_by_statusIdService;
  public get ds_get_status_by_statusId(): Materials_ds_get_status_by_statusIdService {
    if(!this._ds_get_status_by_statusId) {
      this._ds_get_status_by_statusId = this.injector.get(Materials_ds_get_status_by_statusIdService);
    }
    return this._ds_get_status_by_statusId;
  }
  private _ds_get_substitutions_by_material_id_packaging_id: Materials_ds_get_substitutions_by_material_id_packaging_idService;
  public get ds_get_substitutions_by_material_id_packaging_id(): Materials_ds_get_substitutions_by_material_id_packaging_idService {
    if(!this._ds_get_substitutions_by_material_id_packaging_id) {
      this._ds_get_substitutions_by_material_id_packaging_id = this.injector.get(Materials_ds_get_substitutions_by_material_id_packaging_idService);
    }
    return this._ds_get_substitutions_by_material_id_packaging_id;
  }
  private _ds_get_tasks_by_materialId_top1: Materials_ds_get_tasks_by_materialId_top1Service;
  public get ds_get_tasks_by_materialId_top1(): Materials_ds_get_tasks_by_materialId_top1Service {
    if(!this._ds_get_tasks_by_materialId_top1) {
      this._ds_get_tasks_by_materialId_top1 = this.injector.get(Materials_ds_get_tasks_by_materialId_top1Service);
    }
    return this._ds_get_tasks_by_materialId_top1;
  }
  private _ds_get_vendorlot_by_lookupcode_and_materialId: Materials_ds_get_vendorlot_by_lookupcode_and_materialIdService;
  public get ds_get_vendorlot_by_lookupcode_and_materialId(): Materials_ds_get_vendorlot_by_lookupcode_and_materialIdService {
    if(!this._ds_get_vendorlot_by_lookupcode_and_materialId) {
      this._ds_get_vendorlot_by_lookupcode_and_materialId = this.injector.get(Materials_ds_get_vendorlot_by_lookupcode_and_materialIdService);
    }
    return this._ds_get_vendorlot_by_lookupcode_and_materialId;
  }
  private _ds_get_vendorlots_by_materialId: Materials_ds_get_vendorlots_by_materialIdService;
  public get ds_get_vendorlots_by_materialId(): Materials_ds_get_vendorlots_by_materialIdService {
    if(!this._ds_get_vendorlots_by_materialId) {
      this._ds_get_vendorlots_by_materialId = this.injector.get(Materials_ds_get_vendorlots_by_materialIdService);
    }
    return this._ds_get_vendorlots_by_materialId;
  }
  private _ds_inventory_measurement_units_grid: Materials_ds_inventory_measurement_units_gridService;
  public get ds_inventory_measurement_units_grid(): Materials_ds_inventory_measurement_units_gridService {
    if(!this._ds_inventory_measurement_units_grid) {
      this._ds_inventory_measurement_units_grid = this.injector.get(Materials_ds_inventory_measurement_units_gridService);
    }
    return this._ds_inventory_measurement_units_grid;
  }
  private _ds_material_editor: Materials_ds_material_editorService;
  public get ds_material_editor(): Materials_ds_material_editorService {
    if(!this._ds_material_editor) {
      this._ds_material_editor = this.injector.get(Materials_ds_material_editorService);
    }
    return this._ds_material_editor;
  }
  private _ds_material_groups_dd: Materials_ds_material_groups_ddService;
  public get ds_material_groups_dd(): Materials_ds_material_groups_ddService {
    if(!this._ds_material_groups_dd) {
      this._ds_material_groups_dd = this.injector.get(Materials_ds_material_groups_ddService);
    }
    return this._ds_material_groups_dd;
  }
  private _ds_material_packagings_by_project_grid: Materials_ds_material_packagings_by_project_gridService;
  public get ds_material_packagings_by_project_grid(): Materials_ds_material_packagings_by_project_gridService {
    if(!this._ds_material_packagings_by_project_grid) {
      this._ds_material_packagings_by_project_grid = this.injector.get(Materials_ds_material_packagings_by_project_gridService);
    }
    return this._ds_material_packagings_by_project_grid;
  }
  private _ds_material_packagings_dd: Materials_ds_material_packagings_ddService;
  public get ds_material_packagings_dd(): Materials_ds_material_packagings_ddService {
    if(!this._ds_material_packagings_dd) {
      this._ds_material_packagings_dd = this.injector.get(Materials_ds_material_packagings_ddService);
    }
    return this._ds_material_packagings_dd;
  }
  private _ds_material_packagings_grid: Materials_ds_material_packagings_gridService;
  public get ds_material_packagings_grid(): Materials_ds_material_packagings_gridService {
    if(!this._ds_material_packagings_grid) {
      this._ds_material_packagings_grid = this.injector.get(Materials_ds_material_packagings_gridService);
    }
    return this._ds_material_packagings_grid;
  }
  private _ds_material_statuses_dd: Materials_ds_material_statuses_ddService;
  public get ds_material_statuses_dd(): Materials_ds_material_statuses_ddService {
    if(!this._ds_material_statuses_dd) {
      this._ds_material_statuses_dd = this.injector.get(Materials_ds_material_statuses_ddService);
    }
    return this._ds_material_statuses_dd;
  }
  private _ds_materials_by_base_packaging_grid: Materials_ds_materials_by_base_packaging_gridService;
  public get ds_materials_by_base_packaging_grid(): Materials_ds_materials_by_base_packaging_gridService {
    if(!this._ds_materials_by_base_packaging_grid) {
      this._ds_materials_by_base_packaging_grid = this.injector.get(Materials_ds_materials_by_base_packaging_gridService);
    }
    return this._ds_materials_by_base_packaging_grid;
  }
  private _ds_materials_by_count_widget: Materials_ds_materials_by_count_widgetService;
  public get ds_materials_by_count_widget(): Materials_ds_materials_by_count_widgetService {
    if(!this._ds_materials_by_count_widget) {
      this._ds_materials_by_count_widget = this.injector.get(Materials_ds_materials_by_count_widgetService);
    }
    return this._ds_materials_by_count_widget;
  }
  private _ds_materials_by_project_dd: Materials_ds_materials_by_project_ddService;
  public get ds_materials_by_project_dd(): Materials_ds_materials_by_project_ddService {
    if(!this._ds_materials_by_project_dd) {
      this._ds_materials_by_project_dd = this.injector.get(Materials_ds_materials_by_project_ddService);
    }
    return this._ds_materials_by_project_dd;
  }
  private _ds_materials_by_project_excl_material_dd: Materials_ds_materials_by_project_excl_material_ddService;
  public get ds_materials_by_project_excl_material_dd(): Materials_ds_materials_by_project_excl_material_ddService {
    if(!this._ds_materials_by_project_excl_material_dd) {
      this._ds_materials_by_project_excl_material_dd = this.injector.get(Materials_ds_materials_by_project_excl_material_ddService);
    }
    return this._ds_materials_by_project_excl_material_dd;
  }
  private _ds_materials_by_project_multi_dd: Materials_ds_materials_by_project_multi_ddService;
  public get ds_materials_by_project_multi_dd(): Materials_ds_materials_by_project_multi_ddService {
    if(!this._ds_materials_by_project_multi_dd) {
      this._ds_materials_by_project_multi_dd = this.injector.get(Materials_ds_materials_by_project_multi_ddService);
    }
    return this._ds_materials_by_project_multi_dd;
  }
  private _ds_materials_navigational_grid: Materials_ds_materials_navigational_gridService;
  public get ds_materials_navigational_grid(): Materials_ds_materials_navigational_gridService {
    if(!this._ds_materials_navigational_grid) {
      this._ds_materials_navigational_grid = this.injector.get(Materials_ds_materials_navigational_gridService);
    }
    return this._ds_materials_navigational_grid;
  }
  private _ds_measurement_units_dd: Materials_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): Materials_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(Materials_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_owners_dd: Materials_ds_owners_ddService;
  public get ds_owners_dd(): Materials_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(Materials_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_packages_excl_materials_dd: Materials_ds_packages_excl_materials_ddService;
  public get ds_packages_excl_materials_dd(): Materials_ds_packages_excl_materials_ddService {
    if(!this._ds_packages_excl_materials_dd) {
      this._ds_packages_excl_materials_dd = this.injector.get(Materials_ds_packages_excl_materials_ddService);
    }
    return this._ds_packages_excl_materials_dd;
  }
  private _ds_projects_dd: Materials_ds_projects_ddService;
  public get ds_projects_dd(): Materials_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Materials_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_storage_category_rules_dd: Materials_ds_storage_category_rules_ddService;
  public get ds_storage_category_rules_dd(): Materials_ds_storage_category_rules_ddService {
    if(!this._ds_storage_category_rules_dd) {
      this._ds_storage_category_rules_dd = this.injector.get(Materials_ds_storage_category_rules_ddService);
    }
    return this._ds_storage_category_rules_dd;
  }
}

