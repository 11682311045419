<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="line_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.line_number.displayControl.styles.style"
          [ngClass]="row.cells.line_number.displayControl.styles.classes"
          [matTooltip]="row.cells.line_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.line_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="component">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.component.displayControl.styles.style"
          [ngClass]="row.cells.component.displayControl.styles.classes"
          [matTooltip]="row.cells.component.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.component.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="consumed_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.consumed_quantity.displayControl.styles.style"
          [ngClass]="row.cells.consumed_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.consumed_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.consumed_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="quantity_to_return">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.quantity_to_return.displayControl.styles.style"
          [ngClass]="row.cells.quantity_to_return.displayControl.styles.classes"
          [matTooltip]="row.cells.quantity_to_return.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.quantity_to_return.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['quantity_to_return_edit']"
            matInput
            numberBox
            [format]="row.cells.quantity_to_return.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.quantity_to_return.editControl.readOnly || row.cells.quantity_to_return.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.quantity_to_return.editControl.placeholder}}"
            [ngStyle]="row.cells.quantity_to_return.editControl.styles.style"
            [ngClass]="row.cells.quantity_to_return.editControl.styles.classes"
            [matTooltip]="row.cells.quantity_to_return.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="return_to_lp">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.return_to_lp.displayControl.styles.style"
          [ngClass]="row.cells.return_to_lp.displayControl.styles.classes"
          [matTooltip]="row.cells.return_to_lp.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.return_to_lp.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-licenseplates_by_location_single 
        data-cy="selector"
        [type]="row.cells.return_to_lp.editControl.type"
        [formControl]="row.formGroup.controls['return_to_lp_edit']"
        (displayTextChange)="row.cells.return_to_lp.editControl.displayText=$event"
        [placeholder]="row.cells.return_to_lp.editControl.placeholder"
        [styles]="row.cells.return_to_lp.editControl.styles"
        [tooltip]="row.cells.return_to_lp.editControl.tooltip"
      [location_id]="row.$fields_return_to_lp_selector_inParams_location_id"
    >
    </Manufacturing-licenseplates_by_location_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="return_to_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.return_to_location.displayControl.styles.style"
          [ngClass]="row.cells.return_to_location.displayControl.styles.classes"
          [matTooltip]="row.cells.return_to_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.return_to_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <FootprintManager-manufacturing_staging_locations_dd_single 
        data-cy="selector"
        [type]="row.cells.return_to_location.editControl.type"
        [formControl]="row.formGroup.controls['return_to_location_edit']"
        (displayTextChange)="row.cells.return_to_location.editControl.displayText=$event"
        [placeholder]="row.cells.return_to_location.editControl.placeholder"
        [styles]="row.cells.return_to_location.editControl.styles"
        [tooltip]="row.cells.return_to_location.editControl.tooltip"
      [warehouse_id]="row.$fields_return_to_location_selector_inParams_warehouse_id"
      [project_id]="row.$fields_return_to_location_selector_inParams_project_id"
    >
    </FootprintManager-manufacturing_staging_locations_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-lots_by_material_ids_single 
        data-cy="selector"
        [type]="row.cells.lot.editControl.type"
        [formControl]="row.formGroup.controls['lot_edit']"
        (displayTextChange)="row.cells.lot.editControl.displayText=$event"
        [placeholder]="row.cells.lot.editControl.placeholder"
        [styles]="row.cells.lot.editControl.styles"
        [tooltip]="row.cells.lot.editControl.tooltip"
      [material_ids]="row.$fields_lot_selector_inParams_material_ids"
    >
    </Manufacturing-lots_by_material_ids_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
