import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_equipment_grid_ComponentContextService } from './FootprintManager.manufacturing_equipment_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_warehouses_dd_singleComponent } from './Manufacturing.warehouses_dd_single.component'


interface IFootprintManager_manufacturing_equipment_gridComponentEntity {
  Id?: number, Code?: string, Name?: string, Type?: string, WarehouseId?: number}

interface IFootprintManager_manufacturing_equipment_gridComponentInParams {
  warehouse: number}


class FootprintManager_manufacturing_equipment_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_equipment_gridComponent;
  entity: IFootprintManager_manufacturing_equipment_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    warehouse_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    name: null,
    type: null,
    warehouse: null,
  }

  cacheValueFor_$fields_warehouse_selector_inParams_warehouse_ids: number[];
  get $fields_warehouse_selector_inParams_warehouse_ids(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = [$grid.inParams.warehouse];
    
    if(!isEqual(this.cacheValueFor_$fields_warehouse_selector_inParams_warehouse_ids, expr)) {
      this.cacheValueFor_$fields_warehouse_selector_inParams_warehouse_ids = expr;
    }
    return this.cacheValueFor_$fields_warehouse_selector_inParams_warehouse_ids;
  }


  constructor(
    grid: FootprintManager_manufacturing_equipment_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.name,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['name_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.type = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.type,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['type_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.warehouse = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouse,
      new SelectBoxModel(
  this.formGroup.controls['warehouse_display'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
,
      new SelectBoxModel(
        this.formGroup.controls['warehouse_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_equipment_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_equipment_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_row_init();
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouse_id:  $grid.inParams.warehouse ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_equipment_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_equipment_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.name.displayControl as TextModel).text = $row.entity.Name;
    (this.cells.name.editControl as TextBoxModel).reset($row.entity.Name);
    (this.cells.type.displayControl as TextModel).text = $row.entity.Type;
    (this.cells.type.editControl as TextBoxModel).reset($row.entity.Type);
    (this.cells.warehouse.displayControl as SelectBoxModel).reset($row.entity.WarehouseId);
    (this.cells.warehouse.editControl as SelectBoxModel).reset($row.entity.WarehouseId);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_manufacturing_equipment_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_equipment_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_equipment_grid.on_save_existing_row');
  try {
  
      const manufacturingEquipmentUpdate = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingEquipments",
      id: $row.entity.Id,
      entity:
      {
          Name: $row.cells.name.editControl.value
      }}));
  
      await $row.refresh();
  } catch (error) {
      $shell.Utilities.showErrorDetails('Save', 'Error on save.', error);
      throw error;
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_manufacturing_equipment_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_equipment_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_equipment_grid.on_row_data_loaded');
  if( $utils.isDefined($row.entity.Type) )
      $row.cells.type.editControl.readOnly = true;
  else
      $row.cells.type.editControl.readOnly = false;
  
  if( $utils.isDefined($row.entity.WarehouseId) )
      $row.cells.warehouse.editControl.readOnly = true;
  else
      $row.cells.warehouse.editControl.readOnly = false;
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_manufacturing_equipment_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_equipment_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_equipment_grid.on_save_new_row');
  if (!$utils.isDefined($row.cells.name.editControl.value )) {
      $shell.Utilities.openErrorDialog('Save', 'Name field must be filled.');
      throw new Error('Name field must be filled.');
  }
  
  if (!$utils.isDefined($row.cells.name.editControl.value))
  {
      $shell.Utilities.openErrorDialog('Save', 'Type field must be filled.');
      throw new Error('Type field must be filled.');
  }
  
  try {
  
      const manufacturingEquipmentAdd = (await $flows.Utilities.crud_create_flow({
      entitySet: "ManufacturingEquipments",
      entity:
      {
          Name: $row.cells.name.editControl.value,
          Type: $row.cells.type.editControl.value,
          WarehouseId: $row.cells.warehouse.editControl.value,
          Code: ''
      }}));
  
      if( $utils.isDefined(manufacturingEquipmentAdd.reason) )
      {
          $shell.Utilities.showErrorDetails('Erore creating equipment', 'Error on save.', manufacturingEquipmentAdd.reason);
          throw new Error(`Error creating equipment - ${manufacturingEquipmentAdd.reason}`);
      }
  
      $row.cells.type.editControl.readOnly = true;
      $row.cells.warehouse.editControl.readOnly = true;
      $row.cells.warehouse.displayControl.readOnly = true;
  
      await $grid.refresh();
  
  } catch (error) {
      
      throw error; // to prevent displayMode 
  }
  }
  on_row_init(event = null) {
    return this.on_row_initInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_initInternal(
    $row: FootprintManager_manufacturing_equipment_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_equipment_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_equipment_grid.on_row_init');
  $row.entity = {
      Name: '',
      Type: null,
      WarehouseId: null
  };
  
  $row.cells.type.setEditMode();
  $row.cells.warehouse.setEditMode();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_warehouses_dd_singleComponent),
  ],
  selector: 'FootprintManager-manufacturing_equipment_grid',
  templateUrl: './FootprintManager.manufacturing_equipment_grid.component.html'
})
export class FootprintManager_manufacturing_equipment_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_equipment_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_manufacturing_equipment_gridComponentInParams = { warehouse: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     name: new GridHeaderModel(new HeaderStyles(null, null), 'name', 'Name', true, false, null, false),       type: new GridHeaderModel(new HeaderStyles(null, null), 'type', 'Type', true, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_equipment_gridComponentRowModel[] = [];

  @Input('warehouse') set $inParams_warehouse(value: any) {
    this.inParams['warehouse'] = value;
  }
  get $inParams_warehouse(): any {
    return this.inParams['warehouse'] ;
  }

  topToolbar = {
      delete_equipment: new ToolModel(new ButtonModel('delete_equipment', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Equipment';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouse)) {
        this.$missingRequiredInParams.push('warehouse');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouse_id:  $grid.inParams.warehouse ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_equipment_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_equipment_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_equipment_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_manufacturing_equipment_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintManager_manufacturing_equipment_gridComponentEntity) {
    const row = new FootprintManager_manufacturing_equipment_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_click(event = null) {
    return this.on_delete_clickInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickInternal(
    $grid: FootprintManager_manufacturing_equipment_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_equipment_grid.on_delete_click');
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Utilities.openErrorDialog('Equipment Delete Error', 'No equipment selected.');
      return;
  }
  
  const candidates = [];
  for (const row of selectedRows) {
      candidates.push(row);
  }
  
  const confirmCandidates = `${candidates.map(c => c.entity.Name.toString()).join(',')}`
  
  const title = `Delete equipment${candidates.length > 1 ? 's' : ''} ${confirmCandidates} ?`;
  const message = `Once deleted, the equipment${candidates.length > 1 ? 's' : ''} can not be restored`;
  let confirmDeletion = await $shell.Utilities.openConfirmationDialog(title, message, `Delete equipment${candidates.length > 1 ? 's' : ''}`);
  
  if (confirmDeletion) {
      const processSuccess = [];
      const processFailures = [];
      const errorMsgList = [];
      const errorMsgListDetails = [];
  
      for (const candidate of candidates) {
          try {
              const flowParams = {
                  equipment_id: candidate.entity.Id
              }
              const result = await $flows.Manufacturing.delete_manufacturing_equipment_flow(flowParams);
              const reason = result.reason;
              if ($utils.isDefined(reason)) {
                  processFailures.push(candidate);
                  errorMsgList.push(`Equipment ${candidate.entity.Name} - ${reason}`);
              } else {
                  processSuccess.push(candidate);
              }
  
          } catch (error) {
              processFailures.push(candidate);
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Equipment ${candidate.entity.Name.toString()} - ${errorMessage}`;
              errorMsgList.push(errorDescription);
              errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
          }
      }
  
      // all succeeded
      if (processSuccess.length === candidates.length) {
          const title = 'All equipment(s) deleted';
          const message = `Equipment(s) ${processSuccess.map(c => c.entity.Name.toString()).join(',')} deleted`;
          $shell.Utilities.openToaster(title, message, EToasterType.Success, {timeOut: 5000, positionClass: EToasterPosition.bottomRight, progressBar: false, tapToDismiss: true });
          await $grid.refresh();
      } else {
          // all failures
          if (processFailures.length === candidates.length) {
              const title = 'All equipment(s) failed to delete';
              const message = `Equipment(s) ${processFailures.map(c => c.entity.Name.toString()).join(',')} could not be deleted`;
              await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
          } else {
              const title = 'Some equipments could not be deleted';
              const success = `Equipment(s) ${processSuccess.map(c => c.entity.Name.toString()).join(',')} were deleted.`;
              const errors = `The following equipment(s) could not be deleted: ${processFailures.map(c => c.entity.Name.toString()).join(',')}`;
              const message = `${success} \r\n\r\n${errors}`;
              await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
              await $grid.refresh();
          }
      }
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_manufacturing_equipment_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_equipment_grid.on_grid_data_loaded');
  $grid.bottomToolbar.addLine.hidden = false;
  $grid.canEdit = true;
  $grid.topToolbar.delete_equipment.control.readOnly = false;
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_manufacturing_equipment_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_equipment_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_equipment_grid.on_row_selected');
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected and order status is created
  // TODO: Add authorization check
  if (selectedRowsCount > 0 /*&& !(await $operations.FootPrintManager.Disable_SalesOrder_Editing.isAuthorized())*/) {
      $grid.topToolbar.delete_equipment.control.readOnly = false;
  }
  else {
      $grid.topToolbar.delete_equipment.control.readOnly = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
