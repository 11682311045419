import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_lines_components_for_process_grid_ComponentContextService } from './FootprintManager.manufacturing_order_lines_components_for_process_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentEntity {
  Material?: { Id?: number, Name?: string }, FinishedProduct?: { Id?: number, Name?: string }, PlannedQuantity?: number, Packaging?: string, OrderLineId?: number, IsNormalWaveRelease?: boolean, IsSufficient?: boolean, Availability?: string, SourceWarehouses?: string[], TotalInventory?: number}

interface IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentInParams {
  order_id: number, warehouse_id: number}


class FootprintManager_manufacturing_order_lines_components_for_process_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_order_lines_components_for_process_gridComponent;
  entity: IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Material_Name: null,
    FinishedProduct_Name: null,
    PlannedQuantity: null,
    IsNormalWaveRelease: null,
    MaterialSource: null,
    Availability: null,
    SourceWarehouses: null,
  }



  constructor(
    grid: FootprintManager_manufacturing_order_lines_components_for_process_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_order_lines_components_for_process_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.Material_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Material_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.FinishedProduct_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.FinishedProduct_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.PlannedQuantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.PlannedQuantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.IsNormalWaveRelease = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.IsNormalWaveRelease,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.MaterialSource = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.MaterialSource,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Availability = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Availability,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.SourceWarehouses = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.SourceWarehouses,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.OrderLineId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.OrderLineId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.OrderLineId;
    const inParams = {
      $keys:[$resultKey],
      order_id:  $grid.inParams.order_id ,
      manufacturing_warehouse_id:  $grid.inParams.warehouse_id ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_components_for_process_order.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.Material_Name.displayControl as TextModel).text = $row.entity.Material?.Name;
    (this.cells.FinishedProduct_Name.displayControl as TextModel).text = $row.entity.FinishedProduct?.Name;
    (this.cells.PlannedQuantity.displayControl as TextModel).text = $row.entity.PlannedQuantity?.toString() + ' ' + $row.entity.Packaging?.toString();
    (this.cells.IsNormalWaveRelease.displayControl as TextModel).text = $row.entity.IsNormalWaveRelease ? "Normal" : "In stages";
    (this.cells.MaterialSource.displayControl as TextModel).text = !$row.entity.IsSufficient ? 'Combined' : $row.entity.Availability.includes('locally') ? "On-site" : "Off-site";
    (this.cells.SourceWarehouses.displayControl as TextModel).text = $row.entity.SourceWarehouses.join(', ');

    await this.on_row_init();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_init(event = null) {
    return this.on_row_initInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_initInternal(
    $row: FootprintManager_manufacturing_order_lines_components_for_process_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_components_for_process_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_components_for_process_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_components_for_process_grid.on_row_init');
  
  const entity = $row.entity;
  
  const availability = entity.IsSufficient
      ? `Sufficient ${entity.Availability.includes('partially') ? ',' : ''}${entity.Availability}`
      : 'Insufficient';
  
  if (entity.IsSufficient) {
      if (availability.includes('locally')) {
          $row.cells.Availability.styles.setStyle('color', 'rgb(65, 193, 23)');
          $row.cells.Availability.displayControl.text = `\u2713 ${availability}`;
      } else if (availability.includes('partially')) {
          $row.cells.Availability.displayControl.text = `\u2713 ${availability}`;
      } else {
          $row.cells.Availability.styles.setStyle('color', 'rgb(224, 134, 0)');
          $row.cells.Availability.displayControl.text = `\u26A0 ${availability}`;
      }
  } else {
      $row.cells.Availability.styles.setStyle('color', 'rgb(217, 0, 0)');
      $row.cells.Availability.displayControl.text = "\u26A0 Insufficient";
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-manufacturing_order_lines_components_for_process_grid',
  templateUrl: './FootprintManager.manufacturing_order_lines_components_for_process_grid.component.html'
})
export class FootprintManager_manufacturing_order_lines_components_for_process_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);


  inParams: IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentInParams = { order_id: null, warehouse_id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Material_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Material_Name', 'Component', false, false, null, false),       FinishedProduct_Name: new GridHeaderModel(new HeaderStyles(null, null), 'FinishedProduct_Name', 'Finished product', false, false, null, false),       PlannedQuantity: new GridHeaderModel(new HeaderStyles(null, null), 'PlannedQuantity', 'Planned quantity', false, false, null, false),       IsNormalWaveRelease: new GridHeaderModel(new HeaderStyles(null, null), 'IsNormalWaveRelease', 'Wave release', false, false, null, false),       MaterialSource: new GridHeaderModel(new HeaderStyles(null, null), 'MaterialSource', 'Material source', false, false, null, false),       Availability: new GridHeaderModel(new HeaderStyles(null, null), 'Availability', 'Availability', false, false, null, false),       SourceWarehouses: new GridHeaderModel(new HeaderStyles(null, null), 'SourceWarehouses', 'Source warehouse', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_order_lines_components_for_process_gridComponentRowModel[] = [];

  @Input('order_id') set $inParams_order_id(value: any) {
    this.inParams['order_id'] = value;
  }
  get $inParams_order_id(): any {
    return this.inParams['order_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    enable_optimizer: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  filters = {
    enable_optimizer: new FieldModel(new CheckBoxModel(this.formGroup.controls['enable_optimizer'] as DatexFormControl, null, false, false, 'Enable material optimizer', null)
, new ControlContainerStyles(null, null), '', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams


  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_order_lines_components_for_process_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Components to be processed';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;

    (this.filters.enable_optimizer.control as CheckBoxModel).reset(false);

    await this.on_enable_optimizer_click();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['enable_optimizer']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_enable_optimizer_click();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      order_id:  $grid.inParams.order_id ,
      manufacturing_warehouse_id:  $grid.inParams.warehouse_id ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_components_for_process_order.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_order_lines_components_for_process_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_order_lines_components_for_process_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_manufacturing_order_lines_components_for_process_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_enable_optimizer_click(event = null) {
    return this.on_enable_optimizer_clickInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_enable_optimizer_clickInternal(
    $grid: FootprintManager_manufacturing_order_lines_components_for_process_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_components_for_process_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_components_for_process_grid.on_enable_optimizer_click');
  
  const enabled = $grid.filters.enable_optimizer.control.value;
  
  $grid.headers.MaterialSource.hidden = !enabled;
  $grid.headers.Availability.hidden = !enabled;
  $grid.headers.SourceWarehouses.hidden = !enabled;
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
