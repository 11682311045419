<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="delete_intermediary" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_intermediary($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="Sequence">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Sequence.displayControl.styles.style"
          [ngClass]="row.cells.Sequence.displayControl.styles.classes"
          [matTooltip]="row.cells.Sequence.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Sequence.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Owner.displayControl.styles.style"
          [ngClass]="row.cells.Owner.displayControl.styles.classes"
          [matTooltip]="row.cells.Owner.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-owners_dd_single 
        data-cy="selector"
        [type]="row.cells.Owner.editControl.type"
        [formControl]="row.formGroup.controls['Owner_edit']"
        (displayTextChange)="row.cells.Owner.editControl.displayText=$event"
        [placeholder]="row.cells.Owner.editControl.placeholder"
        [styles]="row.cells.Owner.editControl.styles"
        [tooltip]="row.cells.Owner.editControl.tooltip"
      [statusId]="row.$fields_Owner_selector_inParams_statusId"
      [projectId]="row.$fields_Owner_selector_inParams_projectId"
    >
    </Inventory-owners_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Project.displayControl.styles.style"
          [ngClass]="row.cells.Project.displayControl.styles.classes"
          [matTooltip]="row.cells.Project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Owners-projects_dd_single 
        data-cy="selector"
        [type]="row.cells.Project.editControl.type"
        [formControl]="row.formGroup.controls['Project_edit']"
        (displayTextChange)="row.cells.Project.editControl.displayText=$event"
        [placeholder]="row.cells.Project.editControl.placeholder"
        [styles]="row.cells.Project.editControl.styles"
        [tooltip]="row.cells.Project.editControl.tooltip"
      [statusId]="row.$fields_Project_selector_inParams_statusId"
      [ownerId]="row.$fields_Project_selector_inParams_ownerId"
      [excludedProjectIds]="row.$fields_Project_selector_inParams_excludedProjectIds"
    >
    </Owners-projects_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Account">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Account.displayControl.styles.style"
          [ngClass]="row.cells.Account.displayControl.styles.classes"
          [matTooltip]="row.cells.Account.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Account.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <InventoryTransfers-accounts_dd_single 
        data-cy="selector"
        [type]="row.cells.Account.editControl.type"
        [formControl]="row.formGroup.controls['Account_edit']"
        (displayTextChange)="row.cells.Account.editControl.displayText=$event"
        [placeholder]="row.cells.Account.editControl.placeholder"
        [styles]="row.cells.Account.editControl.styles"
        [tooltip]="row.cells.Account.editControl.tooltip"
    >
    </InventoryTransfers-accounts_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
