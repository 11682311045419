import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_ds_get_linenumber_by_line_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { InvoiceId?: number, LineNumber?: number, $top?: number, $skip?: number }): Promise<{ result?: { LineNumber?: number, InvoiceId?: number, Name?: string, Quantity?: number, UnitCost?: number, UnitPrice?: number, LineCost?: number, LinePrice?: number, LineTotal?: number, Description?: string, Notes?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_get_linenumber_by_line_flow/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { InvoiceId?: number, LineNumber?: number, $keys: number[] }): Promise<{ result?: { LineNumber?: number, InvoiceId?: number, Name?: string, Quantity?: number, UnitCost?: number, UnitPrice?: number, LineCost?: number, LinePrice?: number, LineTotal?: number, Description?: string, Notes?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_get_linenumber_by_line_flow/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      InvoiceId?: number, LineNumber?: number    }) {
      return false;
    }
}
