import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_asn_orderlines_grid_ComponentContextService } from './FootprintManager.asn_orderlines_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_asn_orderlines_gridComponentEntity {
  OrderId?: number, LineNumber?: number, Color?: string, EstablishmentIdentifier?: string, Marks?: string, MaterialAlias?: string, PackagedAmount?: number, PurchaseOrderIdentifier?: string, ResidueHoldIdentifier?: string, Size?: string, Style?: string, Material?: { IsFixedWeight?: boolean, LookupCode?: string }, VendorLot?: { LookupCode?: string }, Lot?: { LookupCode?: string }, MaterialPackagingLookup?: { PackagingId?: number, Packaging?: { ShortName?: string } }, Status?: { Name?: string }, Tasks?: { SerialNumberId?: number, SerialNumber?: { Id?: number, GrossWeight?: number, LookupCode?: string, NetWeight?: number, WeightUomId?: number } }[]}

interface IFootprintManager_asn_orderlines_gridComponentInParams {
  orderId: number}


class FootprintManager_asn_orderlines_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_asn_orderlines_gridComponent;
  entity: IFootprintManager_asn_orderlines_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    purchase_order_identifier_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    residue_hold_identifier_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    establishment_identifier_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    material_alias_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    marks_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    size_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    color_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    style_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    linenumber: null,
    material: null,
    vendorlot: null,
    lot: null,
    packaging: null,
    packaged_amount: null,
    purchase_order_identifier: null,
    residue_hold_identifier: null,
    establishment_identifier: null,
    material_alias: null,
    marks: null,
    size: null,
    color: null,
    style: null,
  }



  constructor(
    grid: FootprintManager_asn_orderlines_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_asn_orderlines_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.linenumber = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.linenumber,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.material = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.material,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendorlot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendorlot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packaged_amount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaged_amount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.purchase_order_identifier = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.purchase_order_identifier,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['purchase_order_identifier_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.residue_hold_identifier = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.residue_hold_identifier,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['residue_hold_identifier_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.establishment_identifier = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.establishment_identifier,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['establishment_identifier_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.material_alias = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.material_alias,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['material_alias_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.marks = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.marks,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['marks_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.size = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.size,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['size_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.color = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.color,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['color_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.style = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.style,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['style_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    
    this.formGroup
      .controls['purchase_order_identifier_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save_existing_row();
      });
  }

  async $initializeExisting(entity: IFootprintManager_asn_orderlines_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.OrderId,this.entity.LineNumber].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_asn_orderlines_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.OrderId,this.entity.LineNumber].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'OrderId',this.entity.OrderId);
    set($resultKey, 'LineNumber',this.entity.LineNumber);
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.$datasources.AsnOrders.ds_asn_orderlines_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_asn_orderlines_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.LineNumber?.toString();
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.MaterialPackagingLookup?.Packaging?.ShortName;
    (this.cells.packaged_amount.displayControl as TextModel).text = $row.entity.PackagedAmount?.toString();
    (this.cells.purchase_order_identifier.displayControl as TextModel).text = $row?.entity?.PurchaseOrderIdentifier;
    (this.cells.purchase_order_identifier.editControl as TextBoxModel).reset($row?.entity?.PurchaseOrderIdentifier);
    (this.cells.residue_hold_identifier.displayControl as TextModel).text = $row?.entity?.ResidueHoldIdentifier;
    (this.cells.residue_hold_identifier.editControl as TextBoxModel).reset($row?.entity?.ResidueHoldIdentifier);
    (this.cells.establishment_identifier.displayControl as TextModel).text = $row?.entity?.EstablishmentIdentifier;
    (this.cells.establishment_identifier.editControl as TextBoxModel).reset($row?.entity?.EstablishmentIdentifier);
    (this.cells.material_alias.displayControl as TextModel).text = $row?.entity?.MaterialAlias;
    (this.cells.material_alias.editControl as TextBoxModel).reset($row?.entity?.MaterialAlias);
    (this.cells.marks.displayControl as TextModel).text = $row?.entity?.Marks;
    (this.cells.marks.editControl as TextBoxModel).reset($row?.entity?.Marks);
    (this.cells.size.displayControl as TextModel).text = $row?.entity?.Size;
    (this.cells.size.editControl as TextBoxModel).reset($row?.entity?.Size);
    (this.cells.color.displayControl as TextModel).text = $row?.entity?.Color;
    (this.cells.color.editControl as TextBoxModel).reset($row?.entity?.Color);
    (this.cells.style.displayControl as TextModel).text = $row?.entity?.Style;
    (this.cells.style.editControl as TextBoxModel).reset($row?.entity?.Style);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_asn_orderlines_gridComponentRowModel,
  $grid: FootprintManager_asn_orderlines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_orderlines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'asn_orderlines_grid.on_row_data_loaded');
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_asn_orderlines_gridComponentRowModel,
  $grid: FootprintManager_asn_orderlines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_orderlines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const payload: any = {};
  if ($row.cells.purchase_order_identifier.editControl.isChanged) { payload.PurchaseOrderIdentifier = $row.cells.purchase_order_identifier.editControl.value }
  if ($row.cells.establishment_identifier.editControl.isChanged) { payload.EstablishmentIdentifier = $row.cells.establishment_identifier.editControl.value }
  if ($row.cells.residue_hold_identifier.editControl.isChanged) { payload.ResidueHoldIdentifier = $row.cells.residue_hold_identifier.editControl.value }
  if ($row.cells.material_alias.editControl.isChanged) { payload.MaterialAlias = $row.cells.material_alias.editControl.value }
  if ($row.cells.marks.editControl.isChanged) { payload.Marks = $row.cells.marks.editControl.value }
  if ($row.cells.size.editControl.isChanged) { payload.Size = $row.cells.size.editControl.value }
  if ($row.cells.color.editControl.isChanged) { payload.Color = $row.cells.color.editControl.value }
  if ($row.cells.style.editControl.isChanged) { payload.Style = $row.cells.style.editControl.value }
  
  
  try {
  
      await $flows.Utilities.crud_update_dual_pk_flow({
          entitySet: 'OrderLines',
          pk1String: 'OrderId',
          pk1: $row.entity.OrderId,
          pk2String: 'LineNumber',
          pk2: $row.entity.LineNumber,
          entity: payload
      });
  
      await $row.refresh();
      await $grid.on_edi_enabled();
  } catch (error) {
      $shell.FootprintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-asn_orderlines_grid',
  templateUrl: './FootprintManager.asn_orderlines_grid.component.html'
})
export class FootprintManager_asn_orderlines_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_asn_orderlines_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_asn_orderlines_gridComponentInParams = { orderId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'linenumber', ' ', false, false, null, false),       material: new GridHeaderModel(new HeaderStyles(null, null), 'material', 'Material', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, null, false),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'packaged_amount', 'Quantity', false, false, null, false),       purchase_order_identifier: new GridHeaderModel(new HeaderStyles(null, null), 'purchase_order_identifier', 'Purchase order ID', false, false, null, false),       residue_hold_identifier: new GridHeaderModel(new HeaderStyles(null, null), 'residue_hold_identifier', 'Residue hold ID', false, false, null, false),       establishment_identifier: new GridHeaderModel(new HeaderStyles(null, null), 'establishment_identifier', 'Establishment ID', false, false, null, false),       material_alias: new GridHeaderModel(new HeaderStyles(null, null), 'material_alias', 'Material alias', false, false, null, false),       marks: new GridHeaderModel(new HeaderStyles(null, null), 'marks', 'Marks', false, false, null, false),       size: new GridHeaderModel(new HeaderStyles(null, null), 'size', 'Size', false, false, null, false),       color: new GridHeaderModel(new HeaderStyles(null, null), 'color', 'Color', false, false, null, false),       style: new GridHeaderModel(new HeaderStyles(null, null), 'style', 'Style', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_asn_orderlines_gridComponentRowModel[] = [];

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_asn_orderlines_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Order lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 15;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.$datasources.AsnOrders.ds_asn_orderlines_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_asn_orderlines_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_asn_orderlines_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_asn_orderlines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_orderlines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $grid.on_edi_enabled();
  
  
  
  }
  on_edi_enabled(event = null) {
    return this.on_edi_enabledInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edi_enabledInternal(
    $grid: FootprintManager_asn_orderlines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_orderlines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //Is this an EDI order?
  
  let is_edi = (await $flows.FootprintManager.is_edi_order_flow({ order_id: $grid.inParams.orderId }))?.result ?? false;
  
  if ($utils.isDefined(is_edi)) {
  
      if (is_edi) {
  
          $grid.headers.purchase_order_identifier.hidden = false;
          $grid.headers.establishment_identifier.hidden = false;
          $grid.headers.residue_hold_identifier.hidden = false;
          $grid.headers.marks.hidden = false;
          $grid.headers.material_alias.hidden = false;
          $grid.headers.size.hidden = false;
          $grid.headers.color.hidden = false;
          $grid.headers.style.hidden = false;
  
  
      }
  
  
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_asn_orderlines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_orderlines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    
  $grid.headers.purchase_order_identifier.hidden = true;
  $grid.headers.establishment_identifier.hidden = true;
  $grid.headers.residue_hold_identifier.hidden = true;
  $grid.headers.marks.hidden = true;
  $grid.headers.material_alias.hidden = true;
  $grid.headers.size.hidden = true;
  $grid.headers.color.hidden = true;
  $grid.headers.style.hidden = true;
  
  
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
