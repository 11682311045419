import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ds_serialnumber_editorService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { serialnumberId: number }): 
  Promise<{ result: { Id?: number, Archived?: boolean, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LookupCode?: string, LotId?: number, NetVolume?: number, NetWeight?: number, Notes?: string, PackagingId?: number, Width?: number, DimensionsUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string }, Packaging?: { Id?: number, ShortName?: string }, Status?: { Id?: number, Name?: string }, Lot?: { Id?: number, Material?: { Id?: number, PackagingLookups?: { PackagingId?: number, QuantityPrecisionScale?: number }[] } }, QuantityPrecisionScale?: number } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serialnumberId)) {
      missingRequiredInParams.push('\'serialnumberId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumber_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      serialnumberId: number    }) {
        if(isNil(inParams.serialnumberId)) {
          return true; 
        }
      return false;
    }
}
