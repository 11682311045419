import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './TemperatureReadings.frontend.types'

@Injectable({ providedIn: 'root' })
export class TemperatureReadings_create_temperature_reading_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { shipmentId?: number, shipmentLineId?: number, licensePlateId?: number, loadContainerId?: number, readingPointId: number, readingTypeId: number, temperature1: number, measurementUnit1: number, temperature2?: number, measurementUnit2?: number, temperature3?: number, measurementUnit3?: number, notes?: string }): Promise<{ temperatureReadingId?: number, reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.readingPointId)) {
      missingRequiredInParams.push('\'readingPointId\'');
    }
    if (isNil(inParams.readingTypeId)) {
      missingRequiredInParams.push('\'readingTypeId\'');
    }
    if (isNil(inParams.temperature1)) {
      missingRequiredInParams.push('\'temperature1\'');
    }
    if (isNil(inParams.measurementUnit1)) {
      missingRequiredInParams.push('\'measurementUnit1\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/TemperatureReadings/functions/create_temperature_reading_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

