import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Materials_ds_material_packagings_by_project_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { materialIds?: number[], fullTextSearch?: string, ownerId?: number, projectId?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, IsOrderDefault?: boolean, Length?: number, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, StackableGroupId?: string, StackableHeight?: number, StackableLength?: number, StackableVolume?: number, StackableWidth?: number, SubPackagingQuantity?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, ShortName?: string }, DimensionsUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string }, SubPackaging?: { Id?: number, ShortName?: string }, Material?: { Id?: number, ControllerTypeId?: number, Description?: string, IsFinishedGood?: boolean, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Materials/datasources/ds_material_packagings_by_project_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { materialIds?: number[], fullTextSearch?: string, ownerId?: number, projectId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, IsOrderDefault?: boolean, Length?: number, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, StackableGroupId?: string, StackableHeight?: number, StackableLength?: number, StackableVolume?: number, StackableWidth?: number, SubPackagingQuantity?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, ShortName?: string }, DimensionsUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string }, SubPackaging?: { Id?: number, ShortName?: string }, Material?: { Id?: number, ControllerTypeId?: number, Description?: string, IsFinishedGood?: boolean, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Materials/datasources/ds_material_packagings_by_project_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { materialIds?: number[], fullTextSearch?: string, ownerId?: number, projectId?: number, $keys: { MaterialId?: number, PackagingId?: number }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, IsOrderDefault?: boolean, Length?: number, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, StackableGroupId?: string, StackableHeight?: number, StackableLength?: number, StackableVolume?: number, StackableWidth?: number, SubPackagingQuantity?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, ShortName?: string }, DimensionsUom?: { Id?: number, Short_name?: string }, VolumeUom?: { Id?: number, Short_name?: string }, WeightUom?: { Id?: number, Short_name?: string }, SubPackaging?: { Id?: number, ShortName?: string }, Material?: { Id?: number, ControllerTypeId?: number, Description?: string, IsFinishedGood?: boolean, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_material_packagings_by_project_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      materialIds?: number[], fullTextSearch?: string, ownerId?: number, projectId?: number    }) {
      return false;
    }
}
