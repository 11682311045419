import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Manifesting_ModuleContextService } from './Manifesting.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Manifesting_containers_by_type_dd_ComponentContextService {
  private readonly moduleContext: Manifesting_ModuleContextService;

  constructor(moduleContext: Manifesting_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'containers_by_type_dd',
      type: ComponentType.selector
    }
  }
}