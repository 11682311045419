import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_manufacturing_order_production_confirmation_data_extendedService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { manufacturing_order_id?: number, $top?: number, $skip?: number }): Promise<{ result?: { id?: number, actualPackagedAmount?: number, finishedGood?: string, lot?: string, licensePlate?: string, completedOn?: string, lotExpiration?: string, workshift?: string, packaging?: string, taskChainHead?: number, warehouseId?: number, manufacturingOrderLineId?: number, projectId?: number, stagingLocation?: number }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_manufacturing_order_production_confirmation_data_extended/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { manufacturing_order_id?: number, $keys: number[] }): Promise<{ result?: { id?: number, actualPackagedAmount?: number, finishedGood?: string, lot?: string, licensePlate?: string, completedOn?: string, lotExpiration?: string, workshift?: string, packaging?: string, taskChainHead?: number, warehouseId?: number, manufacturingOrderLineId?: number, projectId?: number, stagingLocation?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_manufacturing_order_production_confirmation_data_extended/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      manufacturing_order_id?: number    }) {
      return false;
    }
}
