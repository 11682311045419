import { Utilities_Types } from './Utilities.frontend.types'

export namespace Invoices_Types {
  /**
   * 
   */
  export interface AutoSchedule {
    date_option?: string;
    date_interval?: any;
    time_option?: string;
    time_interval?: any;
    time_offset?: number;
    time_zone?: { country?: string, utc?: string, time_zone?: string };
  }
  /**
   * This is the definition for the commodity price record.
   */
  export interface CommodityPrice {
    price_id?: string;
    name?: string;
    type?: string;
    description?: string;
    value?: number;
    date?: string;
    date_number?: number;
  }
  /**
   * Holds possible values for creating an accessorial task.
   */
  export enum EAccessorialTaskOption {
    Release = 1,
    Plan = 4,
    Complete = 2
  }
  /**
   * This describes the entity import type.
   */
  export interface EntityImport {
    Entity?: { Type?: string, ReferenceKey?: string, Action?: string, MatchCriteria?: { Limit?: number, Criteria?: { Type?: string, PropertyName?: string, Value?: string }[], OrderBy?: { PropertyName?: string, Direction?: string }[] }, Conditions?: { Type?: string, Group?: number, Test?: string, Value?: string }[], Properties?: { Name?: string, Value?: string, EvaluateAsExpression?: string, Update?: string }[], UserDefinedFields?: { Name?: string, Values?: string[] }[], IsValidation?: string };
  }
  /**
   * This is the definition for the freight billing details record.
   */
  export interface FreightBillingDetails {
    header_id?: string;
    freight_header_lookupcode?: string;
    charge_type?: string;
    charge_amount?: number;
    pallet_range_from?: number;
    pallet_range_to?: number;
    created_date?: string;
    date_number?: number;
    order?: number;
  }
  /**
   * This is the definition for the freight billing header record.
   */
  export interface FreightBillingHeader {
    header_id?: string;
    lookup_code?: string;
    warehouse_id?: number;
    warehouse_lookupcode?: string;
    account_id?: number;
    account_lookupcode?: string;
    destination?: string;
    shipping_days?: string;
    delivery_days?: string;
    owner_id?: number;
    owner_lookupcode?: string;
    shipping_days_mon?: boolean;
    shipping_days_tue?: boolean;
    shipping_days_wed?: boolean;
    shipping_days_thu?: boolean;
    shipping_days_fri?: boolean;
    shipping_days_sat?: boolean;
    shipping_days_sun?: boolean;
    delivery_days_mon?: boolean;
    delivery_days_tue?: boolean;
    delivery_days_wed?: boolean;
    delivery_days_thu?: boolean;
    delivery_days_fri?: boolean;
    delivery_days_sat?: boolean;
    delivery_days_sun?: boolean;
    address_id?: number;
    created_date?: string;
    date_number?: number;
    project_id?: number;
    project_lookupcode?: string;
    project_name?: string;
    owner_name?: string;
    account_name?: string;
  }
  /**
   * Defines the fields from the invoicing instruction object.
   */
  export interface Instruction {
    instructionId?: string;
    instructionName?: string;
    instructionType?: string;
    active?: boolean;
    billingContractId?: number;
    projectId?: number;
    termId?: number;
    taxScheduleId?: number;
    billingAggregationStrategyIds?: number[];
    oneInvoicePerShipment?: boolean;
    oneInvoicePerWarehouse?: any;
    useExistingInvoice?: boolean;
    createdOn?: string;
    createdBy?: string;
    modifiedOn?: string;
    modifiedBy?: string;
    billingCodeIds?: number[];
    cronExpression?: string;
    schedule?: { date_option?: string, date_interval?: string[], time_option?: string, time_interval?: string, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } };
    schedule_name?: string;
  }
  /**
   * Lists group by option values for auto-invoicing rules.
   */
  export enum InvoiceRuleGroupByOptionsEnum {
    Rule = 'RULE',
    Warehouse = 'WAREHOUSE',
    Shipment = 'SHIPMENT'
  }
  /**
   * Defined the fields from the logging object.
   */
  export interface Log {
    logId?: string;
    level?: number;
    projectId?: number;
    billingContractId?: number;
    invoiceId?: number;
    instructionId?: string;
    process?: string;
    notes?: string;
    createdOn?: string;
    alertedOn?: string;
    transactionId?: string;
    requestId?: string;
  }
  /**
   * Defines the fields from the transaction instruction object.
   */
  export interface Transaction {
    transactionId?: string;
    transactionType?: string;
    instructionId?: string;
    instructionName?: string;
    status?: string;
    projectId?: number;
    billingContractId?: number;
    invoiceId?: number;
    shipmentId?: number;
    warehouseId?: number;
    process?: string;
    notes?: string;
    createdOn?: string;
    modifiedOn?: string;
    parentTransactionId?: string;
    requestId?: string;
    termId?: number;
    taxScheduleId?: number;
    billingAggregationStrategyIds?: number[];
    oneInvoicePerShipment?: boolean;
    oneInvoicePerWarehouse?: boolean;
    billingTaskId?: number;
    useExistingInvoice?: boolean;
    billingCodeIds?: number[];
  }
}

export namespace $frontendTypes {
  export import Utilities =  Utilities_Types;
  export import Invoices = Invoices_Types;
}




