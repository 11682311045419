import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Owners_ds_get_container_types_assignment_ddService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId?: number, ownerId?: number, materialId?: number, fullTextSearch?: string }): 
  Promise<{ result: { Id?: number, CatchAll?: boolean, Description?: string, Height?: number, Length?: number, Name?: string, WeightCapacity?: number, Width?: number, DimensionUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Owners/datasources/ds_get_container_types_assignment_dd/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId?: number, ownerId?: number, materialId?: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CatchAll?: boolean, Description?: string, Height?: number, Length?: number, Name?: string, WeightCapacity?: number, Width?: number, DimensionUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Owners/datasources/ds_get_container_types_assignment_dd/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, ownerId?: number, materialId?: number, fullTextSearch?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, CatchAll?: boolean, Description?: string, Height?: number, Length?: number, Name?: string, WeightCapacity?: number, Width?: number, DimensionUom?: { Name?: string, Short_name?: string }, WeightUom?: { Name?: string, Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Owners/datasources/ds_get_container_types_assignment_dd/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projectId?: number, ownerId?: number, materialId?: number, fullTextSearch?: string    }) {
      return false;
    }
}
