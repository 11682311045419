<datex-list [items]="items"
            [pageSize]="pageSize"
            [(pageSkip)]="pageSkip"
            [totalCount]="totalCount"
            [loadingStatus]="loadingStatus"
            (loadMore)="$dataLoad()">

  <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="refresh" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_refresh($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-area" *ngIf="!filters.area.hidden && !filters.area.removed" 
            class="field-container double {{filters.area.invalid ? 'invalid' : ''}} {{filters.area.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.area.styles.style"
            [ngClass]="filters.area.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.area.label + (filters.area.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.area.label}}<span *ngIf="filters.area.required" class="required-asterisk">*</span>
          </label>
        </div>
        <DockAppointments-areas_dd_multi 
            data-cy="selector-multi"
            [type]="filters.area.control.type"
            formControlName="area"
            (displayTextChange)="filters.area.control.displayText=$event"
            [placeholder]="filters.area.control.placeholder"
            [styles]="filters.area.control.styles"
            [tooltip]="filters.area.control.tooltip"
          [warehouseIds]="$fields_area_selector_inParams_warehouseIds"
        >
        </DockAppointments-areas_dd_multi>
        <ng-container *ngIf="filters.area.invalid">
          <ng-container *ngFor="let error of filters.area.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-template listItemDef
               let-item>
    <FootprintManager-dock_door_card
    [dockDoorId]="item.$content_FootprintManager_dock_door_card_inParams_dockDoorId"
    (openEditorClick)="on_refresh($event)"
    (outParamsChange)="on_refresh($event)"
    ($refreshEvent)="$refreshEvent.emit()"
    >
    </FootprintManager-dock_door_card>
  </ng-template>

</datex-list>