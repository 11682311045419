<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-group1"
                     *ngIf="!fieldsets.group1.hidden && !fieldsets.group1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.group1.collapsible }">
                    <div *ngIf="!fieldsets.group1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.group1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.group1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.group1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.group1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.group1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.group1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-clientid" *ngIf="!fields.clientid.hidden && !fields.clientid.removed" 
                            class="field-container standard {{fields.clientid.invalid ? 'invalid' : ''}} {{fields.clientid.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.clientid.styles.style"
                            [ngClass]="fields.clientid.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.clientid.label + (fields.clientid.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.clientid.label}}<span *ngIf="fields.clientid.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="clientid"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.clientid.control.readOnly || fields.clientid.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.clientid.control.placeholder}}"
                                [ngStyle]="fields.clientid.control.styles.style"
                                [ngClass]="fields.clientid.control.styles.classes"
                                [matTooltip]="fields.clientid.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.clientid.invalid">
                          <ng-container *ngFor="let error of fields.clientid.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-clientecrets" *ngIf="!fields.clientecrets.hidden && !fields.clientecrets.removed" 
                            class="field-container standard {{fields.clientecrets.invalid ? 'invalid' : ''}} {{fields.clientecrets.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.clientecrets.styles.style"
                            [ngClass]="fields.clientecrets.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.clientecrets.label + (fields.clientecrets.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.clientecrets.label}}<span *ngIf="fields.clientecrets.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="clientecrets"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.clientecrets.control.readOnly || fields.clientecrets.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.clientecrets.control.placeholder}}"
                                [ngStyle]="fields.clientecrets.control.styles.style"
                                [ngClass]="fields.clientecrets.control.styles.classes"
                                [matTooltip]="fields.clientecrets.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.clientecrets.invalid">
                          <ng-container *ngFor="let error of fields.clientecrets.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-enantid" *ngIf="!fields.enantid.hidden && !fields.enantid.removed" 
                            class="field-container standard {{fields.enantid.invalid ? 'invalid' : ''}} {{fields.enantid.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.enantid.styles.style"
                            [ngClass]="fields.enantid.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.enantid.label + (fields.enantid.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.enantid.label}}<span *ngIf="fields.enantid.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="enantid"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.enantid.control.readOnly || fields.enantid.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.enantid.control.placeholder}}"
                                [ngStyle]="fields.enantid.control.styles.style"
                                [ngClass]="fields.enantid.control.styles.classes"
                                [matTooltip]="fields.enantid.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.enantid.invalid">
                          <ng-container *ngFor="let error of fields.enantid.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-scoope" *ngIf="!fields.scoope.hidden && !fields.scoope.removed" 
                            class="field-container standard {{fields.scoope.invalid ? 'invalid' : ''}} {{fields.scoope.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.scoope.styles.style"
                            [ngClass]="fields.scoope.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.scoope.label + (fields.scoope.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.scoope.label}}<span *ngIf="fields.scoope.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="scoope"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.scoope.control.readOnly || fields.scoope.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.scoope.control.placeholder}}"
                                [ngStyle]="fields.scoope.control.styles.style"
                                [ngClass]="fields.scoope.control.styles.classes"
                                [matTooltip]="fields.scoope.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.scoope.invalid">
                          <ng-container *ngFor="let error of fields.scoope.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-customer_name" *ngIf="!fields.customer_name.hidden && !fields.customer_name.removed" 
                            class="field-container standard {{fields.customer_name.invalid ? 'invalid' : ''}} {{fields.customer_name.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.customer_name.styles.style"
                            [ngClass]="fields.customer_name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.customer_name.label + (fields.customer_name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.customer_name.label}}<span *ngIf="fields.customer_name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="customer_name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.customer_name.control.readOnly || fields.customer_name.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.customer_name.control.placeholder}}"
                                [ngStyle]="fields.customer_name.control.styles.style"
                                [ngClass]="fields.customer_name.control.styles.classes"
                                [matTooltip]="fields.customer_name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.customer_name.invalid">
                          <ng-container *ngFor="let error of fields.customer_name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-compguid" *ngIf="!fields.compguid.hidden && !fields.compguid.removed" 
                            class="field-container standard {{fields.compguid.invalid ? 'invalid' : ''}} {{fields.compguid.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.compguid.styles.style"
                            [ngClass]="fields.compguid.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.compguid.label + (fields.compguid.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.compguid.label}}<span *ngIf="fields.compguid.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="compguid"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.compguid.control.readOnly || fields.compguid.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.compguid.control.placeholder}}"
                                [ngStyle]="fields.compguid.control.styles.style"
                                [ngClass]="fields.compguid.control.styles.classes"
                                [matTooltip]="fields.compguid.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.compguid.invalid">
                          <ng-container *ngFor="let error of fields.compguid.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-url" *ngIf="!fields.url.hidden && !fields.url.removed" 
                            class="field-container standard {{fields.url.invalid ? 'invalid' : ''}} {{fields.url.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.url.styles.style"
                            [ngClass]="fields.url.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.url.label + (fields.url.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.url.label}}<span *ngIf="fields.url.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="url"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.url.control.readOnly || fields.url.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.url.control.placeholder}}"
                                [ngStyle]="fields.url.control.styles.style"
                                [ngClass]="fields.url.control.styles.classes"
                                [matTooltip]="fields.url.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.url.invalid">
                          <ng-container *ngFor="let error of fields.url.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-savez" *ngIf="!fields.savez.hidden && !fields.savez.removed" 
                            class="field-container standard {{fields.savez.invalid ? 'invalid' : ''}} {{fields.savez.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.savez.styles.style"
                            [ngClass]="fields.savez.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.savez.label + (fields.savez.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.savez.label}}<span *ngIf="fields.savez.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.savez.control.readOnly || fields.savez.control.disabled"
                                [ngStyle]="fields.savez.control.styles.style"
                                [ngClass]="fields.savez.control.styles.classes"
                                [matTooltip]="fields.savez.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.savez.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.savez.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.savez.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.savez.invalid">
                          <ng-container *ngFor="let error of fields.savez.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.group1.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-group2"
                     *ngIf="!fieldsets.group2.hidden && !fieldsets.group2.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.group2.collapsible }">
                    <div *ngIf="!fieldsets.group2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.group2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.group2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.group2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.group2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.group2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.group2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-save" *ngIf="!fields.save.hidden && !fields.save.removed" 
                            class="field-container standard {{fields.save.invalid ? 'invalid' : ''}} {{fields.save.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.save.styles.style"
                            [ngClass]="fields.save.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.save.label + (fields.save.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.save.label}}<span *ngIf="fields.save.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.save.control.readOnly || fields.save.control.disabled"
                                [ngStyle]="fields.save.control.styles.style"
                                [ngClass]="fields.save.control.styles.classes"
                                [matTooltip]="fields.save.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.save.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.save.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.save.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.save.invalid">
                          <ng-container *ngFor="let error of fields.save.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-guid" *ngIf="!fields.guid.hidden && !fields.guid.removed" 
                            class="field-container standard {{fields.guid.invalid ? 'invalid' : ''}} {{fields.guid.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.guid.styles.style"
                            [ngClass]="fields.guid.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.guid.label + (fields.guid.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.guid.label}}<span *ngIf="fields.guid.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.guid.control.readOnly || fields.guid.control.disabled"
                                [ngStyle]="fields.guid.control.styles.style"
                                [ngClass]="fields.guid.control.styles.classes"
                                [matTooltip]="fields.guid.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="create_tokens($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.guid.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.guid.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.guid.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.guid.invalid">
                          <ng-container *ngFor="let error of fields.guid.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.group2.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>