import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_asn_order_licenseplates_grid_ComponentContextService } from './FootprintManager.asn_order_licenseplates_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Locations_licenseplate_statuses_dd_singleComponent } from './Locations.licenseplate_statuses_dd_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'


import { FootprintManager_asn_order_licenseplates_receiving_tasks_gridComponent } from './FootprintManager.asn_order_licenseplates_receiving_tasks_grid.component';

interface IFootprintManager_asn_order_licenseplates_gridComponentEntity {
  LookupCode?: string, Id?: number, StatusId?: number, StatusName?: string, LocationId?: number, LocationName?: string, NetWeight?: number, GrossWeight?: number}

interface IFootprintManager_asn_order_licenseplates_gridComponentInParams {
  order_id: number, order_status_id: number, project_id: number, can_unreceive?: boolean}


class FootprintManager_asn_order_licenseplates_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_asn_order_licenseplates_gridComponent;
  entity: IFootprintManager_asn_order_licenseplates_gridComponentEntity;

  vars: { can_receive?: boolean, can_unreceive?: boolean, can_delete?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    lookupcode_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    status_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    location_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    id: null,
    lookupcode: null,
    status: null,
    location: null,
  }

  get $fields_location_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.vars.shipments[0].warehouseId;
    
    return expr;
  }
  get $fields_location_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_location_selector_inParams_isLoose(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = false;
    
    return expr;
  }

  //#region rowExpand inParams


  get $rowExpand_FootprintManager_asn_order_licenseplates_receiving_tasks_grid_inParams_licenseplateId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_asn_order_licenseplates_receiving_tasks_grid_inParams_orderId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.order_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_asn_order_licenseplates_receiving_tasks_grid_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_asn_order_licenseplates_receiving_tasks_grid_inParams_orderStatusId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.order_status_id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    grid: FootprintManager_asn_order_licenseplates_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.id = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.id,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lookupcode,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['lookupcode_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['status_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['location_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false,
        false,
        '', null)
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_asn_order_licenseplates_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_asn_order_licenseplates_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.order_id ,
      shipmentId:  $grid.vars.shipments[0]?.id ,
    };
    const data = await this.$datasources.AsnOrders.ds_asn_order_licenseplates_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_asn_order_licenseplates_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity?.Id?.toString();
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity?.LookupCode;
    (this.cells.lookupcode.editControl as TextBoxModel).reset($row.entity?.LookupCode);
    (this.cells.status.displayControl as TextModel).text = $row.entity.StatusName;
    (this.cells.status.editControl as SelectBoxModel).reset($row.entity?.StatusId ?? $row.cells.status.editControl.value);
    (this.cells.location.displayControl as TextModel).text = $row.entity.LocationName;
    (this.cells.location.editControl as SelectBoxModel).reset($row.entity?.LocationId ?? $row.cells.location.editControl.value);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'id' : {
        this.on_click_id();
        break;
      }
    }
  }

  //#region private flows
  on_click_id(event = null) {
    return this.on_click_idInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_click_idInternal(
    $row: FootprintManager_asn_order_licenseplates_gridComponentRowModel,
  $grid: FootprintManager_asn_order_licenseplates_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootprintManager.opensingle_licenseplate_hub({licenseplateId: $row.entity.Id});
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_asn_order_licenseplates_gridComponentRowModel,
  $grid: FootprintManager_asn_order_licenseplates_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Update LicensePlate
  try {
      if ($row.cells.lookupcode.editControl.isChanged || $row.cells.location.editControl.isChanged) {
          await updateLicensePlate(
              $row.entity.Id,
              $row.cells.lookupcode.editControl.value,
              $row.cells.location.editControl.value,
              $row.cells.status.editControl.value
          );
  
          await $row.refresh();
      }
      let state = (await $flows.AsnOrders.get_license_plates_state_flow({ license_plate_ids: [$row.entity.Id]})).license_plates[0];
  
      $row.vars.can_receive = !$utils.isDefined(state.fail_action_reasons.receive);
      $row.vars.can_unreceive = !$utils.isDefined(state.fail_action_reasons.unreceive);
      $row.vars.can_delete = !$utils.isDefined(state.fail_action_reasons.deletion);
  }
  catch (ex) {
      let message = $utils.isDefined(ex.error?.error?.message) ? ex.error.error.message : ex.message;
      $shell.FootprintManager.openErrorDialog('Error', message);
      throw Error(message); // To prevent display mode
  }
  
  
  /****************************************** 
   * FUNCTIONS
  ******************************************/
  async function updateLicensePlate(licenseplateId: number, lookupcode: string, locationId: number, statusId: number) {
      let payload = {
          "LookupCode": lookupcode,
          "LocationId": locationId,
          "StatusId": statusId
      }
  
      await $flows.Utilities.crud_update_flow({
          entitySet: "LicensePlates",
          id: licenseplateId,
          entity: payload
      });
  
      let tasks = (await $datasources.AsnOrders.ds_asn_order_licenseplates_receiving_tasks_grid.get({ licenseplateId: licenseplateId, orderId: $grid.inParams.order_id })).result;
  
      if ($utils.isDefined(tasks)) {
          for (let task of tasks) {
              let properties = {
                  "ActualTargetLocationId": locationId,
                  "ExpectedTargetLocationId": locationId
              }
  
              await $flows.Utilities.crud_update_flow({ entitySet: "Tasks", id: task.Id, entity: properties })
          }
      }
  
  }
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_asn_order_licenseplates_gridComponentRowModel,
  $grid: FootprintManager_asn_order_licenseplates_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Check that all required fields are populated
      if (!$utils.isAllDefined(
          $row.cells.lookupcode.editControl.value,
          $row.cells.location.editControl.value
      )) {
          $shell.FootprintManager.openErrorDialog('Error', 'Please enter all required fields.');
      }
  
      // Create new LicensePlate
      $row.entity.Id = await createLicensePlate(
          $row.cells.lookupcode.editControl.value,
          $row.cells.location.editControl.value,
          $row.cells.status.editControl.value,
          $grid.vars.shipments[0].id
      );
  
      await $row.refresh();
  
      let state = (await $flows.AsnOrders.get_license_plates_state_flow({ license_plate_ids: [$row.entity.Id]})).license_plates[0];
  
      $row.vars.can_receive = !$utils.isDefined(state.fail_action_reasons.receive);
      $row.vars.can_unreceive = !$utils.isDefined(state.fail_action_reasons.unreceive);
      $row.vars.can_delete = !$utils.isDefined(state.fail_action_reasons.deletion);
  }
  catch (ex) {
      let message = $utils.isDefined(ex.error?.error?.message) ? ex.error.error.message : ex.message;
      $shell.FootprintManager.openErrorDialog('Error', message);
      throw Error(message); // To prevent display mode
  }
  
  /****************************************** 
   * FUNCTIONS
  ******************************************/
  async function createLicensePlate(lookupcode: string, locationId: number, statusId: number, shipmentId: number) {
      let licenseplateId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LicensePlate' })).nextId;
  
      let locationResults = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      let warehouseId = locationResults[0].WarehouseId;
  
      let payload = {
          "Archived": false,
          "ContainsPortalVisibleAttachments": false,
          "Id": licenseplateId,
          "LocationId": locationId,
          "LookupCode": lookupcode,
          "ShipmentId": shipmentId,
          "StatusId": statusId,
          "TypeId": 5,
          "WarehouseId": warehouseId
      }
  
      await $flows.Utilities.crud_create_flow({
          entitySet: "LicensePlates",
          entity: payload
      });
  
      return licenseplateId;
  }
  
  async function updateOrderStatus(orderId: number, shipmentId: number) {
      let order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId })).result;
  
      if (order.OrderStatusId === 1) {
          $flows.Utilities.crud_update_flow({ entitySet: "Orders", id: orderId, entity: { "OrderStatusId": 2 } });
          $flows.Utilities.crud_update_flow({ entitySet: "Shipments", id: shipmentId, entity: { "StatusId": 2 } })
      }
  }
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootprintManager_asn_order_licenseplates_gridComponentRowModel,
  $grid: FootprintManager_asn_order_licenseplates_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      let defaultLocationId = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $grid.vars.shipments[0].warehouseId })).result.DefaultReceivingLocationId;
  
      if ($utils.isDefined(defaultLocationId)) {
          const location = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: defaultLocationId })).result;
          if ($utils.isDefined(location)) {
  
              // Use the deault receiving location only if it is not loose.
              if (!location[0].IsLoose) {
                  $row.cells.location.editControl.value = defaultLocationId;
              }
  
          }
  
      }
      
      $row.cells.status.editControl.value = 1;
  }
  catch (ex) {
      $shell.FootprintManager.openErrorDialog('Error', ex.message);
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_asn_order_licenseplates_gridComponentRowModel,
  $grid: FootprintManager_asn_order_licenseplates_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'asn_order_licenseplates_grid.on_row_data_loaded');
  
  }
  on_receiving_tasks_changed(event = null) {
    return this.on_receiving_tasks_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_receiving_tasks_changedInternal(
    $row: FootprintManager_asn_order_licenseplates_gridComponentRowModel,
  $grid: FootprintManager_asn_order_licenseplates_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let state = (await $flows.AsnOrders.get_license_plates_state_flow({ license_plate_ids: [$row.entity.Id] })).license_plates[0];
  
  $row.vars.can_receive = !$utils.isDefined(state.fail_action_reasons.receive);
  $row.vars.can_unreceive = !$utils.isDefined(state.fail_action_reasons.unreceive);
  $row.vars.can_delete = !$utils.isDefined(state.fail_action_reasons.deletion);
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_licenseplate_statuses_dd_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => FootprintManager_asn_order_licenseplates_receiving_tasks_gridComponent)
  ],
  selector: 'FootprintManager-asn_order_licenseplates_grid',
  templateUrl: './FootprintManager.asn_order_licenseplates_grid.component.html'
})
export class FootprintManager_asn_order_licenseplates_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_asn_order_licenseplates_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', ['compact']);

  fullTextSearch: string;

  inParams: IFootprintManager_asn_order_licenseplates_gridComponentInParams = { order_id: null, order_status_id: null, project_id: null, can_unreceive: null };


  //#region Variables
  vars: { shipments?: { id?: number, warehouseId?: number }[] } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'id', 'ID', false, false, null, false),       lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'License plate', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       location: new GridHeaderModel(new HeaderStyles(null, null), 'location', 'Current location', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_asn_order_licenseplates_gridComponentRowModel[] = [];

  @Input('order_id') set $inParams_order_id(value: any) {
    this.inParams['order_id'] = value;
  }
  get $inParams_order_id(): any {
    return this.inParams['order_id'] ;
  }
  @Input('order_status_id') set $inParams_order_status_id(value: any) {
    this.inParams['order_status_id'] = value;
  }
  get $inParams_order_status_id(): any {
    return this.inParams['order_status_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('can_unreceive') set $inParams_can_unreceive(value: any) {
    this.inParams['can_unreceive'] = value;
  }
  get $inParams_can_unreceive(): any {
    return this.inParams['can_unreceive'] ;
  }

  topToolbar = {
      receive: new ToolModel(new ButtonModel('receive', new ButtonStyles(null, null), false, false, false, 'Receive', 'icon-datex-Receive', null)
    , false),
      unreceive: new ToolModel(new ButtonModel('unreceive', new ButtonStyles(null, null), false, false, false, 'Unreceive', 'icon-ic_fluent_screen_cut_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_licenseplate: new ToolModel(new ButtonModel('delete_licenseplate', new ButtonStyles(['destructive'], null), true, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    ) {
    super();
    this.title = 'License plates';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
      if(isNil(this.inParams.order_status_id)) {
        this.$missingRequiredInParams.push('order_status_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 9;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.order_id ,
      shipmentId:  $grid.vars.shipments[0]?.id ,
    };
    try {
      const data = await this.$datasources.AsnOrders.ds_asn_order_licenseplates_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_asn_order_licenseplates_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_asn_order_licenseplates_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_asn_order_licenseplates_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_selection_changed();
  }

  async addRow(entity?: IFootprintManager_asn_order_licenseplates_gridComponentEntity) {
    const row = new FootprintManager_asn_order_licenseplates_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_licenseplates(event = null) {
    return this.on_delete_licenseplatesInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_licenseplatesInternal(
    $grid: FootprintManager_asn_order_licenseplates_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let licensePlates: any[] = [];
  
  for (let row of $grid.selectedRows) {
      let tasks = (await $datasources.AsnOrders.ds_asn_order_licenseplates_receiving_tasks_grid.get({ licenseplateId: row.entity.Id, orderId: $grid.inParams.order_id })).result
  
      licensePlates.push({
          Id: row.entity.Id,
          Tasks: tasks.map(t => ({ Id: t.Id, ExpectedPackagedAmount: t.ExpectedPackagedAmount, OrderId: t.OrderId, OrderLineNumber: t.OrderLineNumber, ShipmentLineId: t.ShipmentLineId, LotId: t.Lot.Id, VendorLotId: t.VendorLot.Id, MaterialId: t.Material.Id, PackagingId: t.ExpectedPackagedPack.Id }))
      });
  }
  
  let licensePlateCount = licensePlates.length;
  let totalPackagedAmount = licensePlates.reduce((total, lp) => total + lp.Tasks.reduce((lpTotal, task) => lpTotal + task.ExpectedPackagedAmount, 0), 0);
  let message: string = `Are you sure you want to delete ${licensePlateCount} ${licensePlateCount === 1 ? 'license plate' : 'license plates'}${totalPackagedAmount > 0 ? ` along with ${totalPackagedAmount} packaged content` : ''}?`;
  
  
  let isConfirmed = await $shell.FootprintManager.openConfirmationDialog('Confirm: Delete License Plate(s)', message, 'Confirm', 'Cancel');
  
  if ($utils.isDefined(isConfirmed) && isConfirmed) {
      for (let licensePlate of licensePlates) {
          var serialNumbers = (await $flows.AsnOrders.get_serials_to_delete_with_children_flow({ licenseplateId: licensePlate.Id }))
          if ($utils.isDefined(serialNumbers)) {
              var parentSerials = serialNumbers.parentSerialNumbers
              if ($utils.isDefined(serialNumbers.fourthChildSerials)) {
                  let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.fourthChildSerials })).reasons
                  if ($utils.isDefined(result)) {
                      $shell.FootprintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.fourthChildSerials}`, result.join(' ,'))
                  }
              }
              if ($utils.isDefined(serialNumbers.thirdChildSerials)) {
                  let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.thirdChildSerials })).reasons
                  if ($utils.isDefined(result)) {
                      $shell.FootprintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.thirdChildSerials}`, result.join(' ,'))
                  }
              }
              if ($utils.isDefined(serialNumbers.secondChildSerials)) {
                  let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.secondChildSerials })).reasons
                  if ($utils.isDefined(result)) {
                      $shell.FootprintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.secondChildSerials}`, result.join(' ,'))
                  }
              }
              if ($utils.isDefined(serialNumbers.firstChildSerials)) {
                  let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.firstChildSerials })).reasons
                  if ($utils.isDefined(result)) {
                      $shell.FootprintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.firstChildSerials}`, result.join(' ,'))
                  }
              }
          }
      }
      for (let licensePlate of licensePlates) {
          for (let task of licensePlate.Tasks) {
              await $flows.AsnOrders.set_asn_receiving_task_flow({
                  licenseplateId: licensePlate.Id,
                  lotId: task.LotId,
                  packagingId: task.PackagingId,
                  orderId: $grid.inParams.order_id,
                  materialId: task.MaterialId,
                  vendorlotId: task.VendorLotId,
                  packagedAmount: 0,
                  existingTaskId: task.Id,
                  serialNumberIdsToDelete: parentSerials
              });
          }
  
          await $flows.Utilities.crud_delete_flow({ id: licensePlate.Id, entitySet: "LicensePlates" });
          $grid.rows.splice($grid.rows.indexOf($grid.rows.find(r => r.entity.Id === licensePlate.Id)), 1);
      }
  }
  
  /********************************************************************************
  *********************************************************************************
   * FUNCTIONS
  ********************************************************************************
  *******************************************************************************/
  async function updateShipmentLinePackagedAmount(shipmentLineId: number, adjustPackagedAmount: number) {
      let shipmentLine = (await $datasources.AsnOrders.ds_get_shipmentline_by_shipmentlineId.get({ shipmentLineId: shipmentLineId })).result;
  
      if ($utils.isDefined(shipmentLine)) {
          if (shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount > 0) {
              await $flows.Utilities.crud_update_flow({
                  entitySet: "ShipmentLines",
                  id: shipmentLineId,
                  entity: {
                      "ExpectedPackagedAmount": shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount,
                      "ExpectedAmount": (shipmentLine.ExpectedPackagedAmount + adjustPackagedAmount) * (shipmentLine.ExpectedAmount / shipmentLine.ExpectedPackagedAmount)
                  }
              });
          }
          else {
              await $flows.Utilities.crud_delete_flow({ id: shipmentLineId, entitySet: "ShipmentLines" });
          }
      }
  }
  
  
  async function updateOrderLinePackagedAmount(orderId: number, orderLineNumber: number, adjustPackagedAmount: number) {
      let orderLine = (await $datasources.AsnOrders.ds_get_orderlines_by_orderId_linenumber.get({ orderId: orderId, linenumber: orderLineNumber })).result;
  
      // Update or Delete OrderLine
      if ($utils.isDefined(orderLine)) {
          if (orderLine.PackagedAmount + adjustPackagedAmount > 0) {
              await $flows.Utilities.crud_update_dual_pk_flow({
                  entitySet: "OrderLines",
                  pk1String: "OrderId",
                  pk1: orderLine.OrderId,
                  pk2String: "LineNumber",
                  pk2: orderLine.LineNumber,
                  entity: {
                      "PackagedAmount": orderLine.PackagedAmount + adjustPackagedAmount,
                      "Amount": (orderLine.PackagedAmount + adjustPackagedAmount) * (orderLine.Amount / orderLine.PackagedAmount)
                  }
              });
          }
          else {
              await $flows.Utilities.crud_delete_dual_pk_flow({
                  entitySet: "OrderLines",
                  pk1String: "OrderId",
                  pk1: orderLine.OrderId,
                  pk2String: "LineNumber",
                  pk2: orderLine.LineNumber
              });
          }
      }
  }
  }
  on_selection_changed(event = null) {
    return this.on_selection_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selection_changedInternal(
    $grid: FootprintManager_asn_order_licenseplates_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.receive.control.readOnly = true;
      $grid.topToolbar.unreceive.control.readOnly = true;
      $grid.topToolbar.delete_licenseplate.control.readOnly = true;
  
  // One or more rows selected
  if ($grid.selectedRows.length > 0) {
      $grid.topToolbar.receive.control.readOnly = $grid.selectedRows.some(row => !row.vars.can_receive);
      $grid.topToolbar.unreceive.control.readOnly = $grid.selectedRows.some(row => !row.vars.can_unreceive);
      $grid.topToolbar.delete_licenseplate.control.readOnly = $grid.selectedRows.some(row => !row.vars.can_delete);
  }
  }
  on_receive_clicked(event = null) {
    return this.on_receive_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_receive_clickedInternal(
    $grid: FootprintManager_asn_order_licenseplates_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  let license_plate_ids = $grid.selectedRows.filter(r => $utils.isDefined(r.entity?.Id)).map(r => r.entity.Id);
  
  if (license_plate_ids.length > 0) {
      await $shell.FootprintManager.openauto_receive_and_putaway_formDialog({
          orderId: $grid.inParams.order_id,
          licenseplateIds: license_plate_ids
      });
  
      await $grid.refresh();
  }
  else {
      $shell.FootprintManager.openErrorDialog('Error receiving license plates', 'No eligible license plates were selected.');
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootprintManager_asn_order_licenseplates_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.receive.control.readOnly = true;
  $grid.topToolbar.unreceive.control.readOnly = true;
  $grid.topToolbar.delete_licenseplate.control.readOnly = true;
  
  const license_plate_states = (await $flows.AsnOrders.get_license_plates_state_flow({ license_plate_ids: $grid.rows.map(r => r.entity.Id)})).license_plates;
  
  for (let row of $grid.rows) {
      const state = license_plate_states.find(s => s.id === row.entity.Id);
  
      row.vars.can_receive = !$utils.isDefined(state.fail_action_reasons.receive);
      row.vars.can_unreceive = !$utils.isDefined(state.fail_action_reasons.unreceive);
      row.vars.can_delete = !$utils.isDefined(state.fail_action_reasons.deletion);
  }
  
  // Call common flow to apply the role based permissions
  await $grid.apply_operations();
  }
  on_unreceive_clicked(event = null) {
    return this.on_unreceive_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_unreceive_clickedInternal(
    $grid: FootprintManager_asn_order_licenseplates_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let licenseplates = $grid.selectedRows;
  
  const shipment = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.order_id })).result;
  if ($utils.isDefined(shipment)) {
  
      var shipmentId = shipment[0].ShipmentId;
      var orderId = shipment[0].OrderId;
  
      if (licenseplates.length > 0) {
  
          const dialogResult = await $shell.FootprintManager.openunreceiving_all_options_formDialog({
              title: 'Unreceive and bring back selected licenseplates to a planned state?'
          });
          var userConfirmed = dialogResult.confirm;
          var reasonCodeId = dialogResult.reasonCodeId;
  
          if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
              return;
          }
  
          if (userConfirmed) {
              try {
  
                  for (let licenseplate of licenseplates) {
  
                      let licenseplateId = licenseplate.entity.Id;
  
                      await $flows.AsnOrders.unreceive_licenseplate_asn_order_flow({
                          licenseplateId: licenseplateId,
                          reasonCodeId: reasonCodeId,
                          shipmentId: shipmentId,
                          orderId: orderId
                      })
                  }
  
                  await $grid.refresh();
              }
              catch (error) {
  
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              }
          }
      }
      else {
          $shell.FootprintManager.openErrorDialog('Error unreceiving license plates', 'No eligible license plates were selected.');
      }
  
  
  }
  else { $shell.FootprintManager.openErrorDialog('Error unreceiving license plates', 'Unable to determine shipment from order.'); }
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $grid: FootprintManager_asn_order_licenseplates_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let results = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.order_id })).result;
  
  if ($utils.isDefined(results)) {
  
      var shipmentStatus = results[0].Shipment?.StatusId;
      if ($utils.isDefined(shipmentStatus)) {
  
          // Check for completed or cancelled
          if (shipmentStatus !== 8 && shipmentStatus !== 16) {
  
              if (shipmentStatus == 1) {
                  $grid.canEdit = true;
                  $grid.canAdd = true;
  
              }
  
              else if (await $operations.FootprintManager.Disable_AsnOrders_LicenseplateEditing.isAuthorized()) {
                  $grid.canEdit = false;
                  $grid.canAdd = false;
  
              }
              else {
                  $grid.canEdit = true;
                  $grid.canAdd = true;
              }
  
          }
          else {
              $grid.canEdit = false;
              $grid.canAdd = false;
          }
  
  
      }
  
  }
  
  
  //Disable entire grid
  if (await $operations.FootprintManager.Disable_AsnOrders_OrderEdits.isAuthorized()) {
      
      $grid.canEdit = false;
      $grid.canAdd = false;
  
      $grid.topToolbar.delete_licenseplate.control.readOnly = true;
      $grid.topToolbar.receive.control.readOnly = true;
      $grid.topToolbar.unreceive.control.readOnly = true;
  
  }
  
  if (await $operations.FootprintManager.Disable_AsnOrders_AutoReceiving.isAuthorized()) {
      $grid.topToolbar.receive.hidden = true;
      $grid.topToolbar.separator1.hidden = true;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_asn_order_licenseplates_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_asn_order_licenseplates_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let results = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.order_id })).result;
  
  $grid.vars.shipments = results.map(r =>
      ({ id: r.ShipmentId, warehouseId: r.Shipment.ActualWarehouseId ?? r.Shipment.ExpectedWarehouseId })
  );
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
