import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { app_ModuleContextService } from './app.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class app_custom_inventory_lot_grid_ComponentContextService {
  private readonly moduleContext: app_ModuleContextService;

  constructor(moduleContext: app_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'custom_inventory_lot_grid',
      type: ComponentType.grid
    }
  }
}