<datex-list [items]="items"
            [pageSize]="pageSize"
            [(pageSkip)]="pageSkip"
            [totalCount]="totalCount"
            [loadingStatus]="loadingStatus"
            (loadMore)="$dataLoad()">

  <ng-container topToolbar>
  </ng-container>


  <ng-template listItemDef
               let-item>
    <FootprintManager-shipping_container_card
    [shippingContainerId]="item.$content_FootprintManager_shipping_container_card_inParams_shippingContainerId"
    ($refreshEvent)="$refreshEvent.emit()"
    >
    </FootprintManager-shipping_container_card>
  </ng-template>

</datex-list>