<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.short_name.displayControl.styles.style"
          [ngClass]="row.cells.short_name.displayControl.styles.classes"
          [matTooltip]="row.cells.short_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['short_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.short_name.editControl.readOnly || row.cells.short_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.short_name.editControl.placeholder}}"
            [ngStyle]="row.cells.short_name.editControl.styles.style"
            [ngClass]="row.cells.short_name.editControl.styles.classes"
            [matTooltip]="row.cells.short_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes"
          [matTooltip]="row.cells.length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['length_edit']"
            matInput
            numberBox
            [format]="row.cells.length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.length.editControl.readOnly || row.cells.length.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.length.editControl.placeholder}}"
            [ngStyle]="row.cells.length.editControl.styles.style"
            [ngClass]="row.cells.length.editControl.styles.classes"
            [matTooltip]="row.cells.length.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes"
          [matTooltip]="row.cells.width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['width_edit']"
            matInput
            numberBox
            [format]="row.cells.width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.width.editControl.readOnly || row.cells.width.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.width.editControl.placeholder}}"
            [ngStyle]="row.cells.width.editControl.styles.style"
            [ngClass]="row.cells.width.editControl.styles.classes"
            [matTooltip]="row.cells.width.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes"
          [matTooltip]="row.cells.height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['height_edit']"
            matInput
            numberBox
            [format]="row.cells.height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.height.editControl.readOnly || row.cells.height.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.height.editControl.placeholder}}"
            [ngStyle]="row.cells.height.editControl.styles.style"
            [ngClass]="row.cells.height.editControl.styles.classes"
            [matTooltip]="row.cells.height.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dimensions_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.dimensions_uom.displayControl.styles.style"
          [ngClass]="row.cells.dimensions_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.dimensions_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.dimensions_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.dimensions_uom.editControl.type"
        [formControl]="row.formGroup.controls['dimensions_uom_edit']"
        (displayTextChange)="row.cells.dimensions_uom.editControl.displayText=$event"
        [placeholder]="row.cells.dimensions_uom.editControl.placeholder"
        [styles]="row.cells.dimensions_uom.editControl.styles"
        [tooltip]="row.cells.dimensions_uom.editControl.tooltip"
      [typeId]="row.$fields_dimensions_uom_selector_inParams_typeId"
    >
    </Materials-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight.displayControl.styles.style"
          [ngClass]="row.cells.weight.displayControl.styles.classes"
          [matTooltip]="row.cells.weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['weight_edit']"
            matInput
            numberBox
            [format]="row.cells.weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.weight.editControl.readOnly || row.cells.weight.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight.editControl.placeholder}}"
            [ngStyle]="row.cells.weight.editControl.styles.style"
            [ngClass]="row.cells.weight.editControl.styles.classes"
            [matTooltip]="row.cells.weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipping_weight.displayControl.styles.style"
          [ngClass]="row.cells.shipping_weight.displayControl.styles.classes"
          [matTooltip]="row.cells.shipping_weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipping_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shipping_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.shipping_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.shipping_weight.editControl.readOnly || row.cells.shipping_weight.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipping_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.shipping_weight.editControl.styles.style"
            [ngClass]="row.cells.shipping_weight.editControl.styles.classes"
            [matTooltip]="row.cells.shipping_weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.weight_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.weight_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.weight_uom.editControl.type"
        [formControl]="row.formGroup.controls['weight_uom_edit']"
        (displayTextChange)="row.cells.weight_uom.editControl.displayText=$event"
        [placeholder]="row.cells.weight_uom.editControl.placeholder"
        [styles]="row.cells.weight_uom.editControl.styles"
        [tooltip]="row.cells.weight_uom.editControl.tooltip"
      [typeId]="row.$fields_weight_uom_selector_inParams_typeId"
    >
    </Materials-measurement_units_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
