import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ds_outbound_detail_pick_slip_reportService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, OrderId?: number, Shipment?: { Id?: number, ExpectedDate?: string, FobLocation?: string, LookupCode?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { LookupCode?: string }[], Warehouse?: { Name?: string } } }, ExpectedSourceLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ExpectedSourceLocation?: { Name?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, PickSlip?: { LookupCode?: string }, Lot?: { LookupCode?: string }, DimensionsUOM?: { Name?: string, Short_name?: string }, OrderLine?: { Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, Order?: { Id?: number, CreatedSysDateTime?: string, RequestedDeliveryDate?: string }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingVolume?: number, ShippingWeight?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number } }[] }> 
  {
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_outbound_detail_pick_slip_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, OrderId?: number, Shipment?: { Id?: number, ExpectedDate?: string, FobLocation?: string, LookupCode?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { LookupCode?: string }[], Warehouse?: { Name?: string } } }, ExpectedSourceLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ExpectedSourceLocation?: { Name?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, PickSlip?: { LookupCode?: string }, Lot?: { LookupCode?: string }, DimensionsUOM?: { Name?: string, Short_name?: string }, OrderLine?: { Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, Order?: { Id?: number, CreatedSysDateTime?: string, RequestedDeliveryDate?: string }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingVolume?: number, ShippingWeight?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_outbound_detail_pick_slip_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, OrderId?: number, Shipment?: { Id?: number, ExpectedDate?: string, FobLocation?: string, LookupCode?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { LookupCode?: string }[], Warehouse?: { Name?: string } } }, ExpectedSourceLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ExpectedSourceLocation?: { Name?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, PickSlip?: { LookupCode?: string }, Lot?: { LookupCode?: string }, DimensionsUOM?: { Name?: string, Short_name?: string }, OrderLine?: { Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, Order?: { Id?: number, CreatedSysDateTime?: string, RequestedDeliveryDate?: string }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingVolume?: number, ShippingWeight?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_outbound_detail_pick_slip_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId?: number    }) {
      return false;
    }
}
