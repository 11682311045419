import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_editor_ComponentContextService } from './FootprintManager.manufacturing_order_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_manufacturing_order_lines_gridComponent } from './FootprintManager.manufacturing_order_lines_grid.component';
import { FootprintManager_manufacturing_orders_manufacturing_production_gridComponent } from './FootprintManager.manufacturing_orders_manufacturing_production_grid.component';
import { Manufacturing_warehouses_dd_singleComponent } from './Manufacturing.warehouses_dd_single.component'
import { Manufacturing_owners_dd_singleComponent } from './Manufacturing.owners_dd_single.component'
import { Manufacturing_projects_dd_singleComponent } from './Manufacturing.projects_dd_single.component'
import { FootprintManager_manufacturing_staging_locations_dd_singleComponent } from './FootprintManager.manufacturing_staging_locations_dd_single.component'
import { FootprintManager_manufacturing_output_locations_dd_singleComponent } from './FootprintManager.manufacturing_output_locations_dd_single.component'

type EntityType = { 
    Id?: number, ActualCompleteOn?: string, ActualStartOn?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, DefaultStagingLocationId?: number, EstimatedCompleteOn?: string, EstimatedStartOn?: string, LookupCode?: string, ManufacturingLocationId?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ProjectId?: number, StatusId?: number, WarehouseId?: number, Project?: { OwnerId?: number }, Status?: { Name?: string, ShortName?: string }, Tasks?: { Id?: number, PickSlip?: { WaveId?: number } }[]}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_manufacturing_order_lines_gridComponent),
    forwardRef(() => FootprintManager_manufacturing_orders_manufacturing_production_gridComponent),
    forwardRef(() => Manufacturing_warehouses_dd_singleComponent),
    forwardRef(() => Manufacturing_owners_dd_singleComponent),
    forwardRef(() => Manufacturing_projects_dd_singleComponent),
    forwardRef(() => FootprintManager_manufacturing_staging_locations_dd_singleComponent),
    forwardRef(() => FootprintManager_manufacturing_output_locations_dd_singleComponent),
  ],
  selector: 'FootprintManager-manufacturing_order_editor',
  templateUrl: './FootprintManager.manufacturing_order_editor.component.html'
})
export class FootprintManager_manufacturing_order_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { manufacturing_order_id: number } = { manufacturing_order_id: null };
  //#region Inputs
  @Input('manufacturing_order_id') set $inParams_manufacturing_order_id(v: number) {
    this.inParams.manufacturing_order_id = v;
  }
  get $inParams_manufacturing_order_id(): number {
    return this.inParams.manufacturing_order_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    order_id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    empty: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    staging_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    output_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    estimated_start: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    estimated_end: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    actual_start_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_end_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    modified_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(['primary'], null), false, false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      copy_order: new ToolModel(new ButtonModel('copy_order', new ButtonStyles(null, null), false, false, false, 'Copy order', 'icon-ic_fluent_copy_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      attach: new ToolModel(new ButtonModel('attach', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_attach_20_regular', null)
    , false),
      printmain: new ToolModel(new SplitButtonModel<{ printsub: ButtonModel }>(
        'printmain',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'icon-ic_fluent_print_20_regular',
        null,
        [
          new ButtonModel('printsub', new ButtonStyles(null, null), false, false, false, 'Print Test', '')
        ])
    , false)
  };

  fields = {
    order_id: new FieldModel(new TextBoxModel(this.formGroup.controls['order_id'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Manufacturing order', false, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Customer', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    status: new FieldModel(new LabelModel(null, null, null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    empty: new FieldModel(new TextBoxModel(this.formGroup.controls['empty'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
    staging_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['staging_location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Staging location', false, false),
    output_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['output_location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Output location', false, false),
    estimated_start: new FieldModel(new DateBoxModel(this.formGroup.controls['estimated_start'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Estimated start date', true, false),
    estimated_end: new FieldModel(new DateBoxModel(this.formGroup.controls['estimated_end'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Estimated end date', true, false),
    actual_start_date: new FieldModel(new DateBoxModel(this.formGroup.controls['actual_start_date'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Actual start date', false, false),
    actual_end_date: new FieldModel(new DateBoxModel(this.formGroup.controls['actual_end_date'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'Actual end date', false, false),
    created_on: new FieldModel(new DateBoxModel(this.formGroup.controls['created_on'] as DatexFormControl, null, true, false, 'l, LT', 'date', null)
, new ControlContainerStyles(null, null), 'Created on', false, false),
    created_by: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Created by', false, false),
    modified_on: new FieldModel(new DateBoxModel(this.formGroup.controls['modified_on'] as DatexFormControl, null, true, false, 'l, LT', 'date', null)
, new ControlContainerStyles(null, null), 'Modified on', false, false),
    modified_by: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Modified by', false, false),
    pick_wave: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Pick wave', false, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
    order: new FieldsetModel(
      'Order',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    details: new FieldsetModel(
      'Details',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    notes: new FieldsetModel(
      'Notes',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      manufacturing_lines_grid: new TabItemModel(
        this.rootTabGroup, 
        'Manufacturing lines', 
        false,
        ),
      manufacturing_confirmed_productions: new TabItemModel(
        this.rootTabGroup, 
        'Confirmed production', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_order_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.ProjectId;
      
      return expr;
    }
  
    get $tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_order_status_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.StatusId;
      
      return expr;
    }
  
    get $tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.WarehouseId;
      
      return expr;
    }
  
    get $tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_output_location_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.output_location.control.value;
      
      return expr;
    }
  
    get $tabs_manufacturing_lines_grid_manufacturing_order_lines_grid_inParams_source_location_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.staging_location.control.value;
      
      return expr;
    }
  
    get $tabs_manufacturing_confirmed_productions_manufacturing_orders_manufacturing_production_grid_inParams_manufacturing_order_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.manufacturing_order_id;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_manufacturing_lines_grid', { read: FootprintManager_manufacturing_order_lines_gridComponent }) $tabs_manufacturing_lines_grid: FootprintManager_manufacturing_order_lines_gridComponent;
      @ViewChild('$tabs_manufacturing_confirmed_productions', { read: FootprintManager_manufacturing_orders_manufacturing_production_gridComponent }) $tabs_manufacturing_confirmed_productions: FootprintManager_manufacturing_orders_manufacturing_production_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_owner_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.entity.ProjectId;
    
    return expr;
  }

  get $fields_project_selector_inParams_status_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_owner_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  get $fields_staging_location_selector_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.entity.WarehouseId;
    
    return expr;
  }

  get $fields_staging_location_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.entity.ProjectId;
    
    return expr;
  }

  cacheValueFor_$fields_output_location_selector_inParams_warehouse_ids: number[];
  get $fields_output_location_selector_inParams_warehouse_ids(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = [$editor.entity.WarehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_output_location_selector_inParams_warehouse_ids, expr)) {
      this.cacheValueFor_$fields_output_location_selector_inParams_warehouse_ids = expr;
    }
    return this.cacheValueFor_$fields_output_location_selector_inParams_warehouse_ids;
  }

  get $fields_output_location_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.entity.ProjectId;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    order_id: this.fields.order_id.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    empty: this.fields.empty.control.valueChanges
    ,
    staging_location: this.fields.staging_location.control.valueChanges
    ,
    output_location: this.fields.output_location.control.valueChanges
    ,
    estimated_start: this.fields.estimated_start.control.valueChanges
    ,
    estimated_end: this.fields.estimated_end.control.valueChanges
    ,
    actual_start_date: this.fields.actual_start_date.control.valueChanges
    ,
    actual_end_date: this.fields.actual_end_date.control.valueChanges
    ,
    created_on: this.fields.created_on.control.valueChanges
    ,
    modified_on: this.fields.modified_on.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.manufacturing_lines_grid,
      this.tabs.manufacturing_confirmed_productions,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.manufacturing_order_id)) {
        this.$missingRequiredInParams.push('manufacturing_order_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Order editor';
    

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      order_id:  $editor.inParams.manufacturing_order_id 
    };

    const data = await this.$datasources.Manufacturing.ds_manufacturing_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.order_id.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.WarehouseId);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project?.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.ProjectId);
    (this.fields.status.control as LabelModel).label = $editor.entity.Status?.Name;
    
    (this.fields.staging_location.control as SelectBoxModel).reset($editor.entity.DefaultStagingLocationId);
    (this.fields.output_location.control as SelectBoxModel).reset($editor.entity.ManufacturingLocationId);
    (this.fields.estimated_start.control as DateBoxModel).reset($editor.entity.EstimatedStartOn);
    (this.fields.estimated_end.control as DateBoxModel).reset($editor.entity.EstimatedCompleteOn);
    (this.fields.actual_start_date.control as DateBoxModel).reset($editor.entity.ActualStartOn);
    (this.fields.actual_end_date.control as DateBoxModel).reset($editor.entity.ActualCompleteOn);
    (this.fields.created_on.control as DateBoxModel).reset($editor.entity.CreatedSysDateTime);
    (this.fields.created_by.control as TextModel).text = $editor.entity.CreatedSysUser;
    (this.fields.modified_on.control as DateBoxModel).reset($editor.entity.CreatedSysDateTime);
    (this.fields.modified_by.control as TextModel).text = $editor.entity.ModifiedSysUser;
    (this.fields.pick_wave.control as TextModel).text = $utils.isDefined($editor.entity.Tasks?.find(x => x.PickSlip?.WaveId > 0)?.PickSlip?.WaveId) ? $editor.entity.Tasks?.find(x => x.PickSlip?.WaveId > 0)?.PickSlip?.WaveId?.toString() : "";
    

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_manufacturing_lines_grid') {
        if (!isNil(this.$tabs_manufacturing_lines_grid) && !this.tabs.manufacturing_lines_grid.hidden && !this.tabs.manufacturing_lines_grid.removed) {
          this.$tabs_manufacturing_lines_grid.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_manufacturing_confirmed_productions') {
        if (!isNil(this.$tabs_manufacturing_confirmed_productions) && !this.tabs.manufacturing_confirmed_productions.hidden && !this.tabs.manufacturing_confirmed_productions.removed) {
          this.$tabs_manufacturing_confirmed_productions.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .order_id
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lookupcode_change();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .empty
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .staging_location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_staging_location_changed();
      });
    this.$formGroupFieldValidationObservables
      .output_location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_output_location_changed();
      });
    this.$formGroupFieldValidationObservables
      .estimated_start
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_change();
      });
    this.$formGroupFieldValidationObservables
      .estimated_end
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_change();
      });
    this.$formGroupFieldValidationObservables
      .actual_start_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_change();
      });
    this.$formGroupFieldValidationObservables
      .actual_end_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_date_change();
      });
    this.$formGroupFieldValidationObservables
      .created_on
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .modified_on
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_cancel_click(event = null) {
    return this.on_cancel_clickInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_cancel_click');
  const result = await $flows.Manufacturing.is_manufacturing_order_cancelable_flow({ 
      order_id: $editor.entity.Id});
  const reasons = result.reasons;
  if ($utils.isDefined(reasons)) {
      await $shell.Utilities.openErrorDialog('Order cannot be canceled.', `Order ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}.`);
      return;
  } else {
      const confirm = await $shell.Utilities.openConfirmationDialog(`Cancel order (${$editor.entity.LookupCode})?`, `Are you sure you want to cancel order ${$editor.entity.LookupCode}?`, 'Cancel order', 'Keep order');
      if (confirm) {
          try {
  
              const result = await $flows.Manufacturing.cancel_manufacturing_order_flow({ order_id: $editor.entity.Id });
              const reasons = result.reasons;
  
              if ($utils.isDefined(reasons)) {
                  await $shell.Utilities.openErrorDialog('Order cannot be cancelled.', `${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons})).formattedValue}.`);
                  return;
              }
              else {
  
                  const title = `Order ${$editor.entity.LookupCode} cancelled`;
                  $shell.Utilities.openToaster(title,'',EToasterType.Success,{timeOut: 5000, closeButton:true, positionClass:EToasterPosition.bottomRight});
                  await $editor.refresh();
              }
  
  
          } catch (error) {
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Order ${$editor.entity.LookupCode} - ${errorMessage}`;
              await $shell.Utilities.openErrorDialog('Cancel order error', 'An error occurred during the cancelling of the order', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
          }
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_data_loaded');
  if( $editor.entity.Tasks?.filter(x => x.PickSlip?.WaveId > 0).length <= 0 )
  {
      $editor.fields.pick_wave.hidden = true;
  }
  
  if( $utils.isDefined($editor.entity.Notes) )
  {
      $editor.fieldsets.notes.expanded = true;
  }
  $editor.title = `Order ${$editor.entity.LookupCode}`;
  $editor.can_be_processed();
  }
  on_attach_clicked(event = null) {
    return this.on_attach_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attach_clickedInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_attach_clicked');
  
  //await $shell.FootPrintManager.openentity_attachments_gridDialog({ entityType: 'ManufacturingOrder', entityKeys: [{ name: 'Id', value: $editor.entity.Id }]});
  }
  on_print_clicked(event = null) {
    return this.on_print_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_print_clickedInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_print_clicked');
  await $shell.Utilities.openInfoDialog('Not yet implemented', 'The print functionality for this screen has not yet been implemented.');
  }
  on_staging_location_changed(event = null) {
    return this.on_staging_location_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_staging_location_changedInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_staging_location_changed');
  const orderUpdate = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingOrders",
      id: $editor.entity.Id,
      entity:
      {
          DefaultStagingLocationId: $editor.fields.staging_location.control.value,
      }}));
  }
  on_output_location_changed(event = null) {
    return this.on_output_location_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_output_location_changedInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_output_location_changed');
  
  const orderLine = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingOrders",
      id: $editor.entity.Id,
      entity:
      {
          ManufacturingLocationId: $editor.fields.output_location.control.value,
      }}));
  }
  on_date_change(event = null) {
    return this.on_date_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_date_changeInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_date_change');
  
  const entity = 
      {
          EstimatedStartOn: $editor.fields.estimated_start.control.value,
          EstimatedCompleteOn: $editor.fields.estimated_end.control.value,
          ActualStartOn: $editor.fields.actual_start_date.control.value,
          ActualCompleteOn: $editor.fields.actual_end_date.control.value,
      };
  
  
  if (new Date(entity.EstimatedCompleteOn) < new Date(entity.EstimatedStartOn)
  || new Date(entity.ActualCompleteOn) < new Date(entity.ActualStartOn)) {
      return;
  }
  
  
  const orderUpdate = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingOrders",
      id: $editor.entity.Id,
      entity:
      {
          EstimatedStartOn: $editor.fields.estimated_start.control.value,
          EstimatedCompleteOn: $editor.fields.estimated_end.control.value,
          ActualStartOn: $editor.fields.actual_start_date.control.value,
          ActualCompleteOn: $editor.fields.actual_end_date.control.value,
      }
  }));
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_process_clicked');
  
  $shell.FootprintManager.openprocess_orderDialog({order_id: $editor.inParams.manufacturing_order_id}, "modal", EModalSize.Xlarge);
  }
  on_copy_clicked(event = null) {
    return this.on_copy_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_copy_clickedInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_copy_clicked');
  
  const new_order = await $flows.FootprintManager.create_manufacturing_order_copies({
      template_order_id: $editor.entity.Id,
      copies_params: {
          offset_in_days: 0,
          total_runs: 1
      },
      name: '',
      pre_requirements: {}
  });
  
  if (new_order.order_ids.length) {
      const t = await $shell.FootprintManager.openInfoDialog(
          `Copy order (${$editor.entity.LookupCode})`,
          `Created new order (${$editor.entity.LookupCode})`);
      $editor.close();
      $shell.FootprintManager.openmanufacturing_order_editor({ manufacturing_order_id: new_order.order_ids[0] });
  }
  }
  on_lookupcode_change(event = null) {
    return this.on_lookupcode_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_changeInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.on_lookupcode_change');
  
  const orderUpdate = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingOrders",
      id: $editor.entity.Id,
      entity:
      {
          LookupCode: $editor.fields.order_id.control.value
      }
  }));
  }
  can_be_processed(event = null) {
    return this.can_be_processedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async can_be_processedInternal(
    $editor: FootprintManager_manufacturing_order_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_editor.can_be_processed');
  
  const components_for_process = await $datasources.Manufacturing.ds_get_components_for_process_order.getList({order_id: $editor.entity.Id, manufacturing_warehouse_id: $editor.entity.WarehouseId});
  const can_be_processed = components_for_process.result.length > 0;
  
  $editor.toolbar.process.control.disabled = !can_be_processed;
  }
  //#endregion private flows
  
}
