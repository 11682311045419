import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Dynamics365BusinessCentral_orders_fail_count_ComponentContextService } from './Dynamics365BusinessCentral.orders_fail_count.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Dynamics365BusinessCentral-orders_fail_count',
  templateUrl: './Dynamics365BusinessCentral.orders_fail_count.component.html'
})
export class Dynamics365BusinessCentral_orders_fail_countComponent extends BaseComponent implements OnInit {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs


  //#region Variables
  //#endregion  
  private entity: { res?: string };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Dynamics365BusinessCentral_ShellService,
private $datasources: Dynamics365BusinessCentral_DatasourceService,
private $flows: Dynamics365BusinessCentral_FlowService,
private $reports: Dynamics365BusinessCentral_ReportService,
private $localization: Dynamics365BusinessCentral_LocalizationService,
private $operations: Dynamics365BusinessCentral_OperationService,
private $logger: CleanupLoggerService,
private $context: Dynamics365BusinessCentral_orders_fail_count_ComponentContextService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.$utils;

    const dsParams = {
    };

    const data = await this.$datasources.Dynamics365BusinessCentral.ds_orders_count_errors_today.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.$utils;

    this.value = Number($widget.entity.res) ?? 0;

    
    this.originalValue = Number($widget.entity.res) ?? 0;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
