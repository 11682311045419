<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="refresh" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_refresh_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="lookup_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookup_code.displayControl.styles.style"
          [ngClass]="row.cells.lookup_code.displayControl.styles.classes"
          [matTooltip]="row.cells.lookup_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookup_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse_name.displayControl.styles.style"
          [ngClass]="row.cells.warehouse_name.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner_name.displayControl.styles.style"
          [ngClass]="row.cells.owner_name.displayControl.styles.classes"
          [matTooltip]="row.cells.owner_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project_id.displayControl.styles.style"
          [ngClass]="row.cells.project_id.displayControl.styles.classes"
          [matTooltip]="row.cells.project_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="account_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.account_name.displayControl.styles.style"
          [ngClass]="row.cells.account_name.displayControl.styles.classes"
          [matTooltip]="row.cells.account_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.account_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="destination">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.destination.displayControl.styles.style"
          [ngClass]="row.cells.destination.displayControl.styles.classes"
          [matTooltip]="row.cells.destination.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.destination.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_days">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipping_days.displayControl.styles.style"
          [ngClass]="row.cells.shipping_days.displayControl.styles.classes"
          [matTooltip]="row.cells.shipping_days.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipping_days.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="delivery_days">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.delivery_days.displayControl.styles.style"
          [ngClass]="row.cells.delivery_days.displayControl.styles.classes"
          [matTooltip]="row.cells.delivery_days.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.delivery_days.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <Invoices-freight_billing_details_grid
    [lookupcode]="row.$rowExpand_Invoices_freight_billing_details_grid_inParams_lookupcode"
    ($refreshEvent)="row.refresh()">>
  </Invoices-freight_billing_details_grid>
</ng-template>

</datex-grid>
