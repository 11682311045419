import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_get_shipmentline_by_shipmentlineId_detailedService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentlineId: number }): 
  Promise<{ result: { Id?: number, ExpectedAmount?: number, ExpectedPackagedAmount?: number, LineNumber?: number, OrderId?: number, OrderLineNumber?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, ExpectedWarehouseId?: number }, OrderLine?: { LineNumber?: number, Order?: { Id?: number, ProjectId?: number } }, Tasks?: { OperationCodeId?: number, PickSlip?: { Id?: number, StatusId?: number, Wave?: { Id?: number, StatusId?: number } } }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentlineId)) {
      missingRequiredInParams.push('\'shipmentlineId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_shipmentline_by_shipmentlineId_detailed/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipmentlineId: number    }) {
        if(isNil(inParams.shipmentlineId)) {
          return true; 
        }
      return false;
    }
}
