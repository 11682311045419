import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_serialnumbers_modification_grid_ComponentContextService } from './FootprintManager.serialnumbers_modification_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Inventory_measurement_units_dd_singleComponent } from './Inventory.measurement_units_dd_single.component'


interface IFootprintManager_serialnumbers_modification_gridComponentEntity {
  GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, DimensionUOM?: number}

interface IFootprintManager_serialnumbers_modification_gridComponentInParams {
  serial_number_request?: { GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, DimensionsUOM?: number, WeightUOM?: number, Width?: number }[], packagedAmountRequest?: number}

interface IFootprintManager_serialnumbers_modification_gridComponentOutParams {
  serial_number_request?: { GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, DimensionUOM?: number }[], confirm?: boolean}

class FootprintManager_serialnumbers_modification_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_serialnumbers_modification_gridComponent;
  entity: IFootprintManager_serialnumbers_modification_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    uom_weight_unit_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    uom_dimensions_unit_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    serial_lookupcode: null,
    net_weight: null,
    gross_weight: null,
    uom_weight_unit: null,
    length: null,
    width: null,
    height: null,
    uom_dimensions_unit: null,
  }



  constructor(
    grid: FootprintManager_serialnumbers_modification_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_serialnumbers_modification_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.serial_lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.serial_lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.net_weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.net_weight,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.gross_weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.gross_weight,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.uom_weight_unit = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.uom_weight_unit,
      new SelectBoxModel(
  this.formGroup.controls['uom_weight_unit_display'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
,
null
      );
    
    this.cells.length = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.length,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.width = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.width,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.height = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.height,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.uom_dimensions_unit = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.uom_dimensions_unit,
      new SelectBoxModel(
  this.formGroup.controls['uom_dimensions_unit_display'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_serialnumbers_modification_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.LookupCode].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_serialnumbers_modification_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.LookupCode].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.LookupCode;
    const inParams = {
      $keys:[$resultKey],
      serial_number_request:  $grid.inParams.serial_number_request ,
    };
    const data = await this.$datasources.InventoryTransformations.ds_serialnumbers_modification_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_serialnumbers_modification_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.serial_lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.net_weight.displayControl as TextModel).text = $row.entity.NetWeightValue?.toString();
    (this.cells.gross_weight.displayControl as TextModel).text = $row.entity.GrossWeightValue?.toString();
    (this.cells.uom_weight_unit.displayControl as SelectBoxModel).reset($row.entity.WeightUOM);
    (this.cells.length.displayControl as TextModel).text = $row.entity.Length?.toString();
    (this.cells.width.displayControl as TextModel).text = $row.entity.Width?.toString();
    (this.cells.height.displayControl as TextModel).text = $row.entity.Height?.toString();
    (this.cells.uom_dimensions_unit.displayControl as SelectBoxModel).reset($row.entity.DimensionUOM);

    await this.row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  row_data_loaded(event = null) {
    return this.row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async row_data_loadedInternal(
    $row: FootprintManager_serialnumbers_modification_gridComponentRowModel,
  $grid: FootprintManager_serialnumbers_modification_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_modification_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'serialnumbers_modification_grid.row_data_loaded');
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Inventory_measurement_units_dd_singleComponent),
  ],
  selector: 'FootprintManager-serialnumbers_modification_grid',
  templateUrl: './FootprintManager.serialnumbers_modification_grid.component.html'
})
export class FootprintManager_serialnumbers_modification_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_serialnumbers_modification_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_serialnumbers_modification_gridComponentInParams = { serial_number_request: [], packagedAmountRequest: null };

  outParams: IFootprintManager_serialnumbers_modification_gridComponentOutParams = { serial_number_request: [], confirm: null };

  //#region Variables
  vars: { rowSelectedExecutionCount?: number, rowSelectedExecutionBase?: number, selectedSerialNumbers?: { lookupCode?: string }[] } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ serial_number_request?: { GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, DimensionUOM?: number }[], confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     serial_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'serial_lookupcode', 'Serial number', false, false, null, false),       net_weight: new GridHeaderModel(new HeaderStyles(null, null), 'net_weight', 'Net', false, false, null, false),       gross_weight: new GridHeaderModel(new HeaderStyles(null, null), 'gross_weight', 'Gross', false, false, null, false),       uom_weight_unit: new GridHeaderModel(new HeaderStyles(null, null), 'uom_weight_unit', 'UOM', false, false, null, false),       length: new GridHeaderModel(new HeaderStyles(null, null), 'length', 'L', false, false, null, false),       width: new GridHeaderModel(new HeaderStyles(null, null), 'width', 'W', false, false, null, false),       height: new GridHeaderModel(new HeaderStyles(null, null), 'height', 'H', false, false, null, false),       uom_dimensions_unit: new GridHeaderModel(new HeaderStyles(null, null), 'uom_dimensions_unit', 'UOM', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_serialnumbers_modification_gridComponentRowModel[] = [];

  @Input('serial_number_request') set $inParams_serial_number_request(value: any) {
    this.inParams['serial_number_request'] = value;
  }
  get $inParams_serial_number_request(): any {
    return this.inParams['serial_number_request'] ;
  }
  @Input('packagedAmountRequest') set $inParams_packagedAmountRequest(value: any) {
    this.inParams['packagedAmountRequest'] = value;
  }
  get $inParams_packagedAmountRequest(): any {
    return this.inParams['packagedAmountRequest'] ;
  }

  topToolbar = {
      select: new ToolModel(new ButtonModel('select', new ButtonStyles(['primary'], null), false, false, false, 'Select', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_serialnumbers_modification_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Select serial numbers';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 100;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      serial_number_request:  $grid.inParams.serial_number_request ,
    };
    try {
      const data = await this.$datasources.InventoryTransformations.ds_serialnumbers_modification_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_serialnumbers_modification_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_serialnumbers_modification_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_serialnumbers_modification_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootprintManager_serialnumbers_modification_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_modification_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'serialnumbers_modification_grid.on_cancel_clicked');
  
  $grid.outParams.confirm = false
  $grid.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_serialnumbers_modification_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_modification_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'serialnumbers_modification_grid.on_init');
  $grid.outParams.confirm = false;
  $grid.topToolbar.select.control.readOnly = true;
  //$grid.vars.selectedSerialNumbers = []
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_serialnumbers_modification_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_modification_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'serialnumbers_modification_grid.on_row_selected');
  /*if ($grid.rows.length > 0) { $grid.vars.rowSelectedExecutionCount++; }
  
  
  if ($grid.vars.rowSelectedExecutionCount > $grid.vars.rowSelectedExecutionBase) {
      for (let row of $grid.rows) {
          let foundIndex = $grid.vars.selectedSerialNumbers.findIndex(sn => sn.lookupCode === row.entity.LookupCode);
  
          if (row.selected && foundIndex < 0) {
              $grid.vars.selectedSerialNumbers.push({lookupCode: row.entity.LookupCode });
          }
          else if (!row.selected && foundIndex >= 0) {
              $grid.vars.selectedSerialNumbers.splice(foundIndex, 1);
          }
      }
  }
  else if ($grid.vars.rowSelectedExecutionCount === $grid.pageSize + 1) {
      for (let row of $grid.rows) {
          if ($grid.vars.selectedSerialNumbers.findIndex(sn => sn.lookupCode === row.entity.LookupCode) >= 0) {
              row.selected = true;
          }
      }
  }
  
  
  // Top Toolbar
  $grid.topToolbar.select.control.label = `Selected ${$grid.vars.selectedSerialNumbers.length} of ${$grid.inParams.packagedAmountRequest}`
  
  if ($grid.vars.selectedSerialNumbers.length == $grid.inParams.packagedAmountRequest) {
      $grid.topToolbar.select.control.readOnly = false;
  }
  else {
      $grid.topToolbar.select.control.readOnly = true;
  }
  */
  
  // Top Toolbar
  $grid.topToolbar.select.control.label = `Selected ${$grid.selectedRows.length} of ${$grid.inParams.packagedAmountRequest}`
  
  if ($grid.selectedRows.length == $grid.inParams.packagedAmountRequest) {
      $grid.topToolbar.select.control.readOnly = false;
  }
  else {
      $grid.topToolbar.select.control.readOnly = true;
  }
  }
  on_select_clicked(event = null) {
    return this.on_select_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_select_clickedInternal(
    $grid: FootprintManager_serialnumbers_modification_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_modification_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'serialnumbers_modification_grid.on_select_clicked');
  //$grid.outParams.SerialNumberIds = $grid.vars.selectedSerialNumbers.map(sn => sn.id);
  
  
  
  interface SerialNumberRequest { test: number }
  
  
  
  
  
  
  $grid.outParams.serial_number_request = $grid.selectedRows.map(s => ({
      GrossVolumeValue: s.entity.GrossVolumeValue,
      GrossWeightValue: s.entity.GrossWeightValue,
      Height: s.entity.Height,
      Length: s.entity.Length,
      LookupCode: s.entity.LookupCode,
      NetVolumeValue: s.entity.NetVolumeValue,
      NetWeightValue: s.entity.NetWeightValue,
      VolumeUOM: s.entity.VolumeUOM,
      WeightUOM: s.entity.WeightUOM,
      Width: s.entity.Width,
      DimensionUOM: s.entity.DimensionUOM,
  }))
  
  
  $grid.outParams.confirm = true;
  $grid.close();
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_serialnumbers_modification_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_serialnumbers_modification_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'serialnumbers_modification_grid.on_grid_data_loaded');
  
  
  
  /*
  let selectedRowCount = $grid.rows.filter(row => $grid.vars.selectedSerialNumbers.find(sn => sn.lookupCode === row.entity.LookupCode)).length;
  
  $grid.vars.rowSelectedExecutionCount = 1;
  $grid.vars.rowSelectedExecutionBase = $grid.rows.length + selectedRowCount + 1;
  */
  for (let i=0; i<$grid.inParams.packagedAmountRequest; i++)
  {
  
      $grid.rows[i].selected = true
  
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
