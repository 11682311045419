import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Inventory.frontend.types'

@Injectable({ providedIn: 'root' })
export class Inventory_create_licenseplate_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { archived?: boolean, dimensionsUomId: number, grossVolume?: number, grossWeight?: number, height?: number, length?: number, licensePlateContainerTypeId?: number, locationId: number, lookupCode?: string, maximumWeight?: number, netVolume?: number, netWeight?: number, parentId?: number, shipmentId?: number, shippingContainerId?: number, statusId: number, typeId: number, volumeUomId: number, warehouseId: number, weightUomId: number, width?: number }): Promise<{ reasons?: string[], licensePlateId?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.dimensionsUomId)) {
      missingRequiredInParams.push('\'dimensionsUomId\'');
    }
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (isNil(inParams.statusId)) {
      missingRequiredInParams.push('\'statusId\'');
    }
    if (isNil(inParams.typeId)) {
      missingRequiredInParams.push('\'typeId\'');
    }
    if (isNil(inParams.volumeUomId)) {
      missingRequiredInParams.push('\'volumeUomId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.weightUomId)) {
      missingRequiredInParams.push('\'weightUomId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/functions/create_licenseplate_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

