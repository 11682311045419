import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Manufacturing_manufacturing_library_homeComponent } from './Manufacturing.manufacturing_library_home.component';
import { Manufacturing_workshift_formComponent } from './Manufacturing.workshift_form.component';
import { Manufacturing_serialnumbers_creation_gridComponent } from './Manufacturing.serialnumbers_creation_grid.component';
import { Manufacturing_manufacturing_order_reportComponent } from './Manufacturing.manufacturing_order_report.component';
import { Manufacturing_testRepComponent } from './Manufacturing.testRep.component';
import { Manufacturing_days_dd_singleComponent } from './Manufacturing.days_dd_single.component';
import { Manufacturing_license_plates_by_location_with_inventory_singleComponent } from './Manufacturing.license_plates_by_location_with_inventory_single.component';
import { Manufacturing_licenseplates_by_location_singleComponent } from './Manufacturing.licenseplates_by_location_single.component';
import { Manufacturing_lots_by_material_ids_singleComponent } from './Manufacturing.lots_by_material_ids_single.component';
import { Manufacturing_lots_with_inventory_by_license_plate_dd_singleComponent } from './Manufacturing.lots_with_inventory_by_license_plate_dd_single.component';
import { Manufacturing_manufacturing_order_date_types_dd_singleComponent } from './Manufacturing.manufacturing_order_date_types_dd_single.component';
import { Manufacturing_manufacturing_orders_equipment_dd_singleComponent } from './Manufacturing.manufacturing_orders_equipment_dd_single.component';
import { Manufacturing_manufacturing_orders_workshifts_dd_singleComponent } from './Manufacturing.manufacturing_orders_workshifts_dd_single.component';
import { Manufacturing_manufacturnig_order_production_confirmation_dd_singleComponent } from './Manufacturing.manufacturnig_order_production_confirmation_dd_single.component';
import { Manufacturing_material_packagings_dd_singleComponent } from './Manufacturing.material_packagings_dd_single.component';
import { Manufacturing_material_substitutions_dd_singleComponent } from './Manufacturing.material_substitutions_dd_single.component';
import { Manufacturing_materials_by_project_dd_singleComponent } from './Manufacturing.materials_by_project_dd_single.component';
import { Manufacturing_measurement_units_dd_singleComponent } from './Manufacturing.measurement_units_dd_single.component';
import { Manufacturing_order_statuses_dd_singleComponent } from './Manufacturing.order_statuses_dd_single.component';
import { Manufacturing_orders_dd_singleComponent } from './Manufacturing.orders_dd_single.component';
import { Manufacturing_owners_dd_singleComponent } from './Manufacturing.owners_dd_single.component';
import { Manufacturing_projects_dd_singleComponent } from './Manufacturing.projects_dd_single.component';
import { Manufacturing_time_selector_dd_singleComponent } from './Manufacturing.time_selector_dd_single.component';
import { Manufacturing_warehouse_dd_singleComponent } from './Manufacturing.warehouse_dd_single.component';
import { Manufacturing_warehouses_dd_singleComponent } from './Manufacturing.warehouses_dd_single.component';
import { Manufacturing_days_dd_multiComponent } from './Manufacturing.days_dd_multi.component';
import { Manufacturing_license_plates_by_location_with_inventory_multiComponent } from './Manufacturing.license_plates_by_location_with_inventory_multi.component';
import { Manufacturing_licenseplates_by_location_multiComponent } from './Manufacturing.licenseplates_by_location_multi.component';
import { Manufacturing_lots_by_material_ids_multiComponent } from './Manufacturing.lots_by_material_ids_multi.component';
import { Manufacturing_lots_with_inventory_by_license_plate_dd_multiComponent } from './Manufacturing.lots_with_inventory_by_license_plate_dd_multi.component';
import { Manufacturing_manufacturing_order_date_types_dd_multiComponent } from './Manufacturing.manufacturing_order_date_types_dd_multi.component';
import { Manufacturing_manufacturing_orders_equipment_dd_multiComponent } from './Manufacturing.manufacturing_orders_equipment_dd_multi.component';
import { Manufacturing_manufacturing_orders_workshifts_dd_multiComponent } from './Manufacturing.manufacturing_orders_workshifts_dd_multi.component';
import { Manufacturing_manufacturnig_order_production_confirmation_dd_multiComponent } from './Manufacturing.manufacturnig_order_production_confirmation_dd_multi.component';
import { Manufacturing_material_packagings_dd_multiComponent } from './Manufacturing.material_packagings_dd_multi.component';
import { Manufacturing_material_substitutions_dd_multiComponent } from './Manufacturing.material_substitutions_dd_multi.component';
import { Manufacturing_materials_by_project_dd_multiComponent } from './Manufacturing.materials_by_project_dd_multi.component';
import { Manufacturing_measurement_units_dd_multiComponent } from './Manufacturing.measurement_units_dd_multi.component';
import { Manufacturing_order_statuses_dd_multiComponent } from './Manufacturing.order_statuses_dd_multi.component';
import { Manufacturing_orders_dd_multiComponent } from './Manufacturing.orders_dd_multi.component';
import { Manufacturing_owners_dd_multiComponent } from './Manufacturing.owners_dd_multi.component';
import { Manufacturing_projects_dd_multiComponent } from './Manufacturing.projects_dd_multi.component';
import { Manufacturing_time_selector_dd_multiComponent } from './Manufacturing.time_selector_dd_multi.component';
import { Manufacturing_warehouse_dd_multiComponent } from './Manufacturing.warehouse_dd_multi.component';
import { Manufacturing_warehouses_dd_multiComponent } from './Manufacturing.warehouses_dd_multi.component';
import { Manufacturing_pending_confirmation_widgetComponent } from './Manufacturing.pending_confirmation_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Manufacturing: Manufacturing_ShellService = this;

  public openmanufacturing_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Manufacturing_manufacturing_library_home',
        component: Manufacturing_manufacturing_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanufacturing_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_manufacturing_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openworkshift_form(inParams:{ warehouse_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'workshift_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'workshift_form',
        referenceName: 'Manufacturing_workshift_form',
        component: Manufacturing_workshift_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openworkshift_formDialog(
    inParams:{ warehouse_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'workshift_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_workshift_formComponent,
      'workshift_form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openserialnumbers_creation_grid(inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number, close_grid_on_confirm: boolean }, replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'serialnumbers_creation_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Create serial numbers',
        referenceName: 'Manufacturing_serialnumbers_creation_grid',
        component: Manufacturing_serialnumbers_creation_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openserialnumbers_creation_gridDialog(
    inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number, close_grid_on_confirm: boolean }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ serialNumbers?: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number, LicensePlateId?: number, LotId?: number }[], confirm?: boolean }> {
    this.$logger.log('Manufacturing', 'serialnumbers_creation_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Manufacturing_serialnumbers_creation_gridComponent,
      'Create serial numbers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmanufacturing_order_report(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'manufacturing_order_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'manufacturing_order_report',
        referenceName: 'Manufacturing_manufacturing_order_report',
        component: Manufacturing_manufacturing_order_reportComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmanufacturing_order_reportDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'manufacturing_order_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Manufacturing_manufacturing_order_reportComponent,
      'manufacturing_order_report',
      mode,
      dialogSize
    )
  }
  public opentestRep(replaceCurrentView?: boolean) {
    this.$logger.log('Manufacturing', 'testRep');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'testRep',
        referenceName: 'Manufacturing_testRep',
        component: Manufacturing_testRepComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opentestRepDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Manufacturing', 'testRep');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Manufacturing_testRepComponent,
      'testRep',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Manufacturing_manufacturing_library_home') {
      this.$logger.log('Manufacturing', 'manufacturing_library_home');
      const title = 'Home';
      const component = Manufacturing_manufacturing_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_workshift_form') {
      this.$logger.log('Manufacturing', 'workshift_form');
      const title = 'workshift_form';
      const component = Manufacturing_workshift_formComponent;
      const inParams:{ warehouse_id: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_serialnumbers_creation_grid') {
      this.$logger.log('Manufacturing', 'serialnumbers_creation_grid');
      const title = 'Create serial numbers';
      const component = Manufacturing_serialnumbers_creation_gridComponent;
      const inParams:{ materialId: number, packagingId: number, packagedAmount: number, averageGross?: number, averageNet?: number, weightUomId?: number, averageLength?: number, averageWidth?: number, averageHeight?: number, dimensionUomId?: number, lotId?: number, licensePlateId?: number, close_grid_on_confirm: boolean } = { materialId: null, packagingId: null, packagedAmount: null, averageGross: null, averageNet: null, weightUomId: null, averageLength: null, averageWidth: null, averageHeight: null, dimensionUomId: null, lotId: null, licensePlateId: null, close_grid_on_confirm: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagingId'))) {
        const paramValueString = params.get('packagingId');
        inParams.packagingId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('packagedAmount'))) {
        const paramValueString = params.get('packagedAmount');
        inParams.packagedAmount = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageGross'))) {
        const paramValueString = params.get('averageGross');
        inParams.averageGross = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageNet'))) {
        const paramValueString = params.get('averageNet');
        inParams.averageNet = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('weightUomId'))) {
        const paramValueString = params.get('weightUomId');
        inParams.weightUomId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageLength'))) {
        const paramValueString = params.get('averageLength');
        inParams.averageLength = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageWidth'))) {
        const paramValueString = params.get('averageWidth');
        inParams.averageWidth = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('averageHeight'))) {
        const paramValueString = params.get('averageHeight');
        inParams.averageHeight = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('dimensionUomId'))) {
        const paramValueString = params.get('dimensionUomId');
        inParams.dimensionUomId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('licensePlateId'))) {
        const paramValueString = params.get('licensePlateId');
        inParams.licensePlateId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('close_grid_on_confirm'))) {
        const paramValueString = params.get('close_grid_on_confirm');
        inParams.close_grid_on_confirm = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_report') {
      this.$logger.log('Manufacturing', 'manufacturing_order_report');
      const title = 'manufacturing_order_report';
      const component = Manufacturing_manufacturing_order_reportComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_testRep') {
      this.$logger.log('Manufacturing', 'testRep');
      const title = 'testRep';
      const component = Manufacturing_testRepComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_days_dd_single') {
      this.$logger.log('Manufacturing', 'days_dd_single');
      const title = 'days_dd';
      const component = Manufacturing_days_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_license_plates_by_location_with_inventory_single') {
      this.$logger.log('Manufacturing', 'license_plates_by_location_with_inventory_single');
      const title = 'license_plates_by_location_with_inventory';
      const component = Manufacturing_license_plates_by_location_with_inventory_singleComponent;
      const inParams:{ material_id?: number, project_id?: number, location_id?: number, license_plate?: number } = { material_id: null, project_id: null, location_id: null, license_plate: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_licenseplates_by_location_single') {
      this.$logger.log('Manufacturing', 'licenseplates_by_location_single');
      const title = 'licenseplates_by_location';
      const component = Manufacturing_licenseplates_by_location_singleComponent;
      const inParams:{ location_id?: number, license_plate_ids?: number[] } = { location_id: null, license_plate_ids: [] };
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate_ids'))) {
        const paramValueString = params.get('license_plate_ids');
        inParams.license_plate_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_lots_by_material_ids_single') {
      this.$logger.log('Manufacturing', 'lots_by_material_ids_single');
      const title = 'lots_by_material_ids';
      const component = Manufacturing_lots_by_material_ids_singleComponent;
      const inParams:{ material_ids?: number[], lot_id?: number } = { material_ids: [], lot_id: null };
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_lots_with_inventory_by_license_plate_dd_single') {
      this.$logger.log('Manufacturing', 'lots_with_inventory_by_license_plate_dd_single');
      const title = 'lots_with_inventory_by_license_plate_dd';
      const component = Manufacturing_lots_with_inventory_by_license_plate_dd_singleComponent;
      const inParams:{ material_id?: number, license_plate?: number, location?: number, package_id?: number, warehouse_id?: number, lot_id?: number } = { material_id: null, license_plate: null, location: null, package_id: null, warehouse_id: null, lot_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location'))) {
        const paramValueString = params.get('location');
        inParams.location = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_date_types_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturing_order_date_types_dd_single');
      const title = 'manufacturing_order_date_types_dd';
      const component = Manufacturing_manufacturing_order_date_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_equipment_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_equipment_dd_single');
      const title = 'manufacturing_orders_equipment_dd';
      const component = Manufacturing_manufacturing_orders_equipment_dd_singleComponent;
      const inParams:{ warehouse_id?: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_workshifts_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_workshifts_dd_single');
      const title = 'manufacturing_orders_workshifts_dd';
      const component = Manufacturing_manufacturing_orders_workshifts_dd_singleComponent;
      const inParams:{ warehouse_id?: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturnig_order_production_confirmation_dd_single') {
      this.$logger.log('Manufacturing', 'manufacturnig_order_production_confirmation_dd_single');
      const title = 'manufacturnig_order_production_confirmation_dd';
      const component = Manufacturing_manufacturnig_order_production_confirmation_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_material_packagings_dd_single') {
      this.$logger.log('Manufacturing', 'material_packagings_dd_single');
      const title = 'material_packagings_dd';
      const component = Manufacturing_material_packagings_dd_singleComponent;
      const inParams:{ material_id?: number } = { material_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_material_substitutions_dd_single') {
      this.$logger.log('Manufacturing', 'material_substitutions_dd_single');
      const title = 'material_substitutions_dd';
      const component = Manufacturing_material_substitutions_dd_singleComponent;
      const inParams:{ material_ids?: number[], package_id?: number, warehouse_id?: number, substitution_ids?: number[], needed_amount?: number } = { material_ids: [], package_id: null, warehouse_id: null, substitution_ids: [], needed_amount: null };
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('substitution_ids'))) {
        const paramValueString = params.get('substitution_ids');
        inParams.substitution_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('needed_amount'))) {
        const paramValueString = params.get('needed_amount');
        inParams.needed_amount = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_materials_by_project_dd_single') {
      this.$logger.log('Manufacturing', 'materials_by_project_dd_single');
      const title = 'materials_by_project_dd';
      const component = Manufacturing_materials_by_project_dd_singleComponent;
      const inParams:{ project_id: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_measurement_units_dd_single') {
      this.$logger.log('Manufacturing', 'measurement_units_dd_single');
      const title = 'measurement_units_dd';
      const component = Manufacturing_measurement_units_dd_singleComponent;
      const inParams:{ type_id?: number } = { type_id: null };
      if (!isNil(params.get('type_id'))) {
        const paramValueString = params.get('type_id');
        inParams.type_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_order_statuses_dd_single') {
      this.$logger.log('Manufacturing', 'order_statuses_dd_single');
      const title = 'order_statuses_dd';
      const component = Manufacturing_order_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_orders_dd_single') {
      this.$logger.log('Manufacturing', 'orders_dd_single');
      const title = 'orders_dd';
      const component = Manufacturing_orders_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_owners_dd_single') {
      this.$logger.log('Manufacturing', 'owners_dd_single');
      const title = 'owners_dd';
      const component = Manufacturing_owners_dd_singleComponent;
      const inParams:{ project_id?: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_projects_dd_single') {
      this.$logger.log('Manufacturing', 'projects_dd_single');
      const title = 'projects_dd';
      const component = Manufacturing_projects_dd_singleComponent;
      const inParams:{ status_id?: number, owner_id?: number } = { status_id: null, owner_id: null };
      if (!isNil(params.get('status_id'))) {
        const paramValueString = params.get('status_id');
        inParams.status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_time_selector_dd_single') {
      this.$logger.log('Manufacturing', 'time_selector_dd_single');
      const title = 'time_selector_dd';
      const component = Manufacturing_time_selector_dd_singleComponent;
      const inParams:{ intervalMinutes: number } = { intervalMinutes: null };
      if (!isNil(params.get('intervalMinutes'))) {
        const paramValueString = params.get('intervalMinutes');
        inParams.intervalMinutes = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_warehouse_dd_single') {
      this.$logger.log('Manufacturing', 'warehouse_dd_single');
      const title = 'warehouse_dd';
      const component = Manufacturing_warehouse_dd_singleComponent;
      const inParams:{ warehouse_id: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_warehouses_dd_single') {
      this.$logger.log('Manufacturing', 'warehouses_dd_single');
      const title = 'warehouses_dd';
      const component = Manufacturing_warehouses_dd_singleComponent;
      const inParams:{ warehouse_ids?: number[] } = { warehouse_ids: [] };
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_days_dd_multi') {
      this.$logger.log('Manufacturing', 'days_dd_multi');
      const title = 'days_dd';
      const component = Manufacturing_days_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_license_plates_by_location_with_inventory_multi') {
      this.$logger.log('Manufacturing', 'license_plates_by_location_with_inventory_multi');
      const title = 'license_plates_by_location_with_inventory';
      const component = Manufacturing_license_plates_by_location_with_inventory_multiComponent;
      const inParams:{ material_id?: number, project_id?: number, location_id?: number, license_plate?: number } = { material_id: null, project_id: null, location_id: null, license_plate: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_licenseplates_by_location_multi') {
      this.$logger.log('Manufacturing', 'licenseplates_by_location_multi');
      const title = 'licenseplates_by_location';
      const component = Manufacturing_licenseplates_by_location_multiComponent;
      const inParams:{ location_id?: number, license_plate_ids?: number[] } = { location_id: null, license_plate_ids: [] };
      if (!isNil(params.get('location_id'))) {
        const paramValueString = params.get('location_id');
        inParams.location_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate_ids'))) {
        const paramValueString = params.get('license_plate_ids');
        inParams.license_plate_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_lots_by_material_ids_multi') {
      this.$logger.log('Manufacturing', 'lots_by_material_ids_multi');
      const title = 'lots_by_material_ids';
      const component = Manufacturing_lots_by_material_ids_multiComponent;
      const inParams:{ material_ids?: number[], lot_id?: number } = { material_ids: [], lot_id: null };
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_lots_with_inventory_by_license_plate_dd_multi') {
      this.$logger.log('Manufacturing', 'lots_with_inventory_by_license_plate_dd_multi');
      const title = 'lots_with_inventory_by_license_plate_dd';
      const component = Manufacturing_lots_with_inventory_by_license_plate_dd_multiComponent;
      const inParams:{ material_id?: number, license_plate?: number, location?: number, package_id?: number, warehouse_id?: number, lot_id?: number } = { material_id: null, license_plate: null, location: null, package_id: null, warehouse_id: null, lot_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('license_plate'))) {
        const paramValueString = params.get('license_plate');
        inParams.license_plate = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('location'))) {
        const paramValueString = params.get('location');
        inParams.location = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lot_id'))) {
        const paramValueString = params.get('lot_id');
        inParams.lot_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_order_date_types_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturing_order_date_types_dd_multi');
      const title = 'manufacturing_order_date_types_dd';
      const component = Manufacturing_manufacturing_order_date_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_equipment_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_equipment_dd_multi');
      const title = 'manufacturing_orders_equipment_dd';
      const component = Manufacturing_manufacturing_orders_equipment_dd_multiComponent;
      const inParams:{ warehouse_id?: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturing_orders_workshifts_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturing_orders_workshifts_dd_multi');
      const title = 'manufacturing_orders_workshifts_dd';
      const component = Manufacturing_manufacturing_orders_workshifts_dd_multiComponent;
      const inParams:{ warehouse_id?: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_manufacturnig_order_production_confirmation_dd_multi') {
      this.$logger.log('Manufacturing', 'manufacturnig_order_production_confirmation_dd_multi');
      const title = 'manufacturnig_order_production_confirmation_dd';
      const component = Manufacturing_manufacturnig_order_production_confirmation_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_material_packagings_dd_multi') {
      this.$logger.log('Manufacturing', 'material_packagings_dd_multi');
      const title = 'material_packagings_dd';
      const component = Manufacturing_material_packagings_dd_multiComponent;
      const inParams:{ material_id?: number } = { material_id: null };
      if (!isNil(params.get('material_id'))) {
        const paramValueString = params.get('material_id');
        inParams.material_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_material_substitutions_dd_multi') {
      this.$logger.log('Manufacturing', 'material_substitutions_dd_multi');
      const title = 'material_substitutions_dd';
      const component = Manufacturing_material_substitutions_dd_multiComponent;
      const inParams:{ material_ids?: number[], package_id?: number, warehouse_id?: number, substitution_ids?: number[], needed_amount?: number } = { material_ids: [], package_id: null, warehouse_id: null, substitution_ids: [], needed_amount: null };
      if (!isNil(params.get('material_ids'))) {
        const paramValueString = params.get('material_ids');
        inParams.material_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('package_id'))) {
        const paramValueString = params.get('package_id');
        inParams.package_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('substitution_ids'))) {
        const paramValueString = params.get('substitution_ids');
        inParams.substitution_ids = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('needed_amount'))) {
        const paramValueString = params.get('needed_amount');
        inParams.needed_amount = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_materials_by_project_dd_multi') {
      this.$logger.log('Manufacturing', 'materials_by_project_dd_multi');
      const title = 'materials_by_project_dd';
      const component = Manufacturing_materials_by_project_dd_multiComponent;
      const inParams:{ project_id: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_measurement_units_dd_multi') {
      this.$logger.log('Manufacturing', 'measurement_units_dd_multi');
      const title = 'measurement_units_dd';
      const component = Manufacturing_measurement_units_dd_multiComponent;
      const inParams:{ type_id?: number } = { type_id: null };
      if (!isNil(params.get('type_id'))) {
        const paramValueString = params.get('type_id');
        inParams.type_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_order_statuses_dd_multi') {
      this.$logger.log('Manufacturing', 'order_statuses_dd_multi');
      const title = 'order_statuses_dd';
      const component = Manufacturing_order_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_orders_dd_multi') {
      this.$logger.log('Manufacturing', 'orders_dd_multi');
      const title = 'orders_dd';
      const component = Manufacturing_orders_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_owners_dd_multi') {
      this.$logger.log('Manufacturing', 'owners_dd_multi');
      const title = 'owners_dd';
      const component = Manufacturing_owners_dd_multiComponent;
      const inParams:{ project_id?: number } = { project_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_projects_dd_multi') {
      this.$logger.log('Manufacturing', 'projects_dd_multi');
      const title = 'projects_dd';
      const component = Manufacturing_projects_dd_multiComponent;
      const inParams:{ status_id?: number, owner_id?: number } = { status_id: null, owner_id: null };
      if (!isNil(params.get('status_id'))) {
        const paramValueString = params.get('status_id');
        inParams.status_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('owner_id'))) {
        const paramValueString = params.get('owner_id');
        inParams.owner_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_time_selector_dd_multi') {
      this.$logger.log('Manufacturing', 'time_selector_dd_multi');
      const title = 'time_selector_dd';
      const component = Manufacturing_time_selector_dd_multiComponent;
      const inParams:{ intervalMinutes: number } = { intervalMinutes: null };
      if (!isNil(params.get('intervalMinutes'))) {
        const paramValueString = params.get('intervalMinutes');
        inParams.intervalMinutes = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_warehouse_dd_multi') {
      this.$logger.log('Manufacturing', 'warehouse_dd_multi');
      const title = 'warehouse_dd';
      const component = Manufacturing_warehouse_dd_multiComponent;
      const inParams:{ warehouse_id: number } = { warehouse_id: null };
      if (!isNil(params.get('warehouse_id'))) {
        const paramValueString = params.get('warehouse_id');
        inParams.warehouse_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_warehouses_dd_multi') {
      this.$logger.log('Manufacturing', 'warehouses_dd_multi');
      const title = 'warehouses_dd';
      const component = Manufacturing_warehouses_dd_multiComponent;
      const inParams:{ warehouse_ids?: number[] } = { warehouse_ids: [] };
      if (!isNil(params.get('warehouse_ids'))) {
        const paramValueString = params.get('warehouse_ids');
        inParams.warehouse_ids = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Manufacturing_pending_confirmation_widget') {
      this.$logger.log('Manufacturing', 'pending_confirmation_widget');
      const title = 'Pending confirmation';
      const component = Manufacturing_pending_confirmation_widgetComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
