import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_master_bol_report_details_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { load_container_id: number }): Promise<{ result?: { results?: { pallet_count?: number, total_quantity?: number, load_container_sequence?: number, order_lookupcode?: string, order_owner_reference?: string, seal_number?: string, order_vendor_reference?: string, ship_to_acocunt_name?: string, ship_to_address_line1?: string, ship_to_city_state_postal_code?: string, total_gross_weight?: number, row_count?: number }[] } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.load_container_id)) {
      missingRequiredInParams.push('\'load_container_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/master_bol_report_details_flow/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      load_container_id: number    }) {
        if(isNil(inParams.load_container_id)) {
          return true; 
        }
      return false;
    }
}
