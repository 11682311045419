import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Invoices_invoicing_rules_hub_ComponentContextService } from './Invoices.invoicing_rules_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Invoices_invoicing_rules_gridComponent } from './Invoices.invoicing_rules_grid.component';
import { Invoices_owners_dd_singleComponent } from './Invoices.owners_dd_single.component'
import { Invoices_invoicing_projects_dd_singleComponent } from './Invoices.invoicing_projects_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_invoicing_rules_gridComponent),
    forwardRef(() => Invoices_owners_dd_singleComponent),
    forwardRef(() => Invoices_invoicing_projects_dd_singleComponent),
  ],
  selector: 'Invoices-invoicing_rules_hub',
  templateUrl: './Invoices.invoicing_rules_hub.component.html'
})
export class Invoices_invoicing_rules_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { projectId?: number, billingContractId?: number } = { projectId: null, billingContractId: null };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('billingContractId') set $inParams_billingContractId(v: number) {
    this.inParams.billingContractId = v;
  }
  get $inParams_billingContractId(): number {
    return this.inParams.billingContractId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { projectIds?: number[] } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      manage: new ToolModel(new SplitButtonModel<{ configurations: ButtonModel, manage_debug: ButtonModel }>(
        'manage',
        new ButtonStyles(null, null),
        false,
        false,
        'Manage',
        'icon-ic_fluent_settings_20_regular',
        null,
        [
          new ButtonModel('configurations', new ButtonStyles(null, null), false, false, false, 'Configurations', ''),
          new ButtonModel('manage_debug', new ButtonStyles(null, null), false, false, false, 'Debug', '')
        ])
    , false)
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
  };


  filtersets = {
    filters: new FieldsetModel(
      'Filters',
      false,
      true,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      invoicing_rules: new TabItemModel(
        this.rootTabGroup, 
        'Project rules', 
        false,
        ),
      template_rules: new TabItemModel(
        this.rootTabGroup, 
        'Template rules', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_invoicing_rules_invoicing_rules_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_template_rules_invoicing_rules_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = 0;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_invoicing_rules', { read: Invoices_invoicing_rules_gridComponent }) $tabs_invoicing_rules: Invoices_invoicing_rules_gridComponent;
      @ViewChild('$tabs_template_rules', { read: Invoices_invoicing_rules_gridComponent }) $tabs_template_rules: Invoices_invoicing_rules_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Auto-invoicing rules`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Automate invoice generation based on customizable rules.`;
  }

  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: Invoices_ShellService,
  private $datasources: Invoices_DatasourceService,
  private $flows: Invoices_FlowService,
  private $reports: Invoices_ReportService,
  private $localization: Invoices_LocalizationService,
  private $operations: Invoices_OperationService,
  private $logger: CleanupLoggerService,
  private $context: Invoices_invoicing_rules_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.invoicing_rules,
      this.tabs.template_rules,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Auto-invoicing rules hub';
    
    const $hub = this;
    const $utils = this.$utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_changed();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_changed();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_invoicing_rules') {
      if (!isNil(this.$tabs_invoicing_rules) && !this.tabs.invoicing_rules.hidden) {
        this.$tabs_invoicing_rules.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_template_rules') {
      if (!isNil(this.$tabs_template_rules) && !this.tabs.template_rules.hidden) {
        this.$tabs_template_rules.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: Invoices_invoicing_rules_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_invoicing_rules_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $flows.Invoices.initialize_invoicing_configuration({});
  
  
  const projectId = $hub.inParams.projectId;
  
  if ($utils.isDefined(projectId)) {
  
      $hub.vars.projectIds = [projectId];
      $hub.filters.project.control.value = projectId;
      $hub.filtersets.filters.hidden = true;
  
  } else {
  
      let projectIds: number[] = [];
  
      projectIds.push(0);
  
      const instructions = (await $flows.Invoices.invoicing_instruction({
          action: "Read"
      })).payload;
  
      if ($utils.isDefined(instructions)) {
          projectIds.push(...instructions.map(i => i.projectId))
          projectIds = projectIds.filter((value, index, array) => array.indexOf(value) === index); //Distinct
      }
  
      $hub.vars.projectIds = projectIds;
  }
  
  if ($utils.isDefined($hub.inParams.billingContractId)) {
      $hub.filtersets.filters.hidden = true;
  }
  }
  on_invoice_lines_create_execute(event = null) {
    return this.on_invoice_lines_create_executeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_invoice_lines_create_executeInternal(
    $hub: Invoices_invoicing_rules_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_invoicing_rules_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Invoices', 'invoicing_rules_hub.on_invoice_lines_create_execute');
  //O.Arias - 04/22/2024
  
  try {
  
      let results = (await $flows.Invoices.invoicing_create_invoice_lines({
          output: true
      }));
  
      if ($utils.isDefined(results)) {
          $shell.Invoices.openview_json_payloadDialog({ payload: results, title: `invoicing_create_invoice_lines` })
      } else {
          $shell.Invoices.openInfoDialog(`Nothing returned`, `The invoicing_create_invoice_lines did not return a payload.`)
      }
  
      $hub.refresh();
  
  } catch (error) {
  
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      };
  
      if (!$utils.isDefined(targetError?.message)) {
          targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` }
      };
  
      await log_add({
          level: 2,
          process: `on_invoice_lines_create_execute`,
          notes: JSON.stringify({ error: targetError.message })
      });
  };
  
  const log_schema = (await $flows.Invoices.invoicing_log({ action: "" })).payload;
  async function log_add(input: typeof log_schema[0]) {
      await $flows.Invoices.invoicing_log({ action: "Write", payload: input });
  };
  }
  on_manage_configurations_clicked(event = null) {
    return this.on_manage_configurations_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_configurations_clickedInternal(
    $hub: Invoices_invoicing_rules_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_invoicing_rules_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const result = await $shell.Invoices.openinvoicing_rules_configuration_formDialog('flyout', EModalSize.Small);
  
  if (result.is_confirmed) { $hub.refresh(); }
  }
  on_manage_template_rules_clicked(event = null) {
    return this.on_manage_template_rules_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_template_rules_clickedInternal(
    $hub: Invoices_invoicing_rules_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_invoicing_rules_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Invoices', 'invoicing_rules_hub.on_manage_template_rules_clicked');
  await $shell.Invoices.openinvoicing_rules_gridDialog({projectId: 0}, 'flyout', EModalSize.Xlarge);
  $hub.refresh();
  }
  on_owner_changed(event = null) {
    return this.on_owner_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changedInternal(
    $hub: Invoices_invoicing_rules_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_invoicing_rules_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $hub.filters.project.control.value = null;
  
  if ($utils.isDefined($hub.filters.owner.control.value)) {
      let projects = (await $datasources.Invoices.ds_projects_dd.get({ ownerId: $hub.filters.owner.control.value })).result;
      if (projects.length === 1) {
          $hub.filters.project.control.value = projects[0].Id;
      }
  }
  }
  on_project_changed(event = null) {
    return this.on_project_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changedInternal(
    $hub: Invoices_invoicing_rules_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_invoicing_rules_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($hub.filters.project.control.value) && !$utils.isDefined($hub.filters.owner.control.value)) {
      const owners = (await $datasources.Invoices.ds_owners_dd.get({ projectId: $hub.filters.project.control.value})).result;
  
      $hub.filters.owner.control.value = owners[0].Id;
  }
  }
  on_manage_debug_clicked(event = null) {
    return this.on_manage_debug_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_debug_clickedInternal(
    $hub: Invoices_invoicing_rules_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    $context: Invoices_invoicing_rules_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  await $shell.Invoices.openinvoicing_debug_hubDialog('flyout', EModalSize.Xlarge);
  }
  //#endregion private flows
}
