import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { Notifications_log_notes_reportService } from './Notifications.report.index';
import { Notifications_transaction_reportService } from './Notifications.report.index';

@Injectable({ providedIn: 'root' })
export class Notifications_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public Notifications: Notifications_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _log_notes_report: Notifications_log_notes_reportService;
  public get log_notes_report(): Notifications_log_notes_reportService {
    if(!this._log_notes_report) {
      this._log_notes_report = this.injector.get(Notifications_log_notes_reportService);
    }
    return this._log_notes_report;
  }
  private _transaction_report: Notifications_transaction_reportService;
  public get transaction_report(): Notifications_transaction_reportService {
    if(!this._transaction_report) {
      this._transaction_report = this.injector.get(Notifications_transaction_reportService);
    }
    return this._transaction_report;
  }
}

