import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';

interface IManufacturing_testRepServiceInParams {
}

interface IManufacturing_testRepServiceData {
  data?: { result?: { Id?: number, LookupCode?: string, Projects?: { LookupCode?: string, Materials?: { LookupCode?: string, Lots?: { LookupCode?: string, LicensePlateContents?: { PackagedAmount?: number, LicensePlate?: { LookupCode?: string } }[] }[] }[] }[] }[] }
}

@Injectable({ providedIn: 'root' })
export class Manufacturing_testRepService extends ReportBaseService<IManufacturing_testRepServiceInParams, IManufacturing_testRepServiceData> {

  protected reportReferenceName = 'testRep';
  protected appReferenceName = 'Manufacturing';

  constructor(
    $utils: UtilsService,
    private $datasources: Manufacturing_DatasourceService,
    private $logger: CleanupLoggerService
    ) {
      super($utils);
      $logger.log('Manufacturing', 'testRep');
  }

  override throwIfMissingRequiredInParams (inParams: IManufacturing_testRepServiceInParams) {
  }

  protected async getData(inParams: IManufacturing_testRepServiceInParams): Promise<IManufacturing_testRepServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IManufacturing_testRepServiceInParams } = {
      inParams: inParams
    };

    const data: IManufacturing_testRepServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
      };
      
      const dsData = await this.$datasources.Manufacturing.testdfvr.get(dsParams);
      
      data.data = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
