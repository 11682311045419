<datex-list [items]="items"
            [pageSize]="pageSize"
            [(pageSkip)]="pageSkip"
            [totalCount]="totalCount"
            [loadingStatus]="loadingStatus"
            (loadMore)="$dataLoad()">

  <ng-container topToolbar>
  </ng-container>


  <ng-template listItemDef
               let-item>
    <FootprintManager-billing_record_activity_card
    [billingTaskId]="item.$content_FootprintManager_billing_record_activity_card_inParams_billingTaskId"
    [activityDetails]="item.$content_FootprintManager_billing_record_activity_card_inParams_activityDetails"
    [activityDate]="item.$content_FootprintManager_billing_record_activity_card_inParams_activityDate"
    [activityUser]="item.$content_FootprintManager_billing_record_activity_card_inParams_activityUser"
    [style]="item.$content_FootprintManager_billing_record_activity_card_inParams_style"
    [title]="item.$content_FootprintManager_billing_record_activity_card_inParams_title"
    ($refreshEvent)="$refreshEvent.emit()"
    >
    </FootprintManager-billing_record_activity_card>
  </ng-template>

</datex-list>