import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_get_license_plate_inventory_by_material_warehouse_locationService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { material_id: number, package_id: number, location_id: number, warehouse_id: number, quantity_for_consumption: number, license_plate?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, AvailablePackagedAmount?: number, LicensePlateStatusId?: number, LocationId?: number, MaterialId?: number, Material?: { Id?: number, Name?: string }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { ExpirationDate?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.package_id)) {
      missingRequiredInParams.push('\'package_id\'');
    }
    if (isNil(inParams.location_id)) {
      missingRequiredInParams.push('\'location_id\'');
    }
    if (isNil(inParams.warehouse_id)) {
      missingRequiredInParams.push('\'warehouse_id\'');
    }
    if (isNil(inParams.quantity_for_consumption)) {
      missingRequiredInParams.push('\'quantity_for_consumption\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_license_plate_inventory_by_material_warehouse_location/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { material_id: number, package_id: number, location_id: number, warehouse_id: number, quantity_for_consumption: number, license_plate?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, AvailablePackagedAmount?: number, LicensePlateStatusId?: number, LocationId?: number, MaterialId?: number, Material?: { Id?: number, Name?: string }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { ExpirationDate?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.package_id)) {
      missingRequiredInParams.push('\'package_id\'');
    }
    if (isNil(inParams.location_id)) {
      missingRequiredInParams.push('\'location_id\'');
    }
    if (isNil(inParams.warehouse_id)) {
      missingRequiredInParams.push('\'warehouse_id\'');
    }
    if (isNil(inParams.quantity_for_consumption)) {
      missingRequiredInParams.push('\'quantity_for_consumption\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_license_plate_inventory_by_material_warehouse_location/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { material_id: number, package_id: number, location_id: number, warehouse_id: number, quantity_for_consumption: number, license_plate?: number, $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, AvailablePackagedAmount?: number, LicensePlateStatusId?: number, LocationId?: number, MaterialId?: number, Material?: { Id?: number, Name?: string }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { ExpirationDate?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.package_id)) {
      missingRequiredInParams.push('\'package_id\'');
    }
    if (isNil(inParams.location_id)) {
      missingRequiredInParams.push('\'location_id\'');
    }
    if (isNil(inParams.warehouse_id)) {
      missingRequiredInParams.push('\'warehouse_id\'');
    }
    if (isNil(inParams.quantity_for_consumption)) {
      missingRequiredInParams.push('\'quantity_for_consumption\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_license_plate_inventory_by_material_warehouse_location/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      material_id: number, package_id: number, location_id: number, warehouse_id: number, quantity_for_consumption: number, license_plate?: number    }) {
        if(isNil(inParams.material_id)) {
          return true; 
        }
        if(isNil(inParams.package_id)) {
          return true; 
        }
        if(isNil(inParams.location_id)) {
          return true; 
        }
        if(isNil(inParams.warehouse_id)) {
          return true; 
        }
        if(isNil(inParams.quantity_for_consumption)) {
          return true; 
        }
      return false;
    }
}
