<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-scheduled_dock_door" *ngIf="!fields.scheduled_dock_door.hidden && !fields.scheduled_dock_door.removed" 
                            class="field-container standard {{fields.scheduled_dock_door.invalid ? 'invalid' : ''}} {{fields.scheduled_dock_door.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.scheduled_dock_door.styles.style"
                            [ngClass]="fields.scheduled_dock_door.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.scheduled_dock_door.label + (fields.scheduled_dock_door.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.scheduled_dock_door.label}}<span *ngIf="fields.scheduled_dock_door.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <DockAppointments-dock_doors_dd_single 
                            data-cy="selector"
                            [type]="fields.scheduled_dock_door.control.type"
                            formControlName="scheduled_dock_door"
                            (displayTextChange)="fields.scheduled_dock_door.control.displayText=$event"
                            [placeholder]="fields.scheduled_dock_door.control.placeholder"
                            [styles]="fields.scheduled_dock_door.control.styles"
                            [tooltip]="fields.scheduled_dock_door.control.tooltip"
                          [warehouseIds]="$fields_scheduled_dock_door_selector_inParams_warehouseIds"
                          [appointmentType]="$fields_scheduled_dock_door_selector_inParams_appointmentType"
                        >
                        </DockAppointments-dock_doors_dd_single>
                        <ng-container *ngIf="fields.scheduled_dock_door.invalid">
                          <ng-container *ngFor="let error of fields.scheduled_dock_door.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-assigned_dock_door" *ngIf="!fields.assigned_dock_door.hidden && !fields.assigned_dock_door.removed" 
                            class="field-container standard {{fields.assigned_dock_door.invalid ? 'invalid' : ''}} {{fields.assigned_dock_door.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.assigned_dock_door.styles.style"
                            [ngClass]="fields.assigned_dock_door.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.assigned_dock_door.label + (fields.assigned_dock_door.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.assigned_dock_door.label}}<span *ngIf="fields.assigned_dock_door.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <DockAppointments-dock_doors_dd_single 
                            data-cy="selector"
                            [type]="fields.assigned_dock_door.control.type"
                            formControlName="assigned_dock_door"
                            (displayTextChange)="fields.assigned_dock_door.control.displayText=$event"
                            [placeholder]="fields.assigned_dock_door.control.placeholder"
                            [styles]="fields.assigned_dock_door.control.styles"
                            [tooltip]="fields.assigned_dock_door.control.tooltip"
                          [warehouseIds]="$fields_assigned_dock_door_selector_inParams_warehouseIds"
                          [appointmentType]="$fields_assigned_dock_door_selector_inParams_appointmentType"
                        >
                        </DockAppointments-dock_doors_dd_single>
                        <ng-container *ngIf="fields.assigned_dock_door.invalid">
                          <ng-container *ngFor="let error of fields.assigned_dock_door.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-driver_name" *ngIf="!fields.driver_name.hidden && !fields.driver_name.removed" 
                            class="field-container standard {{fields.driver_name.invalid ? 'invalid' : ''}} {{fields.driver_name.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.driver_name.styles.style"
                            [ngClass]="fields.driver_name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.driver_name.label + (fields.driver_name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.driver_name.label}}<span *ngIf="fields.driver_name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="driver_name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.driver_name.control.readOnly || fields.driver_name.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.driver_name.control.placeholder}}"
                                [ngStyle]="fields.driver_name.control.styles.style"
                                [ngClass]="fields.driver_name.control.styles.classes"
                                [matTooltip]="fields.driver_name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.driver_name.invalid">
                          <ng-container *ngFor="let error of fields.driver_name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-driver_license" *ngIf="!fields.driver_license.hidden && !fields.driver_license.removed" 
                            class="field-container standard {{fields.driver_license.invalid ? 'invalid' : ''}} {{fields.driver_license.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.driver_license.styles.style"
                            [ngClass]="fields.driver_license.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.driver_license.label + (fields.driver_license.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.driver_license.label}}<span *ngIf="fields.driver_license.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="driver_license"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.driver_license.control.readOnly || fields.driver_license.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.driver_license.control.placeholder}}"
                                [ngStyle]="fields.driver_license.control.styles.style"
                                [ngClass]="fields.driver_license.control.styles.classes"
                                [matTooltip]="fields.driver_license.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.driver_license.invalid">
                          <ng-container *ngFor="let error of fields.driver_license.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vehicle_license" *ngIf="!fields.vehicle_license.hidden && !fields.vehicle_license.removed" 
                            class="field-container standard {{fields.vehicle_license.invalid ? 'invalid' : ''}} {{fields.vehicle_license.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vehicle_license.styles.style"
                            [ngClass]="fields.vehicle_license.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vehicle_license.label + (fields.vehicle_license.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vehicle_license.label}}<span *ngIf="fields.vehicle_license.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="vehicle_license"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.vehicle_license.control.readOnly || fields.vehicle_license.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.vehicle_license.control.placeholder}}"
                                [ngStyle]="fields.vehicle_license.control.styles.style"
                                [ngClass]="fields.vehicle_license.control.styles.classes"
                                [matTooltip]="fields.vehicle_license.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.vehicle_license.invalid">
                          <ng-container *ngFor="let error of fields.vehicle_license.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>