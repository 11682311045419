import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Notifications_ds_auto_email_rule_contexts_ddService } from './Notifications.datasource.index';
import { Notifications_ds_auto_email_rule_editorService } from './Notifications.datasource.index';
import { Notifications_ds_auto_email_rule_reportsService } from './Notifications.datasource.index';
import { Notifications_ds_auto_email_rule_reports_ddService } from './Notifications.datasource.index';
import { Notifications_ds_auto_email_rulesService } from './Notifications.datasource.index';
import { Notifications_ds_carriers_ddService } from './Notifications.datasource.index';
import { Notifications_ds_dock_appointment_types_ddService } from './Notifications.datasource.index';
import { Notifications_ds_email_request_attachments_gridService } from './Notifications.datasource.index';
import { Notifications_ds_email_requests_gridService } from './Notifications.datasource.index';
import { Notifications_ds_get_auto_email_eligible_dock_appointmentsService } from './Notifications.datasource.index';
import { Notifications_ds_get_auto_email_eligible_invoicesService } from './Notifications.datasource.index';
import { Notifications_ds_get_auto_email_eligible_lot_expirationsService } from './Notifications.datasource.index';
import { Notifications_ds_get_auto_email_eligible_ordersService } from './Notifications.datasource.index';
import { Notifications_ds_get_auto_email_field_parameters_by_contextService } from './Notifications.datasource.index';
import { Notifications_ds_get_auto_email_inventory_below_minimum_thresholdService } from './Notifications.datasource.index';
import { Notifications_ds_get_completed_dock_appointmentsService } from './Notifications.datasource.index';
import { Notifications_ds_get_completed_ordersService } from './Notifications.datasource.index';
import { Notifications_ds_get_dock_appointment_attachmentsService } from './Notifications.datasource.index';
import { Notifications_ds_get_entity_metadataService } from './Notifications.datasource.index';
import { Notifications_ds_get_expired_lotsService } from './Notifications.datasource.index';
import { Notifications_ds_get_exported_invoicesService } from './Notifications.datasource.index';
import { Notifications_ds_get_inventory_below_minimum_thresholdService } from './Notifications.datasource.index';
import { Notifications_ds_get_invoice_attachmentsService } from './Notifications.datasource.index';
import { Notifications_ds_get_invoices_by_invoiceIdsService } from './Notifications.datasource.index';
import { Notifications_ds_get_lot_attachmentsService } from './Notifications.datasource.index';
import { Notifications_ds_get_material_attachmentsService } from './Notifications.datasource.index';
import { Notifications_ds_get_order_attachmentsService } from './Notifications.datasource.index';
import { Notifications_ds_get_orders_by_orderIdsService } from './Notifications.datasource.index';
import { Notifications_ds_get_projectsService } from './Notifications.datasource.index';
import { Notifications_ds_get_referenced_packagesService } from './Notifications.datasource.index';
import { Notifications_ds_get_warehousesService } from './Notifications.datasource.index';
import { Notifications_ds_owners_ddService } from './Notifications.datasource.index';
import { Notifications_ds_projects_ddService } from './Notifications.datasource.index';
import { Notifications_ds_report_inputs_gridService } from './Notifications.datasource.index';
import { Notifications_ds_storage_alertsService } from './Notifications.datasource.index';
import { Notifications_ds_storage_alerts_filtersService } from './Notifications.datasource.index';
import { Notifications_ds_time_selector_ddService } from './Notifications.datasource.index';
import { Notifications_ds_transaction_reportService } from './Notifications.datasource.index';
import { Notifications_ds_warehouses_ddService } from './Notifications.datasource.index';

@Injectable({ providedIn: 'root' })
export class Notifications_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Notifications: Notifications_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_auto_email_rule_contexts_dd: Notifications_ds_auto_email_rule_contexts_ddService;
  public get ds_auto_email_rule_contexts_dd(): Notifications_ds_auto_email_rule_contexts_ddService {
    if(!this._ds_auto_email_rule_contexts_dd) {
      this._ds_auto_email_rule_contexts_dd = this.injector.get(Notifications_ds_auto_email_rule_contexts_ddService);
    }
    return this._ds_auto_email_rule_contexts_dd;
  }
  private _ds_auto_email_rule_editor: Notifications_ds_auto_email_rule_editorService;
  public get ds_auto_email_rule_editor(): Notifications_ds_auto_email_rule_editorService {
    if(!this._ds_auto_email_rule_editor) {
      this._ds_auto_email_rule_editor = this.injector.get(Notifications_ds_auto_email_rule_editorService);
    }
    return this._ds_auto_email_rule_editor;
  }
  private _ds_auto_email_rule_reports: Notifications_ds_auto_email_rule_reportsService;
  public get ds_auto_email_rule_reports(): Notifications_ds_auto_email_rule_reportsService {
    if(!this._ds_auto_email_rule_reports) {
      this._ds_auto_email_rule_reports = this.injector.get(Notifications_ds_auto_email_rule_reportsService);
    }
    return this._ds_auto_email_rule_reports;
  }
  private _ds_auto_email_rule_reports_dd: Notifications_ds_auto_email_rule_reports_ddService;
  public get ds_auto_email_rule_reports_dd(): Notifications_ds_auto_email_rule_reports_ddService {
    if(!this._ds_auto_email_rule_reports_dd) {
      this._ds_auto_email_rule_reports_dd = this.injector.get(Notifications_ds_auto_email_rule_reports_ddService);
    }
    return this._ds_auto_email_rule_reports_dd;
  }
  private _ds_auto_email_rules: Notifications_ds_auto_email_rulesService;
  public get ds_auto_email_rules(): Notifications_ds_auto_email_rulesService {
    if(!this._ds_auto_email_rules) {
      this._ds_auto_email_rules = this.injector.get(Notifications_ds_auto_email_rulesService);
    }
    return this._ds_auto_email_rules;
  }
  private _ds_carriers_dd: Notifications_ds_carriers_ddService;
  public get ds_carriers_dd(): Notifications_ds_carriers_ddService {
    if(!this._ds_carriers_dd) {
      this._ds_carriers_dd = this.injector.get(Notifications_ds_carriers_ddService);
    }
    return this._ds_carriers_dd;
  }
  private _ds_dock_appointment_types_dd: Notifications_ds_dock_appointment_types_ddService;
  public get ds_dock_appointment_types_dd(): Notifications_ds_dock_appointment_types_ddService {
    if(!this._ds_dock_appointment_types_dd) {
      this._ds_dock_appointment_types_dd = this.injector.get(Notifications_ds_dock_appointment_types_ddService);
    }
    return this._ds_dock_appointment_types_dd;
  }
  private _ds_email_request_attachments_grid: Notifications_ds_email_request_attachments_gridService;
  public get ds_email_request_attachments_grid(): Notifications_ds_email_request_attachments_gridService {
    if(!this._ds_email_request_attachments_grid) {
      this._ds_email_request_attachments_grid = this.injector.get(Notifications_ds_email_request_attachments_gridService);
    }
    return this._ds_email_request_attachments_grid;
  }
  private _ds_email_requests_grid: Notifications_ds_email_requests_gridService;
  public get ds_email_requests_grid(): Notifications_ds_email_requests_gridService {
    if(!this._ds_email_requests_grid) {
      this._ds_email_requests_grid = this.injector.get(Notifications_ds_email_requests_gridService);
    }
    return this._ds_email_requests_grid;
  }
  private _ds_get_auto_email_eligible_dock_appointments: Notifications_ds_get_auto_email_eligible_dock_appointmentsService;
  public get ds_get_auto_email_eligible_dock_appointments(): Notifications_ds_get_auto_email_eligible_dock_appointmentsService {
    if(!this._ds_get_auto_email_eligible_dock_appointments) {
      this._ds_get_auto_email_eligible_dock_appointments = this.injector.get(Notifications_ds_get_auto_email_eligible_dock_appointmentsService);
    }
    return this._ds_get_auto_email_eligible_dock_appointments;
  }
  private _ds_get_auto_email_eligible_invoices: Notifications_ds_get_auto_email_eligible_invoicesService;
  public get ds_get_auto_email_eligible_invoices(): Notifications_ds_get_auto_email_eligible_invoicesService {
    if(!this._ds_get_auto_email_eligible_invoices) {
      this._ds_get_auto_email_eligible_invoices = this.injector.get(Notifications_ds_get_auto_email_eligible_invoicesService);
    }
    return this._ds_get_auto_email_eligible_invoices;
  }
  private _ds_get_auto_email_eligible_lot_expirations: Notifications_ds_get_auto_email_eligible_lot_expirationsService;
  public get ds_get_auto_email_eligible_lot_expirations(): Notifications_ds_get_auto_email_eligible_lot_expirationsService {
    if(!this._ds_get_auto_email_eligible_lot_expirations) {
      this._ds_get_auto_email_eligible_lot_expirations = this.injector.get(Notifications_ds_get_auto_email_eligible_lot_expirationsService);
    }
    return this._ds_get_auto_email_eligible_lot_expirations;
  }
  private _ds_get_auto_email_eligible_orders: Notifications_ds_get_auto_email_eligible_ordersService;
  public get ds_get_auto_email_eligible_orders(): Notifications_ds_get_auto_email_eligible_ordersService {
    if(!this._ds_get_auto_email_eligible_orders) {
      this._ds_get_auto_email_eligible_orders = this.injector.get(Notifications_ds_get_auto_email_eligible_ordersService);
    }
    return this._ds_get_auto_email_eligible_orders;
  }
  private _ds_get_auto_email_field_parameters_by_context: Notifications_ds_get_auto_email_field_parameters_by_contextService;
  public get ds_get_auto_email_field_parameters_by_context(): Notifications_ds_get_auto_email_field_parameters_by_contextService {
    if(!this._ds_get_auto_email_field_parameters_by_context) {
      this._ds_get_auto_email_field_parameters_by_context = this.injector.get(Notifications_ds_get_auto_email_field_parameters_by_contextService);
    }
    return this._ds_get_auto_email_field_parameters_by_context;
  }
  private _ds_get_auto_email_inventory_below_minimum_threshold: Notifications_ds_get_auto_email_inventory_below_minimum_thresholdService;
  public get ds_get_auto_email_inventory_below_minimum_threshold(): Notifications_ds_get_auto_email_inventory_below_minimum_thresholdService {
    if(!this._ds_get_auto_email_inventory_below_minimum_threshold) {
      this._ds_get_auto_email_inventory_below_minimum_threshold = this.injector.get(Notifications_ds_get_auto_email_inventory_below_minimum_thresholdService);
    }
    return this._ds_get_auto_email_inventory_below_minimum_threshold;
  }
  private _ds_get_completed_dock_appointments: Notifications_ds_get_completed_dock_appointmentsService;
  public get ds_get_completed_dock_appointments(): Notifications_ds_get_completed_dock_appointmentsService {
    if(!this._ds_get_completed_dock_appointments) {
      this._ds_get_completed_dock_appointments = this.injector.get(Notifications_ds_get_completed_dock_appointmentsService);
    }
    return this._ds_get_completed_dock_appointments;
  }
  private _ds_get_completed_orders: Notifications_ds_get_completed_ordersService;
  public get ds_get_completed_orders(): Notifications_ds_get_completed_ordersService {
    if(!this._ds_get_completed_orders) {
      this._ds_get_completed_orders = this.injector.get(Notifications_ds_get_completed_ordersService);
    }
    return this._ds_get_completed_orders;
  }
  private _ds_get_dock_appointment_attachments: Notifications_ds_get_dock_appointment_attachmentsService;
  public get ds_get_dock_appointment_attachments(): Notifications_ds_get_dock_appointment_attachmentsService {
    if(!this._ds_get_dock_appointment_attachments) {
      this._ds_get_dock_appointment_attachments = this.injector.get(Notifications_ds_get_dock_appointment_attachmentsService);
    }
    return this._ds_get_dock_appointment_attachments;
  }
  private _ds_get_entity_metadata: Notifications_ds_get_entity_metadataService;
  public get ds_get_entity_metadata(): Notifications_ds_get_entity_metadataService {
    if(!this._ds_get_entity_metadata) {
      this._ds_get_entity_metadata = this.injector.get(Notifications_ds_get_entity_metadataService);
    }
    return this._ds_get_entity_metadata;
  }
  private _ds_get_expired_lots: Notifications_ds_get_expired_lotsService;
  public get ds_get_expired_lots(): Notifications_ds_get_expired_lotsService {
    if(!this._ds_get_expired_lots) {
      this._ds_get_expired_lots = this.injector.get(Notifications_ds_get_expired_lotsService);
    }
    return this._ds_get_expired_lots;
  }
  private _ds_get_exported_invoices: Notifications_ds_get_exported_invoicesService;
  public get ds_get_exported_invoices(): Notifications_ds_get_exported_invoicesService {
    if(!this._ds_get_exported_invoices) {
      this._ds_get_exported_invoices = this.injector.get(Notifications_ds_get_exported_invoicesService);
    }
    return this._ds_get_exported_invoices;
  }
  private _ds_get_inventory_below_minimum_threshold: Notifications_ds_get_inventory_below_minimum_thresholdService;
  public get ds_get_inventory_below_minimum_threshold(): Notifications_ds_get_inventory_below_minimum_thresholdService {
    if(!this._ds_get_inventory_below_minimum_threshold) {
      this._ds_get_inventory_below_minimum_threshold = this.injector.get(Notifications_ds_get_inventory_below_minimum_thresholdService);
    }
    return this._ds_get_inventory_below_minimum_threshold;
  }
  private _ds_get_invoice_attachments: Notifications_ds_get_invoice_attachmentsService;
  public get ds_get_invoice_attachments(): Notifications_ds_get_invoice_attachmentsService {
    if(!this._ds_get_invoice_attachments) {
      this._ds_get_invoice_attachments = this.injector.get(Notifications_ds_get_invoice_attachmentsService);
    }
    return this._ds_get_invoice_attachments;
  }
  private _ds_get_invoices_by_invoiceIds: Notifications_ds_get_invoices_by_invoiceIdsService;
  public get ds_get_invoices_by_invoiceIds(): Notifications_ds_get_invoices_by_invoiceIdsService {
    if(!this._ds_get_invoices_by_invoiceIds) {
      this._ds_get_invoices_by_invoiceIds = this.injector.get(Notifications_ds_get_invoices_by_invoiceIdsService);
    }
    return this._ds_get_invoices_by_invoiceIds;
  }
  private _ds_get_lot_attachments: Notifications_ds_get_lot_attachmentsService;
  public get ds_get_lot_attachments(): Notifications_ds_get_lot_attachmentsService {
    if(!this._ds_get_lot_attachments) {
      this._ds_get_lot_attachments = this.injector.get(Notifications_ds_get_lot_attachmentsService);
    }
    return this._ds_get_lot_attachments;
  }
  private _ds_get_material_attachments: Notifications_ds_get_material_attachmentsService;
  public get ds_get_material_attachments(): Notifications_ds_get_material_attachmentsService {
    if(!this._ds_get_material_attachments) {
      this._ds_get_material_attachments = this.injector.get(Notifications_ds_get_material_attachmentsService);
    }
    return this._ds_get_material_attachments;
  }
  private _ds_get_order_attachments: Notifications_ds_get_order_attachmentsService;
  public get ds_get_order_attachments(): Notifications_ds_get_order_attachmentsService {
    if(!this._ds_get_order_attachments) {
      this._ds_get_order_attachments = this.injector.get(Notifications_ds_get_order_attachmentsService);
    }
    return this._ds_get_order_attachments;
  }
  private _ds_get_orders_by_orderIds: Notifications_ds_get_orders_by_orderIdsService;
  public get ds_get_orders_by_orderIds(): Notifications_ds_get_orders_by_orderIdsService {
    if(!this._ds_get_orders_by_orderIds) {
      this._ds_get_orders_by_orderIds = this.injector.get(Notifications_ds_get_orders_by_orderIdsService);
    }
    return this._ds_get_orders_by_orderIds;
  }
  private _ds_get_projects: Notifications_ds_get_projectsService;
  public get ds_get_projects(): Notifications_ds_get_projectsService {
    if(!this._ds_get_projects) {
      this._ds_get_projects = this.injector.get(Notifications_ds_get_projectsService);
    }
    return this._ds_get_projects;
  }
  private _ds_get_referenced_packages: Notifications_ds_get_referenced_packagesService;
  public get ds_get_referenced_packages(): Notifications_ds_get_referenced_packagesService {
    if(!this._ds_get_referenced_packages) {
      this._ds_get_referenced_packages = this.injector.get(Notifications_ds_get_referenced_packagesService);
    }
    return this._ds_get_referenced_packages;
  }
  private _ds_get_warehouses: Notifications_ds_get_warehousesService;
  public get ds_get_warehouses(): Notifications_ds_get_warehousesService {
    if(!this._ds_get_warehouses) {
      this._ds_get_warehouses = this.injector.get(Notifications_ds_get_warehousesService);
    }
    return this._ds_get_warehouses;
  }
  private _ds_owners_dd: Notifications_ds_owners_ddService;
  public get ds_owners_dd(): Notifications_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(Notifications_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_projects_dd: Notifications_ds_projects_ddService;
  public get ds_projects_dd(): Notifications_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Notifications_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_report_inputs_grid: Notifications_ds_report_inputs_gridService;
  public get ds_report_inputs_grid(): Notifications_ds_report_inputs_gridService {
    if(!this._ds_report_inputs_grid) {
      this._ds_report_inputs_grid = this.injector.get(Notifications_ds_report_inputs_gridService);
    }
    return this._ds_report_inputs_grid;
  }
  private _ds_storage_alerts: Notifications_ds_storage_alertsService;
  public get ds_storage_alerts(): Notifications_ds_storage_alertsService {
    if(!this._ds_storage_alerts) {
      this._ds_storage_alerts = this.injector.get(Notifications_ds_storage_alertsService);
    }
    return this._ds_storage_alerts;
  }
  private _ds_storage_alerts_filters: Notifications_ds_storage_alerts_filtersService;
  public get ds_storage_alerts_filters(): Notifications_ds_storage_alerts_filtersService {
    if(!this._ds_storage_alerts_filters) {
      this._ds_storage_alerts_filters = this.injector.get(Notifications_ds_storage_alerts_filtersService);
    }
    return this._ds_storage_alerts_filters;
  }
  private _ds_time_selector_dd: Notifications_ds_time_selector_ddService;
  public get ds_time_selector_dd(): Notifications_ds_time_selector_ddService {
    if(!this._ds_time_selector_dd) {
      this._ds_time_selector_dd = this.injector.get(Notifications_ds_time_selector_ddService);
    }
    return this._ds_time_selector_dd;
  }
  private _ds_transaction_report: Notifications_ds_transaction_reportService;
  public get ds_transaction_report(): Notifications_ds_transaction_reportService {
    if(!this._ds_transaction_report) {
      this._ds_transaction_report = this.injector.get(Notifications_ds_transaction_reportService);
    }
    return this._ds_transaction_report;
  }
  private _ds_warehouses_dd: Notifications_ds_warehouses_ddService;
  public get ds_warehouses_dd(): Notifications_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(Notifications_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
}

