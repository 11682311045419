import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_receiving_details_report_flow_basedService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { orderId: number }): Promise<{ result?: { OrderLines?: { LineNumber?: number, PackagedAmount?: number, ActualPackagedAmount?: number, Packaging?: string, Material_LookupCode?: string, Material_Description?: string, Material_ControllerTypeId?: number, Lot_LookupCode?: string, VendorLot_LookupCode?: string, VendorLot_ExpirationDate?: string, VendorLot_ManufactureDate?: string, TotalGrossWeight?: number, TotalPackagedAmount?: number }[] } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_receiving_details_report_flow_based/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      orderId: number    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
