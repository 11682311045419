import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Materials_ds_get_material_packagings_details_by_materialIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { materialId: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AllowFractions?: boolean, AllowInMobileSales?: boolean, AllowInPurchaseOrder?: boolean, AllowInSalesOrder?: boolean, AutogenerateSerialNumbers?: boolean, BasePackagingId?: number, BasePackagingQuantity?: number, ContainsPortalVisibleAttachments?: boolean, CurrencyId?: number, DeprecatedPackaging?: boolean, DimensionUomId?: number, FreightClassId?: number, Height?: number, IsBasePackaging?: boolean, IsLabelCompliant?: boolean, IsOrderDefault?: boolean, IsReportingDefault?: boolean, LastPackagingReviewDate?: string, Length?: number, MaterialsDynamicPackagingId?: number, NumberOfLabelCopies?: number, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, StackableGroupId?: string, StackableHeight?: number, StackableLength?: number, StackableVolume?: number, StackableWidth?: number, StandardCost?: number, StandardPrice?: number, SubPackagingId?: number, SubPackagingQuantity?: number, TareWeight?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_material_packagings_details_by_materialId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { materialId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AllowFractions?: boolean, AllowInMobileSales?: boolean, AllowInPurchaseOrder?: boolean, AllowInSalesOrder?: boolean, AutogenerateSerialNumbers?: boolean, BasePackagingId?: number, BasePackagingQuantity?: number, ContainsPortalVisibleAttachments?: boolean, CurrencyId?: number, DeprecatedPackaging?: boolean, DimensionUomId?: number, FreightClassId?: number, Height?: number, IsBasePackaging?: boolean, IsLabelCompliant?: boolean, IsOrderDefault?: boolean, IsReportingDefault?: boolean, LastPackagingReviewDate?: string, Length?: number, MaterialsDynamicPackagingId?: number, NumberOfLabelCopies?: number, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, StackableGroupId?: string, StackableHeight?: number, StackableLength?: number, StackableVolume?: number, StackableWidth?: number, StandardCost?: number, StandardPrice?: number, SubPackagingId?: number, SubPackagingQuantity?: number, TareWeight?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_material_packagings_details_by_materialId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { materialId: number, $keys: { MaterialId?: number, PackagingId?: number }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AllowFractions?: boolean, AllowInMobileSales?: boolean, AllowInPurchaseOrder?: boolean, AllowInSalesOrder?: boolean, AutogenerateSerialNumbers?: boolean, BasePackagingId?: number, BasePackagingQuantity?: number, ContainsPortalVisibleAttachments?: boolean, CurrencyId?: number, DeprecatedPackaging?: boolean, DimensionUomId?: number, FreightClassId?: number, Height?: number, IsBasePackaging?: boolean, IsLabelCompliant?: boolean, IsOrderDefault?: boolean, IsReportingDefault?: boolean, LastPackagingReviewDate?: string, Length?: number, MaterialsDynamicPackagingId?: number, NumberOfLabelCopies?: number, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, StackableGroupId?: string, StackableHeight?: number, StackableLength?: number, StackableVolume?: number, StackableWidth?: number, StandardCost?: number, StandardPrice?: number, SubPackagingId?: number, SubPackagingQuantity?: number, TareWeight?: number, UpcCode?: string, UseAsShippingContainer?: boolean, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_material_packagings_details_by_materialId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      materialId: number    }) {
        if(isNil(inParams.materialId)) {
          return true; 
        }
      return false;
    }
}
