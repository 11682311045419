<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="fail_verification" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_fail_verification_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-input_scan" *ngIf="!fields.input_scan.hidden && !fields.input_scan.removed" 
                            class="field-container double {{fields.input_scan.invalid ? 'invalid' : ''}} {{fields.input_scan.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.input_scan.styles.style"
                            [ngClass]="fields.input_scan.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.input_scan.label + (fields.input_scan.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.input_scan.label}}<span *ngIf="fields.input_scan.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="input_scan"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.input_scan.control.readOnly || fields.input_scan.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.input_scan.control.placeholder}}"
                                [ngStyle]="fields.input_scan.control.styles.style"
                                [ngClass]="fields.input_scan.control.styles.classes"
                                [matTooltip]="fields.input_scan.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.input_scan.invalid">
                          <ng-container *ngFor="let error of fields.input_scan.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.container_contents.hasTabToShow" class="tab" data-cy="tab-container_contents">
              <h2 [className]="tabs.container_contents.active? 'active': ''" (click)="tabs.container_contents.activate()">{{tabs.container_contents.title}}</h2>
            </div>
            <div *ngIf="tabs.invalid_serial_numbers.hasTabToShow" class="tab" data-cy="tab-invalid_serial_numbers">
              <h2 [className]="tabs.invalid_serial_numbers.active? 'active': ''" (click)="tabs.invalid_serial_numbers.activate()">{{tabs.invalid_serial_numbers.title}}</h2>
            </div>
            <div *ngIf="tabs.scanned_values.hasTabToShow" class="tab" data-cy="tab-scanned_values">
              <h2 [className]="tabs.scanned_values.active? 'active': ''" (click)="tabs.scanned_values.activate()">{{tabs.scanned_values.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-pack_verification_shipping_container_contents_grid *ngIf="tabs.container_contents.active"
              #$tabs_container_contents
              [shippingContainerId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_shippingContainerId"
              [canAddManually]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_canAddManually"
              [startVerificationTaskId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_startVerificationTaskId"
              [isReadOnly]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isReadOnly"
              [scannedValues]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues"
              [isBlind]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isBlind"
              [expectedSerialNumbers]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_expectedSerialNumbers"
              [locationId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_locationId"
              (refocus)="set_focus($event)"
              (verification_tasks_cancelled)="on_verification_tasks_cancelled($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_container_contents')">
              </FootprintManager-pack_verification_shipping_container_contents_grid>
              <FootprintManager-scanned_values_grid *ngIf="tabs.invalid_serial_numbers.active"
              #$tabs_invalid_serial_numbers
              [scannedValues]="$tabs_invalid_serial_numbers_scanned_values_grid_inParams_scannedValues"
              ($refreshEvent)="refresh(false, false, '$tabs_invalid_serial_numbers')">
              </FootprintManager-scanned_values_grid>
              <FootprintManager-scanned_values_grid *ngIf="tabs.scanned_values.active"
              #$tabs_scanned_values
              [scannedValues]="$tabs_scanned_values_scanned_values_grid_inParams_scannedValues"
              ($refreshEvent)="refresh(false, false, '$tabs_scanned_values')">
              </FootprintManager-scanned_values_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>