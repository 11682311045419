import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_receiving_label_hellofreshfactor_reportServiceInParams {
  orderId?: number, shipmentId?: number, orderLineNumber?: number, copies?: number}

interface IReports_custom_receiving_label_hellofreshfactor_reportServiceData {
  data?: { result?: { Labels?: { OrderId?: number, OrderLookupCode?: string, OwnerReference?: string, ProjectLookupCode?: string, ProjectName?: string, OwnerLookupCode?: string, OwnerName?: string, ShipmentId?: number, ShipmentLookupCode?: string, LineNumber?: number, LotLookupCode?: string, VendorLotLookupCode?: string, MaterialLookupCode?: string, MaterialName?: string, MaterialDescription?: string, ShipToAddressFirstName?: string, ShipToAddressLastName?: string, ShipToAddressLine1?: string, ShipToAddressLine2?: string, ShipToAddressCity?: string, ShipToAddressState?: string, ShipToAddressPostalCode?: string, ShipToAddressAttentionOf?: string, VendorReference?: string, PackagedAmount?: number, PackagedShortName?: string }[] } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_receiving_label_hellofreshfactor_reportService extends ReportBaseService<IReports_custom_receiving_label_hellofreshfactor_reportServiceInParams, IReports_custom_receiving_label_hellofreshfactor_reportServiceData> {

  protected reportReferenceName = 'custom_receiving_label_hellofreshfactor_report';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_receiving_label_hellofreshfactor_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_receiving_label_hellofreshfactor_reportServiceInParams): Promise<IReports_custom_receiving_label_hellofreshfactor_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IReports_custom_receiving_label_hellofreshfactor_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_receiving_label_hellofreshfactor_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId ,
        shipmentId:  $report.inParams.shipmentId ,
        orderLineNumber:  $report.inParams.orderLineNumber ,
        copies:  $report.inParams.copies 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_receiving_label_report_multiple.get(dsParams);
      
      data.data = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
