import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_line_production_confirmation_setup_form_ComponentContextService } from './FootprintManager.manufacturing_order_line_production_confirmation_setup_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { FootprintManager_manufacturing_output_locations_dd_singleComponent } from './FootprintManager.manufacturing_output_locations_dd_single.component'
import { Manufacturing_manufacturing_orders_workshifts_dd_singleComponent } from './Manufacturing.manufacturing_orders_workshifts_dd_single.component'
import { Manufacturing_manufacturing_orders_equipment_dd_singleComponent } from './Manufacturing.manufacturing_orders_equipment_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_manufacturing_output_locations_dd_singleComponent),
    forwardRef(() => Manufacturing_manufacturing_orders_workshifts_dd_singleComponent),
    forwardRef(() => Manufacturing_manufacturing_orders_equipment_dd_singleComponent),
  ],
  selector: 'FootprintManager-manufacturing_order_line_production_confirmation_setup_form',
  templateUrl: './FootprintManager.manufacturing_order_line_production_confirmation_setup_form.component.html'
})
export class FootprintManager_manufacturing_order_line_production_confirmation_setup_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouse_id?: number, output_location_id?: number, project_id?: number, planned_quantity?: number, finished_good_id?: number, finished_good?: string } = { warehouse_id: null, output_location_id: null, project_id: null, planned_quantity: null, finished_good_id: null, finished_good: null };
  //#region Inputs
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('output_location_id') set $inParams_output_location_id(v: number) {
    this.inParams.output_location_id = v;
  }
  get $inParams_output_location_id(): number {
    return this.inParams.output_location_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('planned_quantity') set $inParams_planned_quantity(v: number) {
    this.inParams.planned_quantity = v;
  }
  get $inParams_planned_quantity(): number {
    return this.inParams.planned_quantity;
  }
  @Input('finished_good_id') set $inParams_finished_good_id(v: number) {
    this.inParams.finished_good_id = v;
  }
  get $inParams_finished_good_id(): number {
    return this.inParams.finished_good_id;
  }
  @Input('finished_good') set $inParams_finished_good(v: string) {
    this.inParams.finished_good = v;
  }
  get $inParams_finished_good(): string {
    return this.inParams.finished_good;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { workshift_id?: number, equipment_id?: number, output_location_id?: number } = { workshift_id: null, equipment_id: null, output_location_id: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ workshift_id?: number, equipment_id?: number, output_location_id?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    output_location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    workshift: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    equipment: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    output_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['output_location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Output location', true, false),
    workshift: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['workshift'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Work shift', false, false),
    equipment: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['equipment'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Equipment', false, false),
  };

  fieldsets = {
    details: new FieldsetModel(
      'Details',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  cacheValueFor_$fields_output_location_selector_inParams_warehouse_ids: number[];
  get $fields_output_location_selector_inParams_warehouse_ids(): number[] {
    const $form = this;
    const $utils = this.$utils;
    const expr = [$form.inParams.warehouse_id];
    
    if(!isEqual(this.cacheValueFor_$fields_output_location_selector_inParams_warehouse_ids, expr)) {
      this.cacheValueFor_$fields_output_location_selector_inParams_warehouse_ids = expr;
    }
    return this.cacheValueFor_$fields_output_location_selector_inParams_warehouse_ids;
  }

  get $fields_output_location_selector_inParams_project_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.project_id;
    
    return expr;
  }

  get $fields_workshift_selector_inParams_warehouse_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.warehouse_id;
    
    return expr;
  }

  get $fields_equipment_selector_inParams_warehouse_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.warehouse_id;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    output_location: this.fields.output_location.control.valueChanges
    ,
    workshift: this.fields.workshift.control.valueChanges
    ,
    equipment: this.fields.equipment.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_order_line_production_confirmation_setup_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Order line production confirmation setup';
  
    const $form = this;
    const $utils = this.$utils;

    (this.fields.output_location.control as SelectBoxModel).reset($form.inParams.output_location_id);

    await this.on_init_flow();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .output_location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_changed();
      });
    this.$formGroupFieldValidationObservables
      .workshift
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_workshift_changed();
      });
    this.$formGroupFieldValidationObservables
      .equipment
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_equipment_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_workshift_changed(event = null) {
    return this.on_workshift_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_workshift_changedInternal(
    $form: FootprintManager_manufacturing_order_line_production_confirmation_setup_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_production_confirmation_setup_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_production_confirmation_setup_form.on_workshift_changed');
  
  $form.outParams.workshift_id = $form.fields.workshift.control.value;
  
  console.log($form.outParams.workshift_id);
  
  $form.events.outParamsChange.emit();
  }
  on_equipment_changed(event = null) {
    return this.on_equipment_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_equipment_changedInternal(
    $form: FootprintManager_manufacturing_order_line_production_confirmation_setup_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_production_confirmation_setup_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_production_confirmation_setup_form.on_equipment_changed');
  
  $form.outParams.equipment_id = $form.fields.equipment.control.value;
  
  console.log($form.outParams.equipment_id);
  
  $form.events.outParamsChange.emit();
  }
  on_location_changed(event = null) {
    return this.on_location_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changedInternal(
    $form: FootprintManager_manufacturing_order_line_production_confirmation_setup_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_production_confirmation_setup_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_production_confirmation_setup_form.on_location_changed');
  
  $form.outParams.output_location_id = $form.fields.output_location.control.value;
  
  console.log($form.outParams.output_location_id);
  
  $form.events.outParamsChange.emit();
  }
  on_init_flow(event = null) {
    return this.on_init_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_flowInternal(
    $form: FootprintManager_manufacturing_order_line_production_confirmation_setup_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_production_confirmation_setup_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_production_confirmation_setup_form.on_init_flow');
  
  $form.outParams.output_location_id = $form.inParams.output_location_id;
  
  $form.events.outParamsChange.emit();
  }
  //#endregion private flows
}
