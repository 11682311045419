import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_footprint_events_grid_ComponentContextService } from './FootprintManager.footprint_events_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_footprint_events_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, EndProcessingDate?: string, ErrorMessage?: string, Label?: string, Notes?: string, PublishedDate?: string, StartProcessingDate?: string, FootPrintEventStatus?: { Name?: string }, FootPrintEventType?: { Name?: string }}

interface IFootprintManager_footprint_events_gridComponentInParams {
  statusIds?: number[], typeIds?: number[]}


class FootprintManager_footprint_events_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_footprint_events_gridComponent;
  entity: IFootprintManager_footprint_events_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    id: null,
    event_name: null,
    status: null,
    label: null,
    created_date: null,
    published_date: null,
    start_processing_date: null,
    end_processing_date: null,
    error_message: null,
    notes: null,
  }



  constructor(
    grid: FootprintManager_footprint_events_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_footprint_events_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.id = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.id,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.event_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.event_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.label = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.label,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.created_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.created_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.published_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.published_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.start_processing_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.start_processing_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.end_processing_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.end_processing_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.error_message = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.error_message,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.notes = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.notes,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_footprint_events_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_footprint_events_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      statusIds:  $grid.inParams.statusIds ,
      typeIds:  $grid.inParams.typeIds ,
    };
    const data = await this.$datasources.FootprintManager.ds_footprint_events_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_footprint_events_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.Id?.toString();
    (this.cells.event_name.displayControl as TextModel).text = $row.entity.FootPrintEventType?.Name;
    (this.cells.status.displayControl as TextModel).text = $row.entity.FootPrintEventStatus?.Name;
    (this.cells.label.displayControl as TextModel).text = $row.entity.Label;
    (this.cells.created_date.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.published_date.displayControl as TextModel).text = $row.entity.PublishedDate?.toString();
    (this.cells.start_processing_date.displayControl as TextModel).text = $row.entity.StartProcessingDate?.toString();
    (this.cells.end_processing_date.displayControl as TextModel).text = $row.entity.EndProcessingDate?.toString();
    (this.cells.error_message.displayControl as TextModel).text = $row.entity.ErrorMessage;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_footprint_events_gridComponentRowModel,
  $grid: FootprintManager_footprint_events_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_footprint_events_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'footprint_events_grid.on_row_data_loaded');
  // Set Date/Time Formats
  const format = `${$settings.FootprintManager.DateFormat}, ${$settings.FootprintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  if ($utils.isDefined($row.entity.CreatedSysDateTime)){
  $row.cells.created_date.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime , format);
  }
  
  if ($utils.isDefined($row.entity.PublishedDate)){
  $row.cells.published_date.displayControl.text = $utils.date.format($row.entity.PublishedDate , format);
  }
  
  if ($utils.isDefined($row.entity.EndProcessingDate)){
  $row.cells.end_processing_date.displayControl.text = $utils.date.format($row.entity.EndProcessingDate , format);
  }
  
  if ($utils.isDefined($row.entity.StartProcessingDate)){
  $row.cells.start_processing_date.displayControl.text = $utils.date.format($row.entity.StartProcessingDate , format);
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-footprint_events_grid',
  templateUrl: './FootprintManager.footprint_events_grid.component.html'
})
export class FootprintManager_footprint_events_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_footprint_events_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);


  inParams: IFootprintManager_footprint_events_gridComponentInParams = { statusIds: [], typeIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'id', 'Id', false, false, null, false),       event_name: new GridHeaderModel(new HeaderStyles(null, null), 'event_name', 'Event name', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       label: new GridHeaderModel(new HeaderStyles(null, null), 'label', 'Label', false, false, null, false),       created_date: new GridHeaderModel(new HeaderStyles(null, null), 'created_date', 'Created date', false, false, null, false),       published_date: new GridHeaderModel(new HeaderStyles(null, null), 'published_date', 'Published date', false, false, null, false),       start_processing_date: new GridHeaderModel(new HeaderStyles(null, null), 'start_processing_date', 'Start processing date', false, false, null, false),       end_processing_date: new GridHeaderModel(new HeaderStyles(null, null), 'end_processing_date', 'End processing date', false, false, null, false),       error_message: new GridHeaderModel(new HeaderStyles(null, null), 'error_message', 'Error message', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_footprint_events_gridComponentRowModel[] = [];

  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_footprint_events_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Footprint Events';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 100;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      statusIds:  $grid.inParams.statusIds ,
      typeIds:  $grid.inParams.typeIds ,
    };
    try {
      const data = await this.$datasources.FootprintManager.ds_footprint_events_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_footprint_events_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_footprint_events_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }



 
  close() {
    this.$finish.emit();
  }
}
