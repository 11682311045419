import { 
  IAppContextService,
  AppContextService,
  IPackageInfo,
  IEnvironmentInfo,
  IApplicationInfo 
} from './app-context.service';
import { Injectable, Inject } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Owners_ModuleContextService {
  private readonly applicationContext: IAppContextService;

  constructor(@Inject(AppContextService) appContext: IAppContextService) {
    this.applicationContext = appContext;
  }

  public get env(): IEnvironmentInfo {
    return this.applicationContext.env;
  }

  public get app(): IApplicationInfo {
    return this.applicationContext.app;
  }

  public get package(): IPackageInfo {
    return {
      name: 'Owners',
      uniqueIdentifier: 'datex-owners'
    };
  }
}