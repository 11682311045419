import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';

interface IManufacturing_manufacturing_order_reportServiceInParams {
}

interface IManufacturing_manufacturing_order_reportServiceData {
}

@Injectable({ providedIn: 'root' })
export class Manufacturing_manufacturing_order_reportService extends ReportBaseService<IManufacturing_manufacturing_order_reportServiceInParams, IManufacturing_manufacturing_order_reportServiceData> {

  protected reportReferenceName = 'manufacturing_order_report';
  protected appReferenceName = 'Manufacturing';

  constructor(
    $utils: UtilsService,
    private $datasources: Manufacturing_DatasourceService,
    private $logger: CleanupLoggerService
    ) {
      super($utils);
      $logger.log('Manufacturing', 'manufacturing_order_report');
  }

  override throwIfMissingRequiredInParams (inParams: IManufacturing_manufacturing_order_reportServiceInParams) {
  }

  protected async getData(inParams: IManufacturing_manufacturing_order_reportServiceInParams): Promise<IManufacturing_manufacturing_order_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IManufacturing_manufacturing_order_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IManufacturing_manufacturing_order_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];


    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
