<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.storage_categories.hasTabToShow" class="tab" data-cy="tab-storage_categories">
              <h2 [className]="tabs.storage_categories.active? 'active': ''" (click)="tabs.storage_categories.activate()">{{tabs.storage_categories.title}}</h2>
            </div>
            <div *ngIf="tabs.storage_category_rules.hasTabToShow" class="tab" data-cy="tab-storage_category_rules">
              <h2 [className]="tabs.storage_category_rules.active? 'active': ''" (click)="tabs.storage_category_rules.activate()">{{tabs.storage_category_rules.title}}</h2>
            </div>
            <div *ngIf="tabs.temperature_capture_reading_points.hasTabToShow" class="tab" data-cy="tab-temperature_capture_reading_points">
              <h2 [className]="tabs.temperature_capture_reading_points.active? 'active': ''" (click)="tabs.temperature_capture_reading_points.activate()">{{tabs.temperature_capture_reading_points.title}}</h2>
            </div>
            <div *ngIf="tabs.temperature_capture_reading_types.hasTabToShow" class="tab" data-cy="tab-temperature_capture_reading_types">
              <h2 [className]="tabs.temperature_capture_reading_types.active? 'active': ''" (click)="tabs.temperature_capture_reading_types.activate()">{{tabs.temperature_capture_reading_types.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-storage_categories_grid *ngIf="tabs.storage_categories.active"
              #$tabs_storage_categories
              ($refreshEvent)="refresh(false, false, '$tabs_storage_categories')">
              </FootprintManager-storage_categories_grid>
              <FootprintManager-storage_category_rules_grid *ngIf="tabs.storage_category_rules.active"
              #$tabs_storage_category_rules
              ($refreshEvent)="refresh(false, false, '$tabs_storage_category_rules')">
              </FootprintManager-storage_category_rules_grid>
              <FootprintManager-temperature_capture_reading_points_grid *ngIf="tabs.temperature_capture_reading_points.active"
              #$tabs_temperature_capture_reading_points
              ($refreshEvent)="refresh(false, false, '$tabs_temperature_capture_reading_points')">
              </FootprintManager-temperature_capture_reading_points_grid>
              <FootprintManager-temperature_capture_reading_types_grid *ngIf="tabs.temperature_capture_reading_types.active"
              #$tabs_temperature_capture_reading_types
              ($refreshEvent)="refresh(false, false, '$tabs_temperature_capture_reading_types')">
              </FootprintManager-temperature_capture_reading_types_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>