<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="logs" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_logs_button_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="manage_button" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div *ngIf="!tool.control.buttons.configuration_button.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.configuration_button.readOnly || tool.control.buttons.configuration_button.disabled"
                              [ngStyle]="tool.control.buttons.configuration_button.styles.style"
                              [ngClass]="tool.control.buttons.configuration_button.styles.classes"
                              [matTooltip]="tool.control.buttons.configuration_button.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_configuration_button_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.configuration_button.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.configuration_button.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.configuration_button.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.debugConfigButton.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.debugConfigButton.readOnly || tool.control.buttons.debugConfigButton.disabled"
                              [ngStyle]="tool.control.buttons.debugConfigButton.styles.style"
                              [ngClass]="tool.control.buttons.debugConfigButton.styles.classes"
                              [matTooltip]="tool.control.buttons.debugConfigButton.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_debug_configuration_button_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.debugConfigButton.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.debugConfigButton.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.debugConfigButton.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.transactions.hasTabToShow" class="tab" data-cy="tab-transactions">
              <h2 [className]="tabs.transactions.active? 'active': ''" (click)="tabs.transactions.activate()">{{tabs.transactions.title}}</h2>
            </div>
            <div *ngIf="tabs.tabInbound.hasTabToShow" class="tab" data-cy="tab-tabInbound">
              <h2 [className]="tabs.tabInbound.active? 'active': ''" (click)="tabs.tabInbound.activate()">{{tabs.tabInbound.title}}</h2>
            </div>
            <div *ngIf="tabs.tabOutbound.hasTabToShow" class="tab" data-cy="tab-tabOutbound">
              <h2 [className]="tabs.tabOutbound.active? 'active': ''" (click)="tabs.tabOutbound.activate()">{{tabs.tabOutbound.title}}</h2>
            </div>
          </div>
        
              <Ultraship-ultraship_messages_grid *ngIf="tabs.transactions.active"
              #$tabs_transactions
              [application_name]="$tabs_transactions_ultraship_messages_grid_inParams_application_name"
              ($refreshEvent)="refresh(false, false, '$tabs_transactions')">
              </Ultraship-ultraship_messages_grid>
              <ng-container
                *ngIf="tabs.tabInbound.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.tabInbound.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.tabInbound.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.tabInbound.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <Ultraship-ultraship_messages_grid
                   *ngIf="tabs.tabInbound.tabs.tabInboundOrder.active"
                  #$tabs_tabInbound_tabInboundOrder
                  [application_name]="$tabs_tabInbound_tabInboundOrder_ultraship_messages_grid_inParams_application_name"
                  [transaction_type]="$tabs_tabInbound_tabInboundOrder_ultraship_messages_grid_inParams_transaction_type"
                  ($refreshEvent)="refresh(false, false, '$tabs_tabInbound_tabInboundOrder')">
                  </Ultraship-ultraship_messages_grid>
                  <Ultraship-ultraship_messages_grid
                   *ngIf="tabs.tabInbound.tabs.tabInboundShipment.active"
                  #$tabs_tabInbound_tabInboundShipment
                  [application_name]="$tabs_tabInbound_tabInboundShipment_ultraship_messages_grid_inParams_application_name"
                  [transaction_type]="$tabs_tabInbound_tabInboundShipment_ultraship_messages_grid_inParams_transaction_type"
                  ($refreshEvent)="refresh(false, false, '$tabs_tabInbound_tabInboundShipment')">
                  </Ultraship-ultraship_messages_grid>
                  <Ultraship-ultraship_messages_grid
                   *ngIf="tabs.tabInbound.tabs.tabRawFIle.active"
                  #$tabs_tabInbound_tabRawFIle
                  [application_name]="$tabs_tabInbound_tabRawFIle_ultraship_messages_grid_inParams_application_name"
                  [transaction_type]="$tabs_tabInbound_tabRawFIle_ultraship_messages_grid_inParams_transaction_type"
                  ($refreshEvent)="refresh(false, false, '$tabs_tabInbound_tabRawFIle')">
                  </Ultraship-ultraship_messages_grid>
                  <Ultraship-ultraship_messages_grid
                   *ngIf="tabs.tabInbound.tabs.tabResponses.active"
                  #$tabs_tabInbound_tabResponses
                  [application_name]="$tabs_tabInbound_tabResponses_ultraship_messages_grid_inParams_application_name"
                  [transaction_type]="$tabs_tabInbound_tabResponses_ultraship_messages_grid_inParams_transaction_type"
                  ($refreshEvent)="refresh(false, false, '$tabs_tabInbound_tabResponses')">
                  </Ultraship-ultraship_messages_grid>
              </ng-container>
              <ng-container
                *ngIf="tabs.tabOutbound.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.tabOutbound.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.tabOutbound.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.tabOutbound.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <Ultraship-ultraship_messages_grid
                   *ngIf="tabs.tabOutbound.tabs.tabOutboundOrder.active"
                  #$tabs_tabOutbound_tabOutboundOrder
                  [application_name]="$tabs_tabOutbound_tabOutboundOrder_ultraship_messages_grid_inParams_application_name"
                  [transaction_type]="$tabs_tabOutbound_tabOutboundOrder_ultraship_messages_grid_inParams_transaction_type"
                  ($refreshEvent)="refresh(false, false, '$tabs_tabOutbound_tabOutboundOrder')">
                  </Ultraship-ultraship_messages_grid>
                  <Ultraship-ultraship_messages_grid
                   *ngIf="tabs.tabOutbound.tabs.tabOutboundOrderUpdate.active"
                  #$tabs_tabOutbound_tabOutboundOrderUpdate
                  [application_name]="$tabs_tabOutbound_tabOutboundOrderUpdate_ultraship_messages_grid_inParams_application_name"
                  [transaction_type]="$tabs_tabOutbound_tabOutboundOrderUpdate_ultraship_messages_grid_inParams_transaction_type"
                  ($refreshEvent)="refresh(false, false, '$tabs_tabOutbound_tabOutboundOrderUpdate')">
                  </Ultraship-ultraship_messages_grid>
                  <Ultraship-ultraship_messages_grid
                   *ngIf="tabs.tabOutbound.tabs.tabOutboundShipment.active"
                  #$tabs_tabOutbound_tabOutboundShipment
                  [application_name]="$tabs_tabOutbound_tabOutboundShipment_ultraship_messages_grid_inParams_application_name"
                  [transaction_type]="$tabs_tabOutbound_tabOutboundShipment_ultraship_messages_grid_inParams_transaction_type"
                  ($refreshEvent)="refresh(false, false, '$tabs_tabOutbound_tabOutboundShipment')">
                  </Ultraship-ultraship_messages_grid>
              </ng-container>
        </div>

      </ng-container>
    </div>
  </div>
</div>