import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_dock_appoinment_activity_card_ComponentContextService } from './FootprintManager.dock_appoinment_activity_card.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-dock_appoinment_activity_card',
  templateUrl: './FootprintManager.dock_appoinment_activity_card.component.html'
})
export class FootprintManager_dock_appoinment_activity_cardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(['creation','border-left']);

  inParams: { dockAppointmentId: number, activityDetails?: string, activityDate?: string, activityUser?: string, style?: string, title?: string } = { dockAppointmentId: null, activityDetails: null, activityDate: null, activityUser: null, style: null, title: null };
  //#region Inputs
  @Input('dockAppointmentId') set $inParams_dockAppointmentId(v: number) {
    this.inParams.dockAppointmentId = v;
  }
  get $inParams_dockAppointmentId(): number {
    return this.inParams.dockAppointmentId;
  }
  @Input('activityDetails') set $inParams_activityDetails(v: string) {
    this.inParams.activityDetails = v;
  }
  get $inParams_activityDetails(): string {
    return this.inParams.activityDetails;
  }
  @Input('activityDate') set $inParams_activityDate(v: string) {
    this.inParams.activityDate = v;
  }
  get $inParams_activityDate(): string {
    return this.inParams.activityDate;
  }
  @Input('activityUser') set $inParams_activityUser(v: string) {
    this.inParams.activityUser = v;
  }
  get $inParams_activityUser(): string {
    return this.inParams.activityUser;
  }
  @Input('style') set $inParams_style(v: string) {
    this.inParams.style = v;
  }
  get $inParams_style(): string {
    return this.inParams.style;
  }
  @Input('title') set $inParams_title(v: string) {
    this.inParams.title = v;
  }
  get $inParams_title(): string {
    return this.inParams.title;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  vars: { dateFormat?: string } = { };
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();


  formGroupContent: FormGroup = new FormGroup({
    activity_details: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    activity_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    activity_user: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });


  content = {
    fields: {
        activity_details: new FieldModel(new TextBoxModel(this.formGroupContent.controls['activity_details'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), '', false, false),
        activity_date: new FieldModel(new TextBoxModel(this.formGroupContent.controls['activity_date'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Activity date', false, false),
        activity_user: new FieldModel(new TextBoxModel(this.formGroupContent.controls['activity_user'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'User', false, false),
    },
    fieldsets: {
    newGroup1: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $card = this;
        const $utils = this.$utils;
        return '';
      }
    ),
}
  }


  
  //#region content fields inParams
  //#endregion content fields inParams



  get headerTitle() {
    const $card = this;
    const $utils = this.$utils;
    const expr = $card.inParams.title;
    return expr;
  }






  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_dock_appoinment_activity_card_ComponentContextService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.dockAppointmentId)) {
        this.$missingRequiredInParams.push('dockAppointmentId');
      }
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.$utils;

    (this.content.fields.activity_details.control as TextBoxModel).reset($card.inParams.activityDetails);
    (this.content.fields.activity_date.control as TextBoxModel).reset($card.inParams.activityDate);
    (this.content.fields.activity_user.control as TextBoxModel).reset($card.inParams.activityUser);


    await this.on_init();

    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {

  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $card: FootprintManager_dock_appoinment_activity_cardComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appoinment_activity_card_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var style = $card.inParams.style
  
  if ($utils.isDefined(style)){
  
      if (style === 'Creation'){
  
          $card.styles.setCreationClass();
      }
      else if (style === 'Attention'){
  
          $card.styles.setAttentionClass();
  
      }
  
  
  }
  
  
  }
  //#endregion private flows

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }


}
