import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_total_available_base_amount_widgetService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseIds?: number[], ownerId?: number, projectId?: number, lotId?: number, locationId?: number, materialIds?: number[], materialId?: number }): 
  Promise<{ result: { TotalAvailableBaseAmount?: number } }> 
  {
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_total_available_base_amount_widget/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouseIds?: number[], ownerId?: number, projectId?: number, lotId?: number, locationId?: number, materialIds?: number[], materialId?: number    }) {
      return false;
    }
}
