import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Dynamics365BusinessCentral_material_update_grid_ComponentContextService } from './Dynamics365BusinessCentral.material_update_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IDynamics365BusinessCentral_material_update_gridComponentEntity {
  m_id?: string, bc_lookup?: string, state?: string, operation?: string, insert_date?: string, bc_update_date?: string, application_name?: string, customer?: string, wl_lookup?: string, post_to_wl_date?: string, error_details?: string, q_id?: string, pld?: string}

interface IDynamics365BusinessCentral_material_update_gridComponentInParams {
  id?: string}


class Dynamics365BusinessCentral_material_update_gridComponentRowModel extends GridRowModel {
  grid: Dynamics365BusinessCentral_material_update_gridComponent;
  entity: IDynamics365BusinessCentral_material_update_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    bc_lookup: null,
    wl_lookup: null,
    state: null,
    operation: null,
    post_to_wl_date: null,
    error_details: null,
    pld: null,
  }



  constructor(
    grid: Dynamics365BusinessCentral_material_update_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Dynamics365BusinessCentral_ShellService,
private $datasources: Dynamics365BusinessCentral_DatasourceService,
private $flows: Dynamics365BusinessCentral_FlowService,
private $reports: Dynamics365BusinessCentral_ReportService,
private $localization: Dynamics365BusinessCentral_LocalizationService,
private $operations: Dynamics365BusinessCentral_OperationService,
private $logger: CleanupLoggerService,
private $context: Dynamics365BusinessCentral_material_update_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.bc_lookup = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.bc_lookup,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.wl_lookup = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.wl_lookup,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.state = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.state,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.operation = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.operation,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.post_to_wl_date = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.post_to_wl_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.error_details = new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      this.grid.headers.error_details,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.pld = new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      this.grid.headers.pld,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IDynamics365BusinessCentral_material_update_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.m_id,this.entity.bc_lookup].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IDynamics365BusinessCentral_material_update_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.m_id,this.entity.bc_lookup].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'm_id',this.entity.m_id);
    set($resultKey, 'bc_lookup',this.entity.bc_lookup);
    const inParams = {
      $keys:[$resultKey],
      materialid:  $grid.inParams.id ,
    };
    const data = await this.$datasources.Dynamics365BusinessCentral.ds_get_material_list_update_queue_materials_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IDynamics365BusinessCentral_material_update_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.bc_lookup.displayControl as TextModel).text = $row.entity.bc_lookup;
    (this.cells.wl_lookup.displayControl as TextModel).text = $row.entity.wl_lookup;
    (this.cells.state.displayControl as TextModel).text = $row.entity.state;
    (this.cells.operation.displayControl as TextModel).text = $row.entity.operation;
    (this.cells.post_to_wl_date.displayControl as TextModel).text = $row.entity.post_to_wl_date;
    (this.cells.error_details.displayControl as TextModel).text = $row.entity.error_details;
    (this.cells.pld.displayControl as TextModel).text = $row.entity.pld;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'error_details' : {
        this.on_error_click();
        break;
      }
      case 'pld' : {
        this.on_payload_click();
        break;
      }
    }
  }

  //#region private flows
  on_error_click(event = null) {
    return this.on_error_clickInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_error_clickInternal(
    $row: Dynamics365BusinessCentral_material_update_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_material_update_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    $context: Dynamics365BusinessCentral_material_update_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Dynamics365BusinessCentral', 'material_update_grid.on_error_click');
  
  let payload_json: any;
  let payload_xml: any;
  let payload_string: string = $row.entity.error_details
  
  try {
      payload_json = JSON.parse(payload_string);
  } catch (e) {
  };
  
  if (!$utils.isDefined(payload_json)) {
      try {
          payload_string = payload_string.trim();
          if (payload_string.startsWith("<") && payload_string.endsWith(">")) {
              payload_json = $utils.parseXml(payload_string)
              if ($utils.isDefined(payload_json)) {
                  payload_xml = payload_string;
                  payload_json = null;
              };
          };
      } catch (e) {
      };
  };
  
  if (!$utils.isDefined(payload_json) && !$utils.isDefined(payload_xml)) {
      $shell.Utilities.openInfoDialog("error", payload_string);
  };
  try {
      if ($utils.isDefined(payload_json)) {
          await $shell.Utilities.openview_json_payloadDialog({ payload: payload_json, title: "error" }, 'flyout', EModalSize.Large);
      };
      if ($utils.isDefined(payload_xml)) {
          await $shell.Utilities.openview_xml_payloadDialog({ payload: payload_xml, title: "error" }, 'flyout', EModalSize.Large);
      };
  } catch {
      $shell.Utilities.openInfoDialog("error", payload_string);
  };
  }
  on_payload_click(event = null) {
    return this.on_payload_clickInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_payload_clickInternal(
    $row: Dynamics365BusinessCentral_material_update_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_material_update_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    $context: Dynamics365BusinessCentral_material_update_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Dynamics365BusinessCentral', 'material_update_grid.on_payload_click');
  
  let payload_json: any;
  let payload_xml: any;
  let payload_string: string = $row.entity.pld;
  
  try {
      payload_json = JSON.parse(payload_string);
  } catch (e) {
  };
  
  if (!$utils.isDefined(payload_json)) {
      try {
          payload_string = payload_string.trim();
          if (payload_string.startsWith("<") && payload_string.endsWith(">")) {
              payload_json = $utils.parseXml(payload_string)
              if ($utils.isDefined(payload_json)) {
                  payload_xml = payload_string;
                  payload_json = null;
              };
          };
      } catch (e) {
      };
  };
  
  if (!$utils.isDefined(payload_json) && !$utils.isDefined(payload_xml)) {
      $shell.Utilities.openInfoDialog("payload", payload_string);
  };
  try {
      if ($utils.isDefined(payload_json)) {
          await $shell.Utilities.openview_json_payloadDialog({ payload: payload_json, title: "payload" }, 'flyout', EModalSize.Large);
      };
      if ($utils.isDefined(payload_xml)) {
          await $shell.Utilities.openview_xml_payloadDialog({ payload: payload_xml, title: "payload" }, 'flyout', EModalSize.Large);
      };
  } catch {
      $shell.Utilities.openInfoDialog("payload", payload_string);
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Dynamics365BusinessCentral-material_update_grid',
  templateUrl: './Dynamics365BusinessCentral.material_update_grid.component.html'
})
export class Dynamics365BusinessCentral_material_update_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IDynamics365BusinessCentral_material_update_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', null);

  fullTextSearch: string;

  inParams: IDynamics365BusinessCentral_material_update_gridComponentInParams = { id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     bc_lookup: new GridHeaderModel(new HeaderStyles(['left'], null), 'bc_lookup', 'BC ref', false, false, null, false),       wl_lookup: new GridHeaderModel(new HeaderStyles(['left'], null), 'wl_lookup', 'WL ref', false, false, null, false),       state: new GridHeaderModel(new HeaderStyles(['left'], null), 'state', 'State', false, false, null, false),       operation: new GridHeaderModel(new HeaderStyles(['left'], null), 'operation', 'Operation', false, false, null, false),       post_to_wl_date: new GridHeaderModel(new HeaderStyles(['left'], null), 'post_to_wl_date', 'Post date', false, false, null, false),       error_details: new GridHeaderModel(new HeaderStyles(['left'], null), 'error_details', 'Error', false, false, null, false),       pld: new GridHeaderModel(new HeaderStyles(['left'], null), 'pld', 'Payload', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Dynamics365BusinessCentral_material_update_gridComponentRowModel[] = [];

  @Input('id') set $inParams_id(value: any) {
    this.inParams['id'] = value;
  }
  get $inParams_id(): any {
    return this.inParams['id'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Dynamics365BusinessCentral_ShellService,
    private $datasources: Dynamics365BusinessCentral_DatasourceService,
    private $flows: Dynamics365BusinessCentral_FlowService,
    private $reports: Dynamics365BusinessCentral_ReportService,
    private $localization: Dynamics365BusinessCentral_LocalizationService,
    private $operations: Dynamics365BusinessCentral_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Dynamics365BusinessCentral_material_update_grid_ComponentContextService,
    ) {
    super();
    this.title = 'material_update_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      materialid:  $grid.inParams.id ,
    };
    try {
      const data = await this.$datasources.Dynamics365BusinessCentral.ds_get_material_list_update_queue_materials_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IDynamics365BusinessCentral_material_update_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Dynamics365BusinessCentral_material_update_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: Dynamics365BusinessCentral_material_update_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Dynamics365BusinessCentral_material_update_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    $context: Dynamics365BusinessCentral_material_update_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Dynamics365BusinessCentral', 'material_update_grid.on_init');
  
  for (const header in $grid.headers) {
      $grid.headers[header].name = ''
  }
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
