import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryTransformations_ds_inventory_transformation_target_lines_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { inventory_transformation_id: number, line_numbers?: number[], source_line_number?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, InventoryTransformationId?: number, SourceLineId?: number, StatusId?: number, TargetInventoryCreationDate?: string, TargetLicensePlateId?: number, TargetLicensePlateLookupCode?: string, TargetLocationId?: number, TargetLotId?: number, TargetPackageAmount?: number, TargetSerialRequests?: string, InventoryTransformationLineStatus?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, LocationContainer?: { Id?: number, Name?: string }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, Name?: string, ShortName?: string }, new_lp?: boolean }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventory_transformation_id)) {
      missingRequiredInParams.push('\'inventory_transformation_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_inventory_transformation_target_lines_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { inventory_transformation_id: number, line_numbers?: number[], source_line_number?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, InventoryTransformationId?: number, SourceLineId?: number, StatusId?: number, TargetInventoryCreationDate?: string, TargetLicensePlateId?: number, TargetLicensePlateLookupCode?: string, TargetLocationId?: number, TargetLotId?: number, TargetPackageAmount?: number, TargetSerialRequests?: string, InventoryTransformationLineStatus?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, LocationContainer?: { Id?: number, Name?: string }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, Name?: string, ShortName?: string }, new_lp?: boolean }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventory_transformation_id)) {
      missingRequiredInParams.push('\'inventory_transformation_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_inventory_transformation_target_lines_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { inventory_transformation_id: number, line_numbers?: number[], source_line_number?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, InventoryTransformationId?: number, SourceLineId?: number, StatusId?: number, TargetInventoryCreationDate?: string, TargetLicensePlateId?: number, TargetLicensePlateLookupCode?: string, TargetLocationId?: number, TargetLotId?: number, TargetPackageAmount?: number, TargetSerialRequests?: string, InventoryTransformationLineStatus?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, LocationContainer?: { Id?: number, Name?: string }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, Name?: string, ShortName?: string }, new_lp?: boolean }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventory_transformation_id)) {
      missingRequiredInParams.push('\'inventory_transformation_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_inventory_transformation_target_lines_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      inventory_transformation_id: number, line_numbers?: number[], source_line_number?: number    }) {
        if(isNil(inParams.inventory_transformation_id)) {
          return true; 
        }
      return false;
    }
}
