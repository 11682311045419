import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_return_to_stock_editor_ComponentContextService } from './FootprintManager.manufacturing_order_return_to_stock_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_manufacturing_order_return_to_stock_gridComponent } from './FootprintManager.manufacturing_order_return_to_stock_grid.component';
import { FootprintManager_manufacturing_staging_locations_dd_singleComponent } from './FootprintManager.manufacturing_staging_locations_dd_single.component'

type EntityType = { 
    id?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_manufacturing_order_return_to_stock_gridComponent),
    forwardRef(() => FootprintManager_manufacturing_staging_locations_dd_singleComponent),
  ],
  selector: 'FootprintManager-manufacturing_order_return_to_stock_editor',
  templateUrl: './FootprintManager.manufacturing_order_return_to_stock_editor.component.html'
})
export class FootprintManager_manufacturing_order_return_to_stock_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { manufacturing_order_id?: number, manufacturing_order_line_id?: number, finished_good?: string, warehouse_id?: number, project_id?: number, task_chain_head?: number, location_id?: number } = { manufacturing_order_id: null, manufacturing_order_line_id: null, finished_good: null, warehouse_id: null, project_id: null, task_chain_head: null, location_id: null };
  //#region Inputs
  @Input('manufacturing_order_id') set $inParams_manufacturing_order_id(v: number) {
    this.inParams.manufacturing_order_id = v;
  }
  get $inParams_manufacturing_order_id(): number {
    return this.inParams.manufacturing_order_id;
  }
  @Input('manufacturing_order_line_id') set $inParams_manufacturing_order_line_id(v: number) {
    this.inParams.manufacturing_order_line_id = v;
  }
  get $inParams_manufacturing_order_line_id(): number {
    return this.inParams.manufacturing_order_line_id;
  }
  @Input('finished_good') set $inParams_finished_good(v: string) {
    this.inParams.finished_good = v;
  }
  get $inParams_finished_good(): string {
    return this.inParams.finished_good;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('task_chain_head') set $inParams_task_chain_head(v: number) {
    this.inParams.task_chain_head = v;
  }
  get $inParams_task_chain_head(): number {
    return this.inParams.task_chain_head;
  }
  @Input('location_id') set $inParams_location_id(v: number) {
    this.inParams.location_id = v;
  }
  get $inParams_location_id(): number {
    return this.inParams.location_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { rows?: { materialId?: number, lot?: number, amountToReturn?: number, licensePlate?: number, packageId?: number }[] } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      return_to_stock: new ToolModel(new ButtonModel('return_to_stock', new ButtonStyles(['primary'], null), true, false, false, 'Return to stock', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    finished_good: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Finished product', false, false),
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Return to location', true, false),
  };

  fieldsets = {
    details: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      components: new TabItemModel(
        this.rootTabGroup, 
        'Components', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_components_manufacturing_order_return_to_stock_grid_inParams_manufacturing_order_line_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.manufacturing_order_line_id;
      
      return expr;
    }
  
    get $tabs_components_manufacturing_order_return_to_stock_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_components_manufacturing_order_return_to_stock_grid_inParams_location_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.location_id;
      
      return expr;
    }
  
    get $tabs_components_manufacturing_order_return_to_stock_grid_inParams_manufacturing_order_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.manufacturing_order_id;
      
      return expr;
    }
  
    get $tabs_components_manufacturing_order_return_to_stock_grid_inParams_task_chain_head(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.task_chain_head;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_components', { read: FootprintManager_manufacturing_order_return_to_stock_gridComponent }) $tabs_components: FootprintManager_manufacturing_order_return_to_stock_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_location_selector_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.inParams.warehouse_id;
    
    return expr;
  }

  get $fields_location_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.inParams.project_id;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    location: this.fields.location.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_order_return_to_stock_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.components,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Manufacturing order return to stock editor';
    
    await this.on_init_flow();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
    };

    const data = await this.$datasources.Manufacturing.ds_manufacturing_return_to_stock_dummy.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.finished_good.control as TextModel).text = $editor.inParams.finished_good;

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_components') {
        if (!isNil(this.$tabs_components) && !this.tabs.components.hidden && !this.tabs.components.removed) {
          this.$tabs_components.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootprintManager_manufacturing_order_return_to_stock_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_return_to_stock_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_return_to_stock_editor.on_cancel_clicked');
  
  $editor.close();
  }
  on_init_flow(event = null) {
    return this.on_init_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_flowInternal(
    $editor: FootprintManager_manufacturing_order_return_to_stock_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_return_to_stock_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_return_to_stock_editor.on_init_flow');
  $editor.fields.location.control.value = $editor.inParams.location_id;
  }
  grid_out_params_event_handle(event = null) {
    return this.grid_out_params_event_handleInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async grid_out_params_event_handleInternal(
    $editor: FootprintManager_manufacturing_order_return_to_stock_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_return_to_stock_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_return_to_stock_editor.grid_out_params_event_handle');
  $editor.vars.rows = $event;
  
  console.log(Array.isArray($editor.vars.rows));
  
  $editor.toolbar.return_to_stock.control.readOnly = false;
  }
  on_return_to_stock_clicked(event = null) {
    return this.on_return_to_stock_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_return_to_stock_clickedInternal(
    $editor: FootprintManager_manufacturing_order_return_to_stock_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_return_to_stock_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_return_to_stock_editor.on_return_to_stock_clicked');
  const componentsArray = Object.values($editor.vars.rows).flat();
  
  console.log("Extracted components:", componentsArray);
  
  // Add inventory on the license plate for the returned components inventory
  for (const component of componentsArray) {
  
      if( component.amountToReturn <= 0 )
          continue;
  
      const componentMove = await $flows.Inventory.create_inventory_on_existing_licenseplate_flow({licenseplateId: component.licensePlate,
                                                                                                   lotId: component.lot,
                                                                                                   packagingId: component.packageId,
                                                                                                   packagedAmount: component.amountToReturn,
                                                                                                   reasoncodeId: 38,
                                                                                                   operationcodeId: 7});
  
      if( $utils.isDefined(componentMove.reasons) )
      {
          await $shell.Utilities.openErrorDialog('Error returning inventory to stock', componentMove.reasons.toString());
          throw new Error(componentMove.reasons.toString());
      }                                                                                           
  }
  
  const title = `Inventory returned to stock.`;
  $shell.Utilities.openToaster(title,'',EToasterType.Success,{timeOut: 5000, closeButton:true, positionClass:EToasterPosition.bottomRight});
  await $editor.close();
  }
  //#endregion private flows
  
}
