<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="update($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="cancel($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="delete_plan" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="delete_plan($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-plan_group"
                     *ngIf="!fieldsets.plan_group.hidden && !fieldsets.plan_group.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.plan_group.collapsible }">
                    <div *ngIf="!fieldsets.plan_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.plan_group.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.plan_group.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.plan_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.plan_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.plan_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.plan_group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-is_active" *ngIf="!fields.is_active.hidden && !fields.is_active.removed" 
                            class="field-container full {{fields.is_active.invalid ? 'invalid' : ''}} {{fields.is_active.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.is_active.styles.style"
                            [ngClass]="fields.is_active.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.is_active.label + (fields.is_active.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.is_active.label}}<span *ngIf="fields.is_active.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_active"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_active.control.styles.style"
                                      [ngClass]="fields.is_active.control.styles.classes"
                                      [matTooltip]="fields.is_active.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.is_active.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_active.invalid">
                          <ng-container *ngFor="let error of fields.is_active.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-name" *ngIf="!fields.name.hidden && !fields.name.removed" 
                            class="field-container standard {{fields.name.invalid ? 'invalid' : ''}} {{fields.name.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.name.label + (fields.name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.name.label}}<span *ngIf="fields.name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.name.control.readOnly || fields.name.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"
                                [matTooltip]="fields.name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-template_order_id" *ngIf="!fields.template_order_id.hidden && !fields.template_order_id.removed" 
                            class="field-container standard {{fields.template_order_id.invalid ? 'invalid' : ''}} {{fields.template_order_id.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.template_order_id.styles.style"
                            [ngClass]="fields.template_order_id.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.template_order_id.label + (fields.template_order_id.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.template_order_id.label}}<span *ngIf="fields.template_order_id.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-orders_dd_single 
                            data-cy="selector"
                            [type]="fields.template_order_id.control.type"
                            formControlName="template_order_id"
                            (displayTextChange)="fields.template_order_id.control.displayText=$event"
                            [placeholder]="fields.template_order_id.control.placeholder"
                            [styles]="fields.template_order_id.control.styles"
                            [tooltip]="fields.template_order_id.control.tooltip"
                        >
                        </Manufacturing-orders_dd_single>
                        <ng-container *ngIf="fields.template_order_id.invalid">
                          <ng-container *ngFor="let error of fields.template_order_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.plan_group.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-plan_setup"
                     *ngIf="!fieldsets.plan_setup.hidden && !fieldsets.plan_setup.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.plan_setup.collapsible }">
                    <div *ngIf="!fieldsets.plan_setup.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.plan_setup.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.plan_setup.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.plan_setup.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.plan_setup.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.plan_setup.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.plan_setup.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-total_runs" *ngIf="!fields.total_runs.hidden && !fields.total_runs.removed" 
                            class="field-container standard {{fields.total_runs.invalid ? 'invalid' : ''}} {{fields.total_runs.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.total_runs.styles.style"
                            [ngClass]="fields.total_runs.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.total_runs.label + (fields.total_runs.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.total_runs.label}}<span *ngIf="fields.total_runs.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="total_runs"
                                matInput
                                numberBox
                                [format]="fields.total_runs.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.total_runs.control.readOnly || fields.total_runs.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.total_runs.control.placeholder}}"
                                [ngStyle]="fields.total_runs.control.styles.style"
                                [ngClass]="fields.total_runs.control.styles.classes"
                                [matTooltip]="fields.total_runs.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.total_runs.invalid">
                          <ng-container *ngFor="let error of fields.total_runs.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-offset_in_days" *ngIf="!fields.offset_in_days.hidden && !fields.offset_in_days.removed" 
                            class="field-container standard {{fields.offset_in_days.invalid ? 'invalid' : ''}} {{fields.offset_in_days.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.offset_in_days.styles.style"
                            [ngClass]="fields.offset_in_days.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.offset_in_days.label + (fields.offset_in_days.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.offset_in_days.label}}<span *ngIf="fields.offset_in_days.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="offset_in_days"
                                matInput
                                numberBox
                                [format]="fields.offset_in_days.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.offset_in_days.control.readOnly || fields.offset_in_days.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.offset_in_days.control.placeholder}}"
                                [ngStyle]="fields.offset_in_days.control.styles.style"
                                [ngClass]="fields.offset_in_days.control.styles.classes"
                                [matTooltip]="fields.offset_in_days.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.offset_in_days.invalid">
                          <ng-container *ngFor="let error of fields.offset_in_days.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.plan_setup.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-order_schedule"
                     *ngIf="!fieldsets.order_schedule.hidden && !fieldsets.order_schedule.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.order_schedule.collapsible }">
                    <div *ngIf="!fieldsets.order_schedule.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.order_schedule.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.order_schedule.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.order_schedule.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.order_schedule.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.order_schedule.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.order_schedule.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-scheduling" *ngIf="!fields.scheduling.hidden && !fields.scheduling.removed" 
                            class="field-container standard {{fields.scheduling.invalid ? 'invalid' : ''}} {{fields.scheduling.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.scheduling.styles.style"
                            [ngClass]="fields.scheduling.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.scheduling.label + (fields.scheduling.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.scheduling.label}}<span *ngIf="fields.scheduling.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.scheduling.control.readOnly || fields.scheduling.control.disabled"
                                [ngStyle]="fields.scheduling.control.styles.style"
                                [ngClass]="fields.scheduling.control.styles.classes"
                                [matTooltip]="fields.scheduling.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="schedule_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.scheduling.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.scheduling.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.scheduling.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.scheduling.invalid">
                          <ng-container *ngFor="let error of fields.scheduling.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.order_schedule.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>