import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WorkOrders_ds_accessorial_operationcodes_by_projectId_ddService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { projectId: number, fullTextSearch?: string, showAllOpCodes?: boolean, $top?: number, $skip?: number }): Promise<{ result?: { id?: number, name?: string }[], totalCount?: number }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WorkOrders/datasources/ds_accessorial_operationcodes_by_projectId_dd/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, fullTextSearch?: string, showAllOpCodes?: boolean, $keys: number[] }): Promise<{ result?: { id?: number, name?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WorkOrders/datasources/ds_accessorial_operationcodes_by_projectId_dd/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      projectId: number, fullTextSearch?: string, showAllOpCodes?: boolean    }) {
        if(isNil(inParams.projectId)) {
          return true; 
        }
      return false;
    }
}
