import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_billing_contract_line_creation_form_ComponentContextService } from './FootprintManager.billing_contract_line_creation_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Invoices_billing_aggregation_strategies_dd_singleComponent } from './Invoices.billing_aggregation_strategies_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_billing_aggregation_strategies_dd_singleComponent),
  ],
  selector: 'FootprintManager-billing_contract_line_creation_form',
  templateUrl: './FootprintManager.billing_contract_line_creation_form.component.html'
})
export class FootprintManager_billing_contract_line_creation_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { billingContractId?: number, strategyName?: string, statusId?: number } = { billingContractId: null, strategyName: null, statusId: null };
  //#region Inputs
  @Input('billingContractId') set $inParams_billingContractId(v: number) {
    this.inParams.billingContractId = v;
  }
  get $inParams_billingContractId(): number {
    return this.inParams.billingContractId;
  }
  @Input('strategyName') set $inParams_strategyName(v: string) {
    this.inParams.strategyName = v;
  }
  get $inParams_strategyName(): string {
    return this.inParams.strategyName;
  }
  @Input('statusId') set $inParams_statusId(v: number) {
    this.inParams.statusId = v;
  }
  get $inParams_statusId(): number {
    return this.inParams.statusId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    billing_aggregation_strategy: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, false, false, 'Save and edit', 'icon-ic_fluent_save_edit_20_regular', null)
    , false),
      discard: new ToolModel(new ButtonModel('discard', new ButtonStyles(['secondary'], null), false, false, false, 'Discard', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  fields = {
    billing_aggregation_strategy: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_aggregation_strategy'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Billing aggregation strategy', true, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Confirm the billing aggregation strategy for your new billing contract line',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_billing_aggregation_strategy_selector_inParams_name(): string {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.strategyName;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    billing_aggregation_strategy: this.fields.billing_aggregation_strategy.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_billing_contract_line_creation_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'New billing contract line';
  
    const $form = this;
    const $utils = this.$utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .billing_aggregation_strategy
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_select();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_billing_contract_line_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_line_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/09/2023
  
  // Delay to give the form time to load before setting the focus logic
  await new Promise(resolve => setTimeout(resolve, 100));
  $form.fields.billing_aggregation_strategy.control.focus();
  
  let strategies = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({ name: $form.inParams.strategyName })).result;
  
  //Skip the select when there is only one strategy
  if ($utils.isDefined(strategies)) {
      if (strategies.length === 1) {
          $form.fields.billing_aggregation_strategy.control.value = strategies[0].Id;
          $form.fields.billing_aggregation_strategy.control.displayText = strategies[0].Name;
          $form.on_save();
      };
  };
  }
  on_discard_clicked(event = null) {
    return this.on_discard_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discard_clickedInternal(
    $form: FootprintManager_billing_contract_line_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_line_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $form: FootprintManager_billing_contract_line_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_line_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/28/2024
  
  let under_development = true;
  
  let billing_aggregation_strategy_name = $form.fields.billing_aggregation_strategy.control.displayText;
  
  if (billing_aggregation_strategy_name === 'Accessorial Charges' ||
      billing_aggregation_strategy_name === 'Accessorial Charge Template' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Unit' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Shipment' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Weight' ||
      billing_aggregation_strategy_name === 'Inbound Handling by Volume' ||
      billing_aggregation_strategy_name === 'Inbound Handling by License Plate' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Unit' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Shipment' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Weight' ||
      billing_aggregation_strategy_name === 'Inbound Storage by Volume' ||
      billing_aggregation_strategy_name === 'Inbound Storage by License Plate' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Unit' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Shipment' ||
      billing_aggregation_strategy_name === 'Outbound Handling By Shipment' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Weight' ||
      billing_aggregation_strategy_name === 'Outbound Handling by Volume' ||
      billing_aggregation_strategy_name === 'Outbound Handling by License Plate' ||
      billing_aggregation_strategy_name === 'Outbound Shipment by Carrier Service Type' ||
      billing_aggregation_strategy_name === 'Initial Storage with Grace Period' ||
      billing_aggregation_strategy_name === 'Retainer' ||
      billing_aggregation_strategy_name === 'Recurring Storage') {
      under_development = false;
  };
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.billing_aggregation_strategy.control.value,
  );
  
  if (!allRequiredFieldHaveValue) {
      return;
  };
  
  if (under_development) {
  
      await $shell.FootprintManager.openErrorDialog('Under development.', `The ${billing_aggregation_strategy_name} line editor is under development.`);
  
  } else {
  
      let billing_contract_id = $form.inParams.billingContractId;
      let billing_aggregation_strategy_id = $form.fields.billing_aggregation_strategy.control.value
  
      const billing_contract_request = (await $flows.Invoices.create_billing_contract_line_flow({
          billingContractId: billing_contract_id,
          billingAggregationStrategyId: billing_aggregation_strategy_id
      }));
  
      if ($utils.isDefined(billing_contract_request)) {
  
          let billing_contract_line_id = billing_contract_request.billingContractLineId;
          let reason = billing_contract_request.reason;
  
          if ($utils.isDefined(billing_contract_line_id)) {
  
              if (billing_aggregation_strategy_name === 'Accessorial Charges' ||
                  billing_aggregation_strategy_name === 'Accessorial Charge Template' ||
                  billing_aggregation_strategy_name === 'Inbound Handling by Unit' ||
                  billing_aggregation_strategy_name === 'Inbound Handling by Shipment' ||
                  billing_aggregation_strategy_name === 'Inbound Handling by Weight' ||
                  billing_aggregation_strategy_name === 'Inbound Handling by Volume' ||
                  billing_aggregation_strategy_name === 'Inbound Handling by License Plate' ||
                  billing_aggregation_strategy_name === 'Inbound Storage by Unit' ||
                  billing_aggregation_strategy_name === 'Inbound Storage by Shipment' ||
                  billing_aggregation_strategy_name === 'Inbound Storage by Weight' ||
                  billing_aggregation_strategy_name === 'Inbound Storage by Volume' ||
                  billing_aggregation_strategy_name === 'Inbound Storage by License Plate' ||
                  billing_aggregation_strategy_name === 'Outbound Handling by Unit' ||
                  billing_aggregation_strategy_name === 'Outbound Handling by Shipment' ||
                  billing_aggregation_strategy_name === 'Outbound Handling By Shipment' ||
                  billing_aggregation_strategy_name === 'Outbound Handling by Weight' ||
                  billing_aggregation_strategy_name === 'Outbound Handling by Volume' ||
                  billing_aggregation_strategy_name === 'Outbound Handling by License Plate' ||
                  billing_aggregation_strategy_name === 'Outbound Shipment by Carrier Service Type' ||
                  billing_aggregation_strategy_name === 'Initial Storage with Grace Period' ||
                  billing_aggregation_strategy_name === 'Retainer' ||
                  billing_aggregation_strategy_name === 'Recurring Storage') {
                  await $shell.FootprintManager.openbilling_contract_line_editorDialog({ billingContractLineId: billing_contract_line_id, edit: true, billingAggregationStrategy: billing_aggregation_strategy_name, statusId: $form.inParams.statusId });
              };
              $form.close();
  
          } else {
  
              if ($utils.isDefined(reason)) {
                  await $shell.FootprintManager.openErrorDialog("Error", reason);
              } else {
                  await $shell.FootprintManager.openErrorDialog("Error", "Uncaught exception, please debug.");
              };
          };
      };
  };
  }
  on_select(event = null) {
    return this.on_selectInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selectInternal(
    $form: FootprintManager_billing_contract_line_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_line_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/10/2023
  
  /*
  //This is not working as intended, commenting it out for now.
  if ($utils.isDefined($form.fields.billing_aggregation_strategy)) {
      if ($utils.isDefined($form.fields.billing_aggregation_strategy.control.value)) {
          if ($utils.isDefined($form.fields.billing_aggregation_strategy.control.displayText)) {
              //Run the save right after selction, if anything is selected
              await $form.on_save();
          };
      };
  };
  */
  }
  //#endregion private flows
}
