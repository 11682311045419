import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Utilities.frontend.types'

@Injectable({ providedIn: 'root' })
export class Utilities_odata_encode_special_characters_for_urlService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { stringToEncode: string }): Promise<{ encodedString?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.stringToEncode)) {
      missingRequiredInParams.push('\'stringToEncode\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Utilities/functions/odata_encode_special_characters_for_url`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

