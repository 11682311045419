<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm_swap" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-substitution_group"
                     *ngIf="!fieldsets.substitution_group.hidden && !fieldsets.substitution_group.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.substitution_group.collapsible }">
                    <div *ngIf="!fieldsets.substitution_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.substitution_group.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.substitution_group.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.substitution_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.substitution_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.substitution_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.substitution_group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-description" *ngIf="!fields.description.hidden && !fields.description.removed" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}} {{fields.description.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.description.control.styles.style"
                              [ngClass]="fields.description.control.styles.classes"
                              [matTooltip]="fields.description.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.description.control.text }}</div>
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-substitute" *ngIf="!fields.substitute.hidden && !fields.substitute.removed" 
                            class="field-container full {{fields.substitute.invalid ? 'invalid' : ''}} {{fields.substitute.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.substitute.styles.style"
                            [ngClass]="fields.substitute.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.substitute.label + (fields.substitute.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.substitute.label}}<span *ngIf="fields.substitute.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-material_substitutions_dd_single 
                            data-cy="selector"
                            [type]="fields.substitute.control.type"
                            formControlName="substitute"
                            (displayTextChange)="fields.substitute.control.displayText=$event"
                            [placeholder]="fields.substitute.control.placeholder"
                            [styles]="fields.substitute.control.styles"
                            [tooltip]="fields.substitute.control.tooltip"
                          [material_ids]="$fields_substitute_selector_inParams_material_ids"
                          [warehouse_id]="$fields_substitute_selector_inParams_warehouse_id"
                          [substitution_ids]="$fields_substitute_selector_inParams_substitution_ids"
                          [needed_amount]="$fields_substitute_selector_inParams_needed_amount"
                        >
                        </Manufacturing-material_substitutions_dd_single>
                        <ng-container *ngIf="fields.substitute.invalid">
                          <ng-container *ngFor="let error of fields.substitute.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.substitution_group.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>