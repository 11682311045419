<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="linecount" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes"
          [matTooltip]="row.cells.Id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.error.displayControl.styles.style"
          [ngClass]="row.cells.error.displayControl.styles.classes"
          [matTooltip]="row.cells.error.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.error.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes"
          [matTooltip]="row.cells.owner.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-owners_dd_single 
        data-cy="selector"
        [type]="row.cells.owner.editControl.type"
        [formControl]="row.formGroup.controls['owner_edit']"
        (displayTextChange)="row.cells.owner.editControl.displayText=$event"
        [placeholder]="row.cells.owner.editControl.placeholder"
        [styles]="row.cells.owner.editControl.styles"
        [tooltip]="row.cells.owner.editControl.tooltip"
      [statusId]="row.$fields_owner_selector_inParams_statusId"
      [projectId]="row.$fields_owner_selector_inParams_projectId"
    >
    </ExcelMaterialImport-owners_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-projects_dd_single 
        data-cy="selector"
        [type]="row.cells.project.editControl.type"
        [formControl]="row.formGroup.controls['project_edit']"
        (displayTextChange)="row.cells.project.editControl.displayText=$event"
        [placeholder]="row.cells.project.editControl.placeholder"
        [styles]="row.cells.project.editControl.styles"
        [tooltip]="row.cells.project.editControl.tooltip"
      [statusId]="row.$fields_project_selector_inParams_statusId"
    >
    </ExcelMaterialImport-projects_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['lookupcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.lookupcode.editControl.readOnly || row.cells.lookupcode.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.lookupcode.editControl.placeholder}}"
            [ngStyle]="row.cells.lookupcode.editControl.styles.style"
            [ngClass]="row.cells.lookupcode.editControl.styles.classes"
            [matTooltip]="row.cells.lookupcode.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.uom.displayControl.styles.style"
          [ngClass]="row.cells.uom.displayControl.styles.classes"
          [matTooltip]="row.cells.uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.uom.editControl.type"
        [formControl]="row.formGroup.controls['uom_edit']"
        (displayTextChange)="row.cells.uom.editControl.displayText=$event"
        [placeholder]="row.cells.uom.editControl.placeholder"
        [styles]="row.cells.uom.editControl.styles"
        [tooltip]="row.cells.uom.editControl.tooltip"
    >
    </ExcelMaterialImport-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="sub_packaging_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.sub_packaging_uom.displayControl.styles.style"
          [ngClass]="row.cells.sub_packaging_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.sub_packaging_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.sub_packaging_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.sub_packaging_uom.editControl.type"
        [formControl]="row.formGroup.controls['sub_packaging_uom_edit']"
        (displayTextChange)="row.cells.sub_packaging_uom.editControl.displayText=$event"
        [placeholder]="row.cells.sub_packaging_uom.editControl.placeholder"
        [styles]="row.cells.sub_packaging_uom.editControl.styles"
        [tooltip]="row.cells.sub_packaging_uom.editControl.tooltip"
    >
    </ExcelMaterialImport-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="sub_packaging_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.sub_packaging_quantity.displayControl.styles.style"
          [ngClass]="row.cells.sub_packaging_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.sub_packaging_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.sub_packaging_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['sub_packaging_quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.sub_packaging_quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.sub_packaging_quantity.editControl.readOnly || row.cells.sub_packaging_quantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.sub_packaging_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.sub_packaging_quantity.editControl.styles.style"
            [ngClass]="row.cells.sub_packaging_quantity.editControl.styles.classes"
            [matTooltip]="row.cells.sub_packaging_quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="upc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.upc.displayControl.styles.style"
          [ngClass]="row.cells.upc.displayControl.styles.classes"
          [matTooltip]="row.cells.upc.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.upc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['upc_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.upc.editControl.readOnly || row.cells.upc.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.upc.editControl.placeholder}}"
            [ngStyle]="row.cells.upc.editControl.styles.style"
            [ngClass]="row.cells.upc.editControl.styles.classes"
            [matTooltip]="row.cells.upc.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_ready_to_ship">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.is_ready_to_ship.displayControl.styles.style"
          [ngClass]="row.cells.is_ready_to_ship.displayControl.styles.classes"
          [matTooltip]="row.cells.is_ready_to_ship.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.is_ready_to_ship.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.is_ready_to_ship.editControl.type"
        [formControl]="row.formGroup.controls['is_ready_to_ship_edit']"
        (displayTextChange)="row.cells.is_ready_to_ship.editControl.displayText=$event"
        [placeholder]="row.cells.is_ready_to_ship.editControl.placeholder"
        [styles]="row.cells.is_ready_to_ship.editControl.styles"
        [tooltip]="row.cells.is_ready_to_ship.editControl.tooltip"
    >
    </ExcelMaterialImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pallet_tie">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pallet_tie.displayControl.styles.style"
          [ngClass]="row.cells.pallet_tie.displayControl.styles.classes"
          [matTooltip]="row.cells.pallet_tie.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pallet_tie.displayControl.text | formatText : row.cells.pallet_tie.displayControl.formatType : row.cells.pallet_tie.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['pallet_tie_edit']"
            matInput
            numberBox
            [format]="row.cells.pallet_tie.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.pallet_tie.editControl.readOnly || row.cells.pallet_tie.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.pallet_tie.editControl.placeholder}}"
            [ngStyle]="row.cells.pallet_tie.editControl.styles.style"
            [ngClass]="row.cells.pallet_tie.editControl.styles.classes"
            [matTooltip]="row.cells.pallet_tie.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pallet_high">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.pallet_high.displayControl.styles.style"
          [ngClass]="row.cells.pallet_high.displayControl.styles.classes"
          [matTooltip]="row.cells.pallet_high.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.pallet_high.displayControl.text | formatText : row.cells.pallet_high.displayControl.formatType : row.cells.pallet_high.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['pallet_high_edit']"
            matInput
            numberBox
            [format]="row.cells.pallet_high.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.pallet_high.editControl.readOnly || row.cells.pallet_high.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.pallet_high.editControl.placeholder}}"
            [ngStyle]="row.cells.pallet_high.editControl.styles.style"
            [ngClass]="row.cells.pallet_high.editControl.styles.classes"
            [matTooltip]="row.cells.pallet_high.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="net_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.net_weight.displayControl.styles.style"
          [ngClass]="row.cells.net_weight.displayControl.styles.classes"
          [matTooltip]="row.cells.net_weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.net_weight.displayControl.text | formatText : row.cells.net_weight.displayControl.formatType : row.cells.net_weight.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['net_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.net_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.net_weight.editControl.readOnly || row.cells.net_weight.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.net_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.net_weight.editControl.styles.style"
            [ngClass]="row.cells.net_weight.editControl.styles.classes"
            [matTooltip]="row.cells.net_weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipping_weight.displayControl.styles.style"
          [ngClass]="row.cells.shipping_weight.displayControl.styles.classes"
          [matTooltip]="row.cells.shipping_weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipping_weight.displayControl.text | formatText : row.cells.shipping_weight.displayControl.formatType : row.cells.shipping_weight.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shipping_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.shipping_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.shipping_weight.editControl.readOnly || row.cells.shipping_weight.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipping_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.shipping_weight.editControl.styles.style"
            [ngClass]="row.cells.shipping_weight.editControl.styles.classes"
            [matTooltip]="row.cells.shipping_weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.weight_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.weight_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.weight_uom.editControl.type"
        [formControl]="row.formGroup.controls['weight_uom_edit']"
        (displayTextChange)="row.cells.weight_uom.editControl.displayText=$event"
        [placeholder]="row.cells.weight_uom.editControl.placeholder"
        [styles]="row.cells.weight_uom.editControl.styles"
        [tooltip]="row.cells.weight_uom.editControl.tooltip"
      [typeId]="row.$fields_weight_uom_selector_inParams_typeId"
    >
    </ExcelMaterialImport-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes"
          [matTooltip]="row.cells.length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.length.displayControl.text | formatText : row.cells.length.displayControl.formatType : row.cells.length.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['length_edit']"
            matInput
            numberBox
            [format]="row.cells.length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.length.editControl.readOnly || row.cells.length.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.length.editControl.placeholder}}"
            [ngStyle]="row.cells.length.editControl.styles.style"
            [ngClass]="row.cells.length.editControl.styles.classes"
            [matTooltip]="row.cells.length.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes"
          [matTooltip]="row.cells.width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.width.displayControl.text | formatText : row.cells.width.displayControl.formatType : row.cells.width.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['width_edit']"
            matInput
            numberBox
            [format]="row.cells.width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.width.editControl.readOnly || row.cells.width.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.width.editControl.placeholder}}"
            [ngStyle]="row.cells.width.editControl.styles.style"
            [ngClass]="row.cells.width.editControl.styles.classes"
            [matTooltip]="row.cells.width.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes"
          [matTooltip]="row.cells.height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.height.displayControl.text | formatText : row.cells.height.displayControl.formatType : row.cells.height.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['height_edit']"
            matInput
            numberBox
            [format]="row.cells.height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.height.editControl.readOnly || row.cells.height.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.height.editControl.placeholder}}"
            [ngStyle]="row.cells.height.editControl.styles.style"
            [ngClass]="row.cells.height.editControl.styles.classes"
            [matTooltip]="row.cells.height.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dimension_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.dimension_uom.displayControl.styles.style"
          [ngClass]="row.cells.dimension_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.dimension_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.dimension_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.dimension_uom.editControl.type"
        [formControl]="row.formGroup.controls['dimension_uom_edit']"
        (displayTextChange)="row.cells.dimension_uom.editControl.displayText=$event"
        [placeholder]="row.cells.dimension_uom.editControl.placeholder"
        [styles]="row.cells.dimension_uom.editControl.styles"
        [tooltip]="row.cells.dimension_uom.editControl.tooltip"
      [typeId]="row.$fields_dimension_uom_selector_inParams_typeId"
    >
    </ExcelMaterialImport-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="net_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.net_volume.displayControl.styles.style"
          [ngClass]="row.cells.net_volume.displayControl.styles.classes"
          [matTooltip]="row.cells.net_volume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.net_volume.displayControl.text | formatText : row.cells.net_volume.displayControl.formatType : row.cells.net_volume.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['net_volume_edit']"
            matInput
            numberBox
            [format]="row.cells.net_volume.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.net_volume.editControl.readOnly || row.cells.net_volume.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.net_volume.editControl.placeholder}}"
            [ngStyle]="row.cells.net_volume.editControl.styles.style"
            [ngClass]="row.cells.net_volume.editControl.styles.classes"
            [matTooltip]="row.cells.net_volume.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipping_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.shipping_volume.displayControl.styles.style"
          [ngClass]="row.cells.shipping_volume.displayControl.styles.classes"
          [matTooltip]="row.cells.shipping_volume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.shipping_volume.displayControl.text | formatText : row.cells.shipping_volume.displayControl.formatType : row.cells.shipping_volume.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shipping_volume_edit']"
            matInput
            numberBox
            [format]="row.cells.shipping_volume.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.shipping_volume.editControl.readOnly || row.cells.shipping_volume.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipping_volume.editControl.placeholder}}"
            [ngStyle]="row.cells.shipping_volume.editControl.styles.style"
            [ngClass]="row.cells.shipping_volume.editControl.styles.classes"
            [matTooltip]="row.cells.shipping_volume.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="volume_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.volume_uom.displayControl.styles.style"
          [ngClass]="row.cells.volume_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.volume_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.volume_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.volume_uom.editControl.type"
        [formControl]="row.formGroup.controls['volume_uom_edit']"
        (displayTextChange)="row.cells.volume_uom.editControl.displayText=$event"
        [placeholder]="row.cells.volume_uom.editControl.placeholder"
        [styles]="row.cells.volume_uom.editControl.styles"
        [tooltip]="row.cells.volume_uom.editControl.tooltip"
      [typeId]="row.$fields_volume_uom_selector_inParams_typeId"
    >
    </ExcelMaterialImport-measurement_units_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
