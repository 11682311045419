import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_support_tests_grid_ComponentContextService } from './FootprintManager.support_tests_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_support_tests_gridComponentEntity {
  id?: number, test_name?: string, records_available?: number, records_returned?: number, elapsed_seconds?: number, seconds_per_record?: number, notes?: string}

interface IFootprintManager_support_tests_gridComponentInParams {
  test_ids?: number[], test_runs?: number, rows_override?: number}


class FootprintManager_support_tests_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_support_tests_gridComponent;
  entity: IFootprintManager_support_tests_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    id: null,
    test_name: null,
    records_available: null,
    records_returned: null,
    elapsed_seconds: null,
    seconds_per_record: null,
    notes: null,
  }



  constructor(
    grid: FootprintManager_support_tests_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_support_tests_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.id = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.id,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.test_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.test_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.records_available = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.records_available,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.records_returned = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.records_returned,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.elapsed_seconds = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.elapsed_seconds,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.seconds_per_record = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.seconds_per_record,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.notes = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.notes,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_support_tests_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_support_tests_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      test_ids:  $grid.inParams.test_ids ,
      test_runs:  $grid.inParams.test_runs ,
      rows_override:  $grid.inParams.rows_override ,
    };
    const data = await this.$datasources.FootprintManager.ds_support_tests_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_support_tests_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.id?.toString();
    (this.cells.test_name.displayControl as TextModel).text = $row.entity.test_name;
    (this.cells.records_available.displayControl as TextModel).text = $row.entity.records_available?.toString();
    (this.cells.records_returned.displayControl as TextModel).text = $row.entity.records_returned?.toString();
    (this.cells.elapsed_seconds.displayControl as TextModel).text = $row.entity.elapsed_seconds?.toString();
    (this.cells.seconds_per_record.displayControl as TextModel).text = $row.entity.seconds_per_record?.toString();
    (this.cells.notes.displayControl as TextModel).text = $row.entity.notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_support_tests_gridComponentRowModel,
  $grid: FootprintManager_support_tests_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_support_tests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/25/2024
  
  const elapsed_seconds = $row.entity.elapsed_seconds;
  const seconds_per_record = $row.entity.seconds_per_record;
  const test_name = $row.entity.test_name;
  
  if (test_name !== "Average" && test_name !== "Total") {
  
      const style_nameAndUnit: string = 'color';
      const style_value: string = 'rgba(250, 0, 20, 1)';
  
      if (elapsed_seconds > 5 || seconds_per_record > 0.1) {
          $row.cells.elapsed_seconds.styles.setStyle(style_nameAndUnit, style_value);
          $row.cells.id.styles.setStyle(style_nameAndUnit, style_value);
          $row.cells.notes.styles.setStyle(style_nameAndUnit, style_value);
          $row.cells.records_available.styles.setStyle(style_nameAndUnit, style_value);
          $row.cells.records_returned.styles.setStyle(style_nameAndUnit, style_value);
          $row.cells.seconds_per_record.styles.setStyle(style_nameAndUnit, style_value);
          $row.cells.test_name.styles.setStyle(style_nameAndUnit, style_value);
      };
  };
  
  const start_time = new Date($grid.vars.start);
  const row_elapsed_seconds = Number((((new Date()).getTime() - start_time.getTime()) / 1000).toFixed(4));
  
  $grid.topToolbar.elapsed.hidden = false;
  $grid.topToolbar.elapsed.control.label = `The tests took ${row_elapsed_seconds} seconds to return and load on the grid.`;
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-support_tests_grid',
  templateUrl: './FootprintManager.support_tests_grid.component.html'
})
export class FootprintManager_support_tests_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_support_tests_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);


  inParams: IFootprintManager_support_tests_gridComponentInParams = { test_ids: [], test_runs: null, rows_override: null };


  //#region Variables
  vars: { start?: string } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'id', 'Test ID', false, false, null, false),       test_name: new GridHeaderModel(new HeaderStyles(null, null), 'test_name', 'Test name', false, false, null, false),       records_available: new GridHeaderModel(new HeaderStyles(null, null), 'records_available', 'Records available', false, false, null, false),       records_returned: new GridHeaderModel(new HeaderStyles(null, null), 'records_returned', 'Records returned', false, false, null, false),       elapsed_seconds: new GridHeaderModel(new HeaderStyles(null, null), 'elapsed_seconds', 'Elapsed seconds', false, false, null, false),       seconds_per_record: new GridHeaderModel(new HeaderStyles(null, null), 'seconds_per_record', 'Seconds per record', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_support_tests_gridComponentRowModel[] = [];

  @Input('test_ids') set $inParams_test_ids(value: any) {
    this.inParams['test_ids'] = value;
  }
  get $inParams_test_ids(): any {
    return this.inParams['test_ids'] ;
  }
  @Input('test_runs') set $inParams_test_runs(value: any) {
    this.inParams['test_runs'] = value;
  }
  get $inParams_test_runs(): any {
    return this.inParams['test_runs'] ;
  }
  @Input('rows_override') set $inParams_rows_override(value: any) {
    this.inParams['rows_override'] = value;
  }
  get $inParams_rows_override(): any {
    return this.inParams['rows_override'] ;
  }

  topToolbar = {
      elapsed: new ToolModel(new ButtonModel('elapsed', new ButtonStyles(null, null), false, false, false, 'Elapsed time', 'icon-ic_fluent_timer_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      refresh_grid: new ToolModel(new ButtonModel('refresh_grid', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_arrow_clockwise_20_regular', () => { const $utils = this.$utils; const $grid = this; return 'Refresh'; })
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_support_tests_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Support tests grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 250;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      test_ids:  $grid.inParams.test_ids ,
      test_runs:  $grid.inParams.test_runs ,
      rows_override:  $grid.inParams.rows_override ,
    };
    try {
      const data = await this.$datasources.FootprintManager.ds_support_tests_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_support_tests_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_support_tests_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $grid: FootprintManager_support_tests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_support_tests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/24/2024
  
  $grid.vars.start = (new Date()).toString();
  $grid.refresh();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_support_tests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_support_tests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/24/2024
  
  $grid.topToolbar.elapsed.hidden = true;
  $grid.vars.start = (new Date()).toString();
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootprintManager_support_tests_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_support_tests_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/24/2024
  
  const start_time = new Date($grid.vars.start);
  const elapsed_seconds = Number((((new Date()).getTime() - start_time.getTime()) / 1000).toFixed(4));
  
  $grid.topToolbar.elapsed.hidden = false;
  $grid.topToolbar.elapsed.control.label = `The tests took ${elapsed_seconds} seconds to return and load on the grid.`;
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
