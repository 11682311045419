import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_work_class_creation_form_ComponentContextService } from './FootprintManager.work_class_creation_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-work_class_creation_form',
  templateUrl: './FootprintManager.work_class_creation_form.component.html'
})
export class FootprintManager_work_class_creation_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { workClassId?: number } = { workClassId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ workClassId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Code', true, false),
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Name', true, false),
    priority: new FieldModel(new NumberBoxModel(this.formGroup.controls['priority'] as DatexFormControl, null, false, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Priority', true, false),
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Description', false, false),
  };

  fieldsets = {
    General: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    name: this.fields.name.control.valueChanges
    ,
    priority: this.fields.priority.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_work_class_creation_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create work class';
  
    const $form = this;
    const $utils = this.$utils;

    
    
    (this.fields.priority.control as NumberBoxModel).reset(0);
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_value_changed();
      });
    this.$formGroupFieldValidationObservables
      .name
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_value_changed();
      });
    this.$formGroupFieldValidationObservables
      .priority
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_value_changed();
      });
    this.$formGroupFieldValidationObservables
      .description
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_value_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_value_changed(event = null) {
    return this.on_value_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_value_changedInternal(
    $form: FootprintManager_work_class_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_work_class_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      if ($utils.isDefinedTrimmed($form.fields.lookupcode.control.value) && !$utils.isDefinedTrimmed($form.fields.name.control.value)) {
          $form.fields.name.control.value = $form.fields.lookupcode.control.value;
      }
      else if ($utils.isDefinedTrimmed($form.fields.name.control.value) && !$utils.isDefinedTrimmed($form.fields.lookupcode.control.value)) {
          $form.fields.lookupcode.control.value = $form.fields.name.control.value;
      }
  
      if (checkRequiredFields()) {
          if (!await foundDuplicates($form.fields.lookupcode.control.value)) {
              let result = (await $flows.LaborManagement.create_work_class_flow({
                  name: $form.fields.name.control.value.trim(),
                  lookupcode: $form.fields.lookupcode.control.value.trim(),
                  description: $form.fields.description.control.value,
                  priority: $form.fields.priority.control.value
              }));
  
              if ($utils.isDefined(result?.reasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
              }
  
              $form.outParams.workClassId = result.workClassId;
  
              $form.close();
          } else {
              $form.fields.name.control.value = '';
              $form.fields.lookupcode.control.value = '';
              throw new Error(`Workclass with Lookupcode ${$form.fields.lookupcode.control.value} already exists`);
          }
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.LaborManagement.openErrorDialog('Error creating new work class', error.message);
  }
  
  
  /*******************************************
   * FUNCTIONS
  ********************************************/
  function checkRequiredFields() {
      let isAllDefined = true;
  
      for (let key in $form.fields) {
          if ($form.fields[key].required && !$utils.isDefined($form.fields[key].control.value)) {
              isAllDefined = false;
          }
      }
  
      return isAllDefined;
  }
  
  async function foundDuplicates(lookupcode: string) {
      var hasDuplicates = false;
  
      let workClasses = (await $datasources.LaborManagement.ds_get_work_class_by_lookupcode.get({ lookupcode: lookupcode })).result
  
      if (workClasses?.LookupCode === lookupcode) {
          hasDuplicates = true;
      }
          
      return hasDuplicates;
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_work_class_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_work_class_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  }
  //#endregion private flows
}
