import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_pallet_transactions_grid_ComponentContextService } from './FootprintManager.pallet_transactions_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { FootprintManager_pallet_classes_dd_singleComponent } from './FootprintManager.pallet_classes_dd_single.component'


interface IFootprintManager_pallet_transactions_gridComponentEntity {
  Id?: number, AccountId?: number, Amount?: number, CarrierId?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Date?: string, Employee?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, PalletClassId?: number, ProjectId?: number, Reference?: string, ReferenceCarrierId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, OrderLookups?: { OrderId?: number, Order?: { Id?: number, LookupCode?: string, OrderClass?: { Id?: number, OrderClassTypeId?: number, OrderTypeId?: number } } }[] }, Account?: { Name?: string }, Carrier?: { Name?: string }, PalletClass?: { Name?: string }, Project?: { LookupCode?: string }}

interface IFootprintManager_pallet_transactions_gridComponentInParams {
  project_id?: number, pallet_class_id?: number, project_lookup_code?: string, pallet_class_name?: string}


class FootprintManager_pallet_transactions_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_pallet_transactions_gridComponent;
  entity: IFootprintManager_pallet_transactions_gridComponentEntity;


  options: { edit_transaction: ButtonModel } = {
    edit_transaction: new ButtonModel('edit_transaction', new ButtonStyles(null, null), false, false, false, 'Edit transaction', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Project: null,
    Order: null,
    PalletClass: null,
    Amount: null,
    Reference: null,
    Employee: null,
    Date: null,
    CreatedSysDateTime: null,
    CreatedSysUser: null,
    ModifiedSysDateTime: null,
    ModifiedSysUser: null,
    Notes: null,
  }



  constructor(
    grid: FootprintManager_pallet_transactions_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.Project = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Project,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Order = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.Order,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.PalletClass = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.PalletClass,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Amount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Amount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Reference = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Reference,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Employee = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Employee,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.CreatedSysDateTime = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.CreatedSysDateTime,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.CreatedSysUser = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.CreatedSysUser,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.ModifiedSysDateTime = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.ModifiedSysDateTime,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.ModifiedSysUser = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.ModifiedSysUser,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Notes = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Notes,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_pallet_transactions_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_pallet_transactions_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      pallet_class_ids:  $grid.filters.pallet_class.control.value ? [$grid.filters.pallet_class.control.value] : null ,
      project_ids:  $grid.filters.project.control.value ? [$grid.filters.project.control.value] : null ,
      account_ids:  null ,
      carrier_ids:  null ,
      shipment_ids:  null ,
      full_text_search:  $grid.fullTextSearch ,
      date_from:  $grid.filters.date_from.control.value ,
      date_to:  $grid.filters.date_to.control.value ,
    };
    const data = await this.$datasources.PalletTransactions.ds_pallet_transactions_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_pallet_transactions_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.Project.displayControl as TextModel).text = $row.entity.Project?.LookupCode;
    (this.cells.PalletClass.displayControl as TextModel).text = $row.entity.PalletClass?.Name;
    (this.cells.Amount.displayControl as TextModel).text = $row.entity.Amount?.toLocaleString();
    (this.cells.Reference.displayControl as TextModel).text = $row.entity.Reference;
    (this.cells.Employee.displayControl as TextModel).text = $row.entity.Employee;
    (this.cells.Date.displayControl as TextModel).text = $row.entity.Date?.toString();
    (this.cells.CreatedSysDateTime.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.CreatedSysUser.displayControl as TextModel).text = $row.entity.CreatedSysUser;
    (this.cells.ModifiedSysDateTime.displayControl as TextModel).text = $row.entity.ModifiedSysDateTime?.toString();
    (this.cells.ModifiedSysUser.displayControl as TextModel).text = $row.entity.ModifiedSysUser;
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'Order' : {
        this.on_order_click();
        break;
      }
    }
  }

  //#region private flows
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $row: FootprintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootprintManager_pallet_transactions_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pallet_transactions_grid.on_save');
  //O.Arias - 05/14/2024
  
  /*
  let Id = $row.entity.Id;
  let ProjectId: number = $row.entity.ProjectId ?? $grid.inParams.project_id;
  let ShipmentId: number = $row.entity.ShipmentId ?? $grid.inParams.shipment_id;
  
  let Amount: number = $row.cells.Amount.editControl.value;
  let Reference: string = $row.cells.Reference.editControl.value;
  let Notes: string = $row.cells.Notes.editControl.value;
  
  let PalletClassId: number = $row.cells.PalletClass.editControl.value ?? $grid.inParams.pallet_class_id;
  
  if (!$utils.isDefined(ProjectId)) {
      await $shell.PalletTransactions.openErrorDialog(`Error`, `The project cannot be null.`);
      return;
  }
  
  if (!$utils.isDefined(PalletClassId)) {
      await $shell.PalletTransactions.openErrorDialog(`Error`, `The pallet class cannot be null.`);
      return;
  }
  
  if (!$utils.isDefined(Amount)) {
      await $shell.PalletTransactions.openErrorDialog(`Error`, `The pallet class cannot be null.`);
      return;
  }
  
  let payload: {
      remove?: boolean,
      id?: number,
      project_id: number,
      shipment_id?: number,
      carrier_id?: number,
      account_id?: number,
      pallet_class_id: number,
      reference?: string,
      notes?: string,
      amount: number
  }
  
  payload = {
      id: Id,
      amount: Amount,
      project_id: ProjectId,
      pallet_class_id: PalletClassId,
  }
  
  $row.cells.Direction.displayControl.text = (Amount > 0 ? "In" : (Amount < 0 ? "Out" : ""));
  
  if ($utils.isDefined(ShipmentId)) { payload.shipment_id = ShipmentId }
  if ($utils.isDefined(Reference)) { payload.reference = Reference.trim() }
  if ($utils.isDefined(Notes)) { payload.notes = Notes.trim() }
  
  try {
  
      let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
  
      if (!create_or_update.success) {
          await $shell.PalletTransactions.openErrorDialog(`Failed`, create_or_update.exceptions);
      }
  
      if (!$utils.isDefined(Id)) {
          $row.entity.Id = create_or_update.id;
      }
      $row.refresh();
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) { targetError = targetError.error; };
      if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` } };
      await $shell.PalletTransactions.openErrorDialog(`Failed`, targetError.message);
  }
  */
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootprintManager_pallet_transactions_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/10/2024
  
  // Set Date/Time Formats
  const format = `${$settings.PalletTransactions.DateFormat}, ${$settings.PalletTransactions.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined($row.entity.CreatedSysDateTime)) {
      $row.cells.CreatedSysDateTime.displayControl.text = $utils.date.format($row.entity.CreatedSysDateTime, format);
  }
  
  if ($utils.isDefined($row.entity.ModifiedSysDateTime)) {
      $row.cells.ModifiedSysDateTime.displayControl.text = $utils.date.format($row.entity.ModifiedSysDateTime, format);
  }
  
  if ($utils.isDefined($row.entity.Date)) {
      $row.cells.Date.displayControl.text = $utils.date.format($row.entity.Date, format);
  }
  
  let Amount: number = $row.entity.Amount ?? 0;
  
  $row.cells.Amount.displayControl.text = (Amount > 0 ? `${Amount} in` : (Amount < 0 ? `${-1 * Amount} out` : ""));
  
  /*
  if (Amount > 0) {
      $row.cells.Amount.displayControl.styles.setStyle("setClass", "creation");
  } else {
      $row.cells.Amount.displayControl.styles.setStyle("setClass", "important");
  }
  */
  
  let OrderLookups = $row.entity.Shipment?.OrderLookups;
  
  if ($utils.isDefined(OrderLookups)) {
      $row.cells.Order.displayControl.text = OrderLookups[0].Order.LookupCode
  }
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootprintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootprintManager_pallet_transactions_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pallet_transactions_grid.on_init_new_row');
  //O.Arias - 05/14/2024
  
  let PalletClassId = $grid.inParams.pallet_class_id;
  let ProjectId = $grid.inParams.project_id;
  
  $row.entity = { ProjectId: ProjectId, PalletClassId: PalletClassId, Amount: null }
  }
  on_pallet_class_click(event = null) {
    return this.on_pallet_class_clickInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pallet_class_clickInternal(
    $row: FootprintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootprintManager_pallet_transactions_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'pallet_transactions_grid.on_pallet_class_click');
  //O.Arias - 05/14/2024
  
  await $shell.FootprintManager.openpallet_transactions_gridDialog({
      project_id: $row.entity.ProjectId,
      pallet_class_id: $row.entity.PalletClassId,
      project_lookup_code: $row.entity.Project.LookupCode,
      pallet_class_name: $row.entity.PalletClass.Name
  })
  
  $grid.refresh();
  }
  on_order_click(event = null) {
    return this.on_order_clickInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_clickInternal(
    $row: FootprintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootprintManager_pallet_transactions_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/09/2024
  
  let OrderLookups = $row.entity.Shipment?.OrderLookups;
  
  if ($utils.isDefined(OrderLookups)) {
  
      let Order = OrderLookups[0].Order;
  
      if (Order.OrderClass.OrderTypeId === 2) {
  
          $shell.FootprintManager.opensales_order_editor({ orderId: Order.Id })
      } else {
  
          if (Order.OrderClass.OrderClassTypeId === 12) {
  
              $shell.FootprintManager.openasn_order_editor({ order_id: Order.Id })
          } else {
  
              $shell.FootprintManager.openpurchase_order_editor({ orderId: Order.Id })
          }
      }
  }
  
  }
  on_edit_transaction(event = null) {
    return this.on_edit_transactionInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_transactionInternal(
    $row: FootprintManager_pallet_transactions_gridComponentRowModel,
  $grid: FootprintManager_pallet_transactions_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/25/2024
  
  await $shell.FootprintManager.openpallet_transaction_creation_formDialog({
      project_id: $row.entity.ProjectId,
      shipment_id: $row.entity.ShipmentId,
      pallet_class_id: $row.entity.PalletClassId,
      order_lookup_code: $row.entity.Shipment?.OrderLookups[0]?.Order?.LookupCode,
      transaction_id: $row.entity.Id,
      amount: $row.entity.Amount
  }, "flyout")
  
  await $row.refresh()
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => FootprintManager_pallet_classes_dd_singleComponent),
  ],
  selector: 'FootprintManager-pallet_transactions_grid',
  templateUrl: './FootprintManager.pallet_transactions_grid.component.html'
})
export class FootprintManager_pallet_transactions_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_pallet_transactions_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_pallet_transactions_gridComponentInParams = { project_id: null, pallet_class_id: null, project_lookup_code: null, pallet_class_name: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', 'Project', false, false, null, false),       Order: new GridHeaderModel(new HeaderStyles(null, null), 'Order', 'Order', false, false, null, false),       PalletClass: new GridHeaderModel(new HeaderStyles(null, null), 'PalletClass', 'Pallet class', false, false, null, false),       Amount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount', 'Amount', false, false, null, false),       Reference: new GridHeaderModel(new HeaderStyles(null, null), 'Reference', 'Reference', false, false, null, false),       Employee: new GridHeaderModel(new HeaderStyles(null, null), 'Employee', 'Entered by', false, false, null, false),       Date: new GridHeaderModel(new HeaderStyles(null, null), 'Date', 'Date and time', false, false, null, false),       CreatedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'CreatedSysDateTime', 'Created on', false, false, null, false),       CreatedSysUser: new GridHeaderModel(new HeaderStyles(null, null), 'CreatedSysUser', 'Created by', false, false, null, false),       ModifiedSysDateTime: new GridHeaderModel(new HeaderStyles(null, null), 'ModifiedSysDateTime', 'Modified on', false, false, null, false),       ModifiedSysUser: new GridHeaderModel(new HeaderStyles(null, null), 'ModifiedSysUser', 'Modified by', false, false, null, false),       Notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_pallet_transactions_gridComponentRowModel[] = [];

  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('pallet_class_id') set $inParams_pallet_class_id(value: any) {
    this.inParams['pallet_class_id'] = value;
  }
  get $inParams_pallet_class_id(): any {
    return this.inParams['pallet_class_id'] ;
  }
  @Input('project_lookup_code') set $inParams_project_lookup_code(value: any) {
    this.inParams['project_lookup_code'] = value;
  }
  get $inParams_project_lookup_code(): any {
    return this.inParams['project_lookup_code'] ;
  }
  @Input('pallet_class_name') set $inParams_pallet_class_name(value: any) {
    this.inParams['pallet_class_name'] = value;
  }
  get $inParams_pallet_class_name(): any {
    return this.inParams['pallet_class_name'] ;
  }

  topToolbar = {
      new_transaction: new ToolModel(new ButtonModel('new_transaction', new ButtonStyles(['creation'], null), false, false, false, 'New pallet transaction', 'icon-ic_fluent_add_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    pallet_class: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    pallet_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['pallet_class'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Pallet class', false, false),
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'From', false, false),
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, false, 'l, LT', 'datetime', null)
, new ControlContainerStyles(null, null), 'To', false, false),
  }

  //#region filters inParams
  get $fields_project_selector_inParams_statusId(): number {
    const $grid = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_pallet_class_selector_inParams_project_id(): number {
    const $grid = this;
    const $utils = this.$utils;
    const expr = $grid.filters.project.control.value;
    
    return expr;
  }

  //#endregion filters inParams


  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Pallet transactions grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;

    (this.filters.project.control as SelectBoxModel).reset($grid.inParams.project_id);
    (this.filters.pallet_class.control as SelectBoxModel).reset($grid.inParams.pallet_class_id);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      pallet_class_ids:  $grid.filters.pallet_class.control.value ? [$grid.filters.pallet_class.control.value] : null ,
      project_ids:  $grid.filters.project.control.value ? [$grid.filters.project.control.value] : null ,
      account_ids:  null ,
      carrier_ids:  null ,
      shipment_ids:  null ,
      full_text_search:  $grid.fullTextSearch ,
      date_from:  $grid.filters.date_from.control.value ,
      date_to:  $grid.filters.date_to.control.value ,
    };
    try {
      const data = await this.$datasources.PalletTransactions.ds_pallet_transactions_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_pallet_transactions_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_pallet_transactions_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_pallet_transactions_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_select();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootprintManager_pallet_transactions_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/09/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { reference: string, id: number, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.PalletTransactions.openErrorDialog('Delete error', 'No records were selected.');
      return;
  } else {
  
      const confirm_purge = (await $shell.PalletTransactions.openConfirmationDialog(`Confirm delete`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let entity = row.entity;
              let current_error: string;
              let success = false;
  
              try {
  
                  let remove = (await $flows.PalletTransactions.pallet_transaction_create_or_update({ id: entity.Id, remove: true }))
  
                  success = remove.success;
                  current_error = remove.exceptions;
  
              } catch (error) {
                  let targetError = error;
                  while ($utils.isDefined(targetError?.error)) { targetError = targetError.error; }
                  if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` } }
                  current_error = targetError.message;
              }
  
              if (success) {
                  deleted_records.push(entity.Id.toString())
              } else {
                  errors.push({ reference: entity.Id.toString(), id: entity.Id, errorMessages: [current_error] });
              }
          }
  
          if ($utils.isDefined(errors)) {
              await $shell.PalletTransactions.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deleted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} Reference: ${error.reference}` })))
          }
      }
  }
  
  $grid.refresh();
  }
  on_select(event = null) {
    return this.on_selectInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selectInternal(
    $grid: FootprintManager_pallet_transactions_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/09/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_selected.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_pallet_transactions_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/24/2024
  
  $grid.topToolbar.delete_selected.control.readOnly = true;
  
  $grid.headers.CreatedSysDateTime.hidden = true;
  $grid.headers.CreatedSysUser.hidden = true;
  $grid.headers.ModifiedSysDateTime.hidden = true;
  $grid.headers.ModifiedSysUser.hidden = true;
  
  $grid.title = `Pallet transactions grid`;
  
  if ($utils.isDefined($grid.inParams.project_lookup_code) && !$utils.isDefined($grid.inParams.pallet_class_name)) {
      $grid.title = `Pallet transactions for project ${$grid.inParams.project_lookup_code}`
  }
  
  if ($utils.isDefined($grid.inParams.project_lookup_code) && $utils.isDefined($grid.inParams.pallet_class_name)) {
      $grid.title = `Pallet transactions for project ${$grid.inParams.project_lookup_code} and pallet class ${$grid.inParams.pallet_class_name}`
  }
  
  if ($utils.isDefined($grid.inParams.project_id)) {
      $grid.filters.project.hidden = true;
  }
  
  if ($utils.isDefined($grid.inParams.pallet_class_id)) {
      $grid.filters.pallet_class.hidden = true;
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootprintManager_pallet_transactions_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/14/2024
  
  if ($utils.isDefined($grid.inParams.pallet_class_id) && $utils.isDefined($grid.inParams.project_id)) {
      $grid.headers.PalletClass.hidden = true;    
      $grid.headers.Project.hidden = true;    
  } else {
      $grid.headers.PalletClass.hidden = false;    
      $grid.headers.Project.hidden = false;    
  }
  }
  on_create_clicked(event = null) {
    return this.on_create_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_clickedInternal(
    $grid: FootprintManager_pallet_transactions_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/07/2024
  
  await $shell.FootprintManager.openpallet_transaction_creation_formDialog({
      project_id: $grid.filters.project.control.value,
      pallet_class_id: $grid.filters.pallet_class.control.value
  }, "flyout",EModalSize.Large)
  
  $grid.refresh();
  }
  on_excel_export(event = null) {
    return this.on_excel_exportInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_exportInternal(
    $grid: FootprintManager_pallet_transactions_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_transactions_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  /**
   * Author: Ibrahim Mustafa
   * Date: 2024-12-19
   * Description: Export functionality provided in a more reusable code
   */
  
  
  const settingsExcelExportLimit = $settings.FootprintManager.ExcelExportLimit;
  const serverMaxAllowedRecordsLimit: number = 5000;// could be changed base on what each request to the server can handle.
  const concurrentLimit = 5;//max concurrent requests
  const timeout = 500;//use it only if you want to set a delay between requests...
  // Initialize
  var workBook = $event;
  var recordsToExport = [];
  var fullDataSet: any[] = [];
  const format = `${$settings.PalletTransactions.DateFormat}, ${$settings.PalletTransactions.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  //parameters to be passed to dataset api call
  const baseParams = {
      pallet_class_ids: $grid.filters.pallet_class.control.value ? [$grid?.filters?.pallet_class.control?.value] : null,
      project_ids: $grid.filters.project.control.value ? [$grid?.filters?.project?.control?.value] : null,
      account_ids: [],
      carrier_ids: [],
      shipment_ids: [],
      full_text_search: $grid?.fullTextSearch,
      date_from: $grid?.filters?.date_from.control?.value,
      date_to: $grid?.filters?.date_to?.control?.value
  };
  
  //api call
  const dataApiCall = async (baseParams: any) => {
      return await $datasources.PalletTransactions.ds_pallet_transactions_grid.getList(baseParams);
  };
  
  //records count api call
  const countApiCall = async (baseParams: any) => {
      const params = { ...baseParams, $top: 0 };
      return await $datasources.PalletTransactions.ds_pallet_transactions_grid.getList(params);
  };
  
  //api call execution
  async function processApiCall(apiCall: (params: any) => Promise<any>, baseParams: any = {}, offset: number = 0, limit: number = 0): Promise<any> {
      const defaultParams = { $skip: offset, $top: limit };
      // Merge base, default, and additional params
      const params = { ...baseParams, ...defaultParams };
      return await apiCall(params);
  };
  
  //main funcion to handle concurrency in reagard of server and request limits
  async function processControlledApiRequests(totalRecords: number, requestLimit: number, serverLimit: number): Promise<any[]> {
      const totalBatches = Math.ceil(Math.min(totalRecords, serverLimit) / requestLimit);
  
      const result: any[] = [];
      const fetchPromiseQueue: Promise<any[]>[] = [];
  
      for (let i = 0; i < totalBatches; i++) {
          const offset = i * requestLimit;
          const limit = Math.min(requestLimit, serverLimit - (i * requestLimit));
  
          fetchPromiseQueue.push(processApiCall(dataApiCall, baseParams, offset, limit));
  
          //process promiseQueue once reached the limit then clear queue for the next loop round...
          if (fetchPromiseQueue.length === concurrentLimit) {
              result.push(...await Promise.all(fetchPromiseQueue));
              fetchPromiseQueue.length = 0;
          }
      }
  
      // Process any remaining requests after the loop
      if (fetchPromiseQueue.length) result.push(...await Promise.all(fetchPromiseQueue));
  
      return result;
  }
  
  // Set Date/Time Formats
  const dateFormat = `${$settings.FootprintManager.DateFormat}`;
  
  function getAge(date: any): number {
      var diffInDays: number;
      if ($utils.isDefined(date)) {
          const currentDate = new Date();
          const receiveDate = new Date(date);
          const diffInMS = currentDate.getTime() - receiveDate.getTime();
          diffInDays = Math.floor(diffInMS / (1000 * 60 * 60 * 24));
      }
      return diffInDays;
  }
  
  try {
      //Handle user selected rows only.
      if ($grid.selectedRows?.length > 0) {
          // Compile all data into JSON
          for (let row of $grid.selectedRows) {
              let amountText = (row.entity?.Amount> 0 ? `${row.entity?.Amount?.toLocaleString()} in` : (row.entity?.Amount< 0 ? `${-1 * row.entity?.Amount} out` : ""));
              recordsToExport.push({
                  "Project": row.entity?.Project?.LookupCode,
                  "Order": row.entity?.Shipment?.OrderLookups[0]?.Order?.LookupCode,
                  "Pallet class": row.entity?.PalletClass?.Name,
                  "Amount": amountText,
                  "Reference": row.entity?.Reference,
                  "Notes": row.entity?.Notes,
                  "Entered by": row.entity?.Employee,
                  "Date and time":     $utils.date.format(row.entity?.Date, format)
              });
          }
          var workSheet = $utils.excel.json_to_sheet(recordsToExport);
          $utils.excel.book_append_sheet(workBook, workSheet, "DockAppointmentsExport");
      }
      //take all rows in the dataset with applied filters.
      else {
          //using confirmation logic to make sure customer intend to export the records
          //const confirm = await $shell.FootprintManager.openConfirmationDialog(`Export records...`, `Are you sure you want to export grid records?`, 'Yes', 'No');
          //if (confirm) {
  
          const { totalCount: requestedRecordsCount } = await countApiCall(baseParams);
  
          if (requestedRecordsCount > settingsExcelExportLimit) {
              await $shell.FootprintManager.openInfoDialog(`Export limitation of ${settingsExcelExportLimit} exceeded`, `Excel will only export ${settingsExcelExportLimit} records`);
          }
  
          const responses = await processControlledApiRequests(requestedRecordsCount, serverMaxAllowedRecordsLimit, settingsExcelExportLimit);
          responses.forEach(apiResponse => {
              fullDataSet.push(...apiResponse.result);
          });
  
  
          // Compile all data into JSON
          for (let record of fullDataSet) {
              let amountText = (record?.Amount> 0 ? `${record?.Amount?.toLocaleString()} in` : (record?.Amount< 0 ? `${-1 * record?.Amount} out` : ""));
              recordsToExport.push({
                  "Project": record?.Project?.LookupCode,
                  "Order": record?.Shipment?.OrderLookups[0]?.Order?.LookupCode,
                  "Pallet class": record?.PalletClass?.Name,
                  "Amount": amountText,
                  "Reference": record?.Reference,
                  "Notes": record?.Notes,
                  "Entered by": record?.Employee,
                  "Date and time": $utils.date.format(record?.Date, format),
              });
          }
          var workSheet = $utils.excel.json_to_sheet(recordsToExport);
          $utils.excel.book_append_sheet(workBook, workSheet, "PalletTransactionsExport");
  
      }
  } catch (error) {
      //apparantly the code in here is just for any issues could happen with the code of the functions 
      //but not working for catching errors related the workbook itself
      //so if we commented this code will get a General Error message says "workbook is empty"
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
  
      throw new Error(errorMessage);
  
  
  }
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export(wb);

    writeExcelFile(wb, 'FootprintManager_pallet_transactions_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
