<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="delete_configs" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="field_selector" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button 
                    data-cy="splitbutton"
                    class="datex-button splitbutton" 
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matMenuTriggerFor]="dropdownMenuOne"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip">
              <div class="button-label">
                <div *ngIf="tool.control.icon"
                      class="button-icon">
                  <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                </div>
                <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                <div class="button-icon splitbutton-drop-icon">
                  <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                </div>
              </div>
            </button>
            <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
              <div data-cy="splitbutton-options">
                <div *ngIf="!tool.control.buttons.field_id.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_id.readOnly || tool.control.buttons.field_id.disabled"
                          [ngStyle]="tool.control.buttons.field_id.styles.style"
                          [ngClass]="tool.control.buttons.field_id.styles.classes"
                          [matTooltip]="tool.control.buttons.field_id.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_id.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_id.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_id.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.field_priority.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_priority.readOnly || tool.control.buttons.field_priority.disabled"
                          [ngStyle]="tool.control.buttons.field_priority.styles.style"
                          [ngClass]="tool.control.buttons.field_priority.styles.classes"
                          [matTooltip]="tool.control.buttons.field_priority.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_priority.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_priority.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_priority.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.field_context.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_context.readOnly || tool.control.buttons.field_context.disabled"
                          [ngStyle]="tool.control.buttons.field_context.styles.style"
                          [ngClass]="tool.control.buttons.field_context.styles.classes"
                          [matTooltip]="tool.control.buttons.field_context.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_context.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_context.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_context.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.field_entities.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_entities.readOnly || tool.control.buttons.field_entities.disabled"
                          [ngStyle]="tool.control.buttons.field_entities.styles.style"
                          [ngClass]="tool.control.buttons.field_entities.styles.classes"
                          [matTooltip]="tool.control.buttons.field_entities.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_entities.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_entities.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_entities.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.field_content.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_content.readOnly || tool.control.buttons.field_content.disabled"
                          [ngStyle]="tool.control.buttons.field_content.styles.style"
                          [ngClass]="tool.control.buttons.field_content.styles.classes"
                          [matTooltip]="tool.control.buttons.field_content.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_content.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_content.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_content.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.field_created_on.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_created_on.readOnly || tool.control.buttons.field_created_on.disabled"
                          [ngStyle]="tool.control.buttons.field_created_on.styles.style"
                          [ngClass]="tool.control.buttons.field_created_on.styles.classes"
                          [matTooltip]="tool.control.buttons.field_created_on.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_created_on.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_created_on.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_created_on.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.field_created_by.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_created_by.readOnly || tool.control.buttons.field_created_by.disabled"
                          [ngStyle]="tool.control.buttons.field_created_by.styles.style"
                          [ngClass]="tool.control.buttons.field_created_by.styles.classes"
                          [matTooltip]="tool.control.buttons.field_created_by.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_created_by.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_created_by.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_created_by.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.field_modified_on.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_modified_on.readOnly || tool.control.buttons.field_modified_on.disabled"
                          [ngStyle]="tool.control.buttons.field_modified_on.styles.style"
                          [ngClass]="tool.control.buttons.field_modified_on.styles.classes"
                          [matTooltip]="tool.control.buttons.field_modified_on.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_modified_on.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_modified_on.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_modified_on.label}}</div>
                    </div>
                  </button>
                </div>
                <div *ngIf="!tool.control.buttons.field_modified_by.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.field_modified_by.readOnly || tool.control.buttons.field_modified_by.disabled"
                          [ngStyle]="tool.control.buttons.field_modified_by.styles.style"
                          [ngClass]="tool.control.buttons.field_modified_by.styles.classes"
                          [matTooltip]="tool.control.buttons.field_modified_by.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="on_change_field_select($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.field_modified_by.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.field_modified_by.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.field_modified_by.label}}</div>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="priority">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.priority.displayControl.styles.style"
          [ngClass]="row.cells.priority.displayControl.styles.classes"
          [matTooltip]="row.cells.priority.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.priority.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="context">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.context.displayControl.styles.style"
          [ngClass]="row.cells.context.displayControl.styles.classes"
          [matTooltip]="row.cells.context.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.context.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="entities">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.entities.displayControl.styles.style"
          [ngClass]="row.cells.entities.displayControl.styles.classes"
          [matTooltip]="row.cells.entities.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.entities.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="content">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.content.displayControl.styles.style"
          [ngClass]="row.cells.content.displayControl.styles.classes"
          [matTooltip]="row.cells.content.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.content.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes"
          [matTooltip]="row.cells.created_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_by.displayControl.styles.style"
          [ngClass]="row.cells.created_by.displayControl.styles.classes"
          [matTooltip]="row.cells.created_by.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_on.displayControl.styles.style"
          [ngClass]="row.cells.modified_on.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_by.displayControl.styles.style"
          [ngClass]="row.cells.modified_by.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_by.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
