<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"
[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="manufacturing_production_confirmation" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_production_confirmation_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator11" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="addfinishedproduct" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_add_new_finished_good_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator3" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="deleteorder" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator4" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="refresh" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_refresh_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="additionalmenu" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button 
                    data-cy="splitbutton"
                    class="datex-button splitbutton" 
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matMenuTriggerFor]="dropdownMenuOne"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip">
              <div class="button-label">
                <div *ngIf="tool.control.icon"
                      class="button-icon">
                  <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                </div>
                <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                <div class="button-icon splitbutton-drop-icon">
                  <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                </div>
              </div>
            </button>
            <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
              <div data-cy="splitbutton-options">
                <div *ngIf="!tool.control.buttons.submenuone.removed" 
                    mat-menu-item 
                    class="splitbutton-item-container">
                  <button mat-button
                          data-cy="splitbutton-sub-item"
                          class="datex-button splitbutton-sub-item" 
                          [disabled]="tool.control.buttons.submenuone.readOnly || tool.control.buttons.submenuone.disabled"
                          [ngStyle]="tool.control.buttons.submenuone.styles.style"
                          [ngClass]="tool.control.buttons.submenuone.styles.classes"
                          [matTooltip]="tool.control.buttons.submenuone.tooltip"
                          matTooltipClass="datex-control-tooltip"
                          (click)="($event)">
                    <div class="button-label">
                      <div *ngIf="tool.control.buttons.submenuone.icon"
                          class="button-icon">
                        <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.submenuone.icon}}"></i>
                      </div>
                      <div data-cy="splitbutton-sub-item-label"
                          class="button-text">{{tool.control.buttons.submenuone.label}}</div>
                    </div>
                  </button>
                </div>
              </div>
            </mat-menu>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="linenumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.linenumber.displayControl.styles.style"
          [ngClass]="row.cells.linenumber.displayControl.styles.classes"
          [matTooltip]="row.cells.linenumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.linenumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="finishedgood">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.finishedgood.displayControl.styles.style"
          [ngClass]="row.cells.finishedgood.displayControl.styles.classes"
          [matTooltip]="row.cells.finishedgood.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.finishedgood.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-materials_by_project_dd_single 
        data-cy="selector"
        [type]="row.cells.finishedgood.editControl.type"
        [formControl]="row.formGroup.controls['finishedgood_edit']"
        (displayTextChange)="row.cells.finishedgood.editControl.displayText=$event"
        [placeholder]="row.cells.finishedgood.editControl.placeholder"
        [styles]="row.cells.finishedgood.editControl.styles"
        [tooltip]="row.cells.finishedgood.editControl.tooltip"
      [project_id]="row.$fields_finishedgood_selector_inParams_project_id"
    >
    </Manufacturing-materials_by_project_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes"
          [matTooltip]="row.cells.description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="plannedquantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.plannedquantity.displayControl.styles.style"
          [ngClass]="row.cells.plannedquantity.displayControl.styles.classes"
          [matTooltip]="row.cells.plannedquantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.plannedquantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['plannedquantity_edit']"
            matInput
            numberBox
            [format]="row.cells.plannedquantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.plannedquantity.editControl.readOnly || row.cells.plannedquantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.plannedquantity.editControl.placeholder}}"
            [ngStyle]="row.cells.plannedquantity.editControl.styles.style"
            [ngClass]="row.cells.plannedquantity.editControl.styles.classes"
            [matTooltip]="row.cells.plannedquantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.packaging.editControl.type"
        [formControl]="row.formGroup.controls['packaging_edit']"
        (displayTextChange)="row.cells.packaging.editControl.displayText=$event"
        [placeholder]="row.cells.packaging.editControl.placeholder"
        [styles]="row.cells.packaging.editControl.styles"
        [tooltip]="row.cells.packaging.editControl.tooltip"
      [material_id]="row.$fields_packaging_selector_inParams_material_id"
    >
    </Manufacturing-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="iskit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.iskit.displayControl.styles.style"
          [ngClass]="row.cells.iskit.displayControl.styles.classes"
          [matTooltip]="row.cells.iskit.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.iskit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="usekitcomponents">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.usekitcomponents.displayControl.styles.style"
          [ngClass]="row.cells.usekitcomponents.displayControl.styles.classes"
          [matTooltip]="row.cells.usekitcomponents.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.usekitcomponents.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['usekitcomponents_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.usekitcomponents.editControl.styles.style"
                  [ngClass]="row.cells.usekitcomponents.editControl.styles.classes"
                  [matTooltip]="row.cells.usekitcomponents.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.usekitcomponents.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootprintManager-manufacturing_order_lines_components_grid
    [finished_good_id]="row.$rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_finished_good_id"
    [warehouse_id]="row.$rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_warehouse_id"
    [finished_good_quantity]="row.$rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_finished_good_quantity"
    [is_kit]="row.$rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_is_kit"
    [project_id]="row.$rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_project_id"
    [order_id]="row.$rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_order_id"
    [line_number]="row.$rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_line_number"
    [order_status_id]="row.$rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_order_status_id"
    ($refreshEvent)="row.refresh()">>
  </FootprintManager-manufacturing_order_lines_components_grid>
</ng-template>

</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
