import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { EasyPost_ds_country_codesService } from './EasyPost.datasource.index';
import { EasyPost_ds_get_items_contents_by_shipmentService } from './EasyPost.datasource.index';
import { EasyPost_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService } from './EasyPost.datasource.index';
import { EasyPost_ds_get_project_by_projectIdService } from './EasyPost.datasource.index';
import { EasyPost_ds_get_shipmentorderlookup_by_shipmentIdService } from './EasyPost.datasource.index';
import { EasyPost_ds_get_shippingcontainers_count_by_shipmentIdService } from './EasyPost.datasource.index';

@Injectable({ providedIn: 'root' })
export class EasyPost_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public EasyPost: EasyPost_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_country_codes: EasyPost_ds_country_codesService;
  public get ds_country_codes(): EasyPost_ds_country_codesService {
    if(!this._ds_country_codes) {
      this._ds_country_codes = this.injector.get(EasyPost_ds_country_codesService);
    }
    return this._ds_country_codes;
  }
  private _ds_get_items_contents_by_shipment: EasyPost_ds_get_items_contents_by_shipmentService;
  public get ds_get_items_contents_by_shipment(): EasyPost_ds_get_items_contents_by_shipmentService {
    if(!this._ds_get_items_contents_by_shipment) {
      this._ds_get_items_contents_by_shipment = this.injector.get(EasyPost_ds_get_items_contents_by_shipmentService);
    }
    return this._ds_get_items_contents_by_shipment;
  }
  private _ds_get_ownerscarrierslookup_by_ownerId_carrierId: EasyPost_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService;
  public get ds_get_ownerscarrierslookup_by_ownerId_carrierId(): EasyPost_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService {
    if(!this._ds_get_ownerscarrierslookup_by_ownerId_carrierId) {
      this._ds_get_ownerscarrierslookup_by_ownerId_carrierId = this.injector.get(EasyPost_ds_get_ownerscarrierslookup_by_ownerId_carrierIdService);
    }
    return this._ds_get_ownerscarrierslookup_by_ownerId_carrierId;
  }
  private _ds_get_project_by_projectId: EasyPost_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): EasyPost_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(EasyPost_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_shipmentorderlookup_by_shipmentId: EasyPost_ds_get_shipmentorderlookup_by_shipmentIdService;
  public get ds_get_shipmentorderlookup_by_shipmentId(): EasyPost_ds_get_shipmentorderlookup_by_shipmentIdService {
    if(!this._ds_get_shipmentorderlookup_by_shipmentId) {
      this._ds_get_shipmentorderlookup_by_shipmentId = this.injector.get(EasyPost_ds_get_shipmentorderlookup_by_shipmentIdService);
    }
    return this._ds_get_shipmentorderlookup_by_shipmentId;
  }
  private _ds_get_shippingcontainers_count_by_shipmentId: EasyPost_ds_get_shippingcontainers_count_by_shipmentIdService;
  public get ds_get_shippingcontainers_count_by_shipmentId(): EasyPost_ds_get_shippingcontainers_count_by_shipmentIdService {
    if(!this._ds_get_shippingcontainers_count_by_shipmentId) {
      this._ds_get_shippingcontainers_count_by_shipmentId = this.injector.get(EasyPost_ds_get_shippingcontainers_count_by_shipmentIdService);
    }
    return this._ds_get_shippingcontainers_count_by_shipmentId;
  }
}

