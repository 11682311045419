import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_load_container_create_form_ComponentContextService } from './FootprintManager.load_container_create_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { LoadContainers_shipment_priorities_singleComponent } from './LoadContainers.shipment_priorities_single.component'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { LoadContainers_order_types_singleComponent } from './LoadContainers.order_types_single.component'
import { LoadContainers_container_types_singleComponent } from './LoadContainers.container_types_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => LoadContainers_shipment_priorities_singleComponent),
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => LoadContainers_order_types_singleComponent),
    forwardRef(() => LoadContainers_container_types_singleComponent),
  ],
  selector: 'FootprintManager-load_container_create_form',
  templateUrl: './FootprintManager.load_container_create_form.component.html'
})
export class FootprintManager_load_container_create_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseId?: number, orderTypeId?: number, projectId?: number, ownerId?: number, loadContainerId?: number } = { warehouseId: null, orderTypeId: null, projectId: null, ownerId: null, loadContainerId: null };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  @Input('orderTypeId') set $inParams_orderTypeId(v: number) {
    this.inParams.orderTypeId = v;
  }
  get $inParams_orderTypeId(): number {
    return this.inParams.orderTypeId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('loadContainerId') set $inParams_loadContainerId(v: number) {
    this.inParams.loadContainerId = v;
  }
  get $inParams_loadContainerId(): number {
    return this.inParams.loadContainerId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { nextId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    yard_location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    container_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Container code', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    priority: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['priority'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Priority', true, false),
    yard_location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['yard_location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Yard location', false, false),
    order_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_type'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Order type', true, false),
    container_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['container_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Container type', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_yard_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.warehouse.control.value;
    
    return expr;
  }

  get $fields_yard_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 6;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
    priority: this.fields.priority.control.valueChanges
    ,
    yard_location: this.fields.yard_location.control.valueChanges
    ,
    order_type: this.fields.order_type.control.valueChanges
    ,
    container_type: this.fields.container_type.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_load_container_create_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'New Load Container';
  
    const $form = this;
    const $utils = this.$utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .priority
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .yard_location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .order_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .container_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_load_container_create_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_load_container_create_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/17/2023
  
  $form.outParams.confirm = false;
  
  // Delay to give the form time to load before setting the focus logic
  await new Promise(resolve => setTimeout(resolve, 100));
  
  if ($utils.isDefined($form.inParams.warehouseId)) {
      $form.fields.warehouse.control.value = $form.inParams.warehouseId
  }
  
  let nextloadId = 0;
  
  if ($utils.isDefined($form.inParams.loadContainerId)) {
  
      nextloadId = $form.inParams.loadContainerId;
  
  } else {
  
      while (nextloadId === 0) {
  
          nextloadId = (await $flows.Utilities.reserve_nextId_flow({ entity: "LoadContainer" })).nextId;
  
          const load_containers = (await $datasources.LoadContainers.ds_load_containers_grid.get({ lookupCode: nextloadId.toString(), statusIds: [1, 2, 3] })).result;
  
          if ($utils.isDefined(load_containers)) {
              nextloadId = 0;
          };
      };
  };
  
  $form.vars.nextId = nextloadId;
  $form.fields.lookupcode.control.value = nextloadId.toString();
  $form.fields.lookupcode.control.focus();
  
  
  
  
  }
  on_cancel_click(event = null) {
    return this.on_cancel_clickInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickInternal(
    $form: FootprintManager_load_container_create_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_load_container_create_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/14/2023
  
  $form.close();
  }
  on_confirm_click(event = null) {
    return this.on_confirm_clickInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickInternal(
    $form: FootprintManager_load_container_create_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_load_container_create_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/17/2023
  
  $form.toolbar.confirm.control.readOnly = true;
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.lookupcode.control.value,
      $form.fields.order_type.control.value,
      $form.fields.priority.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.confirm.control.readOnly = false;
      $shell.FootprintManager.openErrorDialog('Load container creation error', 'Please enter in data in all required fields.');
      return;
  }
  
  try {
  
      const load_containers = (await $datasources.LoadContainers.ds_load_containers_grid.get({ lookupCode: $form.fields.lookupcode.control.value, statusIds: [1, 2, 3], orderTypeIds: [$form.fields.order_type.control.value] })).result;
      if ($utils.isDefined(load_containers)) {
          $form.toolbar.confirm.control.readOnly = false;
          $shell.FootprintManager.openErrorDialog('Load container creation error', 'A load container with the same lookup code already exists.');
          return;
      }
  
      let nextloadId;
  
      if ($utils.isDefined($form.vars.nextId)) {
          nextloadId = $form.vars.nextId;
      }
  
      const load_container_request = (await $flows.LoadContainers.create_loadcontainer_flow({
          loadContainerId: nextloadId,
          lookupcode: $form.fields.lookupcode.control.value,
          orderTypeId: $form.fields.order_type.control.value,
          priority: $form.fields.priority.control.value,
          yardLocationId: $form.fields.yard_location.control.value,
          loadContainerContainerTypeId: $form.fields.container_type.control.value
      }));
  
      if ($utils.isDefined(load_container_request)) {
  
          var loadContainerId = load_container_request.loadcontainerId;
          var reasons = load_container_request.reasons;
  
          if ($utils.isDefined(reasons) || !$utils.isDefined(loadContainerId)) {
              throw reasons[0] ?? "Uncaught exception. We failed to create the load container.";
          } else {
              $form.close();
              await $shell.FootprintManager.openload_container_editor({ loadContainerId: loadContainerId });
          }
      }
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Load container creation - ${errorMessage}`;
      await $shell.FootprintManager.openErrorDialog('Create load container error', 'An error occurred during the creation of the load container', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  
  }
  //#endregion private flows
}
