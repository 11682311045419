import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Ultraship_ModuleContextService } from './Ultraship.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Ultraship_project_configuration_add_form_ComponentContextService {
  private readonly moduleContext: Ultraship_ModuleContextService;

  constructor(moduleContext: Ultraship_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'project_configuration_add_form',
      type: ComponentType.form
    }
  }
}