import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles, CardStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { DatexFormControl } from './models/datex-form-control';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_count_task_card_ComponentContextService } from './FootprintManager.count_task_card.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { InventoryCounts_count_task_discrepancy_action_dd_singleComponent } from './InventoryCounts.count_task_discrepancy_action_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => InventoryCounts_count_task_discrepancy_action_dd_singleComponent),
  ],
  selector: 'FootprintManager-count_task_card',
  templateUrl: './FootprintManager.count_task_card.component.html'
})
export class FootprintManager_count_task_cardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  styles = new CardStyles(['creation','border-left']);

  inParams: { taskId: number } = { taskId: null };
  //#region Inputs
  @Input('taskId') set $inParams_taskId(v: number) {
    this.inParams.taskId = v;
  }
  get $inParams_taskId(): number {
    return this.inParams.taskId;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  vars: { header?: string, countTaskId?: number, currentExpectedAmount?: number, discrepancyId?: number, inventoryCountId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  dataChanged = new EventEmitter<void>();
  
  events = {
    dataChanged: this.dataChanged,
  }
  //#endregion

  @Output()
  $refreshEvent = new EventEmitter();


  formGroupContent: FormGroup = new FormGroup({
    expected_amount: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_amount: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    completed_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    completed_by: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    discrepancy_action: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });


  content = {
    fields: {
        expected_amount: new FieldModel(new TextBoxModel(this.formGroupContent.controls['expected_amount'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Expected', false, false),
        actual_amount: new FieldModel(new NumberBoxModel(this.formGroupContent.controls['actual_amount'] as DatexFormControl, null, true, false, '', '', null)
, new ControlContainerStyles(null, null), 'Actual', false, false),
        completed_on: new FieldModel(new TextBoxModel(this.formGroupContent.controls['completed_on'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Completed on', false, false),
        completed_by: new FieldModel(new TextBoxModel(this.formGroupContent.controls['completed_by'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Completed by', false, false),
        discrepancy_action: new FieldModel(new SelectBoxModel(
  this.formGroupContent.controls['discrepancy_action'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(['attention'], null), 'Discrepancy', false, false),
    },
    fieldsets: {
    newGroup1: new FieldsetModel(
      'Count',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $card = this;
        const $utils = this.$utils;
        return '';
      }
    ),
}
  }


  
  //#region content fields inParams
  //#endregion content fields inParams



  get headerTitle() {
    const $card = this;
    const $utils = this.$utils;
    const expr = $card.vars.header;
    return expr;
  }






  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_count_task_card_ComponentContextService,
) {
    super(); 
    this.$subscribeFormControlValueChanges();

  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.taskId)) {
        this.$missingRequiredInParams.push('taskId');
      }
  }

  initialized = false;

  async $init() {

    const $card = this;
    const $utils = this.$utils;

    
    
    


    await this.on_init();

    this.initialized = true;

  }

  private $subscribeFormControlValueChanges() {
    this.formGroupContent
      .controls['discrepancy_action']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_discrepancy_action_change();
      });

  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $card: FootprintManager_count_task_cardComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_count_task_card_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Initialize   
  let task = (await $datasources.InventoryCounts.ds_get_tasks_by_taskIds.get({ taskIds: [$card.inParams.taskId] })).result[0];
  
  // Validate task exists
  if (!$utils.isDefined(task)) {
      throw new Error(`Failed to find task with ID ${$card.inParams.taskId}`);
  }
  
  $card.vars.inventoryCountId = task.InventoryCountTaskProperty?.InventoryCountId;
  
  if (task.OperationCode.Id === 59) {
      
      let parentTask = (await $datasources.InventoryCounts.ds_get_tasks_by_taskIds.get({ taskIds: [task.ChainHead] })).result[0];
      $card.vars.discrepancyId = parentTask?.InventoryCountDiscrepancies[0]?.Id
      $card.vars.currentExpectedAmount = parentTask?.InventoryCountDiscrepancies[0]?.ExpectedAmount;
  }
  else {
      $card.vars.discrepancyId = task.InventoryCountDiscrepancies[0]?.Id;
      $card.vars.currentExpectedAmount = task.InventoryCountDiscrepancies[0]?.ExpectedAmount;
  }
  
  // Load card fields
  $card.vars.header = task.Undone ? 'Override' : `${task.OperationCode.Name} - ${task.Status.Name}`;
  $card.vars.countTaskId = task.OperationCode.Id === 59 ? task.ChainHead : task.Id;
  $card.content.fields.expected_amount.control.value = task.ExpectedPackagedAmount ?? $card.vars.currentExpectedAmount;
  
  
  let modifiedUsername = task.ModifiedSysUser.substring(
      task.ModifiedSysUser.indexOf(`\\`) + 1,
      task.ModifiedSysUser.indexOf('@') >= 0 ? task.ModifiedSysUser.indexOf('@') : task.ModifiedSysUser.length
  );
  
  switch (task.Status.Id) {
      case 1: { // Released
          $card.content.fields.discrepancy_action.hidden = false;
          $card.content.fields.completed_by.hidden = true;
          $card.content.fields.completed_on.hidden = true;
  
          $card.styles.setAttentionClass();
  
          $card.content.fields.actual_amount.control.readOnly = false;
          break;
      }
      case 2: { // Completed
          $card.content.fields.discrepancy_action.hidden = true;
          $card.content.fields.completed_by.control.value = modifiedUsername;
          $card.content.fields.completed_on.control.value = $utils.date.format(task.CompletedDateTime, $settings.InventoryCounts.DateFormat + ' HH:mm');
          $card.content.fields.actual_amount.control.value = task.ActualPackagedAmount;
  
          break;
      }
      case 3: { // Cancelled
          $card.content.fields.discrepancy_action.hidden = true;
          $card.content.fields.actual_amount.label = '';
          $card.content.fields.completed_by.control.value = modifiedUsername;
          $card.content.fields.completed_on.control.value = $utils.date.format(task.CancelledDateTime, $settings.InventoryCounts.DateFormat + ' HH:mm');
          $card.content.fields.completed_by.label = 'Cancelled By';
          $card.content.fields.completed_on.label = 'Cancelled On';
  
          $card.styles.setAttentionClass();
  
          break;
      }
      case 4: { // Planned
          $card.content.fields.discrepancy_action.hidden = true;
          $card.content.fields.actual_amount.label = '';
          $card.content.fields.completed_by.hidden = true;
          $card.content.fields.completed_on.hidden = true;
  
          break;
      }
  }
  }
  on_discrepancy_action_change(event = null) {
    return this.on_discrepancy_action_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discrepancy_action_changeInternal(
    $card: FootprintManager_count_task_cardComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_count_task_card_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      if ($card.content.fields.discrepancy_action.control.value.toUpperCase().trim() === 'RESOLVE') {
          let packagedAmount = $card.content.fields.actual_amount.control.value;
  
          if (!$utils.isDefined(packagedAmount)) {
              throw new Error(`No 'Actual' amount has been entered. Please enter a quantity and try again.`);
          }
  
          if (await $shell.InventoryCounts.openConfirmationDialog('Resolve Count Task', `Are you sure you want to resolve this task with an amount of ${packagedAmount}?`, 'Confirm', 'Cancel')) {
              let result = (await $flows.InventoryCounts.resolve_count_tasks_flow({ countTaskId: $card.vars.countTaskId, packagedAmount: packagedAmount }));
  
              if ($utils.isDefined(result.errors)) {
                  throw new Error(result.errors.join(', '));
              }
  
              let canComplete = false;
  
              if (result.hasDiscrepancy) {
                  if (await $shell.InventoryCounts.openConfirmationDialog('Success', 'Successfully completed count task, but there was a discrepancy. Would you like to create a recount task or resolve now?', 'Create Recount', 'Resolve Now')) {
                      // Create recount task
                      let recountResult = (await $flows.InventoryCounts.create_recount_task_flow({ countTaskId: $card.vars.countTaskId }));
  
                      if ($utils.isAllDefined(recountResult.errors)) {
                          throw new Error(recountResult.errors.join(', '));
                      }
  
                      $card.vars.discrepancyId = recountResult.discrepancyId;
  
                      await $shell.InventoryCounts.openInfoDialog('Success', `Successfully created recount task.`);
                  }
                  else {
                      if ($utils.isDefined($card.vars.discrepancyId)) {
                          // Resolve discrepancy
                          await $flows.Utilities.crud_update_flow({
                              entitySet: "InventoryCountDiscrepancies", id: $card.vars.discrepancyId, entity: {
                                  ActualAmount: packagedAmount,
                                  ResolvedDatetime: $utils.date.now(),
                                  StatusId: 3
                              }
                          });
                      }
  
                      await $shell.InventoryCounts.openInfoDialog('Success', `Successfully resolved count task.`);
  
                      canComplete = true;
                  }
              }
              else {
                  await $shell.InventoryCounts.openInfoDialog('Success', `Successfully resolved count task.`);
                  canComplete = true;
              }
  
              if (canComplete) {
                  // Check if all tasks are resolved
                  let remainingTasks = (await $datasources.InventoryCounts.ds_get_inventory_count_tasks.get({
                      inventoryCountIds: [$card.vars.inventoryCountId],
                      statusIds: [1, 4],
                      operationCodeIds: [58, 59]
                  })).result;
  
                  if (!$utils.isDefined(remainingTasks)) {
                      // Complete InventoryCount
                      (await $flows.InventoryCounts.set_inventory_count_completed_flow({
                          inventoryCountId: $card.vars.inventoryCountId,
                          isCancelOutstanding: false
                      }));
                  }
              }
  
              $card.events.dataChanged.emit();
          }
      }
      else if ($card.content.fields.discrepancy_action.control.value.toUpperCase().trim() === 'CANCEL') {
          if (await $shell.InventoryCounts.openConfirmationDialog('Cancel Count Task', 'Are you sure you want to cancel this task?', 'Confirm', 'Cancel')) {
              let result = (await $flows.InventoryCounts.cancel_count_task_flow({ countTaskIds: [$card.vars.countTaskId] }));
  
              if ($utils.isDefined(result.errors)) {
                  throw new Error(result.errors.join(', '));
              }
  
              await $shell.InventoryCounts.openInfoDialog('Success', `Successfully cancelled count task.`);
  
              $card.events.dataChanged.emit();
          }
      }
  }
  catch (error) {
      await $shell.InventoryCounts.openErrorDialog('Error on Discrepancy Action', error.message);
  }
  
  $card.content.fields.discrepancy_action.control.value = null;
  
  }
  //#endregion private flows

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);

    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }


}
