import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService } from './FootprintManager.inventory_transformation_source_lines_selector_by_serialnumbers_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, NetVolume?: number, NetWeight?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string } }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, LookupCode?: string } }, Packaging?: { Id?: number, ShortName?: string }}

interface IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentInParams {
  material_id: number, lot_id?: number, vendor_lot_id?: number, warehouse_id?: number, project_id?: number, package_id?: number, inventory_transformation_id: number, type: number, source_line_id?: number, gross_weight?: number, weight_uom?: number}

interface IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentOutParams {
  source_line_ids?: number[]}

class FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent;
  entity: IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentEntity;

  vars: { initial_index?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    select_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    select_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    status: null,
    lookupcode: null,
    licenseplate: null,
    location: null,
    lot: null,
    vendor_lot: null,
    packaging: null,
    weight: null,
    weight_uom: null,
    length: null,
    width: null,
    height: null,
    dimension_uom: null,
    select: null,
  }



  constructor(
    grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.licenseplate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.licenseplate,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendor_lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendor_lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.weight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.weight,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.weight_uom = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.weight_uom,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.length = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.length,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.width = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.width,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.height = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.height,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.dimension_uom = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.dimension_uom,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.select = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.select,
      new CheckBoxModel(this.formGroup.controls['select_display'] as DatexFormControl, null, true, false, '', null)
,
      new CheckBoxModel(this.formGroup.controls['select_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      material_id:  $grid.inParams.material_id ,
      lot_id:  $grid.inParams.lot_id ,
      packagingId:  $grid.inParams.package_id ,
      fullTextSearch:  $grid.fullTextSearch ,
      filterSerialNumberIds:  null ,
      vendor_lot_id:  $grid.inParams.vendor_lot_id ,
      excludeSerialNumberIds:  null ,
      weight_uom_id:  $grid.inParams.weight_uom ,
      gross_weight:  $grid.inParams.gross_weight ,
    };
    const data = await this.$datasources.InventoryTransformations.ds_serialnumbers_selection_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.status.displayControl as TextModel).text = 'Available';
    (this.cells.lookupcode.displayControl as TextModel).text = $row?.entity?.LookupCode;
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity.LicensePlate?.LookupCode;
    (this.cells.location.displayControl as TextModel).text = $row.entity.LicensePlate?.Location?.Name;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.vendor_lot.displayControl as TextModel).text = $row.entity.Lot?.VendorLot?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Packaging.ShortName;
    (this.cells.weight.displayControl as TextModel).text = $row.entity?.GrossWeight?.toString();
    (this.cells.weight_uom.displayControl as TextModel).text = $row.entity?.WeightUom?.Short_name;
    (this.cells.length.displayControl as TextModel).text = $row.entity?.Length?.toString();
    (this.cells.width.displayControl as TextModel).text = $row.entity?.Width?.toString();
    (this.cells.height.displayControl as TextModel).text = $row.entity?.Height?.toString();
    (this.cells.dimension_uom.displayControl as TextModel).text = $row.entity?.DimensionsUom?.Short_name;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_serialnumbers_grid.on_save_existing_row');
  
  
  try {
  
      /*
      //if quantity to transform > inventory line quantity then throw error
      if ($row.cells.quantity_to_transform.editControl.value > $row.entity?.PackagedAmount) {
          throw new Error('Quantity to transform must not exceed available inventory.')
      }
      */
  
  
      //if line does not exist create new line
      if (!$utils.isDefined($grid.inParams.source_line_id) && !$utils.isDefined($grid.vars.source_line_id)) {
  
          /*
          //create source line for lot controlled materials
          if ($row?.entity?.Lot?.Material?.ControllerTypeId === 1 || $row?.entity?.Lot?.Material?.ControllerTypeId === 2) {
              //check source lines for selected input
              let source_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_source_line_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  license_plate_id: $row.entity?.LicensePlateId,
                  lot_id: $row.entity?.LotId,
                  material_id: $row.entity.Lot?.MaterialId,
                  package_amount: $row.cells.quantity_to_transform?.editControl?.value,
                  package_id: $row.entity?.PackagedId,
                  vendorlot_id: $row.entity?.Lot?.VendorLotId
              }))
  
              if ($utils.isDefined(source_line_request?.reason)) {
                  throw Error(source_line_request?.reason)
              }
  
              document.getElementsByTagName('inventorytransformations-inventory_transformation_source_line_editor')[0].dispatchEvent(new CustomEvent('handle_source_line_value', { 'detail': [source_line_request?.inventory_transformation_source_line_id] })); ///change to FootprintManager-inventory_transformation_advanced_wizard
  
              $grid.outParams.source_line_ids = [source_line_request?.inventory_transformation_source_line_id]
              $grid.events.outParamsChange.emit()
              $grid.vars.source_line_id = source_line_request?.inventory_transformation_source_line_id
              await $grid.refresh();
  
  
          }
          */
  
          //create source line for serial controlled materials
          //only allow one to many transformation
          //else {
  
          /*
          if ($row?.cells?.quantity_to_transform?.editControl?.value > 1 && $grid.inParams.type === 1) {
              throw Error('Only one to many transformation is allowed.')
          }
          */
  
          /*
          let selected_serials = (await $shell.InventoryTransformations.openserialnumbers_selection_gridDialog({
              lotId: $row?.entity?.LotId,
              licenseplateId: $row?.entity?.LicensePlateId,
              packagingId: $row?.entity?.PackagedId,
              packagedAmountRequest: $row?.cells?.quantity_to_transform?.editControl?.value
          }))
          */
  
  
          if ($row.cells.select.editControl.value) {
  
              let source_line_request = (await $flows.InventoryTransformations.create_inventory_transformation_source_line_by_serial_numbers_flow({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  serial_number_ids: [$row.entity?.Id]
              }))
  
              if ($utils.isDefined(source_line_request?.reason)) {
                  throw Error(source_line_request?.reason)
              }
  
              $grid.vars.serial_number_ids = [$row.entity?.Id]
              $grid.vars.source_line_id = source_line_request?.inventory_transformation_source_line_ids[0]
              document.getElementsByTagName('FootprintManager-inventory_transformation_source_line_editor')[0].dispatchEvent(new CustomEvent('handle_source_line_value', { 'detail': source_line_request?.inventory_transformation_source_line_ids })); ///change to FootprintManager-inventory_transformation_advanced_wizard
              $grid.outParams.source_line_ids = source_line_request?.inventory_transformation_source_line_ids
              $grid.events.outParamsChange.emit()
              await $grid.refresh();
  
          }
  
          //}
  
          $shell.InventoryTransformations.openToaster(
              'Successfully created inventory transformation source line/s.',
              `Inventory transformation source line/s has been created.`,
              EToasterType.Success,
              {
                  positionClass: EToasterPosition.topRight,
                  tapToDismiss: true,
                  timeOut: 5000,
                  progressBar: true,
                  newestOnTop: true
              });
  
      }
  
      //if line exists add to or delete existing line
      else {
  
          /*if ($row?.entity?.Lot?.Material?.ControllerTypeId === 1 || $row?.entity?.Lot?.Material?.ControllerTypeId === 2) {
  
              //check source lines for selected input
              let source_line_check = $grid.vars.source_lines
  
              let source_line_validation = source_line_check.filter(sl =>
                  sl.sourcelicenseplateid === $row.entity?.LicensePlateId &&
                  sl.sourcelotid === $row.entity?.LotId &&
                  sl.sourcematerialid === $row.entity.Lot?.MaterialId &&
                  sl.sourcepackageid === $row.entity?.PackagedId &&
                  sl.sourcevendorlotid === $row.entity?.Lot?.VendorLotId &&
                  sl.sourceserialnumberid === null
              )[0]
  
              if ($utils.isDefined(source_line_validation)) {
  
                  let inventory_source_line_payload: any = {}
  
                  if ($utils.isDefined(source_line_validation?.sourcelicenseplateid)) { inventory_source_line_payload.SourceLicensePlateId = source_line_validation?.sourcelicenseplateid }
                  if ($utils.isDefined(source_line_validation?.sourcelotid)) { inventory_source_line_payload.SourceLotId = source_line_validation?.sourcelotid }
                  if ($utils.isDefined(source_line_validation?.sourcematerialid)) { inventory_source_line_payload.SourceMaterialId = source_line_validation?.sourcematerialid }
                  if ($utils.isDefined(source_line_validation?.sourcepackageid)) { inventory_source_line_payload.SourcePackageId = source_line_validation?.sourcepackageid }
                  if ($utils.isDefined(source_line_validation?.sourcevendorlotid)) { inventory_source_line_payload.SourceVendorLotId = source_line_validation?.sourcevendorlotid }
                  if ($utils.isDefined(source_line_validation?.sourcepackagedamount)) { inventory_source_line_payload.SourcePackageAmount = $row.cells.quantity_to_transform?.editControl?.value }
  
                  //if amount <= 0, then delete, else update values to new amount
                  if ($row.cells.quantity_to_transform?.editControl?.value <= 0) {
  
  
  
                      let delete_row = (await $flows.Utilities.crud_delete_flow({
                          entitySet: 'InventoryTransformationSourceLines',
                          id: source_line_validation?.id
                      }))
  
                      if (delete_row?.reason) {
                          throw Error(delete_row?.reason)
                      }
  
                      $grid.outParams.source_line_ids = null
                      $grid.vars.source_line_id = null
                      $grid.vars.serial_number_ids = null
                      $grid.events.outParamsChange.emit()
                      await $grid.refresh();
  
  
  
                  }
                  else {
  
                      let update_row = (await $flows.Utilities.crud_update_flow({
                          entitySet: 'InventoryTransformationSourceLines',
                          id: source_line_validation.id,
                          entity: inventory_source_line_payload
                      }));
  
                      if (update_row?.reason) {
                          throw Error(update_row?.reason)
                      }
  
                      document.getElementsByTagName('inventorytransformations-inventory_transformation_source_line_editor')[0].dispatchEvent(new CustomEvent('handle_source_line_value', { 'detail': [source_line_validation.id] })); ///change to FootprintManager-inventory_transformation_advanced_wizard
                      $grid.outParams.source_line_ids = [source_line_validation.id]
                      $grid.events.outParamsChange.emit()
                      $grid.vars.source_line_id = source_line_validation.id
                      await $grid.refresh();
                  }
  
              }
  
              else {
                  throw new Error(`Inventory transformation can only have one source line. Sourceline id is vars: ${$grid.vars.source_line_id}, Sourceline id is inparams: ${$grid.inParams.source_line_id}`)
  
              }
  
  
          }*/
  
          //serialized
          //else {
          //check source lines for selected input
          let source_line_check = $grid.vars.source_lines
  
          if (!$row.cells.select.editControl.value) {
  
  
  
              let delete_row = (await $flows.Utilities.crud_delete_flow({
                  entitySet: 'InventoryTransformationSourceLines',
                  id: source_line_check[0]?.id
              }))
  
  
              if (delete_row?.reason) {
                  throw Error(delete_row?.reason)
              }
  
  
          $shell.InventoryTransformations.openToaster(
              'Successfully deleted inventory transformation source line/s.',
              `Inventory transformation source line/s has been deleted.`,
              EToasterType.Success,
              {
                  positionClass: EToasterPosition.topRight,
                  tapToDismiss: true,
                  timeOut: 5000,
                  progressBar: true,
                  newestOnTop: true
              });
  
              $grid.outParams.source_line_ids = null
              $grid.events.outParamsChange.emit()
              $grid.vars.source_line_id = null
              $grid.vars.serial_number_ids = null
              await $grid.refresh();
  
  
  
          }
  
          //}
  
  
      }
  
  }
  
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
      throw new Error(error)
  
  }
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_serialnumbers_grid.on_row_data_loaded');
  
  
  
  let source_line_check = $grid.vars.source_lines
  
  //lock rows if targetline is already present
  if ($grid.vars.target_line_count > 0) {
      for (let key in $row.cells) {
          if ($utils.isDefined($row.cells[key].editControl?.readOnly)) {
              $row.cells[key].editControl.readOnly = true;
          }
      }
  }
  
  
  
  
  }
  on_quantity_to_transform_changed(event = null) {
    return this.on_quantity_to_transform_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_quantity_to_transform_changedInternal(
    $row: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_serialnumbers_grid.on_quantity_to_transform_changed');
  
  /*
  if ($row?.cells.quantity_to_transform.editControl.value<$row.entity.PackagedAmount)
  {
      $row.cells.full_quantity.editControl.value = false
  }
  */
  }
  on_full_quantity_changed(event = null) {
    return this.on_full_quantity_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_full_quantity_changedInternal(
    $row: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_serialnumbers_grid.on_full_quantity_changed');
  
  /*
  if ($row?.cells.full_quantity.editControl.value === true)
  {
      $row.cells.quantity_to_transform.editControl.value = $row?.entity.PackagedAmount
  }
  */
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-inventory_transformation_source_lines_selector_by_serialnumbers_grid',
  templateUrl: './FootprintManager.inventory_transformation_source_lines_selector_by_serialnumbers_grid.component.html'
})
export class FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentInParams = { material_id: null, lot_id: null, vendor_lot_id: null, warehouse_id: null, project_id: null, package_id: null, inventory_transformation_id: null, type: null, source_line_id: null, gross_weight: null, weight_uom: null };

  outParams: IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentOutParams = { source_line_ids: [] };

  //#region Variables
  vars: { target_line_count?: number, source_line_id?: number, serial_number_ids?: number[], source_lines?: { licenseplate_lookupcode?: string, location_id?: number, location_name?: string, vendorlot_lookupcode?: string, inventorytransformation_lookupcode?: string, material_controllertypeid?: number, id?: number, sourcepackagedamount?: number, sourcematerialid?: number, sourcelotid?: number, sourcevendorlotid?: number, sourcelicenseplateid?: number, sourcepackageid?: number, sourceserialnumberid?: number, vendorlot_manufacturedate?: string, vendorlot_expirationdate?: string }[] } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ source_line_ids?: number[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Serial number code', false, false, null, false),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'licenseplate', 'Licenseplate', false, false, null, false),       location: new GridHeaderModel(new HeaderStyles(null, null), 'location', 'Location', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendor_lot: new GridHeaderModel(new HeaderStyles(null, null), 'vendor_lot', 'Vendor lot', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, null, false),       weight: new GridHeaderModel(new HeaderStyles(null, null), 'weight', 'Weight', false, false, null, false),       weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'weight_uom', 'Weight UOM', false, false, null, false),       length: new GridHeaderModel(new HeaderStyles(null, null), 'length', 'Length', false, false, null, false),       width: new GridHeaderModel(new HeaderStyles(null, null), 'width', 'Width', false, false, null, false),       height: new GridHeaderModel(new HeaderStyles(null, null), 'height', 'Height', false, false, null, false),       dimension_uom: new GridHeaderModel(new HeaderStyles(null, null), 'dimension_uom', 'Dimensions UOM', false, false, null, false),       select: new GridHeaderModel(new HeaderStyles(null, null), 'select', 'Select', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentRowModel[] = [];

  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }
  @Input('lot_id') set $inParams_lot_id(value: any) {
    this.inParams['lot_id'] = value;
  }
  get $inParams_lot_id(): any {
    return this.inParams['lot_id'] ;
  }
  @Input('vendor_lot_id') set $inParams_vendor_lot_id(value: any) {
    this.inParams['vendor_lot_id'] = value;
  }
  get $inParams_vendor_lot_id(): any {
    return this.inParams['vendor_lot_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('package_id') set $inParams_package_id(value: any) {
    this.inParams['package_id'] = value;
  }
  get $inParams_package_id(): any {
    return this.inParams['package_id'] ;
  }
  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(value: any) {
    this.inParams['inventory_transformation_id'] = value;
  }
  get $inParams_inventory_transformation_id(): any {
    return this.inParams['inventory_transformation_id'] ;
  }
  @Input('type') set $inParams_type(value: any) {
    this.inParams['type'] = value;
  }
  get $inParams_type(): any {
    return this.inParams['type'] ;
  }
  @Input('source_line_id') set $inParams_source_line_id(value: any) {
    this.inParams['source_line_id'] = value;
  }
  get $inParams_source_line_id(): any {
    return this.inParams['source_line_id'] ;
  }
  @Input('gross_weight') set $inParams_gross_weight(value: any) {
    this.inParams['gross_weight'] = value;
  }
  get $inParams_gross_weight(): any {
    return this.inParams['gross_weight'] ;
  }
  @Input('weight_uom') set $inParams_weight_uom(value: any) {
    this.inParams['weight_uom'] = value;
  }
  get $inParams_weight_uom(): any {
    return this.inParams['weight_uom'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
    ) {
    super();
    this.title = 'inventory_transformation_source_lines_selector_by_serialnumbers_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.material_id)) {
        this.$missingRequiredInParams.push('material_id');
      }
      if(isNil(this.inParams.inventory_transformation_id)) {
        this.$missingRequiredInParams.push('inventory_transformation_id');
      }
      if(isNil(this.inParams.type)) {
        this.$missingRequiredInParams.push('type');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      material_id:  $grid.inParams.material_id ,
      lot_id:  $grid.inParams.lot_id ,
      packagingId:  $grid.inParams.package_id ,
      fullTextSearch:  $grid.fullTextSearch ,
      filterSerialNumberIds:  null ,
      vendor_lot_id:  $grid.inParams.vendor_lot_id ,
      excludeSerialNumberIds:  null ,
      weight_uom_id:  $grid.inParams.weight_uom ,
      gross_weight:  $grid.inParams.gross_weight ,
    };
    try {
      const data = await this.$datasources.InventoryTransformations.ds_serialnumbers_selection_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded_flow();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_material_check(event = null) {
    return this.on_material_checkInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_checkInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_serialnumbers_grid.on_material_check');
  
  /*//check if material is serial controlled
  let material_controller = $grid?.rows[0]?.entity?.Lot?.Material?.ControllerTypeId
  
  //hide full_quantity for serialized materials
  if (material_controller === 3 || material_controller === 4) {
      $grid.headers.full_quantity.hidden = true
  
  }
  */
  
  
  //target line check
  if ($utils.isDefined($grid.inParams.source_line_id) ?? $utils.isDefined($grid.vars.source_line_id)) {
  
      let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
          inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          source_line_number: $grid.inParams.source_line_id ?? $grid.vars.source_line_id
      }))?.result
  
      //lockdown editing/adding lines if targetline is already present
      if ($utils.isDefined(target_line_check)) {
          $grid.vars.target_line_count = target_line_check?.length
          $grid.canEdit = false
      }
      else {
          $grid.vars.target_line_count = 0
          $grid.canEdit = true
      }
  
  }
  
  
  
  //check if inventory line corresponds to a source line for the inventory transformation id
  
  let source_line: {
      id: number;
      licenseplate_lookupcode: string;
      location_id: number;
      location_name: string;
      vendorlot_lookupcode: string;
      vendorlot_manufacturedate: string;
      vendorlot_expirationdate: string;
      inventorytransformation_lookupcode: string;
      material_controllertypeid: number;
      sourcepackagedamount: string;
      sourcematerialid: number;
      sourcelotid: number;
      sourcevendorlotid: number;
      sourcelicenseplateid: number;
      sourcepackageid: number;
      sourceserialnumberid: number;
  }[] = null
  
  
  let source_line_check: {
      Id?: number;
      SourceLicensePlateId?: number;
      SourceLotId?: number;
      SourceVendorLotId?: number;
      SourceMaterialId?: number;
      SourcePackageAmount?: number;
      SourcePackageId?: number;
      SourceSerialNumberId?: number;
      StatusId?: number;
      ErrorMessage?: string;
      LicensePlate?: {
          LookupCode?: string;
          Location?: {
              Id?: number;
              Name?: string;
          }
      }
      Material?: {
          ControllerTypeId?: number;
      }
      Lot?: {
          Id?: number;
          LookupCode?: string;
          VendorLot?: {
              Id?: number;
              LookupCode?: string;
              ManufactureDate?: string;
              ExpirationDate?: string;
          }
      }
      InventoryTransformationLineStatus?: {
          Name?: string;
      }
      InventoryTransformation?: {
          LookupCode?: string;
      }
      SerialNumbers?: {
          Id?: number;
          LookupCode?: string;
          Licenseplate?: {
              Id?: number;
              LookupCode?: string;
              Location?: {
                  Id?: number;
                  Name?: string;
              }
          }
      }
  }[] = null
  
  //if serial controlled, only set 1 (allow one to many only)
  
  if ($utils.isDefined($grid.inParams.source_line_id)) {
      source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
          inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          material_id: $grid.inParams.material_id,
          lot_id: $grid.inParams.lot_id,
          vendor_lot_id: $grid.inParams.vendor_lot_id,
          packaging_id: $grid.inParams.package_id,
          ids: [$grid.inParams.source_line_id],
      }))?.result
  
  }
  if ($utils.isDefined($grid.vars.source_line_id) && !$utils.isDefined(source_line_check)) {
      source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
          inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          material_id: $grid.inParams.material_id,
          lot_id: $grid.inParams.lot_id,
          vendor_lot_id: $grid.inParams.vendor_lot_id,
          packaging_id: $grid.inParams.package_id,
          ids: [$grid.vars.source_line_id],
      }))?.result
  
  
  }
  /*
  else {
      source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
          inventory_transformation_id: $grid.inParams.inventory_transformation_id,
          material_id: $grid.inParams.material_id,
          lot_id: $grid.inParams.lot_id,
          vendor_lot_id: $grid.inParams.vendor_lot_id,
          packaging_id: $grid.inParams.package_id,
      }))?.result
   
  }
  */
  
  
  
  $grid.vars.source_lines = source_line_check?.map(slc =>
  ({
      id: slc?.Id,
      licenseplate_lookupcode: slc?.LicensePlate?.LookupCode,
      location_id: slc?.LicensePlate?.Location?.Id,
      location_name: slc?.LicensePlate?.Location?.Name,
      vendorlot_lookupcode: slc?.Lot?.VendorLot?.LookupCode,
      vendorlot_manufacturedate: slc?.Lot?.VendorLot?.ManufactureDate,
      vendorlot_expirationdate: slc?.Lot?.VendorLot?.ExpirationDate,
      inventorytransformation_lookupcode: slc?.InventoryTransformation?.LookupCode,
      material_controllertypeid: slc?.Material?.ControllerTypeId,
      sourcepackagedamount: slc?.SourcePackageAmount,
      sourcematerialid: slc?.SourceMaterialId,
      sourcelotid: slc?.SourceLotId,
      sourcevendorlotid: slc?.Lot?.VendorLot?.Id,
      sourcelicenseplateid: slc?.SourceLicensePlateId,
      sourcepackageid: slc?.SourcePackageId,
      sourceserialnumberid: slc?.SourceSerialNumberId
  })
  )
  
  
  if ($utils.isDefined(source_line_check)) {
      //refresh every row. We do refresh here instead of row data loaded
      for (let row of $grid.rows) {
  
          //filter results by LP
  
          let serial_check = source_line_check?.filter(slc => slc?.SourceSerialNumberId === row.entity?.Id)
          if ($utils.isDefined(serial_check)) {
              row.cells.select.displayControl.value = true
              row.cells.select.editControl.value = true
          }
  
          /*
          //check if material is serial controlled
          let material_controller = row?.entity?.Lot?.Material?.ControllerTypeId
          if (material_controller === 3 || material_controller === 4) {*/
  
          //if serial controlled, only set 1 (allow one to many only)
  
  
          /*
          let serial_numbers: number[] = []
          if ($utils.isDefined(source_line_check)) {
              serial_numbers = source_line_check?.map(slc => slc?.SourceSerialNumberId)
  
          }
          */
  
  
          //let serial_check = (await $datasources.SerialNumbers.ds_get_serialnumbers_by_serialNumberIds.get({ serialNumberIds: serial_numbers ?? $grid.vars.serial_number_ids }))
          //setup data on cells based from source line check
          if ($utils.isAllDefined(source_line_check, serial_check)) {
  
              if (row?.entity?.Id === serial_check[0]?.SerialNumbers?.Id) {
  
                  //row.cells.status.displayControl.text = 'Not available'
  
  
              }
  
              else {
                  // row.cells.full_quantity.displayControl.value = false
                  // row.cells.full_quantity.editControl.value = false
                  // row.cells.quantity_to_transform.displayControl.value = null
                  // row.cells.quantity_to_transform.editControl.value = null
                  $grid.vars.source_line_id = null
                  //row.cells.status.displayControl.text = 'Available'
  
              }
  
          }
  
          //}
  
          /*
          else {
  
  
              if ($utils.isDefined($grid.inParams.source_line_id)) {
  
                  //setup data on cells based from source line check
                  if ($utils.isDefined(source_line_check)) {
  
                      if (source_line_check[0]?.SourceLicensePlateId === row.entity?.LicensePlateId) {
                          row.cells.quantity_to_transform.displayControl.value = source_line_check[0]?.SourcePackageAmount
                          row.cells.quantity_to_transform.editControl.value = source_line_check[0]?.SourcePackageAmount
                          $grid.vars.source_line_id = source_line_check[0]?.Id
  
                          if (source_line_check[0]?.SourcePackageAmount === row?.entity?.PackagedAmount) {
                              row.cells.full_quantity.displayControl.value = true
                              row.cells.full_quantity.editControl.value = true
                          }
                          else {
                              row.cells.full_quantity.displayControl.value = false
                              row.cells.full_quantity.editControl.value = false
                          }
  
                      }
                  }
  
                  else {
                      row.cells.full_quantity.displayControl.value = false
                      row.cells.full_quantity.editControl.value = false
                      row.cells.quantity_to_transform.displayControl.value = null
                      row.cells.quantity_to_transform.editControl.value = null
                      $grid.vars.source_line_id = null
  
                  }
  
              }
  
          }
          */
  
      }
  
  
  }
  
  else {
      for (let row of $grid.rows) {
          row.cells.select.displayControl.value = false
          row.cells.select.editControl.value = false
      }
      $grid.vars.source_line_id = null
  
  }
  
  }
  on_data_loaded_flow(event = null) {
    return this.on_data_loaded_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loaded_flowInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_serialnumbers_grid.on_data_loaded_flow');
  await $grid.on_material_check()
  
  /*
  $grid.rows.sort((a, b) => {
      if (a.cells?.select.editControl.value === true && !$utils.isDefined(b.cells?.select.editControl.value)) { return -1; }
      if (!$utils.isDefined(a.cells?.select.editControl.value) && b.cells?.select.editControl.value === true) { return 1; }
  
      return a.vars.initial_index - b.vars.initial_index;
  });
  */
  
  $grid.rows.sort((a, b) => {
      if (a.cells.select.editControl.value === b.cells.select.editControl.value) {
          return a.cells.lookupcode.displayControl.text.localeCompare(b.cells.lookupcode.displayControl.text);
      }
      if (a.cells.select.editControl.value === null) return 1;
      if (b.cells.select.editControl.value === null) return -1;
      return b.cells.select.editControl.value ? 1 : -1;
  });
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_selector_by_serialnumbers_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_selector_by_serialnumbers_grid.on_init');
  await add_events()
  
  
  //GRID SORTING
  for (let i = 0; i < $grid.rows.length; i++) {
      $grid.rows[i].vars.initial_index = i;
  }
  
  /*************************************
   * CUSTOM EVENTS
  **************************************/
  async function add_events() {
      await new Promise(result => setTimeout(result, 100));
  
      // Get element to attach to 
      const element = document?.getElementsByTagName('inventorytransformations-inventory_transformation_source_line_editor')[0]; ///change to FootprintManager-inventory_transformation_advanced_wizard
  
      if (element == null) {
          // throw new Error(`Failed to identify the HTML element for pack verification wizard`);
      }
  
      // 'on' events to detect different stages of the wizard
      element?.addEventListener('on_inventory_transformation_source_lines_selector_by_licenseplate_grid_initialized', ((event?: CustomEvent | null) => {    }) as EventListener);
  
      // 'handle' events to perform common actions that may be invoked
      element?.addEventListener('handle_source_line_value', ((event?: CustomEvent<number[]>) => {
          $grid.vars.source_line_id = event?.detail[0]
      }) as EventListener);
  
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
