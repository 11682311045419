<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-target_material" *ngIf="!fields.target_material.hidden && !fields.target_material.removed" 
                            class="field-container standard {{fields.target_material.invalid ? 'invalid' : ''}} {{fields.target_material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.target_material.styles.style"
                            [ngClass]="fields.target_material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.target_material.label + (fields.target_material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.target_material.label}}<span *ngIf="fields.target_material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-materials_by_project_dd_single 
                            data-cy="selector"
                            [type]="fields.target_material.control.type"
                            formControlName="target_material"
                            (displayTextChange)="fields.target_material.control.displayText=$event"
                            [placeholder]="fields.target_material.control.placeholder"
                            [styles]="fields.target_material.control.styles"
                            [tooltip]="fields.target_material.control.tooltip"
                          [projectId]="$fields_target_material_selector_inParams_projectId"
                        >
                        </Materials-materials_by_project_dd_single>
                        <ng-container *ngIf="fields.target_material.invalid">
                          <ng-container *ngFor="let error of fields.target_material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden && !fields.packaging.removed" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}} {{fields.packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.packaging.label + (fields.packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.packaging.label}}<span *ngIf="fields.packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.packaging.control.type"
                            formControlName="packaging"
                            (displayTextChange)="fields.packaging.control.displayText=$event"
                            [placeholder]="fields.packaging.control.placeholder"
                            [styles]="fields.packaging.control.styles"
                            [tooltip]="fields.packaging.control.tooltip"
                          [materialId]="$fields_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden && !fields.lot.removed" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}} {{fields.lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lot.label + (fields.lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lot.label}}<span *ngIf="fields.lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Lots-lots_dd_single 
                            data-cy="selector"
                            [type]="fields.lot.control.type"
                            formControlName="lot"
                            (displayTextChange)="fields.lot.control.displayText=$event"
                            [placeholder]="fields.lot.control.placeholder"
                            [styles]="fields.lot.control.styles"
                            [tooltip]="fields.lot.control.tooltip"
                          [materialId]="$fields_lot_selector_inParams_materialId"
                          [vendorLotId]="$fields_lot_selector_inParams_vendorLotId"
                        >
                        </Lots-lots_dd_single>
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_lot" *ngIf="!fields.vendor_lot.hidden && !fields.vendor_lot.removed" 
                            class="field-container standard {{fields.vendor_lot.invalid ? 'invalid' : ''}} {{fields.vendor_lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_lot.styles.style"
                            [ngClass]="fields.vendor_lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_lot.label + (fields.vendor_lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_lot.label}}<span *ngIf="fields.vendor_lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Lots-vendorlots_dd_single 
                            data-cy="selector"
                            [type]="fields.vendor_lot.control.type"
                            formControlName="vendor_lot"
                            (displayTextChange)="fields.vendor_lot.control.displayText=$event"
                            [placeholder]="fields.vendor_lot.control.placeholder"
                            [styles]="fields.vendor_lot.control.styles"
                            [tooltip]="fields.vendor_lot.control.tooltip"
                          [materialId]="$fields_vendor_lot_selector_inParams_materialId"
                          [lotId]="$fields_vendor_lot_selector_inParams_lotId"
                        >
                        </Lots-vendorlots_dd_single>
                        <ng-container *ngIf="fields.vendor_lot.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight" *ngIf="!fields.weight.hidden && !fields.weight.removed" 
                            class="field-container standard {{fields.weight.invalid ? 'invalid' : ''}} {{fields.weight.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.weight.styles.style"
                            [ngClass]="fields.weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weight.label + (fields.weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weight.label}}<span *ngIf="fields.weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="weight"
                                matInput
                                numberBox
                                [format]="fields.weight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.weight.control.readOnly || fields.weight.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.weight.control.placeholder}}"
                                [ngStyle]="fields.weight.control.styles.style"
                                [ngClass]="fields.weight.control.styles.classes"
                                [matTooltip]="fields.weight.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.weight.invalid">
                          <ng-container *ngFor="let error of fields.weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden && !fields.weight_uom.removed" 
                            class="field-container standard {{fields.weight_uom.invalid ? 'invalid' : ''}} {{fields.weight_uom.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weight_uom.label + (fields.weight_uom.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                            [tooltip]="fields.weight_uom.control.tooltip"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </Inventory-measurement_units_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-quantity_to_transform" *ngIf="!fields.quantity_to_transform.hidden && !fields.quantity_to_transform.removed" 
                            class="field-container standard {{fields.quantity_to_transform.invalid ? 'invalid' : ''}} {{fields.quantity_to_transform.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.quantity_to_transform.styles.style"
                            [ngClass]="fields.quantity_to_transform.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.quantity_to_transform.label + (fields.quantity_to_transform.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.quantity_to_transform.label}}<span *ngIf="fields.quantity_to_transform.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.quantity_to_transform.control.styles.style"
                              [ngClass]="fields.quantity_to_transform.control.styles.classes"
                              [matTooltip]="fields.quantity_to_transform.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.quantity_to_transform.control.text }}</div>
                        <ng-container *ngIf="fields.quantity_to_transform.invalid">
                          <ng-container *ngFor="let error of fields.quantity_to_transform.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-convert_quantity" *ngIf="!fields.convert_quantity.hidden && !fields.convert_quantity.removed" 
                            class="field-container standard {{fields.convert_quantity.invalid ? 'invalid' : ''}} {{fields.convert_quantity.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.convert_quantity.styles.style"
                            [ngClass]="fields.convert_quantity.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.convert_quantity.label + (fields.convert_quantity.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.convert_quantity.label}}<span *ngIf="fields.convert_quantity.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="convert_quantity"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.convert_quantity.control.styles.style"
                                      [ngClass]="fields.convert_quantity.control.styles.classes"
                                      [matTooltip]="fields.convert_quantity.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.convert_quantity.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.convert_quantity.invalid">
                          <ng-container *ngFor="let error of fields.convert_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-quantity_to_transform"
                     *ngIf="!fieldsets.quantity_to_transform.hidden && !fieldsets.quantity_to_transform.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.quantity_to_transform.collapsible }">
                    <div *ngIf="!fieldsets.quantity_to_transform.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.quantity_to_transform.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.quantity_to_transform.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.quantity_to_transform.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.quantity_to_transform.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.quantity_to_transform.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.quantity_to_transform.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-conversion_rate" *ngIf="!fields.conversion_rate.hidden && !fields.conversion_rate.removed" 
                            class="field-container standard {{fields.conversion_rate.invalid ? 'invalid' : ''}} {{fields.conversion_rate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.conversion_rate.styles.style"
                            [ngClass]="fields.conversion_rate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.conversion_rate.label + (fields.conversion_rate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.conversion_rate.label}}<span *ngIf="fields.conversion_rate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="conversion_rate"
                                matInput
                                numberBox
                                [format]="fields.conversion_rate.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.conversion_rate.control.readOnly || fields.conversion_rate.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.conversion_rate.control.placeholder}}"
                                [ngStyle]="fields.conversion_rate.control.styles.style"
                                [ngClass]="fields.conversion_rate.control.styles.classes"
                                [matTooltip]="fields.conversion_rate.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.conversion_rate.invalid">
                          <ng-container *ngFor="let error of fields.conversion_rate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-new_quantity" *ngIf="!fields.new_quantity.hidden && !fields.new_quantity.removed" 
                            class="field-container standard {{fields.new_quantity.invalid ? 'invalid' : ''}} {{fields.new_quantity.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.new_quantity.styles.style"
                            [ngClass]="fields.new_quantity.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.new_quantity.label + (fields.new_quantity.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.new_quantity.label}}<span *ngIf="fields.new_quantity.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.new_quantity.control.styles.style"
                              [ngClass]="fields.new_quantity.control.styles.classes"
                              [matTooltip]="fields.new_quantity.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.new_quantity.control.text }}</div>
                        <ng-container *ngIf="fields.new_quantity.invalid">
                          <ng-container *ngFor="let error of fields.new_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.quantity_to_transform.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>