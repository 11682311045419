import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_footprint_generic_json_material_import_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { materials?: $frontendTypes.Dynamics365BusinessCentral.DatexMaterialInfo[], transaction_id?: string, ingress_id?: string, application_name?: string, project?: string, owner?: string, lookup?: string, matname?: string, matdesc?: string, matgroup?: string, cost?: string, price?: string, packg?: string }): Promise<{ messages?: any[], results?: { requestId?: string, referenceCode?: string, project?: string, message?: string, entities?: any, transactionId?: string }[], reasons?: string[] }> 
  {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/functions/footprint_generic_json_material_import_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

