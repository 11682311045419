import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ds_get_inbound_order_receiving_taskService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { taskId: number }): 
  Promise<{ result: { Id?: number, ExpectedInventoryAmount?: number, ExpectedInventoryAmountPackId?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, LotId?: number, ProjectId?: number, StatusId?: number, WarehouseId?: number, ExpectedTargetLicensePlate?: { Id?: number, Archived?: boolean, LocationId?: number, TypeId?: number, LicensePlateContents?: { LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[] }, ShipmentLine?: { Id?: number, ActualAmount?: number, ActualPackagedAmount?: number, StatusId?: number }, OrderLine?: { OrderId?: number, LineNumber?: number, StatusId?: number }, Shipment?: { Id?: number, ActualWarehouseId?: number, ExpectedWarehouseId?: number, StatusId?: number }, Order?: { Id?: number, OrderStatusId?: number } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.taskId)) {
      missingRequiredInParams.push('\'taskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/datasources/ds_get_inbound_order_receiving_task/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      taskId: number    }) {
        if(isNil(inParams.taskId)) {
          return true; 
        }
      return false;
    }
}
