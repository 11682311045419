import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';



@Injectable({ providedIn: 'root' })
export class Discussions_OperationService {

  constructor(
    private injector: Injector,
    private $utils: UtilsService,
  ) {
  }

  public Discussions: Discussions_OperationService = this;

  public Enable_Discussions_AbilityToDelete = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Discussions_AbilityToDelete');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Discussions_AbilityToDelete');
    }
  }
  public Enable_Discussions_Feature = {
    isAuthorized: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Discussions_Feature');
    },
    isAssigned: (): Promise<boolean> => {
      return this.isAuthorized('Enable_Discussions_Feature');
    }
  }

  private isAuthorized(operationName: string): Promise<boolean> {
    return this.$utils.http.post(`${environment.backendUrl}api/Discussions/operations/${operationName}/isauthorized`, null);
  }
}
