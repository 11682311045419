import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { AsnOrders_ModuleContextService } from './AsnOrders.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class AsnOrders_license_plate_label_report_ComponentContextService {
  private readonly moduleContext: AsnOrders_ModuleContextService;

  constructor(moduleContext: AsnOrders_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'license_plate_label_report',
      type: ComponentType.report
    }
  }
}