import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_dock_appointments_selection_grid_ComponentContextService } from './FootprintManager.dock_appointments_selection_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_dock_appointments_selection_gridComponentEntity {
  Id?: number, CheckedInBy?: string, CheckedInOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, Status?: { Name?: string }, ScheduledProject?: { LookupCode?: string }, AssignedLocation?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInDate?: { id?: number, convertedDate?: string }, dockAppointmentType?: { Id?: number, Name?: string, Options?: string, SystemDefined?: boolean, SystemLabel?: string }}

interface IFootprintManager_dock_appointments_selection_gridComponentInParams {
  typeIds?: number[], carrierId?: number, statusIds?: number[], warehouseId?: number, scheduledProjectId?: number, excludedDockAppointmentIds?: number[]}

interface IFootprintManager_dock_appointments_selection_gridComponentOutParams {
  dockAppointmentId?: number}

class FootprintManager_dock_appointments_selection_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_dock_appointments_selection_gridComponent;
  entity: IFootprintManager_dock_appointments_selection_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: null,
    type: null,
    status: null,
    owner: null,
    project: null,
    scheduled_arrival: null,
    scheduled_departure: null,
    dock_door: null,
    warehouse_name: null,
    checked_in_on: null,
    reference_number: null,
    carrier: null,
  }



  constructor(
    grid: FootprintManager_dock_appointments_selection_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.lookupcode = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.type = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.type,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.owner = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.owner,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.project = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.project,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.scheduled_arrival = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.scheduled_arrival,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.scheduled_departure = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.scheduled_departure,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.dock_door = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.dock_door,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouse_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouse_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.checked_in_on = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.checked_in_on,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.reference_number = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.reference_number,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.carrier = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.carrier,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_dock_appointments_selection_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_dock_appointments_selection_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      locationIds:  null ,
      fromDate:  $grid.filters.from_date.control.value ,
      typeIds:  $grid.inParams.typeIds ,
      carrierId:  $grid.inParams.carrierId ,
      statusIds:  $grid.inParams.statusIds ,
      toDate:  $grid.filters.to_date.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      scheduledProjectId:  $grid.inParams.scheduledProjectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      excludedDockAppointmentIds:  $grid.inParams.excludedDockAppointmentIds ,
    };
    const data = await this.$datasources.DockAppointments.ds_dock_appointments_selection_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_dock_appointments_selection_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.type.displayControl as TextModel).text = $row.entity.dockAppointmentType?.Name;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.owner.displayControl as TextModel).text = $row.entity.ScheduledOwner?.LookupCode;
    (this.cells.project.displayControl as TextModel).text = $row.entity.ScheduledProject?.LookupCode;
    (this.cells.dock_door.displayControl as TextModel).text = $utils.isDefined($row.entity.AssignedLocation?.Name) ? $row.entity.AssignedLocation?.Name : $row.entity.ScheduledLocation?.Name;
    (this.cells.warehouse_name.displayControl as TextModel).text = $row.entity.Warehouse?.Name;
    (this.cells.reference_number.displayControl as TextModel).text = $row.entity.ReferenceNumber;
    (this.cells.carrier.displayControl as TextModel).text = $row.entity.ScheduledCarrier?.Name;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_appointment_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $row: FootprintManager_dock_appointments_selection_gridComponentRowModel,
  $grid: FootprintManager_dock_appointments_selection_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  const dialogResult = await $shell.FootprintManager.opendock_appointment_editorDialog({
      dockAppointmentId: $row.entity.Id
  },'flyout',EModalSize.Large );
  if ($utils.isDefined(dialogResult)) {
      const userConfirm = dialogResult.confirm;
      if (userConfirm) {
          $row.refresh();
      }
  }
  
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_dock_appointments_selection_gridComponentRowModel,
  $grid: FootprintManager_dock_appointments_selection_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  // Set Date/Time Formats
  const format = `${$settings.DockAppointments.DateFormat}, ${$settings.DockAppointments.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  
  
  if ($utils.isDefined($row.entity.convertedScheduledArrival?.convertedDate)){
  $row.cells.scheduled_arrival.displayControl.text = $utils.date.format($row.entity.convertedScheduledArrival?.convertedDate , format);
  }
  
  if ($utils.isDefined($row.entity.convertedScheduledDeparture?.convertedDate)){
  $row.cells.scheduled_departure.displayControl.text = $utils.date.format($row.entity.convertedScheduledDeparture?.convertedDate, format);
  }
  
  if ($utils.isDefined($row.entity.convertedCheckInDate?.convertedDate)){
  $row.cells.checked_in_on.displayControl.text =  $utils.date.format($row.entity.convertedCheckInDate?.convertedDate, format);
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-dock_appointments_selection_grid',
  templateUrl: './FootprintManager.dock_appointments_selection_grid.component.html'
})
export class FootprintManager_dock_appointments_selection_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_dock_appointments_selection_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_dock_appointments_selection_gridComponentInParams = { typeIds: [], carrierId: null, statusIds: [], warehouseId: null, scheduledProjectId: null, excludedDockAppointmentIds: [] };

  outParams: IFootprintManager_dock_appointments_selection_gridComponentOutParams = { dockAppointmentId: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ dockAppointmentId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Appointment', false, false, null, false),       type: new GridHeaderModel(new HeaderStyles(null, null), 'type', 'Type', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'owner', 'Owner', false, false, null, false),       project: new GridHeaderModel(new HeaderStyles(null, null), 'project', 'Project', false, false, null, false),       scheduled_arrival: new GridHeaderModel(new HeaderStyles(null, null), 'scheduled_arrival', 'Scheduled arrival', false, false, null, false),       scheduled_departure: new GridHeaderModel(new HeaderStyles(null, null), 'scheduled_departure', 'Scheduled departure', false, false, null, false),       dock_door: new GridHeaderModel(new HeaderStyles(null, null), 'dock_door', 'Dock door', false, false, null, false),       warehouse_name: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse_name', 'Warehouse', false, false, null, false),       checked_in_on: new GridHeaderModel(new HeaderStyles(null, null), 'checked_in_on', 'Checked-in', false, false, null, false),       reference_number: new GridHeaderModel(new HeaderStyles(null, null), 'reference_number', 'Reference number', false, false, null, false),       carrier: new GridHeaderModel(new HeaderStyles(null, null), 'carrier', 'Carrier', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_dock_appointments_selection_gridComponentRowModel[] = [];

  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }
  @Input('carrierId') set $inParams_carrierId(value: any) {
    this.inParams['carrierId'] = value;
  }
  get $inParams_carrierId(): any {
    return this.inParams['carrierId'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('scheduledProjectId') set $inParams_scheduledProjectId(value: any) {
    this.inParams['scheduledProjectId'] = value;
  }
  get $inParams_scheduledProjectId(): any {
    return this.inParams['scheduledProjectId'] ;
  }
  @Input('excludedDockAppointmentIds') set $inParams_excludedDockAppointmentIds(value: any) {
    this.inParams['excludedDockAppointmentIds'] = value;
  }
  get $inParams_excludedDockAppointmentIds(): any {
    return this.inParams['excludedDockAppointmentIds'] ;
  }

  topToolbar = {
      on_previous_day: new ToolModel(new ButtonModel('on_previous_day', new ButtonStyles(null, null), false, false, false, 'Previous day ', 'icon-ic_fluent_arrow_circle_left_20_regular', null)
    , false),
      on_today: new ToolModel(new ButtonModel('on_today', new ButtonStyles(null, null), false, false, false, 'Today', 'icon-ic_fluent_calendar_today_20_regular', null)
    , false),
      on_next_day: new ToolModel(new ButtonModel('on_next_day', new ButtonStyles(null, null), false, false, false, 'Next day', 'icon-ic_fluent_arrow_circle_right_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_week: new ToolModel(new ButtonModel('on_week', new ButtonStyles(null, null), false, false, false, 'This week', 'icon-ic_fluent_calendar_work_week_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'From', false, false),
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'To', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams


  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Dock appointments';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      locationIds:  null ,
      fromDate:  $grid.filters.from_date.control.value ,
      typeIds:  $grid.inParams.typeIds ,
      carrierId:  $grid.inParams.carrierId ,
      statusIds:  $grid.inParams.statusIds ,
      toDate:  $grid.filters.to_date.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      scheduledProjectId:  $grid.inParams.scheduledProjectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      excludedDockAppointmentIds:  $grid.inParams.excludedDockAppointmentIds ,
    };
    try {
      const data = await this.$datasources.DockAppointments.ds_dock_appointments_selection_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_dock_appointments_selection_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_dock_appointments_selection_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_dock_appointments_selection_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_dock_appointments_selection_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  // Set Date/Time Formats
  const format = `${$settings.DockAppointments.DateFormat}`;
  $grid.filters.from_date.control.format = format;
  $grid.filters.to_date.control.value = format;
  
  // Default current date
  $grid.filters.from_date.control.value = $utils.date.now()
  $grid.filters.to_date.control.value = $utils.date.add(14, 'day', $utils.date.now());
  
  
  
  }
  on_next_day(event = null) {
    return this.on_next_dayInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_next_dayInternal(
    $grid: FootprintManager_dock_appointments_selection_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.add(1, 'day', $grid.filters.from_date.control.value);
  $grid.filters.to_date.control.value = $utils.date.add(1, 'day', $grid.filters.to_date.control.value);
  $grid.refresh();
  }
  on_previous_day(event = null) {
    return this.on_previous_dayInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_previous_dayInternal(
    $grid: FootprintManager_dock_appointments_selection_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.add(-1, 'day', $grid.filters.from_date.control.value);
  $grid.filters.to_date.control.value = $utils.date.add(-1, 'day', $grid.filters.to_date.control.value);
  $grid.refresh();
  
  }
  on_today(event = null) {
    return this.on_todayInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_todayInternal(
    $grid: FootprintManager_dock_appointments_selection_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.now();
  $grid.filters.to_date.control.value = $utils.date.now();
  $grid.refresh();
  }
  on_this_week(event = null) {
    return this.on_this_weekInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_this_weekInternal(
    $grid: FootprintManager_dock_appointments_selection_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  let current = new Date 
  let week = []
  
  var today = current.getDate(); // return the number 1 - 31
  var currentDay = current.getDay(); // return day of the week 0 - 6, 0 = sunday
  var startDay = new Date(current.setDate(today - currentDay + 1)); // monday
  
  for (let i = 1; i <= 7; i++) {
    var firstDay = current.getDate() - current.getDay() + i 
    var lastDay = new Date(current.setDate(firstDay)).toISOString().slice(0, 10)
    week.push(lastDay)
  }
  
  $grid.filters.from_date.control.value = $utils.date.format($utils.date.startOf('day', startDay.toISOString()));
  $grid.filters.to_date.control.value = lastDay;
  
  $grid.refresh();
  
  
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_dock_appointments_selection_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_dock_appointments_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  const selectedRows = $grid.selectedRows;
  
  // Single row only
  if (selectedRowsCount == 1) {
  
      $grid.outParams.dockAppointmentId = selectedRows[0].entity.Id;
      $grid.events.outParamsChange.emit();
  }
  else if (selectedRowsCount > 1) {
      throw new Error('Only one Dock Appointment can be selected')
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
