import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Owners_OperationService } from './Owners.operation.service';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Owners_FlowService } from './Owners.flow.index';
import { Owners_ReportService } from './Owners.report.index';
import { Owners_LocalizationService } from './Owners.localization.service';
import { Owners_owners_by_count_widget_ComponentContextService } from './Owners.owners_by_count_widget.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Owners.frontend.types'
import { $frontendTypes as $types} from './Owners.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Owners-owners_by_count_widget',
  templateUrl: './Owners.owners_by_count_widget.component.html'
})
export class Owners_owners_by_count_widgetComponent extends BaseComponent implements OnInit {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs


  //#region Variables
  //#endregion  
  private entity: { Count?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Owners_ShellService,
private $datasources: Owners_DatasourceService,
private $flows: Owners_FlowService,
private $reports: Owners_ReportService,
private $localization: Owners_LocalizationService,
private $operations: Owners_OperationService,
private $logger: CleanupLoggerService,
private $context: Owners_owners_by_count_widget_ComponentContextService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.$utils;

    const dsParams = {
    };

    const data = await this.$datasources.Owners.ds_owners_by_count_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.$utils;

    this.value = this.format($utils.isDefined($widget.entity.Count) ? $widget.entity.Count : 0, 1);

    
    this.originalValue = $utils.isDefined($widget.entity.Count) ? $widget.entity.Count : 0;
  }

  format(num: number, digits: number): string {
    // at some point in time we should probably respect the selected culture from the localization service
    return isNil(num)
      ? ''
      : Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: digits}).format(num);
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
