import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { WorkOrders_ds_accessorial_operationcodes_by_projectId_ddService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_accessorial_tasks_gridService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_accessorial_charge_template_lines_by_template_idService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_accessorial_operationcode_by_operationcodeId_projectIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_accessorial_operationcodesService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_accessorial_operationcodes_by_projectIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_accessorial_template_operationcodes_by_projectIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_billingtasks_by_accessorial_taskIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_completed_accessorial_tasks_by_workOrderId_top1Service } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_confirmed_accessorial_task_event_detailsService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_current_tasks_by_workorderIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_measurement_unit_by_unitIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_operationcode_by_operationCodeIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_planned_tasks_by_workorderIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_released_tasks_by_workorderIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_task_by_taskIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_tasks_by_workorderIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_tasks_details_by_workorderIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_work_order_details_by_workOrderIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_get_workorder_by_workOrderIdService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_measurement_units_ddService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_work_order_completed_tasks_count_widgetService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_work_order_editorService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_work_order_open_tasks_count_widgetService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_work_order_statuses_ddService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_work_order_total_completed_units_by_date_widgetService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_work_order_total_open_units_by_date_widgetService } from './WorkOrders.datasource.index';
import { WorkOrders_ds_work_orders_gridService } from './WorkOrders.datasource.index';

@Injectable({ providedIn: 'root' })
export class WorkOrders_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public WorkOrders: WorkOrders_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_accessorial_operationcodes_by_projectId_dd: WorkOrders_ds_accessorial_operationcodes_by_projectId_ddService;
  public get ds_accessorial_operationcodes_by_projectId_dd(): WorkOrders_ds_accessorial_operationcodes_by_projectId_ddService {
    if(!this._ds_accessorial_operationcodes_by_projectId_dd) {
      this._ds_accessorial_operationcodes_by_projectId_dd = this.injector.get(WorkOrders_ds_accessorial_operationcodes_by_projectId_ddService);
    }
    return this._ds_accessorial_operationcodes_by_projectId_dd;
  }
  private _ds_accessorial_tasks_grid: WorkOrders_ds_accessorial_tasks_gridService;
  public get ds_accessorial_tasks_grid(): WorkOrders_ds_accessorial_tasks_gridService {
    if(!this._ds_accessorial_tasks_grid) {
      this._ds_accessorial_tasks_grid = this.injector.get(WorkOrders_ds_accessorial_tasks_gridService);
    }
    return this._ds_accessorial_tasks_grid;
  }
  private _ds_get_accessorial_charge_template_lines_by_template_id: WorkOrders_ds_get_accessorial_charge_template_lines_by_template_idService;
  public get ds_get_accessorial_charge_template_lines_by_template_id(): WorkOrders_ds_get_accessorial_charge_template_lines_by_template_idService {
    if(!this._ds_get_accessorial_charge_template_lines_by_template_id) {
      this._ds_get_accessorial_charge_template_lines_by_template_id = this.injector.get(WorkOrders_ds_get_accessorial_charge_template_lines_by_template_idService);
    }
    return this._ds_get_accessorial_charge_template_lines_by_template_id;
  }
  private _ds_get_accessorial_operationcode_by_operationcodeId_projectId: WorkOrders_ds_get_accessorial_operationcode_by_operationcodeId_projectIdService;
  public get ds_get_accessorial_operationcode_by_operationcodeId_projectId(): WorkOrders_ds_get_accessorial_operationcode_by_operationcodeId_projectIdService {
    if(!this._ds_get_accessorial_operationcode_by_operationcodeId_projectId) {
      this._ds_get_accessorial_operationcode_by_operationcodeId_projectId = this.injector.get(WorkOrders_ds_get_accessorial_operationcode_by_operationcodeId_projectIdService);
    }
    return this._ds_get_accessorial_operationcode_by_operationcodeId_projectId;
  }
  private _ds_get_accessorial_operationcodes: WorkOrders_ds_get_accessorial_operationcodesService;
  public get ds_get_accessorial_operationcodes(): WorkOrders_ds_get_accessorial_operationcodesService {
    if(!this._ds_get_accessorial_operationcodes) {
      this._ds_get_accessorial_operationcodes = this.injector.get(WorkOrders_ds_get_accessorial_operationcodesService);
    }
    return this._ds_get_accessorial_operationcodes;
  }
  private _ds_get_accessorial_operationcodes_by_projectId: WorkOrders_ds_get_accessorial_operationcodes_by_projectIdService;
  public get ds_get_accessorial_operationcodes_by_projectId(): WorkOrders_ds_get_accessorial_operationcodes_by_projectIdService {
    if(!this._ds_get_accessorial_operationcodes_by_projectId) {
      this._ds_get_accessorial_operationcodes_by_projectId = this.injector.get(WorkOrders_ds_get_accessorial_operationcodes_by_projectIdService);
    }
    return this._ds_get_accessorial_operationcodes_by_projectId;
  }
  private _ds_get_accessorial_template_operationcodes_by_projectId: WorkOrders_ds_get_accessorial_template_operationcodes_by_projectIdService;
  public get ds_get_accessorial_template_operationcodes_by_projectId(): WorkOrders_ds_get_accessorial_template_operationcodes_by_projectIdService {
    if(!this._ds_get_accessorial_template_operationcodes_by_projectId) {
      this._ds_get_accessorial_template_operationcodes_by_projectId = this.injector.get(WorkOrders_ds_get_accessorial_template_operationcodes_by_projectIdService);
    }
    return this._ds_get_accessorial_template_operationcodes_by_projectId;
  }
  private _ds_get_billingtasks_by_accessorial_taskId: WorkOrders_ds_get_billingtasks_by_accessorial_taskIdService;
  public get ds_get_billingtasks_by_accessorial_taskId(): WorkOrders_ds_get_billingtasks_by_accessorial_taskIdService {
    if(!this._ds_get_billingtasks_by_accessorial_taskId) {
      this._ds_get_billingtasks_by_accessorial_taskId = this.injector.get(WorkOrders_ds_get_billingtasks_by_accessorial_taskIdService);
    }
    return this._ds_get_billingtasks_by_accessorial_taskId;
  }
  private _ds_get_completed_accessorial_tasks_by_workOrderId_top1: WorkOrders_ds_get_completed_accessorial_tasks_by_workOrderId_top1Service;
  public get ds_get_completed_accessorial_tasks_by_workOrderId_top1(): WorkOrders_ds_get_completed_accessorial_tasks_by_workOrderId_top1Service {
    if(!this._ds_get_completed_accessorial_tasks_by_workOrderId_top1) {
      this._ds_get_completed_accessorial_tasks_by_workOrderId_top1 = this.injector.get(WorkOrders_ds_get_completed_accessorial_tasks_by_workOrderId_top1Service);
    }
    return this._ds_get_completed_accessorial_tasks_by_workOrderId_top1;
  }
  private _ds_get_confirmed_accessorial_task_event_details: WorkOrders_ds_get_confirmed_accessorial_task_event_detailsService;
  public get ds_get_confirmed_accessorial_task_event_details(): WorkOrders_ds_get_confirmed_accessorial_task_event_detailsService {
    if(!this._ds_get_confirmed_accessorial_task_event_details) {
      this._ds_get_confirmed_accessorial_task_event_details = this.injector.get(WorkOrders_ds_get_confirmed_accessorial_task_event_detailsService);
    }
    return this._ds_get_confirmed_accessorial_task_event_details;
  }
  private _ds_get_current_tasks_by_workorderId: WorkOrders_ds_get_current_tasks_by_workorderIdService;
  public get ds_get_current_tasks_by_workorderId(): WorkOrders_ds_get_current_tasks_by_workorderIdService {
    if(!this._ds_get_current_tasks_by_workorderId) {
      this._ds_get_current_tasks_by_workorderId = this.injector.get(WorkOrders_ds_get_current_tasks_by_workorderIdService);
    }
    return this._ds_get_current_tasks_by_workorderId;
  }
  private _ds_get_measurement_unit_by_unitId: WorkOrders_ds_get_measurement_unit_by_unitIdService;
  public get ds_get_measurement_unit_by_unitId(): WorkOrders_ds_get_measurement_unit_by_unitIdService {
    if(!this._ds_get_measurement_unit_by_unitId) {
      this._ds_get_measurement_unit_by_unitId = this.injector.get(WorkOrders_ds_get_measurement_unit_by_unitIdService);
    }
    return this._ds_get_measurement_unit_by_unitId;
  }
  private _ds_get_operationcode_by_operationCodeId: WorkOrders_ds_get_operationcode_by_operationCodeIdService;
  public get ds_get_operationcode_by_operationCodeId(): WorkOrders_ds_get_operationcode_by_operationCodeIdService {
    if(!this._ds_get_operationcode_by_operationCodeId) {
      this._ds_get_operationcode_by_operationCodeId = this.injector.get(WorkOrders_ds_get_operationcode_by_operationCodeIdService);
    }
    return this._ds_get_operationcode_by_operationCodeId;
  }
  private _ds_get_planned_tasks_by_workorderId: WorkOrders_ds_get_planned_tasks_by_workorderIdService;
  public get ds_get_planned_tasks_by_workorderId(): WorkOrders_ds_get_planned_tasks_by_workorderIdService {
    if(!this._ds_get_planned_tasks_by_workorderId) {
      this._ds_get_planned_tasks_by_workorderId = this.injector.get(WorkOrders_ds_get_planned_tasks_by_workorderIdService);
    }
    return this._ds_get_planned_tasks_by_workorderId;
  }
  private _ds_get_released_tasks_by_workorderId: WorkOrders_ds_get_released_tasks_by_workorderIdService;
  public get ds_get_released_tasks_by_workorderId(): WorkOrders_ds_get_released_tasks_by_workorderIdService {
    if(!this._ds_get_released_tasks_by_workorderId) {
      this._ds_get_released_tasks_by_workorderId = this.injector.get(WorkOrders_ds_get_released_tasks_by_workorderIdService);
    }
    return this._ds_get_released_tasks_by_workorderId;
  }
  private _ds_get_task_by_taskId: WorkOrders_ds_get_task_by_taskIdService;
  public get ds_get_task_by_taskId(): WorkOrders_ds_get_task_by_taskIdService {
    if(!this._ds_get_task_by_taskId) {
      this._ds_get_task_by_taskId = this.injector.get(WorkOrders_ds_get_task_by_taskIdService);
    }
    return this._ds_get_task_by_taskId;
  }
  private _ds_get_tasks_by_workorderId: WorkOrders_ds_get_tasks_by_workorderIdService;
  public get ds_get_tasks_by_workorderId(): WorkOrders_ds_get_tasks_by_workorderIdService {
    if(!this._ds_get_tasks_by_workorderId) {
      this._ds_get_tasks_by_workorderId = this.injector.get(WorkOrders_ds_get_tasks_by_workorderIdService);
    }
    return this._ds_get_tasks_by_workorderId;
  }
  private _ds_get_tasks_details_by_workorderId: WorkOrders_ds_get_tasks_details_by_workorderIdService;
  public get ds_get_tasks_details_by_workorderId(): WorkOrders_ds_get_tasks_details_by_workorderIdService {
    if(!this._ds_get_tasks_details_by_workorderId) {
      this._ds_get_tasks_details_by_workorderId = this.injector.get(WorkOrders_ds_get_tasks_details_by_workorderIdService);
    }
    return this._ds_get_tasks_details_by_workorderId;
  }
  private _ds_get_work_order_details_by_workOrderId: WorkOrders_ds_get_work_order_details_by_workOrderIdService;
  public get ds_get_work_order_details_by_workOrderId(): WorkOrders_ds_get_work_order_details_by_workOrderIdService {
    if(!this._ds_get_work_order_details_by_workOrderId) {
      this._ds_get_work_order_details_by_workOrderId = this.injector.get(WorkOrders_ds_get_work_order_details_by_workOrderIdService);
    }
    return this._ds_get_work_order_details_by_workOrderId;
  }
  private _ds_get_workorder_by_workOrderId: WorkOrders_ds_get_workorder_by_workOrderIdService;
  public get ds_get_workorder_by_workOrderId(): WorkOrders_ds_get_workorder_by_workOrderIdService {
    if(!this._ds_get_workorder_by_workOrderId) {
      this._ds_get_workorder_by_workOrderId = this.injector.get(WorkOrders_ds_get_workorder_by_workOrderIdService);
    }
    return this._ds_get_workorder_by_workOrderId;
  }
  private _ds_measurement_units_dd: WorkOrders_ds_measurement_units_ddService;
  public get ds_measurement_units_dd(): WorkOrders_ds_measurement_units_ddService {
    if(!this._ds_measurement_units_dd) {
      this._ds_measurement_units_dd = this.injector.get(WorkOrders_ds_measurement_units_ddService);
    }
    return this._ds_measurement_units_dd;
  }
  private _ds_work_order_completed_tasks_count_widget: WorkOrders_ds_work_order_completed_tasks_count_widgetService;
  public get ds_work_order_completed_tasks_count_widget(): WorkOrders_ds_work_order_completed_tasks_count_widgetService {
    if(!this._ds_work_order_completed_tasks_count_widget) {
      this._ds_work_order_completed_tasks_count_widget = this.injector.get(WorkOrders_ds_work_order_completed_tasks_count_widgetService);
    }
    return this._ds_work_order_completed_tasks_count_widget;
  }
  private _ds_work_order_editor: WorkOrders_ds_work_order_editorService;
  public get ds_work_order_editor(): WorkOrders_ds_work_order_editorService {
    if(!this._ds_work_order_editor) {
      this._ds_work_order_editor = this.injector.get(WorkOrders_ds_work_order_editorService);
    }
    return this._ds_work_order_editor;
  }
  private _ds_work_order_open_tasks_count_widget: WorkOrders_ds_work_order_open_tasks_count_widgetService;
  public get ds_work_order_open_tasks_count_widget(): WorkOrders_ds_work_order_open_tasks_count_widgetService {
    if(!this._ds_work_order_open_tasks_count_widget) {
      this._ds_work_order_open_tasks_count_widget = this.injector.get(WorkOrders_ds_work_order_open_tasks_count_widgetService);
    }
    return this._ds_work_order_open_tasks_count_widget;
  }
  private _ds_work_order_statuses_dd: WorkOrders_ds_work_order_statuses_ddService;
  public get ds_work_order_statuses_dd(): WorkOrders_ds_work_order_statuses_ddService {
    if(!this._ds_work_order_statuses_dd) {
      this._ds_work_order_statuses_dd = this.injector.get(WorkOrders_ds_work_order_statuses_ddService);
    }
    return this._ds_work_order_statuses_dd;
  }
  private _ds_work_order_total_completed_units_by_date_widget: WorkOrders_ds_work_order_total_completed_units_by_date_widgetService;
  public get ds_work_order_total_completed_units_by_date_widget(): WorkOrders_ds_work_order_total_completed_units_by_date_widgetService {
    if(!this._ds_work_order_total_completed_units_by_date_widget) {
      this._ds_work_order_total_completed_units_by_date_widget = this.injector.get(WorkOrders_ds_work_order_total_completed_units_by_date_widgetService);
    }
    return this._ds_work_order_total_completed_units_by_date_widget;
  }
  private _ds_work_order_total_open_units_by_date_widget: WorkOrders_ds_work_order_total_open_units_by_date_widgetService;
  public get ds_work_order_total_open_units_by_date_widget(): WorkOrders_ds_work_order_total_open_units_by_date_widgetService {
    if(!this._ds_work_order_total_open_units_by_date_widget) {
      this._ds_work_order_total_open_units_by_date_widget = this.injector.get(WorkOrders_ds_work_order_total_open_units_by_date_widgetService);
    }
    return this._ds_work_order_total_open_units_by_date_widget;
  }
  private _ds_work_orders_grid: WorkOrders_ds_work_orders_gridService;
  public get ds_work_orders_grid(): WorkOrders_ds_work_orders_gridService {
    if(!this._ds_work_orders_grid) {
      this._ds_work_orders_grid = this.injector.get(WorkOrders_ds_work_orders_gridService);
    }
    return this._ds_work_orders_grid;
  }
}

