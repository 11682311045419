import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_unpicking_form_ComponentContextService } from './FootprintManager.unpicking_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Inventory_licenseplates_dd_singleComponent } from './Inventory.licenseplates_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Inventory_licenseplates_dd_singleComponent),
  ],
  selector: 'FootprintManager-unpicking_form',
  templateUrl: './FootprintManager.unpicking_form.component.html'
})
export class FootprintManager_unpicking_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { licenseplateId: number, locationId?: number, inventory?: { lotId: number, packagingId: number, packagedAmount: number, shipmentLineId: number, unpickPackagedAmount?: number, serialNumberIds?: number[] }, suppress_manual_allocation?: boolean } = { licenseplateId: null, locationId: null, inventory: { lotId: null, packagingId: null, packagedAmount: null, shipmentLineId: null, unpickPackagedAmount: null, serialNumberIds: [] }, suppress_manual_allocation: null };
  //#region Inputs
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  @Input('inventory') set $inParams_inventory(v: { lotId: number, packagingId: number, packagedAmount: number, shipmentLineId: number, unpickPackagedAmount?: number, serialNumberIds?: number[] }) {
    this.inParams.inventory = v;
  }
  get $inParams_inventory(): { lotId: number, packagingId: number, packagedAmount: number, shipmentLineId: number, unpickPackagedAmount?: number, serialNumberIds?: number[] } {
    return this.inParams.inventory;
  }
  @Input('suppress_manual_allocation') set $inParams_suppress_manual_allocation(v: boolean) {
    this.inParams.suppress_manual_allocation = v;
  }
  get $inParams_suppress_manual_allocation(): boolean {
    return this.inParams.suppress_manual_allocation;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { warehouseId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    unpick_license_plate: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_create_manual_allocation: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    unpick_packaged_amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    new_licenseplate_checkbox: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    new_licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    unpick_license_plate: new FieldModel(new CheckBoxModel(this.formGroup.controls['unpick_license_plate'] as DatexFormControl, null, false, false, 'Unpick license plate', null)
, new ControlContainerStyles(null, null), '', false, false),
    is_create_manual_allocation: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_create_manual_allocation'] as DatexFormControl, null, false, false, 'Create manual allocation', null)
, new ControlContainerStyles(null, null), '', false, false),
    packaged_amount: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Packaged amount', false, false),
    unpick_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['unpick_packaged_amount'] as DatexFormControl, null, false, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Packaged amount to unpick', true, false),
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Target location', true, false),
    new_licenseplate_checkbox: new FieldModel(new CheckBoxModel(this.formGroup.controls['new_licenseplate_checkbox'] as DatexFormControl, null, false, false, 'New license plate?', null)
, new ControlContainerStyles(null, null), '', false, false),
    licenseplate: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['licenseplate'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'License plate', true, false),
    new_licenseplate: new FieldModel(new TextBoxModel(this.formGroup.controls['new_licenseplate'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'License plate', true, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Notes', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    licenseplate: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    misc: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.vars.warehouseId;
    
    return expr;
  }

  get $fields_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_licenseplate_selector_inParams_locationId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.location.control.value;
    
    return expr;
  }

  get $fields_licenseplate_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_licenseplate_selector_inParams_archived(): boolean {
    const $form = this;
    const $utils = this.$utils;
    const expr = false;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    unpick_license_plate: this.fields.unpick_license_plate.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    is_create_manual_allocation: this.fields.is_create_manual_allocation.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    unpick_packaged_amount: this.fields.unpick_packaged_amount.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    location: this.fields.location.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    new_licenseplate_checkbox: this.fields.new_licenseplate_checkbox.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    licenseplate: this.fields.licenseplate.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    new_licenseplate: this.fields.new_licenseplate.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    notes: this.fields.notes.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_unpicking_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Unpick Inventory';
  
    const $form = this;
    const $utils = this.$utils;

    (this.fields.is_create_manual_allocation.control as CheckBoxModel).reset(false);
    (this.fields.packaged_amount.control as TextModel).text = $form.inParams.inventory?.packagedAmount?.toString();
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .unpick_license_plate
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.set_state();
      });
    this.$formGroupFieldValidationObservables
      .is_create_manual_allocation
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .unpick_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_change();
      });
    this.$formGroupFieldValidationObservables
      .new_licenseplate_checkbox
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_new_licenseplate_clicked();
      });
    this.$formGroupFieldValidationObservables
      .licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .new_licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootprintManager_unpicking_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_unpicking_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootprintManager_unpicking_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_unpicking_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Handle double click scenario
  $form.toolbar.confirm.control.readOnly = true;
  
  try {
      if ($form.fields.unpick_license_plate.control.value) {
          checkLicensePlateRequiredFields();
  
          await unpickLicensePlate({
              licensePlateId: $form.inParams.licenseplateId,
              locationId: $form.fields.location.control.value
          });
  
          // Clear the parent CLP
          let payload: any = {};
          payload.ParentId = null;
          await $flows.Utilities.crud_update_flow({ entitySet: 'LicensePlates', id: $form.inParams.licenseplateId, entity: payload });
      }
      else {
          checkInventoryRequiredFields();
          let materialId = await getInventoryEntities();
  
          await unpickInventory({
              materialId: materialId,
              packagingId: $form.inParams.inventory.packagingId,
              packagedAmount: $form.fields.unpick_packaged_amount.control.value,
              shipmentLineId: $form.inParams.inventory.shipmentLineId
          });
      }
  
  
  
  
      $form.outParams.confirm = true;
      $form.close();
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootprintManager.openErrorDialog('Error unpicking inventory', error.message);
  }
  
  $form.toolbar.confirm.control.readOnly = false;
  
  
  
  /*****************************************
   * FUNCTIONS
  ******************************************/
  function checkInventoryRequiredFields() {
      // Check required fields
      if (!$utils.isAllDefined(
          $form.fields.unpick_packaged_amount.control.value,
          $form.fields.location.control.value
      )) {
          throw new Error('Please complete all required fields.');
      }
      if ($form.fields.unpick_packaged_amount.control.value > $form.fields.packaged_amount.control.text) {
          throw new Error('Amount to unpick is greater than current amount picked.');
      }
  }
  
  function checkLicensePlateRequiredFields() {
      if (!$utils.isDefined($form.fields.location.control.value)) {
          throw new Error(`Missing target location`);
      }
  }
  
  async function getInventoryEntities() {
      const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $form.inParams.inventory.lotId })).result
      if (!$utils.isDefined(lot)) { throw new Error('Unable to determine lot'); }
  
      var materialId = lot[0].MaterialId;
  
      // Identify the type of material
      const fetchMaterialResult = await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      });
      const materials = fetchMaterialResult.result;
  
      if (!$utils.isDefined(materials)) { throw new Error('Unable to determine material'); }
  
      if (!$utils.isDefined($form.inParams.inventory.serialNumberIds) && (materials[0].ControllerTypeId == 3 || materials[0].ControllerTypeId == 4
          || materials[0].IsFixedWeight == false || materials[0].IsFixedVolume == false
          || materials[0].IsFixedLength == false)) {
  
          const dialogResult = await $shell.FootprintManager.openserialnumbers_selection_gridDialog({
              lotId: $form.inParams.inventory.lotId,
              licenseplateId: $form.inParams.licenseplateId,
              packagingId: $form.inParams.inventory.packagingId,
              packagedAmountRequest: $form.fields.unpick_packaged_amount.control.value
          });
  
          $form.inParams.inventory.serialNumberIds = dialogResult.SerialNumberIds;
          var confirmResult = dialogResult.confirm;
          if (!confirmResult) {
              $form.toolbar.confirm.control.readOnly = false; //So they can try again
  
          }
      }
  
      return materialId;
  }
  
  async function unpickInventory(inputs: { materialId: number, packagingId: number, packagedAmount: number, shipmentLineId: number }) {
      //O.Arias - 01/16/2024 - Bug FootPrint Cloud 142834: The User is able to Unpick inventory to the location which already have different material inventory available
      //O.Arias - 01/16/2024 - Bug FootPrint Cloud 150105: Outbound_Kit Picking_Unpicking_The User is able to Unpick inventory to the location which already have different material inventory available
      await $flows.Inventory.validate_location_flow({ lotId: $form.inParams.inventory.lotId, locationId: $form.fields.location.control.value, licensePlateId: $form.fields.new_licenseplate_checkbox.control.value ? null : $form.fields.licenseplate.control.value })
  
      let result = (await $flows.SalesOrders.unpick_inventory_flow({
          licenseplateId: $form.inParams.licenseplateId,
          lotId: $form.inParams.inventory.lotId,
          packagingId: $form.inParams.inventory.packagingId,
          unpickPackagedAmount: $form.fields.unpick_packaged_amount.control.value,
          targetLicensePlate: {
              id: $form.fields.new_licenseplate_checkbox.control.value ? null : $form.fields.licenseplate.control.value,
              lookupcode: $form.fields.new_licenseplate_checkbox.control.value ? $form.fields.new_licenseplate.control.value : null,
              locationId: $form.fields.location.control.value
          },
          serialNumberIds: $form.inParams.inventory.serialNumberIds,
          shipmentLineId: $form.inParams.inventory.shipmentLineId,
          notes: $form.fields.notes.control.value
      }));
  
      if ($utils.isDefined(result?.reasons)) {
          throw new Error(`Error unpicking inventory: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue}`);
      }
  
      if ($form.fields.is_create_manual_allocation.control.value) {
          let manualAllocationResult = (await $flows.SalesOrders.create_manual_allocation_flow({
              materialId: inputs.materialId,
              packagingId: inputs.packagingId,
              vendorLotId: null,
              lotId: null,
              licensePlateId: null,
              sourceLocationId: null,
              packagedAmount: inputs.packagedAmount,
              shipmentLineId: inputs.shipmentLineId
          }));
  
          if ($utils.isDefined(manualAllocationResult?.reasons)) {
              await $shell.FootprintManager.openErrorDialog('Error creating manual allocation', `${(await $flows.Utilities.grammar_format_string_array_flow({ values: manualAllocationResult.reasons })).formattedValue}`);
          }
      }
  }
  
  async function unpickLicensePlate(inputs: { licensePlateId: number, locationId: number }) {
      // Validate move
      let contents = (await $datasources.Inventory.ds_get_licenseplatecontents_by_licenseplateId.get({ licenseplateId: inputs.licensePlateId })).result;
  
      for (let content of contents) {
          await $flows.Inventory.validate_location_flow({
              lotId: content.LotId,
              locationId: $form.fields.location.control.value,
              licensePlateId: content.LicensePlateId
          });
      }
  
      // Unpick from shipment
      let result = (await $flows.SalesOrders.unpick_licenseplate_flow({
          licenseplateId: inputs.licensePlateId,
          targetLocationId: inputs.locationId
      }));
  
      if ($utils.isDefined(result.reason)) {
          throw new Error(result.reason);
      }
  
  
      // Create manual allocations
      if ($form.fields.is_create_manual_allocation.control.value) {
  
          let tasks = (await $datasources.SalesOrders.ds_get_tasks_by_licenseplateId.get({ licenseplateId: inputs.licensePlateId })).result;
  
          for (let task of tasks) {
              let manualAllocationResult = (await $flows.SalesOrders.create_manual_allocation_flow({
                  materialId: task.MaterialId,
                  packagingId: task.ActualPackagedPackId,
                  vendorLotId: null,
                  lotId: null,
                  licensePlateId: null,
                  sourceLocationId: null,
                  packagedAmount: task.ActualPackagedAmount,
                  shipmentLineId: task.ShipmentLineId
              }));
  
              if ($utils.isDefined(manualAllocationResult?.reasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: manualAllocationResult.reasons })).formattedValue);
              }
          }
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_unpicking_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_unpicking_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.toolbar.confirm.control.readOnly = true;
  
  // Hide specific fields on initial load
  $form.fields.licenseplate.hidden = true;
  $form.fields.new_licenseplate.hidden = true;
  $form.fields.new_licenseplate_checkbox.hidden = true;
  
  if ($utils.isDefined($form.inParams.suppress_manual_allocation) && $form.inParams.suppress_manual_allocation){
      $form.fields.is_create_manual_allocation.hidden = true;
  }
  
  
  if ($utils.isDefined($form.inParams.inventory?.lotId)) {
      $form.fields.unpick_license_plate.control.value = false;
  }
  else {
      $form.fields.unpick_license_plate.hidden = true;
      $form.fields.unpick_license_plate.control.value = true;
  }
  
  
  if ($utils.isAllDefined($form.inParams.inventory?.lotId, $form.inParams.inventory?.packagingId)) {
      const materialId = (await $datasources.Lots.ds_get_lot_by_lotId.get({
          lotId: $form.inParams.inventory?.lotId
      })).result[0].MaterialId;
  
      // Default the decimal formatting
      const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
          materialId: materialId,
          packagingId: $form.inParams.inventory.packagingId
      }));
  
      if ($utils.isDefined(decimalFormat)) {
          $form.fields.unpick_packaged_amount.control.format = decimalFormat.format;
  
      }
  }
  
  $form.set_state();
  }
  on_location_change(event = null) {
    return this.on_location_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changeInternal(
    $form: FootprintManager_unpicking_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_unpicking_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Location selection
  // Hide License Plate field
  const locationId = $form.fields.location.control.value
  const isLocationSelected = $utils.isDefined(locationId)
  if (locationId == null) {
      $form.fields.licenseplate.control.value = null;
      $form.fields.new_licenseplate.control.value = null;
      $form.fields.licenseplate.hidden = true;
      $form.fields.new_licenseplate.hidden = true;
      $form.fields.new_licenseplate_checkbox.hidden = true;
  
  }
  
  // Check if location selected is loose hide license plate
  if (isLocationSelected) {
      const location = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      if ($utils.isDefined(location)) {
          const IsLoose = location[0].IsLoose;
          if (IsLoose) {
              $form.fields.licenseplate.control.value = null;
              $form.fields.licenseplate.hidden = true;
  
              $form.fields.new_licenseplate.control.value = null;
              $form.fields.new_licenseplate.hidden = true;
              $form.fields.new_licenseplate_checkbox.hidden = true;
          }
          else {
              $form.fields.new_licenseplate_checkbox.hidden = false;
              $form.fields.licenseplate.hidden = false;
          }
      }
  }
  
  
  }
  on_new_licenseplate_clicked(event = null) {
    return this.on_new_licenseplate_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_licenseplate_clickedInternal(
    $form: FootprintManager_unpicking_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_unpicking_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const newLicensePlate = $form.fields.new_licenseplate_checkbox.control.value;
  
  if (newLicensePlate) {
      $form.fields.licenseplate.control.value = null;
      $form.fields.new_licenseplate.control.value = null;
      $form.fields.licenseplate.hidden = true;
      $form.fields.new_licenseplate.hidden = false;
      $form.fields.new_licenseplate.control.focus();
  
  }
  else {
  
      $form.fields.licenseplate.control.value = null;
      $form.fields.new_licenseplate.control.value = null;
      $form.fields.licenseplate.hidden = false;
      $form.fields.new_licenseplate.hidden = true;
  }
  
  
  }
  set_state(event = null) {
    return this.set_stateInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_stateInternal(
    $form: FootprintManager_unpicking_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_unpicking_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  enum UnpickOptions {
      LicensePlate = "LICENSEPLATE",
      Inventory = "INVENTORY"
  }
  
  try {
      let title = '';
  
  
      if ($form.fields.unpick_license_plate.control.value) { // License plate unpick
          title = await getLicensePlateUnpickTitle();
  
          $form.fields.location.control.focus();
  
          setFieldVisibility(UnpickOptions.LicensePlate);
      } else { // Inventory unpick
          validateInventoryUnpick();
          title = await getInventoryUnpickTitle();
  
          // Set focus on updated packaged amount field
          if (!$form.fields.unpick_packaged_amount.control.readOnly) {
              $form.fields.unpick_packaged_amount.control.focus();
          }
  
          setFieldVisibility(UnpickOptions.Inventory);
      }
  
      // Set title field
      $form.title = title;
      $form.toolbar.confirm.control.readOnly = !getAllFieldsValid();
  
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootprintManager.openErrorDialog('Error opening unpick form', error.message);
      $form.close();
  }
  
  
  /************************************************
   * FUNCTIONS
  *************************************************/
  function validateInventoryUnpick() {
      if ($utils.isDefined($form.inParams.inventory?.unpickPackagedAmount) && $utils.isDefined($form.inParams.inventory?.serialNumberIds) && $form.inParams.inventory?.unpickPackagedAmount !== $form.inParams.inventory?.serialNumberIds.length) {
          throw new Error(`Unpick packaged amount of '${$form.inParams.inventory?.unpickPackagedAmount}' was specified, but '${$form.inParams.inventory?.serialNumberIds.length}' serial numbers were provided.`);
      }
  
      if ($utils.isDefined($form.inParams.inventory?.unpickPackagedAmount)) {
          $form.fields.unpick_packaged_amount.control.value = $form.inParams.inventory.unpickPackagedAmount;
          $form.fields.unpick_packaged_amount.control.readOnly = true;
      }
  
      if ($utils.isDefined($form.inParams.locationId)) {
          $form.fields.location.control.value = $form.inParams.locationId;
          $form.on_location_change();
      }
  }
  
  async function getInventoryUnpickTitle() {
      let title: string = '';
  
      const licenseplate = (await $datasources.Inventory.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $form.inParams.licenseplateId })).result;
      
      if (!$utils.isDefined(licenseplate)) { return null; }
      $form.vars.warehouseId = licenseplate.Warehouse.Id;
  
      let content = (await $datasources.Inventory.ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId_detailed.get({ licenseplateId: licenseplate.Id, lotId: $form.inParams.inventory?.lotId, packagingId: $form.inParams.inventory?.packagingId })).result[0];
  
      title = `Unpick from Location ${licenseplate.Location.Name}, Licenseplate ${licenseplate.LookupCode} Material ${content.Lot.Material.LookupCode} Lot ${content.Lot.LookupCode} Packaging ${content.Packaged.ShortName}`;
  
      return title;
  }
  
  async function getLicensePlateUnpickTitle() {
      let title = '';
  
      let licensePlate = (await $datasources.Locations.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $form.inParams.licenseplateId })).result;
      $form.vars.warehouseId = licensePlate.Warehouse.Id;
  
      return `Unpick license plate ${licensePlate.LookupCode}`;
  }
  
  function setFieldVisibility(option: UnpickOptions) {
      if (option === UnpickOptions.LicensePlate) {
          //$form.fields.unpick_license_plate.control.label = 'Unpick license plate';
          $form.fieldsets.licenseplate.hidden = true;
          $form.fields.licenseplate.hidden = true;
          $form.fields.new_licenseplate.hidden = true;
          $form.fields.new_licenseplate_checkbox.hidden = true;
          $form.fields.packaged_amount.hidden = true;
          $form.fields.unpick_packaged_amount.hidden = true;
      } else {
          //$form.fields.unpick_license_plate.control.label = 'Unpick inventory';
          $form.fieldsets.licenseplate.hidden = false;
          $form.fields.licenseplate.hidden = $form.fields.new_licenseplate_checkbox.control.value;
          $form.fields.new_licenseplate.hidden = !$form.fields.new_licenseplate_checkbox.control.value;
          $form.fields.new_licenseplate_checkbox.hidden = false;
          $form.fields.packaged_amount.hidden = false;
          $form.fields.unpick_packaged_amount.hidden = false;
      }
  }
  
  function getAllFieldsValid() {
      for (let key in $form.fields) {
          if (!$form.fields[key].hidden && !$form.fields[key].control.valid) {
              return false;
          }
      }
  
      return true;
  }
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form(fieldErrors: { unpick_license_plate: Pick<string[], 'push'>,is_create_manual_allocation: Pick<string[], 'push'>,unpick_packaged_amount: Pick<string[], 'push'>,location: Pick<string[], 'push'>,new_licenseplate_checkbox: Pick<string[], 'push'>,licenseplate: Pick<string[], 'push'>,new_licenseplate: Pick<string[], 'push'>,notes: Pick<string[], 'push'>, } = null) {
    const emptyResult = { unpick_license_plate:[],is_create_manual_allocation:[],unpick_packaged_amount:[],location:[],new_licenseplate_checkbox:[],licenseplate:[],new_licenseplate:[],notes:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async validate_formInternal(
    $form: FootprintManager_unpicking_formComponent,
    $validation:{
      fieldErrors: { unpick_license_plate: Pick<string[], 'push'>,is_create_manual_allocation: Pick<string[], 'push'>,unpick_packaged_amount: Pick<string[], 'push'>,location: Pick<string[], 'push'>,new_licenseplate_checkbox: Pick<string[], 'push'>,licenseplate: Pick<string[], 'push'>,new_licenseplate: Pick<string[], 'push'>,notes: Pick<string[], 'push'>, }
    },
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $context: FootprintManager_unpicking_form_ComponentContextService
  ) {
    $form.toolbar.confirm.control.readOnly = !getAllRequiredFieldsEntered();
  
  function getAllRequiredFieldsEntered() {
      for (let key in $form.fields) {
          // Skip if fieldset is hidden
          if (!$form.fields[key].hidden && $form.fields[key].required && !$utils.isDefined($form.fields[key].control.value)) {
              if (['LICENSEPLATE', 'NEW_LICENSEPLATE'].includes(key.trim().toUpperCase()) && $form.fieldsets.licenseplate.hidden) {
                  continue;
              }
  
              return false;
          }
      }
  
      return true;
  }
    return $validation.fieldErrors as { unpick_license_plate:[],is_create_manual_allocation:[],unpick_packaged_amount:[],location:[],new_licenseplate_checkbox:[],licenseplate:[],new_licenseplate:[],notes:[], };
  }
  //#endregion validation flows
}
