<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="process" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="delete_transfer" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden && !fieldsets.header.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.header.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden && !fields.lookupcode.removed" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}} {{fields.lookupcode.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.lookupcode.control.readOnly || fields.lookupcode.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}} {{fields.status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="status"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.status.control.readOnly || fields.status.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.status.control.placeholder}}"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"
                                [matTooltip]="fields.status.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.header.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-source"
                     *ngIf="!fieldsets.source.hidden && !fieldsets.source.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.source.collapsible }">
                    <div *ngIf="!fieldsets.source.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.source.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.source.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.source.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.source.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.source.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.source.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-source_owner" *ngIf="!fields.source_owner.hidden && !fields.source_owner.removed" 
                            class="field-container standard {{fields.source_owner.invalid ? 'invalid' : ''}} {{fields.source_owner.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.source_owner.styles.style"
                            [ngClass]="fields.source_owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.source_owner.label + (fields.source_owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.source_owner.label}}<span *ngIf="fields.source_owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.source_owner.control.type"
                            formControlName="source_owner"
                            (displayTextChange)="fields.source_owner.control.displayText=$event"
                            [placeholder]="fields.source_owner.control.placeholder"
                            [styles]="fields.source_owner.control.styles"
                            [tooltip]="fields.source_owner.control.tooltip"
                          [statusId]="$fields_source_owner_selector_inParams_statusId"
                          [projectId]="$fields_source_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.source_owner.invalid">
                          <ng-container *ngFor="let error of fields.source_owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_project" *ngIf="!fields.source_project.hidden && !fields.source_project.removed" 
                            class="field-container standard {{fields.source_project.invalid ? 'invalid' : ''}} {{fields.source_project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.source_project.styles.style"
                            [ngClass]="fields.source_project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.source_project.label + (fields.source_project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.source_project.label}}<span *ngIf="fields.source_project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.source_project.control.type"
                            formControlName="source_project"
                            (displayTextChange)="fields.source_project.control.displayText=$event"
                            [placeholder]="fields.source_project.control.placeholder"
                            [styles]="fields.source_project.control.styles"
                            [tooltip]="fields.source_project.control.tooltip"
                          [statusId]="$fields_source_project_selector_inParams_statusId"
                          [ownerId]="$fields_source_project_selector_inParams_ownerId"
                          [excludedProjectIds]="$fields_source_project_selector_inParams_excludedProjectIds"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.source_project.invalid">
                          <ng-container *ngFor="let error of fields.source_project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.source.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-target"
                     *ngIf="!fieldsets.target.hidden && !fieldsets.target.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.target.collapsible }">
                    <div *ngIf="!fieldsets.target.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.target.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.target.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.target.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.target.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.target.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.target.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-target_owner" *ngIf="!fields.target_owner.hidden && !fields.target_owner.removed" 
                            class="field-container standard {{fields.target_owner.invalid ? 'invalid' : ''}} {{fields.target_owner.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.target_owner.styles.style"
                            [ngClass]="fields.target_owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.target_owner.label + (fields.target_owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.target_owner.label}}<span *ngIf="fields.target_owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.target_owner.control.type"
                            formControlName="target_owner"
                            (displayTextChange)="fields.target_owner.control.displayText=$event"
                            [placeholder]="fields.target_owner.control.placeholder"
                            [styles]="fields.target_owner.control.styles"
                            [tooltip]="fields.target_owner.control.tooltip"
                          [statusId]="$fields_target_owner_selector_inParams_statusId"
                          [projectId]="$fields_target_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.target_owner.invalid">
                          <ng-container *ngFor="let error of fields.target_owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-target_project" *ngIf="!fields.target_project.hidden && !fields.target_project.removed" 
                            class="field-container standard {{fields.target_project.invalid ? 'invalid' : ''}} {{fields.target_project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.target_project.styles.style"
                            [ngClass]="fields.target_project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.target_project.label + (fields.target_project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.target_project.label}}<span *ngIf="fields.target_project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.target_project.control.type"
                            formControlName="target_project"
                            (displayTextChange)="fields.target_project.control.displayText=$event"
                            [placeholder]="fields.target_project.control.placeholder"
                            [styles]="fields.target_project.control.styles"
                            [tooltip]="fields.target_project.control.tooltip"
                          [statusId]="$fields_target_project_selector_inParams_statusId"
                          [ownerId]="$fields_target_project_selector_inParams_ownerId"
                          [excludedProjectIds]="$fields_target_project_selector_inParams_excludedProjectIds"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.target_project.invalid">
                          <ng-container *ngFor="let error of fields.target_project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.target.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.lines.hasTabToShow" class="tab" data-cy="tab-lines">
              <h2 [className]="tabs.lines.active? 'active': ''" (click)="tabs.lines.activate()">{{tabs.lines.title}}</h2>
            </div>
            <div *ngIf="tabs.transfer_intermediaries.hasTabToShow" class="tab" data-cy="tab-transfer_intermediaries">
              <h2 [className]="tabs.transfer_intermediaries.active? 'active': ''" (click)="tabs.transfer_intermediaries.activate()">{{tabs.transfer_intermediaries.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-inventory_transfer_lines_grid *ngIf="tabs.lines.active"
              #$tabs_lines
              [inventoryTransferId]="$tabs_lines_inventory_transfer_lines_grid_inParams_inventoryTransferId"
              [sourceProjectId]="$tabs_lines_inventory_transfer_lines_grid_inParams_sourceProjectId"
              [targetProjectId]="$tabs_lines_inventory_transfer_lines_grid_inParams_targetProjectId"
              [warehouseId]="$tabs_lines_inventory_transfer_lines_grid_inParams_warehouseId"
              ($refreshEvent)="refresh(false, false, '$tabs_lines')">
              </FootprintManager-inventory_transfer_lines_grid>
              <FootprintManager-inventory_transfer_intermediaries_grid *ngIf="tabs.transfer_intermediaries.active"
              #$tabs_transfer_intermediaries
              [inventoryTransferId]="$tabs_transfer_intermediaries_inventory_transfer_intermediaries_grid_inParams_inventoryTransferId"
              [code]="$tabs_transfer_intermediaries_inventory_transfer_intermediaries_grid_inParams_code"
              [warehouseId]="$tabs_transfer_intermediaries_inventory_transfer_intermediaries_grid_inParams_warehouseId"
              [sourceProjectId]="$tabs_transfer_intermediaries_inventory_transfer_intermediaries_grid_inParams_sourceProjectId"
              [targetProjectId]="$tabs_transfer_intermediaries_inventory_transfer_intermediaries_grid_inParams_targetProjectId"
              ($refreshEvent)="refresh(false, false, '$tabs_transfer_intermediaries')">
              </FootprintManager-inventory_transfer_intermediaries_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>