import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformations_grid_ComponentContextService } from './FootprintManager.inventory_transformations_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { InventoryTransformations_inventory_transformation_date_tyes_dd_singleComponent } from './InventoryTransformations.inventory_transformation_date_tyes_dd_single.component'


interface IFootprintManager_inventory_transformations_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, StatusId?: number, InventoryTransformationStatus?: { Name?: string }, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, Warehouse?: { Id?: number, Name?: string }}

interface IFootprintManager_inventory_transformations_gridComponentInParams {
  owner_id?: number, project_id?: number, warehouse_ids?: number[], status_ids?: number[]}


class FootprintManager_inventory_transformations_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_inventory_transformations_gridComponent;
  entity: IFootprintManager_inventory_transformations_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: null,
    status: null,
    owner: null,
    project: null,
    warehouse: null,
    created_sys_date_time: null,
    created_sys_user: null,
    total_source_inventory: null,
    total_target_inventory: null,
  }



  constructor(
    grid: FootprintManager_inventory_transformations_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.lookupcode = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.owner = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.owner,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.project = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.project,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouse = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouse,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.created_sys_date_time = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.created_sys_date_time,
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      );
    
    this.cells.created_sys_user = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.created_sys_user,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.total_source_inventory = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.total_source_inventory,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.total_target_inventory = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.total_target_inventory,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_inventory_transformations_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_inventory_transformations_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      owner_id:  $grid.inParams.owner_id ,
      project_id:  $grid.inParams.project_id ,
      warehouse_ids:  $grid.inParams.warehouse_ids ,
      status_ids:  $grid.inParams.status_ids ,
      from_date:  $grid.filters.from_date.control.value ,
      to_date:  $grid.filters.to_date.control.value ,
      date_type:  $grid.filters.date_type.control.value ,
    };
    const data = await this.$datasources.InventoryTransformations.ds_inventory_transformations_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_inventory_transformations_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row?.entity?.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row?.entity?.InventoryTransformationStatus?.Name;
    (this.cells.owner.displayControl as TextModel).text = $row?.entity?.Project?.Owner?.LookupCode;
    (this.cells.project.displayControl as TextModel).text = $row?.entity?.Project?.LookupCode;
    (this.cells.warehouse.displayControl as TextModel).text = $row?.entity?.Warehouse?.Name;
    (this.cells.created_sys_date_time.displayControl as TextModel).text = $row?.entity?.CreatedSysDateTime;
    (this.cells.created_sys_user.displayControl as TextModel).text = $row?.entity?.CreatedSysUser;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_order_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_order_clicked(event = null) {
    return this.on_order_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_clickedInternal(
    $row: FootprintManager_inventory_transformations_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformations_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_order_clicked');
  await $shell.FootprintManager.openinventory_transformation_editor({ inventory_transformation_id: $row.entity.Id });
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_inventory_transformations_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformations_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_row_data_loaded');
  let source_line_total = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_total_by_inventory_transformation_id.get({
      inventory_transformation_id: $row.entity?.Id }))?.result
  
  if($utils.isDefined(source_line_total))
  {
  $row.cells.total_source_inventory.displayControl.text = source_line_total?.total_packaged_amount.toString() ?? '0'
  }
  
  
  let target_line_total = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_total_by_inventory_transformation_id.get({
      inventory_transformation_id: $row.entity?.Id }))?.result
  
  if($utils.isDefined(target_line_total))
  {
  $row.cells.total_target_inventory.displayControl.text = target_line_total?.total_packaged_amount.toString() ?? '0'
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => InventoryTransformations_inventory_transformation_date_tyes_dd_singleComponent),
  ],
  selector: 'FootprintManager-inventory_transformations_grid',
  templateUrl: './FootprintManager.inventory_transformations_grid.component.html'
})
export class FootprintManager_inventory_transformations_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_inventory_transformations_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_inventory_transformations_gridComponentInParams = { owner_id: null, project_id: null, warehouse_ids: [], status_ids: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Transformation order', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'owner', 'Owner', false, false, null, false),       project: new GridHeaderModel(new HeaderStyles(null, null), 'project', 'Project', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       created_sys_date_time: new GridHeaderModel(new HeaderStyles(null, null), 'created_sys_date_time', 'Created on', false, false, null, false),       created_sys_user: new GridHeaderModel(new HeaderStyles(null, null), 'created_sys_user', 'Created by', false, false, null, false),       total_source_inventory: new GridHeaderModel(new HeaderStyles(null, null), 'total_source_inventory', 'Total source inventory', false, false, null, false),       total_target_inventory: new GridHeaderModel(new HeaderStyles(null, null), 'total_target_inventory', 'Total target inventory', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_inventory_transformations_gridComponentRowModel[] = [];

  @Input('owner_id') set $inParams_owner_id(value: any) {
    this.inParams['owner_id'] = value;
  }
  get $inParams_owner_id(): any {
    return this.inParams['owner_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('warehouse_ids') set $inParams_warehouse_ids(value: any) {
    this.inParams['warehouse_ids'] = value;
  }
  get $inParams_warehouse_ids(): any {
    return this.inParams['warehouse_ids'] ;
  }
  @Input('status_ids') set $inParams_status_ids(value: any) {
    this.inParams['status_ids'] = value;
  }
  get $inParams_status_ids(): any {
    return this.inParams['status_ids'] ;
  }

  topToolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(null, null), false, false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      execute: new ToolModel(new ButtonModel('execute', new ButtonStyles(null, null), false, false, false, 'Complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, false, false, 'Revert', 'icon-ic_fluent_arrow_undo_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['destructive'], null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      separator4: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      remove: new ToolModel(new ButtonModel('remove', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'From date', false, false),
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, false, '', 'datetime', null)
, new ControlContainerStyles(null, null), 'To date', false, false),
    date_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['date_type'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Date type', false, false),
  }

  //#region filters inParams
  //#endregion filters inParams


  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    ) {
    super();
    this.title = 'inventory_transformations_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;

    (this.filters.date_type.control as SelectBoxModel).reset('CreatedSysDateTime');

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      owner_id:  $grid.inParams.owner_id ,
      project_id:  $grid.inParams.project_id ,
      warehouse_ids:  $grid.inParams.warehouse_ids ,
      status_ids:  $grid.inParams.status_ids ,
      from_date:  $grid.filters.from_date.control.value ,
      to_date:  $grid.filters.to_date.control.value ,
      date_type:  $grid.filters.date_type.control.value ,
    };
    try {
      const data = await this.$datasources.InventoryTransformations.ds_inventory_transformations_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_inventory_transformations_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_inventory_transformations_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_inventory_transformations_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  under_development(event = null) {
    return this.under_developmentInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async under_developmentInternal(
    $grid: FootprintManager_inventory_transformations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.under_development');
  $shell.InventoryTransformations.openInfoDialog('Feature is in development.','')
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootprintManager_inventory_transformations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_delete_clicked');
  
  try {
      let valid_inventory_transformation: number[] = []
      let invalid_inventory_transformation: number[] = []
  
      for (let selected_row of $grid.selectedRows) {
  
          //if transformation is completed, invalid to delete
          if (selected_row?.entity?.StatusId === 3) {
              invalid_inventory_transformation.push(selected_row?.entity?.Id)
          }
          else {
              let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
                  inventory_transformation_id: selected_row?.entity?.Id,
              }))?.result
  
              if (!$utils.isDefined(source_line_check)) {
                  valid_inventory_transformation.push(selected_row?.entity?.Id)
              }
              else {
  
                  for (let source_line of source_line_check) {
  
                      if (source_line?.StatusId === 3) {
                          invalid_inventory_transformation.push(source_line?.InventoryTransformationId)
                      }
                  }
  
                  //target line check
                  let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
                      inventory_transformation_id: selected_row?.entity?.Id,
                  }))?.result
  
                  if (!$utils.isDefined(target_line_check)) {
                      valid_inventory_transformation.push(selected_row?.entity?.Id)
                  }
                  else {
  
                      //if target line is not completed, valid
                      const valid_target = target_line_check.filter(tlc => tlc.StatusId != 3);
                      if ($utils.isDefined(valid_target)) {
                          valid_inventory_transformation.push(...valid_target.map(v => v?.InventoryTransformationId));
                      }
  
                      //if target line is completed, invalid
                      const invalid_target = target_line_check.filter(tlc => tlc.StatusId === 3);
                      if ($utils.isDefined(invalid_target)) {
                          invalid_inventory_transformation.push(...invalid_target.map(v => v?.InventoryTransformationId));
                      }
  
                  }
              }
          }
      }
  
  
      //remove duplicates if any
      valid_inventory_transformation = Array.from(new Set(valid_inventory_transformation));
      invalid_inventory_transformation = Array.from(new Set(invalid_inventory_transformation));
  
  
      let delete_lines: boolean
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length > 0) {
          delete_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Delete inventory transformation.', `Cannot delete ${JSON.stringify(invalid_inventory_transformation)}. Proceed with deleting ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length === 0 && valid_inventory_transformation?.length > 0) {
          delete_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Delete inventory transformation.', `Confirm to delete the inventory transformation ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length === 0) {
          await $shell.InventoryTransformations.openInfoDialog('Delete inventory transformation.', `Cannot delete ${JSON.stringify(invalid_inventory_transformation)}.`)
          return;
      }
  
  
  
      if (!delete_lines) { return }
  
      $grid.topToolbar.remove.control.label = 'Deleting'
      $grid.topToolbar.remove.control.icon = "icon datex-default-spinner"
      $grid.topToolbar.process.control.readOnly = true
      $grid.topToolbar.cancel.control.readOnly = true
      $grid.topToolbar.remove.control.readOnly = true
      $grid.topToolbar.revert.control.readOnly = true
      $grid.topToolbar.execute.control.readOnly = true
  
      for (let inventory_transformation of valid_inventory_transformation) {
  
          //target line check
          let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
              inventory_transformation_id: inventory_transformation,
          }))?.result
  
          if ($utils.isDefined(target_line_check)) {
              for (let row of target_line_check) {
  
                  let delete_row = (await $flows.Utilities.crud_delete_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: row?.Id
                  }))
                  if ($utils.isDefined(delete_row?.reason)) {
                      throw Error(delete_row?.reason)
                  }
              }
          }
          let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
              inventory_transformation_id: inventory_transformation
          }))?.result
  
          for (let source_line of source_line_check) {
              let delete_source_line = (await $flows.InventoryTransformations.delete_inventory_transformation_source_line_flow({ source_line_id: source_line?.Id }))
              if ($utils.isDefined(delete_source_line?.reason)) {
                  throw Error(delete_source_line?.reason)
              }
          }
  
          let delete_row = (await $flows.Utilities.crud_delete_flow({
              entitySet: 'InventoryTransformations',
              id: inventory_transformation
          }))
  
          if ($utils.isDefined(delete_row?.reason)) {
              throw Error(delete_row?.reason)
          }
  
      }
  
      $grid.topToolbar.remove.control.styles.resetStyle();
      $grid.topToolbar.remove.control.label = 'Delete'
      $grid.topToolbar.remove.control.icon = "icon-ic_fluent_delete_20_regular";
      $grid.topToolbar.remove.control.styles.setDestructiveClass()
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
      await $grid.refresh();
  
      $shell.InventoryTransformations.openToaster(
          `Successfully deleted inventory transformations.`,
          `Deleted inventory transformations ${JSON.stringify(valid_inventory_transformation)}`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on delete.', error);
  
      $grid.topToolbar.remove.control.styles.resetStyle();
      $grid.topToolbar.remove.control.label = 'Deleting'
      $grid.topToolbar.remove.control.icon = "icon-ic_fluent_delete_20_regular";
      $grid.topToolbar.remove.control.styles.setDestructiveClass()
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
  }
  
  
  
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $grid: FootprintManager_inventory_transformations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_process_clicked');
  
  try {
      let valid_inventory_transformation: number[] = []
      let invalid_inventory_transformation: number[] = []
  
      for (let selected_row of $grid.selectedRows) {
  
          if (selected_row?.entity?.StatusId === 1) {
              valid_inventory_transformation.push(selected_row?.entity?.Id)
          }
          else {
              invalid_inventory_transformation.push(selected_row?.entity?.Id)
          }
  
      }
  
  
      let process_lines: boolean
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length > 0) {
  
          process_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Process inventory transformation.', `Cannot process ${JSON.stringify(invalid_inventory_transformation)}. Proceed to process ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length === 0 && valid_inventory_transformation?.length > 0) {
          process_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Process inventory transformation.', `Confirm to process the inventory transformation ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length === 0) {
          await $shell.InventoryTransformations.openInfoDialog('Process inventory transformation.', `Cannot process ${JSON.stringify(invalid_inventory_transformation)}.`)
          return;
      }
  
  
      if (!process_lines) { return }
  
      $grid.topToolbar.process.control.label = 'Processing'
      $grid.topToolbar.process.control.icon = "icon datex-default-spinner"
      $grid.topToolbar.process.control.readOnly = true
      $grid.topToolbar.cancel.control.readOnly = true
      $grid.topToolbar.remove.control.readOnly = true
      $grid.topToolbar.revert.control.readOnly = true
      $grid.topToolbar.execute.control.readOnly = true
  
      for (let inventory_transformation of valid_inventory_transformation) {
  
          let process_transformation = (await $flows.InventoryTransformations.process_inventory_transformation_flow({ inventory_transformation_id: inventory_transformation }))
  
          if (process_transformation?.reason) {
              throw Error(process_transformation?.reason)
          }
      }
  
      $grid.topToolbar.process.control.styles.resetStyle();
      $grid.topToolbar.process.control.label = 'Process'
      $grid.topToolbar.process.control.icon = "icon-ic_fluent_arrow_clockwise_dashes_20_regular";
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
      await $grid.refresh()
  
      $shell.InventoryTransformations.openToaster(
          'Successfully processed inventory transformation/s.',
          `Inventory transformation ${JSON.stringify(valid_inventory_transformation)} processed.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  }
  
  catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on process.', error);
      
      $grid.topToolbar.process.control.styles.resetStyle();
      $grid.topToolbar.process.control.label = 'Process'
      $grid.topToolbar.process.control.icon = "icon-ic_fluent_arrow_clockwise_dashes_20_regular";
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
      await $grid.refresh()
  }
  
  
  
  
  /*
  
  let selected_row = $grid?.selectedRows[0]?.entity
  
  let confirm_process = (await $shell.InventoryTransformations.openConfirmationDialog('Process inventory transformation.', `Confirm to process inventory transformation [${selected_row?.LookupCode}]?`, 'Confirm', 'Cancel'))
  
  
  if (!confirm_process) {
      return
  }
  
  
  
  try {
  
      let process_transformation = (await $flows.InventoryTransformations.process_inventory_transformation_flow({ inventory_transformation_id: selected_row?.Id }))
  
      if (process_transformation?.reason) {
          throw Error(process_transformation?.reason)
      }
  
      $shell.InventoryTransformations.openToaster(
          'Successfully processed inventory transformation.',
          `Inventory transformation ${$grid.rows[0]?.entity?.LookupCode} has been processed.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
      await $grid.refresh()
  
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on process.', error);
  }
  */
  }
  on_execute_clicked(event = null) {
    return this.on_execute_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_execute_clickedInternal(
    $grid: FootprintManager_inventory_transformations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_execute_clicked');
  
  
  
  try {
  
      let valid_inventory_transformation: number[] = []
      let invalid_inventory_transformation: number[] = []
  
      for (let selected_row of $grid.selectedRows) {
  
          if (selected_row?.entity?.StatusId === 2) {
              valid_inventory_transformation.push(selected_row?.entity?.Id)
          }
          else {
              invalid_inventory_transformation.push(selected_row?.entity?.Id)
          }
  
      }
  
      let execute_lines: boolean
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length > 0) {
          execute_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Complete inventory transformation.', `Cannot complete ${JSON.stringify(invalid_inventory_transformation)}. Proceed to complete ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length === 0 && valid_inventory_transformation?.length > 0) {
          execute_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Complete inventory transformation.', `Confirm to complete the inventory transformation ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
          if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length === 0) {
          await $shell.InventoryTransformations.openInfoDialog('Complete inventory transformation.', `Cannot complete ${JSON.stringify(invalid_inventory_transformation)}.`)
          return;
      }    
  
  
  
  
  
      if (!execute_lines) { return }
  
      $grid.topToolbar.execute.control.label = 'Competing'
      $grid.topToolbar.execute.control.icon = "icon datex-default-spinner"
      $grid.topToolbar.process.control.readOnly = true
      $grid.topToolbar.cancel.control.readOnly = true
      $grid.topToolbar.remove.control.readOnly = true
      $grid.topToolbar.revert.control.readOnly = true
      $grid.topToolbar.execute.control.readOnly = true
  
      for (let inventory_transformation of valid_inventory_transformation) {
  
          let execute_transformation = (await $flows.InventoryTransformations.execute_inventory_transformation_flow({ inventory_transformation_id: inventory_transformation }))
  
          if (execute_transformation?.reason) {
              throw Error(execute_transformation?.reason)
          }
      }
  
      $grid.topToolbar.execute.control.label = 'Complete'
      $grid.topToolbar.execute.control.icon = "icon-ic_fluent_checkmark_circle_20_regular";
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
      $shell.InventoryTransformations.openToaster(
          'Successfully executed inventory transformation/s.',
          `Inventory transformation/s ${JSON.stringify(valid_inventory_transformation)} is being completed.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
      await $grid.refresh()
  
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on execute.', error);
  
      $grid.topToolbar.execute.control.styles.resetStyle();
      $grid.topToolbar.execute.control.label = 'Complete'
      $grid.topToolbar.execute.control.icon = "icon-ic_fluent_checkmark_circle_20_regular";
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_inventory_transformations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_row_selected');
  
  
  
  
  /*
  if ($grid.selectedRows.length > 1 || $grid.selectedRows.length === 0) {
      $grid.topToolbar.revert.control.readOnly = true
  }
  
  
  else {
      
  
      let selected_row = $grid?.selectedRows[0]?.entity
  
      //check source lines for selected row
      let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get(
          {
              inventory_transformation_id: selected_row?.Id,
          }
      ))?.result
  
  
      if (!$utils.isDefined(source_line_check[0])) {
          $grid.topToolbar.execute.control.readOnly = false
          $grid.topToolbar.process.control.readOnly = false
          $grid.topToolbar.remove.control.readOnly = false
          $grid.topToolbar.revert.control.readOnly = true
      }
  
      if (source_line_check[0]?.StatusId === 1) {
          $grid.topToolbar.execute.control.readOnly = false
          $grid.topToolbar.process.control.readOnly = false
          $grid.topToolbar.remove.control.readOnly = false
          $grid.topToolbar.revert.control.readOnly = true
      }
      if (source_line_check[0]?.StatusId === 2) {
          $grid.topToolbar.execute.control.readOnly = false
          $grid.topToolbar.process.control.readOnly = false
          $grid.topToolbar.remove.control.readOnly = false
          $grid.topToolbar.revert.control.readOnly = false
      }
      if (source_line_check[0]?.StatusId === 3) {
          $grid.topToolbar.execute.control.readOnly = false
          $grid.topToolbar.process.control.readOnly = false
          $grid.topToolbar.remove.control.readOnly = false
          $grid.topToolbar.revert.control.readOnly = true
      }
      if (source_line_check[0]?.StatusId === 4) {
          $grid.topToolbar.execute.control.readOnly = false
          $grid.topToolbar.process.control.readOnly = false
          $grid.topToolbar.remove.control.readOnly = false
          $grid.topToolbar.revert.control.readOnly = false
      }
      if (source_line_check[0]?.StatusId === 5) {
          $grid.topToolbar.execute.control.readOnly = false
          $grid.topToolbar.process.control.readOnly = false
          $grid.topToolbar.remove.control.readOnly = false
          $grid.topToolbar.revert.control.readOnly = false
      }
  
  }*/
  
  
  if ($grid.selectedRows.length === 0) {
      $grid.topToolbar.remove.control.readOnly = true
      $grid.topToolbar.process.control.readOnly = true
      $grid.topToolbar.execute.control.readOnly = true
      $grid.topToolbar.cancel.control.readOnly = true
      $grid.topToolbar.revert.control.readOnly = true
  }
  else{
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
  
  }
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $grid: FootprintManager_inventory_transformations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_revert_clicked');
  
  try {
      let valid_inventory_transformation: number[] = []
      let invalid_inventory_transformation: number[] = []
  
      for (let selected_row of $grid.selectedRows) {
  
          //if transformation is completed, invalid to revert
          if (selected_row?.entity?.StatusId === 3) {
              invalid_inventory_transformation.push(selected_row?.entity?.Id)
          }
          else {
              let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
                  inventory_transformation_id: selected_row?.entity?.Id,
              }))?.result
  
              if (!$utils.isDefined(source_line_check)) {
                  valid_inventory_transformation.push(selected_row?.entity?.Id)
              }
              else {
  
                  for (let source_line of source_line_check) {
  
                      if (source_line?.StatusId === 3) {
                          invalid_inventory_transformation.push(source_line?.InventoryTransformationId)
                      }
                  }
  
                  //target line check
                  let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
                      inventory_transformation_id: selected_row?.entity?.Id,
                  }))?.result
  
                  if (!$utils.isDefined(target_line_check)) {
                      valid_inventory_transformation.push(selected_row?.entity?.Id)
                  }
                  else {
  
                      //if target line is not completed, valid
                      const valid_target = target_line_check.filter(tlc => tlc.StatusId != 3);
                      if ($utils.isDefined(valid_target)) {
                          valid_inventory_transformation.push(...valid_target.map(v => v?.InventoryTransformationId));
                      }
  
                      //if target line is completed, invalid
                      const invalid_target = target_line_check.filter(tlc => tlc.StatusId === 3);
                      if ($utils.isDefined(invalid_target)) {
                          invalid_inventory_transformation.push(...invalid_target.map(v => v?.InventoryTransformationId));
                      }
  
                  }
              }
          }
      }
  
  
      //remove duplicates if any
      valid_inventory_transformation = Array.from(new Set(valid_inventory_transformation));
      invalid_inventory_transformation = Array.from(new Set(invalid_inventory_transformation));
  
  
      let revert_lines: boolean
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length > 0) {
          revert_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Revert inventory transformation.', `Cannot revert ${JSON.stringify(invalid_inventory_transformation)}. Proceed with reverting ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length === 0 && valid_inventory_transformation?.length > 0) {
          revert_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Revert inventory transformation.', `Confirm to revert the inventory transformation ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length === 0) {
          await $shell.InventoryTransformations.openInfoDialog('Revert inventory transformation.', `Cannot revert ${JSON.stringify(invalid_inventory_transformation)}.`)
          return;
      }
  
  
  
      if (!revert_lines) { return }
  
      $grid.topToolbar.revert.control.label = 'Reverting'
      $grid.topToolbar.revert.control.icon = "icon datex-default-spinner"
      $grid.topToolbar.process.control.readOnly = true
      $grid.topToolbar.cancel.control.readOnly = true
      $grid.topToolbar.remove.control.readOnly = true
      $grid.topToolbar.revert.control.readOnly = true
      $grid.topToolbar.execute.control.readOnly = true
  
      for (let inventory_transformation of valid_inventory_transformation) {
  
          //target line check
          let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
              inventory_transformation_id: inventory_transformation,
          }))?.result
  
          if ($utils.isDefined(target_line_check)) {
              for (let target_line of target_line_check) {
  
                  let inventory_target_line_payload: any = {}
                  inventory_target_line_payload.StatusId = 1
  
                  //update status to 1 for target lines
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line?.Id,
                      entity: inventory_target_line_payload
                  }))
  
                  if (update_row?.reason) {
                      throw Error(update_row?.reason)
                  }
  
              }
          }
  
  
          let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
              inventory_transformation_id: inventory_transformation
          }))?.result
  
          for (let source_line of source_line_check) {
  
              //check for any hard allocations
              let hard_allocation_check = (await $datasources.InventoryTransformations.ds_get_hardallocationdetails_by_inventorytransformationsourcelineid.get({
                  inventory_transformation_source_line_id: source_line?.Id,
              }))?.result
  
              //if source line status is completed, throw error.
              if (source_line_check[0]?.StatusId === 3) {
                  throw Error(`Source line is in [${source_line_check[0]?.InventoryTransformationLineStatus?.Name}] status.`)
              }
              //delete hard allocation if it exists
              if ($utils.isDefined(hard_allocation_check)) {
  
                  for (let hard_allocation_detail of hard_allocation_check[0]?.Details) {
                      let delete_hard_allocation_details = (await $flows.Utilities.crud_delete_flow({
                          entitySet: 'HardAllocationDetails',
                          id: hard_allocation_detail?.Id
                      }))
  
                      if (delete_hard_allocation_details?.reason) {
                          throw Error(delete_hard_allocation_details?.reason)
                      }
                  }
  
                  let delete_hard_allocation = (await $flows.Utilities.crud_delete_flow({
                      entitySet: 'HardAllocations',
                      id: hard_allocation_check[0]?.Id,
                  }))
  
                  if (delete_hard_allocation?.reason) {
                      throw Error(delete_hard_allocation?.reason)
                  }
  
  
              }
  
              let inventory_source_line_payload: any = {}
              inventory_source_line_payload.StatusId = 1
              inventory_source_line_payload.ErrorMessage = null
  
              //update status to 1 and notes to null after deleting any hard allocations if any
              let update_row = (await $flows.Utilities.crud_update_flow({
                  entitySet: 'InventoryTransformationSourceLines',
                  id: source_line?.Id,
                  entity: inventory_source_line_payload
              }))
  
              if (update_row?.reason) {
                  throw Error(update_row?.reason)
              }
  
          }
  
          let inventory_transformation_payload: any = {}
          inventory_transformation_payload.StatusId = 1
          inventory_transformation_payload.Notes = `Reverted on ${(new Date()).toISOString}`
  
          //update status to 1 and notes to null after updating source lines
          let update_row = (await $flows.Utilities.crud_update_flow({
              entitySet: 'InventoryTransformations',
              id: inventory_transformation,
              entity: inventory_transformation_payload
          }))
  
          if (update_row?.reason) {
              throw Error(update_row?.reason)
          }
  
      }
  
      $grid.topToolbar.revert.control.styles.resetStyle();
      $grid.topToolbar.revert.control.label = 'Revert'
      $grid.topToolbar.revert.control.icon = "icon-ic_fluent_arrow_undo_20_regular";
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
      await $grid.refresh();
  
      $shell.InventoryTransformations.openToaster(
          `Successfully reverted inventory transformations.`,
          `Reverted inventory transformations ${JSON.stringify(valid_inventory_transformation)}`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on revert.', error);
  
      $grid.topToolbar.revert.control.styles.resetStyle();
      $grid.topToolbar.revert.control.label = 'Revert'
      $grid.topToolbar.revert.control.icon = "icon-ic_fluent_arrow_undo_20_regular";
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
  }
  
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_inventory_transformations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_init');
      $grid.topToolbar.execute.control.readOnly = true
      $grid.topToolbar.process.control.readOnly = true
      $grid.topToolbar.remove.control.readOnly = true
      $grid.topToolbar.revert.control.readOnly = true
      $grid.topToolbar.cancel.control.readOnly = true
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootprintManager_inventory_transformations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_grid.on_cancel_clicked');
  
  try {
      let valid_inventory_transformation: number[] = []
      let invalid_inventory_transformation: number[] = []
  
      for (let selected_row of $grid.selectedRows) {
  
          //if transformation is completed, invalid to cancel
          if (selected_row?.entity?.StatusId === 3) {
              invalid_inventory_transformation.push(selected_row?.entity?.Id)
          }
          else {
              let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
                  inventory_transformation_id: selected_row?.entity?.Id,
              }))?.result
  
              if (!$utils.isDefined(source_line_check)) {
                  valid_inventory_transformation.push(selected_row?.entity?.Id)
              }
              else {
  
                  for (let source_line of source_line_check) {
  
                      if (source_line?.StatusId === 3) {
                          invalid_inventory_transformation.push(source_line?.InventoryTransformationId)
                      }
                  }
  
                  //target line check
                  let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
                      inventory_transformation_id: selected_row?.entity?.Id,
                  }))?.result
  
                  if (!$utils.isDefined(target_line_check)) {
                      valid_inventory_transformation.push(selected_row?.entity?.Id)
                  }
                  else {
  
                      //if target line is not completed, valid
                      const valid_target = target_line_check.filter(tlc => tlc.StatusId != 3);
                      if ($utils.isDefined(valid_target)) {
                          valid_inventory_transformation.push(...valid_target.map(v => v?.InventoryTransformationId));
                      }
  
                      //if target line is completed, invalid
                      const invalid_target = target_line_check.filter(tlc => tlc.StatusId === 3);
                      if ($utils.isDefined(invalid_target)) {
                          invalid_inventory_transformation.push(...invalid_target.map(v => v?.InventoryTransformationId));
                      }
  
                  }
              }
          }
      }
  
  
      //remove duplicates if any
      valid_inventory_transformation = Array.from(new Set(valid_inventory_transformation));
      invalid_inventory_transformation = Array.from(new Set(invalid_inventory_transformation));
  
  
      let cancel_lines: boolean
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length > 0) {
          cancel_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Cancel inventory transformation.', `Cannot cancel ${JSON.stringify(invalid_inventory_transformation)}. Proceed with cancelling ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length === 0 && valid_inventory_transformation?.length > 0) {
          cancel_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Cancel inventory transformation.', `Confirm to cancel the inventory transformation ${JSON.stringify(valid_inventory_transformation)}?`, 'Confirm', 'Cancel'))
      }
      if (invalid_inventory_transformation?.length > 0 && valid_inventory_transformation?.length === 0) {
          await $shell.InventoryTransformations.openInfoDialog('Cancel inventory transformation.', `Cannot cancel ${JSON.stringify(invalid_inventory_transformation)}.`)
          return;
      }
  
  
  
      if (!cancel_lines) { return }
  
      $grid.topToolbar.cancel.control.label = 'Cancelling'
      $grid.topToolbar.cancel.control.icon = "icon datex-default-spinner"
      $grid.topToolbar.process.control.readOnly = true
      $grid.topToolbar.cancel.control.readOnly = true
      $grid.topToolbar.remove.control.readOnly = true
      $grid.topToolbar.revert.control.readOnly = true
      $grid.topToolbar.execute.control.readOnly = true
  
  
      for (let inventory_transformation of valid_inventory_transformation) {
  
          //target line check
          let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
              inventory_transformation_id: inventory_transformation,
          }))?.result
  
          if ($utils.isDefined(target_line_check)) {
              for (let target_line of target_line_check) {
  
                  let inventory_target_line_payload: any = {}
                  inventory_target_line_payload.StatusId = 4
  
                  //update status to 4 for target lines
                  let update_row = (await $flows.Utilities.crud_update_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: target_line?.Id,
                      entity: inventory_target_line_payload
                  }))
  
                  if (update_row?.reason) {
                      throw Error(update_row?.reason)
                  }
  
              }
          }
  
  
          let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
              inventory_transformation_id: inventory_transformation
          }))?.result
  
          for (let source_line of source_line_check) {
  
              //check for any hard allocations
              let hard_allocation_check = (await $datasources.InventoryTransformations.ds_get_hardallocationdetails_by_inventorytransformationsourcelineid.get({
                  inventory_transformation_source_line_id: source_line?.Id,
              }))?.result
  
              //if source line status is completed, throw error.
              if (source_line_check[0]?.StatusId === 3) {
                  throw Error(`Source line is in [${source_line_check[0]?.InventoryTransformationLineStatus?.Name}] status.`)
              }
              //delete hard allocation if it exists
              if ($utils.isDefined(hard_allocation_check)) {
  
                  for (let hard_allocation_detail of hard_allocation_check[0]?.Details) {
                      let delete_hard_allocation_details = (await $flows.Utilities.crud_delete_flow({
                          entitySet: 'HardAllocationDetails',
                          id: hard_allocation_detail?.Id
                      }))
  
                      if (delete_hard_allocation_details?.reason) {
                          throw Error(delete_hard_allocation_details?.reason)
                      }
                  }
  
                  let delete_hard_allocation = (await $flows.Utilities.crud_delete_flow({
                      entitySet: 'HardAllocations',
                      id: hard_allocation_check[0]?.Id,
                  }))
  
                  if (delete_hard_allocation?.reason) {
                      throw Error(delete_hard_allocation?.reason)
                  }
  
  
              }
  
              let inventory_source_line_payload: any = {}
              inventory_source_line_payload.StatusId = 4
              inventory_source_line_payload.ErrorMessage = null
  
              //update status to 4 and notes to null after deleting any hard allocations if any
              let update_row = (await $flows.Utilities.crud_update_flow({
                  entitySet: 'InventoryTransformationSourceLines',
                  id: source_line?.Id,
                  entity: inventory_source_line_payload
              }))
  
              if (update_row?.reason) {
                  throw Error(update_row?.reason)
              }
  
          }
  
          let inventory_transformation_payload: any = {}
          inventory_transformation_payload.StatusId = 4
          inventory_transformation_payload.Notes = `Cancelled on ${(new Date()).toISOString}`
  
          //update status to 4 and notes to null after updating source lines
          let update_row = (await $flows.Utilities.crud_update_flow({
              entitySet: 'InventoryTransformations',
              id: inventory_transformation,
              entity: inventory_transformation_payload
          }))
  
          if (update_row?.reason) {
              throw Error(update_row?.reason)
          }
  
      }
  
      $grid.topToolbar.cancel.control.styles.resetStyle();
      $grid.topToolbar.cancel.control.label = 'Cancel'
      $grid.topToolbar.cancel.control.icon = "icon-ic_fluent_dismiss_circle_20_regular";
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
      await $grid.refresh();
  
      $shell.InventoryTransformations.openToaster(
          `Successfully cancelled inventory transformations.`,
          `Cancelled inventory transformations ${JSON.stringify(valid_inventory_transformation)}`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on cancel.', error);
  
      $grid.topToolbar.cancel.control.styles.resetStyle();
      $grid.topToolbar.cancel.control.label = 'Cancel'
      $grid.topToolbar.cancel.control.icon = "icon-ic_fluent_dismiss_circle_20_regular";
      $grid.topToolbar.process.control.readOnly = false
      $grid.topToolbar.cancel.control.readOnly = false
      $grid.topToolbar.remove.control.readOnly = false
      $grid.topToolbar.revert.control.readOnly = false
      $grid.topToolbar.execute.control.readOnly = false
  
  
  }
  
  
  
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
