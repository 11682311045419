<app-selector
  #selector
  [multiple]="false"
  [type]="type"
  [(ngModel)]="value"
  [disabled]="disabled"
  [placeholder]="placeholder"
  [styles]="styles"
  [displayWithFn]="displayWithFn.bind(this)"
  [optionsFn]="optionsFn.bind(this)"
  [tooltip]="tooltip"
  newItemLabel="Add another location as staging"
  (newItemClick)="set_another_location_as_staging()"
>
</app-selector>