import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { PurchaseOrders_ds_get_completed_or_released_putaway_tasks_by_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_completed_putaway_tasks_by_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_completed_tasks_by_orderId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_converted_weightService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_inbound_order_actualsService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_inbound_order_expectedService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_inbound_totals_by_linenumber_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_inventory_count_tasks_by_licenseplateId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_licenseplate_by_licenseplateIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_licenseplatecontents_by_licenseplateId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_material_packaging_by_materialId_and_packagingIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orderLines_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orderLines_with_totals_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_account_address_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_address_by_orderId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_lines_by_order_id_line_numberService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_owner_contact_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_owner_contact_top1_flow_basedService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_project_contact_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_ship_from_account_vs_order_addressService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_signature_attachmentService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_totals_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_validation_result_most_recentService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_warehouse_contact_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_order_warehouse_contact_top1_flow_basedService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orderaddresses_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orderclasses_by_lookupcodeService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orderlines_by_orderId_bottom1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orderlines_by_orderId_linenumberService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orders_aggregated_expectedService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orders_aggregated_tasksService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_orders_by_lookupcodeService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_project_by_projectIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_putaway_tasks_by_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_receiving_tasks_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_released_putaway_tasks_by_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_released_putaway_tasks_by_shipmentId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_released_receiving_tasks_by_orderId_shipmentId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipmentLines_by_orderId_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipmentLines_by_orderId_shipmentId_singleService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipmentLines_by_orderId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipmentLines_by_shipmentId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipment_by_orderId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipment_lines_by_orderId_orderLineNumberService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipmentline_by_shipmentlineIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipmentlines_by_orderId_bottom1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipmentorder_details_by_shipmentId_top1Service } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipments_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_shipments_by_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_task_by_taskIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_tasks_by_chainHeadService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_tasks_by_orderIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_tasks_by_orderId_orderLineNumberService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_tasks_by_orderId_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_tasks_by_shipmentIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_get_warehouse_by_warehouseIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_inbound_orders_details_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_inbound_orders_details_grid_countService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_inbound_orders_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_inbound_orders_grid_countService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_inbound_orders_grid_propertiesService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_inbound_orders_summary_reportService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_load_container_by_idService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_open_putaway_reportService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_order_total_received_gross_by_dateService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_order_total_received_gross_by_date_widgetService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_order_total_received_order_count_by_dateService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_order_total_received_order_count_by_date_widgetService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_order_total_received_units_by_dateService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_order_total_received_units_by_date_widgetService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_orderclasses_ddService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_orderline_total_amountService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_orderline_total_amount_widgetService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_orderline_total_gross_received_widgetService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_orderline_total_receivedService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_orderline_total_received_widgetService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_orders_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_orderstatuses_ddService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_owners_ddService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_projects_ddService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskIdService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskId_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_details_reportService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_details_report_flow_basedService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_editorService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_header_reportService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_lines_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_lines_grid_ediService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_order_receiving_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_orders_children_tasks_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_purchase_orders_tasks_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_reasoncodes_ddService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_received_inventory_gridService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_receiving_details_reportService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_receiving_details_report_flow_basedService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_receiving_header_reportService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_shipment_payment_term_ddService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_tags_ddService } from './PurchaseOrders.datasource.index';
import { PurchaseOrders_ds_warehouses_ddService } from './PurchaseOrders.datasource.index';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public PurchaseOrders: PurchaseOrders_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_completed_or_released_putaway_tasks_by_shipmentId: PurchaseOrders_ds_get_completed_or_released_putaway_tasks_by_shipmentIdService;
  public get ds_get_completed_or_released_putaway_tasks_by_shipmentId(): PurchaseOrders_ds_get_completed_or_released_putaway_tasks_by_shipmentIdService {
    if(!this._ds_get_completed_or_released_putaway_tasks_by_shipmentId) {
      this._ds_get_completed_or_released_putaway_tasks_by_shipmentId = this.injector.get(PurchaseOrders_ds_get_completed_or_released_putaway_tasks_by_shipmentIdService);
    }
    return this._ds_get_completed_or_released_putaway_tasks_by_shipmentId;
  }
  private _ds_get_completed_putaway_tasks_by_shipmentId: PurchaseOrders_ds_get_completed_putaway_tasks_by_shipmentIdService;
  public get ds_get_completed_putaway_tasks_by_shipmentId(): PurchaseOrders_ds_get_completed_putaway_tasks_by_shipmentIdService {
    if(!this._ds_get_completed_putaway_tasks_by_shipmentId) {
      this._ds_get_completed_putaway_tasks_by_shipmentId = this.injector.get(PurchaseOrders_ds_get_completed_putaway_tasks_by_shipmentIdService);
    }
    return this._ds_get_completed_putaway_tasks_by_shipmentId;
  }
  private _ds_get_completed_receiving_tasks_by_orderId_shipmentId: PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentIdService;
  public get ds_get_completed_receiving_tasks_by_orderId_shipmentId(): PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_completed_receiving_tasks_by_orderId_shipmentId) {
      this._ds_get_completed_receiving_tasks_by_orderId_shipmentId = this.injector.get(PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_completed_receiving_tasks_by_orderId_shipmentId;
  }
  private _ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1: PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1Service;
  public get ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1(): PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1) {
      this._ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1 = this.injector.get(PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_completed_receiving_tasks_by_orderId_shipmentId_top1;
  }
  private _ds_get_completed_receiving_tasks_by_orderId_top1: PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_top1Service;
  public get ds_get_completed_receiving_tasks_by_orderId_top1(): PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_top1Service {
    if(!this._ds_get_completed_receiving_tasks_by_orderId_top1) {
      this._ds_get_completed_receiving_tasks_by_orderId_top1 = this.injector.get(PurchaseOrders_ds_get_completed_receiving_tasks_by_orderId_top1Service);
    }
    return this._ds_get_completed_receiving_tasks_by_orderId_top1;
  }
  private _ds_get_completed_tasks_by_orderId_top1: PurchaseOrders_ds_get_completed_tasks_by_orderId_top1Service;
  public get ds_get_completed_tasks_by_orderId_top1(): PurchaseOrders_ds_get_completed_tasks_by_orderId_top1Service {
    if(!this._ds_get_completed_tasks_by_orderId_top1) {
      this._ds_get_completed_tasks_by_orderId_top1 = this.injector.get(PurchaseOrders_ds_get_completed_tasks_by_orderId_top1Service);
    }
    return this._ds_get_completed_tasks_by_orderId_top1;
  }
  private _ds_get_converted_weight: PurchaseOrders_ds_get_converted_weightService;
  public get ds_get_converted_weight(): PurchaseOrders_ds_get_converted_weightService {
    if(!this._ds_get_converted_weight) {
      this._ds_get_converted_weight = this.injector.get(PurchaseOrders_ds_get_converted_weightService);
    }
    return this._ds_get_converted_weight;
  }
  private _ds_get_inbound_order_actuals: PurchaseOrders_ds_get_inbound_order_actualsService;
  public get ds_get_inbound_order_actuals(): PurchaseOrders_ds_get_inbound_order_actualsService {
    if(!this._ds_get_inbound_order_actuals) {
      this._ds_get_inbound_order_actuals = this.injector.get(PurchaseOrders_ds_get_inbound_order_actualsService);
    }
    return this._ds_get_inbound_order_actuals;
  }
  private _ds_get_inbound_order_expected: PurchaseOrders_ds_get_inbound_order_expectedService;
  public get ds_get_inbound_order_expected(): PurchaseOrders_ds_get_inbound_order_expectedService {
    if(!this._ds_get_inbound_order_expected) {
      this._ds_get_inbound_order_expected = this.injector.get(PurchaseOrders_ds_get_inbound_order_expectedService);
    }
    return this._ds_get_inbound_order_expected;
  }
  private _ds_get_inbound_totals_by_linenumber_orderId: PurchaseOrders_ds_get_inbound_totals_by_linenumber_orderIdService;
  public get ds_get_inbound_totals_by_linenumber_orderId(): PurchaseOrders_ds_get_inbound_totals_by_linenumber_orderIdService {
    if(!this._ds_get_inbound_totals_by_linenumber_orderId) {
      this._ds_get_inbound_totals_by_linenumber_orderId = this.injector.get(PurchaseOrders_ds_get_inbound_totals_by_linenumber_orderIdService);
    }
    return this._ds_get_inbound_totals_by_linenumber_orderId;
  }
  private _ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1: PurchaseOrders_ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1Service;
  public get ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1(): PurchaseOrders_ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1Service {
    if(!this._ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1) {
      this._ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1 = this.injector.get(PurchaseOrders_ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1Service);
    }
    return this._ds_get_inventory_adjustment_task_by_licenseplateId_lotId_packagingId_bottom1;
  }
  private _ds_get_inventory_count_tasks_by_licenseplateId_top1: PurchaseOrders_ds_get_inventory_count_tasks_by_licenseplateId_top1Service;
  public get ds_get_inventory_count_tasks_by_licenseplateId_top1(): PurchaseOrders_ds_get_inventory_count_tasks_by_licenseplateId_top1Service {
    if(!this._ds_get_inventory_count_tasks_by_licenseplateId_top1) {
      this._ds_get_inventory_count_tasks_by_licenseplateId_top1 = this.injector.get(PurchaseOrders_ds_get_inventory_count_tasks_by_licenseplateId_top1Service);
    }
    return this._ds_get_inventory_count_tasks_by_licenseplateId_top1;
  }
  private _ds_get_licenseplate_by_licenseplateId: PurchaseOrders_ds_get_licenseplate_by_licenseplateIdService;
  public get ds_get_licenseplate_by_licenseplateId(): PurchaseOrders_ds_get_licenseplate_by_licenseplateIdService {
    if(!this._ds_get_licenseplate_by_licenseplateId) {
      this._ds_get_licenseplate_by_licenseplateId = this.injector.get(PurchaseOrders_ds_get_licenseplate_by_licenseplateIdService);
    }
    return this._ds_get_licenseplate_by_licenseplateId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId: PurchaseOrders_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService;
  public get ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId(): PurchaseOrders_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId) {
      this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId = this.injector.get(PurchaseOrders_ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingIdService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId_lotId_and_packagingId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId_top1: PurchaseOrders_ds_get_licenseplatecontents_by_licenseplateId_top1Service;
  public get ds_get_licenseplatecontents_by_licenseplateId_top1(): PurchaseOrders_ds_get_licenseplatecontents_by_licenseplateId_top1Service {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId_top1) {
      this._ds_get_licenseplatecontents_by_licenseplateId_top1 = this.injector.get(PurchaseOrders_ds_get_licenseplatecontents_by_licenseplateId_top1Service);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId_top1;
  }
  private _ds_get_material_packaging_by_materialId_and_packagingId: PurchaseOrders_ds_get_material_packaging_by_materialId_and_packagingIdService;
  public get ds_get_material_packaging_by_materialId_and_packagingId(): PurchaseOrders_ds_get_material_packaging_by_materialId_and_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_and_packagingId) {
      this._ds_get_material_packaging_by_materialId_and_packagingId = this.injector.get(PurchaseOrders_ds_get_material_packaging_by_materialId_and_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_and_packagingId;
  }
  private _ds_get_orderLines_by_orderId: PurchaseOrders_ds_get_orderLines_by_orderIdService;
  public get ds_get_orderLines_by_orderId(): PurchaseOrders_ds_get_orderLines_by_orderIdService {
    if(!this._ds_get_orderLines_by_orderId) {
      this._ds_get_orderLines_by_orderId = this.injector.get(PurchaseOrders_ds_get_orderLines_by_orderIdService);
    }
    return this._ds_get_orderLines_by_orderId;
  }
  private _ds_get_orderLines_with_totals_by_orderId: PurchaseOrders_ds_get_orderLines_with_totals_by_orderIdService;
  public get ds_get_orderLines_with_totals_by_orderId(): PurchaseOrders_ds_get_orderLines_with_totals_by_orderIdService {
    if(!this._ds_get_orderLines_with_totals_by_orderId) {
      this._ds_get_orderLines_with_totals_by_orderId = this.injector.get(PurchaseOrders_ds_get_orderLines_with_totals_by_orderIdService);
    }
    return this._ds_get_orderLines_with_totals_by_orderId;
  }
  private _ds_get_order_account_address_by_orderId: PurchaseOrders_ds_get_order_account_address_by_orderIdService;
  public get ds_get_order_account_address_by_orderId(): PurchaseOrders_ds_get_order_account_address_by_orderIdService {
    if(!this._ds_get_order_account_address_by_orderId) {
      this._ds_get_order_account_address_by_orderId = this.injector.get(PurchaseOrders_ds_get_order_account_address_by_orderIdService);
    }
    return this._ds_get_order_account_address_by_orderId;
  }
  private _ds_get_order_address_by_orderId_top1: PurchaseOrders_ds_get_order_address_by_orderId_top1Service;
  public get ds_get_order_address_by_orderId_top1(): PurchaseOrders_ds_get_order_address_by_orderId_top1Service {
    if(!this._ds_get_order_address_by_orderId_top1) {
      this._ds_get_order_address_by_orderId_top1 = this.injector.get(PurchaseOrders_ds_get_order_address_by_orderId_top1Service);
    }
    return this._ds_get_order_address_by_orderId_top1;
  }
  private _ds_get_order_by_orderId: PurchaseOrders_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): PurchaseOrders_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(PurchaseOrders_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_order_lines_by_order_id_line_number: PurchaseOrders_ds_get_order_lines_by_order_id_line_numberService;
  public get ds_get_order_lines_by_order_id_line_number(): PurchaseOrders_ds_get_order_lines_by_order_id_line_numberService {
    if(!this._ds_get_order_lines_by_order_id_line_number) {
      this._ds_get_order_lines_by_order_id_line_number = this.injector.get(PurchaseOrders_ds_get_order_lines_by_order_id_line_numberService);
    }
    return this._ds_get_order_lines_by_order_id_line_number;
  }
  private _ds_get_order_owner_contact_top1: PurchaseOrders_ds_get_order_owner_contact_top1Service;
  public get ds_get_order_owner_contact_top1(): PurchaseOrders_ds_get_order_owner_contact_top1Service {
    if(!this._ds_get_order_owner_contact_top1) {
      this._ds_get_order_owner_contact_top1 = this.injector.get(PurchaseOrders_ds_get_order_owner_contact_top1Service);
    }
    return this._ds_get_order_owner_contact_top1;
  }
  private _ds_get_order_owner_contact_top1_flow_based: PurchaseOrders_ds_get_order_owner_contact_top1_flow_basedService;
  public get ds_get_order_owner_contact_top1_flow_based(): PurchaseOrders_ds_get_order_owner_contact_top1_flow_basedService {
    if(!this._ds_get_order_owner_contact_top1_flow_based) {
      this._ds_get_order_owner_contact_top1_flow_based = this.injector.get(PurchaseOrders_ds_get_order_owner_contact_top1_flow_basedService);
    }
    return this._ds_get_order_owner_contact_top1_flow_based;
  }
  private _ds_get_order_project_contact_top1: PurchaseOrders_ds_get_order_project_contact_top1Service;
  public get ds_get_order_project_contact_top1(): PurchaseOrders_ds_get_order_project_contact_top1Service {
    if(!this._ds_get_order_project_contact_top1) {
      this._ds_get_order_project_contact_top1 = this.injector.get(PurchaseOrders_ds_get_order_project_contact_top1Service);
    }
    return this._ds_get_order_project_contact_top1;
  }
  private _ds_get_order_ship_from_account_vs_order_address: PurchaseOrders_ds_get_order_ship_from_account_vs_order_addressService;
  public get ds_get_order_ship_from_account_vs_order_address(): PurchaseOrders_ds_get_order_ship_from_account_vs_order_addressService {
    if(!this._ds_get_order_ship_from_account_vs_order_address) {
      this._ds_get_order_ship_from_account_vs_order_address = this.injector.get(PurchaseOrders_ds_get_order_ship_from_account_vs_order_addressService);
    }
    return this._ds_get_order_ship_from_account_vs_order_address;
  }
  private _ds_get_order_signature_attachment: PurchaseOrders_ds_get_order_signature_attachmentService;
  public get ds_get_order_signature_attachment(): PurchaseOrders_ds_get_order_signature_attachmentService {
    if(!this._ds_get_order_signature_attachment) {
      this._ds_get_order_signature_attachment = this.injector.get(PurchaseOrders_ds_get_order_signature_attachmentService);
    }
    return this._ds_get_order_signature_attachment;
  }
  private _ds_get_order_totals_by_orderId: PurchaseOrders_ds_get_order_totals_by_orderIdService;
  public get ds_get_order_totals_by_orderId(): PurchaseOrders_ds_get_order_totals_by_orderIdService {
    if(!this._ds_get_order_totals_by_orderId) {
      this._ds_get_order_totals_by_orderId = this.injector.get(PurchaseOrders_ds_get_order_totals_by_orderIdService);
    }
    return this._ds_get_order_totals_by_orderId;
  }
  private _ds_get_order_validation_result_most_recent: PurchaseOrders_ds_get_order_validation_result_most_recentService;
  public get ds_get_order_validation_result_most_recent(): PurchaseOrders_ds_get_order_validation_result_most_recentService {
    if(!this._ds_get_order_validation_result_most_recent) {
      this._ds_get_order_validation_result_most_recent = this.injector.get(PurchaseOrders_ds_get_order_validation_result_most_recentService);
    }
    return this._ds_get_order_validation_result_most_recent;
  }
  private _ds_get_order_warehouse_contact_top1: PurchaseOrders_ds_get_order_warehouse_contact_top1Service;
  public get ds_get_order_warehouse_contact_top1(): PurchaseOrders_ds_get_order_warehouse_contact_top1Service {
    if(!this._ds_get_order_warehouse_contact_top1) {
      this._ds_get_order_warehouse_contact_top1 = this.injector.get(PurchaseOrders_ds_get_order_warehouse_contact_top1Service);
    }
    return this._ds_get_order_warehouse_contact_top1;
  }
  private _ds_get_order_warehouse_contact_top1_flow_based: PurchaseOrders_ds_get_order_warehouse_contact_top1_flow_basedService;
  public get ds_get_order_warehouse_contact_top1_flow_based(): PurchaseOrders_ds_get_order_warehouse_contact_top1_flow_basedService {
    if(!this._ds_get_order_warehouse_contact_top1_flow_based) {
      this._ds_get_order_warehouse_contact_top1_flow_based = this.injector.get(PurchaseOrders_ds_get_order_warehouse_contact_top1_flow_basedService);
    }
    return this._ds_get_order_warehouse_contact_top1_flow_based;
  }
  private _ds_get_orderaddresses_by_orderId: PurchaseOrders_ds_get_orderaddresses_by_orderIdService;
  public get ds_get_orderaddresses_by_orderId(): PurchaseOrders_ds_get_orderaddresses_by_orderIdService {
    if(!this._ds_get_orderaddresses_by_orderId) {
      this._ds_get_orderaddresses_by_orderId = this.injector.get(PurchaseOrders_ds_get_orderaddresses_by_orderIdService);
    }
    return this._ds_get_orderaddresses_by_orderId;
  }
  private _ds_get_orderclasses_by_lookupcode: PurchaseOrders_ds_get_orderclasses_by_lookupcodeService;
  public get ds_get_orderclasses_by_lookupcode(): PurchaseOrders_ds_get_orderclasses_by_lookupcodeService {
    if(!this._ds_get_orderclasses_by_lookupcode) {
      this._ds_get_orderclasses_by_lookupcode = this.injector.get(PurchaseOrders_ds_get_orderclasses_by_lookupcodeService);
    }
    return this._ds_get_orderclasses_by_lookupcode;
  }
  private _ds_get_orderlines_by_orderId_bottom1: PurchaseOrders_ds_get_orderlines_by_orderId_bottom1Service;
  public get ds_get_orderlines_by_orderId_bottom1(): PurchaseOrders_ds_get_orderlines_by_orderId_bottom1Service {
    if(!this._ds_get_orderlines_by_orderId_bottom1) {
      this._ds_get_orderlines_by_orderId_bottom1 = this.injector.get(PurchaseOrders_ds_get_orderlines_by_orderId_bottom1Service);
    }
    return this._ds_get_orderlines_by_orderId_bottom1;
  }
  private _ds_get_orderlines_by_orderId_linenumber: PurchaseOrders_ds_get_orderlines_by_orderId_linenumberService;
  public get ds_get_orderlines_by_orderId_linenumber(): PurchaseOrders_ds_get_orderlines_by_orderId_linenumberService {
    if(!this._ds_get_orderlines_by_orderId_linenumber) {
      this._ds_get_orderlines_by_orderId_linenumber = this.injector.get(PurchaseOrders_ds_get_orderlines_by_orderId_linenumberService);
    }
    return this._ds_get_orderlines_by_orderId_linenumber;
  }
  private _ds_get_orders_aggregated_expected: PurchaseOrders_ds_get_orders_aggregated_expectedService;
  public get ds_get_orders_aggregated_expected(): PurchaseOrders_ds_get_orders_aggregated_expectedService {
    if(!this._ds_get_orders_aggregated_expected) {
      this._ds_get_orders_aggregated_expected = this.injector.get(PurchaseOrders_ds_get_orders_aggregated_expectedService);
    }
    return this._ds_get_orders_aggregated_expected;
  }
  private _ds_get_orders_aggregated_tasks: PurchaseOrders_ds_get_orders_aggregated_tasksService;
  public get ds_get_orders_aggregated_tasks(): PurchaseOrders_ds_get_orders_aggregated_tasksService {
    if(!this._ds_get_orders_aggregated_tasks) {
      this._ds_get_orders_aggregated_tasks = this.injector.get(PurchaseOrders_ds_get_orders_aggregated_tasksService);
    }
    return this._ds_get_orders_aggregated_tasks;
  }
  private _ds_get_orders_by_lookupcode: PurchaseOrders_ds_get_orders_by_lookupcodeService;
  public get ds_get_orders_by_lookupcode(): PurchaseOrders_ds_get_orders_by_lookupcodeService {
    if(!this._ds_get_orders_by_lookupcode) {
      this._ds_get_orders_by_lookupcode = this.injector.get(PurchaseOrders_ds_get_orders_by_lookupcodeService);
    }
    return this._ds_get_orders_by_lookupcode;
  }
  private _ds_get_project_by_projectId: PurchaseOrders_ds_get_project_by_projectIdService;
  public get ds_get_project_by_projectId(): PurchaseOrders_ds_get_project_by_projectIdService {
    if(!this._ds_get_project_by_projectId) {
      this._ds_get_project_by_projectId = this.injector.get(PurchaseOrders_ds_get_project_by_projectIdService);
    }
    return this._ds_get_project_by_projectId;
  }
  private _ds_get_putaway_tasks_by_shipmentId: PurchaseOrders_ds_get_putaway_tasks_by_shipmentIdService;
  public get ds_get_putaway_tasks_by_shipmentId(): PurchaseOrders_ds_get_putaway_tasks_by_shipmentIdService {
    if(!this._ds_get_putaway_tasks_by_shipmentId) {
      this._ds_get_putaway_tasks_by_shipmentId = this.injector.get(PurchaseOrders_ds_get_putaway_tasks_by_shipmentIdService);
    }
    return this._ds_get_putaway_tasks_by_shipmentId;
  }
  private _ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId: PurchaseOrders_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService;
  public get ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId(): PurchaseOrders_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService {
    if(!this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId) {
      this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId = this.injector.get(PurchaseOrders_ds_get_reasoncodes_by_lookupcode_parentId_parentEntityIdService);
    }
    return this._ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId;
  }
  private _ds_get_receiving_tasks_by_orderId: PurchaseOrders_ds_get_receiving_tasks_by_orderIdService;
  public get ds_get_receiving_tasks_by_orderId(): PurchaseOrders_ds_get_receiving_tasks_by_orderIdService {
    if(!this._ds_get_receiving_tasks_by_orderId) {
      this._ds_get_receiving_tasks_by_orderId = this.injector.get(PurchaseOrders_ds_get_receiving_tasks_by_orderIdService);
    }
    return this._ds_get_receiving_tasks_by_orderId;
  }
  private _ds_get_released_putaway_tasks_by_shipmentId: PurchaseOrders_ds_get_released_putaway_tasks_by_shipmentIdService;
  public get ds_get_released_putaway_tasks_by_shipmentId(): PurchaseOrders_ds_get_released_putaway_tasks_by_shipmentIdService {
    if(!this._ds_get_released_putaway_tasks_by_shipmentId) {
      this._ds_get_released_putaway_tasks_by_shipmentId = this.injector.get(PurchaseOrders_ds_get_released_putaway_tasks_by_shipmentIdService);
    }
    return this._ds_get_released_putaway_tasks_by_shipmentId;
  }
  private _ds_get_released_putaway_tasks_by_shipmentId_top1: PurchaseOrders_ds_get_released_putaway_tasks_by_shipmentId_top1Service;
  public get ds_get_released_putaway_tasks_by_shipmentId_top1(): PurchaseOrders_ds_get_released_putaway_tasks_by_shipmentId_top1Service {
    if(!this._ds_get_released_putaway_tasks_by_shipmentId_top1) {
      this._ds_get_released_putaway_tasks_by_shipmentId_top1 = this.injector.get(PurchaseOrders_ds_get_released_putaway_tasks_by_shipmentId_top1Service);
    }
    return this._ds_get_released_putaway_tasks_by_shipmentId_top1;
  }
  private _ds_get_released_receiving_tasks_by_orderId_shipmentId_top1: PurchaseOrders_ds_get_released_receiving_tasks_by_orderId_shipmentId_top1Service;
  public get ds_get_released_receiving_tasks_by_orderId_shipmentId_top1(): PurchaseOrders_ds_get_released_receiving_tasks_by_orderId_shipmentId_top1Service {
    if(!this._ds_get_released_receiving_tasks_by_orderId_shipmentId_top1) {
      this._ds_get_released_receiving_tasks_by_orderId_shipmentId_top1 = this.injector.get(PurchaseOrders_ds_get_released_receiving_tasks_by_orderId_shipmentId_top1Service);
    }
    return this._ds_get_released_receiving_tasks_by_orderId_shipmentId_top1;
  }
  private _ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup: PurchaseOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService;
  public get ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup(): PurchaseOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService {
    if(!this._ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup) {
      this._ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup = this.injector.get(PurchaseOrders_ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookupService);
    }
    return this._ds_get_serialcreation_tasks_by_chainhead_lotId_packagingId_seriallookup;
  }
  private _ds_get_serialnumbers_by_licenseplateId_lotId_packagingId: PurchaseOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_serialnumbers_by_licenseplateId_lotId_packagingId(): PurchaseOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId) {
      this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId = this.injector.get(PurchaseOrders_ds_get_serialnumbers_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_serialnumbers_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_shipmentLines_by_orderId_shipmentId: PurchaseOrders_ds_get_shipmentLines_by_orderId_shipmentIdService;
  public get ds_get_shipmentLines_by_orderId_shipmentId(): PurchaseOrders_ds_get_shipmentLines_by_orderId_shipmentIdService {
    if(!this._ds_get_shipmentLines_by_orderId_shipmentId) {
      this._ds_get_shipmentLines_by_orderId_shipmentId = this.injector.get(PurchaseOrders_ds_get_shipmentLines_by_orderId_shipmentIdService);
    }
    return this._ds_get_shipmentLines_by_orderId_shipmentId;
  }
  private _ds_get_shipmentLines_by_orderId_shipmentId_single: PurchaseOrders_ds_get_shipmentLines_by_orderId_shipmentId_singleService;
  public get ds_get_shipmentLines_by_orderId_shipmentId_single(): PurchaseOrders_ds_get_shipmentLines_by_orderId_shipmentId_singleService {
    if(!this._ds_get_shipmentLines_by_orderId_shipmentId_single) {
      this._ds_get_shipmentLines_by_orderId_shipmentId_single = this.injector.get(PurchaseOrders_ds_get_shipmentLines_by_orderId_shipmentId_singleService);
    }
    return this._ds_get_shipmentLines_by_orderId_shipmentId_single;
  }
  private _ds_get_shipmentLines_by_orderId_top1: PurchaseOrders_ds_get_shipmentLines_by_orderId_top1Service;
  public get ds_get_shipmentLines_by_orderId_top1(): PurchaseOrders_ds_get_shipmentLines_by_orderId_top1Service {
    if(!this._ds_get_shipmentLines_by_orderId_top1) {
      this._ds_get_shipmentLines_by_orderId_top1 = this.injector.get(PurchaseOrders_ds_get_shipmentLines_by_orderId_top1Service);
    }
    return this._ds_get_shipmentLines_by_orderId_top1;
  }
  private _ds_get_shipmentLines_by_shipmentId_top1: PurchaseOrders_ds_get_shipmentLines_by_shipmentId_top1Service;
  public get ds_get_shipmentLines_by_shipmentId_top1(): PurchaseOrders_ds_get_shipmentLines_by_shipmentId_top1Service {
    if(!this._ds_get_shipmentLines_by_shipmentId_top1) {
      this._ds_get_shipmentLines_by_shipmentId_top1 = this.injector.get(PurchaseOrders_ds_get_shipmentLines_by_shipmentId_top1Service);
    }
    return this._ds_get_shipmentLines_by_shipmentId_top1;
  }
  private _ds_get_shipment_by_orderId_top1: PurchaseOrders_ds_get_shipment_by_orderId_top1Service;
  public get ds_get_shipment_by_orderId_top1(): PurchaseOrders_ds_get_shipment_by_orderId_top1Service {
    if(!this._ds_get_shipment_by_orderId_top1) {
      this._ds_get_shipment_by_orderId_top1 = this.injector.get(PurchaseOrders_ds_get_shipment_by_orderId_top1Service);
    }
    return this._ds_get_shipment_by_orderId_top1;
  }
  private _ds_get_shipment_lines_by_orderId_orderLineNumber: PurchaseOrders_ds_get_shipment_lines_by_orderId_orderLineNumberService;
  public get ds_get_shipment_lines_by_orderId_orderLineNumber(): PurchaseOrders_ds_get_shipment_lines_by_orderId_orderLineNumberService {
    if(!this._ds_get_shipment_lines_by_orderId_orderLineNumber) {
      this._ds_get_shipment_lines_by_orderId_orderLineNumber = this.injector.get(PurchaseOrders_ds_get_shipment_lines_by_orderId_orderLineNumberService);
    }
    return this._ds_get_shipment_lines_by_orderId_orderLineNumber;
  }
  private _ds_get_shipmentline_by_shipmentlineId: PurchaseOrders_ds_get_shipmentline_by_shipmentlineIdService;
  public get ds_get_shipmentline_by_shipmentlineId(): PurchaseOrders_ds_get_shipmentline_by_shipmentlineIdService {
    if(!this._ds_get_shipmentline_by_shipmentlineId) {
      this._ds_get_shipmentline_by_shipmentlineId = this.injector.get(PurchaseOrders_ds_get_shipmentline_by_shipmentlineIdService);
    }
    return this._ds_get_shipmentline_by_shipmentlineId;
  }
  private _ds_get_shipmentlines_by_orderId_bottom1: PurchaseOrders_ds_get_shipmentlines_by_orderId_bottom1Service;
  public get ds_get_shipmentlines_by_orderId_bottom1(): PurchaseOrders_ds_get_shipmentlines_by_orderId_bottom1Service {
    if(!this._ds_get_shipmentlines_by_orderId_bottom1) {
      this._ds_get_shipmentlines_by_orderId_bottom1 = this.injector.get(PurchaseOrders_ds_get_shipmentlines_by_orderId_bottom1Service);
    }
    return this._ds_get_shipmentlines_by_orderId_bottom1;
  }
  private _ds_get_shipmentorder_details_by_shipmentId_top1: PurchaseOrders_ds_get_shipmentorder_details_by_shipmentId_top1Service;
  public get ds_get_shipmentorder_details_by_shipmentId_top1(): PurchaseOrders_ds_get_shipmentorder_details_by_shipmentId_top1Service {
    if(!this._ds_get_shipmentorder_details_by_shipmentId_top1) {
      this._ds_get_shipmentorder_details_by_shipmentId_top1 = this.injector.get(PurchaseOrders_ds_get_shipmentorder_details_by_shipmentId_top1Service);
    }
    return this._ds_get_shipmentorder_details_by_shipmentId_top1;
  }
  private _ds_get_shipments_by_orderId: PurchaseOrders_ds_get_shipments_by_orderIdService;
  public get ds_get_shipments_by_orderId(): PurchaseOrders_ds_get_shipments_by_orderIdService {
    if(!this._ds_get_shipments_by_orderId) {
      this._ds_get_shipments_by_orderId = this.injector.get(PurchaseOrders_ds_get_shipments_by_orderIdService);
    }
    return this._ds_get_shipments_by_orderId;
  }
  private _ds_get_shipments_by_shipmentId: PurchaseOrders_ds_get_shipments_by_shipmentIdService;
  public get ds_get_shipments_by_shipmentId(): PurchaseOrders_ds_get_shipments_by_shipmentIdService {
    if(!this._ds_get_shipments_by_shipmentId) {
      this._ds_get_shipments_by_shipmentId = this.injector.get(PurchaseOrders_ds_get_shipments_by_shipmentIdService);
    }
    return this._ds_get_shipments_by_shipmentId;
  }
  private _ds_get_task_by_taskId: PurchaseOrders_ds_get_task_by_taskIdService;
  public get ds_get_task_by_taskId(): PurchaseOrders_ds_get_task_by_taskIdService {
    if(!this._ds_get_task_by_taskId) {
      this._ds_get_task_by_taskId = this.injector.get(PurchaseOrders_ds_get_task_by_taskIdService);
    }
    return this._ds_get_task_by_taskId;
  }
  private _ds_get_tasks_by_chainHead: PurchaseOrders_ds_get_tasks_by_chainHeadService;
  public get ds_get_tasks_by_chainHead(): PurchaseOrders_ds_get_tasks_by_chainHeadService {
    if(!this._ds_get_tasks_by_chainHead) {
      this._ds_get_tasks_by_chainHead = this.injector.get(PurchaseOrders_ds_get_tasks_by_chainHeadService);
    }
    return this._ds_get_tasks_by_chainHead;
  }
  private _ds_get_tasks_by_orderId: PurchaseOrders_ds_get_tasks_by_orderIdService;
  public get ds_get_tasks_by_orderId(): PurchaseOrders_ds_get_tasks_by_orderIdService {
    if(!this._ds_get_tasks_by_orderId) {
      this._ds_get_tasks_by_orderId = this.injector.get(PurchaseOrders_ds_get_tasks_by_orderIdService);
    }
    return this._ds_get_tasks_by_orderId;
  }
  private _ds_get_tasks_by_orderId_orderLineNumber: PurchaseOrders_ds_get_tasks_by_orderId_orderLineNumberService;
  public get ds_get_tasks_by_orderId_orderLineNumber(): PurchaseOrders_ds_get_tasks_by_orderId_orderLineNumberService {
    if(!this._ds_get_tasks_by_orderId_orderLineNumber) {
      this._ds_get_tasks_by_orderId_orderLineNumber = this.injector.get(PurchaseOrders_ds_get_tasks_by_orderId_orderLineNumberService);
    }
    return this._ds_get_tasks_by_orderId_orderLineNumber;
  }
  private _ds_get_tasks_by_orderId_shipmentId: PurchaseOrders_ds_get_tasks_by_orderId_shipmentIdService;
  public get ds_get_tasks_by_orderId_shipmentId(): PurchaseOrders_ds_get_tasks_by_orderId_shipmentIdService {
    if(!this._ds_get_tasks_by_orderId_shipmentId) {
      this._ds_get_tasks_by_orderId_shipmentId = this.injector.get(PurchaseOrders_ds_get_tasks_by_orderId_shipmentIdService);
    }
    return this._ds_get_tasks_by_orderId_shipmentId;
  }
  private _ds_get_tasks_by_shipmentId: PurchaseOrders_ds_get_tasks_by_shipmentIdService;
  public get ds_get_tasks_by_shipmentId(): PurchaseOrders_ds_get_tasks_by_shipmentIdService {
    if(!this._ds_get_tasks_by_shipmentId) {
      this._ds_get_tasks_by_shipmentId = this.injector.get(PurchaseOrders_ds_get_tasks_by_shipmentIdService);
    }
    return this._ds_get_tasks_by_shipmentId;
  }
  private _ds_get_warehouse_by_warehouseId: PurchaseOrders_ds_get_warehouse_by_warehouseIdService;
  public get ds_get_warehouse_by_warehouseId(): PurchaseOrders_ds_get_warehouse_by_warehouseIdService {
    if(!this._ds_get_warehouse_by_warehouseId) {
      this._ds_get_warehouse_by_warehouseId = this.injector.get(PurchaseOrders_ds_get_warehouse_by_warehouseIdService);
    }
    return this._ds_get_warehouse_by_warehouseId;
  }
  private _ds_inbound_orders_details_grid: PurchaseOrders_ds_inbound_orders_details_gridService;
  public get ds_inbound_orders_details_grid(): PurchaseOrders_ds_inbound_orders_details_gridService {
    if(!this._ds_inbound_orders_details_grid) {
      this._ds_inbound_orders_details_grid = this.injector.get(PurchaseOrders_ds_inbound_orders_details_gridService);
    }
    return this._ds_inbound_orders_details_grid;
  }
  private _ds_inbound_orders_details_grid_count: PurchaseOrders_ds_inbound_orders_details_grid_countService;
  public get ds_inbound_orders_details_grid_count(): PurchaseOrders_ds_inbound_orders_details_grid_countService {
    if(!this._ds_inbound_orders_details_grid_count) {
      this._ds_inbound_orders_details_grid_count = this.injector.get(PurchaseOrders_ds_inbound_orders_details_grid_countService);
    }
    return this._ds_inbound_orders_details_grid_count;
  }
  private _ds_inbound_orders_grid: PurchaseOrders_ds_inbound_orders_gridService;
  public get ds_inbound_orders_grid(): PurchaseOrders_ds_inbound_orders_gridService {
    if(!this._ds_inbound_orders_grid) {
      this._ds_inbound_orders_grid = this.injector.get(PurchaseOrders_ds_inbound_orders_gridService);
    }
    return this._ds_inbound_orders_grid;
  }
  private _ds_inbound_orders_grid_count: PurchaseOrders_ds_inbound_orders_grid_countService;
  public get ds_inbound_orders_grid_count(): PurchaseOrders_ds_inbound_orders_grid_countService {
    if(!this._ds_inbound_orders_grid_count) {
      this._ds_inbound_orders_grid_count = this.injector.get(PurchaseOrders_ds_inbound_orders_grid_countService);
    }
    return this._ds_inbound_orders_grid_count;
  }
  private _ds_inbound_orders_grid_properties: PurchaseOrders_ds_inbound_orders_grid_propertiesService;
  public get ds_inbound_orders_grid_properties(): PurchaseOrders_ds_inbound_orders_grid_propertiesService {
    if(!this._ds_inbound_orders_grid_properties) {
      this._ds_inbound_orders_grid_properties = this.injector.get(PurchaseOrders_ds_inbound_orders_grid_propertiesService);
    }
    return this._ds_inbound_orders_grid_properties;
  }
  private _ds_inbound_orders_summary_report: PurchaseOrders_ds_inbound_orders_summary_reportService;
  public get ds_inbound_orders_summary_report(): PurchaseOrders_ds_inbound_orders_summary_reportService {
    if(!this._ds_inbound_orders_summary_report) {
      this._ds_inbound_orders_summary_report = this.injector.get(PurchaseOrders_ds_inbound_orders_summary_reportService);
    }
    return this._ds_inbound_orders_summary_report;
  }
  private _ds_load_container_by_id: PurchaseOrders_ds_load_container_by_idService;
  public get ds_load_container_by_id(): PurchaseOrders_ds_load_container_by_idService {
    if(!this._ds_load_container_by_id) {
      this._ds_load_container_by_id = this.injector.get(PurchaseOrders_ds_load_container_by_idService);
    }
    return this._ds_load_container_by_id;
  }
  private _ds_open_putaway_report: PurchaseOrders_ds_open_putaway_reportService;
  public get ds_open_putaway_report(): PurchaseOrders_ds_open_putaway_reportService {
    if(!this._ds_open_putaway_report) {
      this._ds_open_putaway_report = this.injector.get(PurchaseOrders_ds_open_putaway_reportService);
    }
    return this._ds_open_putaway_report;
  }
  private _ds_order_total_received_gross_by_date: PurchaseOrders_ds_order_total_received_gross_by_dateService;
  public get ds_order_total_received_gross_by_date(): PurchaseOrders_ds_order_total_received_gross_by_dateService {
    if(!this._ds_order_total_received_gross_by_date) {
      this._ds_order_total_received_gross_by_date = this.injector.get(PurchaseOrders_ds_order_total_received_gross_by_dateService);
    }
    return this._ds_order_total_received_gross_by_date;
  }
  private _ds_order_total_received_gross_by_date_widget: PurchaseOrders_ds_order_total_received_gross_by_date_widgetService;
  public get ds_order_total_received_gross_by_date_widget(): PurchaseOrders_ds_order_total_received_gross_by_date_widgetService {
    if(!this._ds_order_total_received_gross_by_date_widget) {
      this._ds_order_total_received_gross_by_date_widget = this.injector.get(PurchaseOrders_ds_order_total_received_gross_by_date_widgetService);
    }
    return this._ds_order_total_received_gross_by_date_widget;
  }
  private _ds_order_total_received_order_count_by_date: PurchaseOrders_ds_order_total_received_order_count_by_dateService;
  public get ds_order_total_received_order_count_by_date(): PurchaseOrders_ds_order_total_received_order_count_by_dateService {
    if(!this._ds_order_total_received_order_count_by_date) {
      this._ds_order_total_received_order_count_by_date = this.injector.get(PurchaseOrders_ds_order_total_received_order_count_by_dateService);
    }
    return this._ds_order_total_received_order_count_by_date;
  }
  private _ds_order_total_received_order_count_by_date_widget: PurchaseOrders_ds_order_total_received_order_count_by_date_widgetService;
  public get ds_order_total_received_order_count_by_date_widget(): PurchaseOrders_ds_order_total_received_order_count_by_date_widgetService {
    if(!this._ds_order_total_received_order_count_by_date_widget) {
      this._ds_order_total_received_order_count_by_date_widget = this.injector.get(PurchaseOrders_ds_order_total_received_order_count_by_date_widgetService);
    }
    return this._ds_order_total_received_order_count_by_date_widget;
  }
  private _ds_order_total_received_units_by_date: PurchaseOrders_ds_order_total_received_units_by_dateService;
  public get ds_order_total_received_units_by_date(): PurchaseOrders_ds_order_total_received_units_by_dateService {
    if(!this._ds_order_total_received_units_by_date) {
      this._ds_order_total_received_units_by_date = this.injector.get(PurchaseOrders_ds_order_total_received_units_by_dateService);
    }
    return this._ds_order_total_received_units_by_date;
  }
  private _ds_order_total_received_units_by_date_widget: PurchaseOrders_ds_order_total_received_units_by_date_widgetService;
  public get ds_order_total_received_units_by_date_widget(): PurchaseOrders_ds_order_total_received_units_by_date_widgetService {
    if(!this._ds_order_total_received_units_by_date_widget) {
      this._ds_order_total_received_units_by_date_widget = this.injector.get(PurchaseOrders_ds_order_total_received_units_by_date_widgetService);
    }
    return this._ds_order_total_received_units_by_date_widget;
  }
  private _ds_orderclasses_dd: PurchaseOrders_ds_orderclasses_ddService;
  public get ds_orderclasses_dd(): PurchaseOrders_ds_orderclasses_ddService {
    if(!this._ds_orderclasses_dd) {
      this._ds_orderclasses_dd = this.injector.get(PurchaseOrders_ds_orderclasses_ddService);
    }
    return this._ds_orderclasses_dd;
  }
  private _ds_orderline_total_amount: PurchaseOrders_ds_orderline_total_amountService;
  public get ds_orderline_total_amount(): PurchaseOrders_ds_orderline_total_amountService {
    if(!this._ds_orderline_total_amount) {
      this._ds_orderline_total_amount = this.injector.get(PurchaseOrders_ds_orderline_total_amountService);
    }
    return this._ds_orderline_total_amount;
  }
  private _ds_orderline_total_amount_widget: PurchaseOrders_ds_orderline_total_amount_widgetService;
  public get ds_orderline_total_amount_widget(): PurchaseOrders_ds_orderline_total_amount_widgetService {
    if(!this._ds_orderline_total_amount_widget) {
      this._ds_orderline_total_amount_widget = this.injector.get(PurchaseOrders_ds_orderline_total_amount_widgetService);
    }
    return this._ds_orderline_total_amount_widget;
  }
  private _ds_orderline_total_gross_received_widget: PurchaseOrders_ds_orderline_total_gross_received_widgetService;
  public get ds_orderline_total_gross_received_widget(): PurchaseOrders_ds_orderline_total_gross_received_widgetService {
    if(!this._ds_orderline_total_gross_received_widget) {
      this._ds_orderline_total_gross_received_widget = this.injector.get(PurchaseOrders_ds_orderline_total_gross_received_widgetService);
    }
    return this._ds_orderline_total_gross_received_widget;
  }
  private _ds_orderline_total_received: PurchaseOrders_ds_orderline_total_receivedService;
  public get ds_orderline_total_received(): PurchaseOrders_ds_orderline_total_receivedService {
    if(!this._ds_orderline_total_received) {
      this._ds_orderline_total_received = this.injector.get(PurchaseOrders_ds_orderline_total_receivedService);
    }
    return this._ds_orderline_total_received;
  }
  private _ds_orderline_total_received_widget: PurchaseOrders_ds_orderline_total_received_widgetService;
  public get ds_orderline_total_received_widget(): PurchaseOrders_ds_orderline_total_received_widgetService {
    if(!this._ds_orderline_total_received_widget) {
      this._ds_orderline_total_received_widget = this.injector.get(PurchaseOrders_ds_orderline_total_received_widgetService);
    }
    return this._ds_orderline_total_received_widget;
  }
  private _ds_orders_grid: PurchaseOrders_ds_orders_gridService;
  public get ds_orders_grid(): PurchaseOrders_ds_orders_gridService {
    if(!this._ds_orders_grid) {
      this._ds_orders_grid = this.injector.get(PurchaseOrders_ds_orders_gridService);
    }
    return this._ds_orders_grid;
  }
  private _ds_orderstatuses_dd: PurchaseOrders_ds_orderstatuses_ddService;
  public get ds_orderstatuses_dd(): PurchaseOrders_ds_orderstatuses_ddService {
    if(!this._ds_orderstatuses_dd) {
      this._ds_orderstatuses_dd = this.injector.get(PurchaseOrders_ds_orderstatuses_ddService);
    }
    return this._ds_orderstatuses_dd;
  }
  private _ds_owners_dd: PurchaseOrders_ds_owners_ddService;
  public get ds_owners_dd(): PurchaseOrders_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(PurchaseOrders_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_projects_dd: PurchaseOrders_ds_projects_ddService;
  public get ds_projects_dd(): PurchaseOrders_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(PurchaseOrders_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_purchase_order_bulk_receiving_by_taskId: PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskIdService;
  public get ds_purchase_order_bulk_receiving_by_taskId(): PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskIdService {
    if(!this._ds_purchase_order_bulk_receiving_by_taskId) {
      this._ds_purchase_order_bulk_receiving_by_taskId = this.injector.get(PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskIdService);
    }
    return this._ds_purchase_order_bulk_receiving_by_taskId;
  }
  private _ds_purchase_order_bulk_receiving_by_taskId_grid: PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskId_gridService;
  public get ds_purchase_order_bulk_receiving_by_taskId_grid(): PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskId_gridService {
    if(!this._ds_purchase_order_bulk_receiving_by_taskId_grid) {
      this._ds_purchase_order_bulk_receiving_by_taskId_grid = this.injector.get(PurchaseOrders_ds_purchase_order_bulk_receiving_by_taskId_gridService);
    }
    return this._ds_purchase_order_bulk_receiving_by_taskId_grid;
  }
  private _ds_purchase_order_details_report: PurchaseOrders_ds_purchase_order_details_reportService;
  public get ds_purchase_order_details_report(): PurchaseOrders_ds_purchase_order_details_reportService {
    if(!this._ds_purchase_order_details_report) {
      this._ds_purchase_order_details_report = this.injector.get(PurchaseOrders_ds_purchase_order_details_reportService);
    }
    return this._ds_purchase_order_details_report;
  }
  private _ds_purchase_order_details_report_flow_based: PurchaseOrders_ds_purchase_order_details_report_flow_basedService;
  public get ds_purchase_order_details_report_flow_based(): PurchaseOrders_ds_purchase_order_details_report_flow_basedService {
    if(!this._ds_purchase_order_details_report_flow_based) {
      this._ds_purchase_order_details_report_flow_based = this.injector.get(PurchaseOrders_ds_purchase_order_details_report_flow_basedService);
    }
    return this._ds_purchase_order_details_report_flow_based;
  }
  private _ds_purchase_order_editor: PurchaseOrders_ds_purchase_order_editorService;
  public get ds_purchase_order_editor(): PurchaseOrders_ds_purchase_order_editorService {
    if(!this._ds_purchase_order_editor) {
      this._ds_purchase_order_editor = this.injector.get(PurchaseOrders_ds_purchase_order_editorService);
    }
    return this._ds_purchase_order_editor;
  }
  private _ds_purchase_order_header_report: PurchaseOrders_ds_purchase_order_header_reportService;
  public get ds_purchase_order_header_report(): PurchaseOrders_ds_purchase_order_header_reportService {
    if(!this._ds_purchase_order_header_report) {
      this._ds_purchase_order_header_report = this.injector.get(PurchaseOrders_ds_purchase_order_header_reportService);
    }
    return this._ds_purchase_order_header_report;
  }
  private _ds_purchase_order_lines_grid: PurchaseOrders_ds_purchase_order_lines_gridService;
  public get ds_purchase_order_lines_grid(): PurchaseOrders_ds_purchase_order_lines_gridService {
    if(!this._ds_purchase_order_lines_grid) {
      this._ds_purchase_order_lines_grid = this.injector.get(PurchaseOrders_ds_purchase_order_lines_gridService);
    }
    return this._ds_purchase_order_lines_grid;
  }
  private _ds_purchase_order_lines_grid_edi: PurchaseOrders_ds_purchase_order_lines_grid_ediService;
  public get ds_purchase_order_lines_grid_edi(): PurchaseOrders_ds_purchase_order_lines_grid_ediService {
    if(!this._ds_purchase_order_lines_grid_edi) {
      this._ds_purchase_order_lines_grid_edi = this.injector.get(PurchaseOrders_ds_purchase_order_lines_grid_ediService);
    }
    return this._ds_purchase_order_lines_grid_edi;
  }
  private _ds_purchase_order_receiving_grid: PurchaseOrders_ds_purchase_order_receiving_gridService;
  public get ds_purchase_order_receiving_grid(): PurchaseOrders_ds_purchase_order_receiving_gridService {
    if(!this._ds_purchase_order_receiving_grid) {
      this._ds_purchase_order_receiving_grid = this.injector.get(PurchaseOrders_ds_purchase_order_receiving_gridService);
    }
    return this._ds_purchase_order_receiving_grid;
  }
  private _ds_purchase_orders_children_tasks_grid: PurchaseOrders_ds_purchase_orders_children_tasks_gridService;
  public get ds_purchase_orders_children_tasks_grid(): PurchaseOrders_ds_purchase_orders_children_tasks_gridService {
    if(!this._ds_purchase_orders_children_tasks_grid) {
      this._ds_purchase_orders_children_tasks_grid = this.injector.get(PurchaseOrders_ds_purchase_orders_children_tasks_gridService);
    }
    return this._ds_purchase_orders_children_tasks_grid;
  }
  private _ds_purchase_orders_tasks_grid: PurchaseOrders_ds_purchase_orders_tasks_gridService;
  public get ds_purchase_orders_tasks_grid(): PurchaseOrders_ds_purchase_orders_tasks_gridService {
    if(!this._ds_purchase_orders_tasks_grid) {
      this._ds_purchase_orders_tasks_grid = this.injector.get(PurchaseOrders_ds_purchase_orders_tasks_gridService);
    }
    return this._ds_purchase_orders_tasks_grid;
  }
  private _ds_reasoncodes_dd: PurchaseOrders_ds_reasoncodes_ddService;
  public get ds_reasoncodes_dd(): PurchaseOrders_ds_reasoncodes_ddService {
    if(!this._ds_reasoncodes_dd) {
      this._ds_reasoncodes_dd = this.injector.get(PurchaseOrders_ds_reasoncodes_ddService);
    }
    return this._ds_reasoncodes_dd;
  }
  private _ds_received_inventory_grid: PurchaseOrders_ds_received_inventory_gridService;
  public get ds_received_inventory_grid(): PurchaseOrders_ds_received_inventory_gridService {
    if(!this._ds_received_inventory_grid) {
      this._ds_received_inventory_grid = this.injector.get(PurchaseOrders_ds_received_inventory_gridService);
    }
    return this._ds_received_inventory_grid;
  }
  private _ds_receiving_details_report: PurchaseOrders_ds_receiving_details_reportService;
  public get ds_receiving_details_report(): PurchaseOrders_ds_receiving_details_reportService {
    if(!this._ds_receiving_details_report) {
      this._ds_receiving_details_report = this.injector.get(PurchaseOrders_ds_receiving_details_reportService);
    }
    return this._ds_receiving_details_report;
  }
  private _ds_receiving_details_report_flow_based: PurchaseOrders_ds_receiving_details_report_flow_basedService;
  public get ds_receiving_details_report_flow_based(): PurchaseOrders_ds_receiving_details_report_flow_basedService {
    if(!this._ds_receiving_details_report_flow_based) {
      this._ds_receiving_details_report_flow_based = this.injector.get(PurchaseOrders_ds_receiving_details_report_flow_basedService);
    }
    return this._ds_receiving_details_report_flow_based;
  }
  private _ds_receiving_header_report: PurchaseOrders_ds_receiving_header_reportService;
  public get ds_receiving_header_report(): PurchaseOrders_ds_receiving_header_reportService {
    if(!this._ds_receiving_header_report) {
      this._ds_receiving_header_report = this.injector.get(PurchaseOrders_ds_receiving_header_reportService);
    }
    return this._ds_receiving_header_report;
  }
  private _ds_shipment_payment_term_dd: PurchaseOrders_ds_shipment_payment_term_ddService;
  public get ds_shipment_payment_term_dd(): PurchaseOrders_ds_shipment_payment_term_ddService {
    if(!this._ds_shipment_payment_term_dd) {
      this._ds_shipment_payment_term_dd = this.injector.get(PurchaseOrders_ds_shipment_payment_term_ddService);
    }
    return this._ds_shipment_payment_term_dd;
  }
  private _ds_tags_dd: PurchaseOrders_ds_tags_ddService;
  public get ds_tags_dd(): PurchaseOrders_ds_tags_ddService {
    if(!this._ds_tags_dd) {
      this._ds_tags_dd = this.injector.get(PurchaseOrders_ds_tags_ddService);
    }
    return this._ds_tags_dd;
  }
  private _ds_warehouses_dd: PurchaseOrders_ds_warehouses_ddService;
  public get ds_warehouses_dd(): PurchaseOrders_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(PurchaseOrders_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
}

