import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_billing_contract_editor_ComponentContextService } from './FootprintManager.billing_contract_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_billing_contract_lines_gridComponent } from './FootprintManager.billing_contract_lines_grid.component';
import { Invoices_invoicing_rules_gridComponent } from './Invoices.invoicing_rules_grid.component';
import { Invoices_billing_contract_lines_widgetComponent } from './Invoices.billing_contract_lines_widget.component';
import { Invoices_billing_contract_total_widgetComponent } from './Invoices.billing_contract_total_widget.component';
import { Invoices_invoice_terms_dd_singleComponent } from './Invoices.invoice_terms_dd_single.component'
import { Invoices_currencies_dd_singleComponent } from './Invoices.currencies_dd_single.component'
import { Invoices_tax_schedules_dd_singleComponent } from './Invoices.tax_schedules_dd_single.component'
import { Invoices_renewal_types_dd_singleComponent } from './Invoices.renewal_types_dd_single.component'

type EntityType = { 
    Id?: number, AccountId?: number, AutoRenewalPercentage?: number, AutoRenewalTypeId?: number, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, CurrencyId?: number, EndDate?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Name?: string, Notes?: string, ProjectId?: number, StartDate?: string, StatusId?: number, TaxScheduleId?: number, TermId?: number, Account?: { Name?: string }, Project?: { Name?: string, Owner?: { Name?: string } }, Status?: { Name?: string }, AutoRenewalType?: { Name?: string }, Currency?: { Label?: string }, TaxSchedule?: { Label?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_billing_contract_lines_gridComponent),
    forwardRef(() => Invoices_invoicing_rules_gridComponent),
    forwardRef(() => Invoices_billing_contract_lines_widgetComponent),
    forwardRef(() => Invoices_billing_contract_total_widgetComponent),
    forwardRef(() => Invoices_invoice_terms_dd_singleComponent),
    forwardRef(() => Invoices_currencies_dd_singleComponent),
    forwardRef(() => Invoices_tax_schedules_dd_singleComponent),
    forwardRef(() => Invoices_renewal_types_dd_singleComponent),
  ],
  selector: 'FootprintManager-billing_contract_editor',
  templateUrl: './FootprintManager.billing_contract_editor.component.html'
})
export class FootprintManager_billing_contract_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { contractId: number, edit?: boolean } = { contractId: null, edit: null };
  //#region Inputs
  @Input('contractId') set $inParams_contractId(v: number) {
    this.inParams.contractId = v;
  }
  get $inParams_contractId(): number {
    return this.inParams.contractId;
  }
  @Input('edit') set $inParams_edit(v: boolean) {
    this.inParams.edit = v;
  }
  get $inParams_edit(): boolean {
    return this.inParams.edit;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { edit?: boolean, line_updated?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    id: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    account: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    billing_terms: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    start_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    end_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    created_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_by: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    modified_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    modified_by: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    currency: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    tax: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    renewal_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    renewal_percentage: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      save_contract: new ToolModel(new ButtonModel('save_contract', new ButtonStyles(['primary'], null), false, false, false, 'Save contract', 'icon-ic_fluent_save_20_regular', null)
    , false),
      edit_contract: new ToolModel(new ButtonModel('edit_contract', new ButtonStyles(['primary'], null), false, false, false, 'Edit contract', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      activate_contract: new ToolModel(new ButtonModel('activate_contract', new ButtonStyles(null, null), false, false, false, 'Activate contract', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      deactivate_contract: new ToolModel(new ButtonModel('deactivate_contract', new ButtonStyles(['destructive'], null), false, false, false, 'Deactivate contract', 'icon-ic_fluent_prohibited_20_regular', null)
    , false),
      copy_contract: new ToolModel(new ButtonModel('copy_contract', new ButtonStyles(null, null), false, false, false, 'Copy contract', 'icon-ic_fluent_copy_20_regular', null)
    , false)
  };

  fields = {
    id: new FieldModel(new NumberBoxModel(this.formGroup.controls['id'] as DatexFormControl, null, true, false, '', '', null)
, new ControlContainerStyles(null, null), 'ID', false, false),
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Name', true, false),
    owner: new FieldModel(new TextBoxModel(this.formGroup.controls['owner'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new TextBoxModel(this.formGroup.controls['project'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    account: new FieldModel(new TextBoxModel(this.formGroup.controls['account'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Account', false, false),
    status: new FieldModel(new TextBoxModel(this.formGroup.controls['status'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
    billing_terms: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_terms'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Billing terms', false, false),
    start_date: new FieldModel(new DateBoxModel(this.formGroup.controls['start_date'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'Start date', true, false),
    end_date: new FieldModel(new DateBoxModel(this.formGroup.controls['end_date'] as DatexFormControl, null, false, false, '', 'date', null)
, new ControlContainerStyles(null, null), 'End date', true, false),
    created_date: new FieldModel(new TextBoxModel(this.formGroup.controls['created_date'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Created date', false, false),
    created_by: new FieldModel(new TextBoxModel(this.formGroup.controls['created_by'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Created by', false, false),
    modified_date: new FieldModel(new TextBoxModel(this.formGroup.controls['modified_date'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Modified date', false, false),
    modified_by: new FieldModel(new TextBoxModel(this.formGroup.controls['modified_by'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Modified by', false, false),
    currency: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['currency'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Currency', true, false),
    tax: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['tax'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Tax schedule', true, false),
    renewal_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['renewal_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Renewal type', false, false),
    renewal_percentage: new FieldModel(new NumberBoxModel(this.formGroup.controls['renewal_percentage'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Renewal percentage', true, false),
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), ' ', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Billing contract',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    newGroup2: new FieldsetModel(
      'Billing contract dates',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    newGroup3: new FieldsetModel(
      'Billing contract details',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    notes: new FieldsetModel(
      'Notes',
      false,
      true,
      false,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      accessorial_lines: new TabItemModel(
        this.rootTabGroup, 
        'Accessorial', 
        false,
        ),
      inbound_charges: new TabItemModel(
        this.rootTabGroup, 
        'Inbound', 
        false,
        ),
      initial_charges: new TabItemModel(
        this.rootTabGroup, 
        'Initial', 
        false,
        ),
      outbound_charges: new TabItemModel(
        this.rootTabGroup, 
        'Outbound', 
        false,
        ),
      recurring_charges: new TabItemModel(
        this.rootTabGroup, 
        'Recurring', 
        false,
        ),
      retainier_charges: new TabItemModel(
        this.rootTabGroup, 
        'Retainer', 
        false,
        ),
      auto_invoicing_rules: new TabItemModel(
        this.rootTabGroup, 
        'Auto-invoicing rules', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_accessorial_lines_billing_contract_lines_grid_inParams_contractId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.contractId;
      
      return expr;
    }
  
    get $tabs_accessorial_lines_billing_contract_lines_grid_inParams_strategyName(): string {
      const $editor = this;
      const $utils = this.$utils;
      const expr = 'Accessorial';
      
      return expr;
    }
  
    get $tabs_accessorial_lines_billing_contract_lines_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.edit;
      
      return expr;
    }
  
    cacheValueFor_$tabs_accessorial_lines_billing_contract_lines_grid_inParams_hide: string[];
    get $tabs_accessorial_lines_billing_contract_lines_grid_inParams_hide(): string[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = ['Priority'];
      
      if(!isEqual(this.cacheValueFor_$tabs_accessorial_lines_billing_contract_lines_grid_inParams_hide, expr)) {
        this.cacheValueFor_$tabs_accessorial_lines_billing_contract_lines_grid_inParams_hide = expr;
      }
      return this.cacheValueFor_$tabs_accessorial_lines_billing_contract_lines_grid_inParams_hide;
    }
  
    get $tabs_accessorial_lines_billing_contract_lines_grid_inParams_statusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.StatusId;
      
      return expr;
    }
  
    get $tabs_inbound_charges_billing_contract_lines_grid_inParams_contractId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.contractId;
      
      return expr;
    }
  
    get $tabs_inbound_charges_billing_contract_lines_grid_inParams_strategyName(): string {
      const $editor = this;
      const $utils = this.$utils;
      const expr = 'Inbound';
      
      return expr;
    }
  
    get $tabs_inbound_charges_billing_contract_lines_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.edit;
      
      return expr;
    }
  
    cacheValueFor_$tabs_inbound_charges_billing_contract_lines_grid_inParams_hide: string[];
    get $tabs_inbound_charges_billing_contract_lines_grid_inParams_hide(): string[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = ['OperationCode','Priority'];
      
      if(!isEqual(this.cacheValueFor_$tabs_inbound_charges_billing_contract_lines_grid_inParams_hide, expr)) {
        this.cacheValueFor_$tabs_inbound_charges_billing_contract_lines_grid_inParams_hide = expr;
      }
      return this.cacheValueFor_$tabs_inbound_charges_billing_contract_lines_grid_inParams_hide;
    }
  
    get $tabs_inbound_charges_billing_contract_lines_grid_inParams_statusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.StatusId;
      
      return expr;
    }
  
    get $tabs_initial_charges_billing_contract_lines_grid_inParams_contractId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.contractId;
      
      return expr;
    }
  
    get $tabs_initial_charges_billing_contract_lines_grid_inParams_strategyName(): string {
      const $editor = this;
      const $utils = this.$utils;
      const expr = 'Initial';
      
      return expr;
    }
  
    get $tabs_initial_charges_billing_contract_lines_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.edit;
      
      return expr;
    }
  
    cacheValueFor_$tabs_initial_charges_billing_contract_lines_grid_inParams_hide: string[];
    get $tabs_initial_charges_billing_contract_lines_grid_inParams_hide(): string[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = ['OperationCode','Priority'];
      
      if(!isEqual(this.cacheValueFor_$tabs_initial_charges_billing_contract_lines_grid_inParams_hide, expr)) {
        this.cacheValueFor_$tabs_initial_charges_billing_contract_lines_grid_inParams_hide = expr;
      }
      return this.cacheValueFor_$tabs_initial_charges_billing_contract_lines_grid_inParams_hide;
    }
  
    get $tabs_initial_charges_billing_contract_lines_grid_inParams_statusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.StatusId;
      
      return expr;
    }
  
    get $tabs_outbound_charges_billing_contract_lines_grid_inParams_contractId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.contractId;
      
      return expr;
    }
  
    get $tabs_outbound_charges_billing_contract_lines_grid_inParams_strategyName(): string {
      const $editor = this;
      const $utils = this.$utils;
      const expr = 'Outbound';
      
      return expr;
    }
  
    get $tabs_outbound_charges_billing_contract_lines_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.edit;
      
      return expr;
    }
  
    cacheValueFor_$tabs_outbound_charges_billing_contract_lines_grid_inParams_hide: string[];
    get $tabs_outbound_charges_billing_contract_lines_grid_inParams_hide(): string[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = ['OperationCode','Priority'];
      
      if(!isEqual(this.cacheValueFor_$tabs_outbound_charges_billing_contract_lines_grid_inParams_hide, expr)) {
        this.cacheValueFor_$tabs_outbound_charges_billing_contract_lines_grid_inParams_hide = expr;
      }
      return this.cacheValueFor_$tabs_outbound_charges_billing_contract_lines_grid_inParams_hide;
    }
  
    get $tabs_outbound_charges_billing_contract_lines_grid_inParams_statusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.StatusId;
      
      return expr;
    }
  
    get $tabs_recurring_charges_billing_contract_lines_grid_inParams_contractId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.contractId;
      
      return expr;
    }
  
    get $tabs_recurring_charges_billing_contract_lines_grid_inParams_strategyName(): string {
      const $editor = this;
      const $utils = this.$utils;
      const expr = 'Recurring';
      
      return expr;
    }
  
    get $tabs_recurring_charges_billing_contract_lines_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.edit;
      
      return expr;
    }
  
    cacheValueFor_$tabs_recurring_charges_billing_contract_lines_grid_inParams_hide: string[];
    get $tabs_recurring_charges_billing_contract_lines_grid_inParams_hide(): string[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = ['OperationCode','Priority'];
      
      if(!isEqual(this.cacheValueFor_$tabs_recurring_charges_billing_contract_lines_grid_inParams_hide, expr)) {
        this.cacheValueFor_$tabs_recurring_charges_billing_contract_lines_grid_inParams_hide = expr;
      }
      return this.cacheValueFor_$tabs_recurring_charges_billing_contract_lines_grid_inParams_hide;
    }
  
    get $tabs_recurring_charges_billing_contract_lines_grid_inParams_statusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.StatusId;
      
      return expr;
    }
  
    get $tabs_retainier_charges_billing_contract_lines_grid_inParams_contractId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.contractId;
      
      return expr;
    }
  
    get $tabs_retainier_charges_billing_contract_lines_grid_inParams_strategyName(): string {
      const $editor = this;
      const $utils = this.$utils;
      const expr = 'Retainer';
      
      return expr;
    }
  
    get $tabs_retainier_charges_billing_contract_lines_grid_inParams_edit(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.edit;
      
      return expr;
    }
  
    cacheValueFor_$tabs_retainier_charges_billing_contract_lines_grid_inParams_hide: string[];
    get $tabs_retainier_charges_billing_contract_lines_grid_inParams_hide(): string[] {
      const $editor = this;
      const $utils = this.$utils;
      const expr = ['OperationCode','Priority'];
      
      if(!isEqual(this.cacheValueFor_$tabs_retainier_charges_billing_contract_lines_grid_inParams_hide, expr)) {
        this.cacheValueFor_$tabs_retainier_charges_billing_contract_lines_grid_inParams_hide = expr;
      }
      return this.cacheValueFor_$tabs_retainier_charges_billing_contract_lines_grid_inParams_hide;
    }
  
    get $tabs_retainier_charges_billing_contract_lines_grid_inParams_statusId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.StatusId;
      
      return expr;
    }
  
    get $tabs_auto_invoicing_rules_invoicing_rules_grid_inParams_projectId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity?.ProjectId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_accessorial_lines', { read: FootprintManager_billing_contract_lines_gridComponent }) $tabs_accessorial_lines: FootprintManager_billing_contract_lines_gridComponent;
      @ViewChild('$tabs_inbound_charges', { read: FootprintManager_billing_contract_lines_gridComponent }) $tabs_inbound_charges: FootprintManager_billing_contract_lines_gridComponent;
      @ViewChild('$tabs_initial_charges', { read: FootprintManager_billing_contract_lines_gridComponent }) $tabs_initial_charges: FootprintManager_billing_contract_lines_gridComponent;
      @ViewChild('$tabs_outbound_charges', { read: FootprintManager_billing_contract_lines_gridComponent }) $tabs_outbound_charges: FootprintManager_billing_contract_lines_gridComponent;
      @ViewChild('$tabs_recurring_charges', { read: FootprintManager_billing_contract_lines_gridComponent }) $tabs_recurring_charges: FootprintManager_billing_contract_lines_gridComponent;
      @ViewChild('$tabs_retainier_charges', { read: FootprintManager_billing_contract_lines_gridComponent }) $tabs_retainier_charges: FootprintManager_billing_contract_lines_gridComponent;
      @ViewChild('$tabs_auto_invoicing_rules', { read: Invoices_invoicing_rules_gridComponent }) $tabs_auto_invoicing_rules: Invoices_invoicing_rules_gridComponent;
    //#endregion tabs children
    widgets = {
      line_count: new WidgetModel(false),
      contract_total: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_line_count_inParams_contractId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.contractId;
      
      return expr;
    }
  
    get $widgets_contract_total_inParams_contractId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.contractId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_line_count', { read:  Invoices_billing_contract_lines_widgetComponent }) $widgets_line_count: Invoices_billing_contract_lines_widgetComponent;
      @ViewChild('$widgets_contract_total', { read:  Invoices_billing_contract_total_widgetComponent }) $widgets_contract_total: Invoices_billing_contract_total_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    id: this.fields.id.control.valueChanges
    ,
    name: this.fields.name.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    account: this.fields.account.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    billing_terms: this.fields.billing_terms.control.valueChanges
    ,
    start_date: this.fields.start_date.control.valueChanges
    ,
    end_date: this.fields.end_date.control.valueChanges
    ,
    created_date: this.fields.created_date.control.valueChanges
    ,
    created_by: this.fields.created_by.control.valueChanges
    ,
    modified_date: this.fields.modified_date.control.valueChanges
    ,
    modified_by: this.fields.modified_by.control.valueChanges
    ,
    currency: this.fields.currency.control.valueChanges
    ,
    tax: this.fields.tax.control.valueChanges
    ,
    renewal_type: this.fields.renewal_type.control.valueChanges
    ,
    renewal_percentage: this.fields.renewal_percentage.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_billing_contract_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.accessorial_lines,
      this.tabs.inbound_charges,
      this.tabs.initial_charges,
      this.tabs.outbound_charges,
      this.tabs.recurring_charges,
      this.tabs.retainier_charges,
      this.tabs.auto_invoicing_rules,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.contractId)) {
        this.$missingRequiredInParams.push('contractId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Billing Contract';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      contractId:  $editor.inParams.contractId 
    };

    const data = await this.$datasources.Invoices.ds_billing_contracts_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.id.control as NumberBoxModel).reset($editor.entity.Id);
    (this.fields.name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.owner.control as TextBoxModel).reset($editor.entity.Project?.Owner?.Name);
    (this.fields.project.control as TextBoxModel).reset($editor.entity.Project?.Name);
    (this.fields.account.control as TextBoxModel).reset($editor.entity.Account?.Name);
    (this.fields.status.control as TextBoxModel).reset($editor.entity.Status?.Name);
    (this.fields.billing_terms.control as SelectBoxModel).reset($editor.entity.TermId);
    (this.fields.start_date.control as DateBoxModel).reset($editor.entity.StartDate);
    (this.fields.end_date.control as DateBoxModel).reset($editor.entity.EndDate);
    
    (this.fields.created_by.control as TextBoxModel).reset($editor.entity.CreatedSysUser);
    
    (this.fields.modified_by.control as TextBoxModel).reset($editor.entity.ModifiedSysUser);
    (this.fields.currency.control as SelectBoxModel).reset($editor.entity.CurrencyId);
    (this.fields.tax.control as SelectBoxModel).reset($editor.entity.TaxScheduleId);
    (this.fields.renewal_type.control as SelectBoxModel).reset($editor.entity.AutoRenewalTypeId);
    (this.fields.renewal_percentage.control as NumberBoxModel).reset($editor.entity.AutoRenewalPercentage);
    (this.fields.notes.control as TextBoxModel).reset($editor.entity.Notes);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_line_count') {
      if (!isNil(this.$widgets_line_count) && !this.widgets.line_count.hidden && !this.widgets.line_count.removed) {
        this.$widgets_line_count.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_contract_total') {
      if (!isNil(this.$widgets_contract_total) && !this.widgets.contract_total.hidden && !this.widgets.contract_total.removed) {
        this.$widgets_contract_total.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_accessorial_lines') {
        if (!isNil(this.$tabs_accessorial_lines) && !this.tabs.accessorial_lines.hidden && !this.tabs.accessorial_lines.removed) {
          this.$tabs_accessorial_lines.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_inbound_charges') {
        if (!isNil(this.$tabs_inbound_charges) && !this.tabs.inbound_charges.hidden && !this.tabs.inbound_charges.removed) {
          this.$tabs_inbound_charges.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_initial_charges') {
        if (!isNil(this.$tabs_initial_charges) && !this.tabs.initial_charges.hidden && !this.tabs.initial_charges.removed) {
          this.$tabs_initial_charges.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_outbound_charges') {
        if (!isNil(this.$tabs_outbound_charges) && !this.tabs.outbound_charges.hidden && !this.tabs.outbound_charges.removed) {
          this.$tabs_outbound_charges.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_recurring_charges') {
        if (!isNil(this.$tabs_recurring_charges) && !this.tabs.recurring_charges.hidden && !this.tabs.recurring_charges.removed) {
          this.$tabs_recurring_charges.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_retainier_charges') {
        if (!isNil(this.$tabs_retainier_charges) && !this.tabs.retainier_charges.hidden && !this.tabs.retainier_charges.removed) {
          this.$tabs_retainier_charges.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_auto_invoicing_rules') {
        if (!isNil(this.$tabs_auto_invoicing_rules) && !this.tabs.auto_invoicing_rules.hidden && !this.tabs.auto_invoicing_rules.removed) {
          this.$tabs_auto_invoicing_rules.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .project
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .account
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .billing_terms
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .start_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .end_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .created_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .created_by
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .modified_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .modified_by
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .currency
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .tax
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .renewal_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .renewal_percentage
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootprintManager_billing_contract_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/10/2024
  
  if ($editor.inParams.edit) {
      $editor.vars.edit = true;
  } else {
      $editor.vars.edit = false;
  };
  
  
  $editor.toolbar.activate_contract.hidden = (await $operations.FootprintManager.Disable_Billing_Contract_Activation.isAuthorized());
  $editor.toolbar.deactivate_contract.hidden = (await $operations.FootprintManager.Disable_Billing_Contract_Deactivation.isAuthorized());
  $editor.toolbar.copy_contract.hidden = (await $operations.FootprintManager.Disable_Billing_ContractCopying.isAuthorized());
  $editor.toolbar.edit_contract.hidden = (await $operations.FootprintManager.Disable_Billing_ContractEditing.isAuthorized());
  $editor.toolbar.save_contract.hidden = (await $operations.FootprintManager.Disable_Billing_ContractEditing.isAuthorized());
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootprintManager_billing_contract_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 10/19/2023
  
  // Set editor title
  $editor.title = `Billing contract ${$editor.entity.Name}`;
  
  // Set Date/Time Formats
  const format = `${$settings.FootprintManager.DateFormat}, ${$settings.FootprintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
  
      if ($utils.isDefined($editor.entity.CreatedSysDateTime)) {
          $editor.fields.created_date.control.value = $utils.date.format($editor.entity.CreatedSysDateTime, format);
      };
  
      if ($utils.isDefined($editor.entity.ModifiedSysDateTime)) {
          $editor.fields.modified_date.control.value = $utils.date.format($editor.entity.ModifiedSysDateTime, format);
      };
  
  };
  
  //All fields to be read only from the start
  $editor.fields.name.control.readOnly = true;
  $editor.fields.billing_terms.control.readOnly = true;
  $editor.fields.owner.control.readOnly = true;
  $editor.fields.project.control.readOnly = true;
  $editor.fields.account.control.readOnly = true;
  $editor.fields.status.control.readOnly = true;
  $editor.fields.created_date.control.readOnly = true;
  $editor.fields.created_by.control.readOnly = true;
  $editor.fields.modified_date.control.readOnly = true;
  $editor.fields.modified_by.control.readOnly = true;
  $editor.fields.start_date.control.readOnly = true;
  $editor.fields.end_date.control.readOnly = true;
  $editor.fields.notes.control.readOnly = true;
  $editor.fields.renewal_type.control.readOnly = true;
  $editor.fields.renewal_percentage.control.readOnly = true;
  $editor.fields.currency.control.readOnly = true;
  $editor.fields.tax.control.readOnly = true;
  
  //If the account is not defined then disable the fields
  if (!$utils.isDefined($editor.entity.AccountId)) {
  
      disableComponent($editor.fields.account);
  
  };
  
  //If the project is not defined then disable the fields
  if (!$utils.isDefined($editor.entity.ProjectId)) {
  
      disableComponent($editor.fields.owner);
      disableComponent($editor.fields.project);
  
  };
  
  //2:Created
  if ($editor.entity.StatusId === 2) {
  
      enableComponent($editor.toolbar.edit_contract);
      enableComponent($editor.toolbar.activate_contract);
      enableComponent($editor.toolbar.deactivate_contract);
  
      disableComponent($editor.toolbar.save_contract);
  };
  
  //1:Active
  if ($editor.entity.StatusId === 1) {
  
      enableComponent($editor.toolbar.deactivate_contract);
      //Let's allow editing for now
      enableComponent($editor.toolbar.edit_contract);
  
      //disableComponent($editor.toolbar.edit_contract);
      disableComponent($editor.toolbar.save_contract);
      disableComponent($editor.toolbar.activate_contract);
  };
  
  //3:Inactive
  if ($editor.entity.StatusId === 3) {
  
      disableComponent($editor.toolbar.edit_contract);
      disableComponent($editor.toolbar.save_contract);
      disableComponent($editor.toolbar.activate_contract);
      disableComponent($editor.toolbar.deactivate_contract);
  };
  
  //If the edit variable is on
  if ($editor.vars.edit) {
  
      await $editor.on_edit_clicked();
  
  };
  
  if ($editor.entity.Notes?.length > 0) { 
  
      $editor.fieldsets.notes.expanded = true; 
  
  } 
  /****FUNCTIONS****/
  
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  }
  
  
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  
  }
  
  
  $editor.toolbar.activate_contract.hidden = (await $operations.FootprintManager.Disable_Billing_Contract_Activation.isAuthorized());
  $editor.toolbar.deactivate_contract.hidden = (await $operations.FootprintManager.Disable_Billing_Contract_Deactivation.isAuthorized());
  $editor.toolbar.copy_contract.hidden = (await $operations.FootprintManager.Disable_Billing_ContractCopying.isAuthorized());
  $editor.toolbar.edit_contract.hidden = (await $operations.FootprintManager.Disable_Billing_ContractEditing.isAuthorized());
  $editor.toolbar.save_contract.hidden = (await $operations.FootprintManager.Disable_Billing_ContractEditing.isAuthorized());
  }
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $editor: FootprintManager_billing_contract_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/01/2024
  
  try {
  
      /*
      //Disabling date validation, allowing this to be done in the activate contract action
  
      let result: any;
      let date_validation: any;
  
      if ($editor.entity.StatusId !== 1) {
          //Run the date validation
          result = await $flows.Invoices.are_billing_contract_dates_valid_flow({
              contractId: $editor.entity.Id,
              projectId: $editor.entity.ProjectId,
              accountId: $editor.entity.AccountId,
              startDate: $editor.fields.start_date.control.value,
              endDate: $editor.fields.end_date.control.value
          });
          if ($utils.isDefined(result)) {
              date_validation = result.result;
          }
      }
  
      if ($utils.isDefined(date_validation)) {
  
          await $shell.FootprintManager.openErrorDialog('Billing contract cannot be updated.', date_validation);
          return;
  
      } else {
      */
  
      //Set the fields to read only, but not hidden
      $editor.fields.name.control.readOnly = true;
      $editor.fields.renewal_type.control.readOnly = true;
      $editor.fields.renewal_percentage.control.readOnly = true;
      $editor.fields.currency.control.readOnly = true;
      $editor.fields.tax.control.readOnly = true;
      $editor.fields.start_date.control.readOnly = true;
      $editor.fields.end_date.control.readOnly = true;
      $editor.fields.notes.control.readOnly = true;
  
      //Disable the save button
      disableComponent($editor.toolbar.save_contract);
  
      //Enable the edit button
      enableComponent($editor.toolbar.edit_contract);
  
      //Enable the activate button
      enableComponent($editor.toolbar.activate_contract);
  
      //Enable the deactivate button
      enableComponent($editor.toolbar.deactivate_contract);
  
      //Enable the copy button
      enableComponent($editor.toolbar.copy_contract);
  
      //Set the edit variable off
      $editor.vars.edit = false;
  
      //Build the contract payload
      let payload: any = {}
  
      if ($editor.fields.name.control.isChanged) {
          payload.Name = $editor.fields.name.control.value;
      }
      if ($editor.fields.renewal_type.control.isChanged) {
          payload.AutoRenewalTypeId = $editor.fields.renewal_type.control.value;
      }
      if ($editor.fields.renewal_percentage.control.isChanged) {
          payload.AutoRenewalPercentage = $editor.fields.renewal_percentage.control.value;
      }
      if ($editor.fields.currency.control.isChanged) {
          payload.CurrencyId = $editor.fields.currency.control.value;
      }
      if ($editor.fields.tax.control.isChanged) {
          payload.TaxScheduleId = $editor.fields.tax.control.value;
      }
      if ($editor.fields.start_date.control.isChanged) {
          payload.StartDate = $editor.fields.start_date.control.value;
      }
      if ($editor.fields.end_date.control.isChanged) {
          payload.EndDate = $editor.fields.end_date.control.value;
      }
      if ($editor.fields.notes.control.isChanged) {
          payload.Notes = $editor.fields.notes.control.value;
      }
      if ($editor.fields.billing_terms.control.isChanged) {
          payload.TermId = $editor.fields.billing_terms.control.value;
      }
  
      if (!$utils.isDefined(payload) && $editor.vars.line_updated) {
          payload.Id = $editor.entity.Id;
      }
  
      if ($utils.isDefined(payload)) {
          await $flows.Utilities.crud_update_flow({ entitySet: 'BillingContracts', id: $editor.entity.Id, entity: payload });
      }
  
      await $editor.refresh();
  
      //}
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `Billing Contract ${$editor.entity.Name} - ${errorMessage}`;
      await $shell.FootprintManager.openErrorDialog('Update billing contract error', 'An error occurred during updating of the contract', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  
  
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  }
  
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  }
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $editor: FootprintManager_billing_contract_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/25/2023
  
  //Enable fields for editing
  enableComponent($editor.fields.name);
  enableComponent($editor.fields.billing_terms);
  enableComponent($editor.fields.renewal_type);
  enableComponent($editor.fields.renewal_percentage);
  enableComponent($editor.fields.currency);
  enableComponent($editor.fields.tax);
  enableComponent($editor.fields.start_date);
  enableComponent($editor.fields.end_date);
  enableComponent($editor.fields.notes);
  
  //Enable the save button
  enableComponent($editor.toolbar.save_contract);
  
  //Disable the edit button
  disableComponent($editor.toolbar.edit_contract);
  
  //Disable the activate button
  disableComponent($editor.toolbar.activate_contract);
  
  //Disable the deactivate button
  disableComponent($editor.toolbar.deactivate_contract);
  
  //Disable the copy button
  disableComponent($editor.toolbar.copy_contract);
  
  //Enable edit
  $editor.vars.edit = true;
  
  /****FUNCTIONS****/
  
  function disableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = true;
      }
      component.hidden = true;
  }
  function enableComponent(component: any) {
      if ($utils.isDefined(component?.control?.readOnly)) {
          component.control.readOnly = false;
      }
      component.hidden = false;
  
  }
  }
  on_activate_clicked(event = null) {
    return this.on_activate_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_activate_clickedInternal(
    $editor: FootprintManager_billing_contract_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/04/2023
  
  try {
  
      let reason = [];
  
      //Validate billing contract lines
      let reasons = (await $flows.Invoices.is_billing_contract_line_valid_flow({
          billingContractId: $editor.entity.Id,
          billingContractLineIds: null //We're validating each of the lines
      }));
  
      if ($utils.isDefined(reasons?.reasons)) {
          reason.push(...reasons.reasons);
      };
  
      if (reason.length > 0) {
          await $shell.FootprintManager.openErrorDialog('Billing contract cannot be activated.', reason.join(' '));
          return;
      }
  
      const confirm = (await $shell.FootprintManager.openConfirmationDialog(`Confirm activation`, `Activate contract ${$editor.entity.Name}?`, `Activate`, `Cancel`));
  
      if (confirm) {
          let result = (await $flows.Invoices.activate_billing_contract_flow({ contractId: $editor.entity.Id }));
  
          if (!$utils.isDefined(result)) {
              throw ("Error calling the [activate_billing_contract_flow] flow!");
          }
  
          if (!result.success) {
              throw ({ message: result.messages.length > 0 ? result.messages.join(',') : "Uncaught exception executing the [activate_billing_contract_flow] flow!" });
          };
      };
  
      await $editor.refresh();
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: "Uncaught exception!" };
      };
  
      const errorDescription = `Billing Contract ${$editor.entity.Name} - ${target_error.message}`;
      await $shell.FootprintManager.openErrorDialog('Activate billing contract error', 'An error occurred during activating of the contract', [errorDescription], null, null);
  }
  }
  on_deactivate_clicked(event = null) {
    return this.on_deactivate_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deactivate_clickedInternal(
    $editor: FootprintManager_billing_contract_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 12/04/2023
  
  try {
      const confirm = (await $shell.FootprintManager.openConfirmationDialog(`Confirm deactivation`, `Deactivate contract ${$editor.entity.Name}?`, `Deactivate`, `Cancel`));
  
      if (confirm) {
          let result = (await $flows.Invoices.deactivate_billing_contract_flow({ contractId: $editor.entity.Id }));
  
          if (!$utils.isDefined(result)) {
              throw ({ message: "Error calling the [deactivate_billing_contract_flow] flow!" });
          }
  
          if (!result.success) {
              throw ({ message: result.messages.length > 0 ? result.messages.join(',') : "Uncaught exception executing the [deactivate_billing_contract_flow] flow!" })
          };
      };
  
      await $editor.refresh();
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: "Uncaught exception!" };
      };
  
      const errorDescription = `Billing Contract ${$editor.entity.Name} - ${target_error.message}`;
      await $shell.FootprintManager.openErrorDialog('Deactivate billing contract error', 'An error occurred during deactivation of the contract', [errorDescription], null, null);
  }
  }
  on_copy_clicked(event = null) {
    return this.on_copy_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_copy_clickedInternal(
    $editor: FootprintManager_billing_contract_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 11/27/2023
  
  try {
      const copy = (await $shell.FootprintManager.openbilling_contract_creation_formDialog({ projectId: $editor.entity.ProjectId, ownerId: null, copy: true }));
  
      if ($utils.isDefined(copy.projectId)) {
          let result = (await $flows.Invoices.copy_billing_contract_flow({ contractId: $editor.entity.Id, projectId: copy.projectId, startDate: $editor.fields.start_date.control.value, endDate: $editor.fields.end_date.control.value }));
  
          if (!$utils.isDefined(result)) {
              throw ("Error calling the [copy_billing_contract_flow] flow!");
          }
  
          if (result.success) {
              const contractId = result.contractId;
              $shell.FootprintManager.openbilling_contract_editor({ contractId: contractId, edit: true });
          } else {
              throw ({ message: result.messages.length > 0 ? result.messages.join(',') : "Uncaught exception executing the [copy_billing_contract_flow] flow!"});
          };
      };
  
      await $editor.refresh();
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: "Uncaught exception!" };
      };
  
      const errorDescription = `Billing Contract ${$editor.entity.Name} - ${target_error.message}`;
      await $shell.FootprintManager.openErrorDialog('Copy billing contract error', 'An error occurred during copying of the contract', [errorDescription], null, null);
  };
  }
  on_line_updated(event = null) {
    return this.on_line_updatedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_line_updatedInternal(
    $editor: FootprintManager_billing_contract_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_billing_contract_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'billing_contract_editor.on_line_updated');
  //O.Arias - 07/01/2024
  
  /*
  if ($utils.isDefined($event.line_update?.line_updated)) {
  
      let updated: boolean = $event.line_update?.line_updated ?? false;
  
      if (updated) {
          $editor.vars.line_updated = true;
      } else {
          $shell.FootprintManager.openInfoDialog("Not", "Edited")
      }
  } else {
      $shell.FootprintManager.openInfoDialog("Not defined", "$event.line_update?.line_updated")
  }
  
  if (!$utils.isDefined($event.line_update)) {
      $shell.FootprintManager.openInfoDialog("Not defined", "$event.line_update")
  }
  
  
  if (!$utils.isDefined($event.line_updated)) {
      $shell.FootprintManager.openInfoDialog("Not defined", "$event.line_updated")
  }
  */
  }
  //#endregion private flows
  
}
