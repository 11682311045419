import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class AsnOrders_ds_get_receiving_tasks_by_orderIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, statusIds?: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossVolume?: number, GrossWeight?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Order?: { Id?: number, LookupCode?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { LookupCode?: string, Name?: string } }, ActualPackagedPack?: { Id?: number, Name?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { CreatedSysDateTime?: string, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, WeightUOM?: { Short_name?: string }, VolumeUOM?: { Short_name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { ShortName?: string }, OrderLine?: { PackagedAmount?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_receiving_tasks_by_orderId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, statusIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossVolume?: number, GrossWeight?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Order?: { Id?: number, LookupCode?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { LookupCode?: string, Name?: string } }, ActualPackagedPack?: { Id?: number, Name?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { CreatedSysDateTime?: string, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, WeightUOM?: { Short_name?: string }, VolumeUOM?: { Short_name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { ShortName?: string }, OrderLine?: { PackagedAmount?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_receiving_tasks_by_orderId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, statusIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, GrossVolume?: number, GrossWeight?: number, MaterialId?: number, NetVolume?: number, NetWeight?: number, Order?: { Id?: number, LookupCode?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { LookupCode?: string, Name?: string } }, ActualPackagedPack?: { Id?: number, Name?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { CreatedSysDateTime?: string, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, WeightUOM?: { Short_name?: string }, VolumeUOM?: { Short_name?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLocation?: { ShortName?: string }, OrderLine?: { PackagedAmount?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/datasources/ds_get_receiving_tasks_by_orderId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, statusIds?: number[]    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
