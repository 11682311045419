import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Returns_ds_return_order_lines_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, fullTextSearch?: string }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_return_order_lines_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_return_order_lines_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, fullTextSearch?: string, $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, StatusId?: number, VendorLotId?: number, WeightUomId?: number, Material?: { Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/datasources/ds_return_order_lines_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, fullTextSearch?: string    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
      return false;
    }
}
