import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ShipTheory_ds_get_items_contents_by_shipmentService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipment_id: number }): 
  Promise<{ result: { quantity?: number, price?: number, gross_weight?: number, Lot?: { Material?: { LookupCode?: string, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShipTheory/datasources/ds_get_items_contents_by_shipment/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipment_id: number, $top?: number, $skip?: number }): 
  Promise<{ result: { quantity?: number, price?: number, gross_weight?: number, Lot?: { Material?: { LookupCode?: string, Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShipTheory/datasources/ds_get_items_contents_by_shipment/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipment_id: number, $keys: { Lot?: { Material?: { LookupCode?: string, Name?: string } } }[] }): 
  Promise<{ result: { quantity?: number, price?: number, gross_weight?: number, Lot?: { Material?: { LookupCode?: string, Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipment_id)) {
      missingRequiredInParams.push('\'shipment_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShipTheory/datasources/ds_get_items_contents_by_shipment/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipment_id: number    }) {
        if(isNil(inParams.shipment_id)) {
          return true; 
        }
      return false;
    }
}
