import { Utilities_Types } from './Utilities.frontend.types'

export namespace Allocations_Types {
  /**
   * Enumeration of available allocation strategies.
   */
  export enum e_allocation_base_strategy {
    FEFO = 'FEFO',
    FIFO = 'FIFO',
    FMFO = 'FMFO',
    LEFO = 'LEFO',
    LIFO = 'LIFO',
    LMFO = 'LMFO'
  }
  /**
   * Enumeration of available configuration entities and their priority for allocations.
   */
  export enum e_allocation_configuration_entity {
    Material = 1,
    MaterialGroup = 2,
    OrderClass = 3,
    Account = 4,
    Project = 5,
    Owner = 6,
    Warehouse = 7,
    Global = 10000
  }
  /**
   * Enumeration of applicable fields to use for the date check of the days remaining logic.
   */
  export enum e_allocation_days_remaining_date_field {
    Order_RequestedDeliveryDate = 'Order.RequestedDeliveryDate',
    Shipment_ExpectedDate = 'Shipment.ExpectedDate'
  }
  /**
   * Enumeration of available options for full_pallets_first config for allocation.
   */
  export enum e_allocation_full_pallets_first {
    Disabled = 0,
    FollowStrategy = 1,
    SkipStrategy = 2
  }
  /**
   * Enumeration of available allocation package strategies
   */
  export enum e_allocation_package_strategy {
    RequestedOnly = 0,
    RequestedFirst = 1,
    LargestFirst = 2
  }
  /**
   * Enumeration of available context options when replenishing via allocation.
   */
  export enum e_allocation_replenishment_context {
    TopUp = 0,
    Schedule = 1,
    InLine = 2
  }
  /**
   * Enumeration of quantity options for replenishments via allocation.
   */
  export enum e_allocation_replenishment_quantity {
    ReplenishmentQuantity = 0,
    SmallestAvailableLicensePlate = 1,
    LargestAvailableLicensePlate = 2,
    FillToMaxCapacity = 3,
    RequestedQuantity = 4
  }
  /**
   * Enumeration of applicable Footprint configuration scopes.
   */
  export enum e_configuration_scope {
    Allocation = 'Allocation'
  }
  /**
   * Enumeration with the options for hard allocation context.
   */
  export enum e_hard_allocation_context {
    GenericHardAllocation = 1,
    CreateAndPlanReplenTasksAllocation = 2,
    GenerateManufacturingInventoryMoveTasksAllocation = 3,
    InventoryAdjustmentTasksGenerationForMaterialTransferLineAllocation = 4,
    InventoryPlanningTasksGenerationAllocation = 5,
    InventoryTransferAllocation = 6,
    ManufacturingLoadAnticipatedAllocationsAllocation = 7,
    MaterialTranferTasksAllocation = 8,
    PickTasksGenerationAllocation = 9,
    PickTasksGenerationReallocation = 10,
    InventoryTransformationAllocation = 11
  }
  /**
   * Represents the Configuration schema for allocation.
   */
  export interface i_allocation_config {
    strategy?: { base: $frontendTypes.Allocations.e_allocation_base_strategy, packaging: $frontendTypes.Allocations.e_allocation_package_strategy, can_break_packagings: boolean, full_pallets_first: $frontendTypes.Allocations.e_allocation_full_pallets_first, use_primary_pick_locations: boolean, can_break_license_plates: boolean };
    replenishments?: { context: $frontendTypes.Allocations.e_allocation_replenishment_context, quantity: $frontendTypes.Allocations.e_allocation_replenishment_quantity };
    days_remaining?: { threshold: number, date_field: string };
    restrictions?: { allow_inactive: boolean, allow_expired: boolean, allowed_status_ids: number[] };
  }
}

export namespace $frontendTypes {
  export import Utilities =  Utilities_Types;
  export import Allocations = Allocations_Types;
}




