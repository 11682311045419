<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="create" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="create_workshift_flow($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="cancel_flow($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-group"
                     *ngIf="!fieldsets.group.hidden && !fieldsets.group.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.group.collapsible }">
                    <div *ngIf="!fieldsets.group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.group.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.group.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-workshift_name" *ngIf="!fields.workshift_name.hidden && !fields.workshift_name.removed" 
                            class="field-container standard {{fields.workshift_name.invalid ? 'invalid' : ''}} {{fields.workshift_name.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.workshift_name.styles.style"
                            [ngClass]="fields.workshift_name.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.workshift_name.label + (fields.workshift_name.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.workshift_name.label}}<span *ngIf="fields.workshift_name.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="workshift_name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.workshift_name.control.readOnly || fields.workshift_name.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.workshift_name.control.placeholder}}"
                                [ngStyle]="fields.workshift_name.control.styles.style"
                                [ngClass]="fields.workshift_name.control.styles.classes"
                                [matTooltip]="fields.workshift_name.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.workshift_name.invalid">
                          <ng-container *ngFor="let error of fields.workshift_name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-warehouse_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                          [warehouse_id]="$fields_warehouse_selector_inParams_warehouse_id"
                        >
                        </Manufacturing-warehouse_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-start_time" *ngIf="!fields.start_time.hidden && !fields.start_time.removed" 
                            class="field-container standard {{fields.start_time.invalid ? 'invalid' : ''}} {{fields.start_time.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.start_time.styles.style"
                            [ngClass]="fields.start_time.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.start_time.label + (fields.start_time.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.start_time.label}}<span *ngIf="fields.start_time.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-time_selector_dd_single 
                            data-cy="selector"
                            [type]="fields.start_time.control.type"
                            formControlName="start_time"
                            (displayTextChange)="fields.start_time.control.displayText=$event"
                            [placeholder]="fields.start_time.control.placeholder"
                            [styles]="fields.start_time.control.styles"
                            [tooltip]="fields.start_time.control.tooltip"
                          [intervalMinutes]="$fields_start_time_selector_inParams_intervalMinutes"
                        >
                        </Manufacturing-time_selector_dd_single>
                        <ng-container *ngIf="fields.start_time.invalid">
                          <ng-container *ngFor="let error of fields.start_time.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-end_time" *ngIf="!fields.end_time.hidden && !fields.end_time.removed" 
                            class="field-container standard {{fields.end_time.invalid ? 'invalid' : ''}} {{fields.end_time.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.end_time.styles.style"
                            [ngClass]="fields.end_time.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.end_time.label + (fields.end_time.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.end_time.label}}<span *ngIf="fields.end_time.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-time_selector_dd_single 
                            data-cy="selector"
                            [type]="fields.end_time.control.type"
                            formControlName="end_time"
                            (displayTextChange)="fields.end_time.control.displayText=$event"
                            [placeholder]="fields.end_time.control.placeholder"
                            [styles]="fields.end_time.control.styles"
                            [tooltip]="fields.end_time.control.tooltip"
                          [intervalMinutes]="$fields_end_time_selector_inParams_intervalMinutes"
                        >
                        </Manufacturing-time_selector_dd_single>
                        <ng-container *ngIf="fields.end_time.invalid">
                          <ng-container *ngFor="let error of fields.end_time.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shift_duration" *ngIf="!fields.shift_duration.hidden && !fields.shift_duration.removed" 
                            class="field-container standard {{fields.shift_duration.invalid ? 'invalid' : ''}} {{fields.shift_duration.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.shift_duration.styles.style"
                            [ngClass]="fields.shift_duration.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.shift_duration.label + (fields.shift_duration.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.shift_duration.label}}<span *ngIf="fields.shift_duration.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="shift_duration"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.shift_duration.control.readOnly || fields.shift_duration.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.shift_duration.control.placeholder}}"
                                [ngStyle]="fields.shift_duration.control.styles.style"
                                [ngClass]="fields.shift_duration.control.styles.classes"
                                [matTooltip]="fields.shift_duration.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.shift_duration.invalid">
                          <ng-container *ngFor="let error of fields.shift_duration.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-code" *ngIf="!fields.code.hidden && !fields.code.removed" 
                            class="field-container standard {{fields.code.invalid ? 'invalid' : ''}} {{fields.code.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.code.styles.style"
                            [ngClass]="fields.code.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.code.label + (fields.code.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.code.label}}<span *ngIf="fields.code.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="code"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.code.control.readOnly || fields.code.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.code.control.placeholder}}"
                                [ngStyle]="fields.code.control.styles.style"
                                [ngClass]="fields.code.control.styles.classes"
                                [matTooltip]="fields.code.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.code.invalid">
                          <ng-container *ngFor="let error of fields.code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-days" *ngIf="!fields.days.hidden && !fields.days.removed" 
                            class="field-container full {{fields.days.invalid ? 'invalid' : ''}} {{fields.days.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.days.styles.style"
                            [ngClass]="fields.days.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.days.label + (fields.days.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.days.label}}<span *ngIf="fields.days.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Manufacturing-days_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.days.control.type"
                            formControlName="days"
                            (displayTextChange)="fields.days.control.displayText=$event"
                            [placeholder]="fields.days.control.placeholder"
                            [styles]="fields.days.control.styles"
                            [tooltip]="fields.days.control.tooltip"
                        >
                        </Manufacturing-days_dd_multi>
                        <ng-container *ngIf="fields.days.invalid">
                          <ng-container *ngFor="let error of fields.days.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.group.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>