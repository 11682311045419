import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintManager_get_planService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { plan_id?: string }): Promise<{ name?: string, plan_id?: string, is_active?: boolean, in_params?: $frontendTypes.Manufacturing.CreateOrderCopiesParams, cron_expression?: string }> 
  {
    let url = `${environment.backendUrl}api/FootprintManager/functions/get_plan`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

