import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Dynamics365BusinessCentral_invoice_line_grid_ComponentContextService } from './Dynamics365BusinessCentral.invoice_line_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IDynamics365BusinessCentral_invoice_line_gridComponentEntity {
  LineNumber?: number, InvoiceId?: number, Name?: string, Quantity?: number, UnitCost?: number, UnitPrice?: number, LineCost?: number, LinePrice?: number, LineTotal?: number, Description?: string, Notes?: string}

interface IDynamics365BusinessCentral_invoice_line_gridComponentInParams {
  InvoiceId: number, line?: number}


class Dynamics365BusinessCentral_invoice_line_gridComponentRowModel extends GridRowModel {
  grid: Dynamics365BusinessCentral_invoice_line_gridComponent;
  entity: IDynamics365BusinessCentral_invoice_line_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    LineNumber: null,
    billingcode: null,
    Quantity: null,
    UnitCost: null,
    UnitPrice: null,
    LineCost: null,
    LinePrice: null,
    LineTotal: null,
    billingcodedescription: null,
    Notes: null,
  }



  constructor(
    grid: Dynamics365BusinessCentral_invoice_line_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Dynamics365BusinessCentral_ShellService,
private $datasources: Dynamics365BusinessCentral_DatasourceService,
private $flows: Dynamics365BusinessCentral_FlowService,
private $reports: Dynamics365BusinessCentral_ReportService,
private $localization: Dynamics365BusinessCentral_LocalizationService,
private $operations: Dynamics365BusinessCentral_OperationService,
private $logger: CleanupLoggerService,
private $context: Dynamics365BusinessCentral_invoice_line_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.LineNumber = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.LineNumber,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.billingcode = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.billingcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Quantity = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.Quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.UnitCost = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.UnitCost,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.UnitPrice = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.UnitPrice,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.LineCost = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.LineCost,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.LinePrice = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.LinePrice,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.LineTotal = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.LineTotal,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.billingcodedescription = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.billingcodedescription,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Notes = new GridCellModel(
      new CellStyles(['left'], null),
      this.grid.headers.Notes,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IDynamics365BusinessCentral_invoice_line_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.LineNumber].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IDynamics365BusinessCentral_invoice_line_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.LineNumber].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.LineNumber;
    const inParams = {
      $keys:[$resultKey],
      InvoiceId:  $grid.inParams.InvoiceId ,
    };
    const data = await this.$datasources.Dynamics365BusinessCentral.ds_get_linenumber_flow.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IDynamics365BusinessCentral_invoice_line_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.LineNumber.displayControl as TextModel).text = $row.entity.LineNumber?.toString();
    (this.cells.billingcode.displayControl as TextModel).text = $row.entity.Name;
    (this.cells.Quantity.displayControl as TextModel).text = $row.entity.Quantity?.toString();
    (this.cells.UnitCost.displayControl as TextModel).text = $row.entity?.UnitCost.toString() ?? '0';
    (this.cells.UnitPrice.displayControl as TextModel).text = $row.entity.UnitPrice?.toString() ?? '0';
    (this.cells.LineCost.displayControl as TextModel).text = $row.entity.LineCost?.toString();
    (this.cells.LinePrice.displayControl as TextModel).text = $row.entity.LinePrice?.toString();
    (this.cells.LineTotal.displayControl as TextModel).text = $row.entity.LineTotal?.toString();
    (this.cells.billingcodedescription.displayControl as TextModel).text = $row.entity.Description;
    (this.cells.Notes.displayControl as TextModel).text = $row.entity.Notes;

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Dynamics365BusinessCentral-invoice_line_grid',
  templateUrl: './Dynamics365BusinessCentral.invoice_line_grid.component.html'
})
export class Dynamics365BusinessCentral_invoice_line_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IDynamics365BusinessCentral_invoice_line_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', ['default']);

  fullTextSearch: string;

  inParams: IDynamics365BusinessCentral_invoice_line_gridComponentInParams = { InvoiceId: null, line: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     LineNumber: new GridHeaderModel(new HeaderStyles(['left'], null), 'LineNumber', 'Line', false, false, null, false),       billingcode: new GridHeaderModel(new HeaderStyles(['left'], null), 'billingcode', 'Name', false, false, null, false),       Quantity: new GridHeaderModel(new HeaderStyles(['left'], null), 'Quantity', 'Quantity', false, false, null, false),       UnitCost: new GridHeaderModel(new HeaderStyles(['left'], null), 'UnitCost', 'Unit cost', false, false, null, false),       UnitPrice: new GridHeaderModel(new HeaderStyles(['left'], null), 'UnitPrice', 'Unit price', false, false, null, false),       LineCost: new GridHeaderModel(new HeaderStyles(['left'], null), 'LineCost', 'Line cost', false, false, null, false),       LinePrice: new GridHeaderModel(new HeaderStyles(['left'], null), 'LinePrice', 'Line price', false, false, null, false),       LineTotal: new GridHeaderModel(new HeaderStyles(['left'], null), 'LineTotal', 'Line total', false, false, null, false),       billingcodedescription: new GridHeaderModel(new HeaderStyles(['left'], null), 'billingcodedescription', 'Description', false, false, null, false),       Notes: new GridHeaderModel(new HeaderStyles(['left'], null), 'Notes', 'Notes', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Dynamics365BusinessCentral_invoice_line_gridComponentRowModel[] = [];

  @Input('InvoiceId') set $inParams_InvoiceId(value: any) {
    this.inParams['InvoiceId'] = value;
  }
  get $inParams_InvoiceId(): any {
    return this.inParams['InvoiceId'] ;
  }
  @Input('line') set $inParams_line(value: any) {
    this.inParams['line'] = value;
  }
  get $inParams_line(): any {
    return this.inParams['line'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Dynamics365BusinessCentral_ShellService,
    private $datasources: Dynamics365BusinessCentral_DatasourceService,
    private $flows: Dynamics365BusinessCentral_FlowService,
    private $reports: Dynamics365BusinessCentral_ReportService,
    private $localization: Dynamics365BusinessCentral_LocalizationService,
    private $operations: Dynamics365BusinessCentral_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Dynamics365BusinessCentral_invoice_line_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Invoice lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.InvoiceId)) {
        this.$missingRequiredInParams.push('InvoiceId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 20;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      InvoiceId:  $grid.inParams.InvoiceId ,
    };
    try {
      const data = await this.$datasources.Dynamics365BusinessCentral.ds_get_linenumber_flow.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IDynamics365BusinessCentral_invoice_line_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Dynamics365BusinessCentral_invoice_line_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: Dynamics365BusinessCentral_invoice_line_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Dynamics365BusinessCentral_invoice_line_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    $context: Dynamics365BusinessCentral_invoice_line_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  $grid.title = 'Invoice '+$grid.inParams.InvoiceId +' lines '
  
  let line_id =$grid.inParams.line
  
  
  }
  on_ro_data_loaded(event = null) {
    return this.on_ro_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_ro_data_loadedInternal(
    $grid: Dynamics365BusinessCentral_invoice_line_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    $context: Dynamics365BusinessCentral_invoice_line_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Dynamics365BusinessCentral', 'invoice_line_grid.on_ro_data_loaded');
  let line_id =$grid.inParams.line
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
