import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_components_serial_numbers_pick_editor_ComponentContextService } from './FootprintManager.manufacturing_order_components_serial_numbers_pick_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_manufacturing_order_component_serial_number_selection_gridComponent } from './FootprintManager.manufacturing_order_component_serial_number_selection_grid.component';
import { FootprintManager_manufacturing_order_serial_number_selector_current_component_widgetComponent } from './FootprintManager.manufacturing_order_serial_number_selector_current_component_widget.component';
import { FootprintManager_manufacturing_order_serial_number_selector_total_components_widgetComponent } from './FootprintManager.manufacturing_order_serial_number_selector_total_components_widget.component';

type EntityType = { 
    id?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_manufacturing_order_component_serial_number_selection_gridComponent),
    forwardRef(() => FootprintManager_manufacturing_order_serial_number_selector_current_component_widgetComponent),
    forwardRef(() => FootprintManager_manufacturing_order_serial_number_selector_total_components_widgetComponent),
  ],
  selector: 'FootprintManager-manufacturing_order_components_serial_numbers_pick_editor',
  templateUrl: './FootprintManager.manufacturing_order_components_serial_numbers_pick_editor.component.html'
})
export class FootprintManager_manufacturing_order_components_serial_numbers_pick_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { serial_controlled_materials?: number, material_ids?: { material_id?: number, serial_count_needed?: number, material_name?: string, packaging_name?: string, license_plate?: number }[], warehouse_id?: number, iteration?: number, serial_numbers?: { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossVolume?: number, netVolume?: number, grossWeight?: number, netWeight?: number, materialId?: number, licensePlate?: number }[], location?: number } = { serial_controlled_materials: null, material_ids: [], warehouse_id: null, iteration: null, serial_numbers: [], location: null };
  //#region Inputs
  @Input('serial_controlled_materials') set $inParams_serial_controlled_materials(v: number) {
    this.inParams.serial_controlled_materials = v;
  }
  get $inParams_serial_controlled_materials(): number {
    return this.inParams.serial_controlled_materials;
  }
  @Input('material_ids') set $inParams_material_ids(v: { material_id?: number, serial_count_needed?: number, material_name?: string, packaging_name?: string, license_plate?: number }[]) {
    this.inParams.material_ids = v;
  }
  get $inParams_material_ids(): { material_id?: number, serial_count_needed?: number, material_name?: string, packaging_name?: string, license_plate?: number }[] {
    return this.inParams.material_ids;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('iteration') set $inParams_iteration(v: number) {
    this.inParams.iteration = v;
  }
  get $inParams_iteration(): number {
    return this.inParams.iteration;
  }
  @Input('serial_numbers') set $inParams_serial_numbers(v: { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossVolume?: number, netVolume?: number, grossWeight?: number, netWeight?: number, materialId?: number, licensePlate?: number }[]) {
    this.inParams.serial_numbers = v;
  }
  get $inParams_serial_numbers(): { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossVolume?: number, netVolume?: number, grossWeight?: number, netWeight?: number, materialId?: number, licensePlate?: number }[] {
    return this.inParams.serial_numbers;
  }
  @Input('location') set $inParams_location(v: number) {
    this.inParams.location = v;
  }
  get $inParams_location(): number {
    return this.inParams.location;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { serial_numbers?: { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossVolume?: number, netVolume?: number, grossWeight?: number, netWeight?: number, materialId?: number, licensePlate?: number }[] } = { serial_numbers: [] };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { serialNumbers?: { serialNumberId?: number, weightUOM?: number, volumeUOM?: number, dimensionsUOM?: number, grossWeight?: number, netWeight?: number, grossVolume?: number, netVolume?: number, materialId?: number, licensePlate?: number }[] } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm_serial: new ToolModel(new ButtonModel('confirm_serial', new ButtonStyles(['primary'], null), true, false, false, 'Confirm', '', null)
    , false),
      back: new ToolModel(new ButtonModel('back', new ButtonStyles(['tertiary'], null), true, false, false, 'Back', '', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    description: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), ' ', false, false),
    material: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Material', false, false),
    consumed_quantity: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Consumed quantity', false, false),
    license_plate: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'License plate', false, false),
  };

  fieldsets = {
    details: new FieldsetModel(
      'Details',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      serial_numbers_grid: new TabItemModel(
        this.rootTabGroup, 
        'Serial numbers', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_serial_numbers_grid_manufacturing_order_component_serial_number_selection_grid_inParams_material_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.material_ids[$editor.inParams.iteration - 1].material_id;
      
      return expr;
    }
  
    get $tabs_serial_numbers_grid_manufacturing_order_component_serial_number_selection_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.warehouse_id;
      
      return expr;
    }
  
    get $tabs_serial_numbers_grid_manufacturing_order_component_serial_number_selection_grid_inParams_serials_needed_count(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.material_ids[$editor.inParams.iteration - 1].serial_count_needed;
      
      return expr;
    }
  
    get $tabs_serial_numbers_grid_manufacturing_order_component_serial_number_selection_grid_inParams_license_plate(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.material_ids[$editor.inParams.iteration - 1].license_plate;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_serial_numbers_grid', { read: FootprintManager_manufacturing_order_component_serial_number_selection_gridComponent }) $tabs_serial_numbers_grid: FootprintManager_manufacturing_order_component_serial_number_selection_gridComponent;
    //#endregion tabs children
    widgets = {
      manufacturing_order_serial_selector_current_component_widget: new WidgetModel(false),
      manufacturing_order_serial_selector_total_components_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_manufacturing_order_serial_selector_current_component_widget_inParams_component_count(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.iteration;
      
      return expr;
    }
  
    get $widgets_manufacturing_order_serial_selector_total_components_widget_inParams_component_count(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.serial_controlled_materials;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_manufacturing_order_serial_selector_current_component_widget', { read:  FootprintManager_manufacturing_order_serial_number_selector_current_component_widgetComponent }) $widgets_manufacturing_order_serial_selector_current_component_widget: FootprintManager_manufacturing_order_serial_number_selector_current_component_widgetComponent;
      @ViewChild('$widgets_manufacturing_order_serial_selector_total_components_widget', { read:  FootprintManager_manufacturing_order_serial_number_selector_total_components_widgetComponent }) $widgets_manufacturing_order_serial_selector_total_components_widget: FootprintManager_manufacturing_order_serial_number_selector_total_components_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_order_components_serial_numbers_pick_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.serial_numbers_grid,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Select serial numbers';
    
    await this.on_init_flow();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
    };

    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_component_serial_numbers_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.description.control as TextModel).text = `${$editor.inParams.serial_controlled_materials} component${$editor.inParams.serial_controlled_materials > 1 ? "s" : ""} ${$editor.inParams.serial_controlled_materials > 1 ? "are" : "is"} serial controlled. Select the serial numbers consumed during manufacturing.`;
    (this.fields.material.control as TextModel).text = $editor.inParams.material_ids[$editor.inParams.iteration - 1].material_name;
    (this.fields.consumed_quantity.control as TextModel).text = `${$editor.inParams.material_ids[$editor.inParams.iteration - 1].serial_count_needed} ${$editor.inParams.material_ids[$editor.inParams.iteration - 1].packaging_name}`;

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_manufacturing_order_serial_selector_current_component_widget') {
      if (!isNil(this.$widgets_manufacturing_order_serial_selector_current_component_widget) && !this.widgets.manufacturing_order_serial_selector_current_component_widget.hidden && !this.widgets.manufacturing_order_serial_selector_current_component_widget.removed) {
        this.$widgets_manufacturing_order_serial_selector_current_component_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_manufacturing_order_serial_selector_total_components_widget') {
      if (!isNil(this.$widgets_manufacturing_order_serial_selector_total_components_widget) && !this.widgets.manufacturing_order_serial_selector_total_components_widget.hidden && !this.widgets.manufacturing_order_serial_selector_total_components_widget.removed) {
        this.$widgets_manufacturing_order_serial_selector_total_components_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_serial_numbers_grid') {
        if (!isNil(this.$tabs_serial_numbers_grid) && !this.tabs.serial_numbers_grid.hidden && !this.tabs.serial_numbers_grid.removed) {
          this.$tabs_serial_numbers_grid.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
  }

  //#region private flows
  serial_numbers_select_event_handler(event = null) {
    return this.serial_numbers_select_event_handlerInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async serial_numbers_select_event_handlerInternal(
    $editor: FootprintManager_manufacturing_order_components_serial_numbers_pick_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_components_serial_numbers_pick_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_components_serial_numbers_pick_editor.serial_numbers_select_event_handler');
  
  $editor.vars.serialNumbers = $event;
  
  if($editor.vars.serialNumbers.length == $editor.inParams.material_ids[$editor.inParams.iteration - 1].serial_count_needed)
      $editor.toolbar.confirm_serial.control.readOnly = false;
  else
      $editor.toolbar.confirm_serial.control.readOnly = true;
  
  $editor.toolbar.confirm_serial.control.label = `Selected ${$editor.vars.serialNumbers.length} of ${$editor.inParams.material_ids[$editor.inParams.iteration - 1].serial_count_needed}`;
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootprintManager_manufacturing_order_components_serial_numbers_pick_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_components_serial_numbers_pick_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_components_serial_numbers_pick_editor.on_confirm_clicked');
  
  if( $editor.inParams.iteration == $editor.inParams.material_ids.length )
  {
      const newSerialNumbersArray = $editor.inParams.serial_numbers.concat($editor.vars.serialNumbers);
  
      $editor.outParams.serial_numbers = newSerialNumbersArray;
      
      $editor.close();
  }
  else
  {
      const newIteration = $editor.inParams.iteration + 1;
  
      const newSerialNumbersArray = $editor.inParams.serial_numbers.concat($editor.vars.serialNumbers);
  
      const pickResult = await $shell.FootprintManager.openmanufacturing_order_components_serial_numbers_pick_editorDialog({serial_controlled_materials: $editor.inParams.serial_controlled_materials, material_ids: $editor.inParams.material_ids, warehouse_id: $editor.inParams.warehouse_id, iteration: newIteration, serial_numbers: newSerialNumbersArray, location: $editor.inParams.location}, 'modal', EModalSize.Large);
  
      $editor.outParams.serial_numbers = pickResult.serial_numbers;
  
      $editor.close();
  }
  }
  on_init_flow(event = null) {
    return this.on_init_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_flowInternal(
    $editor: FootprintManager_manufacturing_order_components_serial_numbers_pick_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_components_serial_numbers_pick_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_components_serial_numbers_pick_editor.on_init_flow');
  
  $editor.toolbar.confirm_serial.control.label = `Selected 0 of ${$editor.inParams.material_ids[$editor.inParams.iteration - 1].serial_count_needed}`;
  
  if( $editor.inParams.iteration > 1 )
  {
      $editor.toolbar.back.control.readOnly = false;
      $editor.fields.description.hidden = true;
  }
  
  const licensePlate = await $datasources.Manufacturing.ds_get_licenseplates_by_locationId.get({locationId: $editor.inParams.location, license_plate_ids: [$editor.inParams.material_ids[$editor.inParams.iteration - 1].license_plate]});
  if( $utils.isDefined(licensePlate.result) && licensePlate.result.length > 0 )
      $editor.fields.license_plate.control.text = licensePlate.result[0].LookupCode;
  
  $editor.title = `Select serial numbers`;
  }
  on_back_clicked(event = null) {
    return this.on_back_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_back_clickedInternal(
    $editor: FootprintManager_manufacturing_order_components_serial_numbers_pick_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_components_serial_numbers_pick_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_components_serial_numbers_pick_editor.on_back_clicked');
  
  const previousIterationSerials = $editor.inParams.serial_numbers.slice(0, -$editor.inParams.material_ids[$editor.inParams.iteration - 2].serial_count_needed);
  
  const pickResult = await $shell.FootprintManager.openmanufacturing_order_components_serial_numbers_pick_editorDialog({serial_controlled_materials: $editor.inParams.serial_controlled_materials, material_ids: $editor.inParams.material_ids, warehouse_id: $editor.inParams.warehouse_id, iteration: $editor.inParams.iteration - 1, serial_numbers: previousIterationSerials, location: $editor.inParams.location}, 'modal', EModalSize.Large);
  
  $editor.outParams.serial_numbers = pickResult.serial_numbers;
  
  $editor.close();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootprintManager_manufacturing_order_components_serial_numbers_pick_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_components_serial_numbers_pick_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_components_serial_numbers_pick_editor.on_cancel_clicked');
  $editor.close();
  }
  //#endregion private flows
  
}
