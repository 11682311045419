import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Catalogs_ModuleContextService } from './Catalogs.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Catalogs_material_catalog_materials_by_project_dd_ComponentContextService {
  private readonly moduleContext: Catalogs_ModuleContextService;

  constructor(moduleContext: Catalogs_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'material_catalog_materials_by_project_dd',
      type: ComponentType.selector
    }
  }
}