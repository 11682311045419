<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="edit" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="save" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_save($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!filtersets.header.hidden && !filtersets.header.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.header.collapsible }">
                    <div *ngIf="!filtersets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.header.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.header.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.header.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-context_allocation" *ngIf="!filters.context_allocation.hidden && !filters.context_allocation.removed" 
                                  class="field-container double {{filters.context_allocation.invalid ? 'invalid' : ''}} {{filters.context_allocation.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.context_allocation.styles.style"
                                  [ngClass]="filters.context_allocation.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.context_allocation.label + (filters.context_allocation.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.context_allocation.label}}<span *ngIf="filters.context_allocation.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-hard_allocation_contexts_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.context_allocation.control.type"
                                  formControlName="context_allocation"
                                  (displayTextChange)="filters.context_allocation.control.displayText=$event"
                                  [placeholder]="filters.context_allocation.control.placeholder"
                                  [styles]="filters.context_allocation.control.styles"
                                  [tooltip]="filters.context_allocation.control.tooltip"
                              >
                              </Allocations-hard_allocation_contexts_dd_multi>
                              <ng-container *ngIf="filters.context_allocation.invalid">
                                <ng-container *ngFor="let error of filters.context_allocation.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-show_inherited_values" *ngIf="!filters.show_inherited_values.hidden && !filters.show_inherited_values.removed" 
                                  class="field-container full {{filters.show_inherited_values.invalid ? 'invalid' : ''}} {{filters.show_inherited_values.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.show_inherited_values.styles.style"
                                  [ngClass]="filters.show_inherited_values.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.show_inherited_values.label + (filters.show_inherited_values.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.show_inherited_values.label}}<span *ngIf="filters.show_inherited_values.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-slide-toggle data-cy="slideToggle" formControlName="show_inherited_values"
                                            color="primary"
                                            class="datex-toggle"
                                            [ngStyle]="filters.show_inherited_values.control.styles.style"
                                            [ngClass]="filters.show_inherited_values.control.styles.classes"
                                            [matTooltip]="filters.show_inherited_values.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.show_inherited_values.control.label}}</mat-slide-toggle>
                              <ng-container *ngIf="filters.show_inherited_values.invalid">
                                <ng-container *ngFor="let error of filters.show_inherited_values.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.header.expanded">
              
              
                          </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-filters_allocation"
                     *ngIf="!filtersets.filters_allocation.hidden && !filtersets.filters_allocation.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters_allocation.collapsible }">
                    <div *ngIf="!filtersets.filters_allocation.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters_allocation.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.filters_allocation.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters_allocation.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters_allocation.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters_allocation.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters_allocation.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-allocation_entity" *ngIf="!filters.allocation_entity.hidden && !filters.allocation_entity.removed" 
                                  class="field-container standard {{filters.allocation_entity.invalid ? 'invalid' : ''}} {{filters.allocation_entity.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.allocation_entity.styles.style"
                                  [ngClass]="filters.allocation_entity.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.allocation_entity.label + (filters.allocation_entity.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.allocation_entity.label}}<span *ngIf="filters.allocation_entity.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-allocation_configuration_entities_dd_single 
                                  data-cy="selector"
                                  [type]="filters.allocation_entity.control.type"
                                  formControlName="allocation_entity"
                                  (displayTextChange)="filters.allocation_entity.control.displayText=$event"
                                  [placeholder]="filters.allocation_entity.control.placeholder"
                                  [styles]="filters.allocation_entity.control.styles"
                                  [tooltip]="filters.allocation_entity.control.tooltip"
                              >
                              </Allocations-allocation_configuration_entities_dd_single>
                              <ng-container *ngIf="filters.allocation_entity.invalid">
                                <ng-container *ngFor="let error of filters.allocation_entity.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_owner" *ngIf="!filters.entity_owner.hidden && !filters.entity_owner.removed" 
                                  class="field-container standard {{filters.entity_owner.invalid ? 'invalid' : ''}} {{filters.entity_owner.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_owner.styles.style"
                                  [ngClass]="filters.entity_owner.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_owner.label + (filters.entity_owner.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_owner.label}}<span *ngIf="filters.entity_owner.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_owner.control.type"
                                  formControlName="entity_owner"
                                  (displayTextChange)="filters.entity_owner.control.displayText=$event"
                                  [placeholder]="filters.entity_owner.control.placeholder"
                                  [styles]="filters.entity_owner.control.styles"
                                  [tooltip]="filters.entity_owner.control.tooltip"
                                [projectId]="$fields_entity_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.entity_owner.invalid">
                                <ng-container *ngFor="let error of filters.entity_owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_project" *ngIf="!filters.entity_project.hidden && !filters.entity_project.removed" 
                                  class="field-container standard {{filters.entity_project.invalid ? 'invalid' : ''}} {{filters.entity_project.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_project.styles.style"
                                  [ngClass]="filters.entity_project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_project.label + (filters.entity_project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_project.label}}<span *ngIf="filters.entity_project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_project.control.type"
                                  formControlName="entity_project"
                                  (displayTextChange)="filters.entity_project.control.displayText=$event"
                                  [placeholder]="filters.entity_project.control.placeholder"
                                  [styles]="filters.entity_project.control.styles"
                                  [tooltip]="filters.entity_project.control.tooltip"
                                [ownerId]="$fields_entity_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.entity_project.invalid">
                                <ng-container *ngFor="let error of filters.entity_project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_material" *ngIf="!filters.entity_material.hidden && !filters.entity_material.removed" 
                                  class="field-container standard {{filters.entity_material.invalid ? 'invalid' : ''}} {{filters.entity_material.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_material.styles.style"
                                  [ngClass]="filters.entity_material.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_material.label + (filters.entity_material.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_material.label}}<span *ngIf="filters.entity_material.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Materials-materials_by_project_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_material.control.type"
                                  formControlName="entity_material"
                                  (displayTextChange)="filters.entity_material.control.displayText=$event"
                                  [placeholder]="filters.entity_material.control.placeholder"
                                  [styles]="filters.entity_material.control.styles"
                                  [tooltip]="filters.entity_material.control.tooltip"
                                [projectId]="$fields_entity_material_selector_inParams_projectId"
                              >
                              </Materials-materials_by_project_dd_single>
                              <ng-container *ngIf="filters.entity_material.invalid">
                                <ng-container *ngFor="let error of filters.entity_material.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_warehouse" *ngIf="!filters.entity_warehouse.hidden && !filters.entity_warehouse.removed" 
                                  class="field-container standard {{filters.entity_warehouse.invalid ? 'invalid' : ''}} {{filters.entity_warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_warehouse.styles.style"
                                  [ngClass]="filters.entity_warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_warehouse.label + (filters.entity_warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_warehouse.label}}<span *ngIf="filters.entity_warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_warehouse.control.type"
                                  formControlName="entity_warehouse"
                                  (displayTextChange)="filters.entity_warehouse.control.displayText=$event"
                                  [placeholder]="filters.entity_warehouse.control.placeholder"
                                  [styles]="filters.entity_warehouse.control.styles"
                                  [tooltip]="filters.entity_warehouse.control.tooltip"
                              >
                              </Locations-warehouses_dd_single>
                              <ng-container *ngIf="filters.entity_warehouse.invalid">
                                <ng-container *ngFor="let error of filters.entity_warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_material_group" *ngIf="!filters.entity_material_group.hidden && !filters.entity_material_group.removed" 
                                  class="field-container standard {{filters.entity_material_group.invalid ? 'invalid' : ''}} {{filters.entity_material_group.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_material_group.styles.style"
                                  [ngClass]="filters.entity_material_group.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_material_group.label + (filters.entity_material_group.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_material_group.label}}<span *ngIf="filters.entity_material_group.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Materials-material_groups_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_material_group.control.type"
                                  formControlName="entity_material_group"
                                  (displayTextChange)="filters.entity_material_group.control.displayText=$event"
                                  [placeholder]="filters.entity_material_group.control.placeholder"
                                  [styles]="filters.entity_material_group.control.styles"
                                  [tooltip]="filters.entity_material_group.control.tooltip"
                              >
                              </Materials-material_groups_dd_single>
                              <ng-container *ngIf="filters.entity_material_group.invalid">
                                <ng-container *ngFor="let error of filters.entity_material_group.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_account_type" *ngIf="!filters.entity_account_type.hidden && !filters.entity_account_type.removed" 
                                  class="field-container standard {{filters.entity_account_type.invalid ? 'invalid' : ''}} {{filters.entity_account_type.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_account_type.styles.style"
                                  [ngClass]="filters.entity_account_type.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_account_type.label + (filters.entity_account_type.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_account_type.label}}<span *ngIf="filters.entity_account_type.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Addresses-account_types_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_account_type.control.type"
                                  formControlName="entity_account_type"
                                  (displayTextChange)="filters.entity_account_type.control.displayText=$event"
                                  [placeholder]="filters.entity_account_type.control.placeholder"
                                  [styles]="filters.entity_account_type.control.styles"
                                  [tooltip]="filters.entity_account_type.control.tooltip"
                              >
                              </Addresses-account_types_dd_single>
                              <ng-container *ngIf="filters.entity_account_type.invalid">
                                <ng-container *ngFor="let error of filters.entity_account_type.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_account" *ngIf="!filters.entity_account.hidden && !filters.entity_account.removed" 
                                  class="field-container standard {{filters.entity_account.invalid ? 'invalid' : ''}} {{filters.entity_account.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_account.styles.style"
                                  [ngClass]="filters.entity_account.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_account.label + (filters.entity_account.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_account.label}}<span *ngIf="filters.entity_account.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Addresses-ownersaccounts_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_account.control.type"
                                  formControlName="entity_account"
                                  (displayTextChange)="filters.entity_account.control.displayText=$event"
                                  [placeholder]="filters.entity_account.control.placeholder"
                                  [styles]="filters.entity_account.control.styles"
                                  [tooltip]="filters.entity_account.control.tooltip"
                                [ownerId]="$fields_entity_account_selector_inParams_ownerId"
                                [typeIds]="$fields_entity_account_selector_inParams_typeIds"
                                [accountTypeId]="$fields_entity_account_selector_inParams_accountTypeId"
                              >
                              </Addresses-ownersaccounts_dd_single>
                              <ng-container *ngIf="filters.entity_account.invalid">
                                <ng-container *ngFor="let error of filters.entity_account.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_order_class_type" *ngIf="!filters.entity_order_class_type.hidden && !filters.entity_order_class_type.removed" 
                                  class="field-container standard {{filters.entity_order_class_type.invalid ? 'invalid' : ''}} {{filters.entity_order_class_type.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_order_class_type.styles.style"
                                  [ngClass]="filters.entity_order_class_type.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_order_class_type.label + (filters.entity_order_class_type.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_order_class_type.label}}<span *ngIf="filters.entity_order_class_type.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-order_class_types_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_order_class_type.control.type"
                                  formControlName="entity_order_class_type"
                                  (displayTextChange)="filters.entity_order_class_type.control.displayText=$event"
                                  [placeholder]="filters.entity_order_class_type.control.placeholder"
                                  [styles]="filters.entity_order_class_type.control.styles"
                                  [tooltip]="filters.entity_order_class_type.control.tooltip"
                              >
                              </Allocations-order_class_types_dd_single>
                              <ng-container *ngIf="filters.entity_order_class_type.invalid">
                                <ng-container *ngFor="let error of filters.entity_order_class_type.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-entity_order_class" *ngIf="!filters.entity_order_class.hidden && !filters.entity_order_class.removed" 
                                  class="field-container standard {{filters.entity_order_class.invalid ? 'invalid' : ''}} {{filters.entity_order_class.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.entity_order_class.styles.style"
                                  [ngClass]="filters.entity_order_class.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.entity_order_class.label + (filters.entity_order_class.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.entity_order_class.label}}<span *ngIf="filters.entity_order_class.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-order_classes_dd_single 
                                  data-cy="selector"
                                  [type]="filters.entity_order_class.control.type"
                                  formControlName="entity_order_class"
                                  (displayTextChange)="filters.entity_order_class.control.displayText=$event"
                                  [placeholder]="filters.entity_order_class.control.placeholder"
                                  [styles]="filters.entity_order_class.control.styles"
                                  [tooltip]="filters.entity_order_class.control.tooltip"
                                [order_class_type_id]="$fields_entity_order_class_selector_inParams_order_class_type_id"
                              >
                              </Allocations-order_classes_dd_single>
                              <ng-container *ngIf="filters.entity_order_class.invalid">
                                <ng-container *ngFor="let error of filters.entity_order_class.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.filters_allocation.expanded">
              
              
                          </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-configurations_allocation"
                     *ngIf="!filtersets.configurations_allocation.hidden && !filtersets.configurations_allocation.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.configurations_allocation.collapsible }">
                    <div *ngIf="!filtersets.configurations_allocation.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.configurations_allocation.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.configurations_allocation.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.configurations_allocation.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.configurations_allocation.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.configurations_allocation.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.configurations_allocation.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-allow_inactive" *ngIf="!filters.allow_inactive.hidden && !filters.allow_inactive.removed" 
                                  class="field-container full {{filters.allow_inactive.invalid ? 'invalid' : ''}} {{filters.allow_inactive.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.allow_inactive.styles.style"
                                  [ngClass]="filters.allow_inactive.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.allow_inactive.label + (filters.allow_inactive.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.allow_inactive.label}}<span *ngIf="filters.allow_inactive.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="allow_inactive"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.allow_inactive.control.styles.style"
                                            [ngClass]="filters.allow_inactive.control.styles.classes"
                                            [matTooltip]="filters.allow_inactive.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.allow_inactive.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.allow_inactive.invalid">
                                <ng-container *ngFor="let error of filters.allow_inactive.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-allow_expired" *ngIf="!filters.allow_expired.hidden && !filters.allow_expired.removed" 
                                  class="field-container full {{filters.allow_expired.invalid ? 'invalid' : ''}} {{filters.allow_expired.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.allow_expired.styles.style"
                                  [ngClass]="filters.allow_expired.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.allow_expired.label + (filters.allow_expired.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.allow_expired.label}}<span *ngIf="filters.allow_expired.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="allow_expired"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.allow_expired.control.styles.style"
                                            [ngClass]="filters.allow_expired.control.styles.classes"
                                            [matTooltip]="filters.allow_expired.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.allow_expired.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.allow_expired.invalid">
                                <ng-container *ngFor="let error of filters.allow_expired.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-allowed_status_ids" *ngIf="!filters.allowed_status_ids.hidden && !filters.allowed_status_ids.removed" 
                                  class="field-container standard {{filters.allowed_status_ids.invalid ? 'invalid' : ''}} {{filters.allowed_status_ids.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.allowed_status_ids.styles.style"
                                  [ngClass]="filters.allowed_status_ids.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.allowed_status_ids.label + (filters.allowed_status_ids.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.allowed_status_ids.label}}<span *ngIf="filters.allowed_status_ids.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Materials-material_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.allowed_status_ids.control.type"
                                  formControlName="allowed_status_ids"
                                  (displayTextChange)="filters.allowed_status_ids.control.displayText=$event"
                                  [placeholder]="filters.allowed_status_ids.control.placeholder"
                                  [styles]="filters.allowed_status_ids.control.styles"
                                  [tooltip]="filters.allowed_status_ids.control.tooltip"
                              >
                              </Materials-material_statuses_dd_multi>
                              <ng-container *ngIf="filters.allowed_status_ids.invalid">
                                <ng-container *ngFor="let error of filters.allowed_status_ids.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.configurations_allocation.expanded">
              
              
                          </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-strategy"
                     *ngIf="!filtersets.strategy.hidden && !filtersets.strategy.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.strategy.collapsible }">
                    <div *ngIf="!filtersets.strategy.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.strategy.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.strategy.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.strategy.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.strategy.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.strategy.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.strategy.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-strategy_base" *ngIf="!filters.strategy_base.hidden && !filters.strategy_base.removed" 
                                  class="field-container standard {{filters.strategy_base.invalid ? 'invalid' : ''}} {{filters.strategy_base.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.strategy_base.styles.style"
                                  [ngClass]="filters.strategy_base.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.strategy_base.label + (filters.strategy_base.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.strategy_base.label}}<span *ngIf="filters.strategy_base.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-base_strategies_dd_single 
                                  data-cy="selector"
                                  [type]="filters.strategy_base.control.type"
                                  formControlName="strategy_base"
                                  (displayTextChange)="filters.strategy_base.control.displayText=$event"
                                  [placeholder]="filters.strategy_base.control.placeholder"
                                  [styles]="filters.strategy_base.control.styles"
                                  [tooltip]="filters.strategy_base.control.tooltip"
                              >
                              </Allocations-base_strategies_dd_single>
                              <ng-container *ngIf="filters.strategy_base.invalid">
                                <ng-container *ngFor="let error of filters.strategy_base.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-strategy_packaging" *ngIf="!filters.strategy_packaging.hidden && !filters.strategy_packaging.removed" 
                                  class="field-container standard {{filters.strategy_packaging.invalid ? 'invalid' : ''}} {{filters.strategy_packaging.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.strategy_packaging.styles.style"
                                  [ngClass]="filters.strategy_packaging.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.strategy_packaging.label + (filters.strategy_packaging.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.strategy_packaging.label}}<span *ngIf="filters.strategy_packaging.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-packaging_strategies_dd_single 
                                  data-cy="selector"
                                  [type]="filters.strategy_packaging.control.type"
                                  formControlName="strategy_packaging"
                                  (displayTextChange)="filters.strategy_packaging.control.displayText=$event"
                                  [placeholder]="filters.strategy_packaging.control.placeholder"
                                  [styles]="filters.strategy_packaging.control.styles"
                                  [tooltip]="filters.strategy_packaging.control.tooltip"
                              >
                              </Allocations-packaging_strategies_dd_single>
                              <ng-container *ngIf="filters.strategy_packaging.invalid">
                                <ng-container *ngFor="let error of filters.strategy_packaging.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-full_pallets_first" *ngIf="!filters.full_pallets_first.hidden && !filters.full_pallets_first.removed" 
                                  class="field-container standard {{filters.full_pallets_first.invalid ? 'invalid' : ''}} {{filters.full_pallets_first.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.full_pallets_first.styles.style"
                                  [ngClass]="filters.full_pallets_first.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.full_pallets_first.label + (filters.full_pallets_first.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.full_pallets_first.label}}<span *ngIf="filters.full_pallets_first.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-full_pallets_first_dd_single 
                                  data-cy="selector"
                                  [type]="filters.full_pallets_first.control.type"
                                  formControlName="full_pallets_first"
                                  (displayTextChange)="filters.full_pallets_first.control.displayText=$event"
                                  [placeholder]="filters.full_pallets_first.control.placeholder"
                                  [styles]="filters.full_pallets_first.control.styles"
                                  [tooltip]="filters.full_pallets_first.control.tooltip"
                              >
                              </Allocations-full_pallets_first_dd_single>
                              <ng-container *ngIf="filters.full_pallets_first.invalid">
                                <ng-container *ngFor="let error of filters.full_pallets_first.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-can_break_packagings" *ngIf="!filters.can_break_packagings.hidden && !filters.can_break_packagings.removed" 
                                  class="field-container full {{filters.can_break_packagings.invalid ? 'invalid' : ''}} {{filters.can_break_packagings.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.can_break_packagings.styles.style"
                                  [ngClass]="filters.can_break_packagings.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.can_break_packagings.label + (filters.can_break_packagings.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.can_break_packagings.label}}<span *ngIf="filters.can_break_packagings.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="can_break_packagings"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.can_break_packagings.control.styles.style"
                                            [ngClass]="filters.can_break_packagings.control.styles.classes"
                                            [matTooltip]="filters.can_break_packagings.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.can_break_packagings.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.can_break_packagings.invalid">
                                <ng-container *ngFor="let error of filters.can_break_packagings.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-use_primary_pick_locations" *ngIf="!filters.use_primary_pick_locations.hidden && !filters.use_primary_pick_locations.removed" 
                                  class="field-container standard {{filters.use_primary_pick_locations.invalid ? 'invalid' : ''}} {{filters.use_primary_pick_locations.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.use_primary_pick_locations.styles.style"
                                  [ngClass]="filters.use_primary_pick_locations.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.use_primary_pick_locations.label + (filters.use_primary_pick_locations.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.use_primary_pick_locations.label}}<span *ngIf="filters.use_primary_pick_locations.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="use_primary_pick_locations"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.use_primary_pick_locations.control.styles.style"
                                            [ngClass]="filters.use_primary_pick_locations.control.styles.classes"
                                            [matTooltip]="filters.use_primary_pick_locations.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.use_primary_pick_locations.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.use_primary_pick_locations.invalid">
                                <ng-container *ngFor="let error of filters.use_primary_pick_locations.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-can_break_license_plates" *ngIf="!filters.can_break_license_plates.hidden && !filters.can_break_license_plates.removed" 
                                  class="field-container full {{filters.can_break_license_plates.invalid ? 'invalid' : ''}} {{filters.can_break_license_plates.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.can_break_license_plates.styles.style"
                                  [ngClass]="filters.can_break_license_plates.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.can_break_license_plates.label + (filters.can_break_license_plates.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.can_break_license_plates.label}}<span *ngIf="filters.can_break_license_plates.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-checkbox data-cy="checkBox" formControlName="can_break_license_plates"
                                            class="datex-checkbox"
                                            color="primary"
                                            [ngStyle]="filters.can_break_license_plates.control.styles.style"
                                            [ngClass]="filters.can_break_license_plates.control.styles.classes"
                                            [matTooltip]="filters.can_break_license_plates.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.can_break_license_plates.control.label}}</mat-checkbox>
                              <ng-container *ngIf="filters.can_break_license_plates.invalid">
                                <ng-container *ngFor="let error of filters.can_break_license_plates.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.strategy.expanded">
              
              
                          </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-days_remaining"
                     *ngIf="!filtersets.days_remaining.hidden && !filtersets.days_remaining.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.days_remaining.collapsible }">
                    <div *ngIf="!filtersets.days_remaining.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.days_remaining.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.days_remaining.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.days_remaining.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.days_remaining.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.days_remaining.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.days_remaining.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-days_remaining_date_field" *ngIf="!filters.days_remaining_date_field.hidden && !filters.days_remaining_date_field.removed" 
                                  class="field-container standard {{filters.days_remaining_date_field.invalid ? 'invalid' : ''}} {{filters.days_remaining_date_field.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.days_remaining_date_field.styles.style"
                                  [ngClass]="filters.days_remaining_date_field.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.days_remaining_date_field.label + (filters.days_remaining_date_field.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.days_remaining_date_field.label}}<span *ngIf="filters.days_remaining_date_field.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-days_remaining_date_fields_dd_single 
                                  data-cy="selector"
                                  [type]="filters.days_remaining_date_field.control.type"
                                  formControlName="days_remaining_date_field"
                                  (displayTextChange)="filters.days_remaining_date_field.control.displayText=$event"
                                  [placeholder]="filters.days_remaining_date_field.control.placeholder"
                                  [styles]="filters.days_remaining_date_field.control.styles"
                                  [tooltip]="filters.days_remaining_date_field.control.tooltip"
                              >
                              </Allocations-days_remaining_date_fields_dd_single>
                              <ng-container *ngIf="filters.days_remaining_date_field.invalid">
                                <ng-container *ngFor="let error of filters.days_remaining_date_field.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-days_remaining_threshold" *ngIf="!filters.days_remaining_threshold.hidden && !filters.days_remaining_threshold.removed" 
                                  class="field-container standard {{filters.days_remaining_threshold.invalid ? 'invalid' : ''}} {{filters.days_remaining_threshold.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.days_remaining_threshold.styles.style"
                                  [ngClass]="filters.days_remaining_threshold.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.days_remaining_threshold.label + (filters.days_remaining_threshold.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.days_remaining_threshold.label}}<span *ngIf="filters.days_remaining_threshold.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="numberBox" formControlName="days_remaining_threshold"
                                      matInput
                                      numberBox
                                      [format]="filters.days_remaining_threshold.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{ (filters.days_remaining_threshold.control.readOnly || filters.days_remaining_threshold.control.disabled) ? 'readonly ' : ''}}"
                                      placeholder="{{filters.days_remaining_threshold.control.placeholder}}"
                                      [ngStyle]="filters.days_remaining_threshold.control.styles.style"
                                      [ngClass]="filters.days_remaining_threshold.control.styles.classes"
                                      [matTooltip]="filters.days_remaining_threshold.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">
                              <ng-container *ngIf="filters.days_remaining_threshold.invalid">
                                <ng-container *ngFor="let error of filters.days_remaining_threshold.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.days_remaining.expanded">
              
              
                          </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-replenishments"
                     *ngIf="!filtersets.replenishments.hidden && !filtersets.replenishments.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.replenishments.collapsible }">
                    <div *ngIf="!filtersets.replenishments.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.replenishments.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.replenishments.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.replenishments.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.replenishments.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.replenishments.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.replenishments.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-replenishments_context" *ngIf="!filters.replenishments_context.hidden && !filters.replenishments_context.removed" 
                                  class="field-container standard {{filters.replenishments_context.invalid ? 'invalid' : ''}} {{filters.replenishments_context.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.replenishments_context.styles.style"
                                  [ngClass]="filters.replenishments_context.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.replenishments_context.label + (filters.replenishments_context.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.replenishments_context.label}}<span *ngIf="filters.replenishments_context.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-replenishment_contexts_dd_single 
                                  data-cy="selector"
                                  [type]="filters.replenishments_context.control.type"
                                  formControlName="replenishments_context"
                                  (displayTextChange)="filters.replenishments_context.control.displayText=$event"
                                  [placeholder]="filters.replenishments_context.control.placeholder"
                                  [styles]="filters.replenishments_context.control.styles"
                                  [tooltip]="filters.replenishments_context.control.tooltip"
                              >
                              </Allocations-replenishment_contexts_dd_single>
                              <ng-container *ngIf="filters.replenishments_context.invalid">
                                <ng-container *ngFor="let error of filters.replenishments_context.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-replenishments_quantity" *ngIf="!filters.replenishments_quantity.hidden && !filters.replenishments_quantity.removed" 
                                  class="field-container standard {{filters.replenishments_quantity.invalid ? 'invalid' : ''}} {{filters.replenishments_quantity.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.replenishments_quantity.styles.style"
                                  [ngClass]="filters.replenishments_quantity.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.replenishments_quantity.label + (filters.replenishments_quantity.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.replenishments_quantity.label}}<span *ngIf="filters.replenishments_quantity.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Allocations-replenishment_quantities_dd_single 
                                  data-cy="selector"
                                  [type]="filters.replenishments_quantity.control.type"
                                  formControlName="replenishments_quantity"
                                  (displayTextChange)="filters.replenishments_quantity.control.displayText=$event"
                                  [placeholder]="filters.replenishments_quantity.control.placeholder"
                                  [styles]="filters.replenishments_quantity.control.styles"
                                  [tooltip]="filters.replenishments_quantity.control.tooltip"
                              >
                              </Allocations-replenishment_quantities_dd_single>
                              <ng-container *ngIf="filters.replenishments_quantity.invalid">
                                <ng-container *ngFor="let error of filters.replenishments_quantity.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.replenishments.expanded">
              
              
                          </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-common"
                     *ngIf="!filtersets.common.hidden && !filtersets.common.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.common.collapsible }">
                    <div *ngIf="!filtersets.common.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.common.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.common.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.common.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.common.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.common.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.common.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-priority" *ngIf="!filters.priority.hidden && !filters.priority.removed" 
                                  class="field-container standard {{filters.priority.invalid ? 'invalid' : ''}} {{filters.priority.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.priority.styles.style"
                                  [ngClass]="filters.priority.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.priority.label + (filters.priority.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.priority.label}}<span *ngIf="filters.priority.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="numberBox" formControlName="priority"
                                      matInput
                                      numberBox
                                      [format]="filters.priority.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{ (filters.priority.control.readOnly || filters.priority.control.disabled) ? 'readonly ' : ''}}"
                                      placeholder="{{filters.priority.control.placeholder}}"
                                      [ngStyle]="filters.priority.control.styles.style"
                                      [ngClass]="filters.priority.control.styles.classes"
                                      [matTooltip]="filters.priority.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">
                              <ng-container *ngIf="filters.priority.invalid">
                                <ng-container *ngFor="let error of filters.priority.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.common.expanded">
              
              
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.applicable_configs.hasTabToShow" class="tab" data-cy="tab-applicable_configs">
              <h2 [className]="tabs.applicable_configs.active? 'active': ''" (click)="tabs.applicable_configs.activate()">{{tabs.applicable_configs.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-allocation_configurations_grid *ngIf="tabs.applicable_configs.active"
              #$tabs_applicable_configs
              [applicable_config_ids]="$tabs_applicable_configs_allocation_configurations_grid_inParams_applicable_config_ids"
              (on_data_change)="get_configuration($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_applicable_configs')">
              </FootprintManager-allocation_configurations_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>