import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_ds_get_errorsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { customerr?: string, application_name?: string }): Promise<{ result?: { value?: number } }> {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_get_errors/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      customerr?: string, application_name?: string    }) {
      return false;
    }
}
