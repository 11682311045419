import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Equipment_ds_equipment_warehouse_get_by_idsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { equipmentId: number, warehouseId: number }): 
  Promise<{ result: { EquipmentId?: number, LocationContainerId?: number, LocationContainer?: { WarehouseId?: number, Warehouse?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.equipmentId)) {
      missingRequiredInParams.push('\'equipmentId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Equipment/datasources/ds_equipment_warehouse_get_by_ids/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { equipmentId: number, warehouseId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { EquipmentId?: number, LocationContainerId?: number, LocationContainer?: { WarehouseId?: number, Warehouse?: { Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.equipmentId)) {
      missingRequiredInParams.push('\'equipmentId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Equipment/datasources/ds_equipment_warehouse_get_by_ids/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { equipmentId: number, warehouseId: number, $keys: { EquipmentId?: number, LocationContainerId?: number }[] }): 
  Promise<{ result: { EquipmentId?: number, LocationContainerId?: number, LocationContainer?: { WarehouseId?: number, Warehouse?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.equipmentId)) {
      missingRequiredInParams.push('\'equipmentId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Equipment/datasources/ds_equipment_warehouse_get_by_ids/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      equipmentId: number, warehouseId: number    }) {
        if(isNil(inParams.equipmentId)) {
          return true; 
        }
        if(isNil(inParams.warehouseId)) {
          return true; 
        }
      return false;
    }
}
