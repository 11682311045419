import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Manifesting.frontend.types'

@Injectable({ providedIn: 'root' })
export class Manifesting_get_label_contents_explanationService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { shipping_container_id: number }): Promise<{ label_contents_explanation?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipping_container_id)) {
      missingRequiredInParams.push('\'shipping_container_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manifesting/functions/get_label_contents_explanation`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

