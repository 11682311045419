import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_auto_invoicing_rules_editorService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { ruleId: string, projectId: number }): Promise<{ result?: { instructionId?: string, billingContractId?: number, projectId?: number, active?: boolean, instructionType?: string, instructionName?: string, billingAggregationStrategyIds?: number[], oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, termId?: number, taxScheduleId?: number, createdOn?: string, createdBy?: string, modifiedOn?: string, modifiedBy?: string, useExistingInvoice?: boolean, isNew?: boolean, billingCodeIds?: number[], cronExpression?: string, schedule?: { date_option?: string, date_interval?: string[], time_option?: string, time_interval?: string, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } }, schedule_name?: string } }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.ruleId)) {
      missingRequiredInParams.push('\'ruleId\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_auto_invoicing_rules_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      ruleId: string, projectId: number    }) {
        if(isNil(inParams.ruleId)) {
          return true; 
        }
        if(isNil(inParams.projectId)) {
          return true; 
        }
      return false;
    }
}
