import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_manufacturing_order_production_confirmation_editorService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: {  }): Promise<{ result?: { lineNumber?: number, finishedProduct?: string, plannedQuantity?: string, producedQuantity?: number, packaging?: number, targetLicensePlate?: number, lot?: number, id?: number } }> {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_manufacturing_order_production_confirmation_editor/get`;
    
    const options = {
    }
    
    const body = null;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      }) {
      return false;
    }
}
