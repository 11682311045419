<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
  </ng-container>


  <ng-container gridColumnDef="udf_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.udf_name.displayControl.styles.style"
          [ngClass]="row.cells.udf_name.displayControl.styles.classes"
          [matTooltip]="row.cells.udf_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.udf_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="udf_value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.udf_value.displayControl.styles.style"
          [ngClass]="row.cells.udf_value.displayControl.styles.classes"
          [matTooltip]="row.cells.udf_value.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.udf_value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['udf_value_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.udf_value.editControl.readOnly || row.cells.udf_value.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.udf_value.editControl.placeholder}}"
            [ngStyle]="row.cells.udf_value.editControl.styles.style"
            [ngClass]="row.cells.udf_value.editControl.styles.classes"
            [matTooltip]="row.cells.udf_value.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
