import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Waves_get_manual_allocationsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { WaveIds: number[] }): 
  Promise<{ result: { TaskCount?: number, OperationCodeId?: number, Shipment?: { Wave?: { Id?: number } }, Status?: { Id?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.WaveIds)) {
      missingRequiredInParams.push('\'WaveIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/get_manual_allocations/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { WaveIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { TaskCount?: number, OperationCodeId?: number, Shipment?: { Wave?: { Id?: number } }, Status?: { Id?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.WaveIds)) {
      missingRequiredInParams.push('\'WaveIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/get_manual_allocations/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { WaveIds: number[], $keys: { Shipment?: { Wave?: { Id?: number } }, OperationCodeId?: number, Status?: { Id?: number } }[] }): 
  Promise<{ result: { TaskCount?: number, OperationCodeId?: number, Shipment?: { Wave?: { Id?: number } }, Status?: { Id?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.WaveIds)) {
      missingRequiredInParams.push('\'WaveIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/get_manual_allocations/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      WaveIds: number[]    }) {
        if(isNil(inParams.WaveIds)) {
          return true; 
        }
      return false;
    }
}
