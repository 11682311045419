import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './WarehouseTransfers.frontend.types'

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_create_warehouse_transfer_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { project_id: number, source_warehouse_id: number, target_warehouse_id: number, notes?: string, lookup?: string, outbound_order_lookup?: string, inbound_order_lookup?: string }): Promise<{ warehouse_transfer_id?: number, outbound_order_id?: number, inbound_order_id?: number, outbound_shipment_id?: number, inbound_shipment_id?: number, reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_id)) {
      missingRequiredInParams.push('\'project_id\'');
    }
    if (isNil(inParams.source_warehouse_id)) {
      missingRequiredInParams.push('\'source_warehouse_id\'');
    }
    if (isNil(inParams.target_warehouse_id)) {
      missingRequiredInParams.push('\'target_warehouse_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/functions/create_warehouse_transfer_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

