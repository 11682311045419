<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="delete">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.delete.displayControl.styles.style"
          [ngClass]="row.cells.delete.displayControl.styles.classes"
          [matTooltip]="row.cells.delete.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.delete.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status_name.displayControl.styles.style"
          [ngClass]="row.cells.status_name.displayControl.styles.classes"
          [matTooltip]="row.cells.status_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes"
          [matTooltip]="row.cells.material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-materials_by_project_dd_single 
        data-cy="selector"
        [type]="row.cells.material.editControl.type"
        [formControl]="row.formGroup.controls['material_edit']"
        (displayTextChange)="row.cells.material.editControl.displayText=$event"
        [placeholder]="row.cells.material.editControl.placeholder"
        [styles]="row.cells.material.editControl.styles"
        [tooltip]="row.cells.material.editControl.tooltip"
      [projectId]="row.$fields_material_selector_inParams_projectId"
    >
    </Materials-materials_by_project_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <FootprintManager-lots_dd_single 
        data-cy="selector"
        [type]="row.cells.lot.editControl.type"
        [formControl]="row.formGroup.controls['lot_edit']"
        (displayTextChange)="row.cells.lot.editControl.displayText=$event"
        [placeholder]="row.cells.lot.editControl.placeholder"
        [styles]="row.cells.lot.editControl.styles"
        [tooltip]="row.cells.lot.editControl.tooltip"
      [materialId]="row.$fields_lot_selector_inParams_materialId"
      [vendorLotId]="row.$fields_lot_selector_inParams_vendorLotId"
    >
    </FootprintManager-lots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendorlot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <FootprintManager-vendorlots_dd_single 
        data-cy="selector"
        [type]="row.cells.vendorlot.editControl.type"
        [formControl]="row.formGroup.controls['vendorlot_edit']"
        (displayTextChange)="row.cells.vendorlot.editControl.displayText=$event"
        [placeholder]="row.cells.vendorlot.editControl.placeholder"
        [styles]="row.cells.vendorlot.editControl.styles"
        [tooltip]="row.cells.vendorlot.editControl.tooltip"
      [materialId]="row.$fields_vendorlot_selector_inParams_materialId"
      [lotId]="row.$fields_vendorlot_selector_inParams_lotId"
    >
    </FootprintManager-vendorlots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.packaging.editControl.type"
        [formControl]="row.formGroup.controls['packaging_edit']"
        (displayTextChange)="row.cells.packaging.editControl.displayText=$event"
        [placeholder]="row.cells.packaging.editControl.placeholder"
        [styles]="row.cells.packaging.editControl.styles"
        [tooltip]="row.cells.packaging.editControl.tooltip"
      [materialId]="row.$fields_packaging_selector_inParams_materialId"
    >
    </Materials-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['packaged_amount_edit']"
            matInput
            numberBox
            [format]="row.cells.packaged_amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.packaged_amount.editControl.readOnly || row.cells.packaged_amount.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.packaged_amount.editControl.placeholder}}"
            [ngStyle]="row.cells.packaged_amount.editControl.styles.style"
            [ngClass]="row.cells.packaged_amount.editControl.styles.classes"
            [matTooltip]="row.cells.packaged_amount.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
