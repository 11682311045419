<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-title" *ngIf="!fields.title.hidden && !fields.title.removed" 
                            class="field-container double {{fields.title.invalid ? 'invalid' : ''}} {{fields.title.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.title.styles.style"
                            [ngClass]="fields.title.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.title.label + (fields.title.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.title.label}}<span *ngIf="fields.title.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.title.control.styles.style"
                              [ngClass]="fields.title.control.styles.classes"
                              [matTooltip]="fields.title.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.title.control.text }}</div>
                        <ng-container *ngIf="fields.title.invalid">
                          <ng-container *ngFor="let error of fields.title.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaged_amount" *ngIf="!fields.packaged_amount.hidden && !fields.packaged_amount.removed" 
                            class="field-container standard {{fields.packaged_amount.invalid ? 'invalid' : ''}} {{fields.packaged_amount.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.packaged_amount.styles.style"
                            [ngClass]="fields.packaged_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.packaged_amount.label + (fields.packaged_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.packaged_amount.label}}<span *ngIf="fields.packaged_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.packaged_amount.control.styles.style"
                              [ngClass]="fields.packaged_amount.control.styles.classes"
                              [matTooltip]="fields.packaged_amount.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.packaged_amount.control.text }}</div>
                        <ng-container *ngIf="fields.packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-updated_packaged_amount" *ngIf="!fields.updated_packaged_amount.hidden && !fields.updated_packaged_amount.removed" 
                            class="field-container standard {{fields.updated_packaged_amount.invalid ? 'invalid' : ''}} {{fields.updated_packaged_amount.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.updated_packaged_amount.styles.style"
                            [ngClass]="fields.updated_packaged_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.updated_packaged_amount.label + (fields.updated_packaged_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.updated_packaged_amount.label}}<span *ngIf="fields.updated_packaged_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="updated_packaged_amount"
                                matInput
                                numberBox
                                [format]="fields.updated_packaged_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.updated_packaged_amount.control.readOnly || fields.updated_packaged_amount.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.updated_packaged_amount.control.placeholder}}"
                                [ngStyle]="fields.updated_packaged_amount.control.styles.style"
                                [ngClass]="fields.updated_packaged_amount.control.styles.classes"
                                [matTooltip]="fields.updated_packaged_amount.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.updated_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.updated_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reasoncode" *ngIf="!fields.reasoncode.hidden && !fields.reasoncode.removed" 
                            class="field-container standard {{fields.reasoncode.invalid ? 'invalid' : ''}} {{fields.reasoncode.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.reasoncode.styles.style"
                            [ngClass]="fields.reasoncode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reasoncode.label + (fields.reasoncode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reasoncode.label}}<span *ngIf="fields.reasoncode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <DockAppointments-reasoncodes_dd_single 
                            data-cy="selector"
                            [type]="fields.reasoncode.control.type"
                            formControlName="reasoncode"
                            (displayTextChange)="fields.reasoncode.control.displayText=$event"
                            [placeholder]="fields.reasoncode.control.placeholder"
                            [styles]="fields.reasoncode.control.styles"
                            [tooltip]="fields.reasoncode.control.tooltip"
                          [parentId]="$fields_reasoncode_selector_inParams_parentId"
                          [parentEntity]="$fields_reasoncode_selector_inParams_parentEntity"
                        >
                        </DockAppointments-reasoncodes_dd_single>
                        <ng-container *ngIf="fields.reasoncode.invalid">
                          <ng-container *ngFor="let error of fields.reasoncode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>