<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="adjust_inventory" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_adjust_inventory_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="move_inventory" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_move_inventory_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator2" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="distribute_total_weight" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_average_weights_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-lot" *ngIf="!filters.lot.hidden && !filters.lot.removed" 
            class="field-container standard {{filters.lot.invalid ? 'invalid' : ''}} {{filters.lot.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.lot.styles.style"
            [ngClass]="filters.lot.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.lot.label + (filters.lot.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.lot.label}}<span *ngIf="filters.lot.required" class="required-asterisk">*</span>
          </label>
        </div>
        <SerialNumbers-serialnumbers_lots_dd_single 
            data-cy="selector"
            [type]="filters.lot.control.type"
            formControlName="lot"
            (displayTextChange)="filters.lot.control.displayText=$event"
            [placeholder]="filters.lot.control.placeholder"
            [styles]="filters.lot.control.styles"
            [tooltip]="filters.lot.control.tooltip"
          [licenseplateIds]="$fields_lot_selector_inParams_licenseplateIds"
          [locationId]="$fields_lot_selector_inParams_locationId"
          [warehouseIds]="$fields_lot_selector_inParams_warehouseIds"
          [lotId]="$fields_lot_selector_inParams_lotId"
          [materialIds]="$fields_lot_selector_inParams_materialIds"
          [projectId]="$fields_lot_selector_inParams_projectId"
          [ownerId]="$fields_lot_selector_inParams_ownerId"
          [archived]="$fields_lot_selector_inParams_archived"
          [fullTextSearch]="$fields_lot_selector_inParams_fullTextSearch"
          [textSearch]="$fields_lot_selector_inParams_textSearch"
          [orderId]="$fields_lot_selector_inParams_orderId"
          [orderLineNumber]="$fields_lot_selector_inParams_orderLineNumber"
        >
        </SerialNumbers-serialnumbers_lots_dd_single>
        <ng-container *ngIf="filters.lot.invalid">
          <ng-container *ngFor="let error of filters.lot.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-packaging" *ngIf="!filters.packaging.hidden && !filters.packaging.removed" 
            class="field-container standard {{filters.packaging.invalid ? 'invalid' : ''}} {{filters.packaging.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.packaging.styles.style"
            [ngClass]="filters.packaging.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.packaging.label + (filters.packaging.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.packaging.label}}<span *ngIf="filters.packaging.required" class="required-asterisk">*</span>
          </label>
        </div>
        <SerialNumbers-serialnumbers_packagings_dd_single 
            data-cy="selector"
            [type]="filters.packaging.control.type"
            formControlName="packaging"
            (displayTextChange)="filters.packaging.control.displayText=$event"
            [placeholder]="filters.packaging.control.placeholder"
            [styles]="filters.packaging.control.styles"
            [tooltip]="filters.packaging.control.tooltip"
          [licenseplateIds]="$fields_packaging_selector_inParams_licenseplateIds"
          [locationId]="$fields_packaging_selector_inParams_locationId"
          [warehouseIds]="$fields_packaging_selector_inParams_warehouseIds"
          [lotId]="$fields_packaging_selector_inParams_lotId"
          [materialIds]="$fields_packaging_selector_inParams_materialIds"
          [projectId]="$fields_packaging_selector_inParams_projectId"
          [ownerId]="$fields_packaging_selector_inParams_ownerId"
          [archived]="$fields_packaging_selector_inParams_archived"
          [fullTextSearch]="$fields_packaging_selector_inParams_fullTextSearch"
          [textSearch]="$fields_packaging_selector_inParams_textSearch"
          [orderId]="$fields_packaging_selector_inParams_orderId"
          [orderLineNumber]="$fields_packaging_selector_inParams_orderLineNumber"
        >
        </SerialNumbers-serialnumbers_packagings_dd_single>
        <ng-container *ngIf="filters.packaging.invalid">
          <ng-container *ngFor="let error of filters.packaging.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Lookup">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Lookup.displayControl.styles.style"
          [ngClass]="row.cells.Lookup.displayControl.styles.classes"
          [matTooltip]="row.cells.Lookup.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Lookup.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes"
          [matTooltip]="row.cells.material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_group">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_group.displayControl.styles.style"
          [ngClass]="row.cells.material_group.displayControl.styles.classes"
          [matTooltip]="row.cells.material_group.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_group.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes"
          [matTooltip]="row.cells.material_description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendor_lot.displayControl.styles.style"
          [ngClass]="row.cells.vendor_lot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendor_lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendor_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="net_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.net_weight.displayControl.styles.style"
          [ngClass]="row.cells.net_weight.displayControl.styles.classes"
          [matTooltip]="row.cells.net_weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.net_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="gross_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.gross_weight.displayControl.styles.style"
          [ngClass]="row.cells.gross_weight.displayControl.styles.classes"
          [matTooltip]="row.cells.gross_weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.gross_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.weight_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.weight_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes"
          [matTooltip]="row.cells.length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes"
          [matTooltip]="row.cells.width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes"
          [matTooltip]="row.cells.height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dimension_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.dimension_uom.displayControl.styles.style"
          [ngClass]="row.cells.dimension_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.dimension_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.dimension_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="net_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.net_volume.displayControl.styles.style"
          [ngClass]="row.cells.net_volume.displayControl.styles.classes"
          [matTooltip]="row.cells.net_volume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.net_volume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="gross_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.gross_volume.displayControl.styles.style"
          [ngClass]="row.cells.gross_volume.displayControl.styles.classes"
          [matTooltip]="row.cells.gross_volume.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.gross_volume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="volume_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.volume_uom.displayControl.styles.style"
          [ngClass]="row.cells.volume_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.volume_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.volume_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_date.displayControl.styles.style"
          [ngClass]="row.cells.created_date.displayControl.styles.classes"
          [matTooltip]="row.cells.created_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_date.displayControl.text | formatText : row.cells.created_date.displayControl.formatType : row.cells.created_date.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_user">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_user.displayControl.styles.style"
          [ngClass]="row.cells.created_user.displayControl.styles.classes"
          [matTooltip]="row.cells.created_user.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_user.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate_type.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate_type.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate_type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.location.displayControl.styles.style"
          [ngClass]="row.cells.location.displayControl.styles.classes"
          [matTooltip]="row.cells.location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
