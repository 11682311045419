import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { PrintNode_label_image_reportService } from './PrintNode.report.index';

@Injectable({ providedIn: 'root' })
export class PrintNode_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public PrintNode: PrintNode_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _label_image_report: PrintNode_label_image_reportService;
  public get label_image_report(): PrintNode_label_image_reportService {
    if(!this._label_image_report) {
      this._label_image_report = this.injector.get(PrintNode_label_image_reportService);
    }
    return this._label_image_report;
  }
}

