import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Surveys_ds_get_surveys_by_surveyIdsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { surveyIds: number[] }): 
  Promise<{ result: { id?: number, createdSysDateTime?: string, createdSysUser?: string, modifiedSysDateTime?: string, modifiedSysUser?: string, SurveyDefinition?: { description?: string, name?: string }, OperationContextType?: { Name?: string }, ContextAppointment?: { LookupCode?: string }, ContextLicensePlate?: { LookupCode?: string }, ContextLocation?: { Name?: string }, ContextProject?: { LookupCode?: string }, ContextShipment?: { LookupCode?: string }, ContextShippingContainer?: { LookupCode?: string }, ContextTask?: { Id?: number, OperationCode?: { Name?: string } }, Equipment?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.surveyIds)) {
      missingRequiredInParams.push('\'surveyIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Surveys/datasources/ds_get_surveys_by_surveyIds/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { surveyIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { id?: number, createdSysDateTime?: string, createdSysUser?: string, modifiedSysDateTime?: string, modifiedSysUser?: string, SurveyDefinition?: { description?: string, name?: string }, OperationContextType?: { Name?: string }, ContextAppointment?: { LookupCode?: string }, ContextLicensePlate?: { LookupCode?: string }, ContextLocation?: { Name?: string }, ContextProject?: { LookupCode?: string }, ContextShipment?: { LookupCode?: string }, ContextShippingContainer?: { LookupCode?: string }, ContextTask?: { Id?: number, OperationCode?: { Name?: string } }, Equipment?: { LookupCode?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.surveyIds)) {
      missingRequiredInParams.push('\'surveyIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Surveys/datasources/ds_get_surveys_by_surveyIds/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { surveyIds: number[], $keys: number[] }): 
  Promise<{ result: { id?: number, createdSysDateTime?: string, createdSysUser?: string, modifiedSysDateTime?: string, modifiedSysUser?: string, SurveyDefinition?: { description?: string, name?: string }, OperationContextType?: { Name?: string }, ContextAppointment?: { LookupCode?: string }, ContextLicensePlate?: { LookupCode?: string }, ContextLocation?: { Name?: string }, ContextProject?: { LookupCode?: string }, ContextShipment?: { LookupCode?: string }, ContextShippingContainer?: { LookupCode?: string }, ContextTask?: { Id?: number, OperationCode?: { Name?: string } }, Equipment?: { LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.surveyIds)) {
      missingRequiredInParams.push('\'surveyIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Surveys/datasources/ds_get_surveys_by_surveyIds/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      surveyIds: number[]    }) {
        if(isNil(inParams.surveyIds)) {
          return true; 
        }
      return false;
    }
}
