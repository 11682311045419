import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { InventoryCounts_DatasourceService } from './InventoryCounts.datasource.index';

interface IInventoryCounts_cycle_count_reportServiceInParams {
  cycle_count_id: number}

interface IInventoryCounts_cycle_count_reportServiceData {
  details?: { result?: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string }, recount?: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string } }[] }[] }
}

@Injectable({ providedIn: 'root' })
export class InventoryCounts_cycle_count_reportService extends ReportBaseService<IInventoryCounts_cycle_count_reportServiceInParams, IInventoryCounts_cycle_count_reportServiceData> {

  protected reportReferenceName = 'cycle_count_report';
  protected appReferenceName = 'InventoryCounts';

  constructor(
    $utils: UtilsService,
    private $datasources: InventoryCounts_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IInventoryCounts_cycle_count_reportServiceInParams) {
  }

  protected async getData(inParams: IInventoryCounts_cycle_count_reportServiceInParams): Promise<IInventoryCounts_cycle_count_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IInventoryCounts_cycle_count_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IInventoryCounts_cycle_count_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        cycle_count_id:  $report.inParams.cycle_count_id ,
        operation_code_id:  58 ,
        chain_head:  null 
      };
      
      const dsData = await this.$datasources.InventoryCounts.ds_cycle_count_report_details.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
