import { Utilities_Types } from './Utilities.frontend.types'

export namespace PrintNode_Types {
  /**
   * Holds the device types supported by PrintNode.
   */
  export enum e_device_type {
    Printer = 'printer',
    Scale = 'scale'
  }
  /**
   * Holds the entity types to be used for PrintNode associations.
   */
  export enum e_entity_type {
    Location = 'location'
  }
  /**
   * Interface representing a computer object from PrintNode.
   */
  export interface i_computer {
    createTimestamp?: string;
    hostname?: string;
    inet?: string;
    inet6?: string;
    jre?: string;
    name?: string;
    state?: string;
    version?: string;
  }
  /**
   * Interface representing a scale object from PrintNode.
   */
  export interface i_scale {
    productId?: number;
    count?: number;
    deviceName?: string;
    port?: string;
    vendorId?: number;
    computerId?: number;
    vendor?: string;
    measurement?: { g?: number, lb?: number, oz?: number, kg?: number };
    mass?: number[];
    deviceNum?: number;
    ageOfData?: number;
    ntpOffset?: number;
    clientReportedCreateTimestamp?: string;
    product?: string;
  }
}

export namespace $frontendTypes {
  export import Utilities =  Utilities_Types;
  export import PrintNode = PrintNode_Types;
}




