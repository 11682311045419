import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class TransloadOrders_ds_get_transload_by_transloadIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { transloadId: number }): 
  Promise<{ result: { Id?: number, InboundOrderId?: number, OutboundOrderId?: number, InboundOrder?: { Id?: number, OrderStatusId?: number, ShipmentOrderLookups?: { ShipmentId?: number }[] }, OutboundOrder?: { Id?: number, OrderStatusId?: number, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { Id?: number, ExpectedWarehouseId?: number, StatusId?: number, LicensePlateShipmentAssociation?: { LicensePlateId?: number }[], ExpectedWarehouse?: { DefaultPickDestinationLocationId?: number } } }[] } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.transloadId)) {
      missingRequiredInParams.push('\'transloadId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/TransloadOrders/datasources/ds_get_transload_by_transloadId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      transloadId: number    }) {
        if(isNil(inParams.transloadId)) {
          return true; 
        }
      return false;
    }
}
