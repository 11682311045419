import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Ultraship_ds_get_load_containers_to_sendService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { $top?: number, $skip?: number }): Promise<{ result?: { OrderId?: number, ShipmentId?: number, ProjectId?: number, LoadContainerId?: number }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Ultraship/datasources/ds_get_load_containers_to_send/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      }) {
      return false;
    }
}
