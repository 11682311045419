import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_lines_grid_ComponentContextService } from './FootprintManager.manufacturing_order_lines_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_materials_by_project_dd_singleComponent } from './Manufacturing.materials_by_project_dd_single.component'
import { Manufacturing_material_packagings_dd_singleComponent } from './Manufacturing.material_packagings_dd_single.component'


import { FootprintManager_manufacturing_order_lines_components_gridComponent } from './FootprintManager.manufacturing_order_lines_components_grid.component';

interface IFootprintManager_manufacturing_order_lines_gridComponentEntity {
  Id?: number, FinishedGoodLineId?: number, LineNumber?: number, MaterialId?: number, OrderId?: number, PackagedAmount?: number, PackagingId?: number, ParentId?: number, StatusId?: number, Material?: { ControllerTypeId?: number, Description?: string, KitTypeId?: number, LookupCode?: string, Name?: string, PackagingLookups?: { MaterialId?: number, PackagingId?: number, Packaging?: { ShortName?: string } }[] }, Status?: { Name?: string }}

interface IFootprintManager_manufacturing_order_lines_gridComponentInParams {
  order_id: number, project_id: number, order_status_id: number, warehouse_id: number, output_location_id?: number, source_location_id?: number}


class FootprintManager_manufacturing_order_lines_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_order_lines_gridComponent;
  entity: IFootprintManager_manufacturing_order_lines_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    finishedgood_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    plannedquantity_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    usekitcomponents_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    linenumber: null,
    finishedgood: null,
    description: null,
    plannedquantity: null,
    packaging: null,
    iskit: null,
    usekitcomponents: null,
  }

  get $fields_finishedgood_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_material_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.finishedgood.editControl.value;
    
    return expr;
  }

  //#region rowExpand inParams


  get $rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_finished_good_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouse_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_finished_good_quantity(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.plannedquantity.editControl.value;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_is_kit(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Material?.KitTypeId > 0 ? true : false;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_order_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.OrderId;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_line_number(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.LineNumber;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_components_grid_inParams_order_status_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.order_status_id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    grid: FootprintManager_manufacturing_order_lines_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.linenumber = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.linenumber,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.finishedgood = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.finishedgood,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['finishedgood_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.description = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.description,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.plannedquantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.plannedquantity,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['plannedquantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.iskit = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.iskit,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.usekitcomponents = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.usekitcomponents,
      new TextModel(null, null, null, null, null)
,
      new CheckBoxModel(this.formGroup.controls['usekitcomponents_edit'] as DatexFormControl, null, false, false, 'Use Kit Components', null)
      );
    
    
    this.formGroup
      .controls['finishedgood_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_finished_good_change();
      });
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_order_lines_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_order_lines_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      order_id:  $grid.inParams.order_id ,
      full_text_search:  $grid.fullTextSearch ,
    };
    const data = await this.$datasources.Manufacturing.ds_manufacturing_order_lines_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_order_lines_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity?.LineNumber?.toString();
    (this.cells.finishedgood.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.finishedgood.editControl as SelectBoxModel).reset($row.entity.MaterialId);
    (this.cells.description.displayControl as TextModel).text = $row.entity.Material?.Description;
    (this.cells.plannedquantity.displayControl as TextModel).text = $row.entity.PackagedAmount?.toString();
    (this.cells.plannedquantity.editControl as NumberBoxModel).reset($row.entity.PackagedAmount);
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Material?.PackagingLookups.find(x => x.PackagingId == $row.entity.PackagingId).Packaging.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity.PackagingId);
    (this.cells.iskit.displayControl as TextModel).text = $row.entity.Material?.KitTypeId > 0 ? 'Yes' : 'No';

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'description' : {
        this.on_finished_good_click();
        break;
      }
    }
  }

  //#region private flows
  on_finished_good_change(event = null) {
    return this.on_finished_good_changeInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_finished_good_changeInternal(
    $row: FootprintManager_manufacturing_order_lines_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_finished_good_change');
  // Clear related cells
  $row.cells.packaging.editControl.value = null;
  
  const materialId = $row.cells.finishedgood.editControl.value;
  
  if ($utils.isDefined(materialId)) {
  
      // Default the packaging
      const defaultPackaging = (await $flows.Manufacturing.get_material_default_packaging({ material_id: materialId }));
      const packagingId = defaultPackaging.packagingId;
  
      if ($utils.isDefined(packagingId)) {
  
          $row.cells.packaging.editControl.value = packagingId;
  
          // Default the decimal formatting
          const decimalFormat = (await $flows.Manufacturing.get_material_packaging_decimal_precision_formatted({
              material_id: materialId,
              packaging_id: packagingId
          }));
  
          if ($utils.isDefined(decimalFormat)) {
              $row.cells.plannedquantity.editControl.format = decimalFormat.format;
  
          }
      }
  
      const material = (await $datasources.Manufacturing.ds_get_material_by_materialId.get({
          material_id: materialId
      })).result;
  
      if( material.KitTypeId > 0 )
          $row.cells.iskit.displayControl.text = "Yes";
  
      $row.cells.description.displayControl.text = material.Description;
  
      if( $row.entity.FinishedGoodLineId > 0 )
      {
          const deleteOperation = (await $flows.Manufacturing.delete_manufacturing_order_line_components_flow({
              order_line_id: $row.entity.FinishedGoodLineId
          }));
      }
  
      if( material.KitTypeId <= 0 || material.KitTypeId == null )
      {
          $row.cells.usekitcomponents.editControl.readOnly = true;
      }
      else if( material.KitTypeId > 0 )
      {
          $row.cells.usekitcomponents.editControl.readOnly = false;
          $row.cells.usekitcomponents.editControl.value = true;
      }
  }
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootprintManager_manufacturing_order_lines_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_init_new_row');
  $row.entity = {
      OrderId: $grid.inParams.order_id,
      PackagedAmount: 0,
  };
  
  $row.cells.usekitcomponents.editControl.readOnly = true;
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_manufacturing_order_lines_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_save_new_row');
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.finishedgood.editControl.value) &&
      $utils.isDefined($row.cells.packaging.editControl.value) &&
      $utils.isDefined($row.cells.plannedquantity.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Utilities.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields');
  }
  
  if ($row.cells.plannedquantity.editControl.value <= 0) {
      $shell.Utilities.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
      throw new Error('Order line quantity must be greater than 0.');
  }
  
  if ($row.cells.packaging.editControl.value <= 0) {
      $shell.Utilities.openErrorDialog('Save', 'Missing packaging.');
      throw new Error('Missing packaging.');
  }
  
  var orderStatusId = $grid.inParams.order_status_id;
  if ((orderStatusId === 1 || orderStatusId === 2)) {
  
      try {
          var orderId = $grid.inParams.order_id;
  
          if (($utils.isDefined($row.cells.packaging.editControl.value))) {
              var grossWeight = $row.cells.packaging.editControl.value;
          }
  
          // Material only provided
          const orderLine = (await $flows.Manufacturing.create_manufacturing_order_line_flow({
              order_id: orderId,
              packaged_amount: $row.cells.plannedquantity.editControl.value,
              material_id: $row.cells.finishedgood.editControl.value,
              packaging_id: $row.cells.packaging.editControl.value,
              line_number: $grid.rows.length,
              finished_good_id: null
          }));
  
          if (!$utils.isDefined(orderLine.reasons)) {
              $row.entity.LineNumber = $grid.rows.length;
              $row.entity.OrderId = orderId;
              $grid.events.order_lines_change.emit();
          }
          else {
              throw new Error('Unable to create order line.' + orderLine.reasons);
          }
  
          if( $row.cells.iskit.displayControl.text.toString() == "Yes" && $row.cells.usekitcomponents.editControl.value == true )
          {
              const kitComponents = (await $datasources.Manufacturing.ds_get_kit_materials.get({
                  material_id: $row.cells.finishedgood.editControl.value
              }))?.result;
  
              var createdComponentsCount = 0;
              for (let kitcomponent of kitComponents) {
  
                  createdComponentsCount++;
  
                  const componentOrderLine = (await $flows.Manufacturing.create_manufacturing_order_line_flow({
                      order_id: orderId,
                      packaged_amount: kitcomponent.ComponentPackagedAmount,
                      material_id: kitcomponent.ComponentMaterialId,
                      packaging_id: kitcomponent.ComponentPackagedId,
                      line_number: createdComponentsCount,
                      finished_good_id: orderLine.Id
                  }));
  
                  if ($utils.isDefined(componentOrderLine.reasons)) {
                      $shell.Utilities.showErrorDetails('Save', 'Unable to create order line.', orderLine.reasons);
                      throw new Error('Unable to create order line.' + orderLine.reasons);
                  }
              };
          }
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.Utilities.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  
  }
  else {
      $shell.Utilities.openErrorDialog('Save', 'Order is not in a created or processing status');
      throw new Error('Order is not in a created or processing status'); // to prevent displayMode 
  
  }
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_manufacturing_order_lines_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_save_existing_row');
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.packaging.editControl.value) &&
      $utils.isDefined($row.cells.plannedquantity.editControl.value) &&
      $utils.isDefined($row.cells.packaging.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Utilities.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields');
  }
  
  const payload: any = {};
  
  if ($row.cells.packaging.editControl.isChanged) {
      payload.PackagedId = $row.cells.packaging.editControl.value;
  }
  if ($row.cells.plannedquantity.editControl.isChanged) {
      if ($row.cells.plannedquantity.editControl.value <= 0) {
          $shell.Utilities.openErrorDialog('Save', 'Manufacturing order line planned quantity must be greater than 0.');
          throw new Error('Manufacturing order line planned quantity must be greater than 0.');
      }
      else {
          payload.PackagedAmount = $row.cells.plannedquantity.editControl.value;
      }
  }
  
  try {
  
      const orderLine = (await $flows.Utilities.crud_update_flow({
      entitySet: "ManufacturingOrderLines",
      id: $row.entity.Id,
      entity:
      {
          PackagedAmount: $row.cells.plannedquantity.editControl.value,
      }}));
  
      $grid.events.order_lines_change.emit();
      await $row.refresh();
  } catch (error) {
      $shell.Utilities.showErrorDetails('Save', 'Error on save.', error);
      throw error;
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_manufacturing_order_lines_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_row_data_loaded');
  if( $row.entity.Id != null )
  {
      $row.cells.finishedgood.editControl.readOnly = true;
      $row.cells.usekitcomponents.editControl.readOnly = true;
  }
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: FootprintManager_manufacturing_order_lines_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_packaging_changed');
  
  const materialId = $row.cells.finishedgood.editControl.value;
  const packagingId = $row.cells.packaging.editControl.value;
  
  const warehouseId = $grid.inParams.warehouse_id;
  
  if ($utils.isAllDefined(materialId, packagingId)) {
  
      // Default to base material packaging
      const packaging = (await $datasources.Manufacturing.ds_get_material_packaging_by_materialId_and_packagingId.get({
          material_id: materialId,
          packaging_id: packagingId
      }))?.result;
  
      // Default the decimal formatting
      const decimalFormat = (await $flows.Manufacturing.get_material_packaging_decimal_precision_formatted({
          material_id: materialId,
          packaging_id: packagingId
      }));
  
      if ($utils.isDefined(decimalFormat)) {
          $row.cells.plannedquantity.editControl.format = decimalFormat.format;
      }
  }
  }
  on_finished_good_click(event = null) {
    return this.on_finished_good_clickInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_finished_good_clickInternal(
    $row: FootprintManager_manufacturing_order_lines_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_finished_good_click');
  
  await $shell.FootprintManager.openmaterial_editorDialog({ materialId: $row.entity.MaterialId }, "modal");
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_materials_by_project_dd_singleComponent),
    forwardRef(() => Manufacturing_material_packagings_dd_singleComponent),
    forwardRef(() => FootprintManager_manufacturing_order_lines_components_gridComponent)
  ],
  selector: 'FootprintManager-manufacturing_order_lines_grid',
  templateUrl: './FootprintManager.manufacturing_order_lines_grid.component.html'
})
export class FootprintManager_manufacturing_order_lines_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_order_lines_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_manufacturing_order_lines_gridComponentInParams = { order_id: null, project_id: null, order_status_id: null, warehouse_id: null, output_location_id: null, source_location_id: null };


  //#region Variables
  //#endregion
  //#region Events
  @Output()
  order_lines_change = new EventEmitter<void>();
  
  events = {
    order_lines_change: this.order_lines_change,
  }
  //#endregion

  headers = {
     linenumber: new GridHeaderModel(new HeaderStyles(null, null), 'linenumber', '#', false, false, null, false),       finishedgood: new GridHeaderModel(new HeaderStyles(null, null), 'finishedgood', 'Finished product', true, false, null, false),       description: new GridHeaderModel(new HeaderStyles(null, null), 'description', 'Description', false, false, null, false),       plannedquantity: new GridHeaderModel(new HeaderStyles(null, null), 'plannedquantity', 'Planned quantity', true, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', true, false, null, false),       iskit: new GridHeaderModel(new HeaderStyles(null, null), 'iskit', 'Kit', false, false, null, false),       usekitcomponents: new GridHeaderModel(new HeaderStyles(null, null), 'usekitcomponents', ' ', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_order_lines_gridComponentRowModel[] = [];

  @Input('order_id') set $inParams_order_id(value: any) {
    this.inParams['order_id'] = value;
  }
  get $inParams_order_id(): any {
    return this.inParams['order_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('order_status_id') set $inParams_order_status_id(value: any) {
    this.inParams['order_status_id'] = value;
  }
  get $inParams_order_status_id(): any {
    return this.inParams['order_status_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('output_location_id') set $inParams_output_location_id(value: any) {
    this.inParams['output_location_id'] = value;
  }
  get $inParams_output_location_id(): any {
    return this.inParams['output_location_id'] ;
  }
  @Input('source_location_id') set $inParams_source_location_id(value: any) {
    this.inParams['source_location_id'] = value;
  }
  get $inParams_source_location_id(): any {
    return this.inParams['source_location_id'] ;
  }

  topToolbar = {
      manufacturing_production_confirmation: new ToolModel(new ButtonModel('manufacturing_production_confirmation', new ButtonStyles(null, null), true, false, false, 'Confirm production', 'icon-ic_fluent_checkmark_20_regular', null)
    , false),
      separator11: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      addfinishedproduct: new ToolModel(new ButtonModel('addfinishedproduct', new ButtonStyles(null, null), false, false, false, 'Add finished product', 'ms-Icon ms-Icon--Add', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      deleteorder: new ToolModel(new ButtonModel('deleteorder', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator4: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    , false),
      additionalmenu: new ToolModel(new SplitButtonModel<{ submenuone: ButtonModel }>(
        'additionalmenu',
        new ButtonStyles(null, null),
        false,
        false,
        ' ',
        'ms-Icon ms-Icon--List',
        null,
        [
          new ButtonModel('submenuone', new ButtonStyles(null, null), false, false, false, 'Sub Menu', '')
        ])
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Manufacturing order lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.order_status_id)) {
        this.$missingRequiredInParams.push('order_status_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      order_id:  $grid.inParams.order_id ,
      full_text_search:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_manufacturing_order_lines_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_order_lines_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_order_lines_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_manufacturing_order_lines_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintManager_manufacturing_order_lines_gridComponentEntity) {
    const row = new FootprintManager_manufacturing_order_lines_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_manufacturing_order_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_init');
  const orderStatusId = $grid.inParams.order_status_id;
  
  if (orderStatusId === 1) {
      $grid.canEdit = true;
      $grid.topToolbar.addfinishedproduct.control.readOnly = false;
      $grid.topToolbar.deleteorder.control.readOnly = false;
      $grid.topToolbar.manufacturing_production_confirmation.control.readOnly = false;
      $grid.headers.finishedgood.required = true;
      $grid.headers.packaging.required = true;
      $grid.headers.plannedquantity.required = true;
  }
  else {
      $grid.canEdit = false;
      $grid.topToolbar.addfinishedproduct.control.readOnly = true;
      $grid.topToolbar.deleteorder.control.readOnly = true;
      $grid.topToolbar.manufacturing_production_confirmation.control.readOnly = true;
      $grid.headers.finishedgood.required = false;
      $grid.headers.packaging.required = false;
      $grid.headers.finishedgood.required = false;
  
  }
  
  $grid.bottomToolbar.addLine.hidden = true;
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_manufacturing_order_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_row_selected');
  const orderStatusId = $grid.inParams.order_status_id;
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected and order status is created
  // TODO: Add authorization check
  if (selectedRowsCount > 0 && orderStatusId === 1 /*&& !(await $operations.FootPrintManager.Disable_SalesOrder_Editing.isAuthorized())*/) {
      $grid.topToolbar.deleteorder.control.readOnly = false;
      $grid.topToolbar.manufacturing_production_confirmation.control.readOnly = false;
  }
  else {
      $grid.topToolbar.deleteorder.control.readOnly = true;
      $grid.topToolbar.manufacturing_production_confirmation.control.readOnly = true;
  }
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootprintManager_manufacturing_order_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_delete');
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Utilities.openErrorDialog('Order Line Delete Error', 'No order lines selected.');
      return;
  }
  
  const candidates = [];
  const failures = [];
  const errors = [];
  
  for (const row of selectedRows) {
      candidates.push(row);
  }
  
  // no candidate
  if (candidates.length === 0) {
      const title = 'Delete order line errors';
      const errorMessage = `Order line(s) ${failures.map(c => c.entity.LineNumber.toString()).join(',')} cannot be deleted`;
      const errorList = errors;
      await $shell.Utilities.openErrorDialog(title, errorMessage, errorList);
      return;
  } else {
      const confirmCandidates = `${candidates.map(c => c.entity.LineNumber.toString()).join(',')}`
  
      let confirm = false;
      if (failures.length >= 1) {
          const title = 'Some order line(s) cannot be deleted';
          const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
          confirm = await $shell.Utilities.openConfirmationDialog(title, message);
      } else {
          const title = `Delete order line${confirmCandidates.length > 1 ? 's' : ''} ${confirmCandidates} ?`;
          const message = `Once deleted, the line${confirmCandidates.length > 1 ? 's' : ''} can not be restored`;
          confirm = await $shell.Utilities.openConfirmationDialog(title, message, `Delete line${confirmCandidates.length > 1 ? 's' : ''}`);
      }
  
      if (confirm) {
          const processSuccess = [];
          const processFailures = [];
          const errorMsgList = [];
          const errorMsgListDetails = [];
  
          for (const candidate of candidates) {
              try {
                  const flowParams = {
                      order_id: candidate.entity.Id,
                      line_number: candidate.entity.LineNumber
                  }
                  const result = await $flows.Manufacturing.delete_manufacturing_order_lines_flow(flowParams);
                  const reason = result.reason;
                  if ($utils.isDefined(reason)) {
                      processFailures.push(candidate);
                      errorMsgList.push(`Order ${candidate.entity.LookupCode} - ${reason}`);
                  } else {
                      processSuccess.push(candidate);
                  }
  
              } catch (error) {
                  processFailures.push(candidate);
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Order line ${candidate.entity.LineNumber.toString()} - ${errorMessage}`;
                  errorMsgList.push(errorDescription);
                  errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
              }
          }
  
          // all succeeded
          if (processSuccess.length === candidates.length) {
              const title = 'All order line(s) deleted';
              const message = `Order line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} deleted`;
              $shell.Utilities.openToaster(title, message, EToasterType.Success, {timeOut: 5000, positionClass:EToasterPosition.bottomRight, progressBar: false, tapToDismiss: true});
              await $grid.refresh();
          } else {
              // all failures
              if (processFailures.length === candidates.length) {
                  const title = 'All order line(s) failed to delete';
                  const message = `Order line(s) ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')} could not be deleted`;
                  await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
              } else {
                  const title = 'Some order lines could not be deleted';
                  const success = `Order line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} were deleted.`;
                  const errors = `The following order line(s) could not be deleted: ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')}`;
                  const message = `${success} \r\n\r\n${errors}`;
                  await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  await $grid.refresh();
              }
          }
  
          $grid.events.order_lines_change.emit();
      }
  }
  }
  on_add_new_finished_good_clicked(event = null) {
    return this.on_add_new_finished_good_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_new_finished_good_clickedInternal(
    $grid: FootprintManager_manufacturing_order_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_add_new_finished_good_clicked');
  
  const newRow = await $grid.addRow();
  
  newRow.cells.finishedgood.editControl.focus();
  }
  on_refresh_clicked(event = null) {
    return this.on_refresh_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_clickedInternal(
    $grid: FootprintManager_manufacturing_order_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_refresh_clicked');
  $grid.refresh();
  }
  on_production_confirmation_clicked(event = null) {
    return this.on_production_confirmation_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_production_confirmation_clickedInternal(
    $grid: FootprintManager_manufacturing_order_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_grid.on_production_confirmation_clicked');
  const selectedRow = $grid.selectedRows;
  
  if( selectedRow.length <= 0 || selectedRow.length > 1 )
      return;
  
  await $shell.FootprintManager.openmanufacturing_order_production_confirmation_wizardDialog({
                                  scheduled_run_code: 1,
                                  warehouse_id: $grid.inParams.warehouse_id,
                                  output_location_id: $grid.inParams.output_location_id,
                                  project_id: $grid.inParams.project_id,
                                  planned_quantity: selectedRow[0].entity.PackagedAmount,
                                  finished_good: selectedRow[0].entity.Material?.Name,
                                  material_id: selectedRow[0].entity.MaterialId,
                                  finished_good_id: selectedRow[0].entity.Id,
                                  expected_source_location: $grid.inParams.source_location_id,
                                  manufacturing_order_id: selectedRow[0].entity.OrderId,
                                  finished_product_is_serialized: selectedRow[0].entity.Material?.ControllerTypeId == 3 || selectedRow[0].entity.Material?.ControllerTypeId == 4})
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
