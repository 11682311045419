import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { ExcelOrderImport_RFTESTService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_adjust_asn_orderline_and_shipmentline_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_create_asn_order_line_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_create_asn_serial_numbers_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_create_purchase_order_line_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_create_sales_order_line_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_create_transload_order_line_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_generate_asn_child_serial_numbers_flowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_set_asn_receiving_task_flowService } from './ExcelOrderImport.flow.index';

import { $frontendTypes } from './ExcelOrderImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public ExcelOrderImport: ExcelOrderImport_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _RFTEST: ExcelOrderImport_RFTESTService;
  public async RFTEST(inParams: {  }): Promise< { output?: string[] }> {
    if(!this._RFTEST) { 
      this._RFTEST = this.injector.get(ExcelOrderImport_RFTESTService);
    }
    return this._RFTEST.run(inParams);
  }
   
   

   
 
  private _adjust_asn_orderline_and_shipmentline_flow: ExcelOrderImport_adjust_asn_orderline_and_shipmentline_flowService;
  public async adjust_asn_orderline_and_shipmentline_flow(inParams: { adjustPackagedAmount: number, shipmentlineId: number, orderId: number, orderlineNumber: number }): Promise<void> {
    if(!this._adjust_asn_orderline_and_shipmentline_flow) { 
      this._adjust_asn_orderline_and_shipmentline_flow = this.injector.get(ExcelOrderImport_adjust_asn_orderline_and_shipmentline_flowService);
    }
    return this._adjust_asn_orderline_and_shipmentline_flow.run(inParams);
  }
   
   

   
 
  private _create_asn_order_line_flow: ExcelOrderImport_create_asn_order_line_flowService;
  public async create_asn_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId: number, packagedAmount: number, materialId?: number, lotId?: number, vendorlotId?: number, notes?: string, warehouseId?: number, licensePlateLookupCode?: string, serialNumberLookupCode?: string, netWeight?: number, grossWeight?: number, weightUomId?: number, length?: number, width?: number, height?: number, dimensionUomId?: number }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean, receiveTaskId?: number }> {
    if(!this._create_asn_order_line_flow) { 
      this._create_asn_order_line_flow = this.injector.get(ExcelOrderImport_create_asn_order_line_flowService);
    }
    return this._create_asn_order_line_flow.run(inParams);
  }
   
   

   
 
  private _create_asn_serial_numbers_flow: ExcelOrderImport_create_asn_serial_numbers_flowService;
  public async create_asn_serial_numbers_flow(inParams: { serialNumbers: { Archived?: boolean, ChainHead?: number, DimensionUOM?: number, GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LicensePlateId?: number, LotId?: number, NetVolumeValue?: number, NetWeightValue?: number, Notes?: string, OutboundShipmentId?: number, PackagingId?: number, StatusId?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, LookupCode?: string, MaterialId?: number }[] }): Promise< { reasons?: string[], serialNumberIds?: number[] }> {
    if(!this._create_asn_serial_numbers_flow) { 
      this._create_asn_serial_numbers_flow = this.injector.get(ExcelOrderImport_create_asn_serial_numbers_flowService);
    }
    return this._create_asn_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _create_purchase_order_line_flow: ExcelOrderImport_create_purchase_order_line_flowService;
  public async create_purchase_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId: number, packagedAmount: number, materialId?: number, lotId?: number, vendorlotId?: number, notes?: string }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_purchase_order_line_flow) { 
      this._create_purchase_order_line_flow = this.injector.get(ExcelOrderImport_create_purchase_order_line_flowService);
    }
    return this._create_purchase_order_line_flow.run(inParams);
  }
   
   

   
 
  private _create_sales_order_line_flow: ExcelOrderImport_create_sales_order_line_flowService;
  public async create_sales_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId?: number, packagedAmount?: number, materialId?: number, lotId?: number, vendorlotId?: number, serialnumberId?: number, notes?: string, licensePlateId?: number }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_sales_order_line_flow) { 
      this._create_sales_order_line_flow = this.injector.get(ExcelOrderImport_create_sales_order_line_flowService);
    }
    return this._create_sales_order_line_flow.run(inParams);
  }
   
   

   
 
  private _create_transload_order_line_flow: ExcelOrderImport_create_transload_order_line_flowService;
  public async create_transload_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId: number, packagedAmount: number, materialId?: number, lotId?: number, vendorlotId?: number, notes?: string }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_transload_order_line_flow) { 
      this._create_transload_order_line_flow = this.injector.get(ExcelOrderImport_create_transload_order_line_flowService);
    }
    return this._create_transload_order_line_flow.run(inParams);
  }
   
   

   
 
  private _generate_asn_child_serial_numbers_flow: ExcelOrderImport_generate_asn_child_serial_numbers_flowService;
  public async generate_asn_child_serial_numbers_flow(inParams: { serialNumberIds: number[] }): Promise< { reasons?: string[] }> {
    if(!this._generate_asn_child_serial_numbers_flow) { 
      this._generate_asn_child_serial_numbers_flow = this.injector.get(ExcelOrderImport_generate_asn_child_serial_numbers_flowService);
    }
    return this._generate_asn_child_serial_numbers_flow.run(inParams);
  }
   
   

   
 
  private _set_asn_receiving_task_flow: ExcelOrderImport_set_asn_receiving_task_flowService;
  public async set_asn_receiving_task_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, orderId: number, vendorlotId: number, packagedAmount: number, serialNumbersToCreate?: { DimensionUOM?: number, GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, LicensePlateId?: number, LotId?: number, PackagingId?: number, MaterialId?: number }[], serialNumberIdsToDelete?: number[], materialId: number, existingTaskId?: number }): Promise< { taskId?: number, orderlineNumber?: number }> {
    if(!this._set_asn_receiving_task_flow) { 
      this._set_asn_receiving_task_flow = this.injector.get(ExcelOrderImport_set_asn_receiving_task_flowService);
    }
    return this._set_asn_receiving_task_flow.run(inParams);
  }
   
   

   
}
