import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { AsnOrders_DatasourceService } from './AsnOrders.datasource.index';

interface IAsnOrders_receiving_reportServiceInParams {
  orderId: number}

interface IAsnOrders_receiving_reportServiceData {
  Header?: { result?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { LookupCode?: string, Notes?: string, PickupDate?: string, SealId?: string, TrailerId?: string, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string } } }[], Project?: { LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string } } }
  Lines?: { result?: { OrderId?: number, LineNumber?: number, PackagedAmount?: number, ShipmentLines?: { ActualPackagedAmount?: number, ActualPackaged?: { ShortName?: string } }[], InventoryMeasurementUnit?: { ShortName?: string }, Material?: { ControllerTypeId?: number, Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string } }, Totals?: { TotalPackagedAmount?: number, TotalGrossWeight?: number, OrderId?: number, OrderLineNumber?: number } }[] }
  WarehouseContact?: { result?: { Id?: number, PreferredWarehouse?: { Id?: number, WarehousesContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }
  OwnerContact?: { result?: { Id?: number, Project?: { Id?: number, Owner?: { Id?: number, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }
  AccountContact?: { result?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }
}

@Injectable({ providedIn: 'root' })
export class AsnOrders_receiving_reportService extends ReportBaseService<IAsnOrders_receiving_reportServiceInParams, IAsnOrders_receiving_reportServiceData> {

  protected reportReferenceName = 'receiving_report';
  protected appReferenceName = 'AsnOrders';

  constructor(
    $utils: UtilsService,
    private $datasources: AsnOrders_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IAsnOrders_receiving_reportServiceInParams) {
  }

  protected async getData(inParams: IAsnOrders_receiving_reportServiceInParams): Promise<IAsnOrders_receiving_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IAsnOrders_receiving_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IAsnOrders_receiving_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.AsnOrders.ds_receiving_header_report.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.AsnOrders.ds_receiving_details_report.get(dsParams);
      
      data.Lines = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.AsnOrders.ds_get_order_warehouse_contact_top1.get(dsParams);
      
      data.WarehouseContact = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.AsnOrders.ds_get_order_owner_contact_top1.get(dsParams);
      
      data.OwnerContact = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.AsnOrders.ds_get_order_ship_from_account_vs_order_address.get(dsParams);
      
      data.AccountContact = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
