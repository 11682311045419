import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_location_grid_ComponentContextService } from './FootprintManager.inventory_location_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_inventory_location_gridComponentEntity {
  LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { Id?: number, Name?: string, WarehouseId?: number, Warehouse?: { Name?: string }, ParentLocationContainer?: { Name?: string } }, Lot?: { LookupCode?: string, ReceiveDate?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { Name?: string, ShortName?: string }}

interface IFootprintManager_inventory_location_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseId?: number, locationId?: number, materialIds?: number[], lotId?: number, zonePath?: string}


class FootprintManager_inventory_location_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_inventory_location_gridComponent;
  entity: IFootprintManager_inventory_location_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    warehouse: null,
    parent: null,
    location_name: null,
    project: null,
    material_lookupcode: null,
    lot_lookupcode: null,
    total_packaged_amount: null,
    available_packaged_amount: null,
    inbound_order: null,
    expiration_date: null,
  }



  constructor(
    grid: FootprintManager_inventory_location_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_location_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.warehouse = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouse,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.parent = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.parent,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.location_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.project = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.project,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.material_lookupcode = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.material_lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot_lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot_lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.total_packaged_amount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.total_packaged_amount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.available_packaged_amount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.available_packaged_amount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.inbound_order = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.inbound_order,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.expiration_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.expiration_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_inventory_location_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_inventory_location_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'LocationId',this.entity.LocationId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      locationId:  $grid.inParams.locationId ,
      materialIds:  $grid.inParams.materialIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      lotId:  $grid.inParams.lotId ,
      take:  null ,
      skip:  null ,
      zonePath:  $grid.inParams.zonePath ,
    };
    const data = await this.$datasources.Inventory.ds_inventory_location_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_inventory_location_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.Location?.Warehouse.Name;
    (this.cells.parent.displayControl as TextModel).text = $row.entity.Location?.ParentLocationContainer?.Name;
    (this.cells.location_name.displayControl as TextModel).text = $row.entity.Location?.Name;
    (this.cells.project.displayControl as TextModel).text = $row.entity.Lot?.Material?.Project?.LookupCode;
    (this.cells.material_lookupcode.displayControl as TextModel).text = `${$row.entity.Lot?.Material?.LookupCode} / ${$row.entity.Lot?.Material?.Description}`;
    (this.cells.lot_lookupcode.displayControl as TextModel).text = `${$row.entity.Lot?.LookupCode} / ${$row.entity.Lot?.VendorLot?.LookupCode}`;
    (this.cells.total_packaged_amount.displayControl as TextModel).text = `${$row.entity.PackagedAmount?.toString()} ${$row.entity.Packaging?.ShortName}`;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'project' : {
        this.on_project_clicked();
        break;
      }
      case 'material_lookupcode' : {
        this.on_material_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_inventory_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Apply date format
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ExpirationDate) && $utils.isDefined($settings.FootprintManager.DateFormat)) {
      var dateFormat = $settings.FootprintManager.DateFormat;
      var expirationDate = $utils.date.format($row.entity.Lot?.VendorLot?.ExpirationDate, dateFormat);
      $row.cells.expiration_date.displayControl.text = expirationDate;
  }
  
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ManufactureDate) && $utils.isDefined($settings.FootprintManager.DateFormat)) {
      var dateFormat = $settings.FootprintManager.DateFormat;
      var manufactureDate = $utils.date.format($row.entity.Lot?.VendorLot?.ManufactureDate, dateFormat);
  }
  
  
  if ($utils.isDefined($row.entity.Lot?.ReceiveDate) && $utils.isDefined($settings.FootprintManager.DateFormat)) {
      var dateFormat = $settings.FootprintManager.DateFormat;
      var receivedDate = $utils.date.format($row.entity.Lot?.ReceiveDate, dateFormat);
  }
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.Vat)) {
  
      $row.cells.lot_lookupcode.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode + ' ' + $row.entity.Lot?.VendorLot?.Vat
  } else {
      $row.cells.lot_lookupcode.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode
  }
  
  }
  on_location_clicked(event = null) {
    return this.on_location_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_clickedInternal(
    $row: FootprintManager_inventory_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_location_grid.on_location_clicked');
  
  
  $shell.FootprintManager.opensingle_location_hub({locationId: $row.entity.LocationId});
  }
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: FootprintManager_inventory_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_location_grid.on_lot_clicked');
  
  
  $shell.FootprintManager.opensingle_lot_hub({lotId: $row.entity.LotId});
  }
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootprintManager_inventory_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootprintManager.opensingle_material_hub({materialId: $row.entity.Lot.Material.Id});
  }
  on_project_clicked(event = null) {
    return this.on_project_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_clickedInternal(
    $row: FootprintManager_inventory_location_gridComponentRowModel,
  $grid: FootprintManager_inventory_location_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootprintManager.opensingle_project_hub({projectId: $row.entity.Lot.Material.ProjectId})
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-inventory_location_grid',
  templateUrl: './FootprintManager.inventory_location_grid.component.html'
})
export class FootprintManager_inventory_location_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_inventory_location_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_inventory_location_gridComponentInParams = { ownerId: null, projectId: null, warehouseId: null, locationId: null, materialIds: [], lotId: null, zonePath: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),       parent: new GridHeaderModel(new HeaderStyles(null, null), 'parent', 'Parent zone', false, false, null, false),       location_name: new GridHeaderModel(new HeaderStyles(null, null), 'location_name', 'Location', false, false, null, false),       project: new GridHeaderModel(new HeaderStyles(null, null), 'project', 'Project', false, false, null, false),       material_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'material_lookupcode', 'Material / Description', false, false, 150, false),       lot_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lot_lookupcode', 'Lot / Vendor lot', false, false, null, false),       total_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'total_packaged_amount', 'Total quantity', false, false, null, false),       available_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'available_packaged_amount', 'Available quantity', false, false, null, false),       inbound_order: new GridHeaderModel(new HeaderStyles(null, null), 'inbound_order', 'Inbound order', false, false, null, false),       expiration_date: new GridHeaderModel(new HeaderStyles(null, null), 'expiration_date', 'Expires on', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_inventory_location_gridComponentRowModel[] = [];

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('locationId') set $inParams_locationId(value: any) {
    this.inParams['locationId'] = value;
  }
  get $inParams_locationId(): any {
    return this.inParams['locationId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }
  @Input('zonePath') set $inParams_zonePath(value: any) {
    this.inParams['zonePath'] = value;
  }
  get $inParams_zonePath(): any {
    return this.inParams['zonePath'] ;
  }

  topToolbar = {
      adjust_inventory: new ToolModel(new ButtonModel('adjust_inventory', new ButtonStyles(null, null), false, false, false, 'Adjust quantity', 'icon-ic_fluent_box_multiple_20_regular', null)
    , false),
      move_inventory: new ToolModel(new ButtonModel('move_inventory', new ButtonStyles(null, null), false, false, false, 'Move inventory', 'icon-ic_fluent_box_multiple_arrow_right_20_regular', null)
    , false),
      transfer_inventory: new ToolModel(new ButtonModel('transfer_inventory', new ButtonStyles(null, null), false, false, false, 'Transfer inventory', 'icon-ic_fluent_person_swap_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_location_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Inventory';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      locationId:  $grid.inParams.locationId ,
      materialIds:  $grid.inParams.materialIds ,
      fullTextSearch:  $grid.fullTextSearch ,
      lotId:  $grid.inParams.lotId ,
      take:  null ,
      skip:  null ,
      zonePath:  $grid.inParams.zonePath ,
    };
    try {
      const data = await this.$datasources.Inventory.ds_inventory_location_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_inventory_location_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_inventory_location_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_inventory_location_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_adjust_inventory_clicked(event = null) {
    return this.on_adjust_inventory_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_adjust_inventory_clickedInternal(
    $grid: FootprintManager_inventory_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var locationId = $grid.selectedRows[0].entity.LocationId;
  
  const locationResult = (await $datasources.Locations.ds_get_location_by_locationId.get({
      locationId: locationId
  })).result;
  
  let availableAmount = $grid.selectedRows[0].cells.available_packaged_amount.displayControl.text
  var availablePackagedAmount: number = +availableAmount
  
  if ($utils.isDefined(locationResult)) {
  
      const typeId = locationResult[0].IsLoose
      if (typeId) {
  
          const licenseplates = (await $datasources.Inventory.ds_get_licenseplates_by_type_and_locationId.get({
              typeId: 3, // Loose Licenseplates
              locationId: locationId
          })).result
  
          if ($utils.isDefined(licenseplates)) {
              
  
              const dialogResult = await $shell.FootprintManager.openinventory_adjustment_formDialog({
                  licenseplateId: licenseplates[0].Id,
                  lotId: $grid.selectedRows[0].entity.LotId,
                  packagingId: $grid.selectedRows[0].entity.PackagedId,
                  packagedAmount: $grid.selectedRows[0].entity.PackagedAmount,
                  availablePackagedAmount: availablePackagedAmount
              }, "modal", EModalSize.Large)
              const userConfirmed = dialogResult.confirm;
              if (userConfirmed) {
                  $grid.refresh()
              }
          }
      } else {
  
          var userConfirm;
          var licenseplateId;
          var packagedAmount;
          var packagingId;
  
  
          const licenseplateContents = (await $datasources.Inventory.ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId.get({
              locationId: locationId,
              lotId: $grid.selectedRows[0].entity.LotId,
              packagingId: $grid.selectedRows[0].entity.PackagedId
          })).result
          if ($utils.isDefined(licenseplateContents) && licenseplateContents.length == 1) {
              userConfirm = true;
              licenseplateId = licenseplateContents[0].LicensePlateId;
              packagingId = licenseplateContents[0].PackagedId;
              packagedAmount = licenseplateContents[0].PackagedAmount
  
  
          } else {
              // Grid filters out shipping license plates
              const dialogResult = await $shell.FootprintManager.openlicenseplate_gridDialog({
                  locationId: locationId,
                  lotId: $grid.selectedRows[0].entity.LotId,
                  packagedId: $grid.selectedRows[0].entity.PackagedId
              })
  
              userConfirm = dialogResult.confirm;
              licenseplateId = dialogResult.licenseplateId;
              packagingId = dialogResult.packagingId;
              packagedAmount = dialogResult.packagedAmount;
  
  
          }
  
          if (userConfirm && $utils.isDefined(licenseplateId)) {
              const dialogResult = await $shell.FootprintManager.openinventory_adjustment_formDialog({
                  licenseplateId: licenseplateId,
                  lotId: $grid.selectedRows[0].entity.LotId,
                  packagingId: packagingId,
                  packagedAmount: packagedAmount,
                  availablePackagedAmount: availablePackagedAmount
              })
              const userConfirmed = dialogResult.confirm;
              if (userConfirmed) {
                  $grid.refresh()
              }
  
          }
      }
  }
  else {
  
      throw new Error('Unable to determine location')
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_inventory_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.adjust_inventory.control.readOnly = true;
  $grid.topToolbar.move_inventory.control.readOnly = true;
  $grid.topToolbar.transfer_inventory.control.readOnly = true;
  
  if ((await $operations.FootprintManager.Disable_Inventory_Adjustment.isAuthorized())) {
      $grid.topToolbar.adjust_inventory.hidden = true;
  }
  
  if ((await $operations.FootprintManager.Disable_Inventory_Movement.isAuthorized())) {
      $grid.topToolbar.move_inventory.hidden = true;
  }
  
  if ((await $operations.FootprintManager.Disable_InventoryGrid_TransferInventory.isAuthorized())) {
      $grid.topToolbar.transfer_inventory.hidden = true;
  }
  }
  on_move_inventory_clicked(event = null) {
    return this.on_move_inventory_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_move_inventory_clickedInternal(
    $grid: FootprintManager_inventory_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var locationId = $grid.selectedRows[0].entity.LocationId;
  
  const locationResult = (await $datasources.Locations.ds_get_location_by_locationId.get({
      locationId: locationId
  })).result;
  
  
  let availableAmount = $grid.selectedRows[0].cells.available_packaged_amount.displayControl.text
  var availablePackagedAmount: number = +availableAmount
  
  
  if ($utils.isDefined(locationResult)) {
  
      const isLoose = locationResult[0].IsLoose
      if (isLoose) {
  
          const licenseplates = (await $datasources.Inventory.ds_get_licenseplates_by_type_and_locationId.get({
              typeId: 3, // Loose Licenseplates
              locationId: locationId
          })).result
  
          if ($utils.isDefined(licenseplates)) {
  
              const dialogResult = await $shell.FootprintManager.openinventory_and_licenseplate_move_formDialog({
                  licenseplates: [{
                          id: licenseplates[0].Id,
                          content: [{
                                  lotId: $grid.selectedRows[0].entity.LotId,
                                  packagingId: $grid.selectedRows[0].entity.PackagedId,
                                  packagedAmount: $grid.selectedRows[0].entity.PackagedAmount,
                                  availablePackagedAmount: availablePackagedAmount > packagedAmount ? packagedAmount : availablePackagedAmount
                              }]
                      }]
              });
  
              const userConfirmed = dialogResult?.confirm;
              if (userConfirmed) {
                  await $grid.refresh()
              }
          }
      } else {
  
          var userConfirm;
          var licenseplateId;
          var packagedAmount;
          var packagingId;
  
  
          const licenseplateContents = (await $datasources.Inventory.ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId.get({
              locationId: locationId,
              lotId: $grid.selectedRows[0].entity.LotId,
              packagingId: $grid.selectedRows[0].entity.PackagedId
          })).result;
  
          if ($utils.isDefined(licenseplateContents) && licenseplateContents.length == 1) {
              userConfirm = true;
              licenseplateId = licenseplateContents[0].LicensePlateId;
              packagingId = licenseplateContents[0].PackagedId;
              packagedAmount = licenseplateContents[0].PackagedAmount
              availablePackagedAmount = availablePackagedAmount > packagedAmount ? packagedAmount : availablePackagedAmount;
  
          } else {
              // Grid filters out shipping license plates
              const dialogResult = await $shell.FootprintManager.openlicenseplate_gridDialog({
                  locationId: locationId,
                  lotId: $grid.selectedRows[0].entity.LotId,
                  packagedId: $grid.selectedRows[0].entity.PackagedId
              })
  
              userConfirm = dialogResult.confirm;
              licenseplateId = dialogResult.licenseplateId;
              packagingId = dialogResult.packagingId;
              packagedAmount = dialogResult.packagedAmount;
              availablePackagedAmount = availablePackagedAmount > packagedAmount ? packagedAmount : availablePackagedAmount;
  
  
          }
  
          if (userConfirm && $utils.isDefined(licenseplateId)) {
              const dialogResult = await $shell.FootprintManager.openinventory_and_licenseplate_move_formDialog({
                  licenseplates: [{
                          id: licenseplateId,
                          content: [{
                                  lotId: $grid.selectedRows[0].entity.LotId,
                                  packagingId: packagingId,
                                  packagedAmount: packagedAmount,
                                  availablePackagedAmount: availablePackagedAmount
                              }]
                      }]
              });
  
              const userConfirmed = dialogResult?.confirm;
              if (userConfirmed) {
                  await $grid.refresh()
              }
  
          }
      }
  }
  else {
  
      throw new Error('Unable to determine location')
  }
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_inventory_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const hasOneSelectedRows = $grid.selectedRows.length === 1;
  $grid.topToolbar.adjust_inventory.control.readOnly = !hasOneSelectedRows;
  $grid.topToolbar.move_inventory.control.readOnly = !hasOneSelectedRows;
  $grid.topToolbar.transfer_inventory.control.readOnly = !hasOneSelectedRows;
  
  
  
  if ((await $operations.FootprintManager.Disable_Inventory_Adjustment.isAuthorized())) {
      $grid.topToolbar.adjust_inventory.hidden = true;
  }
  
  if ((await $operations.FootprintManager.Disable_Inventory_Movement.isAuthorized())) {
      $grid.topToolbar.move_inventory.hidden = true;
  }
  
  if ((await $operations.FootprintManager.Disable_InventoryGrid_TransferInventory.isAuthorized())) {
      $grid.topToolbar.transfer_inventory.hidden = true;
  }
  }
  on_transfer_inventory_clicked(event = null) {
    return this.on_transfer_inventory_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transfer_inventory_clickedInternal(
    $grid: FootprintManager_inventory_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var locationId = $grid.selectedRows[0].entity.LocationId;
  
  const locationResult = (await $datasources.Locations.ds_get_location_by_locationId.get({
      locationId: locationId
  })).result;
  
  
  let availableAmount = $grid.selectedRows[0].cells.available_packaged_amount.displayControl.text
  var availablePackagedAmount: number = +availableAmount
  
  if ($utils.isDefined(locationResult)) {
  
      const typeId = locationResult[0].IsLoose
      if (typeId) {
  
          const licenseplates = (await $datasources.Inventory.ds_get_licenseplates_by_type_and_locationId.get({
              typeId: 3, // Loose Licenseplates
              locationId: locationId
          })).result
  
          if ($utils.isDefined(licenseplates)) {
  
              const dialogResult = await $shell.FootprintManager.openinventory_transfer_formDialog({
  
                  lotId: $grid.selectedRows[0].entity.LotId,
                  packagingId: $grid.selectedRows[0].entity.PackagedId,
                  licenseplateId: licenseplates[0].Id,
                  packagedAmount: $grid.selectedRows[0].entity.PackagedAmount,
                  materialId: $grid.selectedRows[0].entity.Lot.Material.Id,
                  availablePackagedAmount: availablePackagedAmount
              })
              const userConfirmed = dialogResult.confirm;
              if (userConfirmed) {
                  $grid.refresh()
              }
          }
      } else {
  
          var userConfirm;
          var licenseplateId;
          var packagedAmount;
          var packagingId;
  
  
          const licenseplateContents = (await $datasources.Inventory.ds_get_licenseplatecontents_by_locationId_lotId_and_packagingId.get({
              locationId: locationId,
              lotId: $grid.selectedRows[0].entity.LotId,
              packagingId: $grid.selectedRows[0].entity.PackagedId
          })).result
          if ($utils.isDefined(licenseplateContents) && licenseplateContents.length == 1) {
              userConfirm = true;
              licenseplateId = licenseplateContents[0].LicensePlateId;
              packagingId = licenseplateContents[0].PackagedId;
              packagedAmount = licenseplateContents[0].PackagedAmount
  
  
          } else {
              // Grid filters out shipping license plates
              const dialogResult = await $shell.FootprintManager.openlicenseplate_gridDialog({
                  locationId: locationId,
                  lotId: $grid.selectedRows[0].entity.LotId,
                  packagedId: $grid.selectedRows[0].entity.PackagedId
              })
  
              userConfirm = dialogResult.confirm;
              licenseplateId = dialogResult.licenseplateId;
              packagingId = dialogResult.packagingId;
              packagedAmount = dialogResult.packagedAmount;
  
  
          }
  
          if (userConfirm && $utils.isDefined(licenseplateId)) {
              const dialogResult = await $shell.FootprintManager.openinventory_transfer_formDialog({
  
                  lotId: $grid.selectedRows[0].entity.LotId,
                  packagingId: packagingId,
                  licenseplateId: licenseplateId,
                  packagedAmount: packagedAmount,
                  materialId: $grid.selectedRows[0].entity.Lot.Material.Id,
                  availablePackagedAmount: availablePackagedAmount
              })
              const userConfirmed = dialogResult.confirm;
              if (userConfirmed) {
                  $grid.refresh()
              }
  
          }
      }
  }
  else {
  
      throw new Error('Unable to determine location')
  }
  
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootprintManager_inventory_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Get User choice
  let exportAsDetails = await $shell.FootprintManager.openConfirmationDialog('Choose Export', 'Export Details or Summary?', 'Details', 'Summary');
  
  
  const exportLimit = $settings.FootprintManager.ExcelExportLimit
  var topLimit: number = 5000;
  
  // Initialize
  var wb = $event;
  var transactions = [];
  var allData: any[] = [];
  var lot_availability: any[] = [];
  var inboundOrders: any[] = [];
  var freshData: any[] = [];
  
  // Set Date/Time Formats
  const dateFormat = `${$settings.FootprintManager.DateFormat}`;
  
  // Summary Datasource
  if (exportAsDetails) {
      try {
          //Handle user selected rows only. 
  
          if ($grid.selectedRows.length > 0) {
  
              let lotIds = [...new Set($grid.selectedRows.map(r => r.entity?.LotId))]
              let packagedIds = [...new Set($grid.selectedRows.map(r => r.entity?.PackagedId))]
              let locationIds = [...new Set($grid.selectedRows.map(r => r.entity?.LocationId))]
              let warehouseIds = [...new Set($grid.selectedRows.map(r => r.entity?.Location.WarehouseId))]
  
  
  
              let lot_availability = (await $datasources.Inventory.ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batched.get({
                  lotIds: lotIds, packagedIds: packagedIds, warehouseIds: warehouseIds, locationIds: locationIds
              })).result;
              let inboundOrders = (await $datasources.Inventory.ds_get_lot_original_inbound_order_batched.get({ lotIds: lotIds })).result
  
              // Compile all data into JSON
              for (let row of $grid.selectedRows) {
  
                  transactions.push({
                      "Warehouse": row.entity.Location?.Warehouse?.Name,
                      "Parent": row.entity.Location?.ParentLocationContainer?.Name,
                      "Location": row.entity.Location?.Name,
                      "Material": row.entity.Lot?.Material?.LookupCode,
                      "Lot": row.entity.Lot?.LookupCode,
                      "Project": row.entity.Lot?.Material?.Project?.LookupCode,
                      "Total Amount": row.entity.PackagedAmount,
                      "Available Amount": lot_availability.find(e => e?.LotId === row.entity?.LotId
                          && e?.LocationId === row.entity?.LocationId
                          && e?.Warehouse.Id === row.entity?.Location.WarehouseId
                          && e?.PackagedId === row.entity?.PackagedId)?.TotalAvailableAmount.toString(),
                      "Packaging": row.entity.Packaging?.ShortName,
                      "Inbound Order": inboundOrders.find(e => e?.LotId === row.entity?.LotId)?.order?.LookupCode,
                      "Vendor Lot": row.entity.Lot?.VendorLot?.LookupCode,
                      "Vat": row.entity.Lot?.VendorLot?.Vat,
                      "Expiration Date": $utils.date.format(row.entity.Lot?.VendorLot?.ExpirationDate, dateFormat),
                      "Manufacture Date": $utils.date.format(row.entity.Lot?.VendorLot?.ManufactureDate, dateFormat),
                      "Received Date": $utils.date.format(row.entity.Lot?.ReceiveDate, dateFormat),
                      "Material Description": row.entity.Lot?.Material?.Description
  
                  });
              }
              var ws = $utils.excel.json_to_sheet(transactions);
              $utils.excel.book_append_sheet(wb, ws, "InventoryExport");
          }
          //take all rows in the dataset with applied filters.
          else {
  
              var totalRecords = (await $datasources.Inventory.ds_inventory_location_grid.get({
                  ownerId: $grid.inParams.ownerId,
                  projectId: $grid.inParams.projectId,
                  warehouseId: $grid.inParams.warehouseId,
                  materialIds: $grid.inParams.materialIds,
                  lotId: $grid.inParams.lotId,
                  locationId: $grid.inParams.locationId,
                  fullTextSearch: $grid.fullTextSearch,
                  take: 1
              })).totalCount;
  
  
              if (totalRecords > exportLimit) {
                  var exportApproval = true; //(await $shell.FootprintManager.openConfirmationDialog(`Export limitation of ${batchSize} exceeded`, `Excel will only export ${batchSize} records`, 'Continue', 'Cancel')); at the moment we cannot cancel as it will throw an empty excel workbook error.
                  (await $shell.Inventory.openInfoDialog(`Export limitation of ${exportLimit} exceeded`, `Excel will only export ${exportLimit} records`));
              } else {
                  exportApproval = true;
              }
  
  
              if (exportApproval) {
                  var recordsTaken: number = 0;
                  var remainingRecords: number = Math.min(exportLimit, totalRecords);
                  do {
                      var response = await $datasources.Inventory.ds_inventory_location_grid.get({
                          ownerId: $grid.inParams.ownerId,
                          projectId: $grid.inParams.projectId, 
                          warehouseId: $grid.inParams.warehouseId,
                          materialIds: $grid.inParams.materialIds,
                          lotId: $grid.inParams.lotId,
                          locationId: $grid.inParams.locationId,
                          fullTextSearch: $grid.fullTextSearch,
                          take: Math.min(remainingRecords, topLimit),
                          skip: recordsTaken
                      });
                      allData = allData.concat(response.result);
                      recordsTaken += response.result.length
                      remainingRecords -= response.result.length
  
                  } while (remainingRecords > 0 && response.result.length > 0)
  
                  // Function to chunk an array into smaller arrays of a specified size
                  function chunkArray<T>(array: T[], chunkSize: number): T[][] {
                      const chunks: T[][] = [];
                      for (let i = 0; i < array.length; i += chunkSize) {
                          chunks.push(array.slice(i, i + chunkSize));
                      }
                      return chunks;
                  }
  
                  // Main function to get the aggregated results
                  async function getAggregatedInventory(allData: any[], chunkSize: number) {
                      const lotIds = [...new Set(allData.map(a => Number(a.LotId)))];
                      const packagedIds = [...new Set(allData.map(a => Number(a.PackagedId)))];
                      const locationIds = [...new Set(allData.map(a => Number(a.LocationId)))];
                      const warehouseIds = [...new Set(allData.map(a => Number(a.Location.WarehouseId)))];
  
  
                      // Split the arrays into smaller chunks
                      const lotIdChunks = chunkArray(lotIds, chunkSize);
                      const packagedIdChunks = chunkArray(packagedIds, chunkSize);
                      const warehouseIdChunks = chunkArray(warehouseIds, chunkSize);
                      const locationIdChunks = chunkArray(locationIds, chunkSize);
  
                      let aggregatedResults: any[] = [];
  
                      // Helper function to handle HTTP calls
                      async function fetchChunk(lotIds: number[], packagedIds: number[], warehouseIds: number[], locationIds: number[]) {
                          const response = await $datasources.Inventory.ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batched.get({
                              lotIds,
                              packagedIds,
                              warehouseIds,
                              locationIds
                          });
                          return response.result;
                      }
  
                      // Loop through the chunks and make HTTP calls
                      for (let i = 0; i < lotIdChunks.length; i++) {
                          for (let j = 0; j < packagedIdChunks.length; j++) {
                              for (let k = 0; k < warehouseIdChunks.length; k++) {
                                  for (let l = 0; l < locationIdChunks.length; l++) {
  
                                  const lotIdChunk = lotIdChunks[i];
                                  const packagedIdChunk = packagedIdChunks[j];
                                  const warehouseIdChunk = warehouseIdChunks[k];
                                  const locationIdChunk = locationIdChunks[l];
  
  
                                  const result = await fetchChunk(lotIdChunk, packagedIdChunk, warehouseIdChunk, locationIdChunk);
                                  aggregatedResults = aggregatedResults.concat(result);
  
                              }
                          }
                          }
                      }
  
                      return aggregatedResults;
                  }
  
  
  
                  const chunkSize = 500; // Adjust chunk size as needed
                  let lot_availability_totals: any[] = [];
  
                  await getAggregatedInventory(allData, chunkSize)
                      .then(result => {
                          lot_availability_totals = result;
                      })
                      .catch(error => {
                          console.error("Error fetching inventory:", error);
                      });
  
  
  
                  // Compile all data into JSON
                  for (let record of allData) {
  
                      const lot_availability = lot_availability_totals.find(lot => lot.LotId === record.LotId && lot.LocationId === record.LocationId
                          && lot.Warehouse?.Id === record.Location.WarehouseId && lot.PackagedId === record.PackagedId);
  
  
                      transactions.push({
                          "Warehouse": record.Location?.Warehouse?.Name,
                          "Parent": record.Location?.ParentLocationContainer?.Name,
                          "Location": record.Location?.Name,
                          "Material": record.Lot?.Material?.LookupCode,
                          "Lot": record.Lot?.LookupCode,
                          "Project": record.Lot?.Material?.Project?.LookupCode,
                          "Total Amount": record.PackagedAmount,
                          "Available Amount": lot_availability?.TotalAvailableAmount,
                          "Packaging": record.Packaging?.ShortName,
                          "Vendor Lot": record.Lot?.VendorLot?.LookupCode,
                          "Vat": record.Lot?.VendorLot?.Vat,
                          "Expiration Date": $utils.date.format(record.Lot?.VendorLot?.ExpirationDate, dateFormat),
                          "Manufacture Date": $utils.date.format(record.Lot?.VendorLot?.ManufactureDate, dateFormat),
                          "Received Date": $utils.date.format(record.Lot?.ReceiveDate, dateFormat),
                          "Material Description": record.Lot?.Material?.Description
                      });
                  }
                  var ws = $utils.excel.json_to_sheet(transactions);
                  $utils.excel.book_append_sheet(wb, ws, "InventoryExport");
              }
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          throw new Error(errorMessage);
      }
  
  
  }
  else {
  
      try {
  
  
          var totalSummaryRecords = (await $datasources.Inventory.ds_inventory_summary_grid.get({
              ownerId: $grid.inParams.ownerId,
              projectId: $grid.inParams.projectId,
              warehouseId: $utils.isDefined($grid.inParams.warehouseId) ? [$grid.inParams.warehouseId] : null,
              materialIds: $grid.inParams.materialIds,
              fullTextSearch: $grid.fullTextSearch,
              take: 1
          })).totalCount;
  
  
          if (totalSummaryRecords > exportLimit) {
              var exportSummaryApproval = true;
              (await $shell.Inventory.openInfoDialog(`Export limitation of ${exportLimit} exceeded`, `Excel will only export ${exportLimit} records`));
          } else {
              exportSummaryApproval = true;
          }
  
  
          if (exportSummaryApproval) {
              var recordsTaken: number = 0;
              var remainingRecords: number = Math.min(exportLimit, totalSummaryRecords);
              do {
                  var responseSummary = await $datasources.Inventory.ds_inventory_summary_grid.get({
                      ownerId: $grid.inParams.ownerId,
                      projectId: $grid.inParams.projectId,
                      warehouseId: $utils.isDefined($grid.inParams.warehouseId) ? [$grid.inParams.warehouseId] : null,
                      materialIds: $grid.inParams.materialIds,
                      fullTextSearch: $grid.fullTextSearch,
                      take: Math.min(remainingRecords, topLimit),
                      skip: recordsTaken
                  });
                  allData = allData.concat(responseSummary.result);
                  recordsTaken += responseSummary.result.length
                  remainingRecords -= responseSummary.result.length
  
              } while (remainingRecords > 0 && responseSummary.result.length > 0)
  
              // Compile all data into JSON
              for (let record of allData) {
  
  
  
                  transactions.push({
                      "Warehouse": record.Warehouse?.Name,
                      "Material": record.Material?.LookupCode,
                      "Project": record.Material?.Project?.LookupCode,
                      "Total Amount": record.PackagedAmount,
                      "Available Amount": record.material_availability?.TotalAvailablePackagedAmount,
                      "Packaging": record.Packaging?.ShortName,
                      "Material Description": record.Material?.Description
                  });
              }
              var ws = $utils.excel.json_to_sheet(transactions);
              $utils.excel.book_append_sheet(wb, ws, "InventoryExport");
          }
  
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          throw new Error(errorMessage);
      }
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_inventory_location_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_location_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const lotIds = ($grid.rows.map(r => r.entity?.LotId))
  const packagedIds = ($grid.rows.map(r => r.entity?.PackagedId))
  const locationIds = ($grid.rows.map(r => r.entity?.LocationId))
  const warehouseIds = ($grid.rows.map(r => r.entity?.Location.WarehouseId))
  
  let lot_availability = (await $datasources.Inventory.ds_get_available_inventory_by_lotIds_packagedIds_warehouseIds_locationIds_batched.get({ lotIds: lotIds, packagedIds: packagedIds, warehouseIds: warehouseIds, locationIds: locationIds })).result;
  let inboundOrders = (await $datasources.Inventory.ds_get_lot_original_inbound_order_batched.get({ lotIds: lotIds })).result
  
  
  for (let row of $grid.rows) {
      for (let available of lot_availability) {
          if (available.LotId === row.entity.LotId && available.LocationId === row.entity.LocationId && available.Warehouse.Id === row.entity.Location.WarehouseId && available.PackagedId === row.entity.PackagedId) {
              row.cells.available_packaged_amount.displayControl.text = available.TotalAvailableAmount.toString();
          }
      }
      for (let order of inboundOrders){
          if(order.LotId === row.entity.LotId){
              row.cells.inbound_order.displayControl.text = order.order?.LookupCode;
          }
      }
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootprintManager_inventory_location_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
