import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintManager_ds_get_task_detailsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { task_id: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, ExpectedPackagedAmount?: number, ModifiedSysDateTime?: string, Notes?: string, Warehouse?: { Name?: string, Notes?: string }, Status?: { Name?: string }, OperationCode?: { Name?: string }, Material?: { LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, Project?: { LookupCode?: string }, SerialNumber?: { LookupCode?: string }, ActualPackagedPack?: { Name?: string }, ExpectedPackagedPack?: { Name?: string }, ActualSourceLicensePlate?: { LookupCode?: string }, ActualSourceLocation?: { Name?: string }, ActualTargetLicensePlate?: { LookupCode?: string }, ActualTargetLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, ExpectedSourceLocation?: { Name?: string }, ExpectedTargetLicensePlate?: { LookupCode?: string }, ExpectedTargetLocation?: { Name?: string }, Order?: { LookupCode?: string, Notes?: string, OwnerReference?: string, VendorReference?: string }, PickSlip?: { Id?: number, Wave?: { Id?: number } } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.task_id)) {
      missingRequiredInParams.push('\'task_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintManager/datasources/ds_get_task_details/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      task_id: number    }) {
        if(isNil(inParams.task_id)) {
          return true; 
        }
      return false;
    }
}
