import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { Manufacturing_manufacturing_order_reportService } from './Manufacturing.report.index';
import { Manufacturing_testRepService } from './Manufacturing.report.index';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public Manufacturing: Manufacturing_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _manufacturing_order_report: Manufacturing_manufacturing_order_reportService;
  public get manufacturing_order_report(): Manufacturing_manufacturing_order_reportService {
    if(!this._manufacturing_order_report) {
      this._manufacturing_order_report = this.injector.get(Manufacturing_manufacturing_order_reportService);
    }
    return this._manufacturing_order_report;
  }
  private _testRep: Manufacturing_testRepService;
  public get testRep(): Manufacturing_testRepService {
    if(!this._testRep) {
      this._testRep = this.injector.get(Manufacturing_testRepService);
    }
    return this._testRep;
  }
}

