import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Owners_OperationService } from './Owners.operation.service';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Owners_FlowService } from './Owners.flow.index';
import { Owners_ReportService } from './Owners.report.index';
import { Owners_LocalizationService } from './Owners.localization.service';
import { Owners_owner_project_tester_form_ComponentContextService } from './Owners.owner_project_tester_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Owners.frontend.types'
import { $frontendTypes as $types} from './Owners.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Owners_owners_multi_dd_multiComponent } from './Owners.owners_multi_dd_multi.component'
import { Owners_projects_multi_dd_multiComponent } from './Owners.projects_multi_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_multi_dd_multiComponent),
    forwardRef(() => Owners_projects_multi_dd_multiComponent),
  ],
  selector: 'Owners-owner_project_tester_form',
  templateUrl: './Owners.owner_project_tester_form.component.html'
})
export class Owners_owner_project_tester_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Label of newGroup1',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  cacheValueFor_$fields_owner_selector_inParams_projectIds: number[];
  get $fields_owner_selector_inParams_projectIds(): number[] {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.project.control.value;
    
    if(!isEqual(this.cacheValueFor_$fields_owner_selector_inParams_projectIds, expr)) {
      this.cacheValueFor_$fields_owner_selector_inParams_projectIds = expr;
    }
    return this.cacheValueFor_$fields_owner_selector_inParams_projectIds;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  cacheValueFor_$fields_project_selector_inParams_ownerIds: number[];
  get $fields_project_selector_inParams_ownerIds(): number[] {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.owner.control.value;
    
    if(!isEqual(this.cacheValueFor_$fields_project_selector_inParams_ownerIds, expr)) {
      this.cacheValueFor_$fields_project_selector_inParams_ownerIds = expr;
    }
    return this.cacheValueFor_$fields_project_selector_inParams_ownerIds;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Owners_ShellService,
private $datasources: Owners_DatasourceService,
private $flows: Owners_FlowService,
private $reports: Owners_ReportService,
private $localization: Owners_LocalizationService,
private $operations: Owners_OperationService,
private $logger: CleanupLoggerService,
private $context: Owners_owner_project_tester_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Test harness';
  
    const $form = this;
    const $utils = this.$utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: Owners_owner_project_tester_formComponent,
  
    $shell: Owners_ShellService,
    $datasources: Owners_DatasourceService,
    $flows: Owners_FlowService,
    $reports: Owners_ReportService,
    $settings: SettingsValuesService,
    $operations: Owners_OperationService,
    $utils: UtilsService,
    $context: Owners_owner_project_tester_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Owners_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Owners', 'owner_project_tester_form.on_owner_change');
  
  
  if ($form.fields.owner.control.isChanged) {
      $form.fields.project.control.value = null;
  }
  
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: Owners_owner_project_tester_formComponent,
  
    $shell: Owners_ShellService,
    $datasources: Owners_DatasourceService,
    $flows: Owners_FlowService,
    $reports: Owners_ReportService,
    $settings: SettingsValuesService,
    $operations: Owners_OperationService,
    $utils: UtilsService,
    $context: Owners_owner_project_tester_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Owners_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Owners', 'owner_project_tester_form.on_project_change');
  const projectIds = $form.fields.project.control.value;
  const ownerIds: number[] = [];
  
  if ($utils.isDefined(projectIds) && !$utils.isDefined($form.fields.owner.control.value)) {
  
      const projects = (await $datasources.Owners.ds_get_projects_by_projectIds.get({ projectIds: projectIds })).result;
  
      if ($utils.isAllDefined(projects)) {
          for (let project of projects) {
              ownerIds.push(project.OwnerId)
          }
          $form.fields.owner.control.value = ownerIds;
  
      }
  
  }
  }
  //#endregion private flows
}
