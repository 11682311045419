import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Carriers_carrier_library_homeComponent } from './Carriers.carrier_library_home.component';
import { Carriers_carriers_dd_singleComponent } from './Carriers.carriers_dd_single.component';
import { Carriers_carriers_excl_ownerscarriers_dd_singleComponent } from './Carriers.carriers_excl_ownerscarriers_dd_single.component';
import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component';
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component';
import { Carriers_carrierservicetypes_excl_carrier_dd_singleComponent } from './Carriers.carrierservicetypes_excl_carrier_dd_single.component';
import { Carriers_carriers_dd_multiComponent } from './Carriers.carriers_dd_multi.component';
import { Carriers_carriers_excl_ownerscarriers_dd_multiComponent } from './Carriers.carriers_excl_ownerscarriers_dd_multi.component';
import { Carriers_carriers_ownerscarriers_dd_multiComponent } from './Carriers.carriers_ownerscarriers_dd_multi.component';
import { Carriers_carrierservicetype_dd_multiComponent } from './Carriers.carrierservicetype_dd_multi.component';
import { Carriers_carrierservicetypes_excl_carrier_dd_multiComponent } from './Carriers.carrierservicetypes_excl_carrier_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Carriers_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Carriers: Carriers_ShellService = this;

  public opencarrier_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('Carriers', 'carrier_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Carriers_carrier_library_home',
        component: Carriers_carrier_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencarrier_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Carriers', 'carrier_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Carriers_carrier_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Carriers_carrier_library_home') {
      this.$logger.log('Carriers', 'carrier_library_home');
      const title = 'Home';
      const component = Carriers_carrier_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carriers_dd_single') {
      const title = 'List of carriers.';
      const component = Carriers_carriers_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carriers_excl_ownerscarriers_dd_single') {
      const title = 'Carrier List Excluding Carriers already assigned to a owner.';
      const component = Carriers_carriers_excl_ownerscarriers_dd_singleComponent;
      const inParams:{ ownerId: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carriers_ownerscarriers_dd_single') {
      const title = 'Carrier List assigned to a owner. ';
      const component = Carriers_carriers_ownerscarriers_dd_singleComponent;
      const inParams:{ ownerId: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carrierservicetype_dd_single') {
      const title = 'carrierservicetype_dd';
      const component = Carriers_carrierservicetype_dd_singleComponent;
      const inParams:{ carrierId: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carrierservicetypes_excl_carrier_dd_single') {
      const title = 'Carrierservicetypes excluding carriers';
      const component = Carriers_carrierservicetypes_excl_carrier_dd_singleComponent;
      const inParams:{ carrierId: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carriers_dd_multi') {
      const title = 'List of carriers.';
      const component = Carriers_carriers_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carriers_excl_ownerscarriers_dd_multi') {
      const title = 'Carrier List Excluding Carriers already assigned to a owner.';
      const component = Carriers_carriers_excl_ownerscarriers_dd_multiComponent;
      const inParams:{ ownerId: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carriers_ownerscarriers_dd_multi') {
      const title = 'Carrier List assigned to a owner. ';
      const component = Carriers_carriers_ownerscarriers_dd_multiComponent;
      const inParams:{ ownerId: number } = { ownerId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carrierservicetype_dd_multi') {
      const title = 'carrierservicetype_dd';
      const component = Carriers_carrierservicetype_dd_multiComponent;
      const inParams:{ carrierId: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Carriers_carrierservicetypes_excl_carrier_dd_multi') {
      const title = 'Carrierservicetypes excluding carriers';
      const component = Carriers_carrierservicetypes_excl_carrier_dd_multiComponent;
      const inParams:{ carrierId: number } = { carrierId: null };
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
