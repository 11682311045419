import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Dynamics365BusinessCentral_bc_editor_ComponentContextService } from './Dynamics365BusinessCentral.bc_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Dynamics365BusinessCentral_order_po_queue_gridComponent } from './Dynamics365BusinessCentral.order_po_queue_grid.component';
import { Dynamics365BusinessCentral_material_gridComponent } from './Dynamics365BusinessCentral.material_grid.component';
import { Dynamics365BusinessCentral_inbound_order_todayComponent } from './Dynamics365BusinessCentral.inbound_order_today.component';
import { Dynamics365BusinessCentral_outbound_orders_todayComponent } from './Dynamics365BusinessCentral.outbound_orders_today.component';
import { Dynamics365BusinessCentral_materials_todayComponent } from './Dynamics365BusinessCentral.materials_today.component';

type EntityType = { 
    value?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Dynamics365BusinessCentral_order_po_queue_gridComponent),
    forwardRef(() => Dynamics365BusinessCentral_material_gridComponent),
    forwardRef(() => Dynamics365BusinessCentral_inbound_order_todayComponent),
    forwardRef(() => Dynamics365BusinessCentral_outbound_orders_todayComponent),
    forwardRef(() => Dynamics365BusinessCentral_materials_todayComponent),
  ],
  selector: 'Dynamics365BusinessCentral-bc_editor',
  templateUrl: './Dynamics365BusinessCentral.bc_editor.component.html'
})
export class Dynamics365BusinessCentral_bc_editorComponent extends BaseComponent implements OnInit, OnDestroy {

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
  };

  fields = {
  };

  fieldsets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        false,
        ),
      materials: new TabItemModel(
        this.rootTabGroup, 
        'Materials', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_orders', { read: Dynamics365BusinessCentral_order_po_queue_gridComponent }) $tabs_orders: Dynamics365BusinessCentral_order_po_queue_gridComponent;
      @ViewChild('$tabs_materials', { read: Dynamics365BusinessCentral_material_gridComponent }) $tabs_materials: Dynamics365BusinessCentral_material_gridComponent;
    //#endregion tabs children
    widgets = {
      purchase: new WidgetModel(false),
      sells: new WidgetModel(false),
      material: new WidgetModel(false),
    };
  
    //#region widgets inParams
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_purchase', { read:  Dynamics365BusinessCentral_inbound_order_todayComponent }) $widgets_purchase: Dynamics365BusinessCentral_inbound_order_todayComponent;
      @ViewChild('$widgets_sells', { read:  Dynamics365BusinessCentral_outbound_orders_todayComponent }) $widgets_sells: Dynamics365BusinessCentral_outbound_orders_todayComponent;
      @ViewChild('$widgets_material', { read:  Dynamics365BusinessCentral_materials_todayComponent }) $widgets_material: Dynamics365BusinessCentral_materials_todayComponent;
    //#endregion widgets children


  $formGroupFieldValidationObservables = {
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Dynamics365BusinessCentral_ShellService,
    private $datasources: Dynamics365BusinessCentral_DatasourceService,
    private $flows: Dynamics365BusinessCentral_FlowService,
    private $reports: Dynamics365BusinessCentral_ReportService,
    private $localization: Dynamics365BusinessCentral_LocalizationService,
    private $operations: Dynamics365BusinessCentral_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Dynamics365BusinessCentral_bc_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.orders,
      this.tabs.materials,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'bc_editor';
    

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      customerr:  null ,
      application_name:  null 
    };

    const data = await this.$datasources.Dynamics365BusinessCentral.ds_get_errors.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_purchase') {
      if (!isNil(this.$widgets_purchase) && !this.widgets.purchase.hidden && !this.widgets.purchase.removed) {
        this.$widgets_purchase.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_sells') {
      if (!isNil(this.$widgets_sells) && !this.widgets.sells.hidden && !this.widgets.sells.removed) {
        this.$widgets_sells.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_material') {
      if (!isNil(this.$widgets_material) && !this.widgets.material.hidden && !this.widgets.material.removed) {
        this.$widgets_material.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_orders') {
        if (!isNil(this.$tabs_orders) && !this.tabs.orders.hidden && !this.tabs.orders.removed) {
          this.$tabs_orders.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_materials') {
        if (!isNil(this.$tabs_materials) && !this.tabs.materials.hidden && !this.tabs.materials.removed) {
          this.$tabs_materials.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
  }

  
}
