import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Utilities.frontend.types'

@Injectable({ providedIn: 'root' })
export class Utilities_messages_nextService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { input?: $frontendTypes.Utilities.Message[], criteria: { application_name: string, full_text_search?: string, date_from?: string, date_to?: string } }): Promise<{ output?: $frontendTypes.Utilities.Message, success?: boolean, error?: any[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.criteria)) {
      missingRequiredInParams.push('\'criteria\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Utilities/functions/messages_next`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

