import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Allocations_create_allocation_configuration_flowService } from './Allocations.flow.index';
import { Allocations_delete_allocation_configuration_flowService } from './Allocations.flow.index';
import { Allocations_get_allocation_configurations_flowService } from './Allocations.flow.index';
import { Allocations_update_allocation_configuration_flowService } from './Allocations.flow.index';

import { $frontendTypes } from './Allocations.frontend.types'

@Injectable({ providedIn: 'root' })
export class Allocations_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Allocations: Allocations_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_allocation_configuration_flow: Allocations_create_allocation_configuration_flowService;
  public async create_allocation_configuration_flow(inParams: { content: $frontendTypes.Allocations.i_allocation_config, filters: { name: string, value: string }[], context: number[] }): Promise< { config_id?: number, reasons?: string[] }> {
    if(!this._create_allocation_configuration_flow) { 
      this._create_allocation_configuration_flow = this.injector.get(Allocations_create_allocation_configuration_flowService);
    }
    return this._create_allocation_configuration_flow.run(inParams);
  }
   
   

   
 
  private _delete_allocation_configuration_flow: Allocations_delete_allocation_configuration_flowService;
  public async delete_allocation_configuration_flow(inParams: { config_id: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_allocation_configuration_flow) { 
      this._delete_allocation_configuration_flow = this.injector.get(Allocations_delete_allocation_configuration_flowService);
    }
    return this._delete_allocation_configuration_flow.run(inParams);
  }
   
   

   
 
  private _get_allocation_configurations_flow: Allocations_get_allocation_configurations_flowService;
  public async get_allocation_configurations_flow(inParams: { context: $frontendTypes.Allocations.e_hard_allocation_context, filters: { material_id?: number, account_id?: number, project_id?: number, owner_id?: number, warehouse_id?: number, order_class_id?: number, material_group_id?: number }, allow_inherited_configs?: boolean }): Promise< { config?: $frontendTypes.Allocations.i_allocation_config, reasons?: string[], config_source?: $frontendTypes.Allocations.i_allocation_config, config_id?: number, applicable_config_ids?: number[], config_filters?: { name: string, value: string }[] }> {
    if(!this._get_allocation_configurations_flow) { 
      this._get_allocation_configurations_flow = this.injector.get(Allocations_get_allocation_configurations_flowService);
    }
    return this._get_allocation_configurations_flow.run(inParams);
  }
   
   

   
 
  private _update_allocation_configuration_flow: Allocations_update_allocation_configuration_flowService;
  public async update_allocation_configuration_flow(inParams: { config_id: number, content: $frontendTypes.Allocations.i_allocation_config }): Promise< { reasons?: string[] }> {
    if(!this._update_allocation_configuration_flow) { 
      this._update_allocation_configuration_flow = this.injector.get(Allocations_update_allocation_configuration_flowService);
    }
    return this._update_allocation_configuration_flow.run(inParams);
  }
   
   

   
}
