import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_get_manufacturing_order_lines_production_sub_components_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { rows?: { lineNumber?: number, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[], main_component_identifier?: number, component?: string, $top?: number, $skip?: number }): Promise<{ result?: { id?: number, lineNumber?: number, component?: string, consumedQuantity?: number, scrappedQuantity?: number, unusedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, packageId?: number, componentMaterialId?: number }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_order_lines_production_sub_components_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { rows?: { lineNumber?: number, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[], main_component_identifier?: number, component?: string, $keys: { id?: number, lineNumber?: number }[] }): Promise<{ result?: { id?: number, lineNumber?: number, component?: string, consumedQuantity?: number, scrappedQuantity?: number, unusedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, packageId?: number, componentMaterialId?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_order_lines_production_sub_components_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      rows?: { lineNumber?: number, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[], main_component_identifier?: number, component?: string    }) {
      return false;
    }
}
