import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_get_manufacturing_orders_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouse_ids?: number[], project_id?: number, owner_id?: number, status_ids?: number[], from_date?: string, to_date?: string, date_type?: string, production_confirmation?: string, full_text_search?: string }): 
  Promise<{ result: { Id?: number, DefaultStagingLocationId?: number, LookupCode?: string, ManufacturingLocationId?: number, ProjectId?: number, StatusId?: number, WarehouseId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, Status?: { Id?: number, Name?: string }, DefaultStagingLocation?: { Id?: number, Name?: string }, ManufacturingLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, OrderLines?: { Id?: number, StatusId?: number }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_orders_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouse_ids?: number[], project_id?: number, owner_id?: number, status_ids?: number[], from_date?: string, to_date?: string, date_type?: string, production_confirmation?: string, full_text_search?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, DefaultStagingLocationId?: number, LookupCode?: string, ManufacturingLocationId?: number, ProjectId?: number, StatusId?: number, WarehouseId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, Status?: { Id?: number, Name?: string }, DefaultStagingLocation?: { Id?: number, Name?: string }, ManufacturingLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, OrderLines?: { Id?: number, StatusId?: number }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_orders_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouse_ids?: number[], project_id?: number, owner_id?: number, status_ids?: number[], from_date?: string, to_date?: string, date_type?: string, production_confirmation?: string, full_text_search?: string, $keys: number[] }): 
  Promise<{ result: { Id?: number, DefaultStagingLocationId?: number, LookupCode?: string, ManufacturingLocationId?: number, ProjectId?: number, StatusId?: number, WarehouseId?: number, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } }, Status?: { Id?: number, Name?: string }, DefaultStagingLocation?: { Id?: number, Name?: string }, ManufacturingLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, OrderLines?: { Id?: number, StatusId?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_orders_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouse_ids?: number[], project_id?: number, owner_id?: number, status_ids?: number[], from_date?: string, to_date?: string, date_type?: string, production_confirmation?: string, full_text_search?: string    }) {
      return false;
    }
}
