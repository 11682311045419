import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_process_order_ComponentContextService } from './FootprintManager.process_order.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_manufacturing_order_lines_components_for_process_gridComponent } from './FootprintManager.manufacturing_order_lines_components_for_process_grid.component';

type EntityType = { 
    Id?: number, ActualCompleteOn?: string, ActualStartOn?: string, CreatedSysDateTime?: string, CreatedSysUser?: string, DefaultStagingLocationId?: number, EstimatedCompleteOn?: string, EstimatedStartOn?: string, LookupCode?: string, ManufacturingLocationId?: number, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, ProjectId?: number, StatusId?: number, WarehouseId?: number, Project?: { OwnerId?: number }, Status?: { Name?: string, ShortName?: string }, Tasks?: { Id?: number, PickSlip?: { WaveId?: number } }[]}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_manufacturing_order_lines_components_for_process_gridComponent),
  ],
  selector: 'FootprintManager-process_order',
  templateUrl: './FootprintManager.process_order.component.html'
})
export class FootprintManager_process_orderComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { order_id: number } = { order_id: null };
  //#region Inputs
  @Input('order_id') set $inParams_order_id(v: number) {
    this.inParams.order_id = v;
  }
  get $inParams_order_id(): number {
    return this.inParams.order_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    create_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    process_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    release_wave: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    create_manual_allocations_tasks: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    soft_allocate: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(['primary'], null), false, false, false, 'Process', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    priority: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Priority', false, false),
    create_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['create_wave'] as DatexFormControl, null, false, false, 'Create wave', null)
, new ControlContainerStyles(null, null), '', false, false),
    process_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['process_wave'] as DatexFormControl, null, false, false, 'Process wave', null)
, new ControlContainerStyles(null, null), '', false, false),
    release_wave: new FieldModel(new CheckBoxModel(this.formGroup.controls['release_wave'] as DatexFormControl, null, false, false, 'Release wave', null)
, new ControlContainerStyles(null, null), '', false, false),
    create_manual_allocations_tasks: new FieldModel(new CheckBoxModel(this.formGroup.controls['create_manual_allocations_tasks'] as DatexFormControl, null, false, false, 'Create manual allocations tasks', null)
, new ControlContainerStyles(null, null), '', false, false),
    soft_allocate: new FieldModel(new CheckBoxModel(this.formGroup.controls['soft_allocate'] as DatexFormControl, null, false, false, 'Soft allocate', null)
, new ControlContainerStyles(null, null), '', false, false),
  };

  fieldsets = {
    priority_group: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    wave_options_group: new FieldsetModel(
      'Wave options',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      components: new TabItemModel(
        this.rootTabGroup, 
        'Components', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_components_manufacturing_order_lines_components_for_process_grid_inParams_order_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_components_manufacturing_order_lines_components_for_process_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.WarehouseId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_components', { read: FootprintManager_manufacturing_order_lines_components_for_process_gridComponent }) $tabs_components: FootprintManager_manufacturing_order_lines_components_for_process_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    create_wave: this.fields.create_wave.control.valueChanges
    ,
    process_wave: this.fields.process_wave.control.valueChanges
    ,
    release_wave: this.fields.release_wave.control.valueChanges
    ,
    create_manual_allocations_tasks: this.fields.create_manual_allocations_tasks.control.valueChanges
    ,
    soft_allocate: this.fields.soft_allocate.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_process_order_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.components,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.order_id)) {
        this.$missingRequiredInParams.push('order_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Process order';
    

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      order_id:  $editor.inParams.order_id 
    };

    const data = await this.$datasources.Manufacturing.ds_manufacturing_order_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_components') {
        if (!isNil(this.$tabs_components) && !this.tabs.components.hidden && !this.tabs.components.removed) {
          this.$tabs_components.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .create_wave
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .process_wave
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .release_wave
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .create_manual_allocations_tasks
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .soft_allocate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  
}
