import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { app_custom_inventory_by_location_selection_grid_ComponentContextService } from './app.custom_inventory_by_location_selection_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface Iapp_custom_inventory_by_location_selection_gridComponentEntity {
  LocationId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, Location?: { EligibleForAllocation?: boolean, GlobalAllocationSequence?: number, Name?: string, WarehouseId?: number, Status?: { Name?: string } }, Lot?: { LookupCode?: string, MaterialId?: number, VendorLotId?: number, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Material?: { Description?: string, LookupCode?: string } }, Packaging?: { ShortName?: string }}

interface Iapp_custom_inventory_by_location_selection_gridComponentInParams {
  warehouseId: number, materialId?: number, packagingId?: number, lotId?: number, projectId: number, packagedAmountRequest?: number}

interface Iapp_custom_inventory_by_location_selection_gridComponentOutParams {
  inventory?: { lotId?: number, locationId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[]}

class app_custom_inventory_by_location_selection_gridComponentRowModel extends GridRowModel {
  grid: app_custom_inventory_by_location_selection_gridComponent;
  entity: Iapp_custom_inventory_by_location_selection_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    eligible_for_allocation_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    quantity: null,
    location: null,
    material_lookupcode: null,
    packaged_amount: null,
    available: null,
    lot: null,
    vendorlot: null,
    location_status: null,
    eligible_for_allocation: null,
    global_allocation_sequence: null,
    expiration_date: null,
    manufacture_date: null,
    material_description: null,
  }



  constructor(
    grid: app_custom_inventory_by_location_selection_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: app_ShellService,
private $datasources: app_DatasourceService,
private $flows: app_FlowService,
private $reports: app_ReportService,
private $localization: app_LocalizationService,
private $operations: app_OperationService,
private $logger: CleanupLoggerService,
private $context: app_custom_inventory_by_location_selection_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.quantity,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.material_lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.material_lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packaged_amount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaged_amount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.available = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.available,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendorlot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendorlot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.location_status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location_status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.eligible_for_allocation = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.eligible_for_allocation,
      new CheckBoxModel(this.formGroup.controls['eligible_for_allocation_display'] as DatexFormControl, null, true, false, '', null)
,
null
      );
    
    this.cells.global_allocation_sequence = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.global_allocation_sequence,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.expiration_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.expiration_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.manufacture_date = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.manufacture_date,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.material_description = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.material_description,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: Iapp_custom_inventory_by_location_selection_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: Iapp_custom_inventory_by_location_selection_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'LocationId',this.entity.LocationId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      warehouseId:  $grid.inParams.warehouseId ,
      materialId:  $grid.inParams.materialId ,
      packagingId:  $grid.inParams.packagingId ,
      lotId:  $grid.inParams.lotId ,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      take:  null ,
      skip:  null ,
    };
    const data = await this.$datasources.Inventory.ds_inventory_by_location_selection_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as Iapp_custom_inventory_by_location_selection_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.location.displayControl as TextModel).text = $row.entity.Location?.Name;
    (this.cells.material_lookupcode.displayControl as TextModel).text = $row.entity.Lot?.Material?.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.Lot?.VendorLot?.LookupCode;
    (this.cells.location_status.displayControl as TextModel).text = $row.entity.Location?.Status?.Name;
    (this.cells.eligible_for_allocation.displayControl as CheckBoxModel).reset($row.entity.Location?.EligibleForAllocation);
    (this.cells.global_allocation_sequence.displayControl as TextModel).text = $row.entity.Location?.GlobalAllocationSequence?.toString();
    (this.cells.material_description.displayControl as TextModel).text = $row.entity.Lot?.Material?.Description;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: app_custom_inventory_by_location_selection_gridComponentRowModel,
  $grid: app_custom_inventory_by_location_selection_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_inventory_by_location_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_inventory_by_location_selection_grid.on_row_data_loaded');
  var lotId = $row.entity.LotId;
  var packagedId = $row.entity.PackagedId;
  var locationId = $row.entity.LocationId;
  var warehouseId = $row.entity.Location.WarehouseId;
  var materialId = $row.entity.Lot?.MaterialId;
  
  // concatenate uom short name with packaged amount 
  $row.cells.packaged_amount.displayControl.text = $row.entity.PackagedAmount?.toString() + ' ' + $row.entity.Packaging?.ShortName
  
  // Apply date format
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ExpirationDate) && $utils.isDefined($settings.FootprintManager.DateFormat)) {
      var dateFormat = $settings.FootprintManager.DateFormat;
      var expirationDate = $utils.date.format($row.entity.Lot?.VendorLot?.ExpirationDate, dateFormat);
      $row.cells.expiration_date.displayControl.text = expirationDate;
  }
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ManufactureDate) && $utils.isDefined($settings.FootprintManager.DateFormat)) {
      var dateFormat = $settings.FootprintManager.DateFormat;
      var manufactureDate = $utils.date.format($row.entity.Lot?.VendorLot?.ManufactureDate, dateFormat);
      $row.cells.manufacture_date.displayControl.text = manufactureDate;
  }
  
  // Set the available cell
  const inventory = (await $datasources.Inventory.ds_get_available_inventory_by_lotId_packagedId_warehouseId.get({
      lotId: lotId,
      packagedId: packagedId,
      locationId: locationId,
      warehouseId: warehouseId
  })).result;
  if ($utils.isDefined(inventory?.TotalAvailablePackagedAmount)) {
      $row.cells.available.displayControl.text = inventory.TotalAvailablePackagedAmount?.toString();
  }
  else {
      $row.cells.available.displayControl.text = '0'
  }
  
  
  // Load quantity cell from outParam
  
  if ($utils.isDefined($grid.outParams.inventory)) {
  
      const rowFound = $grid.outParams.inventory.filter(
          element => element.lotId === $row.entity.LotId
              && element.locationId === $row.entity.LocationId
              && element.packagingId === $row.entity.PackagedId
  
      );
  
      if ($utils.isDefined(rowFound)) {
  
          $row.cells.quantity.editControl.value = rowFound[0].packagedAmount;
          $row.cells.quantity.displayControl.text = rowFound[0].packagedAmount?.toString();
  
      }
  
  }
  
  if ($utils.isDefined($grid.vars.selectedRows)) {
      const rowFound = $grid.vars.selectedRows.find(selectedRow =>
          selectedRow.entity.LotId === $row.entity.LotId
          && selectedRow.entity.LocationId === $row.entity.LocationId
          && selectedRow.entity.PackagedId === $row.entity.PackagedId
      );
  
      if ($grid.rows.length === 0) {
          $grid.rows = $grid.rows.concat($grid.vars.selectedRows);
      }
  
      if ($utils.isDefined(rowFound)) {
          $grid.rows.splice($grid.rows.indexOf(rowFound), 1);
      }
  
      $grid.sort_grid();
  
  }
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(row: any) {
      return $utils.isDefined(row.cells.quantity.editControl.value) && row.cells.quantity.editControl.value > 0;
  }
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: app_custom_inventory_by_location_selection_gridComponentRowModel,
  $grid: app_custom_inventory_by_location_selection_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_inventory_by_location_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_inventory_by_location_selection_grid.on_save_existing_row');
  var lotId = $row.entity.LotId;
  var packagedId = $row.entity.PackagedId;
  var locationId = $row.entity.LocationId;
  var warehouseId = $row.entity.Location.WarehouseId;
  var materialId = $row.entity.Lot?.MaterialId;
  var availablePackagedAmount;
  
  
  // Get available inventory
  const inventory = (await $datasources.Inventory.ds_get_available_inventory_by_lotId_packagedId_warehouseId.get({
      lotId: lotId,
      packagedId: packagedId,
      locationId: locationId,
      warehouseId: warehouseId
  })).result;
  if ($utils.isDefined(inventory?.TotalAvailablePackagedAmount)) {
      availablePackagedAmount = inventory.TotalAvailablePackagedAmount;
  
      if ($row.cells.quantity.editControl.value > availablePackagedAmount) {
          let message = 'Selected quantity cannot be greater than the available amount.';
          $shell.FootprintManager.openErrorDialog('Cannot select inventory', message);
          throw new Error(message);
      }
  }
  else {
      let message = 'Selected quantity cannot be greater than the available amount.';
      $shell.FootprintManager.openErrorDialog('Cannot select inventory', message);
      throw new Error(message);
  }
  
  
  if ($row.cells.quantity.editControl.value > $row.entity.PackagedAmount) {
      let message = 'Selected quantity cannot be greater than the on hand quantity.';
      $shell.FootprintManager.openErrorDialog('Cannot select inventory', message);
      throw new Error(message);
  }
  
  
  
  if ($row.entity.Location?.EligibleForAllocation == false) {
      let message = 'Selected location is not currently eligible for allocation.'
      $shell.FootprintManager.openErrorDialog('Cannot select inventory', message);
      throw new Error(message);
  }
  
  if ($utils.isDefined($grid.inParams.packagedAmountRequest)) {
  
      if ($row.cells.quantity.editControl.value > $grid.inParams.packagedAmountRequest) {
          let message = 'Selected quantity cannot be greater than the packaged amount on the selected pick task.';
          $shell.FootprintManager.openErrorDialog('Cannot select inventory', message);
          throw new Error(message);
      }
  
  }
  
  
  if ($utils.isDefined($grid.outParams.inventory)) {
  
      const rowFound = $grid.outParams.inventory.filter(
          element => element.lotId === $row.entity.LotId
              && element.locationId === $row.entity.LocationId
              && element.packagingId === $row.entity.PackagedId
  
      );
  
      if ($utils.isDefined(rowFound)) {
  
          if ($utils.isDefined($row.cells.quantity.editControl.value) && $row.cells.quantity.editControl.value > 0) {
              rowFound[0].packagedAmount = $row.cells.quantity.editControl.value;
              $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
          } else {
  
              $grid.outParams.inventory.splice($grid.outParams.inventory.indexOf(rowFound[0]), 1);
              $row.cells.quantity.editControl.value = null;
              $row.cells.quantity.displayControl.text = null;
  
          }
  
      } else {
  
          if ($utils.isDefined($grid.inParams.packagedAmountRequest)) {
  
              let message = 'Cannot select more than one record.'
              $shell.FootprintManager.openErrorDialog('Cannot select inventory', message);
              throw new Error(message);
          }
  
      }
  
  } else {
  
      $grid.outParams.inventory.push(
          {
              packagedAmount: $row.cells.quantity.editControl.value,
              locationId: $row.entity.LocationId,
              lotId: $row.entity.LotId,
              packagingId: $row.entity.PackagedId,
              vendorLotId: $row.entity.Lot.VendorLotId
          }
      )
  
      $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
  }
  
  addToSelectedRows($row);
  
  $grid.events.outParamsChange.emit();
  
  $grid.rows.sort((a, b) => isRowSelected(a) ? isRowSelected(b) ? 0 : -1 : isRowSelected(b) ? 1 : 0);
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(row: any) {
      return $utils.isDefined(row.cells.quantity.editControl.value) && row.cells.quantity.editControl.value > 0;
  }
  
  function addToSelectedRows(row: any) {
      if ($utils.isDefined($grid.vars.selectedRows)) {
  
          const rowFound = $grid.vars.selectedRows.filter(
              existingRow => existingRow.entity.LotId === $row.entity.LotId
                  && existingRow.entity.LocationId === $row.entity.LocationId
                  && existingRow.entity.PackagedId === $row.entity.PackagedId
          );
  
          if ($utils.isDefined(rowFound)) {
  
              if (isRowSelected($row)) {
                  rowFound[0].packagedAmount = $row.cells.quantity.editControl.value;
                  $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
  
              } else {
  
                  $grid.vars.selectedRows.splice($grid.vars.selectedRows.indexOf(rowFound[0]), 1);
                  $row.cells.quantity.editControl.value = null;
                  $row.cells.quantity.displayControl.text = null;
  
              }
  
          } else {
  
              $grid.vars.selectedRows.push($row);
  
              $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
          }
  
      } else {
  
          $grid.vars.selectedRows.push($row);
  
          $row.cells.quantity.displayControl.text = $row.cells.quantity.editControl.value;
      }
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-custom_inventory_by_location_selection_grid',
  templateUrl: './app.custom_inventory_by_location_selection_grid.component.html'
})
export class app_custom_inventory_by_location_selection_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_custom_inventory_by_location_selection_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_custom_inventory_by_location_selection_gridComponentInParams = { warehouseId: null, materialId: null, packagingId: null, lotId: null, projectId: null, packagedAmountRequest: null };

  outParams: Iapp_custom_inventory_by_location_selection_gridComponentOutParams = { inventory: [] };

  //#region Variables
  vars: { selectedRows?: any[] } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ inventory?: { lotId?: number, locationId?: number, packagingId?: number, packagedAmount?: number, vendorLotId?: number }[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     quantity: new GridHeaderModel(new HeaderStyles(null, null), 'quantity', 'Quantity', false, false, null, false),       location: new GridHeaderModel(new HeaderStyles(null, null), 'location', 'Location', false, false, null, false),       material_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'material_lookupcode', 'Material', false, false, null, false),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'packaged_amount', 'On hand', false, false, null, false),       available: new GridHeaderModel(new HeaderStyles(null, null), 'available', 'Available', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'vendorlot', 'Vendor lot', false, false, null, false),       location_status: new GridHeaderModel(new HeaderStyles(null, null), 'location_status', 'Location status', false, false, null, false),       eligible_for_allocation: new GridHeaderModel(new HeaderStyles(null, null), 'eligible_for_allocation', 'Eligible for allocation', false, false, null, false),       global_allocation_sequence: new GridHeaderModel(new HeaderStyles(null, null), 'global_allocation_sequence', 'Allocation seq', false, false, null, false),       expiration_date: new GridHeaderModel(new HeaderStyles(null, null), 'expiration_date', 'Expiration date', false, false, null, false),       manufacture_date: new GridHeaderModel(new HeaderStyles(null, null), 'manufacture_date', 'Manufacture date', false, false, null, false),       material_description: new GridHeaderModel(new HeaderStyles(null, null), 'material_description', 'Description', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_custom_inventory_by_location_selection_gridComponentRowModel[] = [];

  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('packagingId') set $inParams_packagingId(value: any) {
    this.inParams['packagingId'] = value;
  }
  get $inParams_packagingId(): any {
    return this.inParams['packagingId'] ;
  }
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('packagedAmountRequest') set $inParams_packagedAmountRequest(value: any) {
    this.inParams['packagedAmountRequest'] = value;
  }
  get $inParams_packagedAmountRequest(): any {
    return this.inParams['packagedAmountRequest'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: app_ShellService,
    private $datasources: app_DatasourceService,
    private $flows: app_FlowService,
    private $reports: app_ReportService,
    private $localization: app_LocalizationService,
    private $operations: app_OperationService,
    private $logger: CleanupLoggerService,
    private $context: app_custom_inventory_by_location_selection_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Inventory by location';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 15;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouseId:  $grid.inParams.warehouseId ,
      materialId:  $grid.inParams.materialId ,
      packagingId:  $grid.inParams.packagingId ,
      lotId:  $grid.inParams.lotId ,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      take:  null ,
      skip:  null ,
    };
    try {
      const data = await this.$datasources.Inventory.ds_inventory_by_location_selection_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as Iapp_custom_inventory_by_location_selection_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_custom_inventory_by_location_selection_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: app_custom_inventory_by_location_selection_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_inventory_by_location_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_inventory_by_location_selection_grid.on_init');
  $grid.vars.selectedRows = [];
  }
  sort_grid(event = null) {
    return this.sort_gridInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async sort_gridInternal(
    $grid: app_custom_inventory_by_location_selection_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_custom_inventory_by_location_selection_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'custom_inventory_by_location_selection_grid.sort_grid');
  await delay(125);
  
  $grid.rows.sort((a, b) => isRowSelected(a) >= 0 ? isRowSelected(b) >= 0 ? isRowSelected(a) < isRowSelected(b) ? -1 : 1 : -1 : isRowSelected(b) >= 0 ? 1 : 0);
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  function isRowSelected(inputRow: any) {
      return $grid.vars.selectedRows.indexOf($grid.vars.selectedRows.find(selectedRow => 
          selectedRow.entity.LocationId === inputRow.entity.LocationId
          && selectedRow.entity.LotId === inputRow.entity.LotId
          && selectedRow.entity.PackagedId === inputRow.entity.PackagedId
      ));
  }
  
  function delay(ms: number) {
      return new Promise( resolve => setTimeout(resolve, ms) );
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
