import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';

interface IPurchaseOrders_inbound_orders_summary_reportServiceInParams {
  startDate: string, endDate: string}

interface IPurchaseOrders_inbound_orders_summary_reportServiceData {
  OrderShipmentSummary?: { result?: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, OwnerReference?: string, OrderLines?: { LineNumber?: number }[], Project?: { Id?: number, LookupCode?: string, Name?: string }, PreferredWarehouse?: { Name?: string }, PreferredCarrier?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number }[], Actuals?: { TotalPackagedAmount?: number, TotalGrossWeight?: number, OrderId?: number }, Expected?: { TotalPackagedAmount?: number, OrderId?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_inbound_orders_summary_reportService extends ReportBaseService<IPurchaseOrders_inbound_orders_summary_reportServiceInParams, IPurchaseOrders_inbound_orders_summary_reportServiceData> {

  protected reportReferenceName = 'inbound_orders_summary_report';
  protected appReferenceName = 'PurchaseOrders';

  constructor(
    $utils: UtilsService,
    private $datasources: PurchaseOrders_DatasourceService,
    private $logger: CleanupLoggerService
    ) {
      super($utils);
      $logger.log('PurchaseOrders', 'inbound_orders_summary_report');
  }

  override throwIfMissingRequiredInParams (inParams: IPurchaseOrders_inbound_orders_summary_reportServiceInParams) {
  }

  protected async getData(inParams: IPurchaseOrders_inbound_orders_summary_reportServiceInParams): Promise<IPurchaseOrders_inbound_orders_summary_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IPurchaseOrders_inbound_orders_summary_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IPurchaseOrders_inbound_orders_summary_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        startDate:  $report.inParams.startDate ,
        endDate:  $report.inParams.endDate 
      };
      
      const dsData = await this.$datasources.PurchaseOrders.ds_inbound_orders_summary_report.get(dsParams);
      
      data.OrderShipmentSummary = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
