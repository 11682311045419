import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Locations_ModuleContextService } from './Locations.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Locations_mixing_rule_dd_ComponentContextService {
  private readonly moduleContext: Locations_ModuleContextService;

  constructor(moduleContext: Locations_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'mixing_rule_dd',
      type: ComponentType.selector
    }
  }
}