import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LoadContainers_ds_shipment_expectedService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentIds: number[], statusId?: number, available?: boolean }): 
  Promise<{ result: { Amount?: number, Shipment?: { Id?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_shipment_expected/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { shipmentIds: number[], statusId?: number, available?: boolean, $top?: number, $skip?: number }): 
  Promise<{ result: { Amount?: number, Shipment?: { Id?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_shipment_expected/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shipmentIds: number[], statusId?: number, available?: boolean, $keys: any[] }): 
  Promise<{ result: { Amount?: number, Shipment?: { Id?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentIds)) {
      missingRequiredInParams.push('\'shipmentIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_shipment_expected/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipmentIds: number[], statusId?: number, available?: boolean    }) {
        if(isNil(inParams.shipmentIds)) {
          return true; 
        }
      return false;
    }
}
