<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized ">



      <mat-card-content data-cy="card-content" class="card-content" >
        <div [formGroup]="formGroupContent"
             class="formdata">
                <div data-cy="fieldset-formGroupContent-id-newGroup1"
                     *ngIf="!content.fieldsets.newGroup1.hidden && !content.fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{content.fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-date" *ngIf="!content.fields.date.hidden && !content.fields.date.removed" 
                            class="field-container standard {{content.fields.date.invalid ? 'invalid' : ''}} {{content.fields.date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="content.fields.date.styles.style"
                            [ngClass]="content.fields.date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.date.label + (content.fields.date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.date.label}}<span *ngIf="content.fields.date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="date"
                            [format]="content.fields.date.control.format"
                            [mode]="content.fields.date.control.mode"
                            [ngStyle]="content.fields.date.control.styles.style"
                            [ngClass]="content.fields.date.control.styles.classes"
                            [tooltip]="content.fields.date.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="content.fields.date.invalid">
                          <ng-container *ngFor="let error of content.fields.date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!content.fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </mat-card-content>


      <mat-card-footer data-cy="card-footer" class="card-footer">
        <div [formGroup]="formGroupFooter"
             class="formdata">
                <div data-cy="fieldset-formGroupFooter-id-newGroup1"
                     *ngIf="!footer.fieldsets.newGroup1.hidden && !footer.fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': footer.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!footer.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="footer.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{footer.fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="footer.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !footer.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': footer.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="footer.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                    </div>
        
                    <div class="fieldset-details" *ngIf="!footer.fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </mat-card-footer>

    </ng-container>

  </mat-card>
  <div class="card-gripper"></div>
</div>