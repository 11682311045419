<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden && !fieldsets.header.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.header.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-operation_code" *ngIf="!fields.operation_code.hidden && !fields.operation_code.removed" 
                            class="field-container standard {{fields.operation_code.invalid ? 'invalid' : ''}} {{fields.operation_code.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.operation_code.styles.style"
                            [ngClass]="fields.operation_code.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.operation_code.label + (fields.operation_code.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.operation_code.label}}<span *ngIf="fields.operation_code.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inspections-inspection_operation_codes_dd_single 
                            data-cy="selector"
                            [type]="fields.operation_code.control.type"
                            formControlName="operation_code"
                            (displayTextChange)="fields.operation_code.control.displayText=$event"
                            [placeholder]="fields.operation_code.control.placeholder"
                            [styles]="fields.operation_code.control.styles"
                            [tooltip]="fields.operation_code.control.tooltip"
                        >
                        </Inspections-inspection_operation_codes_dd_single>
                        <ng-container *ngIf="fields.operation_code.invalid">
                          <ng-container *ngFor="let error of fields.operation_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden && !fields.warehouse.removed" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}} {{fields.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.warehouse.label + (fields.warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                            [tooltip]="fields.warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}} {{fields.status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inspections-task_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                            [tooltip]="fields.status.control.tooltip"
                        >
                        </Inspections-task_statuses_dd_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order" *ngIf="!fields.order.hidden && !fields.order.removed" 
                            class="field-container standard {{fields.order.invalid ? 'invalid' : ''}} {{fields.order.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.order.styles.style"
                            [ngClass]="fields.order.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.order.label + (fields.order.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.order.label}}<span *ngIf="fields.order.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="order"
                                matInput
                                numberBox
                                [format]="fields.order.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.order.control.readOnly || fields.order.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.order.control.placeholder}}"
                                [ngStyle]="fields.order.control.styles.style"
                                [ngClass]="fields.order.control.styles.classes"
                                [matTooltip]="fields.order.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.order.invalid">
                          <ng-container *ngFor="let error of fields.order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [statusId]="$fields_project_selector_inParams_statusId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden && !fields.material.removed" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}} {{fields.material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material.label + (fields.material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material.label}}<span *ngIf="fields.material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-materials_by_project_dd_single 
                            data-cy="selector"
                            [type]="fields.material.control.type"
                            formControlName="material"
                            (displayTextChange)="fields.material.control.displayText=$event"
                            [placeholder]="fields.material.control.placeholder"
                            [styles]="fields.material.control.styles"
                            [tooltip]="fields.material.control.tooltip"
                          [projectId]="$fields_material_selector_inParams_projectId"
                        >
                        </Materials-materials_by_project_dd_single>
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_lot" *ngIf="!fields.vendor_lot.hidden && !fields.vendor_lot.removed" 
                            class="field-container standard {{fields.vendor_lot.invalid ? 'invalid' : ''}} {{fields.vendor_lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_lot.styles.style"
                            [ngClass]="fields.vendor_lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_lot.label + (fields.vendor_lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_lot.label}}<span *ngIf="fields.vendor_lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Lots-vendorlots_dd_single 
                            data-cy="selector"
                            [type]="fields.vendor_lot.control.type"
                            formControlName="vendor_lot"
                            (displayTextChange)="fields.vendor_lot.control.displayText=$event"
                            [placeholder]="fields.vendor_lot.control.placeholder"
                            [styles]="fields.vendor_lot.control.styles"
                            [tooltip]="fields.vendor_lot.control.tooltip"
                          [materialId]="$fields_vendor_lot_selector_inParams_materialId"
                          [lotId]="$fields_vendor_lot_selector_inParams_lotId"
                        >
                        </Lots-vendorlots_dd_single>
                        <ng-container *ngIf="fields.vendor_lot.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden && !fields.lot.removed" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}} {{fields.lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lot.label + (fields.lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lot.label}}<span *ngIf="fields.lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Lots-lots_dd_single 
                            data-cy="selector"
                            [type]="fields.lot.control.type"
                            formControlName="lot"
                            (displayTextChange)="fields.lot.control.displayText=$event"
                            [placeholder]="fields.lot.control.placeholder"
                            [styles]="fields.lot.control.styles"
                            [tooltip]="fields.lot.control.tooltip"
                          [materialId]="$fields_lot_selector_inParams_materialId"
                          [vendorLotId]="$fields_lot_selector_inParams_vendorLotId"
                        >
                        </Lots-lots_dd_single>
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_location" *ngIf="!fields.source_location.hidden && !fields.source_location.removed" 
                            class="field-container standard {{fields.source_location.invalid ? 'invalid' : ''}} {{fields.source_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.source_location.styles.style"
                            [ngClass]="fields.source_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.source_location.label + (fields.source_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.source_location.label}}<span *ngIf="fields.source_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.source_location.control.type"
                            formControlName="source_location"
                            (displayTextChange)="fields.source_location.control.displayText=$event"
                            [placeholder]="fields.source_location.control.placeholder"
                            [styles]="fields.source_location.control.styles"
                            [tooltip]="fields.source_location.control.tooltip"
                          [warehouseId]="$fields_source_location_selector_inParams_warehouseId"
                          [typeId]="$fields_source_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.source_location.invalid">
                          <ng-container *ngFor="let error of fields.source_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_license_plate" *ngIf="!fields.source_license_plate.hidden && !fields.source_license_plate.removed" 
                            class="field-container standard {{fields.source_license_plate.invalid ? 'invalid' : ''}} {{fields.source_license_plate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.source_license_plate.styles.style"
                            [ngClass]="fields.source_license_plate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.source_license_plate.label + (fields.source_license_plate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.source_license_plate.label}}<span *ngIf="fields.source_license_plate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-licenseplates_dd_single 
                            data-cy="selector"
                            [type]="fields.source_license_plate.control.type"
                            formControlName="source_license_plate"
                            (displayTextChange)="fields.source_license_plate.control.displayText=$event"
                            [placeholder]="fields.source_license_plate.control.placeholder"
                            [styles]="fields.source_license_plate.control.styles"
                            [tooltip]="fields.source_license_plate.control.tooltip"
                          [locationId]="$fields_source_license_plate_selector_inParams_locationId"
                          [typeId]="$fields_source_license_plate_selector_inParams_typeId"
                          [archived]="$fields_source_license_plate_selector_inParams_archived"
                        >
                        </Inventory-licenseplates_dd_single>
                        <ng-container *ngIf="fields.source_license_plate.invalid">
                          <ng-container *ngFor="let error of fields.source_license_plate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reason_code" *ngIf="!fields.reason_code.hidden && !fields.reason_code.removed" 
                            class="field-container standard {{fields.reason_code.invalid ? 'invalid' : ''}} {{fields.reason_code.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.reason_code.styles.style"
                            [ngClass]="fields.reason_code.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reason_code.label + (fields.reason_code.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reason_code.label}}<span *ngIf="fields.reason_code.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inspections-reason_codes_dd_single 
                            data-cy="selector"
                            [type]="fields.reason_code.control.type"
                            formControlName="reason_code"
                            (displayTextChange)="fields.reason_code.control.displayText=$event"
                            [placeholder]="fields.reason_code.control.placeholder"
                            [styles]="fields.reason_code.control.styles"
                            [tooltip]="fields.reason_code.control.tooltip"
                        >
                        </Inspections-reason_codes_dd_single>
                        <ng-container *ngIf="fields.reason_code.invalid">
                          <ng-container *ngFor="let error of fields.reason_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.header.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-notes"
                     *ngIf="!fieldsets.notes.hidden && !fieldsets.notes.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.notes.collapsible }">
                    <div *ngIf="!fieldsets.notes.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.notes.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.notes.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.notes.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.notes.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.notes.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.notes.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.notes.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-details_group"
                     *ngIf="!fieldsets.details_group.hidden && !fieldsets.details_group.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.details_group.collapsible }">
                    <div *ngIf="!fieldsets.details_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.details_group.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.details_group.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.details_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.details_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.details_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.details_group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-created_on" *ngIf="!fields.created_on.hidden && !fields.created_on.removed" 
                            class="field-container standard {{fields.created_on.invalid ? 'invalid' : ''}} {{fields.created_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.created_on.styles.style"
                            [ngClass]="fields.created_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.created_on.label + (fields.created_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.created_on.label}}<span *ngIf="fields.created_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.created_on.control.styles.style"
                              [ngClass]="fields.created_on.control.styles.classes"
                              [matTooltip]="fields.created_on.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.created_on.control.text }}</div>
                        <ng-container *ngIf="fields.created_on.invalid">
                          <ng-container *ngFor="let error of fields.created_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-released_on" *ngIf="!fields.released_on.hidden && !fields.released_on.removed" 
                            class="field-container standard {{fields.released_on.invalid ? 'invalid' : ''}} {{fields.released_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.released_on.styles.style"
                            [ngClass]="fields.released_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.released_on.label + (fields.released_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.released_on.label}}<span *ngIf="fields.released_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.released_on.control.styles.style"
                              [ngClass]="fields.released_on.control.styles.classes"
                              [matTooltip]="fields.released_on.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.released_on.control.text }}</div>
                        <ng-container *ngIf="fields.released_on.invalid">
                          <ng-container *ngFor="let error of fields.released_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-started_on" *ngIf="!fields.started_on.hidden && !fields.started_on.removed" 
                            class="field-container standard {{fields.started_on.invalid ? 'invalid' : ''}} {{fields.started_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.started_on.styles.style"
                            [ngClass]="fields.started_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.started_on.label + (fields.started_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.started_on.label}}<span *ngIf="fields.started_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.started_on.control.styles.style"
                              [ngClass]="fields.started_on.control.styles.classes"
                              [matTooltip]="fields.started_on.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.started_on.control.text }}</div>
                        <ng-container *ngIf="fields.started_on.invalid">
                          <ng-container *ngFor="let error of fields.started_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-completed_on" *ngIf="!fields.completed_on.hidden && !fields.completed_on.removed" 
                            class="field-container standard {{fields.completed_on.invalid ? 'invalid' : ''}} {{fields.completed_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.completed_on.styles.style"
                            [ngClass]="fields.completed_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.completed_on.label + (fields.completed_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.completed_on.label}}<span *ngIf="fields.completed_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.completed_on.control.styles.style"
                              [ngClass]="fields.completed_on.control.styles.classes"
                              [matTooltip]="fields.completed_on.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.completed_on.control.text }}</div>
                        <ng-container *ngIf="fields.completed_on.invalid">
                          <ng-container *ngFor="let error of fields.completed_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-cancelled_on" *ngIf="!fields.cancelled_on.hidden && !fields.cancelled_on.removed" 
                            class="field-container standard {{fields.cancelled_on.invalid ? 'invalid' : ''}} {{fields.cancelled_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.cancelled_on.styles.style"
                            [ngClass]="fields.cancelled_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.cancelled_on.label + (fields.cancelled_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.cancelled_on.label}}<span *ngIf="fields.cancelled_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.cancelled_on.control.styles.style"
                              [ngClass]="fields.cancelled_on.control.styles.classes"
                              [matTooltip]="fields.cancelled_on.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.cancelled_on.control.text }}</div>
                        <ng-container *ngIf="fields.cancelled_on.invalid">
                          <ng-container *ngFor="let error of fields.cancelled_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-modified_on" *ngIf="!fields.modified_on.hidden && !fields.modified_on.removed" 
                            class="field-container standard {{fields.modified_on.invalid ? 'invalid' : ''}} {{fields.modified_on.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.modified_on.styles.style"
                            [ngClass]="fields.modified_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.modified_on.label + (fields.modified_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.modified_on.label}}<span *ngIf="fields.modified_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.modified_on.control.styles.style"
                              [ngClass]="fields.modified_on.control.styles.classes"
                              [matTooltip]="fields.modified_on.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.modified_on.control.text }}</div>
                        <ng-container *ngIf="fields.modified_on.invalid">
                          <ng-container *ngFor="let error of fields.modified_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-modified_by" *ngIf="!fields.modified_by.hidden && !fields.modified_by.removed" 
                            class="field-container standard {{fields.modified_by.invalid ? 'invalid' : ''}} {{fields.modified_by.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.modified_by.styles.style"
                            [ngClass]="fields.modified_by.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.modified_by.label + (fields.modified_by.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.modified_by.label}}<span *ngIf="fields.modified_by.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.modified_by.control.styles.style"
                              [ngClass]="fields.modified_by.control.styles.classes"
                              [matTooltip]="fields.modified_by.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.modified_by.control.text }}</div>
                        <ng-container *ngIf="fields.modified_by.invalid">
                          <ng-container *ngFor="let error of fields.modified_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.details_group.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>