import { Utilities_Types } from './Utilities.frontend.types'

export namespace WorkOrders_Types {
  /**
   * Holds possible values for creating an accessorial task.
   */
  export enum EAccessorialTaskOption {
    Release = 1,
    Plan = 4,
    Complete = 2
  }
}

export namespace $frontendTypes {
  export import Utilities =  Utilities_Types;
  export import WorkOrders = WorkOrders_Types;
}




