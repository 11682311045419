<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-target_material" *ngIf="!fields.target_material.hidden && !fields.target_material.removed" 
                            class="field-container standard {{fields.target_material.invalid ? 'invalid' : ''}} {{fields.target_material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.target_material.styles.style"
                            [ngClass]="fields.target_material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.target_material.label + (fields.target_material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.target_material.label}}<span *ngIf="fields.target_material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-materials_by_project_dd_single 
                            data-cy="selector"
                            [type]="fields.target_material.control.type"
                            formControlName="target_material"
                            (displayTextChange)="fields.target_material.control.displayText=$event"
                            [placeholder]="fields.target_material.control.placeholder"
                            [styles]="fields.target_material.control.styles"
                            [tooltip]="fields.target_material.control.tooltip"
                          [projectId]="$fields_target_material_selector_inParams_projectId"
                        >
                        </Materials-materials_by_project_dd_single>
                        <ng-container *ngIf="fields.target_material.invalid">
                          <ng-container *ngFor="let error of fields.target_material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden && !fields.packaging.removed" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}} {{fields.packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.packaging.label + (fields.packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.packaging.label}}<span *ngIf="fields.packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.packaging.control.type"
                            formControlName="packaging"
                            (displayTextChange)="fields.packaging.control.displayText=$event"
                            [placeholder]="fields.packaging.control.placeholder"
                            [styles]="fields.packaging.control.styles"
                            [tooltip]="fields.packaging.control.tooltip"
                          [materialId]="$fields_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden && !fields.lot.removed" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}} {{fields.lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lot.label + (fields.lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lot.label}}<span *ngIf="fields.lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <FootprintManager-lots_dd_single 
                            data-cy="selector"
                            [type]="fields.lot.control.type"
                            formControlName="lot"
                            (displayTextChange)="fields.lot.control.displayText=$event"
                            [placeholder]="fields.lot.control.placeholder"
                            [styles]="fields.lot.control.styles"
                            [tooltip]="fields.lot.control.tooltip"
                          [materialId]="$fields_lot_selector_inParams_materialId"
                        >
                        </FootprintManager-lots_dd_single>
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_lot" *ngIf="!fields.vendor_lot.hidden && !fields.vendor_lot.removed" 
                            class="field-container standard {{fields.vendor_lot.invalid ? 'invalid' : ''}} {{fields.vendor_lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_lot.styles.style"
                            [ngClass]="fields.vendor_lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_lot.label + (fields.vendor_lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_lot.label}}<span *ngIf="fields.vendor_lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Lots-vendorlots_dd_single 
                            data-cy="selector"
                            [type]="fields.vendor_lot.control.type"
                            formControlName="vendor_lot"
                            (displayTextChange)="fields.vendor_lot.control.displayText=$event"
                            [placeholder]="fields.vendor_lot.control.placeholder"
                            [styles]="fields.vendor_lot.control.styles"
                            [tooltip]="fields.vendor_lot.control.tooltip"
                          [materialId]="$fields_vendor_lot_selector_inParams_materialId"
                          [lotId]="$fields_vendor_lot_selector_inParams_lotId"
                        >
                        </Lots-vendorlots_dd_single>
                        <ng-container *ngIf="fields.vendor_lot.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight" *ngIf="!fields.weight.hidden && !fields.weight.removed" 
                            class="field-container standard {{fields.weight.invalid ? 'invalid' : ''}} {{fields.weight.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.weight.styles.style"
                            [ngClass]="fields.weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weight.label + (fields.weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weight.label}}<span *ngIf="fields.weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="weight"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.weight.control.readOnly || fields.weight.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.weight.control.placeholder}}"
                                [ngStyle]="fields.weight.control.styles.style"
                                [ngClass]="fields.weight.control.styles.classes"
                                [matTooltip]="fields.weight.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.weight.invalid">
                          <ng-container *ngFor="let error of fields.weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden && !fields.weight_uom.removed" 
                            class="field-container standard {{fields.weight_uom.invalid ? 'invalid' : ''}} {{fields.weight_uom.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weight_uom.label + (fields.weight_uom.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                            [tooltip]="fields.weight_uom.control.tooltip"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </Materials-measurement_units_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-length" *ngIf="!fields.length.hidden && !fields.length.removed" 
                            class="field-container standard {{fields.length.invalid ? 'invalid' : ''}} {{fields.length.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.length.styles.style"
                            [ngClass]="fields.length.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.length.label + (fields.length.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.length.label}}<span *ngIf="fields.length.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="length"
                                matInput
                                numberBox
                                [format]="fields.length.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.length.control.readOnly || fields.length.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.length.control.placeholder}}"
                                [ngStyle]="fields.length.control.styles.style"
                                [ngClass]="fields.length.control.styles.classes"
                                [matTooltip]="fields.length.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.length.invalid">
                          <ng-container *ngFor="let error of fields.length.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-length_uom" *ngIf="!fields.length_uom.hidden && !fields.length_uom.removed" 
                            class="field-container standard {{fields.length_uom.invalid ? 'invalid' : ''}} {{fields.length_uom.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.length_uom.styles.style"
                            [ngClass]="fields.length_uom.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.length_uom.label + (fields.length_uom.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.length_uom.label}}<span *ngIf="fields.length_uom.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.length_uom.control.type"
                            formControlName="length_uom"
                            (displayTextChange)="fields.length_uom.control.displayText=$event"
                            [placeholder]="fields.length_uom.control.placeholder"
                            [styles]="fields.length_uom.control.styles"
                            [tooltip]="fields.length_uom.control.tooltip"
                          [typeId]="$fields_length_uom_selector_inParams_typeId"
                        >
                        </Inventory-measurement_units_dd_single>
                        <ng-container *ngIf="fields.length_uom.invalid">
                          <ng-container *ngFor="let error of fields.length_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-width" *ngIf="!fields.width.hidden && !fields.width.removed" 
                            class="field-container standard {{fields.width.invalid ? 'invalid' : ''}} {{fields.width.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.width.styles.style"
                            [ngClass]="fields.width.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.width.label + (fields.width.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.width.label}}<span *ngIf="fields.width.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="width"
                                matInput
                                numberBox
                                [format]="fields.width.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.width.control.readOnly || fields.width.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.width.control.placeholder}}"
                                [ngStyle]="fields.width.control.styles.style"
                                [ngClass]="fields.width.control.styles.classes"
                                [matTooltip]="fields.width.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.width.invalid">
                          <ng-container *ngFor="let error of fields.width.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-width_uom" *ngIf="!fields.width_uom.hidden && !fields.width_uom.removed" 
                            class="field-container standard {{fields.width_uom.invalid ? 'invalid' : ''}} {{fields.width_uom.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.width_uom.styles.style"
                            [ngClass]="fields.width_uom.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.width_uom.label + (fields.width_uom.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.width_uom.label}}<span *ngIf="fields.width_uom.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.width_uom.control.type"
                            formControlName="width_uom"
                            (displayTextChange)="fields.width_uom.control.displayText=$event"
                            [placeholder]="fields.width_uom.control.placeholder"
                            [styles]="fields.width_uom.control.styles"
                            [tooltip]="fields.width_uom.control.tooltip"
                          [typeId]="$fields_width_uom_selector_inParams_typeId"
                        >
                        </Inventory-measurement_units_dd_single>
                        <ng-container *ngIf="fields.width_uom.invalid">
                          <ng-container *ngFor="let error of fields.width_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-height" *ngIf="!fields.height.hidden && !fields.height.removed" 
                            class="field-container standard {{fields.height.invalid ? 'invalid' : ''}} {{fields.height.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.height.styles.style"
                            [ngClass]="fields.height.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.height.label + (fields.height.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.height.label}}<span *ngIf="fields.height.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="height"
                                matInput
                                numberBox
                                [format]="fields.height.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.height.control.readOnly || fields.height.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.height.control.placeholder}}"
                                [ngStyle]="fields.height.control.styles.style"
                                [ngClass]="fields.height.control.styles.classes"
                                [matTooltip]="fields.height.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.height.invalid">
                          <ng-container *ngFor="let error of fields.height.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-height_uom" *ngIf="!fields.height_uom.hidden && !fields.height_uom.removed" 
                            class="field-container standard {{fields.height_uom.invalid ? 'invalid' : ''}} {{fields.height_uom.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.height_uom.styles.style"
                            [ngClass]="fields.height_uom.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.height_uom.label + (fields.height_uom.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.height_uom.label}}<span *ngIf="fields.height_uom.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.height_uom.control.type"
                            formControlName="height_uom"
                            (displayTextChange)="fields.height_uom.control.displayText=$event"
                            [placeholder]="fields.height_uom.control.placeholder"
                            [styles]="fields.height_uom.control.styles"
                            [tooltip]="fields.height_uom.control.tooltip"
                        >
                        </Inventory-measurement_units_dd_single>
                        <ng-container *ngIf="fields.height_uom.invalid">
                          <ng-container *ngFor="let error of fields.height_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-quantity_to_transform" *ngIf="!fields.quantity_to_transform.hidden && !fields.quantity_to_transform.removed" 
                            class="field-container standard {{fields.quantity_to_transform.invalid ? 'invalid' : ''}} {{fields.quantity_to_transform.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.quantity_to_transform.styles.style"
                            [ngClass]="fields.quantity_to_transform.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.quantity_to_transform.label + (fields.quantity_to_transform.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.quantity_to_transform.label}}<span *ngIf="fields.quantity_to_transform.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.quantity_to_transform.control.styles.style"
                              [ngClass]="fields.quantity_to_transform.control.styles.classes"
                              [matTooltip]="fields.quantity_to_transform.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.quantity_to_transform.control.text }}</div>
                        <ng-container *ngIf="fields.quantity_to_transform.invalid">
                          <ng-container *ngFor="let error of fields.quantity_to_transform.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.target_lines.hasTabToShow" class="tab" data-cy="tab-target_lines">
              <h2 [className]="tabs.target_lines.active? 'active': ''" (click)="tabs.target_lines.activate()">{{tabs.target_lines.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-inventory_transformation_target_lines_grid *ngIf="tabs.target_lines.active"
              #$tabs_target_lines
              [inventory_transformation_id]="$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_inventory_transformation_id"
              [source_line_id]="$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_source_line_id"
              [inventory_to_transform]="$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_inventory_to_transform"
              [warehouse_id]="$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_warehouse_id"
              [additional_options]="$tabs_target_lines_inventory_transformation_target_lines_grid_inParams_additional_options"
              (outParamsChange)="on_grid_output_change($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_target_lines')">
              </FootprintManager-inventory_transformation_target_lines_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>