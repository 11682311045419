import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_get_manufacturing_order_component_serial_numbers_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { material_id?: number, warehouse_id?: number, license_plate?: number }): 
  Promise<{ result: { Id?: number, Archived?: boolean, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, NetVolume?: number, NetWeight?: number, PackagingId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string } }, LicensePlate?: { Id?: number, LookupCode?: string, WarehouseId?: number }, Packaging?: { Id?: number, Name?: string, Weight?: number, WeightUomId?: number, WeightUom?: { Short_name?: string } }, DimensionsUom?: { Id?: number, Short_name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_order_component_serial_numbers_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { material_id?: number, warehouse_id?: number, license_plate?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Archived?: boolean, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, NetVolume?: number, NetWeight?: number, PackagingId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string } }, LicensePlate?: { Id?: number, LookupCode?: string, WarehouseId?: number }, Packaging?: { Id?: number, Name?: string, Weight?: number, WeightUomId?: number, WeightUom?: { Short_name?: string } }, DimensionsUom?: { Id?: number, Short_name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_order_component_serial_numbers_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { material_id?: number, warehouse_id?: number, license_plate?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, Archived?: boolean, DimensionUomId?: number, GrossVolume?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateId?: number, LookupCode?: string, NetVolume?: number, NetWeight?: number, PackagingId?: number, VolumeUomId?: number, WeightUomId?: number, Width?: number, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string } }, LicensePlate?: { Id?: number, LookupCode?: string, WarehouseId?: number }, Packaging?: { Id?: number, Name?: string, Weight?: number, WeightUomId?: number, WeightUom?: { Short_name?: string } }, DimensionsUom?: { Id?: number, Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_get_manufacturing_order_component_serial_numbers_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      material_id?: number, warehouse_id?: number, license_plate?: number    }) {
      return false;
    }
}
