import {
Component,
OnInit,
OnDestroy,
OnChanges,
Input,
SimpleChanges,
Output,
EventEmitter,
Inject
} from '@angular/core';

import {
FormGroup,
Validators
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import {
TextBoxModel,
NumberBoxModel,
SelectBoxModel,
ESelectBoxType,
DateBoxModel,
CheckBoxModel,
TextModel,
LabelModel,
ButtonModel,
SplitButtonModel,
SeparatorModel,
ImageModel,
DrawModel,
CodeBoxModel,
ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import * as vkbeautify from 'vkbeautify';
import { BaseComponent } from './components/base.component';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Utilities_ShellService } from './Utilities.shell.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { Utilities_view_json_payload_ComponentContextService } from './Utilities.view_json_payload.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Utilities.frontend.types'
import { $frontendTypes as $types} from './Utilities.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Utilities-view_json_payload',
  templateUrl: './Utilities.view_json_payload.component.html'
})
export class Utilities_view_json_payloadComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges
{

inParams: { payload?: any, title?: string, id?: string, application_name?: string, storage?: string } = { payload: null, title: null, id: null, application_name: null, storage: null };
//#region Inputs
@Input('payload') set $inParams_payload(v: any) {
  this.inParams.payload = v;
}
get $inParams_payload(): any {
  return this.inParams.payload;
}
@Input('title') set $inParams_title(v: string) {
  this.inParams.title = v;
}
get $inParams_title(): string {
  return this.inParams.title;
}
@Input('id') set $inParams_id(v: string) {
  this.inParams.id = v;
}
get $inParams_id(): string {
  return this.inParams.id;
}
@Input('application_name') set $inParams_application_name(v: string) {
  this.inParams.application_name = v;
}
get $inParams_application_name(): string {
  return this.inParams.application_name;
}
@Input('storage') set $inParams_storage(v: string) {
  this.inParams.storage = v;
}
get $inParams_storage(): string {
  return this.inParams.storage;
}
//#endregion Inputs

//#region Outputs
@Output()
$finish = new EventEmitter();
//#endregion

//#region title
// Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
// The title is often meant to be shown from the parent (shell breadcrumb for example)
// and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
// the parent has already been checked and the child now change something on the parent 
// in dev, CD is run twice
$titleChange = new EventEmitter<string>(true);
private $_title: string;
get title(): string {
  return this.$_title;
}
set title(t: string) {
  this.$_title = t;
  this.$titleChange.emit(this.$_title);
}
//#endregion title
codeMirrorOptions = {
theme: 'base16-light',
mode: 'application/json',
lineNumbers: true,
lineWrapping: true,
foldGutter: true,
gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
autoCloseBrackets: true,
matchBrackets: true,
lint: true
};

mode = 'application/json';

value: string;

//#region Variables
//#endregion
//#region Events

//#endregion

toolbar = {
  save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, false, false, 'Save', 'icon-ic_fluent_save_20_regular', null)
, false),
  export_to_file: new ToolModel(new ButtonModel('export_to_file', new ButtonStyles(['secondary'], null), false, false, false, 'Export', 'icon-ic_fluent_arrow_export_20_regular', null)
, false)
};

constructor(
private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Utilities_ShellService,
private $datasources: Utilities_DatasourceService,
private $flows: Utilities_FlowService,
private $reports: Utilities_ReportService,
private $localization: Utilities_LocalizationService,
private $operations: Utilities_OperationService,
private $logger: CleanupLoggerService,
private $context: Utilities_view_json_payload_ComponentContextService,
) {
  super();
}

ngOnInit(): void {
  this.$init();
}

private $isFirstNgOnChanges = true;
ngOnChanges(changes: SimpleChanges): void {
  if (this.$isFirstNgOnChanges) {
    this.$isFirstNgOnChanges = false;
  } else {
    this.$init();
  }
}

private $unsubscribe$ = new Subject();
ngOnDestroy(): void {
  this.$unsubscribe$.next(null);
  this.$unsubscribe$.complete();
}

initialized = false;

async $init() {
this.title = 'view_json_payload';

const $codeEditor = this;
const $utils = this.$utils;

this.value = $codeEditor.inParams.payload;

await this.on_init();

this.initialized = true;
}

onCodeMirrorLoaded(editor: CodemirrorComponent){
  //temporary fix to reset the left margin on the gutters. on load an extra left space is being added to the CodeMirror-gutters div element, which causes it to overlap the code content. this refresh re-calculates the space needed and removes the gap. a better implementation could be possible.
  setTimeout(() =>{editor.codeMirror.refresh() }, 300);
}

close() {
this.$finish.emit();
}

beautify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xml(this.value) : vkbeautify.json(this.value);
}

minify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xmlmin(this.value, [, true]) : vkbeautify.jsonmin(this.value);
}

refresh(
skipParent = false,
skipChildren = false,
childToSkip: string = null) {
}

//#region private flows
on_beautify_clicked(event = null) {
  return this.on_beautify_clickedInternal(
    this,
this.$shell,
    this.$datasources,
    this.$flows,
    this.$reports,
    this.$settings,
    this.$operations,
    this.$utils,
    this.$context,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_beautify_clickedInternal(
  $codeEditor: Utilities_view_json_payloadComponent,

  $shell: Utilities_ShellService,
  $datasources: Utilities_DatasourceService,
  $flows: Utilities_FlowService,
  $reports: Utilities_ReportService,
  $settings: SettingsValuesService,
  $operations: Utilities_OperationService,
  $utils: UtilsService,
  $context: Utilities_view_json_payload_ComponentContextService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Utilities_LocalizationService,
  $event: any
) {
  this.$logger.log('Utilities', 'view_json_payload.on_beautify_clicked');
//O.Arias - 02/06/2024

$codeEditor.beautify();
}
on_init(event = null) {
  return this.on_initInternal(
    this,
this.$shell,
    this.$datasources,
    this.$flows,
    this.$reports,
    this.$settings,
    this.$operations,
    this.$utils,
    this.$context,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_initInternal(
  $codeEditor: Utilities_view_json_payloadComponent,

  $shell: Utilities_ShellService,
  $datasources: Utilities_DatasourceService,
  $flows: Utilities_FlowService,
  $reports: Utilities_ReportService,
  $settings: SettingsValuesService,
  $operations: Utilities_OperationService,
  $utils: UtilsService,
  $context: Utilities_view_json_payload_ComponentContextService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Utilities_LocalizationService,
  $event: any
) {
//O.Arias - 02/04/2025

$codeEditor.beautify();

let title: string = $codeEditor.inParams.title ?? "";

if (title === "") {
    $codeEditor.title = "Code Editor";
} else {
    $codeEditor.title = title;
}

if ($codeEditor?.value?.length > 300000) {
    await $codeEditor.on_export_to_file();
    $codeEditor.value = JSON.stringify({
        Message: `The payload is too large to display. See the generated file.`
    })
}
}
on_save(event = null) {
  return this.on_saveInternal(
    this,
this.$shell,
    this.$datasources,
    this.$flows,
    this.$reports,
    this.$settings,
    this.$operations,
    this.$utils,
    this.$context,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_saveInternal(
  $codeEditor: Utilities_view_json_payloadComponent,

  $shell: Utilities_ShellService,
  $datasources: Utilities_DatasourceService,
  $flows: Utilities_FlowService,
  $reports: Utilities_ReportService,
  $settings: SettingsValuesService,
  $operations: Utilities_OperationService,
  $utils: UtilsService,
  $context: Utilities_view_json_payload_ComponentContextService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Utilities_LocalizationService,
  $event: any
) {
let payload = $codeEditor.value;
let title = $codeEditor.title;
let id = $codeEditor.inParams.id;
let application_name = $codeEditor.inParams.application_name;
let storage = $codeEditor.inParams.storage;

if ($utils.isDefined(payload) && $utils.isDefined(id)) {

    if (storage?.toUpperCase()?.includes("MESSAGE")
        && title?.toUpperCase()?.includes("PAYLOAD")) {

        await $flows.Utilities.messages_update({
            input: [{
                message_id: id,
                payload: payload
            }],
            criteria: { application_name: application_name }
        })
    }

    if (storage?.toUpperCase()?.includes("MESSAGE")
        && title?.toUpperCase()?.includes("NOTE")) {

        await $flows.Utilities.messages_update({
            input: [{
                message_id: id,
                notes: payload
            }],
            criteria: { application_name: application_name }
        })
    }

    if (storage?.toUpperCase()?.includes("MESSAGE")
        && title?.toUpperCase()?.includes("MESSAGE")) {

        await $flows.Utilities.messages_update({
            input: [{
                message_id: id,
                message: payload
            }],
            criteria: { application_name: application_name }
        })
    }

    if (storage?.toUpperCase()?.includes("LOG")
        && title?.toUpperCase()?.includes("NOTE")) {

        await $flows.Utilities.logs_update({
            input: [{
                log_id: id,
                notes: payload
            }],
            criteria: { application_name: application_name }
        })
    }

    if (storage?.toUpperCase()?.includes("LOG")
        && title?.toUpperCase()?.includes("MESSAGE")) {

        await $flows.Utilities.logs_update({
            input: [{
                log_id: id,
                message: payload
            }],
            criteria: { application_name: application_name }
        })
    }
}
}
on_export_to_file(event = null) {
  return this.on_export_to_fileInternal(
    this,
this.$shell,
    this.$datasources,
    this.$flows,
    this.$reports,
    this.$settings,
    this.$operations,
    this.$utils,
    this.$context,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_export_to_fileInternal(
  $codeEditor: Utilities_view_json_payloadComponent,

  $shell: Utilities_ShellService,
  $datasources: Utilities_DatasourceService,
  $flows: Utilities_FlowService,
  $reports: Utilities_ReportService,
  $settings: SettingsValuesService,
  $operations: Utilities_OperationService,
  $utils: UtilsService,
  $context: Utilities_view_json_payload_ComponentContextService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Utilities_LocalizationService,
  $event: any
) {
//O.Arias - 02/04/2025

try {

    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const hour = now.getHours();
    const minute = now.getMinutes();
    const second = now.getSeconds();

    const lookup_array = [`${year}`, (`00${month}`).slice(-2), (`00${day}`).slice(-2), (`00${hour}`).slice(-2), (`00${minute}`).slice(-2), (`00${second}`).slice(-2)];
    const lookup_seed = lookup_array.join("");

    let id = $codeEditor.inParams.id;
    let payload = $codeEditor.value;
    let storage = $codeEditor.inParams.storage ?? "JSON";

    storage = `${storage.substring(0, 1).toUpperCase()}${storage.substring(1, storage.length)}`

    const payload_blob = new Blob([payload]);

    await $utils.blob.saveFile(payload_blob, {
        fileName: `${storage}_${id.toLocaleUpperCase()}_${lookup_seed}.json`,
        extensions: ['.json']
    });

} catch (error) {
    // Get inner-most error message
    let targetError = error;
    while ($utils.isDefined(targetError?.error)) { targetError = targetError.error }
    if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` } }
    throw new Error(`[on_export_to_file] Error ${targetError.message}`);
}
}
//#endregion private flows
}