import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './ExcelInventoryImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_inventory_import_stagingService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { payload?: $frontendTypes.ExcelInventoryImport.InventoryImportRecord[], action?: string, skip?: number, take?: number, full_text_search?: string, import_status?: string, owner?: string, project?: string, warehouse?: string, location?: string, material?: string, lot?: string, vendor_lot?: string, license_plate?: string, packaging?: string }): Promise<{ payload?: $frontendTypes.ExcelInventoryImport.InventoryImportRecord[], success?: boolean, messages?: any[], count?: number }> 
  {
    let url = `${environment.backendUrl}api/ExcelInventoryImport/functions/inventory_import_staging`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

