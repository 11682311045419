import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Allocations_allocations_homeComponent } from './Allocations.allocations_home.component';
import { Allocations_config_content_codeComponent } from './Allocations.config_content_code.component';
import { Allocations_allocation_configuration_entities_dd_singleComponent } from './Allocations.allocation_configuration_entities_dd_single.component';
import { Allocations_base_strategies_dd_singleComponent } from './Allocations.base_strategies_dd_single.component';
import { Allocations_days_remaining_date_fields_dd_singleComponent } from './Allocations.days_remaining_date_fields_dd_single.component';
import { Allocations_footprint_configurations_scopes_dd_singleComponent } from './Allocations.footprint_configurations_scopes_dd_single.component';
import { Allocations_full_pallets_first_dd_singleComponent } from './Allocations.full_pallets_first_dd_single.component';
import { Allocations_hard_allocation_contexts_dd_singleComponent } from './Allocations.hard_allocation_contexts_dd_single.component';
import { Allocations_order_class_types_dd_singleComponent } from './Allocations.order_class_types_dd_single.component';
import { Allocations_order_classes_dd_singleComponent } from './Allocations.order_classes_dd_single.component';
import { Allocations_packaging_strategies_dd_singleComponent } from './Allocations.packaging_strategies_dd_single.component';
import { Allocations_replenishment_contexts_dd_singleComponent } from './Allocations.replenishment_contexts_dd_single.component';
import { Allocations_replenishment_quantities_dd_singleComponent } from './Allocations.replenishment_quantities_dd_single.component';
import { Allocations_allocation_configuration_entities_dd_multiComponent } from './Allocations.allocation_configuration_entities_dd_multi.component';
import { Allocations_base_strategies_dd_multiComponent } from './Allocations.base_strategies_dd_multi.component';
import { Allocations_days_remaining_date_fields_dd_multiComponent } from './Allocations.days_remaining_date_fields_dd_multi.component';
import { Allocations_footprint_configurations_scopes_dd_multiComponent } from './Allocations.footprint_configurations_scopes_dd_multi.component';
import { Allocations_full_pallets_first_dd_multiComponent } from './Allocations.full_pallets_first_dd_multi.component';
import { Allocations_hard_allocation_contexts_dd_multiComponent } from './Allocations.hard_allocation_contexts_dd_multi.component';
import { Allocations_order_class_types_dd_multiComponent } from './Allocations.order_class_types_dd_multi.component';
import { Allocations_order_classes_dd_multiComponent } from './Allocations.order_classes_dd_multi.component';
import { Allocations_packaging_strategies_dd_multiComponent } from './Allocations.packaging_strategies_dd_multi.component';
import { Allocations_replenishment_contexts_dd_multiComponent } from './Allocations.replenishment_contexts_dd_multi.component';
import { Allocations_replenishment_quantities_dd_multiComponent } from './Allocations.replenishment_quantities_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Allocations_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Allocations: Allocations_ShellService = this;

  public openallocations_home(replaceCurrentView?: boolean) {
    this.$logger.log('Allocations', 'allocations_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Allocations home page',
        referenceName: 'Allocations_allocations_home',
        component: Allocations_allocations_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openallocations_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Allocations', 'allocations_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Allocations_allocations_homeComponent,
      'Allocations home page',
      mode,
      dialogSize
    )
  }
  public openconfig_content_code(inParams:{ payload: any, config_id: number }, replaceCurrentView?: boolean) {
    this.$logger.log('Allocations', 'config_content_code');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'config_content_code',
        referenceName: 'Allocations_config_content_code',
        component: Allocations_config_content_codeComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openconfig_content_codeDialog(
    inParams:{ payload: any, config_id: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Allocations', 'config_content_code');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Allocations_config_content_codeComponent,
      'config_content_code',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Allocations_allocations_home') {
      this.$logger.log('Allocations', 'allocations_home');
      const title = 'Allocations home page';
      const component = Allocations_allocations_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_config_content_code') {
      this.$logger.log('Allocations', 'config_content_code');
      const title = 'config_content_code';
      const component = Allocations_config_content_codeComponent;
      const inParams:{ payload: any, config_id: number } = { payload: null, config_id: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('config_id'))) {
        const paramValueString = params.get('config_id');
        inParams.config_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_allocation_configuration_entities_dd_single') {
      this.$logger.log('Allocations', 'allocation_configuration_entities_dd_single');
      const title = 'allocation_configuration_entities_dd';
      const component = Allocations_allocation_configuration_entities_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_base_strategies_dd_single') {
      this.$logger.log('Allocations', 'base_strategies_dd_single');
      const title = 'base_strategies_dd';
      const component = Allocations_base_strategies_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_days_remaining_date_fields_dd_single') {
      this.$logger.log('Allocations', 'days_remaining_date_fields_dd_single');
      const title = 'days_remaining_date_fields_dd';
      const component = Allocations_days_remaining_date_fields_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_footprint_configurations_scopes_dd_single') {
      this.$logger.log('Allocations', 'footprint_configurations_scopes_dd_single');
      const title = 'footprint_configurations_scopes_dd';
      const component = Allocations_footprint_configurations_scopes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_full_pallets_first_dd_single') {
      this.$logger.log('Allocations', 'full_pallets_first_dd_single');
      const title = 'full_pallets_first_dd';
      const component = Allocations_full_pallets_first_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_hard_allocation_contexts_dd_single') {
      this.$logger.log('Allocations', 'hard_allocation_contexts_dd_single');
      const title = 'hard_allocation_contexts_dd';
      const component = Allocations_hard_allocation_contexts_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_order_class_types_dd_single') {
      this.$logger.log('Allocations', 'order_class_types_dd_single');
      const title = 'order_class_types_dd';
      const component = Allocations_order_class_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_order_classes_dd_single') {
      this.$logger.log('Allocations', 'order_classes_dd_single');
      const title = 'order_classes_dd';
      const component = Allocations_order_classes_dd_singleComponent;
      const inParams:{ order_class_type_id?: number } = { order_class_type_id: null };
      if (!isNil(params.get('order_class_type_id'))) {
        const paramValueString = params.get('order_class_type_id');
        inParams.order_class_type_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_packaging_strategies_dd_single') {
      this.$logger.log('Allocations', 'packaging_strategies_dd_single');
      const title = 'packaging_strategies_dd';
      const component = Allocations_packaging_strategies_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_replenishment_contexts_dd_single') {
      this.$logger.log('Allocations', 'replenishment_contexts_dd_single');
      const title = 'replenishment_contexts_dd';
      const component = Allocations_replenishment_contexts_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_replenishment_quantities_dd_single') {
      this.$logger.log('Allocations', 'replenishment_quantities_dd_single');
      const title = 'replenishment_quantities_dd';
      const component = Allocations_replenishment_quantities_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_allocation_configuration_entities_dd_multi') {
      this.$logger.log('Allocations', 'allocation_configuration_entities_dd_multi');
      const title = 'allocation_configuration_entities_dd';
      const component = Allocations_allocation_configuration_entities_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_base_strategies_dd_multi') {
      this.$logger.log('Allocations', 'base_strategies_dd_multi');
      const title = 'base_strategies_dd';
      const component = Allocations_base_strategies_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_days_remaining_date_fields_dd_multi') {
      this.$logger.log('Allocations', 'days_remaining_date_fields_dd_multi');
      const title = 'days_remaining_date_fields_dd';
      const component = Allocations_days_remaining_date_fields_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_footprint_configurations_scopes_dd_multi') {
      this.$logger.log('Allocations', 'footprint_configurations_scopes_dd_multi');
      const title = 'footprint_configurations_scopes_dd';
      const component = Allocations_footprint_configurations_scopes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_full_pallets_first_dd_multi') {
      this.$logger.log('Allocations', 'full_pallets_first_dd_multi');
      const title = 'full_pallets_first_dd';
      const component = Allocations_full_pallets_first_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_hard_allocation_contexts_dd_multi') {
      this.$logger.log('Allocations', 'hard_allocation_contexts_dd_multi');
      const title = 'hard_allocation_contexts_dd';
      const component = Allocations_hard_allocation_contexts_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_order_class_types_dd_multi') {
      this.$logger.log('Allocations', 'order_class_types_dd_multi');
      const title = 'order_class_types_dd';
      const component = Allocations_order_class_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_order_classes_dd_multi') {
      this.$logger.log('Allocations', 'order_classes_dd_multi');
      const title = 'order_classes_dd';
      const component = Allocations_order_classes_dd_multiComponent;
      const inParams:{ order_class_type_id?: number } = { order_class_type_id: null };
      if (!isNil(params.get('order_class_type_id'))) {
        const paramValueString = params.get('order_class_type_id');
        inParams.order_class_type_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_packaging_strategies_dd_multi') {
      this.$logger.log('Allocations', 'packaging_strategies_dd_multi');
      const title = 'packaging_strategies_dd';
      const component = Allocations_packaging_strategies_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_replenishment_contexts_dd_multi') {
      this.$logger.log('Allocations', 'replenishment_contexts_dd_multi');
      const title = 'replenishment_contexts_dd';
      const component = Allocations_replenishment_contexts_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Allocations_replenishment_quantities_dd_multi') {
      this.$logger.log('Allocations', 'replenishment_quantities_dd_multi');
      const title = 'replenishment_quantities_dd';
      const component = Allocations_replenishment_quantities_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
