import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './BarcodeParsing.frontend.types'

@Injectable({ providedIn: 'root' })
export class BarcodeParsing_parse_barcode_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { inputString: string }): Promise<{ material?: { lookupCode?: string }, vendorlot?: { lookupCode?: string, manufactureDate?: string, expirationDate?: string }, lot?: { lookupCode?: string }, serialNumber?: { lookupCode?: string }, quantity?: number, packaging?: { name?: string, UPC?: string }, weight?: { net?: number, gross?: number, unitOfMeasure?: string } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inputString)) {
      missingRequiredInParams.push('\'inputString\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/BarcodeParsing/functions/parse_barcode_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

