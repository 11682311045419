import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './AsnOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class AsnOrders_generate_asn_child_serial_numbers_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { serialNumberIds: number[] }): Promise<{ reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serialNumberIds)) {
      missingRequiredInParams.push('\'serialNumberIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/AsnOrders/functions/generate_asn_child_serial_numbers_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

