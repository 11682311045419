<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-general"
                     *ngIf="!fieldsets.general.hidden && !fieldsets.general.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.general.collapsible }">
                    <div *ngIf="!fieldsets.general.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.general.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.general.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.general.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.general.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.general.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.general.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-convert_packaging_to_base" *ngIf="!fields.convert_packaging_to_base.hidden && !fields.convert_packaging_to_base.removed" 
                            class="field-container standard {{fields.convert_packaging_to_base.invalid ? 'invalid' : ''}} {{fields.convert_packaging_to_base.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.convert_packaging_to_base.styles.style"
                            [ngClass]="fields.convert_packaging_to_base.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.convert_packaging_to_base.label + (fields.convert_packaging_to_base.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.convert_packaging_to_base.label}}<span *ngIf="fields.convert_packaging_to_base.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="convert_packaging_to_base"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.convert_packaging_to_base.control.styles.style"
                                      [ngClass]="fields.convert_packaging_to_base.control.styles.classes"
                                      [matTooltip]="fields.convert_packaging_to_base.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.convert_packaging_to_base.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.convert_packaging_to_base.invalid">
                          <ng-container *ngFor="let error of fields.convert_packaging_to_base.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.general.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-serial_number_options"
                     *ngIf="!fieldsets.serial_number_options.hidden && !fieldsets.serial_number_options.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.serial_number_options.collapsible }">
                    <div *ngIf="!fieldsets.serial_number_options.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.serial_number_options.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.serial_number_options.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.serial_number_options.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.serial_number_options.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.serial_number_options.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.serial_number_options.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-copy_volume" *ngIf="!fields.copy_volume.hidden && !fields.copy_volume.removed" 
                            class="field-container standard {{fields.copy_volume.invalid ? 'invalid' : ''}} {{fields.copy_volume.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.copy_volume.styles.style"
                            [ngClass]="fields.copy_volume.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.copy_volume.label + (fields.copy_volume.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.copy_volume.label}}<span *ngIf="fields.copy_volume.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="copy_volume"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.copy_volume.control.styles.style"
                                      [ngClass]="fields.copy_volume.control.styles.classes"
                                      [matTooltip]="fields.copy_volume.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.copy_volume.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.copy_volume.invalid">
                          <ng-container *ngFor="let error of fields.copy_volume.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-copy_dimensions" *ngIf="!fields.copy_dimensions.hidden && !fields.copy_dimensions.removed" 
                            class="field-container standard {{fields.copy_dimensions.invalid ? 'invalid' : ''}} {{fields.copy_dimensions.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.copy_dimensions.styles.style"
                            [ngClass]="fields.copy_dimensions.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.copy_dimensions.label + (fields.copy_dimensions.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.copy_dimensions.label}}<span *ngIf="fields.copy_dimensions.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="copy_dimensions"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.copy_dimensions.control.styles.style"
                                      [ngClass]="fields.copy_dimensions.control.styles.classes"
                                      [matTooltip]="fields.copy_dimensions.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.copy_dimensions.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.copy_dimensions.invalid">
                          <ng-container *ngFor="let error of fields.copy_dimensions.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-copy_weights" *ngIf="!fields.copy_weights.hidden && !fields.copy_weights.removed" 
                            class="field-container standard {{fields.copy_weights.invalid ? 'invalid' : ''}} {{fields.copy_weights.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.copy_weights.styles.style"
                            [ngClass]="fields.copy_weights.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.copy_weights.label + (fields.copy_weights.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.copy_weights.label}}<span *ngIf="fields.copy_weights.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="copy_weights"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.copy_weights.control.styles.style"
                                      [ngClass]="fields.copy_weights.control.styles.classes"
                                      [matTooltip]="fields.copy_weights.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.copy_weights.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.copy_weights.invalid">
                          <ng-container *ngFor="let error of fields.copy_weights.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.serial_number_options.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-lot_number_options"
                     *ngIf="!fieldsets.lot_number_options.hidden && !fieldsets.lot_number_options.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.lot_number_options.collapsible }">
                    <div *ngIf="!fieldsets.lot_number_options.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.lot_number_options.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.lot_number_options.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.lot_number_options.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.lot_number_options.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.lot_number_options.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.lot_number_options.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-copy_lot_number" *ngIf="!fields.copy_lot_number.hidden && !fields.copy_lot_number.removed" 
                            class="field-container standard {{fields.copy_lot_number.invalid ? 'invalid' : ''}} {{fields.copy_lot_number.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.copy_lot_number.styles.style"
                            [ngClass]="fields.copy_lot_number.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.copy_lot_number.label + (fields.copy_lot_number.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.copy_lot_number.label}}<span *ngIf="fields.copy_lot_number.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="copy_lot_number"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.copy_lot_number.control.styles.style"
                                      [ngClass]="fields.copy_lot_number.control.styles.classes"
                                      [matTooltip]="fields.copy_lot_number.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.copy_lot_number.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.copy_lot_number.invalid">
                          <ng-container *ngFor="let error of fields.copy_lot_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-copy_lot_udfs" *ngIf="!fields.copy_lot_udfs.hidden && !fields.copy_lot_udfs.removed" 
                            class="field-container standard {{fields.copy_lot_udfs.invalid ? 'invalid' : ''}} {{fields.copy_lot_udfs.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.copy_lot_udfs.styles.style"
                            [ngClass]="fields.copy_lot_udfs.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.copy_lot_udfs.label + (fields.copy_lot_udfs.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.copy_lot_udfs.label}}<span *ngIf="fields.copy_lot_udfs.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="copy_lot_udfs"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.copy_lot_udfs.control.styles.style"
                                      [ngClass]="fields.copy_lot_udfs.control.styles.classes"
                                      [matTooltip]="fields.copy_lot_udfs.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.copy_lot_udfs.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.copy_lot_udfs.invalid">
                          <ng-container *ngFor="let error of fields.copy_lot_udfs.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-keep_source_creation_date" *ngIf="!fields.keep_source_creation_date.hidden && !fields.keep_source_creation_date.removed" 
                            class="field-container standard {{fields.keep_source_creation_date.invalid ? 'invalid' : ''}} {{fields.keep_source_creation_date.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.keep_source_creation_date.styles.style"
                            [ngClass]="fields.keep_source_creation_date.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.keep_source_creation_date.label + (fields.keep_source_creation_date.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.keep_source_creation_date.label}}<span *ngIf="fields.keep_source_creation_date.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="keep_source_creation_date"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.keep_source_creation_date.control.styles.style"
                                      [ngClass]="fields.keep_source_creation_date.control.styles.classes"
                                      [matTooltip]="fields.keep_source_creation_date.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.keep_source_creation_date.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.keep_source_creation_date.invalid">
                          <ng-container *ngFor="let error of fields.keep_source_creation_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.lot_number_options.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-instructions"
                     *ngIf="!fieldsets.instructions.hidden && !fieldsets.instructions.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.instructions.collapsible }">
                    <div *ngIf="!fieldsets.instructions.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.instructions.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.instructions.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.instructions.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.instructions.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.instructions.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.instructions.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-instructions" *ngIf="!fields.instructions.hidden && !fields.instructions.removed" 
                            class="field-container standard {{fields.instructions.invalid ? 'invalid' : ''}} {{fields.instructions.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.instructions.styles.style"
                            [ngClass]="fields.instructions.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.instructions.label + (fields.instructions.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.instructions.label}}<span *ngIf="fields.instructions.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.instructions.control.readOnly || fields.instructions.control.disabled"
                                [ngStyle]="fields.instructions.control.styles.style"
                                [ngClass]="fields.instructions.control.styles.classes"
                                [matTooltip]="fields.instructions.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                        >
                          <div class="button-label">
                            <ng-container *ngIf="fields.instructions.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.instructions.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.instructions.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.instructions.invalid">
                          <ng-container *ngFor="let error of fields.instructions.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-add_custom_instruction" *ngIf="!fields.add_custom_instruction.hidden && !fields.add_custom_instruction.removed" 
                            class="field-container standard {{fields.add_custom_instruction.invalid ? 'invalid' : ''}} {{fields.add_custom_instruction.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.add_custom_instruction.styles.style"
                            [ngClass]="fields.add_custom_instruction.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.add_custom_instruction.label + (fields.add_custom_instruction.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.add_custom_instruction.label}}<span *ngIf="fields.add_custom_instruction.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.add_custom_instruction.control.readOnly || fields.add_custom_instruction.control.disabled"
                                [ngStyle]="fields.add_custom_instruction.control.styles.style"
                                [ngClass]="fields.add_custom_instruction.control.styles.classes"
                                [matTooltip]="fields.add_custom_instruction.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                        >
                          <div class="button-label">
                            <ng-container *ngIf="fields.add_custom_instruction.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.add_custom_instruction.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.add_custom_instruction.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.add_custom_instruction.invalid">
                          <ng-container *ngFor="let error of fields.add_custom_instruction.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-instruction_text" *ngIf="!fields.instruction_text.hidden && !fields.instruction_text.removed" 
                            class="field-container double {{fields.instruction_text.invalid ? 'invalid' : ''}} {{fields.instruction_text.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.instruction_text.styles.style"
                            [ngClass]="fields.instruction_text.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.instruction_text.label + (fields.instruction_text.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.instruction_text.label}}<span *ngIf="fields.instruction_text.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="instruction_text"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.instruction_text.control.readOnly || fields.instruction_text.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.instruction_text.control.placeholder}}"
                                [ngStyle]="fields.instruction_text.control.styles.style"
                                [ngClass]="fields.instruction_text.control.styles.classes"
                                [matTooltip]="fields.instruction_text.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.instruction_text.invalid">
                          <ng-container *ngFor="let error of fields.instruction_text.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.instructions.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>