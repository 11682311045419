import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_manufacturing_order_return_to_stock_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { $top?: number, $skip?: number }): Promise<{ result?: { lineNumber?: number, component?: string, lot?: string, remainingQuantity?: number, componentPackageId?: number, countedQuantity?: number, materialId?: number, lotId?: number }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_manufacturing_order_return_to_stock_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { $keys: number[] }): Promise<{ result?: { lineNumber?: number, component?: string, lot?: string, remainingQuantity?: number, componentPackageId?: number, countedQuantity?: number, materialId?: number, lotId?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_manufacturing_order_return_to_stock_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      }) {
      return false;
    }
}
