import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_ds_shipping_containers_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderTypeIds?: number[], warehouseIds?: number[], shipmentIds?: number[], dateFrom?: string, dateTo?: string, dateType?: string, ownerIds?: number[], projectIds?: number[], fullTextSearch?: string, shipmentStatuses?: number[] }): 
  Promise<{ result: { Id?: number, BillOfLading?: string, BondNumber?: string, CarrierId?: number, CarrierServiceTypeId?: number, CommodityDescription?: string, ConsigneeContactId?: number, ContainerNumber?: string, ContainerSizeId?: number, ContainerTypeId?: number, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomsReleaseNumber?: string, DeclaredValue?: number, DeclaredValueCurrencyId?: number, Deleted?: boolean, DimensionUomId?: number, EstimatedDeliveryDateTime?: string, FreightClassId?: number, Height?: number, InventoryMeasurementUnitId?: number, Length?: number, LookupCode?: string, ManifestedPackageBundleCode?: string, ManifestedPackageCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, NmfcNumber?: string, NmfcSubNumber?: string, Notes?: string, OrderTypeId?: number, PackagedItemCount?: number, ParentId?: number, SailOnBoard?: string, SealId?: string, ShipmentId?: number, ShipperContactId?: number, ShippingCost?: number, ShippingCurrencyId?: number, ShippingDate?: string, ShippingManifestId?: number, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, LicensePlates?: { Id?: number, LookupCode?: string, WarehouseId?: number, Warehouse?: { Name?: string } }[], Shipment?: { Id?: number, ActualWarehouseId?: number, ExpectedWarehouseId?: number, LoadContainerId?: number, LookupCode?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { LookupCode?: string, ProjectId?: number, Project?: { Name?: string, OwnerId?: number, Owner?: { Name?: string } } } }[], ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string }, LoadContainer?: { LookupCode?: string }, Status?: { Name?: string } }, TasksForActualSourceShippingContainer?: { CompletedDateTime?: string }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_shipping_containers_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderTypeIds?: number[], warehouseIds?: number[], shipmentIds?: number[], dateFrom?: string, dateTo?: string, dateType?: string, ownerIds?: number[], projectIds?: number[], fullTextSearch?: string, shipmentStatuses?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, BillOfLading?: string, BondNumber?: string, CarrierId?: number, CarrierServiceTypeId?: number, CommodityDescription?: string, ConsigneeContactId?: number, ContainerNumber?: string, ContainerSizeId?: number, ContainerTypeId?: number, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomsReleaseNumber?: string, DeclaredValue?: number, DeclaredValueCurrencyId?: number, Deleted?: boolean, DimensionUomId?: number, EstimatedDeliveryDateTime?: string, FreightClassId?: number, Height?: number, InventoryMeasurementUnitId?: number, Length?: number, LookupCode?: string, ManifestedPackageBundleCode?: string, ManifestedPackageCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, NmfcNumber?: string, NmfcSubNumber?: string, Notes?: string, OrderTypeId?: number, PackagedItemCount?: number, ParentId?: number, SailOnBoard?: string, SealId?: string, ShipmentId?: number, ShipperContactId?: number, ShippingCost?: number, ShippingCurrencyId?: number, ShippingDate?: string, ShippingManifestId?: number, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, LicensePlates?: { Id?: number, LookupCode?: string, WarehouseId?: number, Warehouse?: { Name?: string } }[], Shipment?: { Id?: number, ActualWarehouseId?: number, ExpectedWarehouseId?: number, LoadContainerId?: number, LookupCode?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { LookupCode?: string, ProjectId?: number, Project?: { Name?: string, OwnerId?: number, Owner?: { Name?: string } } } }[], ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string }, LoadContainer?: { LookupCode?: string }, Status?: { Name?: string } }, TasksForActualSourceShippingContainer?: { CompletedDateTime?: string }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_shipping_containers_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderTypeIds?: number[], warehouseIds?: number[], shipmentIds?: number[], dateFrom?: string, dateTo?: string, dateType?: string, ownerIds?: number[], projectIds?: number[], fullTextSearch?: string, shipmentStatuses?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, BillOfLading?: string, BondNumber?: string, CarrierId?: number, CarrierServiceTypeId?: number, CommodityDescription?: string, ConsigneeContactId?: number, ContainerNumber?: string, ContainerSizeId?: number, ContainerTypeId?: number, ContainsPortalVisibleAttachments?: boolean, CreatedSysDateTime?: string, CreatedSysUser?: string, CustomsReleaseNumber?: string, DeclaredValue?: number, DeclaredValueCurrencyId?: number, Deleted?: boolean, DimensionUomId?: number, EstimatedDeliveryDateTime?: string, FreightClassId?: number, Height?: number, InventoryMeasurementUnitId?: number, Length?: number, LookupCode?: string, ManifestedPackageBundleCode?: string, ManifestedPackageCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, NmfcNumber?: string, NmfcSubNumber?: string, Notes?: string, OrderTypeId?: number, PackagedItemCount?: number, ParentId?: number, SailOnBoard?: string, SealId?: string, ShipmentId?: number, ShipperContactId?: number, ShippingCost?: number, ShippingCurrencyId?: number, ShippingDate?: string, ShippingManifestId?: number, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, LicensePlates?: { Id?: number, LookupCode?: string, WarehouseId?: number, Warehouse?: { Name?: string } }[], Shipment?: { Id?: number, ActualWarehouseId?: number, ExpectedWarehouseId?: number, LoadContainerId?: number, LookupCode?: string, StatusId?: number, TypeId?: number, OrderLookups?: { OrderId?: number, Order?: { LookupCode?: string, ProjectId?: number, Project?: { Name?: string, OwnerId?: number, Owner?: { Name?: string } } } }[], ActualWarehouse?: { Name?: string }, ExpectedWarehouse?: { Name?: string }, LoadContainer?: { LookupCode?: string }, Status?: { Name?: string } }, TasksForActualSourceShippingContainer?: { CompletedDateTime?: string }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_shipping_containers_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderTypeIds?: number[], warehouseIds?: number[], shipmentIds?: number[], dateFrom?: string, dateTo?: string, dateType?: string, ownerIds?: number[], projectIds?: number[], fullTextSearch?: string, shipmentStatuses?: number[]    }) {
      return false;
    }
}
