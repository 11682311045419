import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Waves_ds_consolidated_outbound_pick_slip_by_wave_id_reportService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { waveId: number }): 
  Promise<{ result: { ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, ExpectedSourceLocationId?: number, LotId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocation?: { Name?: string, PickSequence?: number }, ExpectedPackagedPack?: { ShortName?: string }, Material?: { LookupCode?: string, Description?: string }, Lot?: { LookupCode?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, Weight?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.waveId)) {
      missingRequiredInParams.push('\'waveId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_consolidated_outbound_pick_slip_by_wave_id_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { waveId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, ExpectedSourceLocationId?: number, LotId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocation?: { Name?: string, PickSequence?: number }, ExpectedPackagedPack?: { ShortName?: string }, Material?: { LookupCode?: string, Description?: string }, Lot?: { LookupCode?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, Weight?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.waveId)) {
      missingRequiredInParams.push('\'waveId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_consolidated_outbound_pick_slip_by_wave_id_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { waveId: number, $keys: { ExpectedPackagedPackId?: number, MaterialId?: number, ExpectedSourceLocationId?: number, LotId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocation?: { Name?: string, PickSequence?: number }, ExpectedPackagedPack?: { ShortName?: string }, Material?: { LookupCode?: string, Description?: string }, Lot?: { LookupCode?: string } }[] }): 
  Promise<{ result: { ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, ExpectedSourceLocationId?: number, LotId?: number, ExpectedSourceLicensePlateId?: number, ExpectedSourceLocation?: { Name?: string, PickSequence?: number }, ExpectedPackagedPack?: { ShortName?: string }, Material?: { LookupCode?: string, Description?: string }, Lot?: { LookupCode?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, Weight?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.waveId)) {
      missingRequiredInParams.push('\'waveId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Waves/datasources/ds_consolidated_outbound_pick_slip_by_wave_id_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      waveId: number    }) {
        if(isNil(inParams.waveId)) {
          return true; 
        }
      return false;
    }
}
