import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Dynamics365BusinessCentral.frontend.types'

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_flow_order_queue_get_oneService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { input?: $frontendTypes.Dynamics365BusinessCentral.order_queue[], criteria: { application_name: string, decrypt?: boolean, full_text_search?: string } }): Promise<{ output?: $frontendTypes.Dynamics365BusinessCentral.order_queue, success?: boolean, error?: any[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.criteria)) {
      missingRequiredInParams.push('\'criteria\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/functions/flow_order_queue_get_one`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

