<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="process" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
              >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
              >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-priority_group"
                     *ngIf="!fieldsets.priority_group.hidden && !fieldsets.priority_group.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.priority_group.collapsible }">
                    <div *ngIf="!fieldsets.priority_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.priority_group.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.priority_group.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.priority_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.priority_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.priority_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.priority_group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-priority" *ngIf="!fields.priority.hidden && !fields.priority.removed" 
                            class="field-container standard {{fields.priority.invalid ? 'invalid' : ''}} {{fields.priority.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.priority.styles.style"
                            [ngClass]="fields.priority.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.priority.label + (fields.priority.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.priority.label}}<span *ngIf="fields.priority.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.priority.control.styles.style"
                              [ngClass]="fields.priority.control.styles.classes"
                              [matTooltip]="fields.priority.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.priority.control.text }}</div>
                        <ng-container *ngIf="fields.priority.invalid">
                          <ng-container *ngFor="let error of fields.priority.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.priority_group.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-wave_options_group"
                     *ngIf="!fieldsets.wave_options_group.hidden && !fieldsets.wave_options_group.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.wave_options_group.collapsible }">
                    <div *ngIf="!fieldsets.wave_options_group.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.wave_options_group.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.wave_options_group.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.wave_options_group.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.wave_options_group.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.wave_options_group.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.wave_options_group.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-create_wave" *ngIf="!fields.create_wave.hidden && !fields.create_wave.removed" 
                            class="field-container full {{fields.create_wave.invalid ? 'invalid' : ''}} {{fields.create_wave.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.create_wave.styles.style"
                            [ngClass]="fields.create_wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.create_wave.label + (fields.create_wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.create_wave.label}}<span *ngIf="fields.create_wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="create_wave"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.create_wave.control.styles.style"
                                      [ngClass]="fields.create_wave.control.styles.classes"
                                      [matTooltip]="fields.create_wave.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.create_wave.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.create_wave.invalid">
                          <ng-container *ngFor="let error of fields.create_wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-process_wave" *ngIf="!fields.process_wave.hidden && !fields.process_wave.removed" 
                            class="field-container standard {{fields.process_wave.invalid ? 'invalid' : ''}} {{fields.process_wave.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.process_wave.styles.style"
                            [ngClass]="fields.process_wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.process_wave.label + (fields.process_wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.process_wave.label}}<span *ngIf="fields.process_wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="process_wave"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.process_wave.control.styles.style"
                                      [ngClass]="fields.process_wave.control.styles.classes"
                                      [matTooltip]="fields.process_wave.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.process_wave.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.process_wave.invalid">
                          <ng-container *ngFor="let error of fields.process_wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-release_wave" *ngIf="!fields.release_wave.hidden && !fields.release_wave.removed" 
                            class="field-container standard {{fields.release_wave.invalid ? 'invalid' : ''}} {{fields.release_wave.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.release_wave.styles.style"
                            [ngClass]="fields.release_wave.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.release_wave.label + (fields.release_wave.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.release_wave.label}}<span *ngIf="fields.release_wave.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="release_wave"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.release_wave.control.styles.style"
                                      [ngClass]="fields.release_wave.control.styles.classes"
                                      [matTooltip]="fields.release_wave.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.release_wave.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.release_wave.invalid">
                          <ng-container *ngFor="let error of fields.release_wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-create_manual_allocations_tasks" *ngIf="!fields.create_manual_allocations_tasks.hidden && !fields.create_manual_allocations_tasks.removed" 
                            class="field-container standard {{fields.create_manual_allocations_tasks.invalid ? 'invalid' : ''}} {{fields.create_manual_allocations_tasks.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.create_manual_allocations_tasks.styles.style"
                            [ngClass]="fields.create_manual_allocations_tasks.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.create_manual_allocations_tasks.label + (fields.create_manual_allocations_tasks.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.create_manual_allocations_tasks.label}}<span *ngIf="fields.create_manual_allocations_tasks.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="create_manual_allocations_tasks"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.create_manual_allocations_tasks.control.styles.style"
                                      [ngClass]="fields.create_manual_allocations_tasks.control.styles.classes"
                                      [matTooltip]="fields.create_manual_allocations_tasks.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.create_manual_allocations_tasks.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.create_manual_allocations_tasks.invalid">
                          <ng-container *ngFor="let error of fields.create_manual_allocations_tasks.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-soft_allocate" *ngIf="!fields.soft_allocate.hidden && !fields.soft_allocate.removed" 
                            class="field-container standard {{fields.soft_allocate.invalid ? 'invalid' : ''}} {{fields.soft_allocate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.soft_allocate.styles.style"
                            [ngClass]="fields.soft_allocate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.soft_allocate.label + (fields.soft_allocate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.soft_allocate.label}}<span *ngIf="fields.soft_allocate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="soft_allocate"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.soft_allocate.control.styles.style"
                                      [ngClass]="fields.soft_allocate.control.styles.classes"
                                      [matTooltip]="fields.soft_allocate.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.soft_allocate.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.soft_allocate.invalid">
                          <ng-container *ngFor="let error of fields.soft_allocate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.wave_options_group.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.components.hasTabToShow" class="tab" data-cy="tab-components">
              <h2 [className]="tabs.components.active? 'active': ''" (click)="tabs.components.activate()">{{tabs.components.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-manufacturing_order_lines_components_for_process_grid *ngIf="tabs.components.active"
              #$tabs_components
              [order_id]="$tabs_components_manufacturing_order_lines_components_for_process_grid_inParams_order_id"
              [warehouse_id]="$tabs_components_manufacturing_order_lines_components_for_process_grid_inParams_warehouse_id"
              ($refreshEvent)="refresh(false, false, '$tabs_components')">
              </FootprintManager-manufacturing_order_lines_components_for_process_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>