<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template 
                toolbarToolDef="edit" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_edit_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator1" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="create_dropdown" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div *ngIf="!tool.control.buttons.create_inventory.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.create_inventory.readOnly || tool.control.buttons.create_inventory.disabled"
                              [ngStyle]="tool.control.buttons.create_inventory.styles.style"
                              [ngClass]="tool.control.buttons.create_inventory.styles.classes"
                              [matTooltip]="tool.control.buttons.create_inventory.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_create_inventory_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.create_inventory.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.create_inventory.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.create_inventory.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.create_material.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.create_material.readOnly || tool.control.buttons.create_material.disabled"
                              [ngStyle]="tool.control.buttons.create_material.styles.style"
                              [ngClass]="tool.control.buttons.create_material.styles.classes"
                              [matTooltip]="tool.control.buttons.create_material.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_create_material_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.create_material.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.create_material.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.create_material.label}}</div>
                        </div>
                      </button>
                    </div>
                    <div *ngIf="!tool.control.buttons.create_project.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.create_project.readOnly || tool.control.buttons.create_project.disabled"
                              [ngStyle]="tool.control.buttons.create_project.styles.style"
                              [ngClass]="tool.control.buttons.create_project.styles.classes"
                              [matTooltip]="tool.control.buttons.create_project.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_create_project_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.create_project.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.create_project.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.create_project.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator2" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="on_delete" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_delete($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator3" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="attachments" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_attachments_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="separator4" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <div class="tool-separator"></div>
                </ng-container>
              </ng-template>
              <ng-template 
                toolbarToolDef="options" let-tool>
                <ng-container  *ngIf="tool.hasButtonsToShow">
                <button mat-button 
                        data-cy="splitbutton"
                        class="datex-button splitbutton" 
                        [disabled]="tool.control.readOnly || tool.control.disabled"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matMenuTriggerFor]="dropdownMenuOne"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip">
                  <div class="button-label">
                    <div *ngIf="tool.control.icon"
                          class="button-icon">
                      <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                    <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                    <div class="button-icon splitbutton-drop-icon">
                      <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                    </div>
                  </div>
                </button>
                <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                  <div data-cy="splitbutton-options">
                    <div *ngIf="!tool.control.buttons.instructions.removed" 
                        mat-menu-item 
                        class="splitbutton-item-container">
                      <button mat-button
                              data-cy="splitbutton-sub-item"
                              class="datex-button splitbutton-sub-item" 
                              [disabled]="tool.control.buttons.instructions.readOnly || tool.control.buttons.instructions.disabled"
                              [ngStyle]="tool.control.buttons.instructions.styles.style"
                              [ngClass]="tool.control.buttons.instructions.styles.classes"
                              [matTooltip]="tool.control.buttons.instructions.tooltip"
                              matTooltipClass="datex-control-tooltip"
                              (click)="on_instructions_clicked($event)">
                        <div class="button-label">
                          <div *ngIf="tool.control.buttons.instructions.icon"
                              class="button-icon">
                            <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.instructions.icon}}"></i>
                          </div>
                          <div data-cy="splitbutton-sub-item-label"
                              class="button-text">{{tool.control.buttons.instructions.label}}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </mat-menu>
                </ng-container>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-tools">
              <app-toolbar [toolbar]="actionbar">
                  <ng-template 
                    toolbarToolDef="ship_from_addresses" let-tool>
                    <ng-container  *ngIf="tool.hasButtonsToShow">
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly || tool.control.disabled"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_ship_from_addresses_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                    </ng-container>
                  </ng-template>
                  <ng-template 
                    toolbarToolDef="ship_to_addresses" let-tool>
                    <ng-container  *ngIf="tool.hasButtonsToShow">
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly || tool.control.disabled"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_ship_to_addresses_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                    </ng-container>
                  </ng-template>
                  <ng-template 
                    toolbarToolDef="assigned_containers_action" let-tool>
                    <ng-container  *ngIf="tool.hasButtonsToShow">
                    <button mat-button
                            data-cy="button"
                            class="datex-button"
                            [disabled]="tool.control.readOnly || tool.control.disabled"
                            [ngStyle]="tool.control.styles.style"
                            [ngClass]="tool.control.styles.classes"
                            [matTooltip]="tool.control.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_assignment_clicked($event)"
                            >
                      <div class="button-label">
                        <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                          <div class="button-icon">
                            <i data-cy="button-icon" class="datex-default-spinner"></i>
                          </div>
                        </ng-container>
                        <ng-template #showIcon>
                          <div class="button-icon">
                            <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                          </div>
                        </ng-template>
                        <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                      </div>
                    
                    </button>
                    </ng-container>
                  </ng-template>
              </app-toolbar>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.materials_by_count_widget.hidden && !widgets.materials_by_count_widget.removed" class="widget-container">
            <Materials-materials_by_count_widget
            #$widgets_materials_by_count_widget 
              [ownerId]="$widgets_materials_by_count_widget_inParams_ownerId"
            ($refreshEvent)="refresh(false, false, '$widgets_materials_by_count_widget')"
            >
            </Materials-materials_by_count_widget>
          </div>
          <div *ngIf="!widgets.total_packaged_amount_widget.hidden && !widgets.total_packaged_amount_widget.removed" class="widget-container">
            <Inventory-total_packaged_amount_widget
            #$widgets_total_packaged_amount_widget 
              [ownerId]="$widgets_total_packaged_amount_widget_inParams_ownerId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_packaged_amount_widget')"
            >
            </Inventory-total_packaged_amount_widget>
          </div>
          <div *ngIf="!widgets.total_gross_weight_widget.hidden && !widgets.total_gross_weight_widget.removed" class="widget-container">
            <Inventory-total_gross_weight_widget
            #$widgets_total_gross_weight_widget 
              [ownerId]="$widgets_total_gross_weight_widget_inParams_ownerId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_gross_weight_widget')"
            >
            </Inventory-total_gross_weight_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.projects.hasTabToShow" class="tab" data-cy="tab-projects">
              <h2 [className]="tabs.projects.active? 'active': ''" (click)="tabs.projects.activate()">{{tabs.projects.title}}</h2>
            </div>
            <div *ngIf="tabs.materials.hasTabToShow" class="tab" data-cy="tab-materials">
              <h2 [className]="tabs.materials.active? 'active': ''" (click)="tabs.materials.activate()">{{tabs.materials.title}}</h2>
            </div>
            <div *ngIf="tabs.inventory.hasTabToShow" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="tabs.carriers.hasTabToShow" class="tab" data-cy="tab-carriers">
              <h2 [className]="tabs.carriers.active? 'active': ''" (click)="tabs.carriers.activate()">{{tabs.carriers.title}}</h2>
            </div>
            <div *ngIf="tabs.contacts.hasTabToShow" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-projects_grid *ngIf="tabs.projects.active"
              #$tabs_projects
              [projectStatusId]="$tabs_projects_projects_grid_inParams_projectStatusId"
              [ownerId]="$tabs_projects_projects_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_projects')">
              </FootprintManager-projects_grid>
              <FootprintManager-materials_by_base_packaging_grid *ngIf="tabs.materials.active"
              #$tabs_materials
              [ownerId]="$tabs_materials_materials_by_base_packaging_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_materials')">
              </FootprintManager-materials_by_base_packaging_grid>
              <FootprintManager-inventory_location_grid *ngIf="tabs.inventory.active"
              #$tabs_inventory
              [ownerId]="$tabs_inventory_inventory_location_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory')">
              </FootprintManager-inventory_location_grid>
              <FootprintManager-ownerscarrierslookup_grid *ngIf="tabs.carriers.active"
              #$tabs_carriers
              [ownerId]="$tabs_carriers_ownerscarrierslookup_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_carriers')">
              </FootprintManager-ownerscarrierslookup_grid>
              <FootprintManager-contact_addresses_grid *ngIf="tabs.contacts.active"
              #$tabs_contacts
              [entity]="$tabs_contacts_contact_addresses_grid_inParams_entity"
              [entityId]="$tabs_contacts_contact_addresses_grid_inParams_entityId"
              [typeIds]="$tabs_contacts_contact_addresses_grid_inParams_typeIds"
              ($refreshEvent)="refresh(false, false, '$tabs_contacts')">
              </FootprintManager-contact_addresses_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>