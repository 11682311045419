<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams"  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.substitutions_swap.readOnly"
                [ngStyle]="row.options.substitutions_swap.styles.style"
                [ngClass]="row.options.substitutions_swap.styles.classes"
                (click)="row.on_swap_with_substitutions_clicked()">
          <span *ngIf="row.options.substitutions_swap.label">{{row.options.substitutions_swap.label}}</span>
        </button>
      </div>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.delete_component.readOnly"
                [ngStyle]="row.options.delete_component.styles.style"
                [ngClass]="row.options.delete_component.styles.classes"
                (click)="row.on_component_delete()">
          <span *ngIf="row.options.delete_component.label">{{row.options.delete_component.label}}</span>
        </button>
      </div>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.undo_swap.readOnly"
                [ngStyle]="row.options.undo_swap.styles.style"
                [ngClass]="row.options.undo_swap.styles.classes"
                (click)="row.on_undo_swap_clicked()">
          <span *ngIf="row.options.undo_swap.label">{{row.options.undo_swap.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="linenumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.linenumber.displayControl.styles.style"
          [ngClass]="row.cells.linenumber.displayControl.styles.classes"
          [matTooltip]="row.cells.linenumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.linenumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="component">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.component.displayControl.styles.style"
          [ngClass]="row.cells.component.displayControl.styles.classes"
          [matTooltip]="row.cells.component.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.component.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-materials_by_project_dd_single 
        data-cy="selector"
        [type]="row.cells.component.editControl.type"
        [formControl]="row.formGroup.controls['component_edit']"
        (displayTextChange)="row.cells.component.editControl.displayText=$event"
        [placeholder]="row.cells.component.editControl.placeholder"
        [styles]="row.cells.component.editControl.styles"
        [tooltip]="row.cells.component.editControl.tooltip"
      [project_id]="row.$fields_component_selector_inParams_project_id"
    >
    </Manufacturing-materials_by_project_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="initial">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.initial.displayControl.styles.style"
          [ngClass]="row.cells.initial.displayControl.styles.classes"
          [matTooltip]="row.cells.initial.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.initial.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['initial_edit']"
            matInput
            numberBox
            [format]="row.cells.initial.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.initial.editControl.readOnly || row.cells.initial.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.initial.editControl.placeholder}}"
            [ngStyle]="row.cells.initial.editControl.styles.style"
            [ngClass]="row.cells.initial.editControl.styles.classes"
            [matTooltip]="row.cells.initial.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="needed_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.needed_quantity.displayControl.styles.style"
          [ngClass]="row.cells.needed_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.needed_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.needed_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['needed_quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.needed_quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.needed_quantity.editControl.readOnly || row.cells.needed_quantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.needed_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.needed_quantity.editControl.styles.style"
            [ngClass]="row.cells.needed_quantity.editControl.styles.classes"
            [matTooltip]="row.cells.needed_quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Manufacturing-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.packaging.editControl.type"
        [formControl]="row.formGroup.controls['packaging_edit']"
        (displayTextChange)="row.cells.packaging.editControl.displayText=$event"
        [placeholder]="row.cells.packaging.editControl.placeholder"
        [styles]="row.cells.packaging.editControl.styles"
        [tooltip]="row.cells.packaging.editControl.tooltip"
      [material_id]="row.$fields_packaging_selector_inParams_material_id"
    >
    </Manufacturing-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="availability">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.availability.displayControl.styles.style"
          [ngClass]="row.cells.availability.displayControl.styles.classes"
          [matTooltip]="row.cells.availability.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.availability.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="onhandinventory">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.onhandinventory.displayControl.styles.style"
          [ngClass]="row.cells.onhandinventory.displayControl.styles.classes"
          [matTooltip]="row.cells.onhandinventory.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.onhandinventory.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="substitutions">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.substitutions.displayControl.styles.style"
          [ngClass]="row.cells.substitutions.displayControl.styles.classes"
          [matTooltip]="row.cells.substitutions.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.substitutions.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
