import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';

import { Allocations_ds_allocation_configuration_entities_ddService } from './Allocations.datasource.index';
import { Allocations_ds_base_strategies_ddService } from './Allocations.datasource.index';
import { Allocations_ds_days_remaining_date_fields_ddService } from './Allocations.datasource.index';
import { Allocations_ds_footprint_configurations_scopes_ddService } from './Allocations.datasource.index';
import { Allocations_ds_full_pallets_first_ddService } from './Allocations.datasource.index';
import { Allocations_ds_get_allocation_configurationService } from './Allocations.datasource.index';
import { Allocations_ds_hard_allocation_contexts_ddService } from './Allocations.datasource.index';
import { Allocations_ds_order_class_types_ddService } from './Allocations.datasource.index';
import { Allocations_ds_order_classes_ddService } from './Allocations.datasource.index';
import { Allocations_ds_packaging_strategies_ddService } from './Allocations.datasource.index';
import { Allocations_ds_replenishment_contexts_ddService } from './Allocations.datasource.index';
import { Allocations_ds_replenishment_quantities_ddService } from './Allocations.datasource.index';

@Injectable({ providedIn: 'root' })
export class Allocations_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
  public Allocations: Allocations_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_allocation_configuration_entities_dd: Allocations_ds_allocation_configuration_entities_ddService;
  public get ds_allocation_configuration_entities_dd(): Allocations_ds_allocation_configuration_entities_ddService {
    if(!this._ds_allocation_configuration_entities_dd) {
      this._ds_allocation_configuration_entities_dd = this.injector.get(Allocations_ds_allocation_configuration_entities_ddService);
    }
    return this._ds_allocation_configuration_entities_dd;
  }
  private _ds_base_strategies_dd: Allocations_ds_base_strategies_ddService;
  public get ds_base_strategies_dd(): Allocations_ds_base_strategies_ddService {
    if(!this._ds_base_strategies_dd) {
      this._ds_base_strategies_dd = this.injector.get(Allocations_ds_base_strategies_ddService);
    }
    return this._ds_base_strategies_dd;
  }
  private _ds_days_remaining_date_fields_dd: Allocations_ds_days_remaining_date_fields_ddService;
  public get ds_days_remaining_date_fields_dd(): Allocations_ds_days_remaining_date_fields_ddService {
    if(!this._ds_days_remaining_date_fields_dd) {
      this._ds_days_remaining_date_fields_dd = this.injector.get(Allocations_ds_days_remaining_date_fields_ddService);
    }
    return this._ds_days_remaining_date_fields_dd;
  }
  private _ds_footprint_configurations_scopes_dd: Allocations_ds_footprint_configurations_scopes_ddService;
  public get ds_footprint_configurations_scopes_dd(): Allocations_ds_footprint_configurations_scopes_ddService {
    if(!this._ds_footprint_configurations_scopes_dd) {
      this._ds_footprint_configurations_scopes_dd = this.injector.get(Allocations_ds_footprint_configurations_scopes_ddService);
    }
    return this._ds_footprint_configurations_scopes_dd;
  }
  private _ds_full_pallets_first_dd: Allocations_ds_full_pallets_first_ddService;
  public get ds_full_pallets_first_dd(): Allocations_ds_full_pallets_first_ddService {
    if(!this._ds_full_pallets_first_dd) {
      this._ds_full_pallets_first_dd = this.injector.get(Allocations_ds_full_pallets_first_ddService);
    }
    return this._ds_full_pallets_first_dd;
  }
  private _ds_get_allocation_configuration: Allocations_ds_get_allocation_configurationService;
  public get ds_get_allocation_configuration(): Allocations_ds_get_allocation_configurationService {
    if(!this._ds_get_allocation_configuration) {
      this._ds_get_allocation_configuration = this.injector.get(Allocations_ds_get_allocation_configurationService);
    }
    return this._ds_get_allocation_configuration;
  }
  private _ds_hard_allocation_contexts_dd: Allocations_ds_hard_allocation_contexts_ddService;
  public get ds_hard_allocation_contexts_dd(): Allocations_ds_hard_allocation_contexts_ddService {
    if(!this._ds_hard_allocation_contexts_dd) {
      this._ds_hard_allocation_contexts_dd = this.injector.get(Allocations_ds_hard_allocation_contexts_ddService);
    }
    return this._ds_hard_allocation_contexts_dd;
  }
  private _ds_order_class_types_dd: Allocations_ds_order_class_types_ddService;
  public get ds_order_class_types_dd(): Allocations_ds_order_class_types_ddService {
    if(!this._ds_order_class_types_dd) {
      this._ds_order_class_types_dd = this.injector.get(Allocations_ds_order_class_types_ddService);
    }
    return this._ds_order_class_types_dd;
  }
  private _ds_order_classes_dd: Allocations_ds_order_classes_ddService;
  public get ds_order_classes_dd(): Allocations_ds_order_classes_ddService {
    if(!this._ds_order_classes_dd) {
      this._ds_order_classes_dd = this.injector.get(Allocations_ds_order_classes_ddService);
    }
    return this._ds_order_classes_dd;
  }
  private _ds_packaging_strategies_dd: Allocations_ds_packaging_strategies_ddService;
  public get ds_packaging_strategies_dd(): Allocations_ds_packaging_strategies_ddService {
    if(!this._ds_packaging_strategies_dd) {
      this._ds_packaging_strategies_dd = this.injector.get(Allocations_ds_packaging_strategies_ddService);
    }
    return this._ds_packaging_strategies_dd;
  }
  private _ds_replenishment_contexts_dd: Allocations_ds_replenishment_contexts_ddService;
  public get ds_replenishment_contexts_dd(): Allocations_ds_replenishment_contexts_ddService {
    if(!this._ds_replenishment_contexts_dd) {
      this._ds_replenishment_contexts_dd = this.injector.get(Allocations_ds_replenishment_contexts_ddService);
    }
    return this._ds_replenishment_contexts_dd;
  }
  private _ds_replenishment_quantities_dd: Allocations_ds_replenishment_quantities_ddService;
  public get ds_replenishment_quantities_dd(): Allocations_ds_replenishment_quantities_ddService {
    if(!this._ds_replenishment_quantities_dd) {
      this._ds_replenishment_quantities_dd = this.injector.get(Allocations_ds_replenishment_quantities_ddService);
    }
    return this._ds_replenishment_quantities_dd;
  }
}

