<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="linecount" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes"
          [matTooltip]="row.cells.Id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.error.displayControl.styles.style"
          [ngClass]="row.cells.error.displayControl.styles.classes"
          [matTooltip]="row.cells.error.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.error.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.project_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.project_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['project_lookupcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.project_lookupcode.editControl.readOnly || row.cells.project_lookupcode.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.project_lookupcode.editControl.placeholder}}"
            [ngStyle]="row.cells.project_lookupcode.editControl.styles.style"
            [ngClass]="row.cells.project_lookupcode.editControl.styles.classes"
            [matTooltip]="row.cells.project_lookupcode.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project_name.displayControl.styles.style"
          [ngClass]="row.cells.project_name.displayControl.styles.classes"
          [matTooltip]="row.cells.project_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['project_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.project_name.editControl.readOnly || row.cells.project_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.project_name.editControl.placeholder}}"
            [ngStyle]="row.cells.project_name.editControl.styles.style"
            [ngClass]="row.cells.project_name.editControl.styles.classes"
            [matTooltip]="row.cells.project_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.owner_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.owner_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['owner_lookupcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.owner_lookupcode.editControl.readOnly || row.cells.owner_lookupcode.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.owner_lookupcode.editControl.placeholder}}"
            [ngStyle]="row.cells.owner_lookupcode.editControl.styles.style"
            [ngClass]="row.cells.owner_lookupcode.editControl.styles.classes"
            [matTooltip]="row.cells.owner_lookupcode.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.owner_name.displayControl.styles.style"
          [ngClass]="row.cells.owner_name.displayControl.styles.classes"
          [matTooltip]="row.cells.owner_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.owner_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['owner_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.owner_name.editControl.readOnly || row.cells.owner_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.owner_name.editControl.placeholder}}"
            [ngStyle]="row.cells.owner_name.editControl.styles.style"
            [ngClass]="row.cells.owner_name.editControl.styles.classes"
            [matTooltip]="row.cells.owner_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="contact_first_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.contact_first_name.displayControl.styles.style"
          [ngClass]="row.cells.contact_first_name.displayControl.styles.classes"
          [matTooltip]="row.cells.contact_first_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.contact_first_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['contact_first_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.contact_first_name.editControl.readOnly || row.cells.contact_first_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.contact_first_name.editControl.placeholder}}"
            [ngStyle]="row.cells.contact_first_name.editControl.styles.style"
            [ngClass]="row.cells.contact_first_name.editControl.styles.classes"
            [matTooltip]="row.cells.contact_first_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="contact_last_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.contact_last_name.displayControl.styles.style"
          [ngClass]="row.cells.contact_last_name.displayControl.styles.classes"
          [matTooltip]="row.cells.contact_last_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.contact_last_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['contact_last_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.contact_last_name.editControl.readOnly || row.cells.contact_last_name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.contact_last_name.editControl.placeholder}}"
            [ngStyle]="row.cells.contact_last_name.editControl.styles.style"
            [ngClass]="row.cells.contact_last_name.editControl.styles.classes"
            [matTooltip]="row.cells.contact_last_name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="contact_primary_email">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.contact_primary_email.displayControl.styles.style"
          [ngClass]="row.cells.contact_primary_email.displayControl.styles.classes"
          [matTooltip]="row.cells.contact_primary_email.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.contact_primary_email.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['contact_primary_email_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.contact_primary_email.editControl.readOnly || row.cells.contact_primary_email.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.contact_primary_email.editControl.placeholder}}"
            [ngStyle]="row.cells.contact_primary_email.editControl.styles.style"
            [ngClass]="row.cells.contact_primary_email.editControl.styles.classes"
            [matTooltip]="row.cells.contact_primary_email.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="contact_primary_telephone">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.contact_primary_telephone.displayControl.styles.style"
          [ngClass]="row.cells.contact_primary_telephone.displayControl.styles.classes"
          [matTooltip]="row.cells.contact_primary_telephone.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.contact_primary_telephone.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['contact_primary_telephone_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.contact_primary_telephone.editControl.readOnly || row.cells.contact_primary_telephone.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.contact_primary_telephone.editControl.placeholder}}"
            [ngStyle]="row.cells.contact_primary_telephone.editControl.styles.style"
            [ngClass]="row.cells.contact_primary_telephone.editControl.styles.classes"
            [matTooltip]="row.cells.contact_primary_telephone.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="contact_notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.contact_notes.displayControl.styles.style"
          [ngClass]="row.cells.contact_notes.displayControl.styles.classes"
          [matTooltip]="row.cells.contact_notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.contact_notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['contact_notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.contact_notes.editControl.readOnly || row.cells.contact_notes.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.contact_notes.editControl.placeholder}}"
            [ngStyle]="row.cells.contact_notes.editControl.styles.style"
            [ngClass]="row.cells.contact_notes.editControl.styles.classes"
            [matTooltip]="row.cells.contact_notes.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="address_attention_of">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.address_attention_of.displayControl.styles.style"
          [ngClass]="row.cells.address_attention_of.displayControl.styles.classes"
          [matTooltip]="row.cells.address_attention_of.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.address_attention_of.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['address_attention_of_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.address_attention_of.editControl.readOnly || row.cells.address_attention_of.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.address_attention_of.editControl.placeholder}}"
            [ngStyle]="row.cells.address_attention_of.editControl.styles.style"
            [ngClass]="row.cells.address_attention_of.editControl.styles.classes"
            [matTooltip]="row.cells.address_attention_of.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="address_line1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.address_line1.displayControl.styles.style"
          [ngClass]="row.cells.address_line1.displayControl.styles.classes"
          [matTooltip]="row.cells.address_line1.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.address_line1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['address_line1_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.address_line1.editControl.readOnly || row.cells.address_line1.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.address_line1.editControl.placeholder}}"
            [ngStyle]="row.cells.address_line1.editControl.styles.style"
            [ngClass]="row.cells.address_line1.editControl.styles.classes"
            [matTooltip]="row.cells.address_line1.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="address_line2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.address_line2.displayControl.styles.style"
          [ngClass]="row.cells.address_line2.displayControl.styles.classes"
          [matTooltip]="row.cells.address_line2.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.address_line2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['address_line2_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.address_line2.editControl.readOnly || row.cells.address_line2.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.address_line2.editControl.placeholder}}"
            [ngStyle]="row.cells.address_line2.editControl.styles.style"
            [ngClass]="row.cells.address_line2.editControl.styles.classes"
            [matTooltip]="row.cells.address_line2.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="address_city">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.address_city.displayControl.styles.style"
          [ngClass]="row.cells.address_city.displayControl.styles.classes"
          [matTooltip]="row.cells.address_city.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.address_city.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['address_city_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.address_city.editControl.readOnly || row.cells.address_city.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.address_city.editControl.placeholder}}"
            [ngStyle]="row.cells.address_city.editControl.styles.style"
            [ngClass]="row.cells.address_city.editControl.styles.classes"
            [matTooltip]="row.cells.address_city.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="address_state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.address_state.displayControl.styles.style"
          [ngClass]="row.cells.address_state.displayControl.styles.classes"
          [matTooltip]="row.cells.address_state.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.address_state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['address_state_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.address_state.editControl.readOnly || row.cells.address_state.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.address_state.editControl.placeholder}}"
            [ngStyle]="row.cells.address_state.editControl.styles.style"
            [ngClass]="row.cells.address_state.editControl.styles.classes"
            [matTooltip]="row.cells.address_state.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="address_postalcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.address_postalcode.displayControl.styles.style"
          [ngClass]="row.cells.address_postalcode.displayControl.styles.classes"
          [matTooltip]="row.cells.address_postalcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.address_postalcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['address_postalcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.address_postalcode.editControl.readOnly || row.cells.address_postalcode.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.address_postalcode.editControl.placeholder}}"
            [ngStyle]="row.cells.address_postalcode.editControl.styles.style"
            [ngClass]="row.cells.address_postalcode.editControl.styles.classes"
            [matTooltip]="row.cells.address_postalcode.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="address_country">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.address_country.displayControl.styles.style"
          [ngClass]="row.cells.address_country.displayControl.styles.classes"
          [matTooltip]="row.cells.address_country.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.address_country.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['address_country_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.address_country.editControl.readOnly || row.cells.address_country.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.address_country.editControl.placeholder}}"
            [ngStyle]="row.cells.address_country.editControl.styles.style"
            [ngClass]="row.cells.address_country.editControl.styles.classes"
            [matTooltip]="row.cells.address_country.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
