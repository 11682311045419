import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_freight_billing_licenseplates_aggregate_by_orderService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { order_id?: number }): 
  Promise<{ result: { licenseplate_count?: number }[] }> 
  {
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_freight_billing_licenseplates_aggregate_by_order/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { order_id?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { licenseplate_count?: number }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_freight_billing_licenseplates_aggregate_by_order/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      order_id?: number    }) {
      return false;
    }
}
