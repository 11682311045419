import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LoadContainers_ds_load_container_archived_contentsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { loadContainerIds: number[] }): 
  Promise<{ result: { Amount?: number, LicensePlate?: { Shipment?: { LoadContainerId?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerIds)) {
      missingRequiredInParams.push('\'loadContainerIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_load_container_archived_contents/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { loadContainerIds: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Amount?: number, LicensePlate?: { Shipment?: { LoadContainerId?: number } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerIds)) {
      missingRequiredInParams.push('\'loadContainerIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_load_container_archived_contents/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { loadContainerIds: number[], $keys: any[] }): 
  Promise<{ result: { Amount?: number, LicensePlate?: { Shipment?: { LoadContainerId?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.loadContainerIds)) {
      missingRequiredInParams.push('\'loadContainerIds\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LoadContainers/datasources/ds_load_container_archived_contents/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      loadContainerIds: number[]    }) {
        if(isNil(inParams.loadContainerIds)) {
          return true; 
        }
      return false;
    }
}
