import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { BarTender_ShellService } from './BarTender.shell.service';
import { BarTender_OperationService } from './BarTender.operation.service';
import { BarTender_DatasourceService } from './BarTender.datasource.index';
import { BarTender_FlowService } from './BarTender.flow.index';
import { BarTender_ReportService } from './BarTender.report.index';
import { BarTender_LocalizationService } from './BarTender.localization.service';
import { BarTender_azure_blob_storage_create_directory_form_ComponentContextService } from './BarTender.azure_blob_storage_create_directory_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './BarTender.frontend.types'
import { $frontendTypes as $types} from './BarTender.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'BarTender-azure_blob_storage_create_directory_form',
  templateUrl: './BarTender.azure_blob_storage_create_directory_form.component.html'
})
export class BarTender_azure_blob_storage_create_directory_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { path: string } = { path: null };
  //#region Inputs
  @Input('path') set $inParams_path(v: string) {
    this.inParams.path = v;
  }
  get $inParams_path(): string {
    return this.inParams.path;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { is_confirmed?: boolean } = { is_confirmed: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ is_confirmed?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), true, false, false, 'Create', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    path: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Path', false, false),
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Name', false, false),
  };

  fieldsets = {
    header: new FieldsetModel(
      'Header',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: BarTender_ShellService,
private $datasources: BarTender_DatasourceService,
private $flows: BarTender_FlowService,
private $reports: BarTender_ReportService,
private $localization: BarTender_LocalizationService,
private $operations: BarTender_OperationService,
private $logger: CleanupLoggerService,
private $context: BarTender_azure_blob_storage_create_directory_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.path)) {
        this.$missingRequiredInParams.push('path');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Create directory';
  
    const $form = this;
    const $utils = this.$utils;

    (this.fields.path.control as TextModel).text = $form.inParams.path;
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: BarTender_azure_blob_storage_create_directory_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    $context: BarTender_azure_blob_storage_create_directory_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.$logger.log('BarTender', 'azure_blob_storage_create_directory_form.on_confirm_clicked');
  await $flows.BarTender.azure_blob_storage_create_directory_flow({
      directory_path: $form.fields.path.control.text,
      directory_name: $form.fields.name.control.value
  });
  
  $form.outParams.is_confirmed = true;
  
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: BarTender_azure_blob_storage_create_directory_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    $context: BarTender_azure_blob_storage_create_directory_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.$logger.log('BarTender', 'azure_blob_storage_create_directory_form.on_init');
  $form.outParams.is_confirmed = false;
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: BarTender_azure_blob_storage_create_directory_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    $context: BarTender_azure_blob_storage_create_directory_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.$logger.log('BarTender', 'azure_blob_storage_create_directory_form.on_cancel_clicked');
  $form.close();
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form(fieldErrors: { name: Pick<string[], 'push'>, } = null) {
    const emptyResult = { name:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async validate_formInternal(
    $form: BarTender_azure_blob_storage_create_directory_formComponent,
    $validation:{
      fieldErrors: { name: Pick<string[], 'push'>, }
    },
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $context: BarTender_azure_blob_storage_create_directory_form_ComponentContextService
  ) {
    this.$logger.log('BarTender', 'azure_blob_storage_create_directory_form.validate_form');
    $form.toolbar.confirm.control.readOnly = !$utils.isDefinedTrimmed($form.fields.name.control.value);
    return $validation.fieldErrors as { name:[], };
  }
  //#endregion validation flows
}
