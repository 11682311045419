import {
Component,
OnInit,
OnDestroy,
OnChanges,
Input,
SimpleChanges,
Output,
EventEmitter,
Inject
} from '@angular/core';

import {
FormGroup,
Validators
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import {
TextBoxModel,
NumberBoxModel,
SelectBoxModel,
ESelectBoxType,
DateBoxModel,
CheckBoxModel,
TextModel,
LabelModel,
ButtonModel,
SplitButtonModel,
SeparatorModel,
ImageModel,
DrawModel,
CodeBoxModel,
ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import * as vkbeautify from 'vkbeautify';
import { BaseComponent } from './components/base.component';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Allocations_ShellService } from './Allocations.shell.service';
import { Allocations_OperationService } from './Allocations.operation.service';
import { Allocations_DatasourceService } from './Allocations.datasource.index';
import { Allocations_FlowService } from './Allocations.flow.index';
import { Allocations_ReportService } from './Allocations.report.index';
import { Allocations_LocalizationService } from './Allocations.localization.service';
import { Allocations_config_content_code_ComponentContextService } from './Allocations.config_content_code.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Allocations.frontend.types'
import { $frontendTypes as $types} from './Allocations.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Allocations-config_content_code',
  templateUrl: './Allocations.config_content_code.component.html'
})
export class Allocations_config_content_codeComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges
{

inParams: { payload: any, config_id: number } = { payload: null, config_id: null };
//#region Inputs
@Input('payload') set $inParams_payload(v: any) {
  this.inParams.payload = v;
}
get $inParams_payload(): any {
  return this.inParams.payload;
}
@Input('config_id') set $inParams_config_id(v: number) {
  this.inParams.config_id = v;
}
get $inParams_config_id(): number {
  return this.inParams.config_id;
}
//#endregion Inputs

//#region Outputs
@Output()
$finish = new EventEmitter();
//#endregion

//#region title
// Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
// The title is often meant to be shown from the parent (shell breadcrumb for example)
// and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
// the parent has already been checked and the child now change something on the parent 
// in dev, CD is run twice
$titleChange = new EventEmitter<string>(true);
private $_title: string;
get title(): string {
  return this.$_title;
}
set title(t: string) {
  this.$_title = t;
  this.$titleChange.emit(this.$_title);
}
//#endregion title
codeMirrorOptions = {
theme: 'base16-light',
mode: 'application/json',
lineNumbers: true,
lineWrapping: true,
foldGutter: true,
gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
autoCloseBrackets: true,
matchBrackets: true,
lint: true
};

mode = 'application/json';

value: string;

//#region Variables
//#endregion
//#region Events

//#endregion

toolbar = {
  save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, false, false, 'Save', 'icon-ic_fluent_save_20_regular', null)
, false),
  export_to_file: new ToolModel(new ButtonModel('export_to_file', new ButtonStyles(['secondary'], null), false, false, false, 'Export', 'icon-ic_fluent_arrow_export_20_regular', null)
, false)
};

constructor(
private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Allocations_ShellService,
private $datasources: Allocations_DatasourceService,
private $flows: Allocations_FlowService,
private $reports: Allocations_ReportService,
private $localization: Allocations_LocalizationService,
private $operations: Allocations_OperationService,
private $logger: CleanupLoggerService,
private $context: Allocations_config_content_code_ComponentContextService,
) {
  super();
}

ngOnInit(): void {
  this.$checkRequiredInParams();
  if (!this.$hasMissingRequiredInParams) {
    this.$init();
  } else {
    this.$initEmpty();
  }
}

private $isFirstNgOnChanges = true;
ngOnChanges(changes: SimpleChanges): void {
  if (this.$isFirstNgOnChanges) {
    this.$isFirstNgOnChanges = false;
  } else {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
}

private $unsubscribe$ = new Subject();
ngOnDestroy(): void {
  this.$unsubscribe$.next(null);
  this.$unsubscribe$.complete();
}
$missingRequiredInParams = [];
get $hasMissingRequiredInParams(): boolean {
  return !!this.$missingRequiredInParams.length;
}

$checkRequiredInParams() {
  this.$missingRequiredInParams = [];
    if(isNil(this.inParams.payload)) {
      this.$missingRequiredInParams.push('payload');
    }
    if(isNil(this.inParams.config_id)) {
      this.$missingRequiredInParams.push('config_id');
    }
}

initialized = false;

async $init() {
this.title = 'config_content_code';

const $codeEditor = this;
const $utils = this.$utils;

this.value = $codeEditor.inParams.payload;

await this.on_init();

this.initialized = true;
}

onCodeMirrorLoaded(editor: CodemirrorComponent){
  //temporary fix to reset the left margin on the gutters. on load an extra left space is being added to the CodeMirror-gutters div element, which causes it to overlap the code content. this refresh re-calculates the space needed and removes the gap. a better implementation could be possible.
  setTimeout(() =>{editor.codeMirror.refresh() }, 300);
}

close() {
this.$finish.emit();
}

beautify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xml(this.value) : vkbeautify.json(this.value);
}

minify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xmlmin(this.value, [, true]) : vkbeautify.jsonmin(this.value);
}

refresh(
skipParent = false,
skipChildren = false,
childToSkip: string = null) {
}

//#region private flows
on_beautify_clicked(event = null) {
  return this.on_beautify_clickedInternal(
    this,
this.$shell,
    this.$datasources,
    this.$flows,
    this.$reports,
    this.$settings,
    this.$operations,
    this.$utils,
    this.$context,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_beautify_clickedInternal(
  $codeEditor: Allocations_config_content_codeComponent,

  $shell: Allocations_ShellService,
  $datasources: Allocations_DatasourceService,
  $flows: Allocations_FlowService,
  $reports: Allocations_ReportService,
  $settings: SettingsValuesService,
  $operations: Allocations_OperationService,
  $utils: UtilsService,
  $context: Allocations_config_content_code_ComponentContextService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Allocations_LocalizationService,
  $event: any
) {
  this.$logger.log('Allocations', 'config_content_code.on_beautify_clicked');
$codeEditor.beautify();
}
on_init(event = null) {
  return this.on_initInternal(
    this,
this.$shell,
    this.$datasources,
    this.$flows,
    this.$reports,
    this.$settings,
    this.$operations,
    this.$utils,
    this.$context,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_initInternal(
  $codeEditor: Allocations_config_content_codeComponent,

  $shell: Allocations_ShellService,
  $datasources: Allocations_DatasourceService,
  $flows: Allocations_FlowService,
  $reports: Allocations_ReportService,
  $settings: SettingsValuesService,
  $operations: Allocations_OperationService,
  $utils: UtilsService,
  $context: Allocations_config_content_code_ComponentContextService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Allocations_LocalizationService,
  $event: any
) {
  this.$logger.log('Allocations', 'config_content_code.on_init');
$codeEditor.beautify();

$codeEditor.title = `Configuration ${$codeEditor.inParams.config_id}`;

}
on_save(event = null) {
  return this.on_saveInternal(
    this,
this.$shell,
    this.$datasources,
    this.$flows,
    this.$reports,
    this.$settings,
    this.$operations,
    this.$utils,
    this.$context,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_saveInternal(
  $codeEditor: Allocations_config_content_codeComponent,

  $shell: Allocations_ShellService,
  $datasources: Allocations_DatasourceService,
  $flows: Allocations_FlowService,
  $reports: Allocations_ReportService,
  $settings: SettingsValuesService,
  $operations: Allocations_OperationService,
  $utils: UtilsService,
  $context: Allocations_config_content_code_ComponentContextService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Allocations_LocalizationService,
  $event: any
) {
  this.$logger.log('Allocations', 'config_content_code.on_save');
await $flows.Allocations.update_allocation_configuration_flow({
    config_id: $codeEditor.inParams.config_id,
    content: $codeEditor.value as any
});

// @ts-ignore
$codeEditor.close();
}
on_export_to_file(event = null) {
  return this.on_export_to_fileInternal(
    this,
this.$shell,
    this.$datasources,
    this.$flows,
    this.$reports,
    this.$settings,
    this.$operations,
    this.$utils,
    this.$context,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_export_to_fileInternal(
  $codeEditor: Allocations_config_content_codeComponent,

  $shell: Allocations_ShellService,
  $datasources: Allocations_DatasourceService,
  $flows: Allocations_FlowService,
  $reports: Allocations_ReportService,
  $settings: SettingsValuesService,
  $operations: Allocations_OperationService,
  $utils: UtilsService,
  $context: Allocations_config_content_code_ComponentContextService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Allocations_LocalizationService,
  $event: any
) {
  this.$logger.log('Allocations', 'config_content_code.on_export_to_file');
try {

    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const hour = now.getHours();
    const minute = now.getMinutes();
    const second = now.getSeconds();

    const lookup_array = [`${year}`, (`00${month}`).slice(-2), (`00${day}`).slice(-2), (`00${hour}`).slice(-2), (`00${minute}`).slice(-2), (`00${second}`).slice(-2)];
    const lookup_seed = lookup_array.join("");

    let id = $codeEditor.inParams.config_id;
    let payload = $codeEditor.value;
    let storage = "JSON";

    storage = `${storage.substring(0, 1).toUpperCase()}${storage.substring(1, storage.length)}`

    const payload_blob = new Blob([payload]);

    await $utils.blob.saveFile(payload_blob, {
        fileName: `${storage}_${id.toString().toLocaleUpperCase()}_${lookup_seed}.json`,
        extensions: ['.json']
    });

} catch (error) {
    // Get inner-most error message
    let targetError = error;
    while ($utils.isDefined(targetError?.error)) { targetError = targetError.error }
    if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception ${JSON.stringify(targetError)}` } }
    throw new Error(`[on_export_to_file] Error ${targetError.message}`);
}
}
//#endregion private flows
}