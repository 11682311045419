<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-type" *ngIf="!fields.type.hidden && !fields.type.removed" 
                            class="field-container double {{fields.type.invalid ? 'invalid' : ''}} {{fields.type.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.type.styles.style"
                            [ngClass]="fields.type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.type.label + (fields.type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.type.label}}<span *ngIf="fields.type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <InventoryTransformations-inventory_transformation_types_dd_single 
                            data-cy="selector"
                            [type]="fields.type.control.type"
                            formControlName="type"
                            (displayTextChange)="fields.type.control.displayText=$event"
                            [placeholder]="fields.type.control.placeholder"
                            [styles]="fields.type.control.styles"
                            [tooltip]="fields.type.control.tooltip"
                        >
                        </InventoryTransformations-inventory_transformation_types_dd_single>
                        <ng-container *ngIf="fields.type.invalid">
                          <ng-container *ngFor="let error of fields.type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden && !fields.material.removed" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}} {{fields.material.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.material.label + (fields.material.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.material.label}}<span *ngIf="fields.material.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-materials_by_project_dd_single 
                            data-cy="selector"
                            [type]="fields.material.control.type"
                            formControlName="material"
                            (displayTextChange)="fields.material.control.displayText=$event"
                            [placeholder]="fields.material.control.placeholder"
                            [styles]="fields.material.control.styles"
                            [tooltip]="fields.material.control.tooltip"
                          [projectId]="$fields_material_selector_inParams_projectId"
                        >
                        </Materials-materials_by_project_dd_single>
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden && !fields.packaging.removed" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}} {{fields.packaging.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.packaging.label + (fields.packaging.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.packaging.label}}<span *ngIf="fields.packaging.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.packaging.control.type"
                            formControlName="packaging"
                            (displayTextChange)="fields.packaging.control.displayText=$event"
                            [placeholder]="fields.packaging.control.placeholder"
                            [styles]="fields.packaging.control.styles"
                            [tooltip]="fields.packaging.control.tooltip"
                          [materialId]="$fields_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden && !fields.lot.removed" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}} {{fields.lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lot.label + (fields.lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lot.label}}<span *ngIf="fields.lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Lots-lots_dd_single 
                            data-cy="selector"
                            [type]="fields.lot.control.type"
                            formControlName="lot"
                            (displayTextChange)="fields.lot.control.displayText=$event"
                            [placeholder]="fields.lot.control.placeholder"
                            [styles]="fields.lot.control.styles"
                            [tooltip]="fields.lot.control.tooltip"
                          [materialId]="$fields_lot_selector_inParams_materialId"
                          [vendorLotId]="$fields_lot_selector_inParams_vendorLotId"
                        >
                        </Lots-lots_dd_single>
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_lot" *ngIf="!fields.vendor_lot.hidden && !fields.vendor_lot.removed" 
                            class="field-container standard {{fields.vendor_lot.invalid ? 'invalid' : ''}} {{fields.vendor_lot.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.vendor_lot.styles.style"
                            [ngClass]="fields.vendor_lot.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.vendor_lot.label + (fields.vendor_lot.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.vendor_lot.label}}<span *ngIf="fields.vendor_lot.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Lots-vendorlots_dd_single 
                            data-cy="selector"
                            [type]="fields.vendor_lot.control.type"
                            formControlName="vendor_lot"
                            (displayTextChange)="fields.vendor_lot.control.displayText=$event"
                            [placeholder]="fields.vendor_lot.control.placeholder"
                            [styles]="fields.vendor_lot.control.styles"
                            [tooltip]="fields.vendor_lot.control.tooltip"
                          [materialId]="$fields_vendor_lot_selector_inParams_materialId"
                          [lotId]="$fields_vendor_lot_selector_inParams_lotId"
                        >
                        </Lots-vendorlots_dd_single>
                        <ng-container *ngIf="fields.vendor_lot.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight" *ngIf="!fields.weight.hidden && !fields.weight.removed" 
                            class="field-container standard {{fields.weight.invalid ? 'invalid' : ''}} {{fields.weight.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.weight.styles.style"
                            [ngClass]="fields.weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weight.label + (fields.weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weight.label}}<span *ngIf="fields.weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="weight"
                                matInput
                                numberBox
                                [format]="fields.weight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.weight.control.readOnly || fields.weight.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.weight.control.placeholder}}"
                                [ngStyle]="fields.weight.control.styles.style"
                                [ngClass]="fields.weight.control.styles.classes"
                                [matTooltip]="fields.weight.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.weight.invalid">
                          <ng-container *ngFor="let error of fields.weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden && !fields.weight_uom.removed" 
                            class="field-container standard {{fields.weight_uom.invalid ? 'invalid' : ''}} {{fields.weight_uom.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weight_uom.label + (fields.weight_uom.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Materials-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                            [tooltip]="fields.weight_uom.control.tooltip"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </Materials-measurement_units_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.inventory.hasTabToShow" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
          </div>
        
              <ng-container
                *ngIf="tabs.inventory.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.inventory.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.inventory.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.inventory.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootprintManager-inventory_transformation_source_lines_selector_by_licenseplate_grid
                   *ngIf="tabs.inventory.tabs.by_licenseplate.active"
                  #$tabs_inventory_by_licenseplate
                  [material_id]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_material_id"
                  [lot_id]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_lot_id"
                  [vendor_lot_id]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_vendor_lot_id"
                  [warehouse_id]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_warehouse_id"
                  [project_id]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_project_id"
                  [package_id]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_package_id"
                  [inventory_transformation_id]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_inventory_transformation_id"
                  [type]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_type"
                  [source_line_id]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_source_line_id"
                  [gross_weight]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_gross_weight"
                  [weight_uom]="$tabs_inventory_by_licenseplate_inventory_transformation_source_lines_selector_by_licenseplate_grid_inParams_weight_uom"
                  (outParamsChange)="on_grid_output_change($event)"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_by_licenseplate')">
                  </FootprintManager-inventory_transformation_source_lines_selector_by_licenseplate_grid>
                  <FootprintManager-inventory_transformation_source_lines_selector_by_lot_grid
                   *ngIf="tabs.inventory.tabs.by_lot.active"
                  #$tabs_inventory_by_lot
                  [material_id]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_material_id"
                  [lot_id]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_lot_id"
                  [vendor_lot_id]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_vendor_lot_id"
                  [warehouse_id]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_warehouse_id"
                  [project_id]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_project_id"
                  [package_id]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_package_id"
                  [inventory_transformation_id]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_inventory_transformation_id"
                  [type]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_type"
                  [source_line_id]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_source_line_id"
                  [gross_weight]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_gross_weight"
                  [weight_uom]="$tabs_inventory_by_lot_inventory_transformation_source_lines_selector_by_lot_grid_inParams_weight_uom"
                  (outParamsChange)="on_grid_output_change($event)"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_by_lot')">
                  </FootprintManager-inventory_transformation_source_lines_selector_by_lot_grid>
                  <FootprintManager-inventory_transformation_source_lines_selector_by_location_grid
                   *ngIf="tabs.inventory.tabs.by_location.active"
                  #$tabs_inventory_by_location
                  [material_id]="$tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_material_id"
                  [lot_id]="$tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_lot_id"
                  [vendor_lot_id]="$tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_vendor_lot_id"
                  [warehouse_id]="$tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_warehouse_id"
                  [project_id]="$tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_project_id"
                  [package_id]="$tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_package_id"
                  [inventory_transformation_id]="$tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_inventory_transformation_id"
                  [type]="$tabs_inventory_by_location_inventory_transformation_source_lines_selector_by_location_grid_inParams_type"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_by_location')">
                  </FootprintManager-inventory_transformation_source_lines_selector_by_location_grid>
                  <FootprintManager-inventory_transformation_source_lines_selector_by_serialnumbers_grid
                   *ngIf="tabs.inventory.tabs.by_serialnumber.active"
                  #$tabs_inventory_by_serialnumber
                  [material_id]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_material_id"
                  [lot_id]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_lot_id"
                  [vendor_lot_id]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_vendor_lot_id"
                  [warehouse_id]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_warehouse_id"
                  [project_id]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_project_id"
                  [package_id]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_package_id"
                  [inventory_transformation_id]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_inventory_transformation_id"
                  [type]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_type"
                  [source_line_id]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_source_line_id"
                  [gross_weight]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_gross_weight"
                  [weight_uom]="$tabs_inventory_by_serialnumber_inventory_transformation_source_lines_selector_by_serialnumbers_grid_inParams_weight_uom"
                  (outParamsChange)="on_grid_output_change($event)"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_by_serialnumber')">
                  </FootprintManager-inventory_transformation_source_lines_selector_by_serialnumbers_grid>
                  <FootprintManager-inventory_transformation_source_lines_selector_by_material_grid
                   *ngIf="tabs.inventory.tabs.by_material.active"
                  #$tabs_inventory_by_material
                  [material_id]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_material_id"
                  [warehouse_id]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_warehouse_id"
                  [project_id]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_project_id"
                  [package_id]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_package_id"
                  [inventory_transformation_id]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_inventory_transformation_id"
                  [type]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_type"
                  [source_line_id]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_source_line_id"
                  [weight]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_weight"
                  [weight_uom]="$tabs_inventory_by_material_inventory_transformation_source_lines_selector_by_material_grid_inParams_weight_uom"
                  (outParamsChange)="on_grid_output_change($event)"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_by_material')">
                  </FootprintManager-inventory_transformation_source_lines_selector_by_material_grid>
              </ng-container>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>