import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Replenishments_replenishments_library_homeComponent } from './Replenishments.replenishments_library_home.component';
import { Replenishments_replenishment_comparison_dd_singleComponent } from './Replenishments.replenishment_comparison_dd_single.component';
import { Replenishments_replenishment_comparison_dd_multiComponent } from './Replenishments.replenishment_comparison_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Replenishments_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Replenishments: Replenishments_ShellService = this;

  public openreplenishments_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('Replenishments', 'replenishments_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Replenishments_replenishments_library_home',
        component: Replenishments_replenishments_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreplenishments_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Replenishments', 'replenishments_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Replenishments_replenishments_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Replenishments_replenishments_library_home') {
      this.$logger.log('Replenishments', 'replenishments_library_home');
      const title = 'Home';
      const component = Replenishments_replenishments_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Replenishments_replenishment_comparison_dd_single') {
      const title = 'Replenishment Comparisons';
      const component = Replenishments_replenishment_comparison_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Replenishments_replenishment_comparison_dd_multi') {
      const title = 'Replenishment Comparisons';
      const component = Replenishments_replenishment_comparison_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
