import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_available_inventory_grid_ComponentContextService } from './FootprintManager.available_inventory_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_available_inventory_gridComponentEntity {
  LocationId?: number, LotId?: number, PackagedId?: number, ActiveAmount?: number, ActivePackagedAmount?: number, AllocatedAmount?: number, AllocatedPackagedAmount?: number, AvailableAmount?: number, AvailablePackagedAmount?: number, CurrentlyAvailableAmount?: number, CurrentlyAvailablePackagedAmount?: number, ExpirationDate?: string, InactiveAmount?: number, InactivePackagedAmount?: number, IncomingAmount?: number, IncomingPackagedAmount?: number, MaterialId?: number, SoftAllocatedAmount?: number, SoftAllocatedPackagedAmount?: number, SoftIncomingAmount?: number, SoftIncomingPackagedAmount?: number, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, Location?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string }, Packaging?: { Id?: number, Name?: string, ShortName?: string }}

interface IFootprintManager_available_inventory_gridComponentInParams {
  materialId: number, warehouseId: number, recommendedAmount?: number, taskId?: number, lotId?: number, vendorLotId?: number, allowInactive?: boolean}


class FootprintManager_available_inventory_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_available_inventory_gridComponent;
  entity: IFootprintManager_available_inventory_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    allocate_quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    AllocatedAmount: null,
    AvailableAmount: null,
    InactiveAmount: null,
    Packaging_Name: null,
    Lot_LookupCode: null,
    VendorLot_ExpirationDate: null,
    VendorLot_ManufactureDate: null,
    Location_Name: null,
    Warehouse_Name: null,
    allocate_quantity: null,
  }



  constructor(
    grid: FootprintManager_available_inventory_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_available_inventory_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.AllocatedAmount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.AllocatedAmount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.AvailableAmount = new GridCellModel(
      new CellStyles(['creation'], null),
      this.grid.headers.AvailableAmount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.InactiveAmount = new GridCellModel(
      new CellStyles(['attention'], null),
      this.grid.headers.InactiveAmount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Packaging_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Packaging_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Lot_LookupCode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Lot_LookupCode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.VendorLot_ExpirationDate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.VendorLot_ExpirationDate,
      new TextModel(null, null,  'datetime', 'MM/DD/YYYY', null)
,
null
      );
    
    this.cells.VendorLot_ManufactureDate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.VendorLot_ManufactureDate,
      new TextModel(null, null,  'datetime', 'MM/DD/YYYY', null)
,
null
      );
    
    this.cells.Location_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Location_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Warehouse_Name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Warehouse_Name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.allocate_quantity = new GridCellModel(
      new CellStyles(['attention'], null),
      this.grid.headers.allocate_quantity,
      new TextModel(null, null,  'number', '0.00', null)
,
      new NumberBoxModel(this.formGroup.controls['allocate_quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_available_inventory_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_available_inventory_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.LocationId,this.entity.LotId,this.entity.PackagedId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'LocationId',this.entity.LocationId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      materialId:  $grid.inParams.materialId ,
      warehouseId:  $grid.inParams.warehouseId ,
      lotId:  $grid.inParams.lotId ,
      vendorLotId:  $grid.inParams.vendorLotId ,
      allowInactive:  $grid.inParams.allowInactive ,
    };
    const data = await this.$datasources.Inventory.ds_get_available_inventory_by_materialId_lotId_vendorlotId.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_available_inventory_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.AllocatedAmount.displayControl as TextModel).text = $row.entity.AllocatedAmount?.toString();
    (this.cells.AvailableAmount.displayControl as TextModel).text = (($grid.inParams.allowInactive ?? false) ? $row.entity.ActivePackagedAmount + $row.entity.InactivePackagedAmount - $row.entity.AllocatedPackagedAmount : $row.entity.AvailablePackagedAmount).toString();
    (this.cells.InactiveAmount.displayControl as TextModel).text = $row.entity.InactivePackagedAmount?.toString();
    (this.cells.Packaging_Name.displayControl as TextModel).text = $row.entity.Packaging?.Name;
    (this.cells.Lot_LookupCode.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.VendorLot_ExpirationDate.displayControl as TextModel).text = $row.entity.VendorLot?.ExpirationDate?.toString();
    (this.cells.VendorLot_ManufactureDate.displayControl as TextModel).text = $row.entity.VendorLot?.ManufactureDate?.toString();
    (this.cells.Location_Name.displayControl as TextModel).text = $row.entity.Location?.Name;
    (this.cells.Warehouse_Name.displayControl as TextModel).text = $row.entity.Warehouse?.Name;

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_row_confirmation();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_confirmation(event = null) {
    return this.on_row_confirmationInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_confirmationInternal(
    $row: FootprintManager_available_inventory_gridComponentRowModel,
  $grid: FootprintManager_available_inventory_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_available_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let quantity = 0
  if (!$row.cells.allocate_quantity?.editControl.isChanged) { return; }
  
  $row.cells.allocate_quantity.displayControl.text = $row.cells.allocate_quantity?.editControl.value;
  if ($row.cells.allocate_quantity.displayControl.text != '') {
      quantity = $row.cells.allocate_quantity?.editControl.value;
  }
  
  if (quantity <= 0) { return; }
  
  const currentPackagedAmount = $grid.inParams.recommendedAmount;
  const taskId = $grid.inParams.taskId;
  const newLotId = $row.entity.LotId;
  const newLocationId = $row.entity.Location.Id;
  let updatedPackagedAmount = +$row.cells.allocate_quantity.displayControl.text;
  const vendorLotId = $row.entity.VendorLot.Id;
  let allocate: boolean = false;
  let availableAmount = +$row.cells.AvailableAmount.displayControl.text;
  
  // Location eligible?
  
  if (updatedPackagedAmount == 0) {
      updatedPackagedAmount = $grid.inParams.recommendedAmount;
  }
  
  try {
      if (!$utils.isDefined(updatedPackagedAmount)) { return; }
  
      // Check quantities
      if (updatedPackagedAmount > $grid.inParams.recommendedAmount) {
          await $shell.FootprintManager.openErrorDialog('Task cannot be reallocated.', `The requested amount exceeds the required amount`);
          return;
      }
  
      if (availableAmount < updatedPackagedAmount) {
          await $shell.FootprintManager.openErrorDialog('Task cannot be reallocated.', `There is not enough available inventory to meet the required amount`);
          return;
      }
  
      // Try to allocate
      const dialogResult = await $shell.FootprintManager.openConfirmationDialog('Manually allocate', `Allocate quantity of ${updatedPackagedAmount}`, 'Confirm', 'Cancel')
      if (!dialogResult) { return; }
  
      const result = await $flows.SalesOrders.complete_manual_allocation_flow({
          taskId: taskId,
          currentPackagedAmount: currentPackagedAmount,
          updatedPackagedAmount: updatedPackagedAmount,
          lotId: newLotId,
          locationId: newLocationId,
          vendorLotId: vendorLotId
      });
  
      if ($utils.isDefined(result.reason)) {
          await $shell.FootprintManager.openErrorDialog('Task cannot be reallocated.', result.reason);
          return;
      }
  
      let title = `Task allocated to location ${$row.entity.Location.Name}`;
      $shell.FootprintManager.openToaster(title, '', EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
  
      // Refresh the entire grid as the manual allocation parent task will go to completed status, otherwise run a row refresh
      if (currentPackagedAmount == updatedPackagedAmount) {
          await $grid.refresh();
      }
      else {
          await $row.refresh();
      }
  }
  catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
      const errorDescription = `${errorMessage}`;
      await $shell.FootprintManager.openErrorDialog('Task reallocation error', 'An error occurred during the reallocation of the task', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-available_inventory_grid',
  templateUrl: './FootprintManager.available_inventory_grid.component.html'
})
export class FootprintManager_available_inventory_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_available_inventory_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);


  inParams: IFootprintManager_available_inventory_gridComponentInParams = { materialId: null, warehouseId: null, recommendedAmount: null, taskId: null, lotId: null, vendorLotId: null, allowInactive: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     AllocatedAmount: new GridHeaderModel(new HeaderStyles(null, null), 'AllocatedAmount', 'Amount allocated', false, false, null, false),       AvailableAmount: new GridHeaderModel(new HeaderStyles(null, null), 'AvailableAmount', 'Amount available', false, false, null, false),       InactiveAmount: new GridHeaderModel(new HeaderStyles(null, null), 'InactiveAmount', 'Amount inactive', false, false, null, false),       Packaging_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging_Name', 'Packaging', false, false, null, false),       Lot_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Lot_LookupCode', 'Lot', false, false, null, false),       VendorLot_ExpirationDate: new GridHeaderModel(new HeaderStyles(null, null), 'VendorLot_ExpirationDate', 'Expiration date', false, false, null, false),       VendorLot_ManufactureDate: new GridHeaderModel(new HeaderStyles(null, null), 'VendorLot_ManufactureDate', 'Manufacture date', false, false, null, false),       Location_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Location_Name', 'Location', false, false, null, false),       Warehouse_Name: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse_Name', 'Warehouse', false, false, null, false),       allocate_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'allocate_quantity', 'Quantity', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_available_inventory_gridComponentRowModel[] = [];

  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('recommendedAmount') set $inParams_recommendedAmount(value: any) {
    this.inParams['recommendedAmount'] = value;
  }
  get $inParams_recommendedAmount(): any {
    return this.inParams['recommendedAmount'] ;
  }
  @Input('taskId') set $inParams_taskId(value: any) {
    this.inParams['taskId'] = value;
  }
  get $inParams_taskId(): any {
    return this.inParams['taskId'] ;
  }
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }
  @Input('vendorLotId') set $inParams_vendorLotId(value: any) {
    this.inParams['vendorLotId'] = value;
  }
  get $inParams_vendorLotId(): any {
    return this.inParams['vendorLotId'] ;
  }
  @Input('allowInactive') set $inParams_allowInactive(value: any) {
    this.inParams['allowInactive'] = value;
  }
  get $inParams_allowInactive(): any {
    return this.inParams['allowInactive'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_available_inventory_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Available inventory';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 50;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      materialId:  $grid.inParams.materialId ,
      warehouseId:  $grid.inParams.warehouseId ,
      lotId:  $grid.inParams.lotId ,
      vendorLotId:  $grid.inParams.vendorLotId ,
      allowInactive:  $grid.inParams.allowInactive ,
    };
    try {
      const data = await this.$datasources.Inventory.ds_get_available_inventory_by_materialId_lotId_vendorlotId.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_available_inventory_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_available_inventory_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_load();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_load(event = null) {
    return this.on_grid_data_loadInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadInternal(
    $grid: FootprintManager_available_inventory_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_available_inventory_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  for (let row of $grid.rows) {
      if (row.entity.AvailablePackagedAmount == 0) {
          row.cells.AvailableAmount.styles.clear();
      } else if (row.entity.InactivePackagedAmount == 0) {
          row.cells.InactiveAmount.styles.clear();
      } 
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
