import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Ultraship_ShellService } from './Ultraship.shell.service';
import { Ultraship_OperationService } from './Ultraship.operation.service';
import { Ultraship_DatasourceService } from './Ultraship.datasource.index';
import { Ultraship_FlowService } from './Ultraship.flow.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Ultraship_LocalizationService } from './Ultraship.localization.service';
import { Ultraship_ultraship_configuration_hub_ComponentContextService } from './Ultraship.ultraship_configuration_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Ultraship.frontend.types'
import { $frontendTypes as $types} from './Ultraship.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Ultraship_ultraship_project_configurations_gridComponent } from './Ultraship.ultraship_project_configurations_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Ultraship_ultraship_project_configurations_gridComponent),
  ],
  selector: 'Ultraship-ultraship_configuration_hub',
  templateUrl: './Ultraship.ultraship_configuration_hub.component.html'
})
export class Ultraship_ultraship_configuration_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { application_name?: string, column_names?: any, filter_values?: any, user_name?: string } = { application_name: null, column_names: null, filter_values: null, user_name: null };
  //#region Inputs
  @Input('application_name') set $inParams_application_name(v: string) {
    this.inParams.application_name = v;
  }
  get $inParams_application_name(): string {
    return this.inParams.application_name;
  }
  @Input('column_names') set $inParams_column_names(v: any) {
    this.inParams.column_names = v;
  }
  get $inParams_column_names(): any {
    return this.inParams.column_names;
  }
  @Input('filter_values') set $inParams_filter_values(v: any) {
    this.inParams.filter_values = v;
  }
  get $inParams_filter_values(): any {
    return this.inParams.filter_values;
  }
  @Input('user_name') set $inParams_user_name(v: string) {
    this.inParams.user_name = v;
  }
  get $inParams_user_name(): string {
    return this.inParams.user_name;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { application_name?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    http_endpoint: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    http_port: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    http_username: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    http_password: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    sftp_endpoint: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    sftp_port: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    sftp_username: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    sftp_password: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    sftp_working_folder: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    sftp_archive_folder: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    sftp_error_folder: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(null, null), false, false, false, 'Save', 'icon-ic_fluent_save_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    http_endpoint: new FieldModel(new TextBoxModel(this.formGroup.controls['http_endpoint'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Endpoint', false, false),
    http_port: new FieldModel(new TextBoxModel(this.formGroup.controls['http_port'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Port', false, false),
    http_username: new FieldModel(new TextBoxModel(this.formGroup.controls['http_username'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Username', false, false),
    http_password: new FieldModel(new TextBoxModel(this.formGroup.controls['http_password'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Password', false, false),
    sftp_endpoint: new FieldModel(new TextBoxModel(this.formGroup.controls['sftp_endpoint'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Endpoint', false, false),
    sftp_port: new FieldModel(new TextBoxModel(this.formGroup.controls['sftp_port'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Port', false, false),
    sftp_username: new FieldModel(new TextBoxModel(this.formGroup.controls['sftp_username'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Username', false, false),
    sftp_password: new FieldModel(new TextBoxModel(this.formGroup.controls['sftp_password'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Password', false, false),
    sftp_working_folder: new FieldModel(new TextBoxModel(this.formGroup.controls['sftp_working_folder'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Working Folder', false, false),
    sftp_archive_folder: new FieldModel(new TextBoxModel(this.formGroup.controls['sftp_archive_folder'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Archive Folder', false, false),
    sftp_error_folder: new FieldModel(new TextBoxModel(this.formGroup.controls['sftp_error_folder'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Error Folder', false, false),
  };


  filtersets = {
    http_configuration: new FieldsetModel(
      'Web Service',
      false,
      true,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    grp_sftp_endpoint: new FieldsetModel(
      'Response via SFTP',
      false,
      true,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      projects_configuration: new TabItemModel(
        this.rootTabGroup, 
        'Projects', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_projects_configuration_ultraship_project_configurations_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_projects_configuration', { read: Ultraship_ultraship_project_configurations_gridComponent }) $tabs_projects_configuration: Ultraship_ultraship_project_configurations_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Ultraship configuration`;
  }


  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: Ultraship_ShellService,
  private $datasources: Ultraship_DatasourceService,
  private $flows: Ultraship_FlowService,
  private $reports: Ultraship_ReportService,
  private $localization: Ultraship_LocalizationService,
  private $operations: Ultraship_OperationService,
  private $logger: CleanupLoggerService,
  private $context: Ultraship_ultraship_configuration_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.projects_configuration,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Ultraship configuration';
    
    const $hub = this;
    const $utils = this.$utils;

    
    
    
    
    
    
    
    
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_projects_configuration') {
      if (!isNil(this.$tabs_projects_configuration) && !this.tabs.projects_configuration.hidden) {
        this.$tabs_projects_configuration.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: Ultraship_ultraship_configuration_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_configuration_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  
  let instanceInfo = await $flows.Ultraship.get_instance_information({})
  let applicationName = instanceInfo.application_name
  let environment = instanceInfo.environment
  
  $hub.vars.application_name = applicationName;
  
  // Check there are configurations for the application
  var applicationConfigs = await $flows.Utilities.configurations_get({
      criteria: {
          application_name: applicationName
      }
  });
  
  if ($utils.isDefined(applicationConfigs)) {
  
  
      // HTTP Endpoint
      const ultraship_http_endpoint = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_http_endpoint,
          $types.Ultraship.ConfigurationType.HTTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_http_endpoint) && ultraship_http_endpoint.length > 0) {
          $hub.filters.http_endpoint.control.value = ultraship_http_endpoint;
      }
  
      // HTTP Port
      const ultraship_http_port = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_http_port,
          $types.Ultraship.ConfigurationType.HTTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_http_port) && ultraship_http_port.length > 0) {
          $hub.filters.http_port.control.value = ultraship_http_port;
      }
  
      // HTTP Username
      const ultraship_http_username = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_http_username,
          $types.Ultraship.ConfigurationType.HTTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_http_username) && ultraship_http_username.length > 0) {
          $hub.filters.http_username.control.value = ultraship_http_username;
      }
  
      // HTTP Password
      const ultraship_http_password = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_http_password,
          $types.Ultraship.ConfigurationType.HTTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_http_password) && ultraship_http_password.length > 0) {
          $hub.filters.http_password.control.value = ultraship_http_password;
      }
  
  
      /** SFTP */
  
      // FTP/SFTP Endpoint
      const ultraship_sftp_endpoint = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_endpoint,
          $types.Ultraship.ConfigurationType.SFTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_sftp_endpoint) && ultraship_sftp_endpoint.length > 0) {
          $hub.filters.sftp_endpoint.control.value = ultraship_sftp_endpoint;
      }
  
  
      // FTP/SFTP Port
      const ultraship_sftp_port = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_port,
          $types.Ultraship.ConfigurationType.SFTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_sftp_port) && ultraship_sftp_port.length > 0) {
          $hub.filters.sftp_port.control.value = ultraship_sftp_port;
      }
  
  
      // FTP/SFTP Username
      const ultraship_sftp_username = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_username,
          $types.Ultraship.ConfigurationType.SFTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_sftp_username) && ultraship_sftp_username.length > 0) {
          $hub.filters.sftp_username.control.value = ultraship_sftp_username;
      }
  
  
      // FTP/SFTP Password
      const ultraship_sftp_password = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_password,
          $types.Ultraship.ConfigurationType.SFTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_sftp_password) && ultraship_sftp_password.length > 0) {
          $hub.filters.sftp_password.control.value = ultraship_sftp_password;
      }
  
  
      // FTP/SFTP Working Folder
      const ultraship_sftp_path_working = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_path_working,
          $types.Ultraship.ConfigurationType.SFTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_sftp_path_working) && ultraship_sftp_path_working.length > 0) {
          $hub.filters.sftp_working_folder.control.value = ultraship_sftp_path_working;
      }
  
      
  
      // FTP/SFTP Archive Folder
      const ultraship_sftp_path_archive = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_path_archive,
          $types.Ultraship.ConfigurationType.SFTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_sftp_path_archive) && ultraship_sftp_path_archive.length > 0) {
          $hub.filters.sftp_archive_folder.control.value = ultraship_sftp_path_archive;
      }
  
  
      // FTP/SFTP Error Folder
      const ultraship_sftp_path_error = await GetConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_path_error,
          $types.Ultraship.ConfigurationType.SFTP,
          applicationConfigs.output
      )
  
      if ($utils.isDefined(ultraship_sftp_path_error) && ultraship_sftp_path_error.length > 0) {
          $hub.filters.sftp_error_folder.control.value = ultraship_sftp_path_error;
      }
  
      // Hide the sftp endpoint and port for now because the connection is via api connection
      $hub.filters.sftp_endpoint.hidden = true;
      $hub.filters.sftp_port.hidden = true;
      $hub.filters.sftp_username.hidden = true;
      $hub.filters.sftp_password.hidden = true;
  
  
  } // End Configurations check
  
  
  /**
   * 
   */
  async function GetConfiguration(
      environment: string,
      configName: $types.Ultraship.Configuration,
      configType: $types.Ultraship.ConfigurationType,
      configurationMatchList: $types.Utilities.Configuration[]
  ) {
  
      const matchResult = configurationMatchList.filter(p =>
          p.string_1 === environment
          && p.string_2 === configType
          && p.name === configName)
  
      if ($utils.isDefined(matchResult) && matchResult.length > 0) {
          return matchResult[0]?.value
      }
      else {
          return ''
      }
  
  }
  }
  on_refresh_dropdowns(event = null) {
    return this.on_refresh_dropdownsInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_dropdownsInternal(
    $hub: Ultraship_ultraship_configuration_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_configuration_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'ultraship_configuration_hub.on_refresh_dropdowns');
  //O.Arias - 06/20/2024
  
  $flows.Utilities.list_applications({ action: "Refresh" });
  $flows.Utilities.list_processes({ action: "Refresh" });
  $flows.Utilities.list_statuses({ action: "Refresh" });
  $flows.Utilities.list_transaction_types({ action: "Refresh" });
  }
  on_save_button_clicked(event = null) {
    return this.on_save_button_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_button_clickedInternal(
    $hub: Ultraship_ultraship_configuration_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_configuration_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  let instanceInfo = await $flows.Ultraship.get_instance_information({})
  let applicationName = instanceInfo.application_name
  let environment = instanceInfo.environment
  
  // Check there are configurations for the application
  var applicationConfigs = await $flows.Utilities.configurations_get({
      criteria: {
          application_name: applicationName
      }
  });
  
  if ($utils.isDefined(applicationConfigs)) {
  
  
      // HTTP Endpoint
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_http_endpoint,
          $types.Ultraship.ConfigurationType.HTTP,
          $hub.filters.http_endpoint.control.value,
          applicationConfigs.output
      )
  
      // HTTP Port
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_http_port,
          $types.Ultraship.ConfigurationType.HTTP,
          $hub.filters.http_port.control.value,
          applicationConfigs.output
      )
  
      // HTTP Username
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_http_username,
          $types.Ultraship.ConfigurationType.HTTP,
          $hub.filters.http_username.control.value,
          applicationConfigs.output
      )
  
      // HTTP Password
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_http_password,
          $types.Ultraship.ConfigurationType.HTTP,
          $hub.filters.http_password.control.value,
          applicationConfigs.output
      )
  
  
      /** SFTP */
  
      // FTP/SFTP Endpoint
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_endpoint,
          $types.Ultraship.ConfigurationType.SFTP,
          $hub.filters.sftp_endpoint.control.value,
          applicationConfigs.output
      )
  
      // FTP/SFTP Port
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_port,
          $types.Ultraship.ConfigurationType.SFTP,
          $hub.filters.sftp_port.control.value,
          applicationConfigs.output
      )
  
      // FTP/SFTP Username
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_username,
          $types.Ultraship.ConfigurationType.SFTP,
          $hub.filters.sftp_username.control.value,
          applicationConfigs.output
      )
  
      // FTP/SFTP Password
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_password,
          $types.Ultraship.ConfigurationType.SFTP,
          $hub.filters.sftp_password.control.value,
          applicationConfigs.output
      )
  
  
      // FTP/SFTP Working Folder
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_path_working,
          $types.Ultraship.ConfigurationType.SFTP,
          $hub.filters.sftp_working_folder.control.value,
          applicationConfigs.output
      )
  
      // FTP/SFTP Archive Folder
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_path_archive,
          $types.Ultraship.ConfigurationType.SFTP,
          $hub.filters.sftp_archive_folder.control.value,
          applicationConfigs.output
      )
  
  
      // FTP/SFTP Error Folder
      await UpdateOrInsertConfiguration(
          environment,
          $types.Ultraship.Configuration.ultraship_sftp_path_error,
          $types.Ultraship.ConfigurationType.SFTP,
          $hub.filters.sftp_error_folder.control.value,
          applicationConfigs.output
      )
  
      $shell.Utilities.openConfirmationDialog("Configuration", `Successfully saved the configuration.`)
  
  
  } // End Configurations check
  
  
  /**
   * 
   */
  async function UpdateOrInsertConfiguration(
      environment: string,
      configName: $types.Ultraship.Configuration,
      configType: $types.Ultraship.ConfigurationType,
      configValue: string,
      configurationMatchList: $types.Utilities.Configuration[]
  ) {
  
      const matchResult = configurationMatchList.filter(p =>
          p.string_1 === environment
          && p.string_2 === configType
          && p.name === configName)
  
      if ($utils.isDefined(matchResult) && matchResult.length > 0) {
          if ($utils.isDefinedTrimmed(configValue)) {
              await $flows.Utilities.configurations_update({
                  input: [{
                      configuration_id: matchResult[0].configuration_id,
                      value: configValue,
                  }],
                  criteria: { application_name: applicationName }
              });
          }
          else {
              await $flows.Utilities.configurations_delete({
                  input: [{
                      configuration_id: matchResult[0].configuration_id
                  }],
                  criteria: { application_name: applicationName }
              });
          }
  
      }
      else {
          if ($utils.isDefinedTrimmed(configValue)) {
              await $flows.Utilities.configurations_write({
                  input: [{
                      application_name: applicationName,
                      name: configName,
                      value: configValue,
                      string_1: environment,
                      string_2: configType,
                  }],
                  criteria: { application_name: applicationName }
              });
          }
      }
  }
  }
  //#endregion private flows
}
