import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Ultraship_ds_get_order_by_lookupCode_referencesService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lookupCode: string, ownerReference?: string, vendorReference?: string }): 
  Promise<{ result: { Id?: number, LookupCode?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, ExpectedWarehouseId?: number, LookupCode?: string } }[] } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lookupCode)) {
      missingRequiredInParams.push('\'lookupCode\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Ultraship/datasources/ds_get_order_by_lookupCode_references/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      lookupCode: string, ownerReference?: string, vendorReference?: string    }) {
        if(isNil(inParams.lookupCode)) {
          return true; 
        }
      return false;
    }
}
