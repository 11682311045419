import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { SerialNumbers_serialnumbers_library_homeComponent } from './SerialNumbers.serialnumbers_library_home.component';
import { SerialNumbers_audit_entities_dd_singleComponent } from './SerialNumbers.audit_entities_dd_single.component';
import { SerialNumbers_measurement_units_dd_singleComponent } from './SerialNumbers.measurement_units_dd_single.component';
import { SerialNumbers_reason_codes_dd_singleComponent } from './SerialNumbers.reason_codes_dd_single.component';
import { SerialNumbers_serialnumber_statuses_dd_singleComponent } from './SerialNumbers.serialnumber_statuses_dd_single.component';
import { SerialNumbers_serialnumbers_lots_dd_singleComponent } from './SerialNumbers.serialnumbers_lots_dd_single.component';
import { SerialNumbers_serialnumbers_packagings_dd_singleComponent } from './SerialNumbers.serialnumbers_packagings_dd_single.component';
import { SerialNumbers_audit_entities_dd_multiComponent } from './SerialNumbers.audit_entities_dd_multi.component';
import { SerialNumbers_measurement_units_dd_multiComponent } from './SerialNumbers.measurement_units_dd_multi.component';
import { SerialNumbers_reason_codes_dd_multiComponent } from './SerialNumbers.reason_codes_dd_multi.component';
import { SerialNumbers_serialnumber_statuses_dd_multiComponent } from './SerialNumbers.serialnumber_statuses_dd_multi.component';
import { SerialNumbers_serialnumbers_lots_dd_multiComponent } from './SerialNumbers.serialnumbers_lots_dd_multi.component';
import { SerialNumbers_serialnumbers_packagings_dd_multiComponent } from './SerialNumbers.serialnumbers_packagings_dd_multi.component';
import { SerialNumbers_serialnumbers_gross_weight_widgetComponent } from './SerialNumbers.serialnumbers_gross_weight_widget.component';
import { SerialNumbers_serialnumbers_total_amount_widgetComponent } from './SerialNumbers.serialnumbers_total_amount_widget.component';
import { SerialNumbers_serialnumbers_total_available_amount_widgetComponent } from './SerialNumbers.serialnumbers_total_available_amount_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public SerialNumbers: SerialNumbers_ShellService = this;

  public openserialnumbers_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('SerialNumbers', 'serialnumbers_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'SerialNumbers_serialnumbers_library_home',
        component: SerialNumbers_serialnumbers_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openserialnumbers_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('SerialNumbers', 'serialnumbers_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      SerialNumbers_serialnumbers_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'SerialNumbers_serialnumbers_library_home') {
      this.$logger.log('SerialNumbers', 'serialnumbers_library_home');
      const title = 'Home';
      const component = SerialNumbers_serialnumbers_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_audit_entities_dd_single') {
      const title = 'audit_entities_dd';
      const component = SerialNumbers_audit_entities_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_measurement_units_dd_single') {
      const title = 'Measurement Units Dropdown';
      const component = SerialNumbers_measurement_units_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_reason_codes_dd_single') {
      const title = 'reason_codes_dd';
      const component = SerialNumbers_reason_codes_dd_singleComponent;
      const inParams:{ parentEntity?: string, parentId?: number } = { parentEntity: null, parentId: null };
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumber_statuses_dd_single') {
      const title = 'Serialnumber statuses Dropdown';
      const component = SerialNumbers_serialnumber_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumbers_lots_dd_single') {
      const title = 'serialnumbers_lots_dd';
      const component = SerialNumbers_serialnumbers_lots_dd_singleComponent;
      const inParams:{ licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, orderId?: number, orderLineNumber?: number } = { licenseplateIds: [], locationId: null, warehouseIds: [], lotId: null, materialIds: [], projectId: null, ownerId: null, archived: null, fullTextSearch: null, textSearch: null, orderId: null, orderLineNumber: null };
      if (!isNil(params.get('licenseplateIds'))) {
        const paramValueString = params.get('licenseplateIds');
        inParams.licenseplateIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('fullTextSearch'))) {
        const paramValueString = params.get('fullTextSearch');
        // TODO: date
        inParams.fullTextSearch = paramValueString;
              }
      if (!isNil(params.get('textSearch'))) {
        const paramValueString = params.get('textSearch');
        // TODO: date
        inParams.textSearch = paramValueString;
              }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumbers_packagings_dd_single') {
      const title = 'serialnumbers_packagings_dd';
      const component = SerialNumbers_serialnumbers_packagings_dd_singleComponent;
      const inParams:{ licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, orderId?: number, orderLineNumber?: number } = { licenseplateIds: [], locationId: null, warehouseIds: [], lotId: null, materialIds: [], projectId: null, ownerId: null, archived: null, fullTextSearch: null, textSearch: null, orderId: null, orderLineNumber: null };
      if (!isNil(params.get('licenseplateIds'))) {
        const paramValueString = params.get('licenseplateIds');
        inParams.licenseplateIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('fullTextSearch'))) {
        const paramValueString = params.get('fullTextSearch');
        // TODO: date
        inParams.fullTextSearch = paramValueString;
              }
      if (!isNil(params.get('textSearch'))) {
        const paramValueString = params.get('textSearch');
        // TODO: date
        inParams.textSearch = paramValueString;
              }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_audit_entities_dd_multi') {
      const title = 'audit_entities_dd';
      const component = SerialNumbers_audit_entities_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_measurement_units_dd_multi') {
      const title = 'Measurement Units Dropdown';
      const component = SerialNumbers_measurement_units_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_reason_codes_dd_multi') {
      const title = 'reason_codes_dd';
      const component = SerialNumbers_reason_codes_dd_multiComponent;
      const inParams:{ parentEntity?: string, parentId?: number } = { parentEntity: null, parentId: null };
      if (!isNil(params.get('parentEntity'))) {
        const paramValueString = params.get('parentEntity');
        // TODO: date
        inParams.parentEntity = paramValueString;
              }
      if (!isNil(params.get('parentId'))) {
        const paramValueString = params.get('parentId');
        inParams.parentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumber_statuses_dd_multi') {
      const title = 'Serialnumber statuses Dropdown';
      const component = SerialNumbers_serialnumber_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumbers_lots_dd_multi') {
      const title = 'serialnumbers_lots_dd';
      const component = SerialNumbers_serialnumbers_lots_dd_multiComponent;
      const inParams:{ licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, orderId?: number, orderLineNumber?: number } = { licenseplateIds: [], locationId: null, warehouseIds: [], lotId: null, materialIds: [], projectId: null, ownerId: null, archived: null, fullTextSearch: null, textSearch: null, orderId: null, orderLineNumber: null };
      if (!isNil(params.get('licenseplateIds'))) {
        const paramValueString = params.get('licenseplateIds');
        inParams.licenseplateIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('fullTextSearch'))) {
        const paramValueString = params.get('fullTextSearch');
        // TODO: date
        inParams.fullTextSearch = paramValueString;
              }
      if (!isNil(params.get('textSearch'))) {
        const paramValueString = params.get('textSearch');
        // TODO: date
        inParams.textSearch = paramValueString;
              }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumbers_packagings_dd_multi') {
      const title = 'serialnumbers_packagings_dd';
      const component = SerialNumbers_serialnumbers_packagings_dd_multiComponent;
      const inParams:{ licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, orderId?: number, orderLineNumber?: number } = { licenseplateIds: [], locationId: null, warehouseIds: [], lotId: null, materialIds: [], projectId: null, ownerId: null, archived: null, fullTextSearch: null, textSearch: null, orderId: null, orderLineNumber: null };
      if (!isNil(params.get('licenseplateIds'))) {
        const paramValueString = params.get('licenseplateIds');
        inParams.licenseplateIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('fullTextSearch'))) {
        const paramValueString = params.get('fullTextSearch');
        // TODO: date
        inParams.fullTextSearch = paramValueString;
              }
      if (!isNil(params.get('textSearch'))) {
        const paramValueString = params.get('textSearch');
        // TODO: date
        inParams.textSearch = paramValueString;
              }
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderLineNumber'))) {
        const paramValueString = params.get('orderLineNumber');
        inParams.orderLineNumber = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumbers_gross_weight_widget') {
      this.$logger.log('SerialNumbers', 'serialnumbers_gross_weight_widget');
      const title = 'Gross weight';
      const component = SerialNumbers_serialnumbers_gross_weight_widgetComponent;
      const inParams:{ warehouseIds?: number[], ownerId?: number, projectId?: number, lotId?: number, materialsIds?: number[], locationId?: number } = { warehouseIds: [], ownerId: null, projectId: null, lotId: null, materialsIds: [], locationId: null };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialsIds'))) {
        const paramValueString = params.get('materialsIds');
        inParams.materialsIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumbers_total_amount_widget') {
      const title = 'Total amount';
      const component = SerialNumbers_serialnumbers_total_amount_widgetComponent;
      const inParams:{ warehouseId?: number[], ownerId?: number, projectId?: number, lotId?: number, materialIds?: number[], locationId?: number, materialId?: number } = { warehouseId: [], ownerId: null, projectId: null, lotId: null, materialIds: [], locationId: null, materialId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SerialNumbers_serialnumbers_total_available_amount_widget') {
      const title = 'Total available';
      const component = SerialNumbers_serialnumbers_total_available_amount_widgetComponent;
      const inParams:{ warehouseIds?: number[], ownerId?: number, projectId?: number, lotId?: number, materialIds?: number[], locationId?: number, materialId?: number } = { warehouseIds: [], ownerId: null, projectId: null, lotId: null, materialIds: [], locationId: null, materialId: null };
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
