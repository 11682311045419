<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized && !$hasMissingRequiredInParams">



      <mat-card-title-group class="card-header">
        <div class="card-title-container">
          <ng-content></ng-content>
          <mat-card-title data-cy="card-title" class="card-title card-title-link" (click)="on_lookupcode_clicked()">{{headerTitle}}</mat-card-title>
        </div>

      </mat-card-title-group>


      <mat-card-content data-cy="card-content" class="card-content" >
        <div [formGroup]="formGroupContent"
             class="formdata">
                <div data-cy="fieldset-formGroupContent-id-newGroup1"
                     *ngIf="!content.fieldsets.newGroup1.hidden && !content.fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{content.fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-location" *ngIf="!content.fields.location.hidden && !content.fields.location.removed" 
                            class="field-container standard {{content.fields.location.invalid ? 'invalid' : ''}} {{content.fields.location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="content.fields.location.styles.style"
                            [ngClass]="content.fields.location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.location.label + (content.fields.location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.location.label}}<span *ngIf="content.fields.location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.location.control.styles.style"
                              [ngClass]="content.fields.location.control.styles.classes"
                              [matTooltip]="content.fields.location.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.location.control.text }}</div>
                        <ng-container *ngIf="content.fields.location.invalid">
                          <ng-container *ngFor="let error of content.fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-total_packaged_amount" *ngIf="!content.fields.total_packaged_amount.hidden && !content.fields.total_packaged_amount.removed" 
                            class="field-container standard {{content.fields.total_packaged_amount.invalid ? 'invalid' : ''}} {{content.fields.total_packaged_amount.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="content.fields.total_packaged_amount.styles.style"
                            [ngClass]="content.fields.total_packaged_amount.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.total_packaged_amount.label + (content.fields.total_packaged_amount.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.total_packaged_amount.label}}<span *ngIf="content.fields.total_packaged_amount.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.total_packaged_amount.control.styles.style"
                              [ngClass]="content.fields.total_packaged_amount.control.styles.classes"
                              [matTooltip]="content.fields.total_packaged_amount.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.total_packaged_amount.control.text }}</div>
                        <ng-container *ngIf="content.fields.total_packaged_amount.invalid">
                          <ng-container *ngFor="let error of content.fields.total_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-total_gross_weight" *ngIf="!content.fields.total_gross_weight.hidden && !content.fields.total_gross_weight.removed" 
                            class="field-container standard {{content.fields.total_gross_weight.invalid ? 'invalid' : ''}} {{content.fields.total_gross_weight.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="content.fields.total_gross_weight.styles.style"
                            [ngClass]="content.fields.total_gross_weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.total_gross_weight.label + (content.fields.total_gross_weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.total_gross_weight.label}}<span *ngIf="content.fields.total_gross_weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.total_gross_weight.control.styles.style"
                              [ngClass]="content.fields.total_gross_weight.control.styles.classes"
                              [matTooltip]="content.fields.total_gross_weight.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.total_gross_weight.control.text }}</div>
                        <ng-container *ngIf="content.fields.total_gross_weight.invalid">
                          <ng-container *ngFor="let error of content.fields.total_gross_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!content.fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </mat-card-content>



    </ng-container>

    <div *ngIf="$hasMissingRequiredInParams"
        class="missing-params">
      <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
    </div>
  </mat-card>
  <div class="card-gripper"></div>
</div>