import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService } from './FootprintManager.pack_verification_shipping_container_properties_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_pack_verification_instructions_gridComponent } from './FootprintManager.pack_verification_instructions_grid.component';
import { PackVerification_containertypes_dd_singleComponent } from './PackVerification.containertypes_dd_single.component'
import { PackVerification_measurementunits_dd_singleComponent } from './PackVerification.measurementunits_dd_single.component'

type EntityType = { 
    Id?: number, Height?: number, Length?: number, LookupCode?: string, TrackingNumber?: string, TrailerNumber?: string, Volume?: number, Weight?: number, Width?: number, LicensePlates?: { Id?: number, Archived?: boolean, LocationId?: number, WarehouseId?: number }[], Shipment?: { Id?: number, ActualWarehouseId?: number, LookupCode?: string, TrailerId?: string, OrderLookups?: { CreatedSysDateTime?: string, Order?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string } }, Account?: { Id?: number, LookupCode?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierServiceType?: { Id?: number, Name?: string } } }[], Carrier?: { Id?: number, Name?: string }, CarrierServiceType?: { Id?: number, Name?: string } }, ContainerType?: { Id?: number, Name?: string }, DimensionUom?: { Id?: number, Name?: string }, WeightUom?: { Id?: number, Name?: string }, VolumeUom?: { Id?: number, Name?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_pack_verification_instructions_gridComponent),
    forwardRef(() => PackVerification_containertypes_dd_singleComponent),
    forwardRef(() => PackVerification_measurementunits_dd_singleComponent),
  ],
  selector: 'FootprintManager-pack_verification_shipping_container_properties_editor',
  templateUrl: './FootprintManager.pack_verification_shipping_container_properties_editor.component.html'
})
export class FootprintManager_pack_verification_shipping_container_properties_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { shippingContainerId: number, startVerificationTaskId: number, targetLocationId?: number, isReadOnly: boolean, can_fail_verification: boolean } = { shippingContainerId: null, startVerificationTaskId: null, targetLocationId: null, isReadOnly: null, can_fail_verification: null };
  //#region Inputs
  @Input('shippingContainerId') set $inParams_shippingContainerId(v: number) {
    this.inParams.shippingContainerId = v;
  }
  get $inParams_shippingContainerId(): number {
    return this.inParams.shippingContainerId;
  }
  @Input('startVerificationTaskId') set $inParams_startVerificationTaskId(v: number) {
    this.inParams.startVerificationTaskId = v;
  }
  get $inParams_startVerificationTaskId(): number {
    return this.inParams.startVerificationTaskId;
  }
  @Input('targetLocationId') set $inParams_targetLocationId(v: number) {
    this.inParams.targetLocationId = v;
  }
  get $inParams_targetLocationId(): number {
    return this.inParams.targetLocationId;
  }
  @Input('isReadOnly') set $inParams_isReadOnly(v: boolean) {
    this.inParams.isReadOnly = v;
  }
  get $inParams_isReadOnly(): boolean {
    return this.inParams.isReadOnly;
  }
  @Input('can_fail_verification') set $inParams_can_fail_verification(v: boolean) {
    this.inParams.can_fail_verification = v;
  }
  get $inParams_can_fail_verification(): boolean {
    return this.inParams.can_fail_verification;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { currentContainerId?: number, currentDimensionUomId?: number, currentWeightUomId?: number, canManuallyEdit?: boolean, containerTypeName?: string, weight_capture?: { can_use_printnode?: boolean, can_use_manual?: boolean } } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    container_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    actual_weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    length: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dimensions_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    consolidated_dimensions: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    consolidated_weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      fail_verification: new ToolModel(new ButtonModel('fail_verification', new ButtonStyles(['destructive'], null), false, false, false, 'Fail verification', 'icon-ic_fluent_prohibited_20_regular', null)
    , false)
  };

  fields = {
    container_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['container_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Container type', false, false),
    actual_weight: new FieldModel(new TextBoxModel(this.formGroup.controls['actual_weight'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Actual weight', false, false),
    capture_weight: new FieldModel(new ButtonModel('', new ButtonStyles(['primary'], null), false, false, false, 'Capture weight', 'icon-ic_fluent_scales_20_regular', null)
, new ControlContainerStyles(null, null), 'Capture weight', false, false),
    weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['weight'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Weight', false, false),
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Unit', false, false),
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Length', false, false),
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Width', false, false),
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, false, '0.00', '', null)
, new ControlContainerStyles(null, null), 'Height', false, false),
    dimensions_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dimensions_uom'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Unit', false, false),
    consolidated_dimensions: new FieldModel(new TextBoxModel(this.formGroup.controls['consolidated_dimensions'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Dimensions', false, false),
    consolidated_weight: new FieldModel(new TextBoxModel(this.formGroup.controls['consolidated_weight'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Weight', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Container properties',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    newGroup2: new FieldsetModel(
      'Measurements',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      instructions: new TabItemModel(
        this.rootTabGroup, 
        'Instructions', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_instructions_pack_verification_instructions_grid_inParams_shippingContainerId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $tabs_instructions_pack_verification_instructions_grid_inParams_startVerificationTaskId(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.startVerificationTaskId;
      
      return expr;
    }
  
    get $tabs_instructions_pack_verification_instructions_grid_inParams_isReadOnly(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.isReadOnly;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_instructions', { read: FootprintManager_pack_verification_instructions_gridComponent }) $tabs_instructions: FootprintManager_pack_verification_instructions_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_dimensions_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    container_type: this.fields.container_type.control.valueChanges
    ,
    actual_weight: this.fields.actual_weight.control.valueChanges
    ,
    weight: this.fields.weight.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
    length: this.fields.length.control.valueChanges
    ,
    width: this.fields.width.control.valueChanges
    ,
    height: this.fields.height.control.valueChanges
    ,
    dimensions_uom: this.fields.dimensions_uom.control.valueChanges
    ,
    consolidated_dimensions: this.fields.consolidated_dimensions.control.valueChanges
    ,
    consolidated_weight: this.fields.consolidated_weight.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.instructions,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shippingContainerId)) {
        this.$missingRequiredInParams.push('shippingContainerId');
      }
      if(isNil(this.inParams.startVerificationTaskId)) {
        this.$missingRequiredInParams.push('startVerificationTaskId');
      }
      if(isNil(this.inParams.isReadOnly)) {
        this.$missingRequiredInParams.push('isReadOnly');
      }
      if(isNil(this.inParams.can_fail_verification)) {
        this.$missingRequiredInParams.push('can_fail_verification');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Shipping container properties';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      shippingContainerId:  $editor.inParams.shippingContainerId 
    };

    const data = await this.$datasources.PackVerification.ds_get_shippingcontainer_by_shippingContainerId.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.container_type.control as SelectBoxModel).reset($editor.entity.ContainerType?.Id);
    
    (this.fields.weight.control as NumberBoxModel).reset($editor.entity.Weight);
    (this.fields.weight_uom.control as SelectBoxModel).reset($editor.entity.WeightUom?.Id);
    (this.fields.length.control as NumberBoxModel).reset($editor.entity.Length);
    (this.fields.width.control as NumberBoxModel).reset($editor.entity.Width);
    (this.fields.height.control as NumberBoxModel).reset($editor.entity.Height);
    (this.fields.dimensions_uom.control as SelectBoxModel).reset($editor.entity.DimensionUom?.Id);
    
    

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_instructions') {
        if (!isNil(this.$tabs_instructions) && !this.tabs.instructions.hidden && !this.tabs.instructions.removed) {
          this.$tabs_instructions.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .container_type
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_container_type_changed();
      });
    this.$formGroupFieldValidationObservables
      .actual_weight
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_actual_weight_changed();
      });
    this.$formGroupFieldValidationObservables
      .weight
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_weight_changed();
      });
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_weight_uom_changed();
      });
    this.$formGroupFieldValidationObservables
      .length
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_length_changed();
      });
    this.$formGroupFieldValidationObservables
      .width
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_width_changed();
      });
    this.$formGroupFieldValidationObservables
      .height
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_height_changed();
      });
    this.$formGroupFieldValidationObservables
      .dimensions_uom
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_dimension_uom_changed();
      });
    this.$formGroupFieldValidationObservables
      .consolidated_dimensions
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .consolidated_weight
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_container_type_changed(event = null) {
    return this.on_container_type_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_container_type_changedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      let length: number = $editor.fields.length.control.value ?? 0;
      let width: number = $editor.fields.width.control.value ?? 0;
      let height: number = $editor.fields.height.control.value ?? 0;
      let dimensionUomId: number = $editor.fields.dimensions_uom.control.value;
      let weight: number = $editor.fields.weight.control.value ?? 0;
      let weightUomId: number = $editor.fields.weight_uom.control.value;
  
      let oldContainerWeight = 0;
      if ($utils.isDefined($editor.vars.currentContainerId)) {
          oldContainerWeight = (await $datasources.PackVerification.ds_get_container_type_by_containerTypeId.get({ containerTypeId: $editor.vars.currentContainerId })).result?.Weight ?? 0;
      }
  
      // Get shipping container type
      if ($utils.isDefined($editor.fields.container_type.control.value)) {
          let containerType = (await $datasources.PackVerification.ds_get_container_type_by_containerTypeId.get({ containerTypeId: $editor.fields.container_type.control.value })).result;
  
          if (!$utils.isDefined(containerType)) {
              throw new Error(`Container type with ID '${$editor.fields.container_type.control.value}' was not found.`);
          }
  
          // Update dimensions
          length = containerType.Length;
          width = containerType.Width;
          height = containerType.Height;
          dimensionUomId = containerType.DimensionUomId;
  
          // Update weight
          weight -= oldContainerWeight; // Remove old container's weight
          if ($utils.isDefined(weightUomId)) {
              let weightConvertResult = (await $flows.Utilities.convert_weight_flow({ inputWeight: weight, fromUom: weightUomId, toUom: containerType.WeightUomId }));
  
              if ($utils.isDefined(weightConvertResult.reasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: weightConvertResult.reasons })).formattedValue);
              }
  
              weight = weightConvertResult.convertedWeight;
          }
  
          weightUomId = containerType.WeightUomId;
          weight += containerType.Weight;
  
          if (weight < 0) { weight = 0; }
  
      }
      else {
          weight -= oldContainerWeight;
      }
  
      $editor.fields.length.control.value = length;
      $editor.fields.width.control.value = width;
      $editor.fields.height.control.value = height;
      $editor.fields.dimensions_uom.control.value = dimensionUomId;
      $editor.fields.weight.control.value = weight;
      $editor.fields.weight_uom.control.value = weightUomId;
  
      if ($utils.isDefined($editor.fields.container_type.control.value)) {
          $editor.vars.containerTypeName = (await $datasources.PackVerification.ds_containertypes_dd.getByKeys({ $keys: [$editor.fields.container_type.control.value] })).result[0]?.Name ?? '';
      } else {
          $editor.vars.containerTypeName = null;
      }
      let dimensionUomName = (await $datasources.PackVerification.ds_measurementunits_dd.getByKeys({ typeId: 1, $keys: [dimensionUomId] })).result[0]?.Name ?? '';
      let weightUomName = (await $datasources.PackVerification.ds_measurementunits_dd.getByKeys({ typeId: 3, $keys: [weightUomId] })).result[0]?.Name ?? '';
  
      $editor.fields.consolidated_dimensions.control.value = `${(await $flows.Utilities.math_round_flow({ inputNumber: length, position: 2 })).roundedNumber} x ${(await $flows.Utilities.math_round_flow({ inputNumber: width, position: 2 })).roundedNumber} x ${(await $flows.Utilities.math_round_flow({ inputNumber: height, position: 2 })).roundedNumber} ${dimensionUomName}`;
      $editor.fields.consolidated_weight.control.value = `${(await $flows.Utilities.math_round_flow({ inputNumber: weight, position: 2 })).roundedNumber} ${weightUomName}`;
  
      await $editor.on_save();
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootprintManager.openErrorDialog('Error updating shipping container', targetError.message);
  }
  
  $editor.vars.currentContainerId = $editor.fields.container_type.control.value;
  $editor.vars.currentDimensionUomId = $editor.fields.dimensions_uom.control.value;
  $editor.vars.currentWeightUomId = $editor.fields.weight_uom.control.value;
  }
  on_weight_changed(event = null) {
    return this.on_weight_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_weight_changedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $editor.on_save();
  }
  on_length_changed(event = null) {
    return this.on_length_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_length_changedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $editor.on_save();
  }
  on_width_changed(event = null) {
    return this.on_width_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_width_changedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $editor.on_save();
  }
  on_height_changed(event = null) {
    return this.on_height_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_height_changedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $editor.on_save();
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $editor.e_update_properties();
  
  // Update typeId and dimensions
  await $flows.Utilities.crud_update_flow({
      entitySet: 'ShippingContainers',
      id: $editor.entity.Id,
      entity: {
          ContainerTypeId: $editor.fields.container_type.control.value,
          Length: $editor.fields.length.control.value,
          Width: $editor.fields.width.control.value,
          Height: $editor.fields.height.control.value,
          DimensionUomId: $editor.fields.dimensions_uom.control.value,
          Weight: $editor.fields.weight.control.value,
          WeightUomId: $editor.fields.weight_uom.control.value,
      }
  });
  
  await $flows.PackVerification.save_shipping_container_actual_weight_flow({ shippingContainerId: $editor.inParams.shippingContainerId, actualWeight: $editor.fields.actual_weight.control.value });
  
  
  }
  on_dimension_uom_changed(event = null) {
    return this.on_dimension_uom_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_dimension_uom_changedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($editor.fields.dimensions_uom.control.value)) {
      // Convert the existing dimension values to the new UOM
      $editor.fields.length.control.value = (await $flows.Utilities.convert_distance_flow({ inputDistance: $editor.fields.length.control.value, fromUom: $editor.vars.currentDimensionUomId, toUom: $editor.fields.dimensions_uom.control.value})).convertedDistance;
      $editor.fields.width.control.value = (await $flows.Utilities.convert_distance_flow({ inputDistance: $editor.fields.width.control.value, fromUom: $editor.vars.currentDimensionUomId, toUom: $editor.fields.dimensions_uom.control.value})).convertedDistance;
      $editor.fields.height.control.value = (await $flows.Utilities.convert_distance_flow({ inputDistance: $editor.fields.height.control.value, fromUom: $editor.vars.currentDimensionUomId, toUom: $editor.fields.dimensions_uom.control.value})).convertedDistance;
  
      $editor.vars.currentDimensionUomId = $editor.fields.dimensions_uom.control.value;
  
      await $editor.on_save();
  }
  }
  on_weight_uom_changed(event = null) {
    return this.on_weight_uom_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_weight_uom_changedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($editor.fields.weight_uom.control.value) && $utils.isDefined($editor.vars.currentWeightUomId)) {
      // Convert the existing weight values to the new UOM
      $editor.fields.weight.control.value = (await $flows.Utilities.convert_weight_flow({ inputWeight: $editor.fields.weight.control.value, fromUom: $editor.vars.currentWeightUomId, toUom: $editor.fields.weight_uom.control.value})).convertedWeight;
  
      $editor.vars.currentWeightUomId = $editor.fields.weight_uom.control.value;
  
      await $editor.on_save();
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if ($editor.inParams.isReadOnly) {
      $editor.lock_editor();
  }
  
  check_weight_capture();
  
  $editor.toolbar.fail_verification.hidden = !$editor.inParams.can_fail_verification;
  
  if ($editor.vars.canManuallyEdit) {
      $editor.fields.length.hidden = false;
      $editor.fields.width.hidden = false;
      $editor.fields.height.hidden = false;
      $editor.fields.dimensions_uom.hidden = false;
      $editor.fields.weight.hidden = false;
      $editor.fields.weight_uom.hidden = false;
  
      $editor.fields.consolidated_dimensions.hidden = true;
      $editor.fields.consolidated_weight.hidden = true;
  }
  else {
      $editor.fields.length.hidden = true;
      $editor.fields.width.hidden = true;
      $editor.fields.height.hidden = true;
      $editor.fields.dimensions_uom.hidden = true;
      $editor.fields.weight.hidden = true;
      $editor.fields.weight_uom.hidden = true;
  
      $editor.fields.consolidated_dimensions.hidden = false;
      $editor.fields.consolidated_weight.hidden = false;
  }
  
  $editor.vars.currentContainerId = $editor.fields.container_type.control.value;
  $editor.vars.currentDimensionUomId = $editor.fields.dimensions_uom.control.value;
  $editor.vars.currentWeightUomId = $editor.fields.weight_uom.control.value;
  
  $editor.vars.containerTypeName = $utils.isDefined($editor.vars.currentContainerId) ? (await $datasources.PackVerification.ds_containertypes_dd.getByKeys({ $keys: [$editor.vars.currentContainerId] })).result[0]?.Name : '';
  let dimensionUomName = $utils.isDefined($editor.vars.currentDimensionUomId) ? (await $datasources.PackVerification.ds_measurementunits_dd.getByKeys({ typeId: 1, $keys: [$editor.vars.currentDimensionUomId] })).result[0]?.Name : '';
  let weightUomName = $utils.isDefined($editor.vars.currentWeightUomId) ? (await $datasources.PackVerification.ds_measurementunits_dd.getByKeys({ typeId: 3, $keys: [$editor.vars.currentWeightUomId] })).result[0]?.Name : '';
  
  $editor.fields.length.control.value = $editor.fields.length.control.value ?? 0;
  $editor.fields.width.control.value = $editor.fields.width.control.value ?? 0;
  $editor.fields.height.control.value = $editor.fields.height.control.value ?? 0;
  $editor.fields.weight.control.value = $editor.fields.weight.control.value ?? 0;
  
  
  $editor.fields.consolidated_dimensions.control.value =
      `${(await $flows.Utilities.math_round_flow({ inputNumber: $editor.fields.length.control.value, position: 2 })).roundedNumber} x ${(await $flows.Utilities.math_round_flow({ inputNumber: $editor.fields.width.control.value, position: 2 })).roundedNumber} x ${(await $flows.Utilities.math_round_flow({ inputNumber: $editor.fields.height.control.value, position: 2 })).roundedNumber} ${dimensionUomName}`;
  $editor.fields.consolidated_weight.control.value = `${(await $flows.Utilities.math_round_flow({ inputNumber: $editor.fields.weight.control.value, position: 2 })).roundedNumber} ${weightUomName}`;
  
  $editor.e_update_properties();
  
  
  
  /**********************************
   * FUNCTIONS
  ***********************************/
  async function check_weight_capture() {
      $editor.fields.capture_weight.hidden = true;
  
      $editor.vars.weight_capture = {
          can_use_printnode: false,
          can_use_manual: true
      };
  
      // PrintNode
      try {
          await $flows.PrintNode.validate_credentials_flow({});
          $editor.vars.weight_capture.can_use_printnode = true;
      } catch (error) {
          $editor.vars.weight_capture.can_use_printnode = false;
      }
  
      // Affect editor
      if ($editor.vars.weight_capture.can_use_printnode) {
          $editor.fields.capture_weight.label = 'PrintNode';
          $editor.fields.capture_weight.hidden = false;
      }
  
      $editor.fields.actual_weight.control.readOnly = !$editor.vars.weight_capture.can_use_manual;
  }
  }
  on_fail_verification_flow(event = null) {
    return this.on_fail_verification_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fail_verification_flowInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  try {
      let confirmationResult = (await $shell.FootprintManager.openpack_verification_failure_formDialog({ shippingContainerCode: $editor.entity.LookupCode }));
  
      if (confirmationResult.isConfirmed) {
          let targetLocationId = (await $flows.PackVerification.get_failure_location_flow({
              inputs: {
                  context: 'ManualFail',
                  defaultLocationId: $editor.inParams.targetLocationId ?? $editor.entity.LicensePlates[0]?.LocationId,
                  taskId: $editor.inParams.startVerificationTaskId
              }
          })).targetLocationId;
  
          let result = (await $flows.PackVerification.fail_pack_verification_flow({
              shippingContainerId: $editor.entity.Id,
              startPackVerificationTaskId: $editor.inParams.startVerificationTaskId,
              reasonCodeId: confirmationResult.reasonCodeId,
              targetLocationId: targetLocationId
      }));
  
          document.getElementsByTagName('FootprintManager-pack_verification_wizard')[0].dispatchEvent(new CustomEvent('handleCloseWizard'));
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.FootprintManager.openErrorDialog('Error verifying content', targetError.message);
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Get element to attach to 
  const element = document.getElementsByTagName('FootprintManager-pack_verification_wizard')[0];
  
  if (element == null) {
      throw new Error(`Failed to identify the HTML element for pack verification wizard`);
  }
  
  element.addEventListener('handle_pack_verification_shipping_container_properties_editor_validated', ((event: CustomEvent) => {
      $editor.lock_editor();
  }) as EventListener);
  
  element.dispatchEvent(new CustomEvent('on_pack_verification_shipping_container_properties_editor_initialized'));
  
  
  
  }
  on_actual_weight_changed(event = null) {
    return this.on_actual_weight_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_actual_weight_changedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  await $editor.on_save();
  }
  e_update_properties(event = null) {
    return this.e_update_propertiesInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async e_update_propertiesInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  document.getElementsByTagName('FootprintManager-pack_verification_wizard')[0].dispatchEvent(new CustomEvent('handle_container_properties_changed', {
      detail: {
          containerType: $editor.vars.containerTypeName,
          dimensions: $editor.fields.consolidated_dimensions.control.value,
          weight: $editor.fields.consolidated_weight.control.value,
          actualWeight: $editor.fields.actual_weight.control.value
      }
  }));
  }
  lock_editor(event = null) {
    return this.lock_editorInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async lock_editorInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $editor.fields.length.control.readOnly = true;
  $editor.fields.width.control.readOnly = true;
  $editor.fields.height.control.readOnly = true;
  $editor.fields.dimensions_uom.control.readOnly = true;
  $editor.fields.weight.control.readOnly = true;
  $editor.fields.weight_uom.control.readOnly = true;
  
  $editor.fields.consolidated_dimensions.control.readOnly = true;
  $editor.fields.consolidated_weight.control.readOnly = true;
  
  $editor.fields.container_type.control.readOnly = true;
  $editor.fields.actual_weight.control.readOnly = true;
  }
  on_capture_weight_clicked(event = null) {
    return this.on_capture_weight_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_capture_weight_clickedInternal(
    $editor: FootprintManager_pack_verification_shipping_container_properties_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pack_verification_shipping_container_properties_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const entity_type = $types.PrintNode.e_entity_type.Location;
  const entity_id = $editor.inParams.targetLocationId;
  
  let scales = (await $datasources.PrintNode.ds_get_scales.getList({ entity: { type: entity_type, id: entity_id.toString()}})).result;
  
  if (!$utils.isDefined(scales)) {
      await $shell.FootprintManager.openInfoDialog('Error on PrintNode capture', `No scale is configured for the selected pack verification location.`);
      return;
  }
  
  scales = scales.filter(s => $utils.isDefinedTrimmed(s.capture?.captured_on));
  
  scales.sort((a, b) => new Date(b.capture.captured_on).getTime() - new Date(a.capture?.captured_on).getTime());
  
  const targetScale = scales[0];
  
  if (!$utils.isDefined(targetScale)) {
      await $shell.FootprintManager.openInfoDialog('Error on PrintNode capture', 'No capture data returned from PrintNode. Please weigh item and capture within 45 seconds.');
      return;
  }
  
  // Convert weight
  const conversionResult = (await $flows.Utilities.convert_weight_flow({ inputWeight: targetScale.capture.micrograms / 1000000, fromUom: 13, toUom: $editor.fields.weight_uom.control.value}));
  if ($utils.isDefined(conversionResult.reasons)) {
      await $shell.FootprintManager.openErrorDialog('Error converting weight from micrograms', `An error occurred when attempting to convert ${targetScale.capture.micrograms} micrograms to ${$editor.fields.weight_uom.control.displayText}`, conversionResult.reasons);
      return;
  }
  
  // Set weight in editor
  $editor.fields.actual_weight.control.value = conversionResult.convertedWeight;
  $editor.on_actual_weight_changed();
  }
  //#endregion private flows
  
}
