import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_invoice_reportService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { Id: number }): 
  Promise<{ result: { Id?: number, BillToContactId?: number, CreatedSysDateTime?: string, DueDate?: string, InvoiceDate?: string, LookupCode?: string, ProjectId?: number, InvoiceLines?: { Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingRecords?: { ActualUOM?: number, BillingTask?: { Id?: number, Order?: { OwnerReference?: string }, BillingCode?: { Description?: string } }, ActualMeasurementUnit?: { Short_name?: string } }[] }[], Project?: { Id?: number, Owner?: { LookupCode?: string } }, Account?: { LookupCode?: string, Name?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string } }[] }, Term?: { Description?: string, Name?: string, PeriodInDays?: number }, OwnerReference?: string, BillingDescription?: string, ActualMesurmentUnit?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.Id)) {
      missingRequiredInParams.push('\'Id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_invoice_report/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { Id: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, BillToContactId?: number, CreatedSysDateTime?: string, DueDate?: string, InvoiceDate?: string, LookupCode?: string, ProjectId?: number, InvoiceLines?: { Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingRecords?: { ActualUOM?: number, BillingTask?: { Id?: number, Order?: { OwnerReference?: string }, BillingCode?: { Description?: string } }, ActualMeasurementUnit?: { Short_name?: string } }[] }[], Project?: { Id?: number, Owner?: { LookupCode?: string } }, Account?: { LookupCode?: string, Name?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string } }[] }, Term?: { Description?: string, Name?: string, PeriodInDays?: number }, OwnerReference?: string, BillingDescription?: string, ActualMesurmentUnit?: string }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.Id)) {
      missingRequiredInParams.push('\'Id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_invoice_report/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { Id: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, BillToContactId?: number, CreatedSysDateTime?: string, DueDate?: string, InvoiceDate?: string, LookupCode?: string, ProjectId?: number, InvoiceLines?: { Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingRecords?: { ActualUOM?: number, BillingTask?: { Id?: number, Order?: { OwnerReference?: string }, BillingCode?: { Description?: string } }, ActualMeasurementUnit?: { Short_name?: string } }[] }[], Project?: { Id?: number, Owner?: { LookupCode?: string } }, Account?: { LookupCode?: string, Name?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string } }[] }, Term?: { Description?: string, Name?: string, PeriodInDays?: number }, OwnerReference?: string, BillingDescription?: string, ActualMesurmentUnit?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.Id)) {
      missingRequiredInParams.push('\'Id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_invoice_report/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      Id: number    }) {
        if(isNil(inParams.Id)) {
          return true; 
        }
      return false;
    }
}
