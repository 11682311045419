import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { Inventory_LocalizationService } from './Inventory.localization.service';
import { Inventory_inventory_material_snaphot_grid_ComponentContextService } from './Inventory.inventory_material_snaphot_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Inventory.frontend.types'
import { $frontendTypes as $types} from './Inventory.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IInventory_inventory_material_snaphot_gridComponentEntity {
  id?: string, captureDate?: string, materialId?: number, packagingId?: number, warehouseId?: number, totalPackagedAmount?: number, totalBaseAmount?: number, projectId?: number, materialLookupcode?: string, packagingShortName?: string, warehouseName?: string, totalNetWeight?: number, totalGrossWeight?: number, weightShortName?: string, weightUomId?: number}

interface IInventory_inventory_material_snaphot_gridComponentInParams {
  captureDate: string, projectId: number, materialIds?: number[], warehouseId: number}


class Inventory_inventory_material_snaphot_gridComponentRowModel extends GridRowModel {
  grid: Inventory_inventory_material_snaphot_gridComponent;
  entity: IInventory_inventory_material_snaphot_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    id: null,
    captureDate: null,
    materialId: null,
    packagingId: null,
    warehouseId: null,
    totalPackagedAmount: null,
    totalBaseAmount: null,
    projectId: null,
    materialLookupcode: null,
    packagingShortName: null,
    warehouseName: null,
    totalNetWeight: null,
    totalGrossWeight: null,
    weightShortName: null,
    weightUomId: null,
  }



  constructor(
    grid: Inventory_inventory_material_snaphot_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Inventory_ShellService,
private $datasources: Inventory_DatasourceService,
private $flows: Inventory_FlowService,
private $reports: Inventory_ReportService,
private $localization: Inventory_LocalizationService,
private $operations: Inventory_OperationService,
private $logger: CleanupLoggerService,
private $context: Inventory_inventory_material_snaphot_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.id = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.id,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.captureDate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.captureDate,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.materialId = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.materialId,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packagingId = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packagingId,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouseId = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouseId,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.totalPackagedAmount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.totalPackagedAmount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.totalBaseAmount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.totalBaseAmount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.projectId = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.projectId,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.materialLookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.materialLookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packagingShortName = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packagingShortName,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouseName = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouseName,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.totalNetWeight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.totalNetWeight,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.totalGrossWeight = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.totalGrossWeight,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.weightShortName = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.weightShortName,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.weightUomId = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.weightUomId,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IInventory_inventory_material_snaphot_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IInventory_inventory_material_snaphot_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      captureDate:  $grid.inParams.captureDate ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      materialIds:  $grid.inParams.materialIds ,
    };
    const data = await this.$datasources.Inventory.ds_inventory_material_snapshot_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IInventory_inventory_material_snaphot_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.id;
    (this.cells.captureDate.displayControl as TextModel).text = $row.entity.captureDate?.toString();
    (this.cells.materialId.displayControl as TextModel).text = $row.entity.materialId?.toString();
    (this.cells.packagingId.displayControl as TextModel).text = $row.entity.packagingId?.toString();
    (this.cells.warehouseId.displayControl as TextModel).text = $row.entity.warehouseId?.toString();
    (this.cells.totalPackagedAmount.displayControl as TextModel).text = $row.entity.totalPackagedAmount?.toString();
    (this.cells.totalBaseAmount.displayControl as TextModel).text = $row.entity.totalBaseAmount?.toString();
    (this.cells.projectId.displayControl as TextModel).text = $row.entity.projectId?.toString();
    (this.cells.materialLookupcode.displayControl as TextModel).text = $row.entity.materialLookupcode;
    (this.cells.packagingShortName.displayControl as TextModel).text = $row.entity.packagingShortName;
    (this.cells.warehouseName.displayControl as TextModel).text = $row.entity.warehouseName;
    (this.cells.totalNetWeight.displayControl as TextModel).text = $row.entity.totalNetWeight?.toString();
    (this.cells.totalGrossWeight.displayControl as TextModel).text = $row.entity.totalGrossWeight?.toString();
    (this.cells.weightShortName.displayControl as TextModel).text = $row.entity.weightShortName;
    (this.cells.weightUomId.displayControl as TextModel).text = $row.entity.weightUomId?.toString();

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Inventory-inventory_material_snaphot_grid',
  templateUrl: './Inventory.inventory_material_snaphot_grid.component.html'
})
export class Inventory_inventory_material_snaphot_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInventory_inventory_material_snaphot_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IInventory_inventory_material_snaphot_gridComponentInParams = { captureDate: null, projectId: null, materialIds: [], warehouseId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'id', 'ID', false, false, null, false),       captureDate: new GridHeaderModel(new HeaderStyles(null, null), 'captureDate', 'Capture date', false, false, null, false),       materialId: new GridHeaderModel(new HeaderStyles(null, null), 'materialId', 'Material ID', false, false, null, false),       packagingId: new GridHeaderModel(new HeaderStyles(null, null), 'packagingId', 'Packaging ID', false, false, null, false),       warehouseId: new GridHeaderModel(new HeaderStyles(null, null), 'warehouseId', 'Warehouse ID', false, false, null, false),       totalPackagedAmount: new GridHeaderModel(new HeaderStyles(null, null), 'totalPackagedAmount', 'Total packaged amount', false, false, null, false),       totalBaseAmount: new GridHeaderModel(new HeaderStyles(null, null), 'totalBaseAmount', 'Total base amount', false, false, null, false),       projectId: new GridHeaderModel(new HeaderStyles(null, null), 'projectId', 'Project ID', false, false, null, false),       materialLookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'materialLookupcode', 'Material', false, false, null, false),       packagingShortName: new GridHeaderModel(new HeaderStyles(null, null), 'packagingShortName', 'Packaging short name', false, false, null, false),       warehouseName: new GridHeaderModel(new HeaderStyles(null, null), 'warehouseName', 'Warehouse name', false, false, null, false),       totalNetWeight: new GridHeaderModel(new HeaderStyles(null, null), 'totalNetWeight', 'Total net weight', false, false, null, false),       totalGrossWeight: new GridHeaderModel(new HeaderStyles(null, null), 'totalGrossWeight', 'Total gross weight', false, false, null, false),       weightShortName: new GridHeaderModel(new HeaderStyles(null, null), 'weightShortName', 'Weight short name', false, false, null, false),       weightUomId: new GridHeaderModel(new HeaderStyles(null, null), 'weightUomId', 'Weight UOM ID', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Inventory_inventory_material_snaphot_gridComponentRowModel[] = [];

  @Input('captureDate') set $inParams_captureDate(value: any) {
    this.inParams['captureDate'] = value;
  }
  get $inParams_captureDate(): any {
    return this.inParams['captureDate'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Inventory_ShellService,
    private $datasources: Inventory_DatasourceService,
    private $flows: Inventory_FlowService,
    private $reports: Inventory_ReportService,
    private $localization: Inventory_LocalizationService,
    private $operations: Inventory_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Inventory_inventory_material_snaphot_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Inventory material snaphot grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.captureDate)) {
        this.$missingRequiredInParams.push('captureDate');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      captureDate:  $grid.inParams.captureDate ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      materialIds:  $grid.inParams.materialIds ,
    };
    try {
      const data = await this.$datasources.Inventory.ds_inventory_material_snapshot_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IInventory_inventory_material_snaphot_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Inventory_inventory_material_snaphot_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: Inventory_inventory_material_snaphot_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }



 
  close() {
    this.$finish.emit();
  }
}
