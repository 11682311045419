import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { PrintNode_printnode_library_homeComponent } from './PrintNode.printnode_library_home.component';
import { PrintNode_printnode_tester_formComponent } from './PrintNode.printnode_tester_form.component';
import { PrintNode_printjob_gridComponent } from './PrintNode.printjob_grid.component';
import { PrintNode_label_image_reportComponent } from './PrintNode.label_image_report.component';
import { PrintNode_printers_dd_singleComponent } from './PrintNode.printers_dd_single.component';
import { PrintNode_scales_dd_singleComponent } from './PrintNode.scales_dd_single.component';
import { PrintNode_printers_dd_multiComponent } from './PrintNode.printers_dd_multi.component';
import { PrintNode_scales_dd_multiComponent } from './PrintNode.scales_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class PrintNode_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public PrintNode: PrintNode_ShellService = this;

  public openprintnode_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('PrintNode', 'printnode_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'PrintNode_printnode_library_home',
        component: PrintNode_printnode_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openprintnode_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('PrintNode', 'printnode_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      PrintNode_printnode_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openprintnode_tester_form(replaceCurrentView?: boolean) {
    this.$logger.log('PrintNode', 'printnode_tester_form');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Test harness',
        referenceName: 'PrintNode_printnode_tester_form',
        component: PrintNode_printnode_tester_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openprintnode_tester_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('PrintNode', 'printnode_tester_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      PrintNode_printnode_tester_formComponent,
      'Test harness',
      mode,
      dialogSize
    )
  }
  public openprintjob_grid(inParams:{ printJobId?: number, printerId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Printjobs',
        referenceName: 'PrintNode_printjob_grid',
        component: PrintNode_printjob_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openprintjob_gridDialog(
    inParams:{ printJobId?: number, printerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      PrintNode_printjob_gridComponent,
      'Printjobs',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlabel_image_report(inParams:{ base_64?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('PrintNode', 'label_image_report');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'label_image_report',
        referenceName: 'PrintNode_label_image_report',
        component: PrintNode_label_image_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlabel_image_reportDialog(
    inParams:{ base_64?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('PrintNode', 'label_image_report');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      PrintNode_label_image_reportComponent,
      'label_image_report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'PrintNode_printnode_library_home') {
      this.$logger.log('PrintNode', 'printnode_library_home');
      const title = 'Home';
      const component = PrintNode_printnode_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrintNode_printnode_tester_form') {
      this.$logger.log('PrintNode', 'printnode_tester_form');
      const title = 'Test harness';
      const component = PrintNode_printnode_tester_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrintNode_printjob_grid') {
      const title = 'Printjobs';
      const component = PrintNode_printjob_gridComponent;
      const inParams:{ printJobId?: number, printerId?: number } = { printJobId: null, printerId: null };
      if (!isNil(params.get('printJobId'))) {
        const paramValueString = params.get('printJobId');
        inParams.printJobId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('printerId'))) {
        const paramValueString = params.get('printerId');
        inParams.printerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrintNode_label_image_report') {
      this.$logger.log('PrintNode', 'label_image_report');
      const title = 'label_image_report';
      const component = PrintNode_label_image_reportComponent;
      const inParams:{ base_64?: string } = { base_64: null };
      if (!isNil(params.get('base_64'))) {
        const paramValueString = params.get('base_64');
        // TODO: date
        inParams.base_64 = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrintNode_printers_dd_single') {
      const title = 'Printers dropdown';
      const component = PrintNode_printers_dd_singleComponent;
      const inParams:{ letterOnly?: boolean } = { letterOnly: null };
      if (!isNil(params.get('letterOnly'))) {
        const paramValueString = params.get('letterOnly');
        inParams.letterOnly = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrintNode_scales_dd_single') {
      const title = 'scales_dd';
      const component = PrintNode_scales_dd_singleComponent;
      const inParams:{ entity_type?: string, entity_id?: string } = { entity_type: null, entity_id: null };
      if (!isNil(params.get('entity_type'))) {
        const paramValueString = params.get('entity_type');
        // TODO: date
        inParams.entity_type = paramValueString;
              }
      if (!isNil(params.get('entity_id'))) {
        const paramValueString = params.get('entity_id');
        // TODO: date
        inParams.entity_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrintNode_printers_dd_multi') {
      const title = 'Printers dropdown';
      const component = PrintNode_printers_dd_multiComponent;
      const inParams:{ letterOnly?: boolean } = { letterOnly: null };
      if (!isNil(params.get('letterOnly'))) {
        const paramValueString = params.get('letterOnly');
        inParams.letterOnly = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PrintNode_scales_dd_multi') {
      const title = 'scales_dd';
      const component = PrintNode_scales_dd_multiComponent;
      const inParams:{ entity_type?: string, entity_id?: string } = { entity_type: null, entity_id: null };
      if (!isNil(params.get('entity_type'))) {
        const paramValueString = params.get('entity_type');
        // TODO: date
        inParams.entity_type = paramValueString;
              }
      if (!isNil(params.get('entity_id'))) {
        const paramValueString = params.get('entity_id');
        // TODO: date
        inParams.entity_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
