import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IPurchaseOrders_receiving_reportServiceInParams {
  orderId: number}

interface IPurchaseOrders_receiving_reportServiceData {
  header?: { result?: { Id?: number, DropIB?: boolean, LookupCode?: string, OwnerReference?: string, PreferredWarehouseId?: number, ProjectId?: number, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, CarrierId?: number, DeliveredDate?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, SealId?: string, ShippedDate?: string, TrailerId?: string, Carrier?: { Id?: number, Name?: string } } }[], Project?: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { Id?: number, LookupCode?: string, Name?: string, OwnersContactsLookup?: { OwnerId?: number, ContactId?: number, Contact?: { Id?: number, AddressId?: number, Address?: { Id?: number, City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } }, PreferredWarehouse?: { Id?: number, Name?: string, WarehousesContactsLookup?: { WarehouseId?: number, ContactId?: number, Contact?: { Id?: number, AddressId?: number, Address?: { Id?: number, City?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }
  detail?: { result?: { Id?: number, ActualPackagedAmount?: number, ActualSourceLicensePlateId?: number, ExpectedPackagedAmount?: number, Lot?: { LookupCode?: string, VendorLot?: { ExpirationDate?: string } }, Material?: { Description?: string, LookupCode?: string, Name?: string } }[] }
  signature?: { result?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number, FileContent?: string } }
  pallet?: { result?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, PalletTransactions?: { Amount?: number, PalletClassId?: number, PalletClass?: { Name?: string } }[] } }[] }
  totals?: { result?: { ExpectedPackagedAmount?: number, ActualPackagedAmount?: number, OrderLineNumber?: number }[] }
}

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_receiving_reportService extends ReportBaseService<IPurchaseOrders_receiving_reportServiceInParams, IPurchaseOrders_receiving_reportServiceData> {

  protected reportReferenceName = 'custom_receiving_report';
  protected appReferenceName = 'Reports';

  constructor(
    $utils: UtilsService,
    private $datasources: Reports_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IPurchaseOrders_receiving_reportServiceInParams) {
  }

  protected async getData(inParams: IPurchaseOrders_receiving_reportServiceInParams): Promise<IPurchaseOrders_receiving_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IPurchaseOrders_receiving_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IPurchaseOrders_receiving_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_header_receiving_report.get(dsParams);
      
      data.header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_details_receiving_report.get(dsParams);
      
      data.detail = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_get_order_signature_attachment.get(dsParams);
      
      data.signature = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId ,
        shipmentId:  null 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_get_pallet_details.get(dsParams);
      
      data.pallet = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.$datasources.Reports.custom_ds_totals_receiving_report.get(dsParams);
      
      data.totals = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
