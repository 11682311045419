<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-top"
                     *ngIf="!fieldsets.top.hidden && !fieldsets.top.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.top.collapsible }">
                    <div *ngIf="!fieldsets.top.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.top.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.top.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.top.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.top.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.top.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.top.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-reason_code" *ngIf="!fields.reason_code.hidden && !fields.reason_code.removed" 
                            class="field-container double {{fields.reason_code.invalid ? 'invalid' : ''}} {{fields.reason_code.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.reason_code.styles.style"
                            [ngClass]="fields.reason_code.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.reason_code.label + (fields.reason_code.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.reason_code.label}}<span *ngIf="fields.reason_code.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <SerialNumbers-reason_codes_dd_single 
                            data-cy="selector"
                            [type]="fields.reason_code.control.type"
                            formControlName="reason_code"
                            (displayTextChange)="fields.reason_code.control.displayText=$event"
                            [placeholder]="fields.reason_code.control.placeholder"
                            [styles]="fields.reason_code.control.styles"
                            [tooltip]="fields.reason_code.control.tooltip"
                          [parentEntity]="$fields_reason_code_selector_inParams_parentEntity"
                          [parentId]="$fields_reason_code_selector_inParams_parentId"
                        >
                        </SerialNumbers-reason_codes_dd_single>
                        <ng-container *ngIf="fields.reason_code.invalid">
                          <ng-container *ngFor="let error of fields.reason_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-move_found_serials" *ngIf="!fields.move_found_serials.hidden && !fields.move_found_serials.removed" 
                            class="field-container standard {{fields.move_found_serials.invalid ? 'invalid' : ''}} {{fields.move_found_serials.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.move_found_serials.styles.style"
                            [ngClass]="fields.move_found_serials.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.move_found_serials.label + (fields.move_found_serials.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.move_found_serials.label}}<span *ngIf="fields.move_found_serials.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="move_found_serials"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.move_found_serials.control.styles.style"
                                      [ngClass]="fields.move_found_serials.control.styles.classes"
                                      [matTooltip]="fields.move_found_serials.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.move_found_serials.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.move_found_serials.invalid">
                          <ng-container *ngFor="let error of fields.move_found_serials.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.top.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-missing_serial_numbers"
                     *ngIf="!fieldsets.missing_serial_numbers.hidden && !fieldsets.missing_serial_numbers.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.missing_serial_numbers.collapsible }">
                    <div *ngIf="!fieldsets.missing_serial_numbers.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.missing_serial_numbers.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.missing_serial_numbers.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.missing_serial_numbers.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.missing_serial_numbers.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.missing_serial_numbers.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.missing_serial_numbers.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-missing_serials_message" *ngIf="!fields.missing_serials_message.hidden && !fields.missing_serials_message.removed" 
                            class="field-container full {{fields.missing_serials_message.invalid ? 'invalid' : ''}} {{fields.missing_serials_message.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.missing_serials_message.styles.style"
                            [ngClass]="fields.missing_serials_message.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.missing_serials_message.label + (fields.missing_serials_message.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.missing_serials_message.label}}<span *ngIf="fields.missing_serials_message.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="missing_serials_message"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.missing_serials_message.control.readOnly || fields.missing_serials_message.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.missing_serials_message.control.placeholder}}"
                                [ngStyle]="fields.missing_serials_message.control.styles.style"
                                [ngClass]="fields.missing_serials_message.control.styles.classes"
                                [matTooltip]="fields.missing_serials_message.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.missing_serials_message.invalid">
                          <ng-container *ngFor="let error of fields.missing_serials_message.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-change_missing_serial_statuses" *ngIf="!fields.change_missing_serial_statuses.hidden && !fields.change_missing_serial_statuses.removed" 
                            class="field-container full {{fields.change_missing_serial_statuses.invalid ? 'invalid' : ''}} {{fields.change_missing_serial_statuses.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.change_missing_serial_statuses.styles.style"
                            [ngClass]="fields.change_missing_serial_statuses.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.change_missing_serial_statuses.label + (fields.change_missing_serial_statuses.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.change_missing_serial_statuses.label}}<span *ngIf="fields.change_missing_serial_statuses.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="change_missing_serial_statuses"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.change_missing_serial_statuses.control.styles.style"
                                      [ngClass]="fields.change_missing_serial_statuses.control.styles.classes"
                                      [matTooltip]="fields.change_missing_serial_statuses.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.change_missing_serial_statuses.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.change_missing_serial_statuses.invalid">
                          <ng-container *ngFor="let error of fields.change_missing_serial_statuses.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-serial_status" *ngIf="!fields.serial_status.hidden && !fields.serial_status.removed" 
                            class="field-container standard {{fields.serial_status.invalid ? 'invalid' : ''}} {{fields.serial_status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.serial_status.styles.style"
                            [ngClass]="fields.serial_status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.serial_status.label + (fields.serial_status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.serial_status.label}}<span *ngIf="fields.serial_status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <SerialNumbers-serialnumber_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.serial_status.control.type"
                            formControlName="serial_status"
                            (displayTextChange)="fields.serial_status.control.displayText=$event"
                            [placeholder]="fields.serial_status.control.placeholder"
                            [styles]="fields.serial_status.control.styles"
                            [tooltip]="fields.serial_status.control.tooltip"
                        >
                        </SerialNumbers-serialnumber_statuses_dd_single>
                        <ng-container *ngIf="fields.serial_status.invalid">
                          <ng-container *ngFor="let error of fields.serial_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-move_missing_serials" *ngIf="!fields.move_missing_serials.hidden && !fields.move_missing_serials.removed" 
                            class="field-container full {{fields.move_missing_serials.invalid ? 'invalid' : ''}} {{fields.move_missing_serials.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.move_missing_serials.styles.style"
                            [ngClass]="fields.move_missing_serials.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.move_missing_serials.label + (fields.move_missing_serials.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.move_missing_serials.label}}<span *ngIf="fields.move_missing_serials.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="move_missing_serials"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.move_missing_serials.control.styles.style"
                                      [ngClass]="fields.move_missing_serials.control.styles.classes"
                                      [matTooltip]="fields.move_missing_serials.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.move_missing_serials.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.move_missing_serials.invalid">
                          <ng-container *ngFor="let error of fields.move_missing_serials.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-serial_target_location" *ngIf="!fields.serial_target_location.hidden && !fields.serial_target_location.removed" 
                            class="field-container standard {{fields.serial_target_location.invalid ? 'invalid' : ''}} {{fields.serial_target_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.serial_target_location.styles.style"
                            [ngClass]="fields.serial_target_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.serial_target_location.label + (fields.serial_target_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.serial_target_location.label}}<span *ngIf="fields.serial_target_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.serial_target_location.control.type"
                            formControlName="serial_target_location"
                            (displayTextChange)="fields.serial_target_location.control.displayText=$event"
                            [placeholder]="fields.serial_target_location.control.placeholder"
                            [styles]="fields.serial_target_location.control.styles"
                            [tooltip]="fields.serial_target_location.control.tooltip"
                          [warehouseId]="$fields_serial_target_location_selector_inParams_warehouseId"
                          [typeId]="$fields_serial_target_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.serial_target_location.invalid">
                          <ng-container *ngFor="let error of fields.serial_target_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-serial_target_license_plate" *ngIf="!fields.serial_target_license_plate.hidden && !fields.serial_target_license_plate.removed" 
                            class="field-container standard {{fields.serial_target_license_plate.invalid ? 'invalid' : ''}} {{fields.serial_target_license_plate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.serial_target_license_plate.styles.style"
                            [ngClass]="fields.serial_target_license_plate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.serial_target_license_plate.label + (fields.serial_target_license_plate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.serial_target_license_plate.label}}<span *ngIf="fields.serial_target_license_plate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Inventory-licenseplates_dd_single 
                            data-cy="selector"
                            [type]="fields.serial_target_license_plate.control.type"
                            formControlName="serial_target_license_plate"
                            (displayTextChange)="fields.serial_target_license_plate.control.displayText=$event"
                            [placeholder]="fields.serial_target_license_plate.control.placeholder"
                            [styles]="fields.serial_target_license_plate.control.styles"
                            [tooltip]="fields.serial_target_license_plate.control.tooltip"
                          [locationId]="$fields_serial_target_license_plate_selector_inParams_locationId"
                          [typeId]="$fields_serial_target_license_plate_selector_inParams_typeId"
                          [archived]="$fields_serial_target_license_plate_selector_inParams_archived"
                        >
                        </Inventory-licenseplates_dd_single>
                        <ng-container *ngIf="fields.serial_target_license_plate.invalid">
                          <ng-container *ngFor="let error of fields.serial_target_license_plate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.missing_serial_numbers.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-license_plate"
                     *ngIf="!fieldsets.license_plate.hidden && !fieldsets.license_plate.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.license_plate.collapsible }">
                    <div *ngIf="!fieldsets.license_plate.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.license_plate.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.license_plate.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.license_plate.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.license_plate.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.license_plate.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.license_plate.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-license_plate_message" *ngIf="!fields.license_plate_message.hidden && !fields.license_plate_message.removed" 
                            class="field-container full {{fields.license_plate_message.invalid ? 'invalid' : ''}} {{fields.license_plate_message.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.license_plate_message.styles.style"
                            [ngClass]="fields.license_plate_message.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.license_plate_message.label + (fields.license_plate_message.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.license_plate_message.label}}<span *ngIf="fields.license_plate_message.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="license_plate_message"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.license_plate_message.control.readOnly || fields.license_plate_message.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.license_plate_message.control.placeholder}}"
                                [ngStyle]="fields.license_plate_message.control.styles.style"
                                [ngClass]="fields.license_plate_message.control.styles.classes"
                                [matTooltip]="fields.license_plate_message.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.license_plate_message.invalid">
                          <ng-container *ngFor="let error of fields.license_plate_message.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-change_license_plate_status" *ngIf="!fields.change_license_plate_status.hidden && !fields.change_license_plate_status.removed" 
                            class="field-container full {{fields.change_license_plate_status.invalid ? 'invalid' : ''}} {{fields.change_license_plate_status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.change_license_plate_status.styles.style"
                            [ngClass]="fields.change_license_plate_status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.change_license_plate_status.label + (fields.change_license_plate_status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.change_license_plate_status.label}}<span *ngIf="fields.change_license_plate_status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="change_license_plate_status"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.change_license_plate_status.control.styles.style"
                                      [ngClass]="fields.change_license_plate_status.control.styles.classes"
                                      [matTooltip]="fields.change_license_plate_status.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.change_license_plate_status.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.change_license_plate_status.invalid">
                          <ng-container *ngFor="let error of fields.change_license_plate_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-license_plate_status" *ngIf="!fields.license_plate_status.hidden && !fields.license_plate_status.removed" 
                            class="field-container standard {{fields.license_plate_status.invalid ? 'invalid' : ''}} {{fields.license_plate_status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.license_plate_status.styles.style"
                            [ngClass]="fields.license_plate_status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.license_plate_status.label + (fields.license_plate_status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.license_plate_status.label}}<span *ngIf="fields.license_plate_status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-licenseplate_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.license_plate_status.control.type"
                            formControlName="license_plate_status"
                            (displayTextChange)="fields.license_plate_status.control.displayText=$event"
                            [placeholder]="fields.license_plate_status.control.placeholder"
                            [styles]="fields.license_plate_status.control.styles"
                            [tooltip]="fields.license_plate_status.control.tooltip"
                        >
                        </Locations-licenseplate_statuses_dd_single>
                        <ng-container *ngIf="fields.license_plate_status.invalid">
                          <ng-container *ngFor="let error of fields.license_plate_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-move_license_plate" *ngIf="!fields.move_license_plate.hidden && !fields.move_license_plate.removed" 
                            class="field-container full {{fields.move_license_plate.invalid ? 'invalid' : ''}} {{fields.move_license_plate.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.move_license_plate.styles.style"
                            [ngClass]="fields.move_license_plate.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.move_license_plate.label + (fields.move_license_plate.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.move_license_plate.label}}<span *ngIf="fields.move_license_plate.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="move_license_plate"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.move_license_plate.control.styles.style"
                                      [ngClass]="fields.move_license_plate.control.styles.classes"
                                      [matTooltip]="fields.move_license_plate.control.tooltip"
                                      matTooltipClass="datex-control-tooltip">{{fields.move_license_plate.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.move_license_plate.invalid">
                          <ng-container *ngFor="let error of fields.move_license_plate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-license_plate_target_location" *ngIf="!fields.license_plate_target_location.hidden && !fields.license_plate_target_location.removed" 
                            class="field-container standard {{fields.license_plate_target_location.invalid ? 'invalid' : ''}} {{fields.license_plate_target_location.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.license_plate_target_location.styles.style"
                            [ngClass]="fields.license_plate_target_location.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.license_plate_target_location.label + (fields.license_plate_target_location.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.license_plate_target_location.label}}<span *ngIf="fields.license_plate_target_location.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.license_plate_target_location.control.type"
                            formControlName="license_plate_target_location"
                            (displayTextChange)="fields.license_plate_target_location.control.displayText=$event"
                            [placeholder]="fields.license_plate_target_location.control.placeholder"
                            [styles]="fields.license_plate_target_location.control.styles"
                            [tooltip]="fields.license_plate_target_location.control.tooltip"
                          [warehouseId]="$fields_license_plate_target_location_selector_inParams_warehouseId"
                          [typeId]="$fields_license_plate_target_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.license_plate_target_location.invalid">
                          <ng-container *ngFor="let error of fields.license_plate_target_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.license_plate.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>