import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Ultraship_ShellService } from './Ultraship.shell.service';
import { Ultraship_OperationService } from './Ultraship.operation.service';
import { Ultraship_DatasourceService } from './Ultraship.datasource.index';
import { Ultraship_FlowService } from './Ultraship.flow.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Ultraship_LocalizationService } from './Ultraship.localization.service';
import { Ultraship_project_configuration_add_form_ComponentContextService } from './Ultraship.project_configuration_add_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Ultraship.frontend.types'
import { $frontendTypes as $types} from './Ultraship.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Ultraship_owners_dd_singleComponent } from './Ultraship.owners_dd_single.component'
import { Ultraship_projects_dd_singleComponent } from './Ultraship.projects_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Ultraship_owners_dd_singleComponent),
    forwardRef(() => Ultraship_projects_dd_singleComponent),
  ],
  selector: 'Ultraship-project_configuration_add_form',
  templateUrl: './Ultraship.project_configuration_add_form.component.html'
})
export class Ultraship_project_configuration_add_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { application_name?: string, environment?: string } = { application_name: null, environment: null };
  //#region Inputs
  @Input('application_name') set $inParams_application_name(v: string) {
    this.inParams.application_name = v;
  }
  get $inParams_application_name(): string {
    return this.inParams.application_name;
  }
  @Input('environment') set $inParams_environment(v: string) {
    this.inParams.environment = v;
  }
  get $inParams_environment(): string {
    return this.inParams.environment;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { projectId?: number } = { projectId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ projectId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      Save: new ToolModel(new ButtonModel('Save', new ButtonStyles(['primary'], null), false, false, false, 'Save', 'icon-ic_fluent_save_20_regular', null)
    , false),
      discard: new ToolModel(new ButtonModel('discard', new ButtonStyles(['secondary'], null), false, false, false, 'Discard', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
  };

  fieldsets = {
    ownerProjectGroup: new FieldsetModel(
      'Confirm the owner/project to enable for integration',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.owner?.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Ultraship_ShellService,
private $datasources: Ultraship_DatasourceService,
private $flows: Ultraship_FlowService,
private $reports: Ultraship_ReportService,
private $localization: Ultraship_LocalizationService,
private $operations: Ultraship_OperationService,
private $logger: CleanupLoggerService,
private $context: Ultraship_project_configuration_add_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Project Configuration Creation Form';
  
    const $form = this;
    const $utils = this.$utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: Ultraship_project_configuration_add_formComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_project_configuration_add_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'project_configuration_add_form.on_owner_change');
  // Clear out the project selection
  $form.fields.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: Ultraship_project_configuration_add_formComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_project_configuration_add_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'project_configuration_add_form.on_project_change');
  const projectId = $form.fields.project.control.value
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($form.fields.owner.control.value)) {
      const project = (await $datasources.Ultraship.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
     
      if ($utils.isDefined(project)) {
          $form.fields.owner.control.value = project[0].OwnerId;
  
      };
  };
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: Ultraship_project_configuration_add_formComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_project_configuration_add_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'project_configuration_add_form.on_init');
  
  }
  on_discard_clicked(event = null) {
    return this.on_discard_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_discard_clickedInternal(
    $form: Ultraship_project_configuration_add_formComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_project_configuration_add_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'project_configuration_add_form.on_discard_clicked');
  $form.close();
  }
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $form: Ultraship_project_configuration_add_formComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_project_configuration_add_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'project_configuration_add_form.on_save_clicked');
  let instanceInfo = await $flows.Ultraship.get_instance_information({})
  
  let applicationName = $form.inParams.application_name?.toUpperCase();
  if($utils.isDefined(applicationName) === false) {
      applicationName = instanceInfo.application_name
  }
  
  let environment = $form.inParams.environment?.toUpperCase();
  if($utils.isDefined(environment) === false) {
      environment = `TEST`;
  }
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.project.control.value,
  );
  
  if (!allRequiredFieldHaveValue) {
      return;
  }
  
  // Reconfirm project and then save to the configuration table
  if ($utils.isDefined($form.fields.project.control.value)) {
  
      const project = (await $datasources.Ultraship.ds_get_project_by_projectId.get({
          projectId: $form.fields.project.control.value
      })).result;
  
      if ($utils.isDefined(project)) {
  
          // Check if the configuration is already there
          var projectConfigs = await $flows.Utilities.configurations_get({
              criteria: {
                  application_name: applicationName
              }
          });
  
          if ($utils.isDefined(projectConfigs) && projectConfigs.count > 0) {
  
              const matchList = projectConfigs.output.filter(p => p.name === $types.Ultraship.Configuration.ultraship_projects
                  && p.value === project[0].Id.toString()
                  && p.string_1 === environment
                  && p.string_2 === $types.Ultraship.ConfigurationType.Projects)
  
              if ($utils.isDefined(matchList) && matchList.length > 0) {
                  $shell.Utilities.openErrorDialog("Project Configuration", `Transaction is cancelled because the configuration exists.`)
                  return;
              }
  
               // Insert configuration
              await $flows.Utilities.configurations_write({
                  input: [{
                      application_name: applicationName,
                      name: $types.Ultraship.Configuration.ultraship_projects,
                      value: project[0].Id.toString(),
                      string_1: environment,
                      string_2: $types.Ultraship.ConfigurationType.Projects
                  }],
                  criteria: { application_name: applicationName }
              });
  
              $form.outParams.projectId = project[0].Id;
  
              $shell.Utilities.openConfirmationDialog("Project Configuration", `Successfully added the project with lookupcode [${project[0].LookupCode}] to the configuration.`)
  
              $form.fields.owner.control.value = null;
              $form.fields.project.control.value = null;
  
          } // End Configurations check
          else {
              // Insert configuration
              await $flows.Utilities.configurations_write({
                  input: [{
                      application_name: applicationName,
                      name: $types.Ultraship.Configuration.ultraship_projects,
                      value: project[0].Id.toString(),
                      string_1: environment,
                      string_2: $types.Ultraship.ConfigurationType.Projects
                  }],
                  criteria: { application_name: applicationName }
              });
  
              $form.outParams.projectId = project[0].Id;
  
              $shell.Utilities.openConfirmationDialog("Project Configuration", `Successfully added the project with lookupcode [${project[0].LookupCode}] to the configuration.`)
  
              $form.fields.owner.control.value = null;
              $form.fields.project.control.value = null;
          }
  
      } // End Project Check
  
  };
  
  }
  //#endregion private flows
}
