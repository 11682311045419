import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_ReportService } from './Document360.report.index';
import { Usersnap_ReportService } from './Usersnap.report.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Addresses_ReportService } from './Addresses.report.index';
import { Cartonization_ReportService } from './Cartonization.report.index';
import { Discussions_ReportService } from './Discussions.report.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { ExcelInventoryImport_ReportService } from './ExcelInventoryImport.report.index';
import { Owners_ReportService } from './Owners.report.index';
import { PalletTransactions_ReportService } from './PalletTransactions.report.index';
import { Surveys_ReportService } from './Surveys.report.index';
import { TransloadOrders_ReportService } from './TransloadOrders.report.index';
import { Waves_ReportService } from './Waves.report.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Materials_ReportService } from './Materials.report.index';
import { Carriers_ReportService } from './Carriers.report.index';
import { Locations_ReportService } from './Locations.report.index';
import { PurchaseOrders_ReportService } from './PurchaseOrders.report.index';
import { SalesOrders_ReportService } from './SalesOrders.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { Lots_ReportService } from './Lots.report.index';
import { ExcelOrderImport_ReportService } from './ExcelOrderImport.report.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { Reports_ReportService } from './Reports.report.index';
import { AsnOrders_ReportService } from './AsnOrders.report.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';

import { app_custom_invoice_report_sales_invoiceService } from './app.report.index';

@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_ReportService);
    this.Usersnap = this.injector.get(Usersnap_ReportService);
    this.Utilities = this.injector.get(Utilities_ReportService);
    this.Addresses = this.injector.get(Addresses_ReportService);
    this.Cartonization = this.injector.get(Cartonization_ReportService);
    this.Discussions = this.injector.get(Discussions_ReportService);
    this.DockAppointments = this.injector.get(DockAppointments_ReportService);
    this.Dynamics365BusinessCentral = this.injector.get(Dynamics365BusinessCentral_ReportService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_ReportService);
    this.Owners = this.injector.get(Owners_ReportService);
    this.PalletTransactions = this.injector.get(PalletTransactions_ReportService);
    this.Surveys = this.injector.get(Surveys_ReportService);
    this.TransloadOrders = this.injector.get(TransloadOrders_ReportService);
    this.Waves = this.injector.get(Waves_ReportService);
    this.Notifications = this.injector.get(Notifications_ReportService);
    this.Invoices = this.injector.get(Invoices_ReportService);
    this.Ultraship = this.injector.get(Ultraship_ReportService);
    this.Materials = this.injector.get(Materials_ReportService);
    this.Carriers = this.injector.get(Carriers_ReportService);
    this.Locations = this.injector.get(Locations_ReportService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_ReportService);
    this.SalesOrders = this.injector.get(SalesOrders_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
    this.Lots = this.injector.get(Lots_ReportService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_ReportService);
    this.Inventory = this.injector.get(Inventory_ReportService);
    this.Reports = this.injector.get(Reports_ReportService);
    this.AsnOrders = this.injector.get(AsnOrders_ReportService);
    this.FootprintManager = this.injector.get(FootprintManager_ReportService);
  }
    public Document360: Document360_ReportService;
    public Usersnap: Usersnap_ReportService;
    public Utilities: Utilities_ReportService;
    public Addresses: Addresses_ReportService;
    public Cartonization: Cartonization_ReportService;
    public Discussions: Discussions_ReportService;
    public DockAppointments: DockAppointments_ReportService;
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_ReportService;
    public ExcelInventoryImport: ExcelInventoryImport_ReportService;
    public Owners: Owners_ReportService;
    public PalletTransactions: PalletTransactions_ReportService;
    public Surveys: Surveys_ReportService;
    public TransloadOrders: TransloadOrders_ReportService;
    public Waves: Waves_ReportService;
    public Notifications: Notifications_ReportService;
    public Invoices: Invoices_ReportService;
    public Ultraship: Ultraship_ReportService;
    public Materials: Materials_ReportService;
    public Carriers: Carriers_ReportService;
    public Locations: Locations_ReportService;
    public PurchaseOrders: PurchaseOrders_ReportService;
    public SalesOrders: SalesOrders_ReportService;
    public PrintNode: PrintNode_ReportService;
    public Lots: Lots_ReportService;
    public ExcelOrderImport: ExcelOrderImport_ReportService;
    public Inventory: Inventory_ReportService;
    public Reports: Reports_ReportService;
    public AsnOrders: AsnOrders_ReportService;
    public FootprintManager: FootprintManager_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_invoice_report_sales_invoice: app_custom_invoice_report_sales_invoiceService;
  public get custom_invoice_report_sales_invoice(): app_custom_invoice_report_sales_invoiceService {
    if(!this._custom_invoice_report_sales_invoice) {
      this._custom_invoice_report_sales_invoice = this.injector.get(app_custom_invoice_report_sales_invoiceService);
    }
    return this._custom_invoice_report_sales_invoice;
  }
}

