<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="component">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.component.displayControl.styles.style"
          [ngClass]="row.cells.component.displayControl.styles.classes"
          [matTooltip]="row.cells.component.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.component.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="consumed_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.consumed_quantity.displayControl.styles.style"
          [ngClass]="row.cells.consumed_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.consumed_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.consumed_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="scrapped_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.scrapped_quantity.displayControl.styles.style"
          [ngClass]="row.cells.scrapped_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.scrapped_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.scrapped_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="remaining_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.remaining_quantity.displayControl.styles.style"
          [ngClass]="row.cells.remaining_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.remaining_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.remaining_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_license_plate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.source_license_plate.displayControl.styles.style"
          [ngClass]="row.cells.source_license_plate.displayControl.styles.classes"
          [matTooltip]="row.cells.source_license_plate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.source_license_plate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_expiration">

    <ng-template gridCellDisplayControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['lot_expiration_display']"
        [format]="row.cells.lot_expiration.displayControl.format"
        [mode]="row.cells.lot_expiration.displayControl.mode"
        [ngStyle]="row.cells.lot_expiration.displayControl.styles.style"
        [ngClass]="row.cells.lot_expiration.displayControl.styles.classes"
        [tooltip]="row.cells.lot_expiration.displayControl.tooltip">
    </app-datebox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
