import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  forwardRef
} from '@angular/core';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatexFormControl } from './models/datex-form-control';
import { ListItemModel, ELoadingStatus } from './components/list.component';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_licenseplates_by_shipping_container_list_ComponentContextService } from './FootprintManager.licenseplates_by_shipping_container_list.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootprintManager_licenseplate_with_content_cardComponent } from './FootprintManager.licenseplate_with_content_card.component';

interface IFootprintManager_licenseplates_by_shipping_container_listComponentEntity {
Id?: number, GrossWeight?: number, LookupCode?: string}

class FootprintManager_licenseplates_by_shipping_container_listComponentItemModel extends ListItemModel {
  list: FootprintManager_licenseplates_by_shipping_container_listComponent;
  entity: IFootprintManager_licenseplates_by_shipping_container_listComponentEntity;



  get $content_FootprintManager_licenseplate_with_content_card_inParams_licenseplateId(): number {
    if (!this.entity) return null; 
    const $item = this;
    const $utils = this.$utils;
    const $list = this.list;
    const expr = $item.entity.Id;
    
    return expr;
  }

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_licenseplates_by_shipping_container_list_ComponentContextService,
   ) {
    super();
  }

  private $unsubscribe$ = new Subject();
  destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $initializeExisting(list: FootprintManager_licenseplates_by_shipping_container_listComponent, entity: IFootprintManager_licenseplates_by_shipping_container_listComponentEntity) {
    this.list = list;
    this.entity = entity;

    this.styles = new Styles(
      ['small-card'], 
      { 
        width: '100%', 
        height: '350px' 
      }
    );
  }
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_licenseplate_with_content_cardComponent)
  ],
  selector: 'FootprintManager-licenseplates_by_shipping_container_list',
  templateUrl: './FootprintManager.licenseplates_by_shipping_container_list.component.html'
})
export class FootprintManager_licenseplates_by_shipping_container_listComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { shippingContainerId: number } = { shippingContainerId: null };
  //#region Inputs
  @Input('shippingContainerId') set $inParams_shippingContainerId(v: number) {
    this.inParams.shippingContainerId = v;
  }
  get $inParams_shippingContainerId(): number {
    return this.inParams.shippingContainerId;
  }
  //#endregion Inputs

  //#region Outputs
  //#endregion
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title



  entities: IFootprintManager_licenseplates_by_shipping_container_listComponentEntity[] = [];
  pageSize = 40;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus: ELoadingStatus = ELoadingStatus.Loading;

  items: FootprintManager_licenseplates_by_shipping_container_listComponentItemModel[] = [];
  

  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_licenseplates_by_shipping_container_list_ComponentContextService,
) { 
    super();
    this.title = 'License plates by shipping container';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shippingContainerId)) {
        this.$missingRequiredInParams.push('shippingContainerId');
      }
  }

  initialized = false;

  async $init() {

    const $list = this;
    const $utils = this.$utils;



    this.initialized = true;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.refresh();
  }
 
  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    this.$reset();
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    
  }

  $reset() {
    this.pageSkip = 0;
    this.entities = [];
    this.items.forEach(i => i.destroy());
    this.items = [];
  }


  $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $list = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      shippingContainerId:  $list.inParams.shippingContainerId ,
    };
    this.$datasources.PackVerification.ds_get_licenseplates_by_shippingContainerId.getList(inParams)
    .then(data => {
      this.entities.push(...data.result);
      this.totalCount = data.totalCount;
      this.$dataLoaded(data.result);
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    })
    .catch(error => {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    });
  }

  async $dataLoaded(data: IFootprintManager_licenseplates_by_shipping_container_listComponentEntity[]) {
    for (let entity of data) {
      const item = new FootprintManager_licenseplates_by_shipping_container_listComponentItemModel(
        this.$utils,
        this.$settings,
        this.$shell, 
        this.$datasources,
        this.$flows,
        this.$reports,
        this.$localization,
        this.$operations,
        this.$logger,
        this.$context);
      item.$initializeExisting(this, entity);
      this.items.push(item);
    } 
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  

  close() {
    this.$finish.emit();
  }
}
