import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_orders_hub_ComponentContextService } from './FootprintManager.manufacturing_orders_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_manufacturing_orders_gridComponent } from './FootprintManager.manufacturing_orders_grid.component';
import { FootprintManager_manufacturing_orders_calendarComponent } from './FootprintManager.manufacturing_orders_calendar.component';
import { FootprintManager_plans_gridComponent } from './FootprintManager.plans_grid.component';
import { Manufacturing_pending_confirmation_widgetComponent } from './Manufacturing.pending_confirmation_widget.component';
import { FootprintManager_scheduled_for_today_widgetComponent } from './FootprintManager.scheduled_for_today_widget.component';
import { Manufacturing_owners_dd_singleComponent } from './Manufacturing.owners_dd_single.component'
import { Manufacturing_projects_dd_singleComponent } from './Manufacturing.projects_dd_single.component'
import { Manufacturing_warehouses_dd_multiComponent } from './Manufacturing.warehouses_dd_multi.component'
import { Manufacturing_order_statuses_dd_multiComponent } from './Manufacturing.order_statuses_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_manufacturing_orders_gridComponent),
    forwardRef(() => FootprintManager_manufacturing_orders_calendarComponent),
    forwardRef(() => FootprintManager_plans_gridComponent),
    forwardRef(() => Manufacturing_pending_confirmation_widgetComponent),
    forwardRef(() => FootprintManager_scheduled_for_today_widgetComponent),
    forwardRef(() => Manufacturing_owners_dd_singleComponent),
    forwardRef(() => Manufacturing_projects_dd_singleComponent),
    forwardRef(() => Manufacturing_warehouses_dd_multiComponent),
    forwardRef(() => Manufacturing_order_statuses_dd_multiComponent),
  ],
  selector: 'FootprintManager-manufacturing_orders_hub',
  templateUrl: './FootprintManager.manufacturing_orders_hub.component.html'
})
export class FootprintManager_manufacturing_orders_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    customer: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    warehouses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    statuses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      new_order: new ToolModel(new ButtonModel('new_order', new ButtonStyles(['primary'], null), false, false, false, 'New manufacturing order', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    customer: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['customer'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Customer', false, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    warehouses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouses'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    statuses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['statuses'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Status', false, false),
  };


  filtersets = {
    filters: new FieldsetModel(
      'Filters',
      false,
      true,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        false,
        ),
      calendar: new TabItemModel(
        this.rootTabGroup, 
        'Calendar', 
        false,
        ),
      plans: new TabItemModel(
        this.rootTabGroup, 
        'Plans', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_orders_manufacturing_orders_grid_inParams_warehouse_ids: number[];
    get $tabs_orders_manufacturing_orders_grid_inParams_warehouse_ids(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_orders_manufacturing_orders_grid_inParams_warehouse_ids, expr)) {
        this.cacheValueFor_$tabs_orders_manufacturing_orders_grid_inParams_warehouse_ids = expr;
      }
      return this.cacheValueFor_$tabs_orders_manufacturing_orders_grid_inParams_warehouse_ids;
    }
  
    get $tabs_orders_manufacturing_orders_grid_inParams_owner_id(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.customer.control.value;
      
      return expr;
    }
  
    get $tabs_orders_manufacturing_orders_grid_inParams_project_id(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_orders_manufacturing_orders_grid_inParams_status_ids: number[];
    get $tabs_orders_manufacturing_orders_grid_inParams_status_ids(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.statuses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_orders_manufacturing_orders_grid_inParams_status_ids, expr)) {
        this.cacheValueFor_$tabs_orders_manufacturing_orders_grid_inParams_status_ids = expr;
      }
      return this.cacheValueFor_$tabs_orders_manufacturing_orders_grid_inParams_status_ids;
    }
  
    cacheValueFor_$tabs_calendar_manufacturing_orders_calendar_inParams_warehouse_ids: number[];
    get $tabs_calendar_manufacturing_orders_calendar_inParams_warehouse_ids(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_calendar_manufacturing_orders_calendar_inParams_warehouse_ids, expr)) {
        this.cacheValueFor_$tabs_calendar_manufacturing_orders_calendar_inParams_warehouse_ids = expr;
      }
      return this.cacheValueFor_$tabs_calendar_manufacturing_orders_calendar_inParams_warehouse_ids;
    }
  
    get $tabs_calendar_manufacturing_orders_calendar_inParams_project_id(): number {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_orders', { read: FootprintManager_manufacturing_orders_gridComponent }) $tabs_orders: FootprintManager_manufacturing_orders_gridComponent;
      @ViewChild('$tabs_calendar', { read: FootprintManager_manufacturing_orders_calendarComponent }) $tabs_calendar: FootprintManager_manufacturing_orders_calendarComponent;
      @ViewChild('$tabs_plans', { read: FootprintManager_plans_gridComponent }) $tabs_plans: FootprintManager_plans_gridComponent;
    //#endregion tabs children
    widgets = {
      pending_confirmation_widget: new WidgetModel(false),
      scheduled_for_today_widget: new WidgetModel(false),
    };
  
    //#region widgets inParams
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_pending_confirmation_widget', { read:  Manufacturing_pending_confirmation_widgetComponent }) $widgets_pending_confirmation_widget: Manufacturing_pending_confirmation_widgetComponent;
      @ViewChild('$widgets_scheduled_for_today_widget', { read:  FootprintManager_scheduled_for_today_widgetComponent }) $widgets_scheduled_for_today_widget: FootprintManager_scheduled_for_today_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  get $fields_customer_selector_inParams_project_id(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Manufacturing orders`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Create, plan, and manage your manufacturing orders`;
  }

  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: FootprintManager_ShellService,
  private $datasources: FootprintManager_DatasourceService,
  private $flows: FootprintManager_FlowService,
  private $reports: FootprintManager_ReportService,
  private $localization: FootprintManager_LocalizationService,
  private $operations: FootprintManager_OperationService,
  private $logger: CleanupLoggerService,
  private $context: FootprintManager_manufacturing_orders_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.orders,
      this.tabs.calendar,
      this.tabs.plans,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Manufacturing orders hub';
    
    const $hub = this;
    const $utils = this.$utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['customer']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.changed_customer();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_pending_confirmation_widget') {
      if (!isNil(this.$widgets_pending_confirmation_widget) && !this.widgets.pending_confirmation_widget.hidden) {
        this.$widgets_pending_confirmation_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_scheduled_for_today_widget') {
      if (!isNil(this.$widgets_scheduled_for_today_widget) && !this.widgets.scheduled_for_today_widget.hidden) {
        this.$widgets_scheduled_for_today_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_orders') {
      if (!isNil(this.$tabs_orders) && !this.tabs.orders.hidden) {
        this.$tabs_orders.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_calendar') {
      if (!isNil(this.$tabs_calendar) && !this.tabs.calendar.hidden) {
        this.$tabs_calendar.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_plans') {
      if (!isNil(this.$tabs_plans) && !this.tabs.plans.hidden) {
        this.$tabs_plans.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_new_order(event = null) {
    return this.on_new_orderInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_orderInternal(
    $hub: FootprintManager_manufacturing_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_orders_hub.on_new_order');
  $shell.FootprintManager.openmanufacturing_order_form();
  }
  changed_customer(event = null) {
    return this.changed_customerInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async changed_customerInternal(
    $hub: FootprintManager_manufacturing_orders_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_orders_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_orders_hub.changed_customer');
  $hub.filters.project.control.value = null;
  }
  //#endregion private flows
}
