<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel_button" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-stringField1" *ngIf="!fields.stringField1.hidden && !fields.stringField1.removed" 
                            class="field-container standard {{fields.stringField1.invalid ? 'invalid' : ''}} {{fields.stringField1.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.stringField1.styles.style"
                            [ngClass]="fields.stringField1.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.stringField1.label + (fields.stringField1.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.stringField1.label}}<span *ngIf="fields.stringField1.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="stringField1"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.stringField1.control.readOnly || fields.stringField1.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.stringField1.control.placeholder}}"
                                [ngStyle]="fields.stringField1.control.styles.style"
                                [ngClass]="fields.stringField1.control.styles.classes"
                                [matTooltip]="fields.stringField1.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.stringField1.invalid">
                          <ng-container *ngFor="let error of fields.stringField1.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-stringField2" *ngIf="!fields.stringField2.hidden && !fields.stringField2.removed" 
                            class="field-container standard {{fields.stringField2.invalid ? 'invalid' : ''}} {{fields.stringField2.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.stringField2.styles.style"
                            [ngClass]="fields.stringField2.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.stringField2.label + (fields.stringField2.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.stringField2.label}}<span *ngIf="fields.stringField2.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="stringField2"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.stringField2.control.readOnly || fields.stringField2.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.stringField2.control.placeholder}}"
                                [ngStyle]="fields.stringField2.control.styles.style"
                                [ngClass]="fields.stringField2.control.styles.classes"
                                [matTooltip]="fields.stringField2.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.stringField2.invalid">
                          <ng-container *ngFor="let error of fields.stringField2.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-stringField3" *ngIf="!fields.stringField3.hidden && !fields.stringField3.removed" 
                            class="field-container standard {{fields.stringField3.invalid ? 'invalid' : ''}} {{fields.stringField3.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.stringField3.styles.style"
                            [ngClass]="fields.stringField3.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.stringField3.label + (fields.stringField3.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.stringField3.label}}<span *ngIf="fields.stringField3.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="stringField3"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.stringField3.control.readOnly || fields.stringField3.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.stringField3.control.placeholder}}"
                                [ngStyle]="fields.stringField3.control.styles.style"
                                [ngClass]="fields.stringField3.control.styles.classes"
                                [matTooltip]="fields.stringField3.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.stringField3.invalid">
                          <ng-container *ngFor="let error of fields.stringField3.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-stringField4" *ngIf="!fields.stringField4.hidden && !fields.stringField4.removed" 
                            class="field-container standard {{fields.stringField4.invalid ? 'invalid' : ''}} {{fields.stringField4.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.stringField4.styles.style"
                            [ngClass]="fields.stringField4.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.stringField4.label + (fields.stringField4.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.stringField4.label}}<span *ngIf="fields.stringField4.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="stringField4"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.stringField4.control.readOnly || fields.stringField4.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.stringField4.control.placeholder}}"
                                [ngStyle]="fields.stringField4.control.styles.style"
                                [ngClass]="fields.stringField4.control.styles.classes"
                                [matTooltip]="fields.stringField4.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.stringField4.invalid">
                          <ng-container *ngFor="let error of fields.stringField4.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-stringField5" *ngIf="!fields.stringField5.hidden && !fields.stringField5.removed" 
                            class="field-container standard {{fields.stringField5.invalid ? 'invalid' : ''}} {{fields.stringField5.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.stringField5.styles.style"
                            [ngClass]="fields.stringField5.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.stringField5.label + (fields.stringField5.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.stringField5.label}}<span *ngIf="fields.stringField5.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="stringField5"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.stringField5.control.readOnly || fields.stringField5.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.stringField5.control.placeholder}}"
                                [ngStyle]="fields.stringField5.control.styles.style"
                                [ngClass]="fields.stringField5.control.styles.classes"
                                [matTooltip]="fields.stringField5.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.stringField5.invalid">
                          <ng-container *ngFor="let error of fields.stringField5.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dateField1" *ngIf="!fields.dateField1.hidden && !fields.dateField1.removed" 
                            class="field-container standard {{fields.dateField1.invalid ? 'invalid' : ''}} {{fields.dateField1.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.dateField1.styles.style"
                            [ngClass]="fields.dateField1.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dateField1.label + (fields.dateField1.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dateField1.label}}<span *ngIf="fields.dateField1.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="dateField1"
                            [format]="fields.dateField1.control.format"
                            [mode]="fields.dateField1.control.mode"
                            [ngStyle]="fields.dateField1.control.styles.style"
                            [ngClass]="fields.dateField1.control.styles.classes"
                            [tooltip]="fields.dateField1.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.dateField1.invalid">
                          <ng-container *ngFor="let error of fields.dateField1.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dateField2" *ngIf="!fields.dateField2.hidden && !fields.dateField2.removed" 
                            class="field-container standard {{fields.dateField2.invalid ? 'invalid' : ''}} {{fields.dateField2.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.dateField2.styles.style"
                            [ngClass]="fields.dateField2.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dateField2.label + (fields.dateField2.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dateField2.label}}<span *ngIf="fields.dateField2.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="dateField2"
                            [format]="fields.dateField2.control.format"
                            [mode]="fields.dateField2.control.mode"
                            [ngStyle]="fields.dateField2.control.styles.style"
                            [ngClass]="fields.dateField2.control.styles.classes"
                            [tooltip]="fields.dateField2.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.dateField2.invalid">
                          <ng-container *ngFor="let error of fields.dateField2.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dateField3" *ngIf="!fields.dateField3.hidden && !fields.dateField3.removed" 
                            class="field-container standard {{fields.dateField3.invalid ? 'invalid' : ''}} {{fields.dateField3.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.dateField3.styles.style"
                            [ngClass]="fields.dateField3.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dateField3.label + (fields.dateField3.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dateField3.label}}<span *ngIf="fields.dateField3.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="dateField3"
                            [format]="fields.dateField3.control.format"
                            [mode]="fields.dateField3.control.mode"
                            [ngStyle]="fields.dateField3.control.styles.style"
                            [ngClass]="fields.dateField3.control.styles.classes"
                            [tooltip]="fields.dateField3.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.dateField3.invalid">
                          <ng-container *ngFor="let error of fields.dateField3.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dateField4" *ngIf="!fields.dateField4.hidden && !fields.dateField4.removed" 
                            class="field-container standard {{fields.dateField4.invalid ? 'invalid' : ''}} {{fields.dateField4.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.dateField4.styles.style"
                            [ngClass]="fields.dateField4.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dateField4.label + (fields.dateField4.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dateField4.label}}<span *ngIf="fields.dateField4.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="dateField4"
                            [format]="fields.dateField4.control.format"
                            [mode]="fields.dateField4.control.mode"
                            [ngStyle]="fields.dateField4.control.styles.style"
                            [ngClass]="fields.dateField4.control.styles.classes"
                            [tooltip]="fields.dateField4.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.dateField4.invalid">
                          <ng-container *ngFor="let error of fields.dateField4.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dateField5" *ngIf="!fields.dateField5.hidden && !fields.dateField5.removed" 
                            class="field-container standard {{fields.dateField5.invalid ? 'invalid' : ''}} {{fields.dateField5.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.dateField5.styles.style"
                            [ngClass]="fields.dateField5.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dateField5.label + (fields.dateField5.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dateField5.label}}<span *ngIf="fields.dateField5.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="dateField5"
                            [format]="fields.dateField5.control.format"
                            [mode]="fields.dateField5.control.mode"
                            [ngStyle]="fields.dateField5.control.styles.style"
                            [ngClass]="fields.dateField5.control.styles.classes"
                            [tooltip]="fields.dateField5.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.dateField5.invalid">
                          <ng-container *ngFor="let error of fields.dateField5.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-numberField1" *ngIf="!fields.numberField1.hidden && !fields.numberField1.removed" 
                            class="field-container standard {{fields.numberField1.invalid ? 'invalid' : ''}} {{fields.numberField1.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.numberField1.styles.style"
                            [ngClass]="fields.numberField1.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.numberField1.label + (fields.numberField1.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.numberField1.label}}<span *ngIf="fields.numberField1.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="numberField1"
                                matInput
                                numberBox
                                [format]="fields.numberField1.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.numberField1.control.readOnly || fields.numberField1.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.numberField1.control.placeholder}}"
                                [ngStyle]="fields.numberField1.control.styles.style"
                                [ngClass]="fields.numberField1.control.styles.classes"
                                [matTooltip]="fields.numberField1.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.numberField1.invalid">
                          <ng-container *ngFor="let error of fields.numberField1.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-numberField2" *ngIf="!fields.numberField2.hidden && !fields.numberField2.removed" 
                            class="field-container standard {{fields.numberField2.invalid ? 'invalid' : ''}} {{fields.numberField2.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.numberField2.styles.style"
                            [ngClass]="fields.numberField2.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.numberField2.label + (fields.numberField2.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.numberField2.label}}<span *ngIf="fields.numberField2.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="numberField2"
                                matInput
                                numberBox
                                [format]="fields.numberField2.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.numberField2.control.readOnly || fields.numberField2.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.numberField2.control.placeholder}}"
                                [ngStyle]="fields.numberField2.control.styles.style"
                                [ngClass]="fields.numberField2.control.styles.classes"
                                [matTooltip]="fields.numberField2.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.numberField2.invalid">
                          <ng-container *ngFor="let error of fields.numberField2.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-numberField3" *ngIf="!fields.numberField3.hidden && !fields.numberField3.removed" 
                            class="field-container standard {{fields.numberField3.invalid ? 'invalid' : ''}} {{fields.numberField3.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.numberField3.styles.style"
                            [ngClass]="fields.numberField3.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.numberField3.label + (fields.numberField3.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.numberField3.label}}<span *ngIf="fields.numberField3.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="numberField3"
                                matInput
                                numberBox
                                [format]="fields.numberField3.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.numberField3.control.readOnly || fields.numberField3.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.numberField3.control.placeholder}}"
                                [ngStyle]="fields.numberField3.control.styles.style"
                                [ngClass]="fields.numberField3.control.styles.classes"
                                [matTooltip]="fields.numberField3.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.numberField3.invalid">
                          <ng-container *ngFor="let error of fields.numberField3.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-numberField4" *ngIf="!fields.numberField4.hidden && !fields.numberField4.removed" 
                            class="field-container standard {{fields.numberField4.invalid ? 'invalid' : ''}} {{fields.numberField4.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.numberField4.styles.style"
                            [ngClass]="fields.numberField4.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.numberField4.label + (fields.numberField4.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.numberField4.label}}<span *ngIf="fields.numberField4.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="numberField4"
                                matInput
                                numberBox
                                [format]="fields.numberField4.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.numberField4.control.readOnly || fields.numberField4.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.numberField4.control.placeholder}}"
                                [ngStyle]="fields.numberField4.control.styles.style"
                                [ngClass]="fields.numberField4.control.styles.classes"
                                [matTooltip]="fields.numberField4.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.numberField4.invalid">
                          <ng-container *ngFor="let error of fields.numberField4.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-numberField5" *ngIf="!fields.numberField5.hidden && !fields.numberField5.removed" 
                            class="field-container standard {{fields.numberField5.invalid ? 'invalid' : ''}} {{fields.numberField5.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.numberField5.styles.style"
                            [ngClass]="fields.numberField5.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.numberField5.label + (fields.numberField5.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.numberField5.label}}<span *ngIf="fields.numberField5.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="numberField5"
                                matInput
                                numberBox
                                [format]="fields.numberField5.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{ (fields.numberField5.control.readOnly || fields.numberField5.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.numberField5.control.placeholder}}"
                                [ngStyle]="fields.numberField5.control.styles.style"
                                [ngClass]="fields.numberField5.control.styles.classes"
                                [matTooltip]="fields.numberField5.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.numberField5.invalid">
                          <ng-container *ngFor="let error of fields.numberField5.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>