import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_ds_get_materials_by_lookupcodesService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lookupcodes: string[], projects?: string[], owners?: string[] }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, ProjectId?: number, PackagingLookups?: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, Length?: number, ShippingVolume?: number, ShippingWeight?: number, SubPackagingId?: number, SubPackagingQuantity?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, ShortName?: string } }[], VendorLots?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string, Lots?: { Id?: number, LookupCode?: string, ReceiveDate?: string, StatusId?: number, TypeId?: number }[] }[], Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lookupcodes)) {
      missingRequiredInParams.push('\'lookupcodes\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_get_materials_by_lookupcodes/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { lookupcodes: string[], projects?: string[], owners?: string[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, ProjectId?: number, PackagingLookups?: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, Length?: number, ShippingVolume?: number, ShippingWeight?: number, SubPackagingId?: number, SubPackagingQuantity?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, ShortName?: string } }[], VendorLots?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string, Lots?: { Id?: number, LookupCode?: string, ReceiveDate?: string, StatusId?: number, TypeId?: number }[] }[], Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lookupcodes)) {
      missingRequiredInParams.push('\'lookupcodes\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_get_materials_by_lookupcodes/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { lookupcodes: string[], projects?: string[], owners?: string[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ControllerTypeId?: number, IsFixedWeight?: boolean, LookupCode?: string, ProjectId?: number, PackagingLookups?: { MaterialId?: number, PackagingId?: number, AutogenerateSerialNumbers?: boolean, DimensionUomId?: number, Height?: number, Length?: number, ShippingVolume?: number, ShippingWeight?: number, SubPackagingId?: number, SubPackagingQuantity?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number, Width?: number, Packaging?: { Id?: number, ShortName?: string } }[], VendorLots?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string, Lots?: { Id?: number, LookupCode?: string, ReceiveDate?: string, StatusId?: number, TypeId?: number }[] }[], Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lookupcodes)) {
      missingRequiredInParams.push('\'lookupcodes\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/datasources/ds_get_materials_by_lookupcodes/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      lookupcodes: string[], projects?: string[], owners?: string[]    }) {
        if(isNil(inParams.lookupcodes)) {
          return true; 
        }
      return false;
    }
}
