import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService } from './FootprintManager.inventory_transformation_source_lines_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootprintManager_inventory_transformation_target_lines_gridComponent } from './FootprintManager.inventory_transformation_target_lines_grid.component';

interface IFootprintManager_inventory_transformation_source_lines_gridComponentEntity {
  Id?: number, ErrorMessage?: string, InventoryTransformationId?: number, SourcePackageAmount?: number, SourceSerialNumberId?: number, InventoryTransformationLineStatus?: { Id?: number, Name?: string }, Material?: { Id?: number, LookupCode?: string }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } }, Lot?: { Id?: number, LookupCode?: string, StatusId?: number, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, Packaging?: { Id?: number, Name?: string, ShortName?: string }, InventoryTransformation?: { StatusId?: number }}

interface IFootprintManager_inventory_transformation_source_lines_gridComponentInParams {
  inventory_transformation_id: number, line_number?: number, project_id: number, warehouse_id: number, inventory_transformation_status_id?: number, source_read_only?: boolean, target_read_only?: boolean}


class FootprintManager_inventory_transformation_source_lines_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_inventory_transformation_source_lines_gridComponent;
  entity: IFootprintManager_inventory_transformation_source_lines_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    error_message: null,
    status: null,
    source_material: null,
    licenseplate: null,
    location: null,
    lot: null,
    vendor_lot: null,
    packaging: null,
    quantity: null,
  }


  //#region rowExpand inParams


  get $rowExpand_FootprintManager_inventory_transformation_target_lines_grid_inParams_inventory_transformation_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.inventory_transformation_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_inventory_transformation_target_lines_grid_inParams_source_line_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_inventory_transformation_target_lines_grid_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouse_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_inventory_transformation_target_lines_grid_inParams_is_read_only(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.target_read_only;
    
    return expr;
  }


  get $rowExpand_FootprintManager_inventory_transformation_target_lines_grid_inParams_inventory_transformation_status_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.inventory_transformation_status_id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    grid: FootprintManager_inventory_transformation_source_lines_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.error_message = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.error_message,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.source_material = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.source_material,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.licenseplate = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.licenseplate,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.location,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.vendor_lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.vendor_lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.packaging = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.packaging,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_inventory_transformation_source_lines_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_inventory_transformation_source_lines_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      inventory_transformation_id:  $grid.inParams.inventory_transformation_id ,
      line_number:  $grid.inParams.line_number ,
    };
    const data = await this.$datasources.InventoryTransformations.ds_inventory_transformation_source_lines_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_inventory_transformation_source_lines_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.error_message.displayControl as TextModel).text = $row?.entity?.ErrorMessage;
    (this.cells.status.displayControl as TextModel).text = $row.entity.InventoryTransformationLineStatus?.Name;
    (this.cells.source_material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity.LicensePlate?.LookupCode;
    (this.cells.location.displayControl as TextModel).text = $row.entity.LicensePlate?.Location?.Name;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.vendor_lot.displayControl as TextModel).text = $row.entity.Lot?.VendorLot?.LookupCode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Packaging?.Name;
    (this.cells.quantity.displayControl as TextModel).text = $row.entity.SourcePackageAmount?.toString();

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      this.isNew = false;
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'source_material' : {
        this.on_material_clicked();
        break;
      }
      case 'licenseplate' : {
        this.on_licenseplate_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootprintManager_inventory_transformation_source_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_grid.on_material_clicked');
  
  $shell.FootprintManager.opensingle_material_hub({ materialId: $row.entity.Material?.Id });
  }
  on_licenseplate_clicked(event = null) {
    return this.on_licenseplate_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_clickedInternal(
    $row: FootprintManager_inventory_transformation_source_lines_gridComponentRowModel,
  $grid: FootprintManager_inventory_transformation_source_lines_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_grid.on_licenseplate_clicked');
  
  $shell.FootprintManager.opensingle_licenseplate_hub({ licenseplateId: $row.entity?.LicensePlate?.Id });
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_inventory_transformation_target_lines_gridComponent)
  ],
  selector: 'FootprintManager-inventory_transformation_source_lines_grid',
  templateUrl: './FootprintManager.inventory_transformation_source_lines_grid.component.html'
})
export class FootprintManager_inventory_transformation_source_lines_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_inventory_transformation_source_lines_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_inventory_transformation_source_lines_gridComponentInParams = { inventory_transformation_id: null, line_number: null, project_id: null, warehouse_id: null, inventory_transformation_status_id: null, source_read_only: null, target_read_only: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     error_message: new GridHeaderModel(new HeaderStyles(null, null), 'error_message', 'Error', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       source_material: new GridHeaderModel(new HeaderStyles(null, null), 'source_material', 'Source material code', false, false, null, false),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'licenseplate', 'Licenseplate', false, false, null, false),       location: new GridHeaderModel(new HeaderStyles(null, null), 'location', 'Location', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       vendor_lot: new GridHeaderModel(new HeaderStyles(null, null), 'vendor_lot', 'Vendor lot', false, false, null, false),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'packaging', 'Packaging', false, false, null, false),       quantity: new GridHeaderModel(new HeaderStyles(null, null), 'quantity', 'Quantity', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_inventory_transformation_source_lines_gridComponentRowModel[] = [];

  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(value: any) {
    this.inParams['inventory_transformation_id'] = value;
  }
  get $inParams_inventory_transformation_id(): any {
    return this.inParams['inventory_transformation_id'] ;
  }
  @Input('line_number') set $inParams_line_number(value: any) {
    this.inParams['line_number'] = value;
  }
  get $inParams_line_number(): any {
    return this.inParams['line_number'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('inventory_transformation_status_id') set $inParams_inventory_transformation_status_id(value: any) {
    this.inParams['inventory_transformation_status_id'] = value;
  }
  get $inParams_inventory_transformation_status_id(): any {
    return this.inParams['inventory_transformation_status_id'] ;
  }
  @Input('source_read_only') set $inParams_source_read_only(value: any) {
    this.inParams['source_read_only'] = value;
  }
  get $inParams_source_read_only(): any {
    return this.inParams['source_read_only'] ;
  }
  @Input('target_read_only') set $inParams_target_read_only(value: any) {
    this.inParams['target_read_only'] = value;
  }
  get $inParams_target_read_only(): any {
    return this.inParams['target_read_only'] ;
  }

  topToolbar = {
      add_line: new ToolModel(new ButtonModel('add_line', new ButtonStyles(['creation'], null), false, false, false, 'Add line', 'icon-ic_fluent_add_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      edit_line: new ToolModel(new ButtonModel('edit_line', new ButtonStyles(null, null), false, false, false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_line: new ToolModel(new ButtonModel('delete_line', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Inventory transformation source lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.inventory_transformation_id)) {
        this.$missingRequiredInParams.push('inventory_transformation_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      inventory_transformation_id:  $grid.inParams.inventory_transformation_id ,
      line_number:  $grid.inParams.line_number ,
    };
    try {
      const data = await this.$datasources.InventoryTransformations.ds_inventory_transformation_source_lines_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_inventory_transformation_source_lines_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_inventory_transformation_source_lines_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_inventory_transformation_source_lines_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintManager_inventory_transformation_source_lines_gridComponentEntity) {
    const row = new FootprintManager_inventory_transformation_source_lines_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_add_new_row(event = null) {
    return this.on_add_new_rowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_new_rowInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_grid.on_add_new_row');
  
  
  
  
  
  //creating lines
  await $shell.FootprintManager.openinventory_transformation_advanced_wizardDialog(
      {project_id: $grid.inParams.project_id, 
      warehouse_id: $grid.inParams.warehouse_id, 
      inventory_transformation_id: $grid.inParams.inventory_transformation_id})
  //this will refresh the grid when the wizard closes
  await $grid.refresh()
  }
  on_edit_existing_row(event = null) {
    return this.on_edit_existing_rowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_existing_rowInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_grid.on_edit_existing_row');
  
  
  
  
  
  //creating lines
  await $shell.FootprintManager.openinventory_transformation_advanced_wizardDialog({
      project_id: $grid.inParams.project_id, 
      warehouse_id: $grid.inParams.warehouse_id, 
      inventory_transformation_id: $grid.inParams.inventory_transformation_id,
      source_line_id: $grid.selectedRows[0].entity?.Id})
  //this will refresh the grid when the wizard closes
  await $grid.refresh()
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_grid.on_grid_data_loaded');
  
  //check if status is inventory transformation returns an error, show error message column
  
  
  if($grid.inParams.inventory_transformation_status_id === 5)
  {
      $grid.headers.error_message.hidden = false
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_grid.on_row_selected');
  
  
  if($grid.inParams.inventory_transformation_status_id != 1)
  {
      $grid.canAdd = false
      $grid.canEdit = false
      $grid.topToolbar.edit_line.control.readOnly = true
      $grid.topToolbar.add_line.control.readOnly = true
      $grid.topToolbar.delete_line.control.readOnly = true
  }
  else
  {
  $grid.topToolbar.add_line.control.readOnly = $grid.selectedRows.length > 0
  $grid.topToolbar.edit_line.control.readOnly = $grid.selectedRows.length >1 || $grid.selectedRows.length === 0
  $grid.topToolbar.delete_line.control.readOnly =  $grid.selectedRows.length === 0
  
  }
  
  if($utils.isDefined($grid.inParams.source_read_only))
  {
      if(($grid.inParams.source_read_only))
      {
          $grid.topToolbar.edit_line.control.readOnly  = true
          $grid.topToolbar.delete_line.control.readOnly = true
          $grid.topToolbar.add_line.control.readOnly = true
          $grid.canAdd = false
          $grid.canEdit = false
      }
  }
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_grid.on_init');
  
  if($grid.inParams.inventory_transformation_status_id != 1)
  {
      $grid.topToolbar.add_line.control.readOnly = true
  }
  
  $grid.topToolbar.edit_line.control.readOnly  = true
  $grid.topToolbar.delete_line.control.readOnly = true   
  $grid.canAdd = false
  $grid.headers.error_message.hidden = true
  
  if($utils.isDefined($grid.inParams.source_read_only))
  {
      if(($grid.inParams.source_read_only))
      {
          $grid.topToolbar.edit_line.hidden  = true
          $grid.topToolbar.delete_line.hidden = true
          $grid.topToolbar.add_line.hidden = true
          $grid.canAdd = false
          $grid.canEdit = false
      }
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootprintManager_inventory_transformation_source_lines_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_source_lines_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_source_lines_grid.on_delete_clicked');
  
  try {
  
  
      let valid_rows: number[] = []
      let invalid_rows: number[] = []
  
      for (let row of $grid.selectedRows) {
  
          //if source line status is completed, invalid
          if (row?.entity?.InventoryTransformationLineStatus?.Id === 3) {
              invalid_rows.push(row?.entity?.Id)
          }
  
          else {
              //target line check
              let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
                  inventory_transformation_id: $grid.inParams.inventory_transformation_id,
                  source_line_number: row?.entity?.Id
              }))?.result
  
  
              if ($utils.isDefined(target_line_check)) {
  
                  const valid_target = target_line_check.find(tlc => tlc.StatusId != 3)?.SourceLineId;
                  if (valid_target !== null && valid_target !== undefined) {
                      valid_rows.push(valid_target);
                  }
  
                  const invalid_target = target_line_check.find(tlc => tlc.StatusId === 3)?.SourceLineId;
                  if (invalid_target !== null && invalid_target !== undefined) {
                      invalid_rows.push(invalid_target);
                  }
              }
              else {
                  valid_rows.push(row.entity.Id)
              }
          }
  
  
      }
  
      //remove duplicates if any
      valid_rows = Array.from(new Set(valid_rows));
      invalid_rows = Array.from(new Set(invalid_rows));
  
      let delete_lines: boolean
  
      if (invalid_rows?.length > 0) {
          delete_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Delete source lines.', `Lines ${JSON.stringify(invalid_rows)}] cannot be deleted as their target/source lines is Completed. Confirm to delete source lines ${JSON.stringify(valid_rows)}?`, 'Confirm', 'Cancel'))
      }
      else {
          delete_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Delete source lines.', `Confirm to delete source lines ${JSON.stringify(valid_rows)}?`, 'Confirm', 'Cancel'))
      }
  
      if (!delete_lines) { return }
  
      for (let row of valid_rows) {
  
          //target line check
          let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
              inventory_transformation_id: $grid.inParams.inventory_transformation_id,
              source_line_number: row
          }))?.result
  
          if ($utils.isDefined(target_line_check)) {
              for (let row of target_line_check) {
  
                  let delete_row = (await $flows.Utilities.crud_delete_flow({
                      entitySet: 'InventoryTransformationTargetLines',
                      id: row?.Id
                  }))
                  if ($utils.isDefined(delete_row?.reason)) {
                      throw Error(delete_row?.reason)
                  }
              }
          }
  
          let delete_source_line = (await $flows.InventoryTransformations.delete_inventory_transformation_source_line_flow({source_line_id: row}))
          if($utils.isDefined(delete_source_line?.reason))
          {
              throw Error(delete_source_line?.reason)
          }
      }
  
      await $grid.refresh();
  
      $shell.InventoryTransformations.openToaster(
          'Successfully deleted soure line/s.',
          `Inventory transformation source line/s ${JSON.stringify(valid_rows)} deleted.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
  }
  
  catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on delete.', error);
  
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
