import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_line_multiple_substitutions_swap_form_ComponentContextService } from './FootprintManager.manufacturing_order_line_multiple_substitutions_swap_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_material_substitutions_dd_singleComponent } from './Manufacturing.material_substitutions_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_material_substitutions_dd_singleComponent),
  ],
  selector: 'FootprintManager-manufacturing_order_line_multiple_substitutions_swap_form',
  templateUrl: './FootprintManager.manufacturing_order_line_multiple_substitutions_swap_form.component.html'
})
export class FootprintManager_manufacturing_order_line_multiple_substitutions_swap_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { material_name?: string, material_packaging?: string, material_amount?: number, substitution_ids?: number[], material_ids?: number[], warehouse_id?: number } = { material_name: null, material_packaging: null, material_amount: null, substitution_ids: [], material_ids: [], warehouse_id: null };
  //#region Inputs
  @Input('material_name') set $inParams_material_name(v: string) {
    this.inParams.material_name = v;
  }
  get $inParams_material_name(): string {
    return this.inParams.material_name;
  }
  @Input('material_packaging') set $inParams_material_packaging(v: string) {
    this.inParams.material_packaging = v;
  }
  get $inParams_material_packaging(): string {
    return this.inParams.material_packaging;
  }
  @Input('material_amount') set $inParams_material_amount(v: number) {
    this.inParams.material_amount = v;
  }
  get $inParams_material_amount(): number {
    return this.inParams.material_amount;
  }
  @Input('substitution_ids') set $inParams_substitution_ids(v: number[]) {
    this.inParams.substitution_ids = v;
  }
  get $inParams_substitution_ids(): number[] {
    return this.inParams.substitution_ids;
  }
  @Input('material_ids') set $inParams_material_ids(v: number[]) {
    this.inParams.material_ids = v;
  }
  get $inParams_material_ids(): number[] {
    return this.inParams.material_ids;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { material_swapped?: boolean, subtitution_id?: number } = { material_swapped: null, subtitution_id: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ material_swapped?: boolean, subtitution_id?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    substitute: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm_swap: new ToolModel(new ButtonModel('confirm_swap', new ButtonStyles(['primary'], null), false, false, false, 'Swap material', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['tertiary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    description: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), ' ', false, false),
    substitute: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['substitute'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Substitute', false, false),
  };

  fieldsets = {
    substitution_group: new FieldsetModel(
      ' ',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  cacheValueFor_$fields_substitute_selector_inParams_material_ids: number[];
  get $fields_substitute_selector_inParams_material_ids(): number[] {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.material_ids;
    
    if(!isEqual(this.cacheValueFor_$fields_substitute_selector_inParams_material_ids, expr)) {
      this.cacheValueFor_$fields_substitute_selector_inParams_material_ids = expr;
    }
    return this.cacheValueFor_$fields_substitute_selector_inParams_material_ids;
  }

  get $fields_substitute_selector_inParams_warehouse_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.warehouse_id;
    
    return expr;
  }

  cacheValueFor_$fields_substitute_selector_inParams_substitution_ids: number[];
  get $fields_substitute_selector_inParams_substitution_ids(): number[] {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.substitution_ids;
    
    if(!isEqual(this.cacheValueFor_$fields_substitute_selector_inParams_substitution_ids, expr)) {
      this.cacheValueFor_$fields_substitute_selector_inParams_substitution_ids = expr;
    }
    return this.cacheValueFor_$fields_substitute_selector_inParams_substitution_ids;
  }

  get $fields_substitute_selector_inParams_needed_amount(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.material_amount;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    substitute: this.fields.substitute.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Order line multiple substitutions swap';
  
    const $form = this;
    const $utils = this.$utils;


    await this.on_init_flow();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .substitute
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_substitute_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init_flow(event = null) {
    return this.on_init_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_flowInternal(
    $form: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_multiple_substitutions_swap_form.on_init_flow');
  
  $form.title = `Swap material ${$form.inParams.material_name} for a substitute?`;
  $form.fields.description.control.text = `You are about to replace material ${$form.inParams.material_name} (${$form.inParams.material_amount} ${$form.inParams.material_packaging})\nwith a substitute. Select one from the list below.`
  $form.toolbar.confirm_swap.control.readOnly = true;
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_multiple_substitutions_swap_form.on_confirm_clicked');
  $form.outParams.material_swapped = true;
  $form.outParams.subtitution_id = $form.fields.substitute.control.value;
  $form.close();
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_multiple_substitutions_swap_form.on_cancel_clicked');
  $form.outParams.material_swapped = false;
  $form.close();
  }
  on_substitute_changed(event = null) {
    return this.on_substitute_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_substitute_changedInternal(
    $form: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_multiple_substitutions_swap_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_multiple_substitutions_swap_form.on_substitute_changed');
  
  $form.toolbar.confirm_swap.control.readOnly = false;
  
  if( !$utils.isDefined($form.fields.substitute.control.value) )
      $form.toolbar.confirm_swap.control.readOnly = true;
  }
  //#endregion private flows
}
