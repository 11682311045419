<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="question">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.question.displayControl.styles.style"
          [ngClass]="row.cells.question.displayControl.styles.classes"
          [matTooltip]="row.cells.question.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.question.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="input_value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.input_value.displayControl.styles.style"
          [ngClass]="row.cells.input_value.displayControl.styles.classes"
          [matTooltip]="row.cells.input_value.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.input_value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootprintManager-survey_grid_input_values_grid
    [values]="row.$rowExpand_FootprintManager_survey_grid_input_values_grid_inParams_values"
    [headers]="row.$rowExpand_FootprintManager_survey_grid_input_values_grid_inParams_headers"
    ($refreshEvent)="row.refresh()">>
  </FootprintManager-survey_grid_input_values_grid>
</ng-template>

</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
