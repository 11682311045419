<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.on_instructions_clicked.readOnly"
                [ngStyle]="row.options.on_instructions_clicked.styles.style"
                [ngClass]="row.options.on_instructions_clicked.styles.classes"
                (click)="row.on_row_instructions_clicked()">
          <span *ngIf="row.options.on_instructions_clicked.label">{{row.options.on_instructions_clicked.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="assign_containers_button" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_assign_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="on_delete" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes"
          [matTooltip]="row.cells.name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.name.editControl.readOnly || row.cells.name.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.name.editControl.placeholder}}"
            [ngStyle]="row.cells.name.editControl.styles.style"
            [ngClass]="row.cells.name.editControl.styles.classes"
            [matTooltip]="row.cells.name.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes"
          [matTooltip]="row.cells.type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-container_type_categories_dd_single 
        data-cy="selector"
        [type]="row.cells.type.editControl.type"
        [formControl]="row.formGroup.controls['type_edit']"
        (displayTextChange)="row.cells.type.editControl.displayText=$event"
        [placeholder]="row.cells.type.editControl.placeholder"
        [styles]="row.cells.type.editControl.styles"
        [tooltip]="row.cells.type.editControl.tooltip"
    >
    </Inventory-container_type_categories_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="active">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['active_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.active.displayControl.styles.style"
                  [ngClass]="row.cells.active.displayControl.styles.classes"
                  [matTooltip]="row.cells.active.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.active.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['active_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.active.editControl.styles.style"
                  [ngClass]="row.cells.active.editControl.styles.classes"
                  [matTooltip]="row.cells.active.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.active.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="catch_all">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['catch_all_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.catch_all.displayControl.styles.style"
                  [ngClass]="row.cells.catch_all.displayControl.styles.classes"
                  [matTooltip]="row.cells.catch_all.displayControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.catch_all.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['catch_all_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.catch_all.editControl.styles.style"
                  [ngClass]="row.cells.catch_all.editControl.styles.classes"
                  [matTooltip]="row.cells.catch_all.editControl.tooltip"
                  matTooltipClass="datex-control-tooltip">{{row.cells.catch_all.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.length.displayControl.styles.style"
          [ngClass]="row.cells.length.displayControl.styles.classes"
          [matTooltip]="row.cells.length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['length_edit']"
            matInput
            numberBox
            [format]="row.cells.length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.length.editControl.readOnly || row.cells.length.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.length.editControl.placeholder}}"
            [ngStyle]="row.cells.length.editControl.styles.style"
            [ngClass]="row.cells.length.editControl.styles.classes"
            [matTooltip]="row.cells.length.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="inner_length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.inner_length.displayControl.styles.style"
          [ngClass]="row.cells.inner_length.displayControl.styles.classes"
          [matTooltip]="row.cells.inner_length.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.inner_length.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['inner_length_edit']"
            matInput
            numberBox
            [format]="row.cells.inner_length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.inner_length.editControl.readOnly || row.cells.inner_length.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.inner_length.editControl.placeholder}}"
            [ngStyle]="row.cells.inner_length.editControl.styles.style"
            [ngClass]="row.cells.inner_length.editControl.styles.classes"
            [matTooltip]="row.cells.inner_length.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.width.displayControl.styles.style"
          [ngClass]="row.cells.width.displayControl.styles.classes"
          [matTooltip]="row.cells.width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['width_edit']"
            matInput
            numberBox
            [format]="row.cells.width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.width.editControl.readOnly || row.cells.width.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.width.editControl.placeholder}}"
            [ngStyle]="row.cells.width.editControl.styles.style"
            [ngClass]="row.cells.width.editControl.styles.classes"
            [matTooltip]="row.cells.width.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="inner_width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.inner_width.displayControl.styles.style"
          [ngClass]="row.cells.inner_width.displayControl.styles.classes"
          [matTooltip]="row.cells.inner_width.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.inner_width.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['inner_width_edit']"
            matInput
            numberBox
            [format]="row.cells.inner_width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.inner_width.editControl.readOnly || row.cells.inner_width.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.inner_width.editControl.placeholder}}"
            [ngStyle]="row.cells.inner_width.editControl.styles.style"
            [ngClass]="row.cells.inner_width.editControl.styles.classes"
            [matTooltip]="row.cells.inner_width.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.height.displayControl.styles.style"
          [ngClass]="row.cells.height.displayControl.styles.classes"
          [matTooltip]="row.cells.height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['height_edit']"
            matInput
            numberBox
            [format]="row.cells.height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.height.editControl.readOnly || row.cells.height.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.height.editControl.placeholder}}"
            [ngStyle]="row.cells.height.editControl.styles.style"
            [ngClass]="row.cells.height.editControl.styles.classes"
            [matTooltip]="row.cells.height.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="inner_height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.inner_height.displayControl.styles.style"
          [ngClass]="row.cells.inner_height.displayControl.styles.classes"
          [matTooltip]="row.cells.inner_height.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.inner_height.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['inner_height_edit']"
            matInput
            numberBox
            [format]="row.cells.inner_height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.inner_height.editControl.readOnly || row.cells.inner_height.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.inner_height.editControl.placeholder}}"
            [ngStyle]="row.cells.inner_height.editControl.styles.style"
            [ngClass]="row.cells.inner_height.editControl.styles.classes"
            [matTooltip]="row.cells.inner_height.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dimension_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.dimension_uom.displayControl.styles.style"
          [ngClass]="row.cells.dimension_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.dimension_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.dimension_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.dimension_uom.editControl.type"
        [formControl]="row.formGroup.controls['dimension_uom_edit']"
        (displayTextChange)="row.cells.dimension_uom.editControl.displayText=$event"
        [placeholder]="row.cells.dimension_uom.editControl.placeholder"
        [styles]="row.cells.dimension_uom.editControl.styles"
        [tooltip]="row.cells.dimension_uom.editControl.tooltip"
      [typeId]="row.$fields_dimension_uom_selector_inParams_typeId"
    >
    </Inventory-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_capacity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight_capacity.displayControl.styles.style"
          [ngClass]="row.cells.weight_capacity.displayControl.styles.classes"
          [matTooltip]="row.cells.weight_capacity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight_capacity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['weight_capacity_edit']"
            matInput
            numberBox
            [format]="row.cells.weight_capacity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.weight_capacity.editControl.readOnly || row.cells.weight_capacity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight_capacity.editControl.placeholder}}"
            [ngStyle]="row.cells.weight_capacity.editControl.styles.style"
            [ngClass]="row.cells.weight_capacity.editControl.styles.classes"
            [matTooltip]="row.cells.weight_capacity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight.displayControl.styles.style"
          [ngClass]="row.cells.weight.displayControl.styles.classes"
          [matTooltip]="row.cells.weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['weight_edit']"
            matInput
            numberBox
            [format]="row.cells.weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.weight.editControl.readOnly || row.cells.weight.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight.editControl.placeholder}}"
            [ngStyle]="row.cells.weight.editControl.styles.style"
            [ngClass]="row.cells.weight.editControl.styles.classes"
            [matTooltip]="row.cells.weight.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight_uom.displayControl.styles.style"
          [ngClass]="row.cells.weight_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.weight_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.weight_uom.editControl.type"
        [formControl]="row.formGroup.controls['weight_uom_edit']"
        (displayTextChange)="row.cells.weight_uom.editControl.displayText=$event"
        [placeholder]="row.cells.weight_uom.editControl.placeholder"
        [styles]="row.cells.weight_uom.editControl.styles"
        [tooltip]="row.cells.weight_uom.editControl.tooltip"
      [typeId]="row.$fields_weight_uom_selector_inParams_typeId"
    >
    </Inventory-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="volume_capacity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.volume_capacity.displayControl.styles.style"
          [ngClass]="row.cells.volume_capacity.displayControl.styles.classes"
          [matTooltip]="row.cells.volume_capacity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.volume_capacity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['volume_capacity_edit']"
            matInput
            numberBox
            [format]="row.cells.volume_capacity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.volume_capacity.editControl.readOnly || row.cells.volume_capacity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.volume_capacity.editControl.placeholder}}"
            [ngStyle]="row.cells.volume_capacity.editControl.styles.style"
            [ngClass]="row.cells.volume_capacity.editControl.styles.classes"
            [matTooltip]="row.cells.volume_capacity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="volume_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.volume_uom.displayControl.styles.style"
          [ngClass]="row.cells.volume_uom.displayControl.styles.classes"
          [matTooltip]="row.cells.volume_uom.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.volume_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.volume_uom.editControl.type"
        [formControl]="row.formGroup.controls['volume_uom_edit']"
        (displayTextChange)="row.cells.volume_uom.editControl.displayText=$event"
        [placeholder]="row.cells.volume_uom.editControl.placeholder"
        [styles]="row.cells.volume_uom.editControl.styles"
        [tooltip]="row.cells.volume_uom.editControl.tooltip"
      [typeId]="row.$fields_volume_uom_selector_inParams_typeId"
    >
    </Inventory-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes"
          [matTooltip]="row.cells.description.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['description_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.description.editControl.readOnly || row.cells.description.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.description.editControl.placeholder}}"
            [ngStyle]="row.cells.description.editControl.styles.style"
            [ngClass]="row.cells.description.editControl.styles.classes"
            [matTooltip]="row.cells.description.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="style">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.style.displayControl.styles.style"
          [ngClass]="row.cells.style.displayControl.styles.classes"
          [matTooltip]="row.cells.style.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.style.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-container_type_styles_dd_single 
        data-cy="selector"
        [type]="row.cells.style.editControl.type"
        [formControl]="row.formGroup.controls['style_edit']"
        (displayTextChange)="row.cells.style.editControl.displayText=$event"
        [placeholder]="row.cells.style.editControl.placeholder"
        [styles]="row.cells.style.editControl.styles"
        [tooltip]="row.cells.style.editControl.tooltip"
    >
    </Inventory-container_type_styles_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
