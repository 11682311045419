import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Invoices_ModuleContextService } from './Invoices.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Invoices_measurement_units_dd_ComponentContextService {
  private readonly moduleContext: Invoices_ModuleContextService;

  constructor(moduleContext: Invoices_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'measurement_units_dd',
      type: ComponentType.selector
    }
  }
}