import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_physical_inventory_by_license_plate_lotService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { license_plate_id?: number, warehouse_id?: number, material_id?: number, full_text_search?: string, lot_id?: number, package_id?: number, location_id?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { ShortName?: string }, LicensePlate?: { LocationId?: number, LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } }, ParentLicensePlate?: { Id?: number, LookupCode?: string } }, WeightUom?: { Short_name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_physical_inventory_by_license_plate_lot/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { license_plate_id?: number, warehouse_id?: number, material_id?: number, full_text_search?: string, lot_id?: number, package_id?: number, location_id?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { ShortName?: string }, LicensePlate?: { LocationId?: number, LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } }, ParentLicensePlate?: { Id?: number, LookupCode?: string } }, WeightUom?: { Short_name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_physical_inventory_by_license_plate_lot/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { license_plate_id?: number, warehouse_id?: number, material_id?: number, full_text_search?: string, lot_id?: number, package_id?: number, location_id?: number, $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { ShortName?: string }, LicensePlate?: { LocationId?: number, LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } }, ParentLicensePlate?: { Id?: number, LookupCode?: string } }, WeightUom?: { Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_physical_inventory_by_license_plate_lot/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      license_plate_id?: number, warehouse_id?: number, material_id?: number, full_text_search?: string, lot_id?: number, package_id?: number, location_id?: number    }) {
      return false;
    }
}
