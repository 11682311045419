import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { app_customized_lots_udf_grid_ComponentContextService } from './app.customized_lots_udf_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface Iapp_customized_lots_udf_gridComponentEntity {
  Id?: number, LookupCode?: string, Material?: { LookupCode?: string }  ,
    udfs?: { Id?: number, LotCOO?: string }
}

interface Iapp_customized_lots_udf_gridComponentInParams {
  projectId?: number, ownerId?: number, materialIds?: number[], lotId?: number}


class app_customized_lots_udf_gridComponentRowModel extends GridRowModel {
  grid: app_customized_lots_udf_gridComponent;
  entity: Iapp_customized_lots_udf_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: null,
    lot_coo: null,
  }



  constructor(
    grid: app_customized_lots_udf_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: app_ShellService,
private $datasources: app_DatasourceService,
private $flows: app_FlowService,
private $reports: app_ReportService,
private $localization: app_LocalizationService,
private $operations: app_OperationService,
private $logger: CleanupLoggerService,
private $context: app_customized_lots_udf_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot_coo = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot_coo,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: Iapp_customized_lots_udf_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: Iapp_customized_lots_udf_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      materialIds:  $grid.inParams.materialIds ,
      lotId:  $grid.inParams.lotId ,
    };
    const data = await this.$datasources.Lots.ds_lots_udf_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as Iapp_customized_lots_udf_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.lot_coo.displayControl as TextModel).text = $row.entity?.udfs?.LotCOO;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: app_customized_lots_udf_gridComponentRowModel,
  $grid: app_customized_lots_udf_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    $context: app_customized_lots_udf_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
    this.$logger.log('app', 'customized_lots_udf_grid.on_save_existing_row');
  let payload: any = {};
  
  // Replace with UDFs
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Lots', id: $row.entity.Id, entity: payload });
  
      $row.refresh();
  } catch (error) {
      $shell.FootprintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-customized_lots_udf_grid',
  templateUrl: './app.customized_lots_udf_grid.component.html'
})
export class app_customized_lots_udf_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: Iapp_customized_lots_udf_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: Iapp_customized_lots_udf_gridComponentInParams = { projectId: null, ownerId: null, materialIds: [], lotId: null };


  //#region Variables
  vars: { entities?: { Id?: number, CommodityCode?: string, CountryOfOrigin?: string, Description?: string, DutyRate?: number, LookupCode?: string, Name?: string, SpecialInstructions?: string }[], totalCount?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Lot', false, false, null, false),       lot_coo: new GridHeaderModel(new HeaderStyles(null, null), 'lot_coo', 'LotCOO', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: app_customized_lots_udf_gridComponentRowModel[] = [];

  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }
  @Input('lotId') set $inParams_lotId(value: any) {
    this.inParams['lotId'] = value;
  }
  get $inParams_lotId(): any {
    return this.inParams['lotId'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  datasources = {
    ds_lot_udfs: this.$datasources.app.customized_lots_udf_grid___ds_lot_udfs
  }

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: app_ShellService,
    private $datasources: app_DatasourceService,
    private $flows: app_FlowService,
    private $reports: app_ReportService,
    private $localization: app_LocalizationService,
    private $operations: app_OperationService,
    private $logger: CleanupLoggerService,
    private $context: app_customized_lots_udf_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Lots UDF';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 100;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      materialIds:  $grid.inParams.materialIds ,
      lotId:  $grid.inParams.lotId ,
    };
    try {
      const data = await this.$datasources.Lots.ds_lots_udf_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as Iapp_customized_lots_udf_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new app_customized_lots_udf_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
      // list of all linkedDatasources functions, so that they can be executed in parallel
      const linkedDatasourcesQueries: (() => Promise<void>)[] = [];
      {
              
              
            for (const currentEntity of outParams.result) {
              const $entity = currentEntity; 
              const flowParams  = {
                lot_id:  $entity.Id ,
              };
              
              if (!this.$datasources.app.customized_lots_udf_grid___ds_lot_udfs.hasMissingRequiredInParams(flowParams)) 
              {
                linkedDatasourcesQueries.push(async () => {
                  const flowOutput = await this.$datasources.app.customized_lots_udf_grid___ds_lot_udfs.get(flowParams);
                  if (flowOutput && flowOutput.result) {
                    currentEntity['udfs'] = flowOutput.result;
                  } else { 
                    currentEntity['udfs'] = null;
                  }
                });
              } else { 
                currentEntity['udfs'] = null;
              }
            }
      }
      await Promise.all(linkedDatasourcesQueries.map(t => t()));
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }



 
  close() {
    this.$finish.emit();
  }
}
