import { ButtonModel } from "./control";

export class FieldsetModel {
  label: string;
  hideTitle: boolean;
  collapsible: boolean;
  expanded: boolean;
  hidden: boolean;
  removed: boolean;
  action: ButtonModel;
  infoGetter: () => string;

  constructor(label: string, hideTitle: boolean, collapsible: boolean, expanded: boolean, removed: boolean, action: ButtonModel, infoGetter: () => string) {
    this.label = label;
    this.hideTitle = hideTitle;
    this.collapsible = collapsible;
    this.expanded = expanded;
    this.removed = removed;
    this.hidden = false;
    this.action = action;
    this.infoGetter = infoGetter;
  }

  toggle() {
    if (this.collapsible) this.expanded = !this.expanded;
  }

  get info() {
    if (this.infoGetter) {
      return this.infoGetter();
    }
    return null;
  }
}