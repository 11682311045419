import { isNil } from 'lodash-es';
import { FormGroup } from '@angular/forms'
import { ControlModel, ValueControlModel } from './control';
import { Styles, ControlContainerStyles } from './style';
import { Observable, Subject } from 'rxjs';


export class HeaderStyles extends Styles {
  private setTextAlignClass(klass: string) {
    this.removeClass([
      'center',
      'right',
      'left']);
    this.addClass(klass);
  }

  setTextAlignCenterClass() { this.setTextAlignClass('center'); }
  setTextAlignRightClass() { this.setTextAlignClass('right'); }
  setTextAlignLeftClass() { this.setTextAlignClass('left'); }
}

export class GridHeaderModel {
  id: string;
  styles: HeaderStyles;
  name: string;
  required: boolean;
  private _hidden: boolean;
  private _width: number;
  removed: boolean;

  constructor(styles?: any, id?: string, name?: string, required?: boolean, hidden?: boolean, width?: number, removed?: boolean) {
    this.id = id;
    this.styles = isNil(styles) ? new HeaderStyles() : styles;
    this.name = name;
    this.required = required;
    this._hidden = hidden ?? false;
    this._width = width;
    this.removed = removed ?? false;
  }

  get hidden(): boolean {
    return this._hidden;
  }
  set hidden(val: boolean) {
    this._hidden = val;
  }

  get width(): number {
    return this._width;
  }
  set width(val: number) {
    this._width = val;
  }
}

export class CellStyles extends ControlContainerStyles {
  setHyperlinkClass(enabled: boolean) { enabled ? this.addClass('grid-table-cell-link') : this.removeClass('grid-table-cell-link') }

  private setTextAlignClass(klass: string) {
    this.removeClass([
      'center',
      'right',
      'left']);
    this.addClass(klass);
  }

  setTextAlignCenterClass() { this.setTextAlignClass('center'); }
  setTextAlignRightClass() { this.setTextAlignClass('right'); }
  setTextAlignLeftClass() { this.setTextAlignClass('left'); }
}

export class GridCellModel<TDC extends ControlModel, TEC extends ControlModel | void = void> {
  styles: CellStyles;
  mode = 'display';
  displayControl: TDC;
  editControl: TEC;
  header: GridHeaderModel;

  constructor(styles: CellStyles, header: GridHeaderModel, displayControl: TDC, editControl?: TEC) {
    this.styles = isNil(styles) ? new CellStyles() : styles;
    this.displayControl = displayControl;
    this.editControl = editControl;
    this.header = header;
  }

  setDisplayMode() {
    this.mode = 'display';
  }

  setEditMode() {
    if (this.editControl) {
      this.mode = 'edit';
    }
  }

  get isDisplay() {
    return this.mode === 'display';
  }

  get isEdit() {
    return this.mode === 'edit';
  }

  get isEditable(): boolean {
    return this.editControl && this.editControl instanceof ValueControlModel
      && !(this.editControl as ValueControlModel).disabled;
  }

  get isHidden(): boolean {
    return this.header.hidden || this.header.removed;
  }

  focus() {
    if (this.isEditable) {
      Promise.resolve(null).then(() => (this.editControl as ValueControlModel).focus());
    }
  }
}

export class GridRowModel {
  styles: Styles;
  rowId: string;

  isNew = false;

  mode: 'display' | 'edit' = 'display';

  expand = false;

  formGroup: FormGroup
  cells: { [key: string]: GridCellModel<ControlModel, ControlModel | void> };

  $propertyChanges: Subject<string> = new Subject<string>();

  constructor(styles?: any) {
    this.styles = isNil(styles) ? new Styles() : styles;
  }

  protected $_selected = false;
  get selected(): boolean {
    return this.$_selected;
  }

  // NOTE: this setter is meant to be used from (private) flows inside a grid
  set selected(value: boolean) {
    this.setSelectedState(value, true);
  }

  setSelectedState(value: boolean, emitEvent?: boolean) {
    this.$_selected = value;
    if (emitEvent === true) {
      this.$propertyChanges.next('selected');
    }

  }

  setDisplayMode() {
    this.mode = 'display';
    Object.keys(this.cells).forEach((cellName) => {
      this.cells[cellName].setDisplayMode();
    });
  }

  setEditMode() {
    this.mode = 'edit';
    Object.keys(this.cells).forEach((cellName) => {
      this.cells[cellName].setEditMode();
    });
  }

  get isDisplay() {
    return this.mode === 'display';
  }

  get isEdit() {
    return this.mode === 'edit';
  }

  getFirstEditableCell() {
    for (let cell of Object.keys(this.cells).map((cellName) => this.cells[cellName])) {
      if (cell.isEditable && !cell.isHidden) {
        return cell;
      }
    }
    return null;
  }

  getLastEditableCell() {
    for (let cell of Object.keys(this.cells).reverse().map((cellName) => this.cells[cellName])) {
      if (cell.isEditable && !cell.isHidden) {
        return cell;
      }
    }
    return null;
  }

  focusFirstEditableCell() {
    const cell = this.getFirstEditableCell();
    if (cell) {
      cell.focus();
    }
  }

  focusLastEditableCell() {
    const cell = this.getLastEditableCell();
    if (cell) {
      cell.focus();
    }
  }

  $cellClicked(cellId: string): void { }

  destroy() { }
  async refresh() { }
  async save() { }

  $_inAction = false;
  async confirm() {
    this.$_inAction = true;
    try {
      if (this.formGroup.valid) {
        // TODO: revise
        // expects save flow to throw an error on unsuccessful save
        try {
          if (this.formGroup.dirty || this.isNew) {
            await this.save();
            //await this.refresh(); //moved into save flow
            this.setDisplayMode();
          } else {
            //just close edit mode
            this.setDisplayMode();
          }
        } catch {
          // noop
        }
      } else {
        console.log('invalid form on confirm');
      }
      this.$_inAction = false;;
    } catch (error) {
      console.log(error);
      this.$_inAction = false;;
      throw error;
    }
  }

  async mediumSave() {
    this.$_inAction = true;
    try {
      if (this.formGroup.valid) {
        // TODO: revise
        // expects save flow to throw an error on unsuccessful save
        try {
          if (this.formGroup.dirty || this.isNew) {
            await this.save();
            //await this.refresh(); //moved into save flow
          }
        } catch {
          // noop
        }
      } else {
        console.log('invalid form on mediumSave');
      }
      this.$_inAction = false;;
    } catch (error) {
      this.$_inAction = false;;
      throw error;
    }
  }

  get $isBusy() {
    return this.$isInAction ||
      (!this.isNew && (this.isChanged)) ||
      (this.isNew && (this.isInvalid || this.isChanged));
  }

  get $isReadyToConfirm() {
    return !this.$isInAction && !this.isInvalid;
  }

  get $isInAction() {
    return this.$_inAction;
  }

  get isInvalid() {
    return !this.formGroup.valid;
  }

  get isChanged() {
    return this.formGroup.dirty;
  }
}

export class GridContainerStyle extends Styles {
  columnSizingType: string;

  constructor(columnSizingType: string, classes?: string[], style?: { [klass: string]: any }) {
    super(classes, style);
    this.columnSizingType = columnSizingType;
  }

  private setStateClass(klass: string) {
    this.removeClass([
      'set-width-table',
      'noscroll-table',
      'fit-content-table'
    ]);

    this.addClass(klass);
  }

  private setRowStylingClass(klass: string) {
    this.removeClass([
      'relaxed',
      'compact',
    ]);

    this.addClass(klass);
  }

  setRelaxedRowClass() { this.setRowStylingClass('relaxed'); }
  setCompactRowClass() { this.setRowStylingClass('compact'); }
  setDefaultRowClass() { this.setRowStylingClass(''); }

  setHeadersWidthClass() { this.columnSizingType = 'headersWidth'; this.setStateClass(''); }
  setFixedWidthClass() { this.columnSizingType = 'fixedWidth'; this.setStateClass('set-width-table'); }
  setFittedWidthClass() { this.columnSizingType = 'fitedWidth'; this.setStateClass('noscroll-table'); }
  setCellsWidthClass() { this.columnSizingType = 'cellsWidth'; this.setStateClass('fit-content-table'); }
}