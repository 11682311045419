import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_reason_codes_grid_ComponentContextService } from './FootprintManager.reason_codes_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_reason_codes_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, EnumName?: string, Label?: string, LookupCode?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Name?: string, NumberOfMinutesBeforeExpiration?: number, ParentEntity?: string, ParentId?: number, SystemDefined?: boolean}

interface IFootprintManager_reason_codes_gridComponentInParams {
  parentEntity?: string}


class FootprintManager_reason_codes_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_reason_codes_gridComponent;
  entity: IFootprintManager_reason_codes_gridComponentEntity;


  options: { Delete: ButtonModel } = {
    Delete: new ButtonModel('Delete', new ButtonStyles(null, null), false, false, false, 'Delete', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    LookupCode: null,
    Label: null,
    ParentId: null,
    NumberOfMinutesBeforeExpiration: null,
  }



  constructor(
    grid: FootprintManager_reason_codes_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_reason_codes_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.LookupCode = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.LookupCode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Label = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Label,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.ParentId = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.ParentId,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.NumberOfMinutesBeforeExpiration = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.NumberOfMinutesBeforeExpiration,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_reason_codes_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_reason_codes_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      parentEntity:  null ,
      parentId:  null ,
    };
    const data = await this.$datasources.FootprintManager.ds_reason_codes_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_reason_codes_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.LookupCode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.Label.displayControl as TextModel).text = $row.entity.Label;
    (this.cells.NumberOfMinutesBeforeExpiration.displayControl as TextModel).text = $row.entity.NumberOfMinutesBeforeExpiration?.toString() ? `Resets inventory status after ${$row.entity.NumberOfMinutesBeforeExpiration?.toString()} minutes` : '';;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'LookupCode' : {
        this.on_reason_code_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_reason_codes_gridComponentRowModel,
  $grid: FootprintManager_reason_codes_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_reason_codes_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // O.Arias 11/20/2023
  
  // Set Date/Time Formats
  const format = `${$settings.FootprintManager.DateFormat}, ${$settings.FootprintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if (!$utils.isDefined($row.cells.ParentId.displayControl.text)) {
      if ($utils.isDefined($row.cells.ParentId)) {
          const options = (await $datasources.FootprintManager.ds_reason_code_parent_entities_dd.getByKeys({ id: $row.entity.ParentId, parent: $row.entity.ParentEntity, $keys: [$row.entity.ParentId] })).result;
          if ($utils.isDefined(options)) {
              $row.cells.ParentId.displayControl.text = options[0].Name;
          };
      };
  };
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $row: FootprintManager_reason_codes_gridComponentRowModel,
  $grid: FootprintManager_reason_codes_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_reason_codes_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 11/21/2023
  
  const id = $row.entity.Id;
  const lookup = $row.entity.LookupCode;
  
  let confirmation = false;
  
  confirmation = (await $shell.FootprintManager.openConfirmationDialog(
      "Confirm deletion",
      `Are you sure you want to delete reason code ${lookup} with id ${id}?`,
      "Yes",
      "No"
  ));
  
  if (confirmation) {
  
      if ($utils.isDefined(id)) {
          //Delete
          const results = (await $flows.FootprintManager.reason_codes_create_update_delete_flow({ payload: null, id: id, action: "Delete" }));
  
          if (!$utils.isDefined(results)) {
              await $shell.FootprintManager.openErrorDialog(
                  "Error deleting reason code.",
                  "No results were returned when firing the [reason_codes_create_update_delete_flow] flow."
              );
          } else {
  
              if (results.success) {
                  await $shell.FootprintManager.openInfoDialog(
                      "Success",
                      `Deleted reason code ${lookup} with id ${id}.`
                  );
                  $grid.refresh();
              } else {
                  await $shell.FootprintManager.openErrorDialog(
                      "Error deleting reason code.",
                      `Failed to delete reason code ${lookup} with id ${id}. Error: ${results.message}`
                  );
              };
          };
      };
  };
  }
  on_reason_code_clicked(event = null) {
    return this.on_reason_code_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_reason_code_clickedInternal(
    $row: FootprintManager_reason_codes_gridComponentRowModel,
  $grid: FootprintManager_reason_codes_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_reason_codes_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let reasonCodeId = $row.entity.Id
  console.log(`ID: ${$row.entity.Id} , Lookupcode: ${$row.entity.LookupCode}, ParentId: ${$row.entity.ParentId}`)
  let result = (await $shell.FootprintManager.openreason_code_editorDialog({reasonCodeId: reasonCodeId}, "flyout"))
  
  if(result.confirm){
      $grid.refresh();
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-reason_codes_grid',
  templateUrl: './FootprintManager.reason_codes_grid.component.html'
})
export class FootprintManager_reason_codes_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_reason_codes_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_reason_codes_gridComponentInParams = { parentEntity: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'LookupCode', 'Reason code', false, false, null, false),       Label: new GridHeaderModel(new HeaderStyles(null, null), 'Label', 'Displayed label', false, false, null, false),       ParentId: new GridHeaderModel(new HeaderStyles(null, null), 'ParentId', 'Reason type', false, false, null, false),       NumberOfMinutesBeforeExpiration: new GridHeaderModel(new HeaderStyles(null, null), 'NumberOfMinutesBeforeExpiration', ' ', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_reason_codes_gridComponentRowModel[] = [];

  @Input('parentEntity') set $inParams_parentEntity(value: any) {
    this.inParams['parentEntity'] = value;
  }
  get $inParams_parentEntity(): any {
    return this.inParams['parentEntity'] ;
  }

  topToolbar = {
      new_reason_code_button: new ToolModel(new ButtonModel('new_reason_code_button', new ButtonStyles(['creation'], null), false, false, false, 'New reason code', 'ms-Icon ms-Icon--Add', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_reason_codes_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Reason codes';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 15;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      parentEntity:  null ,
      parentId:  null ,
    };
    try {
      const data = await this.$datasources.FootprintManager.ds_reason_codes_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_reason_codes_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_reason_codes_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  grid_data_loaded(event = null) {
    return this.grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async grid_data_loadedInternal(
    $grid: FootprintManager_reason_codes_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_reason_codes_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 11/20/2023
  
  let options: { parent: string, options: { Id: number, Name: string }[] }[];
  options = [];
  
  const invoice = (await $datasources.FootprintManager.ds_reason_code_parent_entities_dd.getList({ parent: "InvoiceType" })).result;
  
  options = options.concat({
      parent: "InvoiceType",
      options: invoice.map(i => {
          return {
              Id: i.Id,
              Name: i.Name
          }
      })
  });
  
  const load = (await $datasources.FootprintManager.ds_reason_code_parent_entities_dd.getList({ parent: "LoadContainer" })).result;
  
  options = options.concat({
      parent: "LoadContainer",
      options: load.map(i => {
          return {
              Id: i.Id,
              Name: i.Name
          }
      })
  });
  
  const operation = (await $datasources.FootprintManager.ds_reason_code_parent_entities_dd.getList({ parent: "OperationType" })).result;
  
  options = options.concat({
      parent: "OperationType",
      options: operation.map(i => {
          return {
              Id: i.Id,
              Name: i.Name
          }
      })
  });
  
  
  }
  on_new_reason_code_clicked(event = null) {
    return this.on_new_reason_code_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_reason_code_clickedInternal(
    $grid: FootprintManager_reason_codes_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_reason_codes_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let result = (await $shell.FootprintManager.openreason_code_creation_formDialog("flyout"))
  
  if(result.confirm){
      $grid.refresh();
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
