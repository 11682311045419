<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="add" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_add_order($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden && !filters.date_from.removed" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}} {{filters.date_from.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_from.label + (filters.date_from.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_from.label}}<span *ngIf="filters.date_from.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes"
            [tooltip]="filters.date_from.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden && !filters.date_to.removed" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}} {{filters.date_to.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_to.label + (filters.date_to.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_to.label}}<span *ngIf="filters.date_to.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes"
            [tooltip]="filters.date_to.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_type" *ngIf="!filters.date_type.hidden && !filters.date_type.removed" 
            class="field-container standard {{filters.date_type.invalid ? 'invalid' : ''}} {{filters.date_type.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.date_type.styles.style"
            [ngClass]="filters.date_type.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_type.label + (filters.date_type.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_type.label}}<span *ngIf="filters.date_type.required" class="required-asterisk">*</span>
          </label>
        </div>
        <LoadContainers-order_date_types_single 
            data-cy="selector"
            [type]="filters.date_type.control.type"
            formControlName="date_type"
            (displayTextChange)="filters.date_type.control.displayText=$event"
            [placeholder]="filters.date_type.control.placeholder"
            [styles]="filters.date_type.control.styles"
            [tooltip]="filters.date_type.control.tooltip"
        >
        </LoadContainers-order_date_types_single>
        <ng-container *ngIf="filters.date_type.invalid">
          <ng-container *ngFor="let error of filters.date_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes"
          [matTooltip]="row.cells.Id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Order">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Order.displayControl.styles.style"
          [ngClass]="row.cells.Order.displayControl.styles.classes"
          [matTooltip]="row.cells.Order.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Order.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Shipment.displayControl.styles.style"
          [ngClass]="row.cells.Shipment.displayControl.styles.classes"
          [matTooltip]="row.cells.Shipment.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Shipment.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OrderClassName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OrderClassName.displayControl.styles.style"
          [ngClass]="row.cells.OrderClassName.displayControl.styles.classes"
          [matTooltip]="row.cells.OrderClassName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OrderClassName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LoadSequence">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.LoadSequence.displayControl.styles.style"
          [ngClass]="row.cells.LoadSequence.displayControl.styles.classes"
          [matTooltip]="row.cells.LoadSequence.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.LoadSequence.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['LoadSequence_edit']"
            matInput
            numberBox
            [format]="row.cells.LoadSequence.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{ (row.cells.LoadSequence.editControl.readOnly || row.cells.LoadSequence.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.LoadSequence.editControl.placeholder}}"
            [ngStyle]="row.cells.LoadSequence.editControl.styles.style"
            [ngClass]="row.cells.LoadSequence.editControl.styles.classes"
            [matTooltip]="row.cells.LoadSequence.editControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OwnerName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OwnerName.displayControl.styles.style"
          [ngClass]="row.cells.OwnerName.displayControl.styles.classes"
          [matTooltip]="row.cells.OwnerName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OwnerName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ProjectName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ProjectName.displayControl.styles.style"
          [ngClass]="row.cells.ProjectName.displayControl.styles.classes"
          [matTooltip]="row.cells.ProjectName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ProjectName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OwnerReference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.OwnerReference.displayControl.styles.style"
          [ngClass]="row.cells.OwnerReference.displayControl.styles.classes"
          [matTooltip]="row.cells.OwnerReference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.OwnerReference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="VendorReference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.VendorReference.displayControl.styles.style"
          [ngClass]="row.cells.VendorReference.displayControl.styles.classes"
          [matTooltip]="row.cells.VendorReference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.VendorReference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="StatusName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.StatusName.displayControl.styles.style"
          [ngClass]="row.cells.StatusName.displayControl.styles.classes"
          [matTooltip]="row.cells.StatusName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.StatusName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CreatedDate.displayControl.styles.style"
          [ngClass]="row.cells.CreatedDate.displayControl.styles.classes"
          [matTooltip]="row.cells.CreatedDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CreatedDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="FulfillmentDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.FulfillmentDate.displayControl.styles.style"
          [ngClass]="row.cells.FulfillmentDate.displayControl.styles.classes"
          [matTooltip]="row.cells.FulfillmentDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.FulfillmentDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="RequestedDeliveryDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.RequestedDeliveryDate.displayControl.styles.style"
          [ngClass]="row.cells.RequestedDeliveryDate.displayControl.styles.classes"
          [matTooltip]="row.cells.RequestedDeliveryDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.RequestedDeliveryDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PreferredWarehouseName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.PreferredWarehouseName.displayControl.styles.style"
          [ngClass]="row.cells.PreferredWarehouseName.displayControl.styles.classes"
          [matTooltip]="row.cells.PreferredWarehouseName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.PreferredWarehouseName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PreferredCarrierName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.PreferredCarrierName.displayControl.styles.style"
          [ngClass]="row.cells.PreferredCarrierName.displayControl.styles.classes"
          [matTooltip]="row.cells.PreferredCarrierName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.PreferredCarrierName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PreferredCarrierServiceTypeName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.PreferredCarrierServiceTypeName.displayControl.styles.style"
          [ngClass]="row.cells.PreferredCarrierServiceTypeName.displayControl.styles.classes"
          [matTooltip]="row.cells.PreferredCarrierServiceTypeName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.PreferredCarrierServiceTypeName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CancelThresholdDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.CancelThresholdDate.displayControl.styles.style"
          [ngClass]="row.cells.CancelThresholdDate.displayControl.styles.classes"
          [matTooltip]="row.cells.CancelThresholdDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.CancelThresholdDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReadyToExportDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ReadyToExportDate.displayControl.styles.style"
          [ngClass]="row.cells.ReadyToExportDate.displayControl.styles.classes"
          [matTooltip]="row.cells.ReadyToExportDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ReadyToExportDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.ModifiedDate.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedDate.displayControl.styles.classes"
          [matTooltip]="row.cells.ModifiedDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.ModifiedDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
