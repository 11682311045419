import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './TransloadOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class TransloadOrders_get_transload_shipment_stateService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { orders: { orderId: number, orderStatusId?: number, orderStatus?: string, shipmentId?: number, shipmentStatusId?: number, waveId?: number, waveStatus?: string, waveStatusId?: number }[] }): Promise<{ states?: { orderId?: number, stateId?: number, stateName?: string, has_manual?: boolean, picked_total?: number, expected_total?: number, shipmentId?: number }[], messages?: any[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orders)) {
      missingRequiredInParams.push('\'orders\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/TransloadOrders/functions/get_transload_shipment_state`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

