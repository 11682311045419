import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manual_allocations_grid_ComponentContextService } from './FootprintManager.manual_allocations_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootprintManager_available_inventory_gridComponent } from './FootprintManager.available_inventory_grid.component';

interface IFootprintManager_manual_allocations_gridComponentEntity {
  Id?: number, ExpectedPackagedAmount?: number, OrderLineNumber?: number, WarehouseId?: number, Order?: { Id?: number, LookupCode?: string, OwnerReference?: string, VendorReference?: string, OrderClass?: { AllowInactiveInventory?: boolean, Name?: string } }, Material?: { Id?: number, LookupCode?: string, Name?: string }, Lot?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, ManufactureDate?: string }, ExpectedPackagedPack?: { ShortName?: string }}

interface IFootprintManager_manual_allocations_gridComponentInParams {
  waveId?: number, orderId?: number, allowInactive?: boolean}


class FootprintManager_manual_allocations_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manual_allocations_gridComponent;
  entity: IFootprintManager_manual_allocations_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    Order_LookupCode: null,
    orderline_number: null,
    Order_OwnerReference: null,
    Order_VendorReference: null,
    Material_LookupCode: null,
    ExpectedPackagedAmount: null,
    Material_PackagingLookups_Packaging_ShortName: null,
    Lot_LookupCode: null,
    VendorLot_ExpirationDate: null,
    VendorLot_ManufactureDate: null,
    task_id: null,
  }


  //#region rowExpand inParams


  get $rowExpand_FootprintManager_available_inventory_grid_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Material.Id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_available_inventory_grid_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.WarehouseId;
    
    return expr;
  }


  get $rowExpand_FootprintManager_available_inventory_grid_inParams_recommendedAmount(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.ExpectedPackagedAmount;
    
    return expr;
  }


  get $rowExpand_FootprintManager_available_inventory_grid_inParams_taskId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_available_inventory_grid_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Lot?.Id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_available_inventory_grid_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.VendorLot?.Id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_available_inventory_grid_inParams_allowInactive(): boolean {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Order.OrderClass.AllowInactiveInventory;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    grid: FootprintManager_manual_allocations_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manual_allocations_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.Order_LookupCode = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.Order_LookupCode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.orderline_number = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.orderline_number,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Order_OwnerReference = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Order_OwnerReference,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Order_VendorReference = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Order_VendorReference,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Material_LookupCode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Material_LookupCode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.ExpectedPackagedAmount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.ExpectedPackagedAmount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Material_PackagingLookups_Packaging_ShortName = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Material_PackagingLookups_Packaging_ShortName,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Lot_LookupCode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Lot_LookupCode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.VendorLot_ExpirationDate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.VendorLot_ExpirationDate,
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      );
    
    this.cells.VendorLot_ManufactureDate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.VendorLot_ManufactureDate,
      new TextModel(null, null,  'datetime', 'l, LT', null)
,
null
      );
    
    this.cells.task_id = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.task_id,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_manual_allocations_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manual_allocations_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      waveId:  $grid.inParams.waveId ,
      orderId:  $grid.inParams.orderId ,
    };
    const data = await this.$datasources.SalesOrders.ds_manual_allocations_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manual_allocations_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.Order_LookupCode.displayControl as TextModel).text = $row.entity.Order?.LookupCode;
    (this.cells.orderline_number.displayControl as TextModel).text = $row.entity.OrderLineNumber.toString();;
    (this.cells.Order_OwnerReference.displayControl as TextModel).text = $row.entity.Order?.OwnerReference;
    (this.cells.Order_VendorReference.displayControl as TextModel).text = $row.entity.Order?.VendorReference;
    (this.cells.Material_LookupCode.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.ExpectedPackagedAmount.displayControl as TextModel).text = $row.entity.ExpectedPackagedAmount?.toString();
    (this.cells.Material_PackagingLookups_Packaging_ShortName.displayControl as TextModel).text = $row.entity?.ExpectedPackagedPack?.ShortName;
    (this.cells.Lot_LookupCode.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.VendorLot_ExpirationDate.displayControl as TextModel).text = $row.entity.VendorLot?.ExpirationDate?.toString();
    (this.cells.VendorLot_ManufactureDate.displayControl as TextModel).text = $row.entity.VendorLot?.ManufactureDate?.toString();
    (this.cells.task_id.displayControl as TextModel).text = $row.entity.Id.toString();;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'Order_LookupCode' : {
        this.on_order_code_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_order_code_clicked(event = null) {
    return this.on_order_code_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_code_clickedInternal(
    $row: FootprintManager_manual_allocations_gridComponentRowModel,
  $grid: FootprintManager_manual_allocations_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manual_allocations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  // Check order class type
  const order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $row.entity.Order.Id })).result;
  if ($utils.isDefined(order)) {
      const orderClassTypeId = order.OrderClass.OrderClassTypeId;
      if (orderClassTypeId == 5) {
          $shell.FootprintManager.opensales_order_editor({ orderId: $row.entity.Order.Id });
      }
      else {
          throw new Error('Editor for selected order class type under development!')
      }
  }
  
  
  
  
  }
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootprintManager_manual_allocations_gridComponentRowModel,
  $grid: FootprintManager_manual_allocations_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manual_allocations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manual_allocations_grid.on_material_clicked');
  $shell.FootprintManager.opensingle_material_hub({materialId: $row.entity.Material.Id});
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_available_inventory_gridComponent)
  ],
  selector: 'FootprintManager-manual_allocations_grid',
  templateUrl: './FootprintManager.manual_allocations_grid.component.html'
})
export class FootprintManager_manual_allocations_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manual_allocations_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_manual_allocations_gridComponentInParams = { waveId: null, orderId: null, allowInactive: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     Order_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Order_LookupCode', 'Order', false, false, null, false),       orderline_number: new GridHeaderModel(new HeaderStyles(null, null), 'orderline_number', 'Order line #', false, false, null, false),       Order_OwnerReference: new GridHeaderModel(new HeaderStyles(null, null), 'Order_OwnerReference', 'Owner reference', false, false, null, false),       Order_VendorReference: new GridHeaderModel(new HeaderStyles(null, null), 'Order_VendorReference', 'Vendor reference', false, false, null, false),       Material_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Material_LookupCode', 'Material', false, false, null, false),       ExpectedPackagedAmount: new GridHeaderModel(new HeaderStyles(null, null), 'ExpectedPackagedAmount', 'Expected amount', false, false, null, false),       Material_PackagingLookups_Packaging_ShortName: new GridHeaderModel(new HeaderStyles(null, null), 'Material_PackagingLookups_Packaging_ShortName', 'Packaging', false, false, null, false),       Lot_LookupCode: new GridHeaderModel(new HeaderStyles(null, null), 'Lot_LookupCode', 'Lot', false, false, null, false),       VendorLot_ExpirationDate: new GridHeaderModel(new HeaderStyles(null, null), 'VendorLot_ExpirationDate', 'Lot expiration', false, false, null, false),       VendorLot_ManufactureDate: new GridHeaderModel(new HeaderStyles(null, null), 'VendorLot_ManufactureDate', 'Lot manufacture', false, false, null, false),       task_id: new GridHeaderModel(new HeaderStyles(null, null), 'task_id', 'Task ID', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manual_allocations_gridComponentRowModel[] = [];

  @Input('waveId') set $inParams_waveId(value: any) {
    this.inParams['waveId'] = value;
  }
  get $inParams_waveId(): any {
    return this.inParams['waveId'] ;
  }
  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('allowInactive') set $inParams_allowInactive(value: any) {
    this.inParams['allowInactive'] = value;
  }
  get $inParams_allowInactive(): any {
    return this.inParams['allowInactive'] ;
  }

  topToolbar = {
      release_button: new ToolModel(new ButtonModel('release_button', new ButtonStyles(null, null), false, false, false, 'Release', 'icon-ic_fluent_arrow_circle_up_20_regular', null)
    , false),
      reprocess_button: new ToolModel(new ButtonModel('reprocess_button', new ButtonStyles(null, null), false, false, false, 'Reprocess', 'ms-Icon ms-Icon--WorkFlow', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manual_allocations_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Manual allocations';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 20;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      waveId:  $grid.inParams.waveId ,
      orderId:  $grid.inParams.orderId ,
    };
    try {
      const data = await this.$datasources.SalesOrders.ds_manual_allocations_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manual_allocations_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manual_allocations_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_manual_allocations_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_reprocess_clicked(event = null) {
    return this.on_reprocess_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_reprocess_clickedInternal(
    $grid: FootprintManager_manual_allocations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manual_allocations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $grid: FootprintManager_manual_allocations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manual_allocations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootprintManager.openErrorDialog('Task Cancellation Error', 'No tasks selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              taskId: row.entity.Id
          };
          const result = await $flows.SalesOrders.is_manual_allocation_cancelable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Order ${row.entity.Order.LookupCode.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Cancel manual allocation errors';
          const errorMessage = `Manual allocation(s) ${failures.map(c => c.entity.Order.LookupCode.toString()).join(',')} cannot be cancelled`;
          const errorList = errors;
          await $shell.FootprintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Manual allocation(s) ${candidates.map(c => c.entity.Order.LookupCode.toString()).join(',')} - Once cancelled, the task(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some manual allocation(s) cannot be cancelled';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Cancel the following manual allocation(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          taskId: candidate.entity.Id
                      }
                      const result = await $flows.SalesOrders.cancel_manual_allocation_flow(flowParams);
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          processFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.Order.LookupCode.toString()} - ${reason}`);
                      } else {
                          processSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.Order.LookupCode.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All manual allocation(s) cancelled';
                  const message = `Manual allocation(s) ${processSuccess.map(c => c.entity.Order.LookupCode.toString()).join(',')} cancelled`;
                  await $shell.FootprintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All manual allocation(s) failed to cancel';
                      const message = `Manual allocation(s) ${processFailures.map(c => c.entity.Order.LookupCode.toString()).join(',')} could not be cancelled`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some manual allocation(s) could not be cancelled';
                      const success = `Manual allocation(s) ${processSuccess.map(c => c.entity.Order.LookupCode.toString()).join(',')} were cancelled.`;
                      const errors = `The following manual allocation(s) could not be cancelled: ${processFailures.map(c => c.entity.Order.LookupCode.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_manual_allocations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manual_allocations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  // Cancel
  $grid.topToolbar.cancel_button.control.readOnly = (await checkAllCancelable(selectedRowsCount >= 0));
  
  // Reprocess
  $grid.topToolbar.reprocess_button.control.readOnly = (await checkAllReprocessable(selectedRowsCount >= 0));
  
  // Release
  $grid.topToolbar.release_button.control.readOnly = (await checkAllReleasable(selectedRowsCount >= 0));
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  async function checkAllCancelable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          allow = false;
      }
      return allow;
  }
  async function checkAllReprocessable(allow: boolean) {
      for (let row of $grid.selectedRows) {
          allow = true;
      }
      return allow;
  }
  
  async function checkAllReleasable(allow: boolean) {
      
      for (let row of $grid.selectedRows) {
          let getPickStatus = (await $flows.SalesOrders.is_manual_allocation_releasable_flow({taskId: row.entity.Id})).can_release
          if(getPickStatus === true && allow === true){
              allow = true;
          } else {
              allow = false;
          }
      }
      return allow;
  }
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_manual_allocations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manual_allocations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //hide button until reprocess flow is developed.
  $grid.topToolbar.reprocess_button.hidden = true; 
  
  //Set read only until rows are selected. 
  $grid.topToolbar.release_button.control.readOnly = true;
  
  
  // Hide order related columns when the grid is specifically opened for a order
  if ($utils.isDefined($grid.inParams.orderId)){
  
      $grid.headers.Order_LookupCode.hidden = true;
      $grid.headers.Order_OwnerReference.hidden = true;
      $grid.headers.Order_VendorReference.hidden = true;
  }
  }
  on_release_clicked(event = null) {
    return this.on_release_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_release_clickedInternal(
    $grid: FootprintManager_manual_allocations_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manual_allocations_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootprintManager.openErrorDialog('Task Release Error', 'No tasks selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              taskId: row.entity.Id
          };
          const result = await $flows.SalesOrders.is_manual_allocation_releasable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Order ${row.entity.Order.LookupCode.toString()} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Release manual allocation errors';
          const errorMessage = `Manual allocation(s) ${failures.map(c => c.entity.Order.LookupCode.toString()).join(',')} cannot be released`;
          const errorList = errors;
          await $shell.FootprintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Release manual allocation(s) ${candidates.map(c => c.entity.Order.LookupCode.toString()).join(',')}.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some manual allocation(s) cannot be released';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Release the following manual allocation(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          taskId: candidate.entity.Id
                      }
                      const result = await $flows.SalesOrders.release_manual_allocation_flow(flowParams);
                      const reason = result.reason;
                      if ($utils.isDefined(reason)) {
                          processFailures.push(candidate);
                          errorMsgList.push(`Order ${candidate.entity.Order.LookupCode.toString()} - ${reason}`);
                      } else {
                          processSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order ${candidate.entity.Order.LookupCode.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All manual allocation(s) released';
                  const message = `Manual allocation(s) ${processSuccess.map(c => c.entity.Order.LookupCode.toString()).join(',')} released`;
                  await $shell.FootprintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All manual allocation(s) failed to release';
                      const message = `Manual allocation(s) ${processFailures.map(c => c.entity.Order.LookupCode.toString()).join(',')} could not be released`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some manual allocation(s) could not be released';
                      const success = `Manual allocation(s) ${processSuccess.map(c => c.entity.Order.LookupCode.toString()).join(',')} were released.`;
                      const errors = `The following manual allocation(s) could not be released: ${processFailures.map(c => c.entity.Order.LookupCode.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
