import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Waves_DatasourceService } from './Waves.datasource.index';

interface IWaves_pick_slip_by_wave_id_reportServiceInParams {
  waveId: number}

interface IWaves_pick_slip_by_wave_id_reportServiceData {
  PickSlip?: { result?: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, Order?: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Name?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } }, Shipment?: { Id?: number, ExpectedDate?: string, LookupCode?: string, ShippedDate?: string, WaveId?: number, Wave?: { Id?: number, Warehouse?: { Name?: string } }, Carrier?: { Name?: string, ShortName?: string }, Account?: { Name?: string } }, PickSlip?: { Id?: number, LookupCode?: string }, Lot?: { LookupCode?: string }, OrderLine?: { LineNumber?: number, Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, ExpectedSourceLocation?: { Name?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, Weight?: number }, OrderAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number }, OwnerAddress?: { Id?: number, OwnerId?: number, Address?: { Id?: number, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }
}

@Injectable({ providedIn: 'root' })
export class Waves_pick_slip_by_wave_id_reportService extends ReportBaseService<IWaves_pick_slip_by_wave_id_reportServiceInParams, IWaves_pick_slip_by_wave_id_reportServiceData> {

  protected reportReferenceName = 'pick_slip_by_wave_id_report';
  protected appReferenceName = 'Waves';

  constructor(
    $utils: UtilsService,
    private $datasources: Waves_DatasourceService,
    ) {
      super($utils);
  }

  override throwIfMissingRequiredInParams (inParams: IWaves_pick_slip_by_wave_id_reportServiceInParams) {
  }

  protected async getData(inParams: IWaves_pick_slip_by_wave_id_reportServiceInParams): Promise<IWaves_pick_slip_by_wave_id_reportServiceData> 
  {
    const $utils = this.$utils;
    const $report: { inParams: IWaves_pick_slip_by_wave_id_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IWaves_pick_slip_by_wave_id_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        waveId:  $report.inParams.waveId 
      };
      
      const dsData = await this.$datasources.Waves.ds_outbound_pick_slip_by_wave_id_report.get(dsParams);
      
      data.PickSlip = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
