import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryTransformations_ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackagingService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { inventory_transformation_id: number, material_id?: number, lot_id?: number, vendor_lot_id?: number, licenseplate_id?: number, packaging_id?: number, serial_number_id?: number, location_id?: number, ids?: number[] }): 
  Promise<{ result: { Id?: number, ErrorMessage?: string, InventoryTransformationId?: number, SourceLicensePlateId?: number, SourceLotId?: number, SourceMaterialId?: number, SourcePackageAmount?: number, SourcePackageId?: number, SourceSerialNumberId?: number, SourceVendorLotId?: number, StatusId?: number, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string } }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, InventoryTransformation?: { LookupCode?: string }, Material?: { ControllerTypeId?: number }, Lot?: { Id?: number, LookupCode?: string, ReceiveDate?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, InventoryTransformationLineStatus?: { Name?: string }, SerialNumbers?: { Id?: number, LookupCode?: string, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventory_transformation_id)) {
      missingRequiredInParams.push('\'inventory_transformation_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { inventory_transformation_id: number, material_id?: number, lot_id?: number, vendor_lot_id?: number, licenseplate_id?: number, packaging_id?: number, serial_number_id?: number, location_id?: number, ids?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ErrorMessage?: string, InventoryTransformationId?: number, SourceLicensePlateId?: number, SourceLotId?: number, SourceMaterialId?: number, SourcePackageAmount?: number, SourcePackageId?: number, SourceSerialNumberId?: number, SourceVendorLotId?: number, StatusId?: number, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string } }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, InventoryTransformation?: { LookupCode?: string }, Material?: { ControllerTypeId?: number }, Lot?: { Id?: number, LookupCode?: string, ReceiveDate?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, InventoryTransformationLineStatus?: { Name?: string }, SerialNumbers?: { Id?: number, LookupCode?: string, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventory_transformation_id)) {
      missingRequiredInParams.push('\'inventory_transformation_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { inventory_transformation_id: number, material_id?: number, lot_id?: number, vendor_lot_id?: number, licenseplate_id?: number, packaging_id?: number, serial_number_id?: number, location_id?: number, ids?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, ErrorMessage?: string, InventoryTransformationId?: number, SourceLicensePlateId?: number, SourceLotId?: number, SourceMaterialId?: number, SourcePackageAmount?: number, SourcePackageId?: number, SourceSerialNumberId?: number, SourceVendorLotId?: number, StatusId?: number, LicensePlate?: { LookupCode?: string, Location?: { Id?: number, Name?: string } }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string }, InventoryTransformation?: { LookupCode?: string }, Material?: { ControllerTypeId?: number }, Lot?: { Id?: number, LookupCode?: string, ReceiveDate?: string, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string } }, InventoryTransformationLineStatus?: { Name?: string }, SerialNumbers?: { Id?: number, LookupCode?: string, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number, Name?: string } } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventory_transformation_id)) {
      missingRequiredInParams.push('\'inventory_transformation_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      inventory_transformation_id: number, material_id?: number, lot_id?: number, vendor_lot_id?: number, licenseplate_id?: number, packaging_id?: number, serial_number_id?: number, location_id?: number, ids?: number[]    }) {
        if(isNil(inParams.inventory_transformation_id)) {
          return true; 
        }
      return false;
    }
}
