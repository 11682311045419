import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_dock_appointments_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { locationIds?: number[], typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], fullTextSearch?: string, timeZoneDates?: { timeZoneId?: string, fromDate?: string, toDate?: string }[], warehouseIds?: number[] }): 
  Promise<{ result: { Id?: number, CancelledBy?: string, CancelledOn?: string, CheckedInBy?: string, CheckedInOn?: string, CompletedBy?: string, CompletedOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, ScheduledProject?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, AssignedLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInDate?: { id?: number, convertedDate?: string }, dockAppointmentType?: { Id?: number, Name?: string, Options?: string, SystemDefined?: boolean, SystemLabel?: string }, convertedCompletedOnDate?: { id?: number, convertedDate?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { locationIds?: number[], typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], fullTextSearch?: string, timeZoneDates?: { timeZoneId?: string, fromDate?: string, toDate?: string }[], warehouseIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CancelledBy?: string, CancelledOn?: string, CheckedInBy?: string, CheckedInOn?: string, CompletedBy?: string, CompletedOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, ScheduledProject?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, AssignedLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInDate?: { id?: number, convertedDate?: string }, dockAppointmentType?: { Id?: number, Name?: string, Options?: string, SystemDefined?: boolean, SystemLabel?: string }, convertedCompletedOnDate?: { id?: number, convertedDate?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { locationIds?: number[], typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], fullTextSearch?: string, timeZoneDates?: { timeZoneId?: string, fromDate?: string, toDate?: string }[], warehouseIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CancelledBy?: string, CancelledOn?: string, CheckedInBy?: string, CheckedInOn?: string, CompletedBy?: string, CompletedOn?: string, LookupCode?: string, ReferenceNumber?: string, ScheduledArrival?: string, ScheduledCarrierId?: number, ScheduledDeparture?: string, ScheduledOwnerId?: number, ScheduledProjectId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, ScheduledLocation?: { Id?: number, Name?: string }, ScheduledCarrier?: { Id?: number, Name?: string }, ScheduledOwner?: { Id?: number, LookupCode?: string }, ScheduledProject?: { Id?: number, LookupCode?: string }, Status?: { Id?: number, Name?: string }, AssignedLocation?: { Id?: number, Name?: string }, Warehouse?: { Id?: number, Name?: string, TimeZoneId?: string }, convertedScheduledArrival?: { id?: number, convertedDate?: string }, convertedScheduledDeparture?: { id?: number, convertedDate?: string }, convertedCheckInDate?: { id?: number, convertedDate?: string }, dockAppointmentType?: { Id?: number, Name?: string, Options?: string, SystemDefined?: boolean, SystemLabel?: string }, convertedCompletedOnDate?: { id?: number, convertedDate?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      locationIds?: number[], typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], fullTextSearch?: string, timeZoneDates?: { timeZoneId?: string, fromDate?: string, toDate?: string }[], warehouseIds?: number[]    }) {
      return false;
    }
}
