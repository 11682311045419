import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_location_replenishment_editor_ComponentContextService } from './FootprintManager.location_replenishment_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Replenishments_replenishment_comparison_dd_singleComponent } from './Replenishments.replenishment_comparison_dd_single.component'

type EntityType = { 
    Id?: number, ComparisonTypeId?: number, LocationId?: number, MaterialId?: number, MaximumPackagedAmount?: number, MaximumPackingId?: number, MinimumPackagedAmount?: number, MinimumPackagingId?: number, OnDemand?: boolean, ReplenishmentPackagedAmount?: number, ReplenishmentPackagingId?: number, SystemTriggered?: boolean, ComparisonType?: { DisplayName?: string }, Location?: { Name?: string }, Material?: { Description?: string, LookupCode?: string, Name?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Replenishments_replenishment_comparison_dd_singleComponent),
  ],
  selector: 'FootprintManager-location_replenishment_editor',
  templateUrl: './FootprintManager.location_replenishment_editor.component.html'
})
export class FootprintManager_location_replenishment_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { locationReplenishmentId: number } = { locationReplenishmentId: null };
  //#region Inputs
  @Input('locationReplenishmentId') set $inParams_locationReplenishmentId(v: number) {
    this.inParams.locationReplenishmentId = v;
  }
  get $inParams_locationReplenishmentId(): number {
    return this.inParams.locationReplenishmentId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    location: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    minimum_packaged_quantity: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    minimum_packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    replenish_by_quantity_toggle: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    replenishment_packaged_amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    replenishment_packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    maximum_packaged_amount: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    maximum_packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    rule_trigger: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    system_triggered: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    on_demand: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(['primary'], null), false, false, false, 'Update', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false)
  };

  fields = {
    location: new FieldModel(new TextBoxModel(this.formGroup.controls['location'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Location', false, false),
    material: new FieldModel(new TextBoxModel(this.formGroup.controls['material'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Material', false, false),
    minimum_packaged_quantity: new FieldModel(new NumberBoxModel(this.formGroup.controls['minimum_packaged_quantity'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Minimum quantity', true, false),
    minimum_packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['minimum_packaging'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Minimum packaging', true, false),
    replenish_by_quantity_toggle: new FieldModel(new CheckBoxModel(this.formGroup.controls['replenish_by_quantity_toggle'] as DatexFormControl, null, false, false, 'Replenish by quantity', null)
, new ControlContainerStyles(null, null), '', false, false),
    replenishment_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['replenishment_packaged_amount'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Replenishment quantity', true, false),
    replenishment_packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['replenishment_packaging'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Replenishment packaging', true, false),
    maximum_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['maximum_packaged_amount'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Maximum quantity', false, false),
    maximum_packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['maximum_packaging'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Maximum packaging', false, false),
    rule_trigger: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['rule_trigger'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Trigger if', true, false),
    system_triggered: new FieldModel(new CheckBoxModel(this.formGroup.controls['system_triggered'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Triggered by the system', false, false),
    on_demand: new FieldModel(new CheckBoxModel(this.formGroup.controls['on_demand'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Triggered on demand', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Replenishment information',
      false,
      true,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    newGroup2: new FieldsetModel(
      'Rule trigger',
      false,
      true,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};


  //#region fields inParams
  get $fields_minimum_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.entity.MaterialId;
    
    return expr;
  }

  get $fields_replenishment_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.entity.MaterialId;
    
    return expr;
  }

  get $fields_maximum_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.entity.MaterialId;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    location: this.fields.location.control.valueChanges
    ,
    material: this.fields.material.control.valueChanges
    ,
    minimum_packaged_quantity: this.fields.minimum_packaged_quantity.control.valueChanges
    ,
    minimum_packaging: this.fields.minimum_packaging.control.valueChanges
    ,
    replenish_by_quantity_toggle: this.fields.replenish_by_quantity_toggle.control.valueChanges
    ,
    replenishment_packaged_amount: this.fields.replenishment_packaged_amount.control.valueChanges
    ,
    replenishment_packaging: this.fields.replenishment_packaging.control.valueChanges
    ,
    maximum_packaged_amount: this.fields.maximum_packaged_amount.control.valueChanges
    ,
    maximum_packaging: this.fields.maximum_packaging.control.valueChanges
    ,
    rule_trigger: this.fields.rule_trigger.control.valueChanges
    ,
    system_triggered: this.fields.system_triggered.control.valueChanges
    ,
    on_demand: this.fields.on_demand.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_location_replenishment_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.locationReplenishmentId)) {
        this.$missingRequiredInParams.push('locationReplenishmentId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Rule';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      locationReplenishmentId:  $editor.inParams.locationReplenishmentId 
    };

    const data = await this.$datasources.Replenishments.ds_location_replenishment_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.location.control as TextBoxModel).reset($editor.entity.Location.Name);
    (this.fields.material.control as TextBoxModel).reset($editor.entity.Material.LookupCode);
    (this.fields.minimum_packaged_quantity.control as NumberBoxModel).reset($editor.entity.MinimumPackagedAmount);
    (this.fields.minimum_packaging.control as SelectBoxModel).reset($editor.entity.MinimumPackagingId);
    (this.fields.replenish_by_quantity_toggle.control as CheckBoxModel).reset(false);
    (this.fields.replenishment_packaged_amount.control as NumberBoxModel).reset($editor.entity.ReplenishmentPackagedAmount);
    (this.fields.replenishment_packaging.control as SelectBoxModel).reset($editor.entity.ReplenishmentPackagingId);
    (this.fields.maximum_packaged_amount.control as NumberBoxModel).reset($editor.entity.MaximumPackagedAmount);
    (this.fields.maximum_packaging.control as SelectBoxModel).reset($editor.entity.MaximumPackingId);
    (this.fields.rule_trigger.control as SelectBoxModel).reset($editor.entity.ComparisonTypeId);
    (this.fields.system_triggered.control as CheckBoxModel).reset($editor.entity.SystemTriggered);
    (this.fields.on_demand.control as CheckBoxModel).reset($editor.entity.OnDemand);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .location
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .minimum_packaged_quantity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .minimum_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .replenish_by_quantity_toggle
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_replenish_by_quantity_toggled();
      });
    this.$formGroupFieldValidationObservables
      .replenishment_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .replenishment_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .maximum_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .maximum_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .rule_trigger
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .system_triggered
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .on_demand
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_update_clicked(event = null) {
    return this.on_update_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_clickedInternal(
    $editor: FootprintManager_location_replenishment_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_location_replenishment_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $editor.fields.minimum_packaged_quantity.control.value,
      $editor.fields.minimum_packaging.control.value,
      $editor.fields.rule_trigger.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      throw new Error('Missing data in mandatory fields.')
  }
  
  
  // Validations
  if ($utils.isDefined($editor.fields.maximum_packaging.control.value) && !$utils.isDefined($editor.fields.maximum_packaged_amount.control.value)) {
      throw new Error('Maximum amount value is missing.')
  }
  if (!$utils.isDefined($editor.fields.maximum_packaging.control.value) && $utils.isDefined($editor.fields.maximum_packaged_amount.control.value)) {
      throw new Error('Maximum packaging must be selected.')
  }
  
  if ($utils.isDefined($editor.fields.minimum_packaged_quantity.control.value)) {
      if ($editor.fields.minimum_packaged_quantity.control.value < 0) {
          throw new Error('Minimum amount cannot be negative')
      }
  }
  if ($utils.isDefined($editor.fields.replenishment_packaged_amount.control.value)) {
      if ($editor.fields.replenishment_packaged_amount.control.value < 0) {
          throw new Error('Replenishment amount cannot be negative')
      }
  }
  
  if ($utils.isDefined($editor.fields.maximum_packaged_amount.control.value)) {
      if ($editor.fields.maximum_packaged_amount.control.value <= 0) {
          throw new Error('Maximum amount must be greater than 0')
      }
  }
  //Default values when only a minimum is set. 
  if(!$editor.fields.replenish_by_quantity_toggle.control.value){
      $editor.fields.replenishment_packaged_amount.control.value = $editor.fields.minimum_packaged_quantity.control.value
      $editor.fields.replenishment_packaging.control.value = $editor.fields.minimum_packaging.control.value
  }
  
  
  
  try {
      const locationReplenishmentRequest = (await $flows.Replenishments.update_location_replenishment_threshold_flow({
          locationReplenishmentId: $editor.entity.Id,
          minimumPackagedAmount: $editor.fields.minimum_packaged_quantity.control.value,
          minimumPackagingId: $editor.fields.minimum_packaging.control.value,
          replenishmentPackagedAmount: $editor.fields.replenishment_packaged_amount.control.value,
          replenishmentPackagingId: $editor.fields.replenishment_packaging.control.value,
          maximumPackagedAmount: $editor.fields.maximum_packaged_amount.control.value,
          maximumPackingId: $editor.fields.maximum_packaging.control.value,
          onDemand: $editor.fields.on_demand.control.value,
          systemTriggered: $editor.fields.system_triggered.control.value,
          comparisonTypeId: $editor.fields.rule_trigger.control.value
      }));
  
  
  
      if ($utils.isDefined(locationReplenishmentRequest)) {
          if ($utils.isDefined(locationReplenishmentRequest.reasons)) {
              var errorMessage = locationReplenishmentRequest.reasons;
              throw errorMessage;
          }
          else {
  
              $editor.outParams.confirm = true;
              $editor.close();
          }
      }
      else {
          $editor.outParams.confirm = true;
          $editor.close();
      }
  
  }
  catch (error) {
      $shell.FootprintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // To prevent display mode
  }
  
  
  
  
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootprintManager_location_replenishment_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_location_replenishment_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootprintManager_location_replenishment_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_location_replenishment_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.on_replenish_by_quantity_toggled();
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootprintManager_location_replenishment_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_location_replenishment_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $editor.entity.MaterialId
  var packagingId = $editor.entity.MinimumPackagingId
  
  if ($utils.isAllDefined(materialId, packagingId)) {
  
      // Default the decimal formatting
      const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
          materialId: materialId,
          packagingId: packagingId
      }));
  
      if ($utils.isDefined(decimalFormat)) {
          $editor.fields.minimum_packaged_quantity.control.format = decimalFormat.format;
          $editor.fields.replenishment_packaged_amount.control.format = decimalFormat.format;
          $editor.fields.maximum_packaged_amount.control.format = decimalFormat.format;
  
      }
  
  }
  
  }
  on_replenish_by_quantity_toggled(event = null) {
    return this.on_replenish_by_quantity_toggledInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_replenish_by_quantity_toggledInternal(
    $editor: FootprintManager_location_replenishment_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_location_replenishment_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  if ($editor.fields.replenish_by_quantity_toggle.control.value) {
      //Shown
      $editor.fields.replenishment_packaged_amount.hidden = false
      $editor.fields.replenishment_packaging.hidden = false
      $editor.fields.maximum_packaged_amount.hidden = false
      $editor.fields.maximum_packaging.hidden = false
  
      //Required
      $editor.fields.replenishment_packaged_amount.required = true
      $editor.fields.replenishment_packaging.required = true
  } else {
      //Shown
      $editor.fields.replenishment_packaged_amount.hidden = true
      $editor.fields.replenishment_packaging.hidden = true
      $editor.fields.maximum_packaged_amount.hidden = true
      $editor.fields.maximum_packaging.hidden = true
  
      //Required
      $editor.fields.replenishment_packaged_amount.required = false
      $editor.fields.replenishment_packaging.required = false
  }
  }
  //#endregion private flows
  
}
