import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Locations.frontend.types'

@Injectable({ providedIn: 'root' })
export class Locations_create_location_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { warehouseId: number, parentId?: number, statusId: number, typeId: number, name: string, shortName?: string, isLoose?: boolean, pickSequence?: number, allocationSequence?: number, putAwaySequence?: number, globalPickSequence?: number, globalAllocationSequence?: number, globalPutAwaySequence?: number, eligibleForAllocation?: boolean, weightCapacity?: number, weightUomId?: number, length?: number, width?: number, height?: number, dimensionUomId?: number, volumeUomId?: number, enabled?: boolean, volumeCapacity?: number, childFootprint?: number, childStackHeight?: number, manufacturingLineFlag?: boolean, mixingRuleId?: number, restrictToReplenishmentMaterials?: boolean, purgeAddedInventory?: boolean, isPrimaryPick?: boolean, allowInventoryIfNotActive?: boolean, skipCycleCount?: boolean, eligibleForManufacturingFeedback?: boolean, isManufacturingStagingLocation?: boolean, storageCategoryId?: number, allowMoveTaskCreation?: boolean, disableNonPreferredLocationAlertCreation?: boolean, scannableLookup?: string, skipValidationRules?: boolean, breakInventoryToBasePackaging?: boolean, confirmInventoryDeletion?: boolean, correlationId?: string, eligibleForScaleTicket?: boolean, skipAfterPickingCountBack?: boolean, countBackThreshold?: number, mobilePickingMaterialUPCIncrementScanningBypassTreshold?: number, mobileReplenishmentMaterialUPCIncrementScanningBypassTreshold?: number, autoSelectCountOnCountBack?: boolean, mobileForceUserCountBack?: boolean, isDynamicPrimaryPick?: boolean, isPackageVerification?: boolean, attachedPrinter?: string, isUsedForReturns?: boolean, allowOnlyCLPs?: boolean }): Promise<{ reasons?: string[], newLocationId?: number, newLooseLicensePlateId?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.statusId)) {
      missingRequiredInParams.push('\'statusId\'');
    }
    if (isNil(inParams.typeId)) {
      missingRequiredInParams.push('\'typeId\'');
    }
    if (isNil(inParams.name)) {
      missingRequiredInParams.push('\'name\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Locations/functions/create_location_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

