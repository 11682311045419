<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-enable_optimizer" *ngIf="!filters.enable_optimizer.hidden && !filters.enable_optimizer.removed" 
            class="field-container standard {{filters.enable_optimizer.invalid ? 'invalid' : ''}} {{filters.enable_optimizer.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.enable_optimizer.styles.style"
            [ngClass]="filters.enable_optimizer.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.enable_optimizer.label + (filters.enable_optimizer.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.enable_optimizer.label}}<span *ngIf="filters.enable_optimizer.required" class="required-asterisk">*</span>
          </label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="enable_optimizer"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.enable_optimizer.control.styles.style"
                      [ngClass]="filters.enable_optimizer.control.styles.classes"
                      [matTooltip]="filters.enable_optimizer.control.tooltip"
                      matTooltipClass="datex-control-tooltip">{{filters.enable_optimizer.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.enable_optimizer.invalid">
          <ng-container *ngFor="let error of filters.enable_optimizer.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Material_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Material_Name.displayControl.styles.style"
          [ngClass]="row.cells.Material_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.Material_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Material_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="FinishedProduct_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.FinishedProduct_Name.displayControl.styles.style"
          [ngClass]="row.cells.FinishedProduct_Name.displayControl.styles.classes"
          [matTooltip]="row.cells.FinishedProduct_Name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.FinishedProduct_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PlannedQuantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.PlannedQuantity.displayControl.styles.style"
          [ngClass]="row.cells.PlannedQuantity.displayControl.styles.classes"
          [matTooltip]="row.cells.PlannedQuantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.PlannedQuantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="IsNormalWaveRelease">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.IsNormalWaveRelease.displayControl.styles.style"
          [ngClass]="row.cells.IsNormalWaveRelease.displayControl.styles.classes"
          [matTooltip]="row.cells.IsNormalWaveRelease.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.IsNormalWaveRelease.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MaterialSource">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.MaterialSource.displayControl.styles.style"
          [ngClass]="row.cells.MaterialSource.displayControl.styles.classes"
          [matTooltip]="row.cells.MaterialSource.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.MaterialSource.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Availability">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Availability.displayControl.styles.style"
          [ngClass]="row.cells.Availability.displayControl.styles.classes"
          [matTooltip]="row.cells.Availability.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Availability.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SourceWarehouses">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.SourceWarehouses.displayControl.styles.style"
          [ngClass]="row.cells.SourceWarehouses.displayControl.styles.classes"
          [matTooltip]="row.cells.SourceWarehouses.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.SourceWarehouses.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
