import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService } from './FootprintManager.manufacturing_order_lines_production_sub_components_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_license_plates_by_location_with_inventory_singleComponent } from './Manufacturing.license_plates_by_location_with_inventory_single.component'
import { Manufacturing_lots_with_inventory_by_license_plate_dd_singleComponent } from './Manufacturing.lots_with_inventory_by_license_plate_dd_single.component'


interface IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentEntity {
  id?: number, lineNumber?: number, component?: string, consumedQuantity?: number, scrappedQuantity?: number, unusedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, packageId?: number, componentMaterialId?: number}

interface IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentInParams {
  material_id?: number, location_id?: number, component?: string, main_component_identifier?: number, rows?: { lineNumber: number, consumedQuantity: number, scrappedQuantity: number, sourceLicensePlate: number, lot: number, lotExpiration: string, mainComponentIdentifier: number, packageId: number, componentMaterialId: number }[], package_id?: number, project_id?: number, warehouse_id?: number}

interface IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentOutParams {
  rows?: { lineNumber?: number, component?: string, consumedQuantity?: number, scrappedQuantity?: number, unusedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[]}

class FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent;
  entity: IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentEntity;


  options: { delete_line: ButtonModel } = {
    delete_line: new ButtonModel('delete_line', new ButtonStyles(null, null), false, false, false, 'Delete line', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    source_license_plate_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    consumed_quantity_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    scrapped_quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_expiration_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    line_number: null,
    source_license_plate: null,
    consumed_quantity: null,
    scrapped_quantity: null,
    lot: null,
    lot_expiration: null,
  }

  get $fields_source_license_plate_selector_inParams_material_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.material_id;
    
    return expr;
  }
  get $fields_source_license_plate_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }
  get $fields_source_license_plate_selector_inParams_location_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.location_id;
    
    return expr;
  }
  get $fields_lot_selector_inParams_material_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.material_id;
    
    return expr;
  }
  get $fields_lot_selector_inParams_license_plate(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.source_license_plate.editControl.value;
    
    return expr;
  }
  get $fields_lot_selector_inParams_location(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.location_id;
    
    return expr;
  }
  get $fields_lot_selector_inParams_package_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.package_id;
    
    return expr;
  }
  get $fields_lot_selector_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouse_id;
    
    return expr;
  }


  constructor(
    grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.line_number = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.line_number,
      new TextModel(null, null, null, null, null)
,
      new TextModel(null, null, null, null, null)
      );
    
    this.cells.source_license_plate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.source_license_plate,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['source_license_plate_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.consumed_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.consumed_quantity,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['consumed_quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.scrapped_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.scrapped_quantity,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['scrapped_quantity_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.lot_expiration = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot_expiration,
      new DateBoxModel(this.formGroup.controls['lot_expiration_display'] as DatexFormControl, null, true, false, '', 'date', null)
,
null
      );
    
    
    this.formGroup
      .controls['source_license_plate_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_source_license_plate_changed();
      });
    this.formGroup
      .controls['consumed_quantity_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_consumed_quantity_changed();
      });
    this.formGroup
      .controls['scrapped_quantity_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_scrapped_quantity_changed();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_changed();
      });
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.id,this.entity.lineNumber].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id,this.entity.lineNumber].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'id',this.entity.id);
    set($resultKey, 'lineNumber',this.entity.lineNumber);
    const inParams = {
      $keys:[$resultKey],
      rows:  $grid.inParams.rows ,
      main_component_identifier:  $grid.inParams.main_component_identifier ,
      component:  $grid.inParams.component ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_lines_production_sub_components_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.line_number.displayControl as TextModel).text = $row.entity?.lineNumber?.toString();
    (this.cells.line_number.editControl as TextModel).text = $row.entity?.lineNumber?.toString();
    (this.cells.source_license_plate.editControl as SelectBoxModel).reset($row.entity?.sourceLicensePlate);
    (this.cells.consumed_quantity.editControl as NumberBoxModel).reset($row.entity?.consumedQuantity);
    (this.cells.scrapped_quantity.editControl as NumberBoxModel).reset($row.entity?.scrappedQuantity);
    (this.cells.lot_expiration.displayControl as DateBoxModel).reset($row.entity?.lotExpiration);

    await this.on_init_row();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_save_new_row');
  try
  {
      if( $utils.isDefined($row.cells.consumed_quantity.editControl.value)
       && $utils.isDefined($row.cells.source_license_plate.editControl.value)
       && $utils.isDefined($row.cells.lot.editControl.value) )
      {
          const lot = (await $datasources.Manufacturing.ds_physical_inventory_by_license_plate_lot.get({material_id: $grid.inParams.material_id
                                                                                                 , lot_id: $row.cells.lot.editControl.value.LotId
                                                                                                 , license_plate_id: $row.cells.source_license_plate.editControl.value
                                                                                                 , location_id: $grid.inParams.location_id
                                                                                                 , package_id: $grid.inParams.package_id
                                                                                                 , warehouse_id: $grid.inParams.warehouse_id}))
  
          console.log($row.cells.scrapped_quantity.editControl.value);
          console.log($row.cells.consumed_quantity.editControl.value);
  
          if( $utils.isDefined(lot.result) && lot.result.length > 0 && lot.result[0].PackagedAmount < ( ( $row.cells.consumed_quantity.editControl.value ?? 0 ) + ( $row.cells.scrapped_quantity.editControl.value ?? 0) ) )
          {
              await $shell.Utilities.openInfoDialog(`Not enough availability`, `Not enough availability for the selected lot. Current availability: ${lot.result[0].PackagedAmount}. Availability needed: ${(($row.cells.consumed_quantity.editControl.value ?? 0) + ($row.cells.scrapped_quantity.editControl.value ?? 0))}.`);
              throw new Error('Not enough availability');
          }
      }
  }
  catch (error) {
      throw error; // to prevent displayMode 
  }
  
  let maxItem = $grid.rows.reduce((max, item) => 
    parseFloat(item.cells.line_number.displayControl.text) > parseFloat(max.cells.line_number.displayControl.text) 
      ? item 
      : max, 
    $grid.rows[0]
  );
  
  let maxLineNumber = parseFloat(maxItem.cells.line_number.displayControl.text) || 0;
  
  // Increment the max line number
  maxLineNumber++;
  
  $row.cells.line_number.displayControl.text = maxLineNumber.toString();
  $row.cells.line_number.editControl.text = maxLineNumber.toString();
  $row.cells.scrapped_quantity.displayControl.text = $row.cells.scrapped_quantity.editControl.value;
  $row.cells.consumed_quantity.displayControl.text = $row.cells.consumed_quantity.editControl.value;
  
  const lotRequest = (await $datasources.Manufacturing.ds_get_inventory_by_license_plate_lot.get({material_id: $grid.inParams.material_id
                                                                                         , lot_id: $row.cells.lot.editControl.value.LotId
                                                                                         , license_plate: $row.cells.source_license_plate.editControl.value
                                                                                         , location: $grid.inParams.location_id
                                                                                         , package_id: $grid.inParams.package_id
                                                                                         , warehouse_id: $grid.inParams.warehouse_id}))
  
  if( $utils.isDefined(lotRequest.result) && lotRequest.result.length > 0 )
  {
      const lot = lotRequest.result[0];
      $row.cells.lot.displayControl.text = lot.Lot.LookupCode;
      $row.cells.lot_expiration.displayControl.value = lot.Lot?.VendorLot?.ExpirationDate;
  }
  
  const licensePlateRequest = await $datasources.Manufacturing.ds_get_licenseplates_by_locationId.get({locationId: $grid.inParams.location_id, license_plate_ids: [$row.cells.source_license_plate.editControl.value]});
  if( $utils.isDefined(licensePlateRequest) && licensePlateRequest.result.length > 0 )
  {
      const licensePlate = licensePlateRequest.result[0];
      $row.cells.source_license_plate.displayControl.text = licensePlate?.LookupCode;
  }
  
  const rowData = {lineNumber: $row.cells.line_number.displayControl.text
                  , mainComponentIdentifier: $grid.inParams.main_component_identifier
                  , consumedQuantity: $row.cells.consumed_quantity.editControl.value
                  , scrappedQuantity: $row.cells.scrapped_quantity.editControl.value
                  , sourceLicensePlate: $row.cells.source_license_plate.editControl.value
                  , lot: $row.cells.lot.editControl.value.LotId
                  , lotExpiration: $row.cells.lot_expiration.displayControl.value
                  , packageId: $grid.inParams.package_id
                  , componentMaterialId: $grid.inParams.material_id};
  
  $grid.events.row_add.emit(rowData);
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_save_existing_row');
  try
  {
      if( $utils.isDefined($row.cells.consumed_quantity.editControl.value)
       && $utils.isDefined($row.cells.source_license_plate.editControl.value)
       && $utils.isDefined($row.cells.lot.editControl.value) )
      {
          const lot = (await $datasources.Manufacturing.ds_physical_inventory_by_license_plate_lot.get({material_id: $grid.inParams.material_id
                                                                                                 , lot_id: $row.cells.lot.editControl.value.LotId
                                                                                                 , license_plate_id: $row.cells.source_license_plate.editControl.value
                                                                                                 , location_id: $grid.inParams.location_id
                                                                                                 , package_id: $grid.inParams.package_id
                                                                                                 , warehouse_id: $grid.inParams.warehouse_id}))
  
          console.log($row.cells.scrapped_quantity.editControl.value);
          console.log($row.cells.consumed_quantity.editControl.value);
  
          if( $utils.isDefined(lot.result) && lot.result.length > 0 && lot.result[0].PackagedAmount < ( ( $row.cells.consumed_quantity.editControl.value ?? 0 ) + ( $row.cells.scrapped_quantity.editControl.value ?? 0) ) )
          {
              await $shell.Utilities.openInfoDialog(`Not enough availability`, `Not enough availability for the selected lot. Current availability: ${lot.result[0].PackagedAmount}. Availability needed: ${(($row.cells.consumed_quantity.editControl.value ?? 0) + ($row.cells.scrapped_quantity.editControl.value ?? 0))}.`);
              throw new Error('Not enough availability');
          }
      }
  }
  catch (error) {
      throw error; // to prevent displayMode 
  }
  
  $row.cells.scrapped_quantity.displayControl.text = $row.cells.scrapped_quantity.editControl.value;
  $row.cells.consumed_quantity.displayControl.text = $row.cells.consumed_quantity.editControl.value;
  
  const lotRequest = (await $datasources.Manufacturing.ds_get_inventory_by_license_plate_lot.get({material_id: $grid.inParams.material_id
                                                                                         , lot_id: $row.cells.lot.editControl.value.LotId
                                                                                         , license_plate: $row.cells.source_license_plate.editControl.value
                                                                                         , location: $grid.inParams.location_id
                                                                                         , package_id: $grid.inParams.package_id
                                                                                         , warehouse_id: $grid.inParams.warehouse_id}))
  
  if( $utils.isDefined(lotRequest.result) && lotRequest.result.length > 0 )
  {
      const lot = lotRequest.result[0];
      $row.cells.lot.displayControl.text = lot.Lot.LookupCode;
      $row.cells.lot_expiration.displayControl.value = lot.Lot?.VendorLot?.ExpirationDate;
  }
  
  const licensePlateRequest = await $datasources.Manufacturing.ds_get_licenseplates_by_locationId.get({locationId: $grid.inParams.location_id, license_plate_ids: [$row.cells.source_license_plate.editControl.value]});
  if( $utils.isDefined(licensePlateRequest) && licensePlateRequest.result.length > 0 )
  {
      const licensePlate = licensePlateRequest.result[0];
      $row.cells.source_license_plate.displayControl.text = licensePlate?.LookupCode;
  }
  
  const rowData = {lineNumber: $row.cells.line_number.displayControl.text
                  , mainComponentIdentifier: $grid.inParams.main_component_identifier
                  , consumedQuantity: $row.cells.consumed_quantity.editControl.value
                  , scrappedQuantity: $row.cells.scrapped_quantity.editControl.value
                  , sourceLicensePlate: $row.cells.source_license_plate.editControl.value
                  , lot: $row.cells.lot.editControl.value.LotId
                  , lotExpiration: $row.cells.lot_expiration.displayControl.value
                  , packageId: $grid.inParams.package_id
                  , componentMaterialId: $row.entity.componentMaterialId};
  
  $grid.events.row_modify.emit(rowData);
  
  $grid.refresh();
  }
  on_lot_changed(event = null) {
    return this.on_lot_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changedInternal(
    $row: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_lot_changed');
  
  if( !$utils.isDefined($row.cells.lot.editControl.value?.LotId) )
      return;
  
  $row.entity.lot = $row.cells.lot.editControl.value?.LotId;
  
  const lot = (await $datasources.Manufacturing.ds_get_inventory_by_license_plate_lot.get({material_id: $grid.inParams.material_id
                                                                                         , lot_id: $row.entity.lot
                                                                                         , license_plate: $row.cells.source_license_plate.editControl.value
                                                                                         , location: $grid.inParams.location_id
                                                                                         , package_id: $grid.inParams.package_id
                                                                                         , warehouse_id: $grid.inParams.warehouse_id}))
  
  if( $utils.isDefined(lot.result) && lot.result.length > 0 )
  {
      $row.cells.lot_expiration.displayControl.value = lot.result[0]?.Lot.VendorLot?.ExpirationDate;
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $row: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_delete_clicked');
  
  const rowForDeletion = {mainComponentIdentifier: $row.entity.id, lineNumber: $row.cells.line_number.displayControl.text};
  
  let index = $grid.rows.findIndex(item => 
    parseFloat(item.cells.line_number.displayControl.text) === parseFloat($row.cells.line_number.displayControl.text)
  );
  
  if (index !== -1) {
    $grid.rows.splice(index, 1);
  }
  
  $grid.events.row_delete.emit(rowForDeletion);
  }
  on_init_row(event = null) {
    return this.on_init_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_rowInternal(
    $row: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_init_row');
  
  }
  on_consumed_quantity_changed(event = null) {
    return this.on_consumed_quantity_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_consumed_quantity_changedInternal(
    $row: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_consumed_quantity_changed');
  
  }
  on_scrapped_quantity_changed(event = null) {
    return this.on_scrapped_quantity_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_scrapped_quantity_changedInternal(
    $row: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_scrapped_quantity_changed');
  
  }
  on_source_license_plate_changed(event = null) {
    return this.on_source_license_plate_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_source_license_plate_changedInternal(
    $row: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_source_license_plate_changed');
  
  $row.cells.lot.editControl.value = null;
  $row.cells.lot.displayControl.text = "";
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_license_plates_by_location_with_inventory_singleComponent),
    forwardRef(() => Manufacturing_lots_with_inventory_by_license_plate_dd_singleComponent),
  ],
  selector: 'FootprintManager-manufacturing_order_lines_production_sub_components_grid',
  templateUrl: './FootprintManager.manufacturing_order_lines_production_sub_components_grid.component.html'
})
export class FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);


  inParams: IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentInParams = { material_id: null, location_id: null, component: null, main_component_identifier: null, rows: [], package_id: null, project_id: null, warehouse_id: null };

  outParams: IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentOutParams = { rows: [] };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  row_modify = new EventEmitter<{ mainComponentIdentifier?: number, lineNumber?: string, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, packageId?: number, componentMaterialId?: number }>();
  @Output()
  row_add = new EventEmitter<{ mainComponentIdentifier?: number, lineNumber?: string, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, packageId?: number, componentMaterialId?: number }>();
  @Output()
  row_delete = new EventEmitter<{ mainComponentIdentifier?: number, lineNumber?: string }>();
  @Output()
  outParamsChange = new EventEmitter<{ rows?: { lineNumber?: number, component?: string, consumedQuantity?: number, scrappedQuantity?: number, unusedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[] }>();
  
  events = {
    row_modify: this.row_modify,
    row_add: this.row_add,
    row_delete: this.row_delete,
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     line_number: new GridHeaderModel(new HeaderStyles(null, null), 'line_number', '#', false, false, null, false),       source_license_plate: new GridHeaderModel(new HeaderStyles(null, null), 'source_license_plate', 'Source license plate', true, false, null, false),       consumed_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'consumed_quantity', 'Consumed quantity', true, false, null, false),       scrapped_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'scrapped_quantity', 'Scrapped quantity', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', true, false, null, false),       lot_expiration: new GridHeaderModel(new HeaderStyles(null, null), 'lot_expiration', 'Lot expiration', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel[] = [];

  @Input('material_id') set $inParams_material_id(value: any) {
    this.inParams['material_id'] = value;
  }
  get $inParams_material_id(): any {
    return this.inParams['material_id'] ;
  }
  @Input('location_id') set $inParams_location_id(value: any) {
    this.inParams['location_id'] = value;
  }
  get $inParams_location_id(): any {
    return this.inParams['location_id'] ;
  }
  @Input('component') set $inParams_component(value: any) {
    this.inParams['component'] = value;
  }
  get $inParams_component(): any {
    return this.inParams['component'] ;
  }
  @Input('main_component_identifier') set $inParams_main_component_identifier(value: any) {
    this.inParams['main_component_identifier'] = value;
  }
  get $inParams_main_component_identifier(): any {
    return this.inParams['main_component_identifier'] ;
  }
  @Input('rows') set $inParams_rows(value: any) {
    this.inParams['rows'] = value;
  }
  get $inParams_rows(): any {
    return this.inParams['rows'] ;
  }
  @Input('package_id') set $inParams_package_id(value: any) {
    this.inParams['package_id'] = value;
  }
  get $inParams_package_id(): any {
    return this.inParams['package_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Selecting component for manufacturing production';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      rows:  $grid.inParams.rows ,
      main_component_identifier:  $grid.inParams.main_component_identifier ,
      component:  $grid.inParams.component ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_lines_production_sub_components_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_init_flow();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  async addRow(entity?: IFootprintManager_manufacturing_order_lines_production_sub_components_gridComponentEntity) {
    const row = new FootprintManager_manufacturing_order_lines_production_sub_components_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init_flow(event = null) {
    return this.on_init_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_flowInternal(
    $grid: FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_lines_production_sub_components_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_lines_production_sub_components_grid.on_init_flow');
  
  $grid.rows.forEach( async x => {
      x.cells.line_number.displayControl.text = x.entity?.lineNumber?.toString();
      x.cells.line_number.editControl.text = x.entity?.lineNumber?.toString();
      x.cells.consumed_quantity.displayControl.text = x.entity?.consumedQuantity?.toString();
      
      if( $utils.isDefined(x.entity?.consumedQuantity) )
          x.cells.consumed_quantity.editControl.value = x.entity?.consumedQuantity;
      
      if( $utils.isDefined(x.entity?.scrappedQuantity) )
          x.cells.scrapped_quantity.editControl.value = x.entity?.scrappedQuantity;
      
      x.cells.scrapped_quantity.displayControl.text = x.entity?.scrappedQuantity?.toString();
      x.cells.source_license_plate.editControl.value= x.entity?.sourceLicensePlate;
      
      const lotRequest = (await $datasources.Manufacturing.ds_get_inventory_by_license_plate_lot.get({material_id: $grid.inParams.material_id
                                                                                         , lot_id: x.entity.lot
                                                                                         , license_plate: x.cells.source_license_plate.editControl.value
                                                                                         , location: $grid.inParams.location_id
                                                                                         , package_id: $grid.inParams.package_id
                                                                                         , warehouse_id: $grid.inParams.warehouse_id}))
  
      if( $utils.isDefined(lotRequest.result) && lotRequest.result.length > 0 )
      {
          const lot = lotRequest.result[0];
          x.cells.lot.displayControl.text = lot.Lot.LookupCode;
          x.cells.lot_expiration.displayControl.value = lot.Lot?.VendorLot?.ExpirationDate;
          x.cells.lot.editControl.value = {LicensePlateId: x.entity?.sourceLicensePlate, LotId: x.entity?.lot, PackagedId: x.entity?.packageId};
      }
      
      const licensePlateRequest = await $datasources.Manufacturing.ds_get_licenseplates_by_locationId.get({locationId: $grid.inParams.location_id, license_plate_ids: [x.cells.source_license_plate.editControl.value]});
      if( $utils.isDefined(licensePlateRequest) && licensePlateRequest.result.length > 0 )
      {
          const licensePlate = licensePlateRequest.result[0];
          x.cells.source_license_plate.displayControl.text = licensePlate?.LookupCode;
      }
      
      x.cells.lot_expiration.displayControl.value = x.entity?.lotExpiration;
  })
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
