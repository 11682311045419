import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './WorkOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class WorkOrders_create_accessorial_task_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { project_id: number, expected_amount: number, expected_uom: number, operation_code_id: number, warehouse_id: number, action: $frontendTypes.WorkOrders.EAccessorialTaskOption, additional_properties?: { notes?: string, work_order_id?: number, order_id?: number, shipment_id?: number, license_plate_id?: number, shipping_container_id?: number } }): Promise<{ reason?: string, task_id?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_id)) {
      missingRequiredInParams.push('\'project_id\'');
    }
    if (isNil(inParams.expected_amount)) {
      missingRequiredInParams.push('\'expected_amount\'');
    }
    if (isNil(inParams.expected_uom)) {
      missingRequiredInParams.push('\'expected_uom\'');
    }
    if (isNil(inParams.operation_code_id)) {
      missingRequiredInParams.push('\'operation_code_id\'');
    }
    if (isNil(inParams.warehouse_id)) {
      missingRequiredInParams.push('\'warehouse_id\'');
    }
    if (isNil(inParams.action)) {
      missingRequiredInParams.push('\'action\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WorkOrders/functions/create_accessorial_task_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

