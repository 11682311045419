import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryTransformations_ds_inventory_transformation_target_lines_total_packaged_amountService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { inventory_transformation_id: number, line_numbers?: number[], source_line_number?: number }): 
  Promise<{ result: { total_target_packaged_amount?: number, InventoryTransformationId?: number } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventory_transformation_id)) {
      missingRequiredInParams.push('\'inventory_transformation_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransformations/datasources/ds_inventory_transformation_target_lines_total_packaged_amount/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      inventory_transformation_id: number, line_numbers?: number[], source_line_number?: number    }) {
        if(isNil(inParams.inventory_transformation_id)) {
          return true; 
        }
      return false;
    }
}
