import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ds_serial_number_total_amount_widgetService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseIds?: number[], ownerId?: number, projectId?: number, materialIds?: number[], archived?: boolean }): 
  Promise<{ result: { TotalAmount?: number } }> 
  {
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serial_number_total_amount_widget/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouseIds?: number[], ownerId?: number, projectId?: number, materialIds?: number[], archived?: boolean    }) {
      return false;
    }
}
