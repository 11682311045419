import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_purchase_order_lines_grid_ediService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, lineNumbers: number[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Color?: string, EstablishmentIdentifier?: string, Marks?: string, MaterialAlias?: string, PurchaseOrderIdentifier?: string, ResidueHoldIdentifier?: string, Size?: string, Style?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.lineNumbers)) {
      missingRequiredInParams.push('\'lineNumbers\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_purchase_order_lines_grid_edi/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, lineNumbers: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Color?: string, EstablishmentIdentifier?: string, Marks?: string, MaterialAlias?: string, PurchaseOrderIdentifier?: string, ResidueHoldIdentifier?: string, Size?: string, Style?: string }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.lineNumbers)) {
      missingRequiredInParams.push('\'lineNumbers\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_purchase_order_lines_grid_edi/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, lineNumbers: number[], $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Color?: string, EstablishmentIdentifier?: string, Marks?: string, MaterialAlias?: string, PurchaseOrderIdentifier?: string, ResidueHoldIdentifier?: string, Size?: string, Style?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.lineNumbers)) {
      missingRequiredInParams.push('\'lineNumbers\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_purchase_order_lines_grid_edi/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, lineNumbers: number[]    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
        if(isNil(inParams.lineNumbers)) {
          return true; 
        }
      return false;
    }
}
