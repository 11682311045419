import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { DockAppointments_OperationService } from './DockAppointments.operation.service';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { DockAppointments_ReportService } from './DockAppointments.report.index';
import { DockAppointments_LocalizationService } from './DockAppointments.localization.service';
import { DockAppointments_areas_dd_ComponentContextService } from './DockAppointments.areas_dd.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './DockAppointments.frontend.types'
import { $frontendTypes as $types} from './DockAppointments.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'DockAppointments-areas_dd_multi',
  templateUrl: './DockAppointments.areas_dd_multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DockAppointments_areas_dd_multiComponent),
      multi: true
    }
  ]
})
export class DockAppointments_areas_dd_multiComponent extends BaseComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;
  @Input() tooltip = '';

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;

  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return true;
  }

  get isSingle() {
    return false;
  }


  inParams: { warehouseIds?: number[] } = { warehouseIds: [] };
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }

  fullTextSearch: string;

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: DockAppointments_ShellService,
    private $datasources: DockAppointments_DatasourceService,
    private $flows: DockAppointments_FlowService,
    private $reports: DockAppointments_ReportService,
    private $localization: DockAppointments_LocalizationService,
    private $operations: DockAppointments_OperationService,
    private $logger: CleanupLoggerService,
    private $context: DockAppointments_areas_dd_ComponentContextService,
    private _elementRef: ElementRef
    ) {
      super();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): number | number[] {
    return this._innerValue;
  }

  set value(val:  number | number[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  displayWithFn(value: any): Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}> {
    const promise = new Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}>((resolve, reject) => {

      const $selector = this;
      const $utils = this.$utils;

      this.fullTextSearch = null;
      const $fullTextSearch = null;
      this.$datasources.DockAppointments.ds_areas_dd.getByKeys({
        $keys: value,
        fullTextSearch:  $selector.fullTextSearch ,
        warehouseIds:  $selector.inParams.warehouseIds ,
      }).then(result => {
        const selected = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          const e: { Id?: number, Name?: string } = found 
            ?? { 
              Id : k
            };

          const $option = { entity: e };
          const $entity = e;
          const $resultOption = { key: null, name: found ? $option.entity.Name : 'No data to display', disabled: false, selected:true };
          $resultOption.key = $option.entity.Id;
          return $resultOption;
        });
        const displayText = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          if (found) {
            const $option = { entity: found };
            const $entity = found;
            return $option.entity.Name;
          } else {
            return 'No data to display';
          }
        })
        .join(
           ', '
        );
        this.displayTextChange.emit(displayText);
        resolve({displayText, selected});
      });
    });
    return promise;
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {

    const promise = new Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>((resolve, reject) => {

      const $selector = this;
      const $utils = this.$utils;

      this.fullTextSearch = filterText;
      const $fullTextSearch = filterText;
      this.$datasources.DockAppointments.ds_areas_dd.getList({
        $top: 25,
        $skip: 0,
        fullTextSearch:  $selector.fullTextSearch ,
        warehouseIds:  $selector.inParams.warehouseIds ,
      }).then(result => {
        resolve({
          list: result.result.map(item => {
            const $option = { entity: item };
            const $entity = item;
            const $resultOption = { key: null, name: $option.entity.Name, disabled: null };
            $resultOption.key = $option.entity.Id;
            return $resultOption;
          }),
          totalCount: result.totalCount,
          top: 25
        });
      });

    });

    return promise;

  }

  //#region private flows
  on_add_action(event = null) {
    return this.on_add_actionInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_actionInternal(
    $selector: DockAppointments_areas_dd_multiComponent,
  
    $shell: DockAppointments_ShellService,
    $datasources: DockAppointments_DatasourceService,
    $flows: DockAppointments_FlowService,
    $reports: DockAppointments_ReportService,
    $settings: SettingsValuesService,
    $operations: DockAppointments_OperationService,
    $utils: UtilsService,
    $context: DockAppointments_areas_dd_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: DockAppointments_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($selector.inParams.warehouseIds)) {
      throw new Error('Unable to determine warehouse.')
  }
  
  if ($selector.inParams.warehouseIds.length > 1) {
  
      throw new Error('More than one warehouse selected on the filter, cannot create a new area.')
  
  }
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LocationContainer' })).nextId;
  const newAreaName = $selector.fullTextSearch;
  // Initialize the first warehouse selected
  var warehouseId = $selector.inParams.warehouseIds[0];
  
  
  if (!$utils.isDefinedTrimmed(newAreaName)) {
      throw new Error('To create a new area please first type in the desired value in the drop down then try again.')
  }
  
  const result = (await $flows.DockAppointments.create_dock_door_area_flow({ warehouse_id: warehouseId, name: newAreaName}));
  
  if ($utils.isDefined(result.reasons)) {
      throw new Error(result.reasons.join(', '));
  }
  
  if ($selector.isMulti == true) {
      $selector.value = [result.location_id];
  }
  else {
      $selector.value = result.location_id;
  }
  }
  //#endregion private flows
}
