import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ExcelProjectImport_ds_project_import_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string }, ProjectsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelProjectImport/datasources/ds_project_import_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string }, ProjectsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelProjectImport/datasources/ds_project_import_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Name?: string, OwnerId?: number, Owner?: { LookupCode?: string, Name?: string }, ProjectsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelProjectImport/datasources/ds_project_import_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projectId: number    }) {
        if(isNil(inParams.projectId)) {
          return true; 
        }
      return false;
    }
}
