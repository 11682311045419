import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { InventoryTransformations_ShellService } from './InventoryTransformations.shell.service';
import { InventoryTransformations_OperationService } from './InventoryTransformations.operation.service';
import { InventoryTransformations_DatasourceService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_FlowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_ReportService } from './InventoryTransformations.report.index';
import { InventoryTransformations_LocalizationService } from './InventoryTransformations.localization.service';
import { InventoryTransformations_active_inventory_transformations_widget_ComponentContextService } from './InventoryTransformations.active_inventory_transformations_widget.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './InventoryTransformations.frontend.types'
import { $frontendTypes as $types} from './InventoryTransformations.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'InventoryTransformations-active_inventory_transformations_widget',
  templateUrl: './InventoryTransformations.active_inventory_transformations_widget.component.html'
})
export class InventoryTransformations_active_inventory_transformations_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { owner_id?: number, project_id?: number, warehouse_ids?: number[] } = { owner_id: null, project_id: null, warehouse_ids: [] };
  //#region Inputs
  @Input('owner_id') set $inParams_owner_id(v: number) {
    this.inParams.owner_id = v;
  }
  get $inParams_owner_id(): number {
    return this.inParams.owner_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('warehouse_ids') set $inParams_warehouse_ids(v: number[]) {
    this.inParams.warehouse_ids = v;
  }
  get $inParams_warehouse_ids(): number[] {
    return this.inParams.warehouse_ids;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { total_active_transformations?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: InventoryTransformations_ShellService,
private $datasources: InventoryTransformations_DatasourceService,
private $flows: InventoryTransformations_FlowService,
private $reports: InventoryTransformations_ReportService,
private $localization: InventoryTransformations_LocalizationService,
private $operations: InventoryTransformations_OperationService,
private $logger: CleanupLoggerService,
private $context: InventoryTransformations_active_inventory_transformations_widget_ComponentContextService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.$utils;

    const dsParams = {
      owner_id:  $widget.inParams.owner_id ,
      project_id:  $widget.inParams.project_id ,
      warehouse_ids:  $widget.inParams.warehouse_ids 
    };

    const data = await this.$datasources.InventoryTransformations.ds_active_inventory_transformations_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.$utils;

    this.value = $widget?.entity?.total_active_transformations;

    
    this.originalValue = $widget?.entity?.total_active_transformations;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
