import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Replenishments_ds_get_total_released_pick_quantity_by_locationId_lotIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { locationId: number, lotId: number }): 
  Promise<{ result: { TotalReleasedPickBaseAmount?: number } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Replenishments/datasources/ds_get_total_released_pick_quantity_by_locationId_lotId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      locationId: number, lotId: number    }) {
        if(isNil(inParams.locationId)) {
          return true; 
        }
        if(isNil(inParams.lotId)) {
          return true; 
        }
      return false;
    }
}
