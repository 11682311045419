import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Ultraship_a_testService } from './Ultraship.flow.index';
import { Ultraship_entity_update_trackersService } from './Ultraship.flow.index';
import { Ultraship_entity_update_trackers_deleteService } from './Ultraship.flow.index';
import { Ultraship_entity_update_trackers_distinctService } from './Ultraship.flow.index';
import { Ultraship_entity_update_trackers_getService } from './Ultraship.flow.index';
import { Ultraship_entity_update_trackers_get_oneService } from './Ultraship.flow.index';
import { Ultraship_entity_update_trackers_purgeService } from './Ultraship.flow.index';
import { Ultraship_entity_update_trackers_updateService } from './Ultraship.flow.index';
import { Ultraship_entity_update_trackers_writeService } from './Ultraship.flow.index';
import { Ultraship_execute_insert_entity_for_updateService } from './Ultraship.flow.index';
import { Ultraship_execute_insert_update_order_requestService } from './Ultraship.flow.index';
import { Ultraship_export_ultraship_outbound_orderService } from './Ultraship.flow.index';
import { Ultraship_export_ultraship_outbound_shipmentService } from './Ultraship.flow.index';
import { Ultraship_get_http_configurationService } from './Ultraship.flow.index';
import { Ultraship_get_instance_informationService } from './Ultraship.flow.index';
import { Ultraship_get_projects_configurationService } from './Ultraship.flow.index';
import { Ultraship_get_sftp_configurationService } from './Ultraship.flow.index';
import { Ultraship_get_utc_date_by_timezoneService } from './Ultraship.flow.index';
import { Ultraship_import_inbound_shipment_request_payloadService } from './Ultraship.flow.index';
import { Ultraship_insert_shipment_payload_to_stagingService } from './Ultraship.flow.index';
import { Ultraship_insert_ultraship_outbound_orders_to_stagingService } from './Ultraship.flow.index';
import { Ultraship_insert_ultraship_outbound_shipment_to_stagingService } from './Ultraship.flow.index';
import { Ultraship_main_get_and_process_ultraship_documents_from_sftpService } from './Ultraship.flow.index';
import { Ultraship_main_outbound_order_to_ultrashipService } from './Ultraship.flow.index';
import { Ultraship_main_outbound_shipment_to_ultrashipService } from './Ultraship.flow.index';
import { Ultraship_main_process_records_from_entity_update_trackerService } from './Ultraship.flow.index';
import { Ultraship_main_process_ultraship_inbound_shipments_from_stagingService } from './Ultraship.flow.index';
import { Ultraship_main_process_ultraship_outbound_orders_update_from_stagingService } from './Ultraship.flow.index';
import { Ultraship_main_process_ultraship_response_request_from_stagingService } from './Ultraship.flow.index';
import { Ultraship_map_outbound_order_request_payloadService } from './Ultraship.flow.index';
import { Ultraship_map_outbound_order_update_request_payloadService } from './Ultraship.flow.index';
import { Ultraship_map_outbound_shipment_request_payloadService } from './Ultraship.flow.index';
import { Ultraship_parse_and_insert_response_request_payload_to_stagingService } from './Ultraship.flow.index';
import { Ultraship_parse_and_process_http_request_response_from_ultrashipService } from './Ultraship.flow.index';
import { Ultraship_parse_and_process_order_request_response_from_ultrashipService } from './Ultraship.flow.index';
import { Ultraship_parse_and_process_shipment_request_response_from_ultrashipService } from './Ultraship.flow.index';
import { Ultraship_parse_order_v4_request_payloadService } from './Ultraship.flow.index';
import { Ultraship_parse_raw_file_to_stagingService } from './Ultraship.flow.index';
import { Ultraship_parse_shipment_v4_request_payloadService } from './Ultraship.flow.index';
import { Ultraship_process_ultraship_outbound_orders_from_stagingService } from './Ultraship.flow.index';
import { Ultraship_process_ultraship_outbound_shipments_from_stagingService } from './Ultraship.flow.index';

import { $frontendTypes } from './Ultraship.frontend.types'

@Injectable({ providedIn: 'root' })
export class Ultraship_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Ultraship: Ultraship_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _a_test: Ultraship_a_testService;
  public async a_test(inParams: {  }): Promise< { debugDetails?: string[] }> {
    if(!this._a_test) { 
      this._a_test = this.injector.get(Ultraship_a_testService);
    }
    return this._a_test.run(inParams);
  }
   
   

   
 
  private _entity_update_trackers: Ultraship_entity_update_trackersService;
  public async entity_update_trackers(inParams: { payload?: $frontendTypes.Ultraship.Entity_update_tracker[], action: string, skip?: number, take?: number, application_name: string, full_text_search?: string }): Promise< { payload?: $frontendTypes.Ultraship.Entity_update_tracker[], success?: boolean, error?: any[], count?: number }> {
    if(!this._entity_update_trackers) { 
      this._entity_update_trackers = this.injector.get(Ultraship_entity_update_trackersService);
    }
    return this._entity_update_trackers.run(inParams);
  }
   
   

   
 
  private _entity_update_trackers_delete: Ultraship_entity_update_trackers_deleteService;
  public async entity_update_trackers_delete(inParams: { input?: { entity_update_tracker_id: string }[], criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._entity_update_trackers_delete) { 
      this._entity_update_trackers_delete = this.injector.get(Ultraship_entity_update_trackers_deleteService);
    }
    return this._entity_update_trackers_delete.run(inParams);
  }
   
   

   
 
  private _entity_update_trackers_distinct: Ultraship_entity_update_trackers_distinctService;
  public async entity_update_trackers_distinct(inParams: { input?: $frontendTypes.Ultraship.Entity_update_tracker[], criteria?: { application_name?: string, full_text_search?: string }, field: string, top?: number }): Promise< { output?: { application_name?: string, value?: any }[], success?: boolean, error?: any[], count?: number }> {
    if(!this._entity_update_trackers_distinct) { 
      this._entity_update_trackers_distinct = this.injector.get(Ultraship_entity_update_trackers_distinctService);
    }
    return this._entity_update_trackers_distinct.run(inParams);
  }
   
   

   
 
  private _entity_update_trackers_get: Ultraship_entity_update_trackers_getService;
  public async entity_update_trackers_get(inParams: { input?: $frontendTypes.Ultraship.Entity_update_tracker[], criteria: { application_name: string, decrypt?: boolean, full_text_search?: string, skip?: number, take?: number } }): Promise< { output?: $frontendTypes.Ultraship.Entity_update_tracker[], success?: boolean, error?: any[], count?: number }> {
    if(!this._entity_update_trackers_get) { 
      this._entity_update_trackers_get = this.injector.get(Ultraship_entity_update_trackers_getService);
    }
    return this._entity_update_trackers_get.run(inParams);
  }
   
   

   
 
  private _entity_update_trackers_get_one: Ultraship_entity_update_trackers_get_oneService;
  public async entity_update_trackers_get_one(inParams: { input?: $frontendTypes.Ultraship.Entity_update_tracker[], criteria: { application_name: string, decrypt?: boolean, full_text_search?: string } }): Promise< { output?: $frontendTypes.Ultraship.Entity_update_tracker, success?: boolean, error?: any[] }> {
    if(!this._entity_update_trackers_get_one) { 
      this._entity_update_trackers_get_one = this.injector.get(Ultraship_entity_update_trackers_get_oneService);
    }
    return this._entity_update_trackers_get_one.run(inParams);
  }
   
   

   
 
  private _entity_update_trackers_purge: Ultraship_entity_update_trackers_purgeService;
  public async entity_update_trackers_purge(inParams: { criteria: { application_name: string } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._entity_update_trackers_purge) { 
      this._entity_update_trackers_purge = this.injector.get(Ultraship_entity_update_trackers_purgeService);
    }
    return this._entity_update_trackers_purge.run(inParams);
  }
   
   

   
 
  private _entity_update_trackers_update: Ultraship_entity_update_trackers_updateService;
  public async entity_update_trackers_update(inParams: { input?: $frontendTypes.Ultraship.Entity_update_tracker[], criteria: { application_name: string, encrypt?: boolean } }): Promise< { success?: boolean, error?: any[] }> {
    if(!this._entity_update_trackers_update) { 
      this._entity_update_trackers_update = this.injector.get(Ultraship_entity_update_trackers_updateService);
    }
    return this._entity_update_trackers_update.run(inParams);
  }
   
   

   
 
  private _entity_update_trackers_write: Ultraship_entity_update_trackers_writeService;
  public async entity_update_trackers_write(inParams: { input?: $frontendTypes.Ultraship.Entity_update_tracker[], criteria: { application_name: string, encrypt?: boolean } }): Promise< { output?: { entiry_update_tracker_id?: string }[], success?: boolean, error?: any[] }> {
    if(!this._entity_update_trackers_write) { 
      this._entity_update_trackers_write = this.injector.get(Ultraship_entity_update_trackers_writeService);
    }
    return this._entity_update_trackers_write.run(inParams);
  }
   
   

   
 
  private _execute_insert_entity_for_update: Ultraship_execute_insert_entity_for_updateService;
  public async execute_insert_entity_for_update(inParams: { entity?: string, entityId?: number }): Promise< { entity_tracker_update_id?: string, success?: boolean, outputDetails?: string[] }> {
    if(!this._execute_insert_entity_for_update) { 
      this._execute_insert_entity_for_update = this.injector.get(Ultraship_execute_insert_entity_for_updateService);
    }
    return this._execute_insert_entity_for_update.run(inParams);
  }
   
   

   
 
  private _execute_insert_update_order_request: Ultraship_execute_insert_update_order_requestService;
  public async execute_insert_update_order_request(inParams: { orderId?: number, shipmentId?: number, projectId?: number }): Promise< { message_transaction_id?: string, success?: boolean, outputDetails?: string[] }> {
    if(!this._execute_insert_update_order_request) { 
      this._execute_insert_update_order_request = this.injector.get(Ultraship_execute_insert_update_order_requestService);
    }
    return this._execute_insert_update_order_request.run(inParams);
  }
   
   

   
 
  private _export_ultraship_outbound_order: Ultraship_export_ultraship_outbound_orderService;
  public async export_ultraship_outbound_order(inParams: { ultrashipOrdersPayload?: string, messageTransactionId?: string }): Promise< { responseResult?: $frontendTypes.Ultraship.HttpRequestResponse, outputDetails?: string[] }> {
    if(!this._export_ultraship_outbound_order) { 
      this._export_ultraship_outbound_order = this.injector.get(Ultraship_export_ultraship_outbound_orderService);
    }
    return this._export_ultraship_outbound_order.run(inParams);
  }
   
   

   
 
  private _export_ultraship_outbound_shipment: Ultraship_export_ultraship_outbound_shipmentService;
  public async export_ultraship_outbound_shipment(inParams: { shipmentWsPayload?: string, messageTransactionId?: string }): Promise< { responseResult?: $frontendTypes.Ultraship.HttpRequestResponse, outputDetails?: string[] }> {
    if(!this._export_ultraship_outbound_shipment) { 
      this._export_ultraship_outbound_shipment = this.injector.get(Ultraship_export_ultraship_outbound_shipmentService);
    }
    return this._export_ultraship_outbound_shipment.run(inParams);
  }
   
   

   
 
  private _get_http_configuration: Ultraship_get_http_configurationService;
  public async get_http_configuration(inParams: { application_name: string, environment: string }): Promise< { Endpoint?: string, Port?: string, Username?: string, Password?: string, debugDetails?: string[] }> {
    if(!this._get_http_configuration) { 
      this._get_http_configuration = this.injector.get(Ultraship_get_http_configurationService);
    }
    return this._get_http_configuration.run(inParams);
  }
   
   

   
 
  private _get_instance_information: Ultraship_get_instance_informationService;
  public async get_instance_information(inParams: {  }): Promise< { application_name?: string, environment?: string }> {
    if(!this._get_instance_information) { 
      this._get_instance_information = this.injector.get(Ultraship_get_instance_informationService);
    }
    return this._get_instance_information.run(inParams);
  }
   
   

   
 
  private _get_projects_configuration: Ultraship_get_projects_configurationService;
  public async get_projects_configuration(inParams: { application_name: string, environment: string }): Promise< { Projects?: string[], ErrorFolder?: string }> {
    if(!this._get_projects_configuration) { 
      this._get_projects_configuration = this.injector.get(Ultraship_get_projects_configurationService);
    }
    return this._get_projects_configuration.run(inParams);
  }
   
   

   
 
  private _get_sftp_configuration: Ultraship_get_sftp_configurationService;
  public async get_sftp_configuration(inParams: { application_name: string, environment: string }): Promise< { Endpoint?: string, Port?: string, Username?: string, Password?: string, WorkingFolder?: string, ArchiveFolder?: string, ErrorFolder?: string, FileExtension?: string }> {
    if(!this._get_sftp_configuration) { 
      this._get_sftp_configuration = this.injector.get(Ultraship_get_sftp_configurationService);
    }
    return this._get_sftp_configuration.run(inParams);
  }
   
   

   
 
  private _get_utc_date_by_timezone: Ultraship_get_utc_date_by_timezoneService;
  public async get_utc_date_by_timezone(inParams: { dateTime: string, timezone: string }): Promise< { utcDate?: string }> {
    if(!this._get_utc_date_by_timezone) { 
      this._get_utc_date_by_timezone = this.injector.get(Ultraship_get_utc_date_by_timezoneService);
    }
    return this._get_utc_date_by_timezone.run(inParams);
  }
   
   

   
 
  private _import_inbound_shipment_request_payload: Ultraship_import_inbound_shipment_request_payloadService;
  public async import_inbound_shipment_request_payload(inParams: { ultrashipShipmentPayload?: string, messageStorageId?: string, messageTransactionId?: string }): Promise< { responseResult?: $frontendTypes.Ultraship.HttpRequestResponse, outputDetails?: string[], success?: boolean }> {
    if(!this._import_inbound_shipment_request_payload) { 
      this._import_inbound_shipment_request_payload = this.injector.get(Ultraship_import_inbound_shipment_request_payloadService);
    }
    return this._import_inbound_shipment_request_payload.run(inParams);
  }
   
   

   
 
  private _insert_shipment_payload_to_staging: Ultraship_insert_shipment_payload_to_stagingService;
  public async insert_shipment_payload_to_staging(inParams: { shipmentPayload: $frontendTypes.Ultraship.ShipmentWS, storageMessageId?: string, storageTransactionId?: string }): Promise< { outputDetails?: string[], debugDetails?: string[], success?: boolean }> {
    if(!this._insert_shipment_payload_to_staging) { 
      this._insert_shipment_payload_to_staging = this.injector.get(Ultraship_insert_shipment_payload_to_stagingService);
    }
    return this._insert_shipment_payload_to_staging.run(inParams);
  }
   
   

   
 
  private _insert_ultraship_outbound_orders_to_staging: Ultraship_insert_ultraship_outbound_orders_to_stagingService;
  public async insert_ultraship_outbound_orders_to_staging(inParams: {  }): Promise<void> {
    if(!this._insert_ultraship_outbound_orders_to_staging) { 
      this._insert_ultraship_outbound_orders_to_staging = this.injector.get(Ultraship_insert_ultraship_outbound_orders_to_stagingService);
    }
    return this._insert_ultraship_outbound_orders_to_staging.run(inParams);
  }
   
   

   
 
  private _insert_ultraship_outbound_shipment_to_staging: Ultraship_insert_ultraship_outbound_shipment_to_stagingService;
  public async insert_ultraship_outbound_shipment_to_staging(inParams: {  }): Promise<void> {
    if(!this._insert_ultraship_outbound_shipment_to_staging) { 
      this._insert_ultraship_outbound_shipment_to_staging = this.injector.get(Ultraship_insert_ultraship_outbound_shipment_to_stagingService);
    }
    return this._insert_ultraship_outbound_shipment_to_staging.run(inParams);
  }
   
   

   
 
  private _main_get_and_process_ultraship_documents_from_sftp: Ultraship_main_get_and_process_ultraship_documents_from_sftpService;
  public async main_get_and_process_ultraship_documents_from_sftp(inParams: { ftpWorkingFolder?: string, ftpArchiveFolder?: string, ftpErroFolder?: string, ftpFileExtension?: string }): Promise< { debugDetails?: string[], outputDetails?: string[] }> {
    if(!this._main_get_and_process_ultraship_documents_from_sftp) { 
      this._main_get_and_process_ultraship_documents_from_sftp = this.injector.get(Ultraship_main_get_and_process_ultraship_documents_from_sftpService);
    }
    return this._main_get_and_process_ultraship_documents_from_sftp.run(inParams);
  }
   
   

   
 
  private _main_outbound_order_to_ultraship: Ultraship_main_outbound_order_to_ultrashipService;
  public async main_outbound_order_to_ultraship(inParams: {  }): Promise<void> {
    if(!this._main_outbound_order_to_ultraship) { 
      this._main_outbound_order_to_ultraship = this.injector.get(Ultraship_main_outbound_order_to_ultrashipService);
    }
    return this._main_outbound_order_to_ultraship.run(inParams);
  }
   
   

   
 
  private _main_outbound_shipment_to_ultraship: Ultraship_main_outbound_shipment_to_ultrashipService;
  public async main_outbound_shipment_to_ultraship(inParams: {  }): Promise<void> {
    if(!this._main_outbound_shipment_to_ultraship) { 
      this._main_outbound_shipment_to_ultraship = this.injector.get(Ultraship_main_outbound_shipment_to_ultrashipService);
    }
    return this._main_outbound_shipment_to_ultraship.run(inParams);
  }
   
   

   
 
  private _main_process_records_from_entity_update_tracker: Ultraship_main_process_records_from_entity_update_trackerService;
  public async main_process_records_from_entity_update_tracker(inParams: {  }): Promise< { outputDetails?: string[] }> {
    if(!this._main_process_records_from_entity_update_tracker) { 
      this._main_process_records_from_entity_update_tracker = this.injector.get(Ultraship_main_process_records_from_entity_update_trackerService);
    }
    return this._main_process_records_from_entity_update_tracker.run(inParams);
  }
   
   

   
 
  private _main_process_ultraship_inbound_shipments_from_staging: Ultraship_main_process_ultraship_inbound_shipments_from_stagingService;
  public async main_process_ultraship_inbound_shipments_from_staging(inParams: {  }): Promise< { outputDetails?: string[] }> {
    if(!this._main_process_ultraship_inbound_shipments_from_staging) { 
      this._main_process_ultraship_inbound_shipments_from_staging = this.injector.get(Ultraship_main_process_ultraship_inbound_shipments_from_stagingService);
    }
    return this._main_process_ultraship_inbound_shipments_from_staging.run(inParams);
  }
   
   

   
 
  private _main_process_ultraship_outbound_orders_update_from_staging: Ultraship_main_process_ultraship_outbound_orders_update_from_stagingService;
  public async main_process_ultraship_outbound_orders_update_from_staging(inParams: {  }): Promise< { outputDetails?: string[] }> {
    if(!this._main_process_ultraship_outbound_orders_update_from_staging) { 
      this._main_process_ultraship_outbound_orders_update_from_staging = this.injector.get(Ultraship_main_process_ultraship_outbound_orders_update_from_stagingService);
    }
    return this._main_process_ultraship_outbound_orders_update_from_staging.run(inParams);
  }
   
   

   
 
  private _main_process_ultraship_response_request_from_staging: Ultraship_main_process_ultraship_response_request_from_stagingService;
  public async main_process_ultraship_response_request_from_staging(inParams: {  }): Promise< { outputDetails?: string[] }> {
    if(!this._main_process_ultraship_response_request_from_staging) { 
      this._main_process_ultraship_response_request_from_staging = this.injector.get(Ultraship_main_process_ultraship_response_request_from_stagingService);
    }
    return this._main_process_ultraship_response_request_from_staging.run(inParams);
  }
   
   

   
 
  private _map_outbound_order_request_payload: Ultraship_map_outbound_order_request_payloadService;
  public async map_outbound_order_request_payload(inParams: { orderId: number, shipmentId?: number }): Promise< { outputDetails?: string[], outputMapResult?: $frontendTypes.Ultraship.Order[] }> {
    if(!this._map_outbound_order_request_payload) { 
      this._map_outbound_order_request_payload = this.injector.get(Ultraship_map_outbound_order_request_payloadService);
    }
    return this._map_outbound_order_request_payload.run(inParams);
  }
   
   

   
 
  private _map_outbound_order_update_request_payload: Ultraship_map_outbound_order_update_request_payloadService;
  public async map_outbound_order_update_request_payload(inParams: { orderId?: number, shipmentId?: number }): Promise< { outputDetails?: string[], outputMapResult?: $frontendTypes.Ultraship.Order[] }> {
    if(!this._map_outbound_order_update_request_payload) { 
      this._map_outbound_order_update_request_payload = this.injector.get(Ultraship_map_outbound_order_update_request_payloadService);
    }
    return this._map_outbound_order_update_request_payload.run(inParams);
  }
   
   

   
 
  private _map_outbound_shipment_request_payload: Ultraship_map_outbound_shipment_request_payloadService;
  public async map_outbound_shipment_request_payload(inParams: { loadContainerId: number, orderId?: number, shipmentId?: number }): Promise< { outputDetails?: string[], outputMapResult?: $frontendTypes.Ultraship.Shipment[] }> {
    if(!this._map_outbound_shipment_request_payload) { 
      this._map_outbound_shipment_request_payload = this.injector.get(Ultraship_map_outbound_shipment_request_payloadService);
    }
    return this._map_outbound_shipment_request_payload.run(inParams);
  }
   
   

   
 
  private _parse_and_insert_response_request_payload_to_staging: Ultraship_parse_and_insert_response_request_payload_to_stagingService;
  public async parse_and_insert_response_request_payload_to_staging(inParams: { payload: string, storageMessageId?: string, storageTransactionId?: string }): Promise< { outputDetails?: string[], debugDetails?: string[], success?: boolean }> {
    if(!this._parse_and_insert_response_request_payload_to_staging) { 
      this._parse_and_insert_response_request_payload_to_staging = this.injector.get(Ultraship_parse_and_insert_response_request_payload_to_stagingService);
    }
    return this._parse_and_insert_response_request_payload_to_staging.run(inParams);
  }
   
   

   
 
  private _parse_and_process_http_request_response_from_ultraship: Ultraship_parse_and_process_http_request_response_from_ultrashipService;
  public async parse_and_process_http_request_response_from_ultraship(inParams: { responsePayload: string, responseContext?: $frontendTypes.Ultraship.HttpRequestSyncContext }): Promise< { outputDetails?: string[], requestResultResponse?: $frontendTypes.Ultraship.HttpRequestResponse, debugDetails?: string[] }> {
    if(!this._parse_and_process_http_request_response_from_ultraship) { 
      this._parse_and_process_http_request_response_from_ultraship = this.injector.get(Ultraship_parse_and_process_http_request_response_from_ultrashipService);
    }
    return this._parse_and_process_http_request_response_from_ultraship.run(inParams);
  }
   
   

   
 
  private _parse_and_process_order_request_response_from_ultraship: Ultraship_parse_and_process_order_request_response_from_ultrashipService;
  public async parse_and_process_order_request_response_from_ultraship(inParams: { ultrashipOrderResponsePayload: string, messageTransactionId?: string }): Promise< { outputDetails?: string[], requestResultResponse?: $frontendTypes.Ultraship.OrderRequestResponse }> {
    if(!this._parse_and_process_order_request_response_from_ultraship) { 
      this._parse_and_process_order_request_response_from_ultraship = this.injector.get(Ultraship_parse_and_process_order_request_response_from_ultrashipService);
    }
    return this._parse_and_process_order_request_response_from_ultraship.run(inParams);
  }
   
   

   
 
  private _parse_and_process_shipment_request_response_from_ultraship: Ultraship_parse_and_process_shipment_request_response_from_ultrashipService;
  public async parse_and_process_shipment_request_response_from_ultraship(inParams: { ultrashipShipmentResponsePayload: string, messageTransactionId?: string }): Promise< { outputDetails?: string[], requestResultResponse?: $frontendTypes.Ultraship.ShipmentRequestResponse }> {
    if(!this._parse_and_process_shipment_request_response_from_ultraship) { 
      this._parse_and_process_shipment_request_response_from_ultraship = this.injector.get(Ultraship_parse_and_process_shipment_request_response_from_ultrashipService);
    }
    return this._parse_and_process_shipment_request_response_from_ultraship.run(inParams);
  }
   
   

   
 
  private _parse_order_v4_request_payload: Ultraship_parse_order_v4_request_payloadService;
  public async parse_order_v4_request_payload(inParams: { payload?: string }): Promise< { outputDetails?: string[], debugDetails?: string[], parsedOrderResult?: $frontendTypes.Ultraship.OrderWS }> {
    if(!this._parse_order_v4_request_payload) { 
      this._parse_order_v4_request_payload = this.injector.get(Ultraship_parse_order_v4_request_payloadService);
    }
    return this._parse_order_v4_request_payload.run(inParams);
  }
   
   

   
 
  private _parse_raw_file_to_staging: Ultraship_parse_raw_file_to_stagingService;
  public async parse_raw_file_to_staging(inParams: { payload?: string }): Promise<void> {
    if(!this._parse_raw_file_to_staging) { 
      this._parse_raw_file_to_staging = this.injector.get(Ultraship_parse_raw_file_to_stagingService);
    }
    return this._parse_raw_file_to_staging.run(inParams);
  }
   
   

   
 
  private _parse_shipment_v4_request_payload: Ultraship_parse_shipment_v4_request_payloadService;
  public async parse_shipment_v4_request_payload(inParams: { payload?: string }): Promise< { outputDetails?: string[], debugDetails?: string[], parsedShipmentResult?: $frontendTypes.Ultraship.ShipmentWS }> {
    if(!this._parse_shipment_v4_request_payload) { 
      this._parse_shipment_v4_request_payload = this.injector.get(Ultraship_parse_shipment_v4_request_payloadService);
    }
    return this._parse_shipment_v4_request_payload.run(inParams);
  }
   
   

   
 
  private _process_ultraship_outbound_orders_from_staging: Ultraship_process_ultraship_outbound_orders_from_stagingService;
  public async process_ultraship_outbound_orders_from_staging(inParams: {  }): Promise< { outputDetails?: string[] }> {
    if(!this._process_ultraship_outbound_orders_from_staging) { 
      this._process_ultraship_outbound_orders_from_staging = this.injector.get(Ultraship_process_ultraship_outbound_orders_from_stagingService);
    }
    return this._process_ultraship_outbound_orders_from_staging.run(inParams);
  }
   
   

   
 
  private _process_ultraship_outbound_shipments_from_staging: Ultraship_process_ultraship_outbound_shipments_from_stagingService;
  public async process_ultraship_outbound_shipments_from_staging(inParams: {  }): Promise< { outputDetails?: string[] }> {
    if(!this._process_ultraship_outbound_shipments_from_staging) { 
      this._process_ultraship_outbound_shipments_from_staging = this.injector.get(Ultraship_process_ultraship_outbound_shipments_from_stagingService);
    }
    return this._process_ultraship_outbound_shipments_from_staging.run(inParams);
  }
   
   

   
}
