import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Ultraship_ShellService } from './Ultraship.shell.service';
import { Ultraship_OperationService } from './Ultraship.operation.service';
import { Ultraship_DatasourceService } from './Ultraship.datasource.index';
import { Ultraship_FlowService } from './Ultraship.flow.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Ultraship_LocalizationService } from './Ultraship.localization.service';
import { Ultraship_ultraship_hub_ComponentContextService } from './Ultraship.ultraship_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Ultraship.frontend.types'
import { $frontendTypes as $types} from './Ultraship.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Ultraship_ultraship_messages_gridComponent } from './Ultraship.ultraship_messages_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Ultraship_ultraship_messages_gridComponent),
  ],
  selector: 'Ultraship-ultraship_hub',
  templateUrl: './Ultraship.ultraship_hub.component.html'
})
export class Ultraship_ultraship_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { application_name?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
  });
  
  toolbar = {
      logs: new ToolModel(new ButtonModel('logs', new ButtonStyles(null, null), false, false, false, 'Logs', '', null)
    , false),
      manage_button: new ToolModel(new SplitButtonModel<{ configuration_button: ButtonModel, debugConfigButton: ButtonModel }>(
        'manage_button',
        new ButtonStyles(null, null),
        false,
        false,
        'Manage',
        '',
        null,
        [
          new ButtonModel('configuration_button', new ButtonStyles(null, null), false, false, false, 'Configurations', ''),
          new ButtonModel('debugConfigButton', new ButtonStyles(null, null), false, false, false, 'Debug configuration', '')
        ])
    , false)
  };

  actionbar = {
  };

 filters = {
  };


  filtersets = {
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
      tabInbound: new TabGroupModel(),
      tabOutbound: new TabGroupModel(),
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      transactions: new TabItemModel(
        this.rootTabGroup, 
        'Transactions', 
        false,
        ),
      tabInbound: new TabItemModel(
        this.rootTabGroup, 
        'Inbound', 
        false,
        true,
        {
          tabInboundOrder: new TabItemModel(
          this.subTabGroups.tabInbound, 
          'Order', 
          false
          ),
          tabInboundShipment: new TabItemModel(
          this.subTabGroups.tabInbound, 
          'Shipment', 
          false
          ),
          tabRawFIle: new TabItemModel(
          this.subTabGroups.tabInbound, 
          'Raw files (SFTP)', 
          false
          ),
          tabResponses: new TabItemModel(
          this.subTabGroups.tabInbound, 
          'Response', 
          false
          ),
        }
        ),
      tabOutbound: new TabItemModel(
        this.rootTabGroup, 
        'Outbound', 
        false,
        true,
        {
          tabOutboundOrder: new TabItemModel(
          this.subTabGroups.tabOutbound, 
          'Order', 
          false
          ),
          tabOutboundOrderUpdate: new TabItemModel(
          this.subTabGroups.tabOutbound, 
          'Order update', 
          false
          ),
          tabOutboundShipment: new TabItemModel(
          this.subTabGroups.tabOutbound, 
          'Shipment', 
          false
          ),
        }
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_transactions_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    get $tabs_tabInbound_tabInboundOrder_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    get $tabs_tabInbound_tabInboundOrder_ultraship_messages_grid_inParams_transaction_type(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = "Inbound_Order";
      
      return expr;
    }
  
    get $tabs_tabInbound_tabInboundShipment_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    get $tabs_tabInbound_tabInboundShipment_ultraship_messages_grid_inParams_transaction_type(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = "Inbound_Shipment";
      
      return expr;
    }
  
    get $tabs_tabInbound_tabRawFIle_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    get $tabs_tabInbound_tabRawFIle_ultraship_messages_grid_inParams_transaction_type(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = "RawFile";
      
      return expr;
    }
  
    get $tabs_tabInbound_tabResponses_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    get $tabs_tabInbound_tabResponses_ultraship_messages_grid_inParams_transaction_type(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = "Response";
      
      return expr;
    }
  
    get $tabs_tabOutbound_tabOutboundOrder_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    get $tabs_tabOutbound_tabOutboundOrder_ultraship_messages_grid_inParams_transaction_type(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = "Outbound_Order";
      
      return expr;
    }
  
    get $tabs_tabOutbound_tabOutboundOrderUpdate_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    get $tabs_tabOutbound_tabOutboundOrderUpdate_ultraship_messages_grid_inParams_transaction_type(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = "Outbound_Order_Update";
      
      return expr;
    }
  
    get $tabs_tabOutbound_tabOutboundShipment_ultraship_messages_grid_inParams_application_name(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.application_name;
      
      return expr;
    }
  
    get $tabs_tabOutbound_tabOutboundShipment_ultraship_messages_grid_inParams_transaction_type(): string {
      const $hub = this;
      const $utils = this.$utils;
      const expr = "Outbound_Shipment";
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_transactions', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_transactions: Ultraship_ultraship_messages_gridComponent;
        @ViewChild('$tabs_tabInbound_tabInboundOrder', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_tabInbound_tabInboundOrder: Ultraship_ultraship_messages_gridComponent;
        @ViewChild('$tabs_tabInbound_tabInboundShipment', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_tabInbound_tabInboundShipment: Ultraship_ultraship_messages_gridComponent;
        @ViewChild('$tabs_tabInbound_tabRawFIle', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_tabInbound_tabRawFIle: Ultraship_ultraship_messages_gridComponent;
        @ViewChild('$tabs_tabInbound_tabResponses', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_tabInbound_tabResponses: Ultraship_ultraship_messages_gridComponent;
        @ViewChild('$tabs_tabOutbound_tabOutboundOrder', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_tabOutbound_tabOutboundOrder: Ultraship_ultraship_messages_gridComponent;
        @ViewChild('$tabs_tabOutbound_tabOutboundOrderUpdate', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_tabOutbound_tabOutboundOrderUpdate: Ultraship_ultraship_messages_gridComponent;
        @ViewChild('$tabs_tabOutbound_tabOutboundShipment', { read: Ultraship_ultraship_messages_gridComponent }) $tabs_tabOutbound_tabOutboundShipment: Ultraship_ultraship_messages_gridComponent;
    //#endregion tabs children


  get hubTitle(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Ultraship hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Configure integration and monitor transactions`;
  }

  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: Ultraship_ShellService,
  private $datasources: Ultraship_DatasourceService,
  private $flows: Ultraship_FlowService,
  private $reports: Ultraship_ReportService,
  private $localization: Ultraship_LocalizationService,
  private $operations: Ultraship_OperationService,
  private $logger: CleanupLoggerService,
  private $context: Ultraship_ultraship_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.transactions,
      this.tabs.tabInbound,
      this.tabs.tabOutbound,
    ]; 
    this.subTabGroups.tabInbound.tabs = [
        this.tabs.tabInbound.tabs.tabInboundOrder,
        this.tabs.tabInbound.tabs.tabInboundShipment,
        this.tabs.tabInbound.tabs.tabRawFIle,
        this.tabs.tabInbound.tabs.tabResponses,
    ];    
    this.subTabGroups.tabOutbound.tabs = [
        this.tabs.tabOutbound.tabs.tabOutboundOrder,
        this.tabs.tabOutbound.tabs.tabOutboundOrderUpdate,
        this.tabs.tabOutbound.tabs.tabOutboundShipment,
    ];    
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Ultraship hub';
    
    const $hub = this;
    const $utils = this.$utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_transactions') {
      if (!isNil(this.$tabs_transactions) && !this.tabs.transactions.hidden) {
        this.$tabs_transactions.refresh(true, false, null);
      }
    }
      if (childToSkip !== '$tabs_tabInbound_tabInboundOrder') {
        if (!isNil(this.$tabs_tabInbound_tabInboundOrder) && !this.tabs.tabInbound.tabs.tabInboundOrder.hidden) {
          this.$tabs_tabInbound_tabInboundOrder.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_tabInbound_tabInboundShipment') {
        if (!isNil(this.$tabs_tabInbound_tabInboundShipment) && !this.tabs.tabInbound.tabs.tabInboundShipment.hidden) {
          this.$tabs_tabInbound_tabInboundShipment.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_tabInbound_tabRawFIle') {
        if (!isNil(this.$tabs_tabInbound_tabRawFIle) && !this.tabs.tabInbound.tabs.tabRawFIle.hidden) {
          this.$tabs_tabInbound_tabRawFIle.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_tabInbound_tabResponses') {
        if (!isNil(this.$tabs_tabInbound_tabResponses) && !this.tabs.tabInbound.tabs.tabResponses.hidden) {
          this.$tabs_tabInbound_tabResponses.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_tabOutbound_tabOutboundOrder') {
        if (!isNil(this.$tabs_tabOutbound_tabOutboundOrder) && !this.tabs.tabOutbound.tabs.tabOutboundOrder.hidden) {
          this.$tabs_tabOutbound_tabOutboundOrder.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_tabOutbound_tabOutboundOrderUpdate') {
        if (!isNil(this.$tabs_tabOutbound_tabOutboundOrderUpdate) && !this.tabs.tabOutbound.tabs.tabOutboundOrderUpdate.hidden) {
          this.$tabs_tabOutbound_tabOutboundOrderUpdate.refresh(true, false, null);
        }
      }   
      if (childToSkip !== '$tabs_tabOutbound_tabOutboundShipment') {
        if (!isNil(this.$tabs_tabOutbound_tabOutboundShipment) && !this.tabs.tabOutbound.tabs.tabOutboundShipment.hidden) {
          this.$tabs_tabOutbound_tabOutboundShipment.refresh(true, false, null);
        }
      }   
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  
  
  let instanceInfo = await $flows.Ultraship.get_instance_information({})
  let applicationName = instanceInfo.application_name;
  
  $hub.vars.application_name = applicationName;
  
  }
  on_refresh_dropdowns(event = null) {
    return this.on_refresh_dropdownsInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refresh_dropdownsInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'ultraship_hub.on_refresh_dropdowns');
  
  $flows.Utilities.list_applications({ action: "Refresh" });
  $flows.Utilities.list_processes({ action: "Refresh" });
  $flows.Utilities.list_statuses({ action: "Refresh" });
  $flows.Utilities.list_transaction_types({ action: "Refresh" });
  }
  on_logs_button_clicked(event = null) {
    return this.on_logs_button_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_logs_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  await $shell.Utilities.openlogs_gridDialog({ application_name: `${$hub.vars.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  on_projects_button_clicked(event = null) {
    return this.on_projects_button_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_projects_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'ultraship_hub.on_projects_button_clicked');
  await $shell.Ultraship.openultraship_project_configurations_gridDialog({ application_name: `${$hub.vars.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  on_http_button_clicked(event = null) {
    return this.on_http_button_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_http_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'ultraship_hub.on_http_button_clicked');
  await $shell.Ultraship.openultraship_http_configurations_gridDialog({ application_name: `${$hub.vars.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  on_sftp_button_clicked(event = null) {
    return this.on_sftp_button_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_sftp_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Ultraship', 'ultraship_hub.on_sftp_button_clicked');
  await $shell.Ultraship.openultraship_sftp_configurations_gridDialog({ application_name: `${$hub.vars.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  on_configuration_button_clicked(event = null) {
    return this.on_configuration_button_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_configuration_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  //await $shell.Ultraship.openultraship_configuration_hubDialog({ application_name: `${$hub.vars.application_name}`}, 'flyout', EModalSize.Large);
  $shell.Ultraship.openultraship_configuration_hub({ application_name: `${$hub.vars.application_name}`});
  
  
  }
  on_debug_configuration_button_clicked(event = null) {
    return this.on_debug_configuration_button_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_debug_configuration_button_clickedInternal(
    $hub: Ultraship_ultraship_hubComponent,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    $context: Ultraship_ultraship_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
  await $shell.Utilities.openconfigurations_gridDialog({ application_name: `${$hub.vars.application_name}`}, 'flyout', EModalSize.Xlarge);
  }
  //#endregion private flows
}
