import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_warehouses_navigation_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId?: number, fullTextSearch?: string, timezone?: string[] }): 
  Promise<{ result: { Id?: number, Name?: string, Notes?: string, TimeZoneId?: string, Type?: { Name?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_warehouses_navigation_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseId?: number, fullTextSearch?: string, timezone?: string[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, Name?: string, Notes?: string, TimeZoneId?: string, Type?: { Name?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_warehouses_navigation_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseId?: number, fullTextSearch?: string, timezone?: string[], $keys: number[] }): 
  Promise<{ result: { Id?: number, Name?: string, Notes?: string, TimeZoneId?: string, Type?: { Name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_warehouses_navigation_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouseId?: number, fullTextSearch?: string, timezone?: string[]    }) {
      return false;
    }
}
