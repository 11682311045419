<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="print" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_print_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="preview" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_preview_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-printnode"
                     *ngIf="!fieldsets.printnode.hidden && !fieldsets.printnode.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.printnode.collapsible }">
                    <div *ngIf="!fieldsets.printnode.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.printnode.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.printnode.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.printnode.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.printnode.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.printnode.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.printnode.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-printnode_printer" *ngIf="!fields.printnode_printer.hidden && !fields.printnode_printer.removed" 
                            class="field-container standard {{fields.printnode_printer.invalid ? 'invalid' : ''}} {{fields.printnode_printer.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.printnode_printer.styles.style"
                            [ngClass]="fields.printnode_printer.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.printnode_printer.label + (fields.printnode_printer.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.printnode_printer.label}}<span *ngIf="fields.printnode_printer.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <PrintNode-printers_dd_single 
                            data-cy="selector"
                            [type]="fields.printnode_printer.control.type"
                            formControlName="printnode_printer"
                            (displayTextChange)="fields.printnode_printer.control.displayText=$event"
                            [placeholder]="fields.printnode_printer.control.placeholder"
                            [styles]="fields.printnode_printer.control.styles"
                            [tooltip]="fields.printnode_printer.control.tooltip"
                        >
                        </PrintNode-printers_dd_single>
                        <ng-container *ngIf="fields.printnode_printer.invalid">
                          <ng-container *ngFor="let error of fields.printnode_printer.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.printnode.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-bartender"
                     *ngIf="!fieldsets.bartender.hidden && !fieldsets.bartender.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.bartender.collapsible }">
                    <div *ngIf="!fieldsets.bartender.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.bartender.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.bartender.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.bartender.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.bartender.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.bartender.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.bartender.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-bartender_printer" *ngIf="!fields.bartender_printer.hidden && !fields.bartender_printer.removed" 
                            class="field-container standard {{fields.bartender_printer.invalid ? 'invalid' : ''}} {{fields.bartender_printer.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.bartender_printer.styles.style"
                            [ngClass]="fields.bartender_printer.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.bartender_printer.label + (fields.bartender_printer.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.bartender_printer.label}}<span *ngIf="fields.bartender_printer.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <BarTender-printers_dd_single 
                            data-cy="selector"
                            [type]="fields.bartender_printer.control.type"
                            formControlName="bartender_printer"
                            (displayTextChange)="fields.bartender_printer.control.displayText=$event"
                            [placeholder]="fields.bartender_printer.control.placeholder"
                            [styles]="fields.bartender_printer.control.styles"
                            [tooltip]="fields.bartender_printer.control.tooltip"
                        >
                        </BarTender-printers_dd_single>
                        <ng-container *ngIf="fields.bartender_printer.invalid">
                          <ng-container *ngFor="let error of fields.bartender_printer.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.bartender.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>