import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SerialNumbers_ds_serialnumbers_license_platesService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, packagingId?: number, orderId?: number, orderLineNumber?: number }): 
  Promise<{ result: { LicensePlateId?: number }[] }> 
  {
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_license_plates/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, packagingId?: number, orderId?: number, orderLineNumber?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_license_plates/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, packagingId?: number, orderId?: number, orderLineNumber?: number, $keys: number[] }): 
  Promise<{ result: { LicensePlateId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/datasources/ds_serialnumbers_license_plates/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      licenseplateIds?: number[], locationId?: number, warehouseIds?: number[], lotId?: number, materialIds?: number[], projectId?: number, ownerId?: number, archived?: boolean, fullTextSearch?: string, textSearch?: string, packagingId?: number, orderId?: number, orderLineNumber?: number    }) {
      return false;
    }
}
