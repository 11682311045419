import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Catalogs_ds_get_material_catalog_by_project_id_and_catalog_idService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { project_id: number, catalog_id: number }): 
  Promise<{ result: { ProjectId?: number, MaterialCatalogId?: number } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.project_id)) {
      missingRequiredInParams.push('\'project_id\'');
    }
    if (isNil(inParams.catalog_id)) {
      missingRequiredInParams.push('\'catalog_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Catalogs/datasources/ds_get_material_catalog_by_project_id_and_catalog_id/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      project_id: number, catalog_id: number    }) {
        if(isNil(inParams.project_id)) {
          return true; 
        }
        if(isNil(inParams.catalog_id)) {
          return true; 
        }
      return false;
    }
}
