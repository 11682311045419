import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Replenishments_ModuleContextService } from './Replenishments.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Replenishments_replenishment_comparison_dd_ComponentContextService {
  private readonly moduleContext: Replenishments_ModuleContextService;

  constructor(moduleContext: Replenishments_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'replenishment_comparison_dd',
      type: ComponentType.selector
    }
  }
}