import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Locations_ds_locations_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseId: number, fullTextSearch?: string, take?: number, skip?: number }): 
  Promise<{ result: { Id?: number, AllocationSequence?: number, ChildFootprint?: number, ChildStackHeight?: number, DimensionUomId?: number, EligibleForAllocation?: boolean, Height?: number, IsLoose?: boolean, Length?: number, Name?: string, ParentId?: number, PickSequence?: number, PutAwaySequence?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, Warehouse?: { Name?: string }, Type?: { Name?: string }, ParentLocationContainer?: { Name?: string }, Status?: { Name?: string }, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Locations/datasources/ds_locations_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseId: number, fullTextSearch?: string, take?: number, skip?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, AllocationSequence?: number, ChildFootprint?: number, ChildStackHeight?: number, DimensionUomId?: number, EligibleForAllocation?: boolean, Height?: number, IsLoose?: boolean, Length?: number, Name?: string, ParentId?: number, PickSequence?: number, PutAwaySequence?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, Warehouse?: { Name?: string }, Type?: { Name?: string }, ParentLocationContainer?: { Name?: string }, Status?: { Name?: string }, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Locations/datasources/ds_locations_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseId: number, fullTextSearch?: string, take?: number, skip?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, AllocationSequence?: number, ChildFootprint?: number, ChildStackHeight?: number, DimensionUomId?: number, EligibleForAllocation?: boolean, Height?: number, IsLoose?: boolean, Length?: number, Name?: string, ParentId?: number, PickSequence?: number, PutAwaySequence?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, Warehouse?: { Name?: string }, Type?: { Name?: string }, ParentLocationContainer?: { Name?: string }, Status?: { Name?: string }, DimensionsUom?: { Short_name?: string }, WeightUom?: { Short_name?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Locations/datasources/ds_locations_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouseId: number, fullTextSearch?: string, take?: number, skip?: number    }) {
        if(isNil(inParams.warehouseId)) {
          return true; 
        }
      return false;
    }
}
