<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-types" *ngIf="!filters.types.hidden && !filters.types.removed" 
                                  class="field-container standard {{filters.types.invalid ? 'invalid' : ''}} {{filters.types.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.types.styles.style"
                                  [ngClass]="filters.types.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.types.label + (filters.types.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.types.label}}<span *ngIf="filters.types.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <FootprintManager-footprint_events_type_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.types.control.type"
                                  formControlName="types"
                                  (displayTextChange)="filters.types.control.displayText=$event"
                                  [placeholder]="filters.types.control.placeholder"
                                  [styles]="filters.types.control.styles"
                                  [tooltip]="filters.types.control.tooltip"
                              >
                              </FootprintManager-footprint_events_type_dd_multi>
                              <ng-container *ngIf="filters.types.invalid">
                                <ng-container *ngFor="let error of filters.types.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-statuses" *ngIf="!filters.statuses.hidden && !filters.statuses.removed" 
                                  class="field-container standard {{filters.statuses.invalid ? 'invalid' : ''}} {{filters.statuses.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.statuses.styles.style"
                                  [ngClass]="filters.statuses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.statuses.label + (filters.statuses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.statuses.label}}<span *ngIf="filters.statuses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <FootprintManager-footprint_events_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.statuses.control.type"
                                  formControlName="statuses"
                                  (displayTextChange)="filters.statuses.control.displayText=$event"
                                  [placeholder]="filters.statuses.control.placeholder"
                                  [styles]="filters.statuses.control.styles"
                                  [tooltip]="filters.statuses.control.tooltip"
                              >
                              </FootprintManager-footprint_events_statuses_dd_multi>
                              <ng-container *ngIf="filters.statuses.invalid">
                                <ng-container *ngFor="let error of filters.statuses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.newGroup1.expanded">
              
              
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.events.hasTabToShow" class="tab" data-cy="tab-events">
              <h2 [className]="tabs.events.active? 'active': ''" (click)="tabs.events.activate()">{{tabs.events.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-footprint_events_grid *ngIf="tabs.events.active"
              #$tabs_events
              [statusIds]="$tabs_events_footprint_events_grid_inParams_statusIds"
              [typeIds]="$tabs_events_footprint_events_grid_inParams_typeIds"
              ($refreshEvent)="refresh(false, false, '$tabs_events')">
              </FootprintManager-footprint_events_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>