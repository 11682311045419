<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-operation_code" *ngIf="!filters.operation_code.hidden && !filters.operation_code.removed" 
            class="field-container standard {{filters.operation_code.invalid ? 'invalid' : ''}} {{filters.operation_code.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.operation_code.styles.style"
            [ngClass]="filters.operation_code.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.operation_code.label + (filters.operation_code.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.operation_code.label}}<span *ngIf="filters.operation_code.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Inventory-operationcodes_dd_multi 
            data-cy="selector-multi"
            [type]="filters.operation_code.control.type"
            formControlName="operation_code"
            (displayTextChange)="filters.operation_code.control.displayText=$event"
            [placeholder]="filters.operation_code.control.placeholder"
            [styles]="filters.operation_code.control.styles"
            [tooltip]="filters.operation_code.control.tooltip"
        >
        </Inventory-operationcodes_dd_multi>
        <ng-container *ngIf="filters.operation_code.invalid">
          <ng-container *ngFor="let error of filters.operation_code.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-status" *ngIf="!filters.status.hidden && !filters.status.removed" 
            class="field-container standard {{filters.status.invalid ? 'invalid' : ''}} {{filters.status.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.status.styles.style"
            [ngClass]="filters.status.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.status.label + (filters.status.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.status.label}}<span *ngIf="filters.status.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Inventory-task_statuses_dd_multi 
            data-cy="selector-multi"
            [type]="filters.status.control.type"
            formControlName="status"
            (displayTextChange)="filters.status.control.displayText=$event"
            [placeholder]="filters.status.control.placeholder"
            [styles]="filters.status.control.styles"
            [tooltip]="filters.status.control.tooltip"
        >
        </Inventory-task_statuses_dd_multi>
        <ng-container *ngIf="filters.status.invalid">
          <ng-container *ngFor="let error of filters.status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes"
          [matTooltip]="row.cells.Id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouse.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="operation_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.operation_code.displayControl.styles.style"
          [ngClass]="row.cells.operation_code.displayControl.styles.classes"
          [matTooltip]="row.cells.operation_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.operation_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reason_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.reason_code.displayControl.styles.style"
          [ngClass]="row.cells.reason_code.displayControl.styles.classes"
          [matTooltip]="row.cells.reason_code.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.reason_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.material_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.material_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes"
          [matTooltip]="row.cells.project.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serialnumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.serialnumber.displayControl.styles.style"
          [ngClass]="row.cells.serialnumber.displayControl.styles.classes"
          [matTooltip]="row.cells.serialnumber.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.serialnumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.actual_packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_packaging.displayControl.styles.style"
          [ngClass]="row.cells.actual_packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.expected_packaged_amount.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_packaged_amount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_packaging.displayControl.styles.style"
          [ngClass]="row.cells.expected_packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.order_lookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.order_lookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_date.displayControl.styles.style"
          [ngClass]="row.cells.created_date.displayControl.styles.classes"
          [matTooltip]="row.cells.created_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="completed_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.completed_date.displayControl.styles.style"
          [ngClass]="row.cells.completed_date.displayControl.styles.classes"
          [matTooltip]="row.cells.completed_date.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.completed_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="user">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.user.displayControl.styles.style"
          [ngClass]="row.cells.user.displayControl.styles.classes"
          [matTooltip]="row.cells.user.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.user.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_source_licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_source_licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.actual_source_licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_source_licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_source_licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_source_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_source_location.displayControl.styles.style"
          [ngClass]="row.cells.actual_source_location.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_source_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_source_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_target_licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_target_licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.actual_target_licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_target_licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_target_licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_target_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.actual_target_location.displayControl.styles.style"
          [ngClass]="row.cells.actual_target_location.displayControl.styles.classes"
          [matTooltip]="row.cells.actual_target_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.actual_target_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_source_licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_source_licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.expected_source_licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_source_licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_source_licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_source_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_source_location.displayControl.styles.style"
          [ngClass]="row.cells.expected_source_location.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_source_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_source_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_target_licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_target_licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.expected_target_licenseplate.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_target_licenseplate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_target_licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expected_target_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.expected_target_location.displayControl.styles.style"
          [ngClass]="row.cells.expected_target_location.displayControl.styles.classes"
          [matTooltip]="row.cells.expected_target_location.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.expected_target_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_owner_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_owner_reference.displayControl.styles.style"
          [ngClass]="row.cells.order_owner_reference.displayControl.styles.classes"
          [matTooltip]="row.cells.order_owner_reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_owner_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_vendor_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.order_vendor_reference.displayControl.styles.style"
          [ngClass]="row.cells.order_vendor_reference.displayControl.styles.classes"
          [matTooltip]="row.cells.order_vendor_reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.order_vendor_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
