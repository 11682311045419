import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  NgZone,
  Inject
} from '@angular/core';

import {
  ChartComponent,
  ApexTitleSubtitle,
  ApexChart,
  ApexLegend,
  ApexTooltip,
  ApexDataLabels,
  ApexStroke,
  ApexTheme
} from 'ng-apexcharts';

import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { InventoryTransformations_ShellService } from './InventoryTransformations.shell.service';
import { InventoryTransformations_OperationService } from './InventoryTransformations.operation.service';
import { InventoryTransformations_DatasourceService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_FlowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_ReportService } from './InventoryTransformations.report.index';
import { InventoryTransformations_LocalizationService } from './InventoryTransformations.localization.service';
import { InventoryTransformations_inventory_transformations_by_status_widget_ComponentContextService } from './InventoryTransformations.inventory_transformations_by_status_widget.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './InventoryTransformations.frontend.types'
import { $frontendTypes as $types} from './InventoryTransformations.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'InventoryTransformations-inventory_transformations_by_status_widget',
  templateUrl: './InventoryTransformations.inventory_transformations_by_status_widget.component.html'
})
export class InventoryTransformations_inventory_transformations_by_status_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  outParamsChange = new EventEmitter<{ slice?: { entity?: { inventory_transformation_by_status?: number, InventoryTransformationStatus?: { Name?: string } } } }>();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { owner_id?: number, project_id?: number, warehouse_ids?: number[], status_ids?: number[] } = { owner_id: null, project_id: null, warehouse_ids: [], status_ids: [] };
  //#region Inputs
  @Input('owner_id') set $inParams_owner_id(v: number) {
    this.inParams.owner_id = v;
  }
  get $inParams_owner_id(): number {
    return this.inParams.owner_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('warehouse_ids') set $inParams_warehouse_ids(v: number[]) {
    this.inParams.warehouse_ids = v;
  }
  get $inParams_warehouse_ids(): number[] {
    return this.inParams.warehouse_ids;
  }
  @Input('status_ids') set $inParams_status_ids(v: number[]) {
    this.inParams.status_ids = v;
  }
  get $inParams_status_ids(): number[] {
    return this.inParams.status_ids;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion
  @ViewChild('pieChart') 
  pieChart: ChartComponent;

  private selected = false;
  private entities: { inventory_transformation_by_status?: number, InventoryTransformationStatus?: { Name?: string } }[];

  series: number[] = [];
  labels: string[] = [];

  chart: ApexChart = {
    type: 'pie',
    width: '100%',
    height: '170px', // fixed heigh because of issues with ApexCharts not being able to respect the container height
    redrawOnParentResize: true,
    fontFamily: '"Segoe UI", "Roboto", sans-serif',
    events: {
      dataPointSelection: (event, chartContext, config) => {
        // run inside the Angular zone, since ng-apexcharts runs almost everything outside of it
        // see: https://github.com/apexcharts/ng-apexcharts/blob/master/projects/ng-apexcharts/src/lib/chart/chart.component.ts
        this.ngZone.run(() => {
          if (this.isPiePointSelected(config.dataPointIndex)) {
            this.outParamsChange.emit({ slice: null});
          } else {
            this.outParamsChange.emit({ slice: { entity: this.entities[config.dataPointIndex] } } );
          }
        });
      }
    }
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  stroke: ApexStroke = {
    width: 0
  };
  title: ApexTitleSubtitle = {
  };
  legend: ApexLegend = {
    show: false
  };
  tooltip: ApexTooltip = {
    followCursor: false,
    fillSeriesColor: true,
    onDatasetHover: {
      highlightDataSeries: true,
    },
    style: {
      fontSize: '14px',
      fontFamily: '"Segoe UI", "Roboto", sans-serif',
    },
    enabled: true
  };
  theme: ApexTheme = {
    palette: 'palette2',
  };

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: InventoryTransformations_ShellService,
    private $datasources: InventoryTransformations_DatasourceService,
    private $flows: InventoryTransformations_FlowService,
    private $reports: InventoryTransformations_ReportService,
    private $localization: InventoryTransformations_LocalizationService,
    private $operations: InventoryTransformations_OperationService,
    private $logger: CleanupLoggerService,
    private $context: InventoryTransformations_inventory_transformations_by_status_widget_ComponentContextService,
    private ngZone: NgZone
  ) { 
    super();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  async $init() {
    
    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.$utils;

    const dsParams = {
      owner_id:  $widget.inParams.owner_id ,
      project_id:  $widget.inParams.project_id ,
      warehouse_ids:  $widget.inParams.warehouse_ids ,
      status_ids:  $widget.inParams.status_ids 
    };

    const data = await this.$datasources.InventoryTransformations.ds_inventory_transformations_by_status_widget.getList(dsParams);
    this.entities = data.result;
    await this.$dataLoaded();
  }

   async $dataLoaded() {
    const $widget = this;
    const $utils = this.$utils;

    if(this.entities) {
      this.labels = this.entities.map(e => { 
        const $slice = { entity:  e };
        return $slice?.entity?.InventoryTransformationStatus?.Name;
      });

      this.series = this.entities.map(e => { 
        const $slice = { entity:  e };
        return $slice?.entity?.inventory_transformation_by_status;
      });
    }

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  private isPiePointSelected(pointIndex: number) {
    if (this.pieChart) {
      const pieSlice = (this.pieChart as any).chartObj.w.globals.dom.Paper.select(`.apexcharts-pie-slice-${pointIndex}`).members[0];
      const isSelected = pieSlice.attr('data:pieClicked');
      return isSelected === 'true';
    }
    return false;
  }

}
