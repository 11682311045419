import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_grid_ComponentContextService } from './FootprintManager.manufacturing_order_undo_manufacturing_production_finished_product_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_licenseplates_by_location_singleComponent } from './Manufacturing.licenseplates_by_location_single.component'
import { FootprintManager_manufacturing_output_locations_dd_singleComponent } from './FootprintManager.manufacturing_output_locations_dd_single.component'
import { Manufacturing_lots_with_inventory_by_license_plate_dd_singleComponent } from './Manufacturing.lots_with_inventory_by_license_plate_dd_single.component'


interface IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentEntity {
  Id?: number, ActualInventoryAmount?: number, ActualInventoryAmountPackId?: number, ActualPackagedAmount?: number, ActualPackagedPackId?: number, ActualSourceLicensePlateId?: number, ActualSourceLocationId?: number, ActualTargetLicensePlateId?: number, ActualTargetLocationId?: number, AuxiliaryExternalIdentifier?: number, ChainHead?: number, CreatedSysDateTime?: string, LotId?: number, ManufacturingOrderId?: number, ManufacturingOrderLineId?: number, MaterialId?: number, OperationCodeId?: number, ProjectId?: number, SerialNumberId?: number, WarehouseId?: number, WorkShiftId?: number, Material?: { Name?: string, ProjectId?: number }, ActualPackagedPack?: { Name?: string }, ManufacturingOrder?: { DefaultStagingLocationId?: number }, Lot?: { Id?: number, LookupCode?: string, VendorLot?: { Id?: number, ExpirationDate?: string } }, WorkShift?: { Id?: number, Name?: string }, ActualSourceLicensePlate?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }}

interface IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentInParams {
  manufacturing_order_id?: number, manufacturing_order_line_id?: number, task_chain_head?: number}

interface IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentOutParams {
  rows?: { material_id?: number, decrease_quantity?: number, license_plate?: number, location?: number, packaging_id?: number, lot?: number }[]}

class FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent;
  entity: IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    quantity_to_unmake_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    unmake_from_lp_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    unmake_from_location_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    unmake_from_lot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    line_number: null,
    finished_product: null,
    produced_quantity: null,
    quantity_to_unmake: null,
    unmake_from_lp: null,
    unmake_from_location: null,
    unmake_from_lot: null,
  }

  get $fields_unmake_from_lp_selector_inParams_location_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.ActualTargetLocationId;
    
    return expr;
  }
  cacheValueFor_$fields_unmake_from_location_selector_inParams_warehouse_ids: number[];
  get $fields_unmake_from_location_selector_inParams_warehouse_ids(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = [$row.entity.WarehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_unmake_from_location_selector_inParams_warehouse_ids, expr)) {
      this.cacheValueFor_$fields_unmake_from_location_selector_inParams_warehouse_ids = expr;
    }
    return this.cacheValueFor_$fields_unmake_from_location_selector_inParams_warehouse_ids;
  }
  get $fields_unmake_from_location_selector_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Material?.ProjectId;
    
    return expr;
  }
  get $fields_unmake_from_lot_selector_inParams_material_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.MaterialId;
    
    return expr;
  }
  get $fields_unmake_from_lot_selector_inParams_license_plate(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.cells.unmake_from_lp.editControl.value;
    
    return expr;
  }
  get $fields_unmake_from_lot_selector_inParams_location(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.ActualTargetLocationId;
    
    return expr;
  }
  get $fields_unmake_from_lot_selector_inParams_package_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.ActualPackagedPackId;
    
    return expr;
  }
  get $fields_unmake_from_lot_selector_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.WarehouseId;
    
    return expr;
  }


  constructor(
    grid: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.line_number = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.line_number,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.finished_product = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.finished_product,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.produced_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.produced_quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.quantity_to_unmake = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.quantity_to_unmake,
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['quantity_to_unmake_edit'] as DatexFormControl, null, false, false, '', '', null)
      );
    
    this.cells.unmake_from_lp = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.unmake_from_lp,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['unmake_from_lp_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.unmake_from_location = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.unmake_from_location,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['unmake_from_location_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.unmake_from_lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.unmake_from_lot,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['unmake_from_lot_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    
    this.formGroup
      .controls['unmake_from_lp_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_license_plate_changed();
      });
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      manufacturing_order_id:  $grid.inParams.manufacturing_order_id ,
      manufacturing_order_line_id:  $grid.inParams.manufacturing_order_line_id ,
      operation_code:  49 ,
      task_chain_head:  $grid.inParams.task_chain_head ,
      take:  null ,
      skip:  null ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_production_confirmation_data.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.finished_product.displayControl as TextModel).text = $row.entity.Material?.Name;
    (this.cells.produced_quantity.displayControl as TextModel).text = `${$row.entity.ActualPackagedAmount} ${$row.entity.ActualPackagedPack?.Name}`;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_undo_manufacturing_production_finished_product_grid.on_row_data_loaded');
  $row.cells.line_number.displayControl.text = ($grid.rows.length + 1).toString();
  
  $row.cells.quantity_to_unmake.editControl.value = $row.entity.ActualPackagedAmount;
  $row.cells.quantity_to_unmake.displayControl.text = $row.entity.ActualPackagedAmount.toString();
  
  $row.cells.unmake_from_lp.editControl.value = $row.entity.ActualTargetLicensePlateId;
  
  const licensePlateRequest = await $datasources.Manufacturing.ds_get_licenseplates_by_locationId.get({locationId: $row.entity.ActualTargetLocationId, license_plate_ids: [$row.entity.ActualTargetLicensePlateId]});
  if( $utils.isDefined(licensePlateRequest) && licensePlateRequest.result.length > 0 )
  {
      const licensePlate = licensePlateRequest.result[0];
      $row.cells.unmake_from_lp.displayControl.text = licensePlate?.LookupCode;
  }
  
  $row.cells.unmake_from_location.editControl.value = $row.entity.ActualTargetLocationId;
  
  const locationsRequest = await $datasources.Manufacturing.ds_get_manufacturing_output_locations_dd.get({warehouse_ids: [$row.entity.WarehouseId], project_id: $row.entity.Material?.ProjectId});
  if( $utils.isDefined(licensePlateRequest) )
  {
      const currentLocation = locationsRequest.result.find( item => item.Id == $row.entity.ActualTargetLocationId );
      if( $utils.isDefined(currentLocation) )
      {
          $row.cells.unmake_from_location.displayControl.text = currentLocation?.Name;
      }
  }
  
  const lotRequest = await $datasources.Manufacturing.ds_physical_inventory_by_license_plate_lot.get({lot_id: $row.entity.LotId,
                                                                                                      license_plate_id: $row.entity.ActualTargetLicensePlateId,
                                                                                                      material_id: $row.entity.MaterialId,
                                                                                                      warehouse_id: $row.entity.WarehouseId,
                                                                                                      package_id: $row.entity.ActualPackagedPackId,
                                                                                                      location_id: $row.entity.ActualTargetLocationId});
  if( $utils.isDefined(lotRequest) )
  {
      if( $utils.isDefined(lotRequest) && lotRequest.result.length > 0 )
      {
          const lot = lotRequest.result[0];
          $row.cells.unmake_from_lot.displayControl.text = lot?.Lot?.LookupCode;
          $row.cells.unmake_from_lot.editControl.value = {LicensePlateId: $row.entity.ActualTargetLicensePlateId, LotId: $row.entity.LotId, PackagedId: $row.entity.ActualPackagedPackId};
      }
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_undo_manufacturing_production_finished_product_grid.on_save_existing_row');
  
  $row.entity.ActualPackagedAmount = $row.cells.quantity_to_unmake.editControl.value;
  $row.entity.ActualTargetLicensePlateId = $row.cells.unmake_from_lp.editControl.value;
  $row.entity.ActualTargetLocationId = $row.cells.unmake_from_location.editControl.value;
  
  $row.cells.quantity_to_unmake.displayControl.text = $row.entity.ActualPackagedAmount.toString();
  
  const licensePlateRequest = await $datasources.Manufacturing.ds_get_licenseplates_by_locationId.get({locationId: $row.entity.ActualTargetLocationId, license_plate_ids: [$row.entity.ActualTargetLicensePlateId]});
  if( $utils.isDefined(licensePlateRequest) && licensePlateRequest.result.length > 0 )
  {
      const licensePlate = licensePlateRequest.result[0];
      $row.cells.unmake_from_lp.displayControl.text = licensePlate?.LookupCode;
  }
  
  const locationsRequest = await $datasources.Manufacturing.ds_get_manufacturing_output_locations_dd.get({warehouse_ids: [$row.entity.WarehouseId], project_id: $row.entity.Material?.ProjectId});
  if( $utils.isDefined(licensePlateRequest) )
  {
      const currentLocation = locationsRequest.result.find( item => item.Id == $row.entity.ActualTargetLocationId );
      if( $utils.isDefined(currentLocation) )
      {
          $row.cells.unmake_from_location.displayControl.text = currentLocation?.Name;
      }
  }
  
  const lotRequest = await $datasources.Manufacturing.ds_get_lots_by_materialIds.get({materialIds: [$row.entity.MaterialId], lot_id: $row.cells.unmake_from_lot.editControl.value.LotId});
  if( $utils.isDefined(lotRequest) && lotRequest.result.length > 0 )
  {
      const lot = lotRequest.result[0];
      $row.cells.unmake_from_lot.displayControl.text = lot?.LookupCode;
  }
  
  let outputRows = [];
  
  $grid.rows.forEach( item => {
      const outputRow = {material_id: item.entity.MaterialId,
                         decrease_quantity: item.entity.ActualPackagedAmount,
                         license_plate: item.cells.unmake_from_lp.editControl.value,
                         location: item.cells.unmake_from_location.editControl.value,
                         packaging_id: item.entity.ActualPackagedPackId,
                         lot: item.cells.unmake_from_lot.editControl.value.LotId};
  
      outputRows.push(outputRow);
  })
  
  $grid.outParams.rows = outputRows;
  
  $grid.events.outParamsChange.emit();
  }
  on_license_plate_changed(event = null) {
    return this.on_license_plate_changedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_license_plate_changedInternal(
    $row: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_undo_manufacturing_production_finished_product_grid.on_license_plate_changed');
  $row.cells.unmake_from_lot.editControl.value = null;
  $row.cells.unmake_from_lot.displayControl.text = "";
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_licenseplates_by_location_singleComponent),
    forwardRef(() => FootprintManager_manufacturing_output_locations_dd_singleComponent),
    forwardRef(() => Manufacturing_lots_with_inventory_by_license_plate_dd_singleComponent),
  ],
  selector: 'FootprintManager-manufacturing_order_undo_manufacturing_production_finished_product_grid',
  templateUrl: './FootprintManager.manufacturing_order_undo_manufacturing_production_finished_product_grid.component.html'
})
export class FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentInParams = { manufacturing_order_id: null, manufacturing_order_line_id: null, task_chain_head: null };

  outParams: IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentOutParams = { rows: [] };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ rows?: { material_id?: number, decrease_quantity?: number, license_plate?: number, location?: number, packaging_id?: number, lot?: number }[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     line_number: new GridHeaderModel(new HeaderStyles(null, null), 'line_number', '#', false, false, null, false),       finished_product: new GridHeaderModel(new HeaderStyles(null, null), 'finished_product', 'Finished product', false, false, null, false),       produced_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'produced_quantity', 'Produced quantity', false, false, null, false),       quantity_to_unmake: new GridHeaderModel(new HeaderStyles(null, null), 'quantity_to_unmake', 'Quantity to unmake', true, false, null, false),       unmake_from_lp: new GridHeaderModel(new HeaderStyles(null, null), 'unmake_from_lp', 'Unmake from LP', true, false, null, false),       unmake_from_location: new GridHeaderModel(new HeaderStyles(null, null), 'unmake_from_location', 'Unmake from location', true, false, null, false),       unmake_from_lot: new GridHeaderModel(new HeaderStyles(null, null), 'unmake_from_lot', 'Unmake from lot', true, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentRowModel[] = [];

  @Input('manufacturing_order_id') set $inParams_manufacturing_order_id(value: any) {
    this.inParams['manufacturing_order_id'] = value;
  }
  get $inParams_manufacturing_order_id(): any {
    return this.inParams['manufacturing_order_id'] ;
  }
  @Input('manufacturing_order_line_id') set $inParams_manufacturing_order_line_id(value: any) {
    this.inParams['manufacturing_order_line_id'] = value;
  }
  get $inParams_manufacturing_order_line_id(): any {
    return this.inParams['manufacturing_order_line_id'] ;
  }
  @Input('task_chain_head') set $inParams_task_chain_head(value: any) {
    this.inParams['task_chain_head'] = value;
  }
  get $inParams_task_chain_head(): any {
    return this.inParams['task_chain_head'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Undoing manufacturing production for the finished products';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      manufacturing_order_id:  $grid.inParams.manufacturing_order_id ,
      manufacturing_order_line_id:  $grid.inParams.manufacturing_order_line_id ,
      operation_code:  49 ,
      task_chain_head:  $grid.inParams.task_chain_head ,
      take:  null ,
      skip:  null ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_production_confirmation_data.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_undo_manufacturing_production_finished_product_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_undo_manufacturing_production_finished_product_grid.on_grid_data_loaded');
  let outputRows = [];
  
  $grid.rows.forEach( item => {
      const outputRow = {material_id: item.entity.MaterialId,
                         decrease_quantity: item.entity.ActualPackagedAmount,
                         license_plate: item.cells.unmake_from_lp.editControl.value,
                         location: item.cells.unmake_from_location.editControl.value,
                         packaging_id: item.entity.ActualPackagedPackId,
                         lot: item.entity.LotId};
  
      outputRows.push(outputRow);
  })
  
  $grid.outParams.rows = outputRows;
  
  $grid.events.outParamsChange.emit();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
