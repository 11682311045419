import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_reason_code_creation_form_ComponentContextService } from './FootprintManager.reason_code_creation_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { FootprintManager_reason_code_parent_entities_dd_singleComponent } from './FootprintManager.reason_code_parent_entities_dd_single.component'
import { FootprintManager_reason_code_parent_entity_ids_dd_singleComponent } from './FootprintManager.reason_code_parent_entity_ids_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_reason_code_parent_entities_dd_singleComponent),
    forwardRef(() => FootprintManager_reason_code_parent_entity_ids_dd_singleComponent),
  ],
  selector: 'FootprintManager-reason_code_creation_form',
  templateUrl: './FootprintManager.reason_code_creation_form.component.html'
})
export class FootprintManager_reason_code_creation_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    reason_entity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reason_type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    reason_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    displayed_label: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inventory_status_in_minutes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(null, null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    reason_entity: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reason_entity'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Reason entity', false, false),
    reason_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reason_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Reason type', true, false),
    reason_code: new FieldModel(new TextBoxModel(this.formGroup.controls['reason_code'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Reason code', true, false),
    displayed_label: new FieldModel(new TextBoxModel(this.formGroup.controls['displayed_label'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Displayed label', false, false),
    inventory_status_in_minutes: new FieldModel(new NumberBoxModel(this.formGroup.controls['inventory_status_in_minutes'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Reset inventory status in minutes', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Label of newGroup1',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_reason_type_selector_inParams_parent(): string {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.reason_entity.control.displayText;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    reason_entity: this.fields.reason_entity.control.valueChanges
    ,
    reason_type: this.fields.reason_type.control.valueChanges
    ,
    reason_code: this.fields.reason_code.control.valueChanges
    ,
    displayed_label: this.fields.displayed_label.control.valueChanges
    ,
    inventory_status_in_minutes: this.fields.inventory_status_in_minutes.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_reason_code_creation_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create reason code';
  
    const $form = this;
    const $utils = this.$utils;

    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .reason_entity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reason_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reason_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .displayed_label
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .inventory_status_in_minutes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootprintManager_reason_code_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_reason_code_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 11/21/2023
  
  $form.outParams.confirm = false;
  // Check for  duplicates
  const reasoncodes = (await $datasources.Inventory.ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId.get({
      lookupcode: $form.fields.reason_code.control.value,
      parentId: $form.fields.reason_type.control.value,
      parentEntity: $form.fields.reason_entity.control.value
  })).result;
  if ($utils.isDefined(reasoncodes)) {
  
      throw new Error('Reason code already exists.')
  }
  else {
      try {
  
          const nextReasonId = (await $flows.Utilities.reserve_nextId_flow({ entity: "ReasonCode" })).nextId;
  
          let payload = {
              LookupCode: $form.fields.reason_code.control.value,
              Name: $form.fields.reason_code.control.value,
              ParentEntity: $form.fields.reason_entity.control.value,
              ParentId: $form.fields.reason_type.control.value,
              Label: $form.fields.displayed_label.control.value,
              EnumName: $form.fields.reason_code.control.value,
              NumberOfMinutesBeforeExpiration: $form.fields.inventory_status_in_minutes.control.value,
              SystemDefined: false
          };
  
          let results: {
              success?: boolean;
              message?: string;
              id?: number;
          };
  
          let action = "";
  
          {
              //Create
              if ($utils.isDefined(payload)) {
                  results = (await $flows.FootprintManager.reason_codes_create_update_delete_flow({ payload: payload, id: nextReasonId, action: "Create" }));
                  action = "creating";
              };
          };
  
          if (action !== "") {
              if (!$utils.isDefined(results)) {
                  throw new Error("No results were returned when firing the [reason_codes_create_update_delete_flow] flow!");
              } else {
                  if (!results.success) {
                      throw new Error(`${results.message} - ${JSON.stringify(payload)}`
                      );
                  } else {
                      $form.outParams.confirm = true;
                      $form.close();
                  };
              };
          };
      } catch (error) {
  
          $shell.FootprintManager.showErrorDetails('Failure to save', `Error saving reason code ${$form.fields.reason_code.control.value}.`, error);
          throw error; // to prevent displayMode 
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootprintManager_reason_code_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_reason_code_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_reason_code_creation_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_reason_code_creation_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $form.title = `Create reason code`
  }
  //#endregion private flows
}
