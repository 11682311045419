import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Allocations.frontend.types'

@Injectable({ providedIn: 'root' })
export class Allocations_get_allocation_configurations_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { context: $frontendTypes.Allocations.e_hard_allocation_context, filters: { material_id?: number, account_id?: number, project_id?: number, owner_id?: number, warehouse_id?: number, order_class_id?: number, material_group_id?: number }, allow_inherited_configs?: boolean }): Promise<{ config?: $frontendTypes.Allocations.i_allocation_config, reasons?: string[], config_source?: $frontendTypes.Allocations.i_allocation_config, config_id?: number, applicable_config_ids?: number[], config_filters?: { name: string, value: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.context)) {
      missingRequiredInParams.push('\'context\'');
    }
    if (isNil(inParams.filters)) {
      missingRequiredInParams.push('\'filters\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Allocations/functions/get_allocation_configurations_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

