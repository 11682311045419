<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="new_plan" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="new_plan($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes"
          [matTooltip]="row.cells.status.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes"
          [matTooltip]="row.cells.name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="template_order_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.template_order_id.displayControl.styles.style"
          [ngClass]="row.cells.template_order_id.displayControl.styles.classes"
          [matTooltip]="row.cells.template_order_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.template_order_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="finished_product">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.finished_product.displayControl.styles.style"
          [ngClass]="row.cells.finished_product.displayControl.styles.classes"
          [matTooltip]="row.cells.finished_product.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.finished_product.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="plan_setup">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.plan_setup.displayControl.styles.style"
          [ngClass]="row.cells.plan_setup.displayControl.styles.classes"
          [matTooltip]="row.cells.plan_setup.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.plan_setup.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="plan_schedule">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.plan_schedule.displayControl.styles.style"
          [ngClass]="row.cells.plan_schedule.displayControl.styles.classes"
          [matTooltip]="row.cells.plan_schedule.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.plan_schedule.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="next_execution">

    <ng-template gridCellDisplayControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['next_execution_display']"
        [format]="row.cells.next_execution.displayControl.format"
        [mode]="row.cells.next_execution.displayControl.mode"
        [ngStyle]="row.cells.next_execution.displayControl.styles.style"
        [ngClass]="row.cells.next_execution.displayControl.styles.classes"
        [tooltip]="row.cells.next_execution.displayControl.tooltip">
    </app-datebox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_run">

    <ng-template gridCellDisplayControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['last_run_display']"
        [format]="row.cells.last_run.displayControl.format"
        [mode]="row.cells.last_run.displayControl.mode"
        [ngStyle]="row.cells.last_run.displayControl.styles.style"
        [ngClass]="row.cells.last_run.displayControl.styles.classes"
        [tooltip]="row.cells.last_run.displayControl.tooltip">
    </app-datebox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_run_state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.last_run_state.displayControl.styles.style"
          [ngClass]="row.cells.last_run_state.displayControl.styles.classes"
          [matTooltip]="row.cells.last_run_state.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.last_run_state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
