import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService } from './FootprintManager.manufacturing_order_line_components_production_confirmation_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent } from './FootprintManager.manufacturing_order_lines_production_sub_components_grid.component';

interface IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentEntity {
  Id?: number, Amount?: number, FinishedGoodLineId?: number, LineNumber?: number, MaterialId?: number, OrderId?: number, PackagedAmount?: number, PackagingId?: number, ParentId?: number, StatusId?: number, FinishedGoodLine?: { Amount?: number, LineNumber?: number, MaterialId?: number, PackagedAmount?: number }, Material?: { Id?: number, ControllerTypeId?: number, Description?: string, Name?: string, PackagingLookups?: { PackagingId?: number, Packaging?: { ShortName?: string } }[], InventoryByWarehouseMaterial?: { MaterialId?: number, PackagedId?: number, CurrentlyAvailablePackagedAmount?: number }[] }, Status?: { Name?: string }}

interface IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentInParams {
  finished_good_id?: number, warehouse_id?: number, quantity_to_produce?: number, license_plate_ids?: number[], location_id?: number, project_id?: number}

interface IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentOutParams {
  data?: { lineNumber?: number, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[]}

class FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent;
  entity: IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentEntity;

  vars: { data_loaded?: boolean } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    line_number: null,
    component: null,
    planned_quantity: null,
  }


  //#region rowExpand inParams


  get $rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_material_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.MaterialId;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_location_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.location_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_component(): string {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Material?.Name;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_main_component_identifier(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.Id;
    
    return expr;
  }

  cacheValueFor_$rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_rows: { lineNumber: number, consumedQuantity: number, scrappedQuantity: number, sourceLicensePlate: number, lot: number, lotExpiration: string, mainComponentIdentifier: number, packageId: number, componentMaterialId: number }[];

  get $rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_rows(): { lineNumber: number, consumedQuantity: number, scrappedQuantity: number, sourceLicensePlate: number, lot: number, lotExpiration: string, mainComponentIdentifier: number, packageId: number, componentMaterialId: number }[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.vars?.components?.filter(x => x.mainComponentIdentifier == $row.entity.Id);
    
    if(!isEqual(this.cacheValueFor_$rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_rows, expr)) {
      this.cacheValueFor_$rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_rows = expr;
    }
    return this.cacheValueFor_$rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_rows;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_package_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.PackagingId;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_project_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.project_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_order_lines_production_sub_components_grid_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.warehouse_id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.line_number = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.line_number,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.component = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.component,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.planned_quantity = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.planned_quantity,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      finished_good_id:  $grid.inParams.finished_good_id ,
      warehouse_id:  $grid.inParams.warehouse_id ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_lines_components_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.line_number.displayControl as TextModel).text = $row.entity.LineNumber.toString();
    (this.cells.component.displayControl as TextModel).text = $row.entity.Material.Name;
    (this.cells.planned_quantity.displayControl as TextModel).text = ($grid.inParams.quantity_to_produce * $row.entity.PackagedAmount).toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_row(event = null) {
    return this.on_init_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_rowInternal(
    $row: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_components_production_confirmation_grid.on_init_row');
  
  }
  sub_row_add_event_handler(event = null) {
    return this.sub_row_add_event_handlerInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async sub_row_add_event_handlerInternal(
    $row: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_components_production_confirmation_grid.sub_row_add_event_handler');
  let newRow = $event;
  
  if( !$utils.isDefined($grid.vars.components) )
      $grid.vars.components = [];
  
  $grid.vars.components.push(newRow);
  
  $grid.outParams.data = $grid.vars.components;
  
  $grid.events.outParamsChange.emit();
  }
  sub_row_modify_event_handler(event = null) {
    return this.sub_row_modify_event_handlerInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async sub_row_modify_event_handlerInternal(
    $row: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_components_production_confirmation_grid.sub_row_modify_event_handler');
  
  let modifiedRow = $event;
  
  $grid.vars.components.forEach( item => {
      if( Number(item.lineNumber) == Number(modifiedRow.lineNumber) && item.mainComponentIdentifier == $row.entity.Id )
      {
          item.consumedQuantity = modifiedRow.consumedQuantity;
          item.lot = modifiedRow.lot;
          item.lotExpiration = modifiedRow.lotExpiration;
          item.sourceLicensePlate = modifiedRow.sourceLicensePlate;
          item.scrappedQuantity = modifiedRow.scrappedQuantity;
      }
  })
  
  $grid.outParams.data = $grid.vars.components;
  
  $grid.events.outParamsChange.emit();
  }
  sub_row_delete_event_handler(event = null) {
    return this.sub_row_delete_event_handlerInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async sub_row_delete_event_handlerInternal(
    $row: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_components_production_confirmation_grid.sub_row_delete_event_handler');
  let deletedRow = $event;
  
  const index = $grid.vars.components.findIndex( x => Number(x.lineNumber) == Number(deletedRow.lineNumber) && x.mainComponentIdentifier == $row.entity.Id );
  if( index > -1 )
  {
      $grid.vars.components.splice(index, 1);
  }
  
  $grid.outParams.data = $grid.vars.components;
  
  $grid.events.outParamsChange.emit();
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_components_production_confirmation_grid.on_row_data_loaded');
  if( !$utils.isDefined($row.vars.data_loaded) )
      $row.vars.data_loaded = false;
  
  if( $row.vars.data_loaded )
      return;
  
  const licensePlates = await $datasources.Manufacturing.ds_get_license_plate_inventory_by_material_warehouse_location.get(
      {
          material_id: $row.entity.MaterialId,
          package_id: $row.entity.PackagingId,
          location_id: $grid.inParams.location_id,
          warehouse_id: $grid.inParams.warehouse_id,
          quantity_for_consumption: $row.entity.PackagedAmount * $grid.inParams.quantity_to_produce
      }
  )
  
  if( $utils.isDefined(licensePlates.result) )
  {
      const licensePlatesWithInventory = licensePlates.result.filter( item => item.AvailablePackagedAmount >= ( $row.entity.PackagedAmount * $grid.inParams.quantity_to_produce ) )
  
      if( licensePlatesWithInventory.length == 1 )
      {
          if( !$utils.isDefined($grid.vars.components) )
              $grid.vars.components = [];
  
          const licensePlateComponent = {lineNumber: 1,
                                         mainComponentIdentifier: $row.entity.Id,
                                         consumedQuantity: $row.entity.PackagedAmount * $grid.inParams.quantity_to_produce,
                                         scrappedQuantity: 0,
                                         sourceLicensePlate: licensePlatesWithInventory[0].LicensePlateId,
                                         lot: licensePlatesWithInventory[0].LotId,
                                         lotExpiration: licensePlatesWithInventory[0].Lot?.VendorLot?.ExpirationDate,
                                         packageId: $row.entity.PackagingId,
                                         componentMaterialId: $row.entity.MaterialId};
  
          $grid.vars.components.push(licensePlateComponent);
  
          if( !$utils.isDefined($grid.outParams.data) )
              $grid.outParams.data = [];
  
          $grid.outParams.data.push(licensePlateComponent);
  
          $grid.events.outParamsChange.emit();
      }
  }
  
  $row.vars.data_loaded = true;
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_manufacturing_order_lines_production_sub_components_gridComponent)
  ],
  selector: 'FootprintManager-manufacturing_order_line_components_production_confirmation_grid',
  templateUrl: './FootprintManager.manufacturing_order_line_components_production_confirmation_grid.component.html'
})
export class FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentInParams = { finished_good_id: null, warehouse_id: null, quantity_to_produce: null, license_plate_ids: [], location_id: null, project_id: null };

  outParams: IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentOutParams = { data: [] };

  //#region Variables
  vars: { components?: { lineNumber: number, consumedQuantity: number, scrappedQuantity: number, sourceLicensePlate: number, lot: number, lotExpiration: string, mainComponentIdentifier: number, packageId: number, componentMaterialId: number }[] } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ data?: { lineNumber?: number, consumedQuantity?: number, scrappedQuantity?: number, sourceLicensePlate?: number, lot?: number, lotExpiration?: string, mainComponentIdentifier?: number, packageId?: number, componentMaterialId?: number }[] }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     line_number: new GridHeaderModel(new HeaderStyles(null, null), 'line_number', '#', false, false, null, false),       component: new GridHeaderModel(new HeaderStyles(null, null), 'component', 'Component', false, false, null, false),       planned_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'planned_quantity', 'Planned quantity', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentRowModel[] = [];

  @Input('finished_good_id') set $inParams_finished_good_id(value: any) {
    this.inParams['finished_good_id'] = value;
  }
  get $inParams_finished_good_id(): any {
    return this.inParams['finished_good_id'] ;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(value: any) {
    this.inParams['warehouse_id'] = value;
  }
  get $inParams_warehouse_id(): any {
    return this.inParams['warehouse_id'] ;
  }
  @Input('quantity_to_produce') set $inParams_quantity_to_produce(value: any) {
    this.inParams['quantity_to_produce'] = value;
  }
  get $inParams_quantity_to_produce(): any {
    return this.inParams['quantity_to_produce'] ;
  }
  @Input('license_plate_ids') set $inParams_license_plate_ids(value: any) {
    this.inParams['license_plate_ids'] = value;
  }
  get $inParams_license_plate_ids(): any {
    return this.inParams['license_plate_ids'] ;
  }
  @Input('location_id') set $inParams_location_id(value: any) {
    this.inParams['location_id'] = value;
  }
  get $inParams_location_id(): any {
    return this.inParams['location_id'] ;
  }
  @Input('project_id') set $inParams_project_id(value: any) {
    this.inParams['project_id'] = value;
  }
  get $inParams_project_id(): any {
    return this.inParams['project_id'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Order line components production confirmation grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      finished_good_id:  $grid.inParams.finished_good_id ,
      warehouse_id:  $grid.inParams.warehouse_id ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_order_lines_components_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_components_production_confirmation_grid.on_init');
  
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_manufacturing_order_line_components_production_confirmation_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_order_line_components_production_confirmation_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_order_line_components_production_confirmation_grid.on_grid_data_loaded');
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
