import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Materials_ds_get_material_packaging_by_materialId_and_packagingIdService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { materialId: number, packagingId: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AllowFractions?: boolean, BasePackagingId?: number, BasePackagingQuantity?: number, IsBasePackaging?: boolean, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_material_packaging_by_materialId_and_packagingId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { materialId: number, packagingId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AllowFractions?: boolean, BasePackagingId?: number, BasePackagingQuantity?: number, IsBasePackaging?: boolean, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_material_packaging_by_materialId_and_packagingId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { materialId: number, packagingId: number, $keys: { MaterialId?: number, PackagingId?: number }[] }): 
  Promise<{ result: { MaterialId?: number, PackagingId?: number, AllowFractions?: boolean, BasePackagingId?: number, BasePackagingQuantity?: number, IsBasePackaging?: boolean, PalletHigh?: number, PalletTie?: number, QuantityPrecisionScale?: number, ShippingVolume?: number, ShippingWeight?: number, Volume?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Materials/datasources/ds_get_material_packaging_by_materialId_and_packagingId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      materialId: number, packagingId: number    }) {
        if(isNil(inParams.materialId)) {
          return true; 
        }
        if(isNil(inParams.packagingId)) {
          return true; 
        }
      return false;
    }
}
