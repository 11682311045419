<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="serial_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.serial_number.displayControl.styles.style"
          [ngClass]="row.cells.serial_number.displayControl.styles.classes"
          [matTooltip]="row.cells.serial_number.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.serial_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.vendor_lot.displayControl.styles.style"
          [ngClass]="row.cells.vendor_lot.displayControl.styles.classes"
          [matTooltip]="row.cells.vendor_lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.vendor_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['expiration_date_display']"
        [format]="row.cells.expiration_date.displayControl.format"
        [mode]="row.cells.expiration_date.displayControl.mode"
        [ngStyle]="row.cells.expiration_date.displayControl.styles.style"
        [ngClass]="row.cells.expiration_date.displayControl.styles.classes"
        [tooltip]="row.cells.expiration_date.displayControl.tooltip">
    </app-datebox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weight.displayControl.styles.style"
          [ngClass]="row.cells.weight.displayControl.styles.classes"
          [matTooltip]="row.cells.weight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="dimensions">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.dimensions.displayControl.styles.style"
          [ngClass]="row.cells.dimensions.displayControl.styles.classes"
          [matTooltip]="row.cells.dimensions.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.dimensions.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
