import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { InventoryTransformations_ShellService } from './InventoryTransformations.shell.service';
import { InventoryTransformations_OperationService } from './InventoryTransformations.operation.service';
import { InventoryTransformations_DatasourceService } from './InventoryTransformations.datasource.index';
import { InventoryTransformations_FlowService } from './InventoryTransformations.flow.index';
import { InventoryTransformations_ReportService } from './InventoryTransformations.report.index';
import { InventoryTransformations_LocalizationService } from './InventoryTransformations.localization.service';
import { InventoryTransformations_inventory_transformation_types_dd_ComponentContextService } from './InventoryTransformations.inventory_transformation_types_dd.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './InventoryTransformations.frontend.types'
import { $frontendTypes as $types} from './InventoryTransformations.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'InventoryTransformations-inventory_transformation_types_dd_multi',
  templateUrl: './InventoryTransformations.inventory_transformation_types_dd_multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InventoryTransformations_inventory_transformation_types_dd_multiComponent),
      multi: true
    }
  ]
})
export class InventoryTransformations_inventory_transformation_types_dd_multiComponent extends BaseComponent implements OnInit, ControlValueAccessor {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;
  @Input() tooltip = '';

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;

  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return true;
  }

  get isSingle() {
    return false;
  }

  options: Array<{ key: any; name: string; disabled?: boolean; }> = [
    {
      key: 1,
      name: 'One-to-many',
      disabled: null
    },  
    {
      key: 2,
      name: 'Many-to-one',
      disabled: null
    },  
    {
      key: 3,
      name: 'Many-to-many',
      disabled: null
    },  
  ];


  fullTextSearch: string;

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: InventoryTransformations_ShellService,
    private $datasources: InventoryTransformations_DatasourceService,
    private $flows: InventoryTransformations_FlowService,
    private $reports: InventoryTransformations_ReportService,
    private $localization: InventoryTransformations_LocalizationService,
    private $operations: InventoryTransformations_OperationService,
    private $logger: CleanupLoggerService,
    private $context: InventoryTransformations_inventory_transformation_types_dd_ComponentContextService,
    private _elementRef: ElementRef
    ) {
      super();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): number | number[] {
    return this._innerValue;
  }

  set value(val:  number | number[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  displayWithFn(value: any): Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}> {
    const selected = this.options.filter(o => value.includes(o.key));
    const displayText = selected.map(o => o.name).join(
           ', '
        );
    this.displayTextChange.emit(displayText);
    return Promise.resolve({displayText, selected});
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {
    if (filterText.length > 0) {
      const list = this.options.filter(item => item.name.startsWith(filterText))
      return Promise.resolve({ list, totalCount: this.options.length, top: null });
    } else {
      const list = [...this.options];
      return Promise.resolve({ list, totalCount: this.options.length, top: null });
    }
  }

}
