import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_orders_manufacturing_production_grid_ComponentContextService } from './FootprintManager.manufacturing_orders_manufacturing_production_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponent } from './FootprintManager.manufacturing_orders_manufacturing_production_components_grid.component';

interface IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentEntity {
  id?: number, actualPackagedAmount?: number, finishedGood?: string, lot?: string, licensePlate?: string, completedOn?: string, lotExpiration?: string, workshift?: string, packaging?: string, taskChainHead?: number, warehouseId?: number, manufacturingOrderLineId?: number, projectId?: number, stagingLocation?: number}

interface IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentInParams {
  manufacturing_order_id?: number}


class FootprintManager_manufacturing_orders_manufacturing_production_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_orders_manufacturing_production_gridComponent;
  entity: IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    completed_on_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot_expiration_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    finished_good: null,
    produced: null,
    lot: null,
    license_plate: null,
    completed_on: null,
    lot_expiration: null,
    workshift: null,
  }


  //#region rowExpand inParams


  get $rowExpand_FootprintManager_manufacturing_orders_manufacturing_production_components_grid_inParams_manufacturing_order_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.manufacturing_order_id;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_orders_manufacturing_production_components_grid_inParams_manufacturing_order_line_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.manufacturingOrderLineId;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_orders_manufacturing_production_components_grid_inParams_warehouse_id(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.warehouseId;
    
    return expr;
  }


  get $rowExpand_FootprintManager_manufacturing_orders_manufacturing_production_components_grid_inParams_task_chain_head(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $row.entity.taskChainHead;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    grid: FootprintManager_manufacturing_orders_manufacturing_production_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_orders_manufacturing_production_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.finished_good = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.finished_good,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.produced = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.produced,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.lot = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.license_plate = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.license_plate,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.completed_on = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.completed_on,
      new DateBoxModel(this.formGroup.controls['completed_on_display'] as DatexFormControl, null, true, false, 'l, LT', 'date', null)
,
null
      );
    
    this.cells.lot_expiration = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.lot_expiration,
      new DateBoxModel(this.formGroup.controls['lot_expiration_display'] as DatexFormControl, null, true, false, '', 'date', null)
,
null
      );
    
    this.cells.workshift = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.workshift,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      manufacturing_order_id:  $grid.inParams.manufacturing_order_id ,
    };
    const data = await this.$datasources.Manufacturing.ds_manufacturing_order_production_confirmation_data_extended.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.finished_good.displayControl as TextModel).text = $row.entity.finishedGood;
    (this.cells.produced.displayControl as TextModel).text = `${$row.entity.actualPackagedAmount.toString()} ${$row.entity.packaging}`;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.lot;
    (this.cells.license_plate.displayControl as TextModel).text = $row.entity.licensePlate;
    (this.cells.completed_on.displayControl as DateBoxModel).reset($row.entity.completedOn);
    (this.cells.lot_expiration.displayControl as DateBoxModel).reset($row.entity.lotExpiration);
    (this.cells.workshift.displayControl as TextModel).text = $row.entity.workshift;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_manufacturing_orders_manufacturing_production_components_gridComponent)
  ],
  selector: 'FootprintManager-manufacturing_orders_manufacturing_production_grid',
  templateUrl: './FootprintManager.manufacturing_orders_manufacturing_production_grid.component.html'
})
export class FootprintManager_manufacturing_orders_manufacturing_production_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentInParams = { manufacturing_order_id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     finished_good: new GridHeaderModel(new HeaderStyles(null, null), 'finished_good', 'Finished good', false, false, null, false),       produced: new GridHeaderModel(new HeaderStyles(null, null), 'produced', 'Produced quantity', false, false, null, false),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'lot', 'Lot', false, false, null, false),       license_plate: new GridHeaderModel(new HeaderStyles(null, null), 'license_plate', 'License plate', false, false, null, false),       completed_on: new GridHeaderModel(new HeaderStyles(null, null), 'completed_on', 'Completed on', false, false, null, false),       lot_expiration: new GridHeaderModel(new HeaderStyles(null, null), 'lot_expiration', 'Lot expiration', false, false, null, false),       workshift: new GridHeaderModel(new HeaderStyles(null, null), 'workshift', 'Workshift', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_orders_manufacturing_production_gridComponentRowModel[] = [];

  @Input('manufacturing_order_id') set $inParams_manufacturing_order_id(value: any) {
    this.inParams['manufacturing_order_id'] = value;
  }
  get $inParams_manufacturing_order_id(): any {
    return this.inParams['manufacturing_order_id'] ;
  }

  topToolbar = {
      return_to_stock: new ToolModel(new ButtonModel('return_to_stock', new ButtonStyles(null, null), true, false, false, 'Return to stock', 'icon-datex-Returns', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      undo_confirmation: new ToolModel(new ButtonModel('undo_confirmation', new ButtonStyles(null, null), true, false, false, 'Undo confirmation', 'icon-ic_fluent_arrow_undo_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_orders_manufacturing_production_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Manufacturing production';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 20;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      manufacturing_order_id:  $grid.inParams.manufacturing_order_id ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_manufacturing_order_production_confirmation_data_extended.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_orders_manufacturing_production_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_orders_manufacturing_production_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_manufacturing_orders_manufacturing_production_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_return_to_stock_clicked(event = null) {
    return this.on_return_to_stock_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_return_to_stock_clickedInternal(
    $grid: FootprintManager_manufacturing_orders_manufacturing_production_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_orders_manufacturing_production_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_orders_manufacturing_production_grid.on_return_to_stock_clicked');
  if( $grid.selectedRows.length != 1 )
      return;
  
  const selectedRow = $grid.selectedRows[0];
  
  await $shell.FootprintManager.openmanufacturing_order_return_to_stock_editorDialog({manufacturing_order_id: selectedRow.entity.id
                                                                                    , manufacturing_order_line_id: selectedRow.entity.manufacturingOrderLineId
                                                                                    , finished_good: selectedRow.entity.finishedGood
                                                                                    , warehouse_id: selectedRow.entity.warehouseId
                                                                                    , project_id: selectedRow.entity.projectId
                                                                                    , task_chain_head: selectedRow.entity.taskChainHead
                                                                                    , location_id: selectedRow.entity.stagingLocation}, "modal", EModalSize.Large);
  }
  on_undo_production_clicked(event = null) {
    return this.on_undo_production_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_undo_production_clickedInternal(
    $grid: FootprintManager_manufacturing_orders_manufacturing_production_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_orders_manufacturing_production_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_orders_manufacturing_production_grid.on_undo_production_clicked');
  if( $grid.selectedRows.length != 1 )
      return;
  
  const selectedRow = $grid.selectedRows[0];
  
  await $shell.FootprintManager.openmanufacturing_order_production_confirmation_undo_wizardDialog({task_chain_head: selectedRow.entity.taskChainHead
                                                                                                 , warehouse_id: selectedRow.entity.warehouseId
                                                                                                 , manufacturing_order_id: selectedRow.entity.id
                                                                                                 , manufacturing_order_line_id: selectedRow.entity.manufacturingOrderLineId}, "modal", EModalSize.Large);
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_manufacturing_orders_manufacturing_production_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_orders_manufacturing_production_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_orders_manufacturing_production_grid.on_row_selected');
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount == 1) {
      $grid.topToolbar.return_to_stock.control.readOnly = false;
      $grid.topToolbar.undo_confirmation.control.readOnly = false;
  }
  else {
      $grid.topToolbar.return_to_stock.control.readOnly = true;
      $grid.topToolbar.undo_confirmation.control.readOnly = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
