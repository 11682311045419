import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformations_target_material_form_ComponentContextService } from './FootprintManager.inventory_transformations_target_material_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Lots_lots_dd_singleComponent } from './Lots.lots_dd_single.component'
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component'
import { Inventory_measurement_units_dd_singleComponent } from './Inventory.measurement_units_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Lots_lots_dd_singleComponent),
    forwardRef(() => Lots_vendorlots_dd_singleComponent),
    forwardRef(() => Inventory_measurement_units_dd_singleComponent),
  ],
  selector: 'FootprintManager-inventory_transformations_target_material_form',
  templateUrl: './FootprintManager.inventory_transformations_target_material_form.component.html'
})
export class FootprintManager_inventory_transformations_target_material_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { project_id: number, warehouse_id: number, inventory_transformation_id: number, source_line_id?: number, type?: number, additional_options?: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_udfs: boolean, copy_attachments: boolean, copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean, copy_status: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } } } = { project_id: null, warehouse_id: null, inventory_transformation_id: null, source_line_id: null, type: null, additional_options: { general: { keep_source_creation_date: null, convert_packaging_to_base: null }, serial_number_options: { copy_udfs: null, copy_attachments: null, copy_weights: null, copy_volume: null, copy_dimensions: null, copy_status: null }, lot_number_options: { copy_udfs: null, copy_lot_number: null, copy_vendor_lot_number: null } } };
  //#region Inputs
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(v: number) {
    this.inParams.inventory_transformation_id = v;
  }
  get $inParams_inventory_transformation_id(): number {
    return this.inParams.inventory_transformation_id;
  }
  @Input('source_line_id') set $inParams_source_line_id(v: number) {
    this.inParams.source_line_id = v;
  }
  get $inParams_source_line_id(): number {
    return this.inParams.source_line_id;
  }
  @Input('type') set $inParams_type(v: number) {
    this.inParams.type = v;
  }
  get $inParams_type(): number {
    return this.inParams.type;
  }
  @Input('additional_options') set $inParams_additional_options(v: { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_udfs: boolean, copy_attachments: boolean, copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean, copy_status: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } }) {
    this.inParams.additional_options = v;
  }
  get $inParams_additional_options(): { general: { keep_source_creation_date: boolean, convert_packaging_to_base: boolean }, serial_number_options: { copy_udfs: boolean, copy_attachments: boolean, copy_weights: boolean, copy_volume: boolean, copy_dimensions: boolean, copy_status: boolean }, lot_number_options: { copy_udfs: boolean, copy_lot_number: boolean, copy_vendor_lot_number: boolean } } {
    return this.inParams.additional_options;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { inventory_to_transform?: { target_material_id: number, packaging_id: number, lot_id: number, vendor_lot_id: number, weight: number, weight_uom: number, new_quantity: number } } = { inventory_to_transform: { target_material_id: null, packaging_id: null, lot_id: null, vendor_lot_id: null, weight: null, weight_uom: null, new_quantity: null } };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { additional_details?: any } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ inventory_to_transform?: { target_material_id: number, packaging_id: number, lot_id: number, vendor_lot_id: number, weight: number, weight_uom: number, new_quantity: number } }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    target_material: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    convert_quantity: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    conversion_rate: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    target_material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['target_material'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material code', true, false),
    packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['packaging'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Packaging', true, false),
    lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['lot'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Lot', false, false),
    vendor_lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['vendor_lot'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Vendor lot', false, false),
    weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['weight'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Weight', false, false),
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Weight UOM', false, false),
    quantity_to_transform: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Quantity to transform', false, false),
    convert_quantity: new FieldModel(new CheckBoxModel(this.formGroup.controls['convert_quantity'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Convert quantity', false, false),
    conversion_rate: new FieldModel(new NumberBoxModel(this.formGroup.controls['conversion_rate'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Conversion rate', false, false),
    new_quantity: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'New quantity', false, false),
  };

  fieldsets = {
    newGroup1: new FieldsetModel(
      'Label of newGroup1',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    quantity_to_transform: new FieldsetModel(
      'Quantity conversion multiples the existing quantity to the selected rate.',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_target_material_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.project_id;
    
    return expr;
  }

  get $fields_packaging_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.target_material.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.target_material.control.value;
    
    return expr;
  }

  get $fields_lot_selector_inParams_vendorLotId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.vendor_lot.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.target_material.control.value;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_lotId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.fields.lot.control.value;
    
    return expr;
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 3;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    target_material: this.fields.target_material.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    lot: this.fields.lot.control.valueChanges
    ,
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    ,
    weight: this.fields.weight.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
    convert_quantity: this.fields.convert_quantity.control.valueChanges
    ,
    conversion_rate: this.fields.conversion_rate.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
      if(isNil(this.inParams.inventory_transformation_id)) {
        this.$missingRequiredInParams.push('inventory_transformation_id');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Add inventory to transform';
  
    const $form = this;
    const $utils = this.$utils;

    (this.fields.convert_quantity.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .target_material
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_changed();
      });
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendor_lot_changed();
      });
    this.$formGroupFieldValidationObservables
      .weight
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .convert_quantity
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_convert_quantity_clicked();
      });
    this.$formGroupFieldValidationObservables
      .conversion_rate
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_conversion_rate_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  map_outputs(event = null) {
    return this.map_outputsInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async map_outputsInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.map_outputs');
  
  
  $form.outParams.inventory_to_transform.lot_id = $form.fields.lot.control.value
  $form.outParams.inventory_to_transform.new_quantity =  Number($form.fields.new_quantity.control.text ?? $form.fields.quantity_to_transform.control.text)
  $form.outParams.inventory_to_transform.packaging_id = $form.fields.packaging.control.value
  $form.outParams.inventory_to_transform.target_material_id = $form.fields.target_material.control.value
  $form.outParams.inventory_to_transform.vendor_lot_id = $form.fields.vendor_lot.control.value
  $form.outParams.inventory_to_transform.weight = $form.fields.weight.control.value
  $form.outParams.inventory_to_transform.weight_uom = $form.fields.weight_uom.control.value
  
  $form.events.outParamsChange.emit();
  }
  on_convert_quantity_clicked(event = null) {
    return this.on_convert_quantity_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_convert_quantity_clickedInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_convert_quantity_clicked');
  if ($form.fields.convert_quantity.control.value)
  {
      $form.fieldsets.quantity_to_transform.hidden = false
      
  }
  else
  {
      $form.fieldsets.quantity_to_transform.hidden = true
  }
  }
  on_conversion_rate_changed(event = null) {
    return this.on_conversion_rate_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_conversion_rate_changedInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_conversion_rate_changed');
  $utils.isDefined($form.fields.conversion_rate.control.value)
  {
      $form.fields.new_quantity.control.text =  (Number($form.fields.quantity_to_transform.control.text??0)*$form.fields.conversion_rate.control.value).toString()
  }
  
  await $form.map_outputs()
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_material_changed');
  
  
  //clear lot and vendor lot
  $form.fields.lot.control.value = null
  $form.fields.vendor_lot.control.value = null
  $form.fields.packaging.control.value = null
  
  
  try {
  
      if ($utils.isDefined($form.fields.target_material.control.value)) {
  
          //copy vendor lot only
          if (!$form.inParams.additional_options.lot_number_options.copy_lot_number && $form.inParams.additional_options.lot_number_options.copy_vendor_lot_number) {
              let copy_vendor_lot = (await $shell.InventoryTransformations.openConfirmationDialog('Copy vendor lot from the source material to this target material?', '', 'Confirm', 'Discard'))
  
              if (!copy_vendor_lot) { return }
  
              let source_line = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_lines_grid.get(
                  {
                      inventory_transformation_id: $form.inParams.inventory_transformation_id,
                      line_number: $form.inParams.source_line_id
                  }))?.result[0]
  
              let new_vendor_lot = (await $flows.Lots.create_vendor_lot(
                  {
                      materialId: $form.fields.target_material.control.value,
                      lookupCode: source_line?.Lot?.VendorLot?.LookupCode,
                      manufactureDate: source_line?.Lot?.VendorLot?.ManufactureDate,
                      expirationDate: source_line?.Lot?.VendorLot?.ExpirationDate
                  }))
  
              if ($utils.isDefined(new_vendor_lot)) {
                  const title = 'Copy vendor lot';
                  const message = `Vendor lot ${source_line?.Lot?.VendorLot?.LookupCode} copied to Material Id: ${$form.fields.target_material.control.value}.`;
                  $shell.InventoryTransformations.openToaster(
                      title,
                      message,
                      EToasterType.Success,
                      {
                          positionClass: EToasterPosition.topRight,
                          tapToDismiss: true,
                          timeOut: 10000,
                          newestOnTop: false
                      });
                  $form.fields.vendor_lot.control.value = new_vendor_lot?.vendorLotId
              }
  
          }
  
          //copy lot and vendor lot
          if ($form.inParams.additional_options.lot_number_options.copy_lot_number && $form.inParams.additional_options.lot_number_options.copy_vendor_lot_number) {
              let copy_lot_and_vendor_lot = (await $shell.InventoryTransformations.openConfirmationDialog('Copy lot and vendor lot from the source material to this target material?', '', 'Confirm', 'Discard'))
  
              if (!copy_lot_and_vendor_lot) { return }
  
              let source_line = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_lines_grid.get(
                  {
                      inventory_transformation_id: $form.inParams.inventory_transformation_id,
                      line_number: $form.inParams.source_line_id
                  }))?.result[0]
  
  
              let new_lot_and_vendor_lot = (await $flows.Lots.create_lot_and_vendor_lot(
                  {
                      materialId: $form.fields.target_material.control.value,
                      lotLookupCode: source_line?.Lot?.LookupCode,
                      vendorLotLookupCode: source_line?.Lot?.VendorLot?.LookupCode,
                      lotStatusId: source_line?.Lot?.StatusId,
                      manufactureDate: source_line?.Lot?.VendorLot?.ManufactureDate,
                      expirationDate: source_line?.Lot?.VendorLot?.ExpirationDate
                  }))
  
              if ($utils.isDefined(new_lot_and_vendor_lot)) {
                  const title = 'Copy lot and vendor lot';
                  const message = `Vendor lot ${source_line?.Lot?.VendorLot?.LookupCode} and lot ${source_line?.Lot?.LookupCode} copied to Material Id: ${$form.fields.target_material.control.value}.`;
                  $shell.InventoryTransformations.openToaster(
                      title,
                      message,
                      EToasterType.Success,
                      {
                          positionClass: EToasterPosition.topRight,
                          tapToDismiss: true,
                          timeOut: 10000,
                          newestOnTop: false
                      });
                  $form.fields.vendor_lot.control.value = new_lot_and_vendor_lot?.VendorLotId
                  $form.fields.lot.control.value = new_lot_and_vendor_lot?.LotId
              }
  
          }
  
  
  
      }
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  
  await $form.map_outputs()
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_init');
  
  $form.fieldsets.quantity_to_transform.hidden = true
  
  if ($form.inParams.additional_options.lot_number_options.copy_lot_number && !$form.inParams.additional_options.lot_number_options.copy_vendor_lot_number)
  {
      $form.fields.lot.control.readOnly = true
  }
  
  
  //check source lines for selected input
  let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get(
  {
      ids: [$form.inParams.source_line_id],
      inventory_transformation_id: $form.inParams.inventory_transformation_id,
  }
  ))?.result
  
  if ($utils.isDefined(source_line_check))
  
  {
  
      $form.fields.quantity_to_transform.control.text = source_line_check[0]?.SourcePackageAmount.toString()
  }
  }
  on_vendor_lot_changed(event = null) {
    return this.on_vendor_lot_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendor_lot_changedInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_vendor_lot_changed');
  
  
  
  //copy lot only
  if ($form.inParams.additional_options.lot_number_options.copy_lot_number && !$form.inParams.additional_options.lot_number_options.copy_vendor_lot_number) {
      let copy_lot = (await $shell.InventoryTransformations.openConfirmationDialog('Copy lot from the source material to this target material?', '', 'Confirm', 'Discard'))
  
      if (copy_lot) {
          let source_line = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_lines_grid.get(
              {
                  inventory_transformation_id: $form.inParams.inventory_transformation_id,
                  line_number: $form.inParams.source_line_id
              }))?.result[0]
  
          try {
              let new_lot = (await $flows.Lots.create_lot(
                  {
                      vendorLotId: $form.fields.vendor_lot.control.value,
                      lookupCode: source_line?.Lot?.VendorLot?.LookupCode,
                      statusId: source_line?.Lot?.StatusId
                  }))
  
              if ($utils.isDefined(new_lot)) {
                  const title = 'Copy lot';
                  const message = `Lot ${source_line?.Lot?.VendorLot?.LookupCode} copied to Material Id: ${$form.fields.target_material.control.value}.`;
                  $shell.InventoryTransformations.openToaster(
                      title,
                      message,
                      EToasterType.Success,
                      {
                          positionClass: EToasterPosition.topRight,
                          tapToDismiss: true,
                          timeOut: 10000,
                          newestOnTop: false
                      });
                  $form.fields.lot.control.value = new_lot?.lotId
                  $form.fields.lot.control.readOnly = false
  
              }
  
  
          } catch (error) {
              $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
              throw error; // to prevent displayMode 
          }
  
  
  
      }
  
  }
  
  
  
  await $form.map_outputs()
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_save');
  await $form.map_outputs()
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_packaging_changed');
  
  
  //check if target material is serialized or not
  
  if ($utils.isAllDefined($form.fields.target_material, $form.fields.packaging)) {
  
      let material_check = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $form.fields.target_material.control.value }))?.result
      if ($utils.isDefined(material_check)) {
          if (material_check[0]?.IsFixedWeight) {
              let is_fixed_weight = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
                  materialId: $form.fields.target_material.control.value,
                  packagingId: $form.fields.packaging.control.value,
              }))?.result
  
              $form.fields.weight.control.readOnly = true
              $form.fields.weight_uom.control.readOnly = true
              $form.fields.weight.control.value = is_fixed_weight[0].ShippingWeight
              $form.fields.weight_uom.control.value = is_fixed_weight[0].WeightUomId            
  
          }
  
      }
  
  }
  
  await $form.map_outputs()
  
  }
  on_lot_changed(event = null) {
    return this.on_lot_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changedInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_lot_changed');
  $form.fields.vendor_lot.control.value = null
  await $form.map_outputs();
  }
  //#endregion private flows
  //#region validation flows
  
  on_form_validation(fieldErrors: { target_material: Pick<string[], 'push'>,packaging: Pick<string[], 'push'>,lot: Pick<string[], 'push'>,vendor_lot: Pick<string[], 'push'>,weight: Pick<string[], 'push'>,weight_uom: Pick<string[], 'push'>,convert_quantity: Pick<string[], 'push'>,conversion_rate: Pick<string[], 'push'>, } = null) {
    const emptyResult = { target_material:[],packaging:[],lot:[],vendor_lot:[],weight:[],weight_uom:[],convert_quantity:[],conversion_rate:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.on_form_validationInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async on_form_validationInternal(
    $form: FootprintManager_inventory_transformations_target_material_formComponent,
    $validation:{
      fieldErrors: { target_material: Pick<string[], 'push'>,packaging: Pick<string[], 'push'>,lot: Pick<string[], 'push'>,vendor_lot: Pick<string[], 'push'>,weight: Pick<string[], 'push'>,weight_uom: Pick<string[], 'push'>,convert_quantity: Pick<string[], 'push'>,conversion_rate: Pick<string[], 'push'>, }
    },
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $context: FootprintManager_inventory_transformations_target_material_form_ComponentContextService
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformations_target_material_form.on_form_validation');
    for (let key in $form.fields) {
      $form.fields[key].control.value = null
  }
    return $validation.fieldErrors as { target_material:[],packaging:[],lot:[],vendor_lot:[],weight:[],weight_uom:[],convert_quantity:[],conversion_rate:[], };
  }
  //#endregion validation flows
}
