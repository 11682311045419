<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="process" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_process_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator2" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="delete_transfer" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_delete_transfer_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="attachments" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_attachments_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="print" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_print_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden && !fieldsets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden && !fields.lookupcode.removed" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}} {{fields.lookupcode.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.lookupcode.label + (fields.lookupcode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.lookupcode.control.readOnly || fields.lookupcode.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"
                                [matTooltip]="fields.lookupcode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden && !fields.status.removed" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}} {{fields.status.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.status.label + (fields.status.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.status.label}}<span *ngIf="fields.status.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.status.control.styles.style"
                              [ngClass]="fields.status.control.styles.classes"
                              [matTooltip]="fields.status.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.status.control.text }}</div>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden && !fields.owner.removed" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}} {{fields.owner.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.owner.label + (fields.owner.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.owner.label}}<span *ngIf="fields.owner.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                            [tooltip]="fields.owner.control.tooltip"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden && !fields.project.removed" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}} {{fields.project.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.project.label + (fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.project.label}}<span *ngIf="fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                            [tooltip]="fields.project.control.tooltip"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_warehouse" *ngIf="!fields.source_warehouse.hidden && !fields.source_warehouse.removed" 
                            class="field-container standard {{fields.source_warehouse.invalid ? 'invalid' : ''}} {{fields.source_warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.source_warehouse.styles.style"
                            [ngClass]="fields.source_warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.source_warehouse.label + (fields.source_warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.source_warehouse.label}}<span *ngIf="fields.source_warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.source_warehouse.control.type"
                            formControlName="source_warehouse"
                            (displayTextChange)="fields.source_warehouse.control.displayText=$event"
                            [placeholder]="fields.source_warehouse.control.placeholder"
                            [styles]="fields.source_warehouse.control.styles"
                            [tooltip]="fields.source_warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.source_warehouse.invalid">
                          <ng-container *ngFor="let error of fields.source_warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-target_warehouse" *ngIf="!fields.target_warehouse.hidden && !fields.target_warehouse.removed" 
                            class="field-container standard {{fields.target_warehouse.invalid ? 'invalid' : ''}} {{fields.target_warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.target_warehouse.styles.style"
                            [ngClass]="fields.target_warehouse.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.target_warehouse.label + (fields.target_warehouse.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.target_warehouse.label}}<span *ngIf="fields.target_warehouse.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.target_warehouse.control.type"
                            formControlName="target_warehouse"
                            (displayTextChange)="fields.target_warehouse.control.displayText=$event"
                            [placeholder]="fields.target_warehouse.control.placeholder"
                            [styles]="fields.target_warehouse.control.styles"
                            [tooltip]="fields.target_warehouse.control.tooltip"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.target_warehouse.invalid">
                          <ng-container *ngFor="let error of fields.target_warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-outbound_order" *ngIf="!fields.outbound_order.hidden && !fields.outbound_order.removed" 
                            class="field-container standard {{fields.outbound_order.invalid ? 'invalid' : ''}} {{fields.outbound_order.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.outbound_order.styles.style"
                            [ngClass]="fields.outbound_order.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.outbound_order.label + (fields.outbound_order.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.outbound_order.label}}<span *ngIf="fields.outbound_order.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.outbound_order.control.readOnly || fields.outbound_order.control.disabled"
                                [ngStyle]="fields.outbound_order.control.styles.style"
                                [ngClass]="fields.outbound_order.control.styles.classes"
                                [matTooltip]="fields.outbound_order.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_outbound_order_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.outbound_order.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.outbound_order.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.outbound_order.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.outbound_order.invalid">
                          <ng-container *ngFor="let error of fields.outbound_order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inbound_order" *ngIf="!fields.inbound_order.hidden && !fields.inbound_order.removed" 
                            class="field-container standard {{fields.inbound_order.invalid ? 'invalid' : ''}} {{fields.inbound_order.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.inbound_order.styles.style"
                            [ngClass]="fields.inbound_order.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.inbound_order.label + (fields.inbound_order.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.inbound_order.label}}<span *ngIf="fields.inbound_order.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.inbound_order.control.readOnly || fields.inbound_order.control.disabled"
                                [ngStyle]="fields.inbound_order.control.styles.style"
                                [ngClass]="fields.inbound_order.control.styles.classes"
                                [matTooltip]="fields.inbound_order.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_inbound_order_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.inbound_order.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.inbound_order.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.inbound_order.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.inbound_order.invalid">
                          <ng-container *ngFor="let error of fields.inbound_order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.newGroup1.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-notes"
                     *ngIf="!fieldsets.notes.hidden && !fieldsets.notes.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.notes.collapsible }">
                    <div *ngIf="!fieldsets.notes.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.notes.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.notes.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.notes.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.notes.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.notes.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.notes.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden && !fields.notes.removed" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}} {{fields.notes.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.notes.label + (fields.notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.notes.label}}<span *ngIf="fields.notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.notes.control.readOnly || fields.notes.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"
                                [matTooltip]="fields.notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.notes.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.transfer_lines.hasTabToShow" class="tab" data-cy="tab-transfer_lines">
              <h2 [className]="tabs.transfer_lines.active? 'active': ''" (click)="tabs.transfer_lines.activate()">{{tabs.transfer_lines.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-warehouse_transfer_outbound_order_lines_grid *ngIf="tabs.transfer_lines.active"
              #$tabs_transfer_lines
              [orderId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_orderId"
              [shipmentId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_shipmentId"
              [projectId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_projectId"
              [orderStatusId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_orderStatusId"
              [warehouseId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_warehouseId"
              [warehouseTransferId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_warehouseTransferId"
              ($refreshEvent)="refresh(false, false, '$tabs_transfer_lines')">
              </FootprintManager-warehouse_transfer_outbound_order_lines_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>