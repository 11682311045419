<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="resubmit_message" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_resubmit_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="clean_message" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_clean_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="delete_message" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_delete_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-status" *ngIf="!filters.status.hidden && !filters.status.removed" 
            class="field-container standard {{filters.status.invalid ? 'invalid' : ''}} {{filters.status.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.status.styles.style"
            [ngClass]="filters.status.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.status.label + (filters.status.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.status.label}}<span *ngIf="filters.status.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Utilities-statuses_dd_single 
            data-cy="selector"
            [type]="filters.status.control.type"
            formControlName="status"
            (displayTextChange)="filters.status.control.displayText=$event"
            [placeholder]="filters.status.control.placeholder"
            [styles]="filters.status.control.styles"
            [tooltip]="filters.status.control.tooltip"
          [application_name]="$fields_status_selector_inParams_application_name"
        >
        </Utilities-statuses_dd_single>
        <ng-container *ngIf="filters.status.invalid">
          <ng-container *ngFor="let error of filters.status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-direction" *ngIf="!filters.direction.hidden && !filters.direction.removed" 
            class="field-container standard {{filters.direction.invalid ? 'invalid' : ''}} {{filters.direction.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.direction.styles.style"
            [ngClass]="filters.direction.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.direction.label + (filters.direction.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.direction.label}}<span *ngIf="filters.direction.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Utilities-directions_dd_single 
            data-cy="selector"
            [type]="filters.direction.control.type"
            formControlName="direction"
            (displayTextChange)="filters.direction.control.displayText=$event"
            [placeholder]="filters.direction.control.placeholder"
            [styles]="filters.direction.control.styles"
            [tooltip]="filters.direction.control.tooltip"
        >
        </Utilities-directions_dd_single>
        <ng-container *ngIf="filters.direction.invalid">
          <ng-container *ngFor="let error of filters.direction.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-transaction_type" *ngIf="!filters.transaction_type.hidden && !filters.transaction_type.removed" 
            class="field-container standard {{filters.transaction_type.invalid ? 'invalid' : ''}} {{filters.transaction_type.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.transaction_type.styles.style"
            [ngClass]="filters.transaction_type.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.transaction_type.label + (filters.transaction_type.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.transaction_type.label}}<span *ngIf="filters.transaction_type.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Utilities-transaction_types_dd_single 
            data-cy="selector"
            [type]="filters.transaction_type.control.type"
            formControlName="transaction_type"
            (displayTextChange)="filters.transaction_type.control.displayText=$event"
            [placeholder]="filters.transaction_type.control.placeholder"
            [styles]="filters.transaction_type.control.styles"
            [tooltip]="filters.transaction_type.control.tooltip"
          [application_name]="$fields_transaction_type_selector_inParams_application_name"
        >
        </Utilities-transaction_types_dd_single>
        <ng-container *ngIf="filters.transaction_type.invalid">
          <ng-container *ngFor="let error of filters.transaction_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-process" *ngIf="!filters.process.hidden && !filters.process.removed" 
            class="field-container standard {{filters.process.invalid ? 'invalid' : ''}} {{filters.process.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.process.styles.style"
            [ngClass]="filters.process.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.process.label + (filters.process.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.process.label}}<span *ngIf="filters.process.required" class="required-asterisk">*</span>
          </label>
        </div>
        <Utilities-processes_dd_single 
            data-cy="selector"
            [type]="filters.process.control.type"
            formControlName="process"
            (displayTextChange)="filters.process.control.displayText=$event"
            [placeholder]="filters.process.control.placeholder"
            [styles]="filters.process.control.styles"
            [tooltip]="filters.process.control.tooltip"
          [application_name]="$fields_process_selector_inParams_application_name"
        >
        </Utilities-processes_dd_single>
        <ng-container *ngIf="filters.process.invalid">
          <ng-container *ngFor="let error of filters.process.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden && !filters.date_from.removed" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}} {{filters.date_from.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_from.label + (filters.date_from.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_from.label}}<span *ngIf="filters.date_from.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes"
            [tooltip]="filters.date_from.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden && !filters.date_to.removed" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}} {{filters.date_to.control.disabled ? 'field-container-disabled' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
             [matTooltip]="filters.date_to.label + (filters.date_to.required ? ' (required)' : '')"
             matTooltipClass="datex-control-tooltip">
          <label data-cy="field-label" class="datex-label">{{filters.date_to.label}}<span *ngIf="filters.date_to.required" class="required-asterisk">*</span>
          </label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes"
            [tooltip]="filters.date_to.control.tooltip">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="application_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.application_name.displayControl.styles.style"
          [ngClass]="row.cells.application_name.displayControl.styles.classes"
          [matTooltip]="row.cells.application_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.application_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="process_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.process_name.displayControl.styles.style"
          [ngClass]="row.cells.process_name.displayControl.styles.classes"
          [matTooltip]="row.cells.process_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.process_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="transaction_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.transaction_type.displayControl.styles.style"
          [ngClass]="row.cells.transaction_type.displayControl.styles.classes"
          [matTooltip]="row.cells.transaction_type.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.transaction_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="transaction_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.transaction_id.displayControl.styles.style"
          [ngClass]="row.cells.transaction_id.displayControl.styles.classes"
          [matTooltip]="row.cells.transaction_id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.transaction_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.status_name.displayControl.styles.style"
          [ngClass]="row.cells.status_name.displayControl.styles.classes"
          [matTooltip]="row.cells.status_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.status_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="environment">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.environment.displayControl.styles.style"
          [ngClass]="row.cells.environment.displayControl.styles.classes"
          [matTooltip]="row.cells.environment.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.environment.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="direction">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.direction.displayControl.styles.style"
          [ngClass]="row.cells.direction.displayControl.styles.classes"
          [matTooltip]="row.cells.direction.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.direction.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.reference.displayControl.styles.style"
          [ngClass]="row.cells.reference.displayControl.styles.classes"
          [matTooltip]="row.cells.reference.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="payload">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.payload.displayControl.styles.style"
          [ngClass]="row.cells.payload.displayControl.styles.classes"
          [matTooltip]="row.cells.payload.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.payload.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="message">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.message.displayControl.styles.style"
          [ngClass]="row.cells.message.displayControl.styles.classes"
          [matTooltip]="row.cells.message.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.message.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_by.displayControl.styles.style"
          [ngClass]="row.cells.created_by.displayControl.styles.classes"
          [matTooltip]="row.cells.created_by.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes"
          [matTooltip]="row.cells.created_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_by.displayControl.styles.style"
          [ngClass]="row.cells.modified_by.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_by.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.modified_on.displayControl.styles.style"
          [ngClass]="row.cells.modified_on.displayControl.styles.classes"
          [matTooltip]="row.cells.modified_on.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.modified_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="number_1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.number_1.displayControl.styles.style"
          [ngClass]="row.cells.number_1.displayControl.styles.classes"
          [matTooltip]="row.cells.number_1.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.number_1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="number_2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.number_2.displayControl.styles.style"
          [ngClass]="row.cells.number_2.displayControl.styles.classes"
          [matTooltip]="row.cells.number_2.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.number_2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="number_3">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.number_3.displayControl.styles.style"
          [ngClass]="row.cells.number_3.displayControl.styles.classes"
          [matTooltip]="row.cells.number_3.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.number_3.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="number_4">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.number_4.displayControl.styles.style"
          [ngClass]="row.cells.number_4.displayControl.styles.classes"
          [matTooltip]="row.cells.number_4.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.number_4.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="number_5">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.number_5.displayControl.styles.style"
          [ngClass]="row.cells.number_5.displayControl.styles.classes"
          [matTooltip]="row.cells.number_5.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.number_5.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="string_1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.string_1.displayControl.styles.style"
          [ngClass]="row.cells.string_1.displayControl.styles.classes"
          [matTooltip]="row.cells.string_1.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.string_1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="string_2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.string_2.displayControl.styles.style"
          [ngClass]="row.cells.string_2.displayControl.styles.classes"
          [matTooltip]="row.cells.string_2.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.string_2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="string_3">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.string_3.displayControl.styles.style"
          [ngClass]="row.cells.string_3.displayControl.styles.classes"
          [matTooltip]="row.cells.string_3.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.string_3.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="string_4">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.string_4.displayControl.styles.style"
          [ngClass]="row.cells.string_4.displayControl.styles.classes"
          [matTooltip]="row.cells.string_4.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.string_4.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="string_5">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.string_5.displayControl.styles.style"
          [ngClass]="row.cells.string_5.displayControl.styles.classes"
          [matTooltip]="row.cells.string_5.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.string_5.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="json_test">

    <ng-template gridCellDisplayControlDef let-row>
    <ngx-codemirror
                [formControl]="row.formGroup.controls['json_test_display']"
                [options]="{
                theme: 'base16-light',
                mode: row.cells.json_test.displayControl.codeMirrorMode,
                lineNumbers: true,
                lineWrapping: true,
                foldGutter: true,
                gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                autoCloseBrackets: true,
                matchBrackets: true,
                lint: true
                }"
                [CodeValidator]="row.cells.json_test.displayControl.mode"
                [matTooltip]="row.cells.json_test.displayControl.tooltip"
                matTooltipClass="datex-control-tooltip">
    </ngx-codemirror>
    
    <button type="button margin-top-10"
            (click)="row.cells.json_test.displayControl.beautify();"
            class="datex-button secondary ">Beautify</button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="xml_test">

    <ng-template gridCellDisplayControlDef let-row>
    <ngx-codemirror
                [formControl]="row.formGroup.controls['xml_test_display']"
                [options]="{
                theme: 'base16-light',
                mode: row.cells.xml_test.displayControl.codeMirrorMode,
                lineNumbers: true,
                lineWrapping: true,
                foldGutter: true,
                gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                autoCloseBrackets: true,
                matchBrackets: true,
                lint: true
                }"
                [CodeValidator]="row.cells.xml_test.displayControl.mode"
                [matTooltip]="row.cells.xml_test.displayControl.tooltip"
                matTooltipClass="datex-control-tooltip">
    </ngx-codemirror>
    
    <button type="button margin-top-10"
            (click)="row.cells.xml_test.displayControl.beautify();"
            class="datex-button secondary ">Beautify</button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
