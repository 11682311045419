import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_transload_orders_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, createdFrom?: string, createdTo?: string, materialIds?: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, Name?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { BillOfLading?: string, ConveyanceId?: number, ConveyenceReference?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ExpectedWarehouse?: { Name?: string }, Conveyance?: { LookupCode?: string } } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[], TransLoadsForInboundOrder?: { Id?: number, OutboundOrderId?: number, OutboundOrder?: { Id?: number, OrderStatusId?: number, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ConveyanceId?: number, ConveyenceReference?: string, Conveyance?: { LookupCode?: string } } }[] } }[], total_received?: { TotalCount?: number, ExpectedCount?: number, OrderId?: number }, orderline_count?: { ExpectedCount?: number, OrderId?: number }, calculated_total_items?: string }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_transload_orders_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, createdFrom?: string, createdTo?: string, materialIds?: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, Name?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { BillOfLading?: string, ConveyanceId?: number, ConveyenceReference?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ExpectedWarehouse?: { Name?: string }, Conveyance?: { LookupCode?: string } } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[], TransLoadsForInboundOrder?: { Id?: number, OutboundOrderId?: number, OutboundOrder?: { Id?: number, OrderStatusId?: number, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ConveyanceId?: number, ConveyenceReference?: string, Conveyance?: { LookupCode?: string } } }[] } }[], total_received?: { TotalCount?: number, ExpectedCount?: number, OrderId?: number }, orderline_count?: { ExpectedCount?: number, OrderId?: number }, calculated_total_items?: string }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_transload_orders_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, createdFrom?: string, createdTo?: string, materialIds?: number[], $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, Notes?: string, OrderClassId?: number, OrderStatusId?: number, OwnerReference?: string, PreferredWarehouseId?: number, RequestedDeliveryDate?: string, VendorReference?: string, Status?: { Id?: number, Name?: string }, OrderClass?: { LookupCode?: string }, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, Account?: { Id?: number, Name?: string, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, PreferredWarehouse?: { Id?: number, Name?: string }, PreferredCarrier?: { Id?: number, Name?: string }, PreferredCarrierService?: { CarrierId?: number }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { BillOfLading?: string, ConveyanceId?: number, ConveyenceReference?: string, LookupCode?: string, Notes?: string, ReferenceNumber?: string, ExpectedWarehouse?: { Name?: string }, Conveyance?: { LookupCode?: string } } }[], Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[], TransLoadsForInboundOrder?: { Id?: number, OutboundOrderId?: number, OutboundOrder?: { Id?: number, OrderStatusId?: number, Status?: { Name?: string }, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ConveyanceId?: number, ConveyenceReference?: string, Conveyance?: { LookupCode?: string } } }[] } }[], total_received?: { TotalCount?: number, ExpectedCount?: number, OrderId?: number }, orderline_count?: { ExpectedCount?: number, OrderId?: number }, calculated_total_items?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_transload_orders_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], fullTextSearch?: string, createdFrom?: string, createdTo?: string, materialIds?: number[]    }) {
      return false;
    }
}
