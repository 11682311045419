import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_allocation_configurations_hub_ComponentContextService } from './FootprintManager.allocation_configurations_hub.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_allocation_configurations_gridComponent } from './FootprintManager.allocation_configurations_grid.component';
import { Allocations_hard_allocation_contexts_dd_multiComponent } from './Allocations.hard_allocation_contexts_dd_multi.component'
import { Allocations_allocation_configuration_entities_dd_singleComponent } from './Allocations.allocation_configuration_entities_dd_single.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'
import { Materials_material_groups_dd_singleComponent } from './Materials.material_groups_dd_single.component'
import { Addresses_account_types_dd_singleComponent } from './Addresses.account_types_dd_single.component'
import { Addresses_ownersaccounts_dd_singleComponent } from './Addresses.ownersaccounts_dd_single.component'
import { Allocations_order_class_types_dd_singleComponent } from './Allocations.order_class_types_dd_single.component'
import { Allocations_order_classes_dd_singleComponent } from './Allocations.order_classes_dd_single.component'
import { Materials_material_statuses_dd_multiComponent } from './Materials.material_statuses_dd_multi.component'
import { Allocations_base_strategies_dd_singleComponent } from './Allocations.base_strategies_dd_single.component'
import { Allocations_packaging_strategies_dd_singleComponent } from './Allocations.packaging_strategies_dd_single.component'
import { Allocations_full_pallets_first_dd_singleComponent } from './Allocations.full_pallets_first_dd_single.component'
import { Allocations_days_remaining_date_fields_dd_singleComponent } from './Allocations.days_remaining_date_fields_dd_single.component'
import { Allocations_replenishment_contexts_dd_singleComponent } from './Allocations.replenishment_contexts_dd_single.component'
import { Allocations_replenishment_quantities_dd_singleComponent } from './Allocations.replenishment_quantities_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_allocation_configurations_gridComponent),
    forwardRef(() => Allocations_hard_allocation_contexts_dd_multiComponent),
    forwardRef(() => Allocations_allocation_configuration_entities_dd_singleComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
    forwardRef(() => Materials_material_groups_dd_singleComponent),
    forwardRef(() => Addresses_account_types_dd_singleComponent),
    forwardRef(() => Addresses_ownersaccounts_dd_singleComponent),
    forwardRef(() => Allocations_order_class_types_dd_singleComponent),
    forwardRef(() => Allocations_order_classes_dd_singleComponent),
    forwardRef(() => Materials_material_statuses_dd_multiComponent),
    forwardRef(() => Allocations_base_strategies_dd_singleComponent),
    forwardRef(() => Allocations_packaging_strategies_dd_singleComponent),
    forwardRef(() => Allocations_full_pallets_first_dd_singleComponent),
    forwardRef(() => Allocations_days_remaining_date_fields_dd_singleComponent),
    forwardRef(() => Allocations_replenishment_contexts_dd_singleComponent),
    forwardRef(() => Allocations_replenishment_quantities_dd_singleComponent),
  ],
  selector: 'FootprintManager-allocation_configurations_hub',
  templateUrl: './FootprintManager.allocation_configurations_hub.component.html'
})
export class FootprintManager_allocation_configurations_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { loaded_configuration?: any, tooltip_definitions?: any, tooltips?: any, config_id?: number, applicable_config_ids?: number[], is_edit?: boolean, loaded_configuration_source?: any, is_entity_change_pending?: boolean, entity_details?: { is_valid_entity?: boolean, entities?: { name: string, value: string }[], filters_to_show?: any[], has_required_filters?: boolean } } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    context_allocation: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    show_inherited_values: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allocation_entity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_material: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_material_group: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_account_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_account: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_order_class_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    entity_order_class: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    allow_inactive: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allow_expired: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allowed_status_ids: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    strategy_base: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    strategy_packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    full_pallets_first: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    can_break_packagings: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    use_primary_pick_locations: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    can_break_license_plates: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    days_remaining_date_field: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    days_remaining_threshold: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    replenishments_context: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    replenishments_quantity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(null, null), false, false, false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    , false),
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, false, false, 'Save', 'icon-ic_fluent_save_20_regular', null)
    , false)
  };

  actionbar = {
  };

 filters = {
    context_allocation: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['context_allocation'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Context', false, false),
    show_inherited_values: new FieldModel(new CheckBoxModel(this.formGroup.controls['show_inherited_values'] as DatexFormControl, null, false, false, 'Show inherited values', null)
, new ControlContainerStyles(null, null), '', false, false),
    allocation_entity: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['allocation_entity'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Configuration entity', false, false),
    entity_owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', false, false),
    entity_project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    entity_material: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_material'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material', false, false),
    entity_warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    entity_material_group: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_material_group'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Material group', false, false),
    entity_account_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_account_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Account type', false, false),
    entity_account: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_account'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Account', false, false),
    entity_order_class_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_order_class_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Order class type', false, false),
    entity_order_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['entity_order_class'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Order class', false, false),
    allow_inactive: new FieldModel(new CheckBoxModel(this.formGroup.controls['allow_inactive'] as DatexFormControl, null, false, false, 'Allow inactive inventory', () => { const $utils = this.$utils; const $hub = this; return `Include inactive inventory`; })
, new ControlContainerStyles(null, null), '', false, false),
    allow_expired: new FieldModel(new CheckBoxModel(this.formGroup.controls['allow_expired'] as DatexFormControl, null, false, false, 'Allow expired inventory', () => { const $utils = this.$utils; const $hub = this; return `Include inventory that has expired`; })
, new ControlContainerStyles(null, null), '', false, false),
    allowed_status_ids: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['allowed_status_ids'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Allowed statuses', false, false),
    strategy_base: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['strategy_base'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', () => { const $utils = this.$utils; const $hub = this; return $hub.vars.tooltips.strategy_base; })
, new ControlContainerStyles(null, null), 'Base strategy', false, false),
    strategy_packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['strategy_packaging'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', () => { const $utils = this.$utils; const $hub = this; return $hub.vars.tooltips.strategy_packaging; })
, new ControlContainerStyles(null, null), 'Packaging strategy', false, false),
    full_pallets_first: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['full_pallets_first'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', () => { const $utils = this.$utils; const $hub = this; return $hub.vars.tooltips.full_pallets_first; })
, new ControlContainerStyles(null, null), 'Full pallets first', false, false),
    can_break_packagings: new FieldModel(new CheckBoxModel(this.formGroup.controls['can_break_packagings'] as DatexFormControl, null, false, false, 'Can break packagings', () => { const $utils = this.$utils; const $hub = this; return `Set whether larger packagings can be broken down to allocate sub packagings`; })
, new ControlContainerStyles(null, null), '', false, false),
    use_primary_pick_locations: new FieldModel(new CheckBoxModel(this.formGroup.controls['use_primary_pick_locations'] as DatexFormControl, null, false, false, 'Use primary pick locations', () => { const $utils = this.$utils; const $hub = this; return `Utilize primary pick locations for partial quantities`; })
, new ControlContainerStyles(null, null), '', false, false),
    can_break_license_plates: new FieldModel(new CheckBoxModel(this.formGroup.controls['can_break_license_plates'] as DatexFormControl, null, false, false, 'Can break license plates', () => { const $utils = this.$utils; const $hub = this; return `Allows the allocation workflow to break full license plates in non-primary locations`; })
, new ControlContainerStyles(null, null), '', false, false),
    days_remaining_date_field: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['days_remaining_date_field'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', () => { const $utils = this.$utils; const $hub = this; return $hub.vars.tooltips.days_remaining_date_field; })
, new ControlContainerStyles(null, null), 'Days remaining: Field', false, false),
    days_remaining_threshold: new FieldModel(new NumberBoxModel(this.formGroup.controls['days_remaining_threshold'] as DatexFormControl, null, false, false, '', '', () => { const $utils = this.$utils; const $hub = this; return `Set the number of days that must remain before lot expiration after delivery`; })
, new ControlContainerStyles(null, null), 'Days remaining: Threshold', false, false),
    replenishments_context: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['replenishments_context'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', () => { const $utils = this.$utils; const $hub = this; return $hub.vars.tooltips.replenishments_context; })
, new ControlContainerStyles(null, null), 'Replenishment context', false, false),
    replenishments_quantity: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['replenishments_quantity'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', () => { const $utils = this.$utils; const $hub = this; return $hub.vars.tooltips.replenishments_quantity; })
, new ControlContainerStyles(null, null), 'Replenishment quantity', false, false),
    priority: new FieldModel(new NumberBoxModel(this.formGroup.controls['priority'] as DatexFormControl, null, false, false, '', '', null)
, new ControlContainerStyles(null, null), 'Priority', false, false),
  };


  filtersets = {
    header: new FieldsetModel(
      'Header',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    filters_allocation: new FieldsetModel(
      'Allocation filters',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    configurations_allocation: new FieldsetModel(
      'Allocation configurations',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    strategy: new FieldsetModel(
      'Strategy',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    days_remaining: new FieldsetModel(
      'Days remaining',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    replenishments: new FieldsetModel(
      'Replenishments',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    common: new FieldsetModel(
      'Common',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $hub = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      applicable_configs: new TabItemModel(
        this.rootTabGroup, 
        'Applicable configurations', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    cacheValueFor_$tabs_applicable_configs_allocation_configurations_grid_inParams_applicable_config_ids: number[];
    get $tabs_applicable_configs_allocation_configurations_grid_inParams_applicable_config_ids(): number[] {
      const $hub = this;
      const $utils = this.$utils;
      const expr = $hub.vars.applicable_config_ids;
      
      if(!isEqual(this.cacheValueFor_$tabs_applicable_configs_allocation_configurations_grid_inParams_applicable_config_ids, expr)) {
        this.cacheValueFor_$tabs_applicable_configs_allocation_configurations_grid_inParams_applicable_config_ids = expr;
      }
      return this.cacheValueFor_$tabs_applicable_configs_allocation_configurations_grid_inParams_applicable_config_ids;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_applicable_configs', { read: FootprintManager_allocation_configurations_gridComponent }) $tabs_applicable_configs: FootprintManager_allocation_configurations_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_entity_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.entity_project.control.value;
    
    return expr;
  }



  get $fields_entity_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.entity_owner.control.value;
    
    return expr;
  }



  get $fields_entity_material_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.entity_project.control.value;
    
    return expr;
  }



  get $fields_entity_account_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.entity_owner.control.value ?? '';
    
    return expr;
  }



  cacheValueFor_$fields_entity_account_selector_inParams_typeIds: number[];
  get $fields_entity_account_selector_inParams_typeIds(): number[] {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = [];
    
    if(!isEqual(this.cacheValueFor_$fields_entity_account_selector_inParams_typeIds, expr)) {
      this.cacheValueFor_$fields_entity_account_selector_inParams_typeIds = expr;
    }
    return this.cacheValueFor_$fields_entity_account_selector_inParams_typeIds;
  }



  get $fields_entity_account_selector_inParams_accountTypeId(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.entity_account_type.control.value ?? '';
    
    return expr;
  }



  get $fields_entity_order_class_selector_inParams_order_class_type_id(): number {
    const $hub = this;
    const $utils = this.$utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.entity_order_class_type.control.value;
    
    return expr;
  }



  //#endregion filters inParams



  constructor(
  private $utils: UtilsService,
  private $settings: SettingsValuesService,
  private $shell: FootprintManager_ShellService,
  private $datasources: FootprintManager_DatasourceService,
  private $flows: FootprintManager_FlowService,
  private $reports: FootprintManager_ReportService,
  private $localization: FootprintManager_LocalizationService,
  private $operations: FootprintManager_OperationService,
  private $logger: CleanupLoggerService,
  private $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.applicable_configs,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Allocation configurations';
    
    const $hub = this;
    const $utils = this.$utils;

    (this.filters.show_inherited_values.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['show_inherited_values']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.get_configuration();
      });
    this.formGroup
      .controls['allocation_entity']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_allocation_entity();
      });
    this.formGroup
      .controls['entity_owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_entity_owner();
      });
    this.formGroup
      .controls['entity_project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_entity_project();
      });
    this.formGroup
      .controls['entity_material']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.get_configuration();
      });
    this.formGroup
      .controls['entity_warehouse']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.get_configuration();
      });
    this.formGroup
      .controls['entity_material_group']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.get_configuration();
      });
    this.formGroup
      .controls['entity_account_type']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_entity_account_type();
      });
    this.formGroup
      .controls['entity_account']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.get_configuration();
      });
    this.formGroup
      .controls['entity_order_class_type']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_entity_order_class_type();
      });
    this.formGroup
      .controls['entity_order_class']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.get_configuration();
      });
    this.formGroup
      .controls['strategy_base']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_strategy_base();
      });
    this.formGroup
      .controls['strategy_packaging']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_strategy_packaging();
      });
    this.formGroup
      .controls['full_pallets_first']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_full_pallets_first();
      });
    this.formGroup
      .controls['days_remaining_date_field']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_days_remaining_date_field();
      });
    this.formGroup
      .controls['replenishments_context']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_replenishments_context();
      });
    this.formGroup
      .controls['replenishments_quantity']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_change_replenishments_quantity();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_applicable_configs') {
      if (!isNil(this.$tabs_applicable_configs) && !this.tabs.applicable_configs.hidden) {
        this.$tabs_applicable_configs.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_init');
  if ((await $operations.FootprintManager.Disable_Navigation_Settings_Allocation_Configuration.isAssigned())) { $hub.close(); }
  
  $hub.vars.applicable_config_ids = [];
  $hub.vars.tooltips = {};
  $hub.vars.is_entity_change_pending = false;
  
  $hub.vars.is_edit = false;
  
  $hub.toolbar.edit.hidden = true;
  $hub.toolbar.save.hidden = true;
  
  $hub.on_change_allocation_entity();
  
  $hub.vars.tooltip_definitions = {
      context_allocation: {
          strategy_base: [
              { value: null, tooltip: 'Select how lots should be prioritized' },
              { value: $types.Allocations.e_allocation_base_strategy.FEFO, tooltip: 'Prioritizes lots that are first to expire' },
              { value: $types.Allocations.e_allocation_base_strategy.LEFO, tooltip: 'Prioritizes lots that are last to expire' },
              { value: $types.Allocations.e_allocation_base_strategy.FIFO, tooltip: 'Prioritizes lots in the order they were received' },
              { value: $types.Allocations.e_allocation_base_strategy.LIFO, tooltip: 'Prioritizes lots in reverse order that they were received' },
              { value: $types.Allocations.e_allocation_base_strategy.FMFO, tooltip: 'Prioritizes lots that were manufactured first' },
              { value: $types.Allocations.e_allocation_base_strategy.LMFO, tooltip: 'Prioritizes lots that were manufactured last' }
          ],
          days_remaining_date_field: [
              { value: null, tooltip: 'Select which date field to use when calculating days remaining for an order' },
              { value: $types.Allocations.e_allocation_days_remaining_date_field.Order_RequestedDeliveryDate, tooltip: `Use the Order's 'RequestedDeliveryDate' property` },
              { value: $types.Allocations.e_allocation_days_remaining_date_field.Shipment_ExpectedDate, tooltip: `Use the Shipment's 'ExpectedDate' property` }
          ],
          full_pallets_first: [
              { value: null, tooltip: 'Select if/how full pallets should be prioritized' },
              { value: $types.Allocations.e_allocation_full_pallets_first.Disabled, tooltip: 'Full pallets will not be prioritized' },
              { value: $types.Allocations.e_allocation_full_pallets_first.FollowStrategy, tooltip: 'Full pallets will be prioritized, but limited to the base strategy' },
              { value: $types.Allocations.e_allocation_full_pallets_first.SkipStrategy, tooltip: 'Full pallets will be prioritized, even over the base strategy' }
          ],
          strategy_packaging: [
              { value: null, tooltip: 'Select how packagings should be prioritized' },
              { value: $types.Allocations.e_allocation_package_strategy.LargestFirst, tooltip: 'Upconvert requested amount to prioritize largest packaging available' },
              { value: $types.Allocations.e_allocation_package_strategy.RequestedFirst, tooltip: 'Prioritize the requested packaging, then prioritize largest packaging available' },
              { value: $types.Allocations.e_allocation_package_strategy.RequestedOnly, tooltip: 'Restrict to only allocating the requested packaging' }
          ],
          replenishments_context: [
              { value: null, tooltip: 'Select under which context replenishments should be created' },
              { value: $types.Allocations.e_allocation_replenishment_context.InLine, tooltip: 'Replenishments will be created as needed during wave processing' },
              { value: $types.Allocations.e_allocation_replenishment_context.Schedule, tooltip: 'Replenishments will be created on a schedule' },
              { value: $types.Allocations.e_allocation_replenishment_context.TopUp, tooltip: 'Replenishments will be created manually, as needed' }
          ],
          replenishments_quantity: [
              { value: null, tooltip: 'Select how replenishments will decide on the quantity to replenish' },
              { value: $types.Allocations.e_allocation_replenishment_quantity.FillToMaxCapacity, tooltip: 'Replenish the difference between the current amount and the max amount for the location' },
              { value: $types.Allocations.e_allocation_replenishment_quantity.ReplenishmentQuantity, tooltip: "Replenish the amount configured on the location's replenishment threshold" },
              { value: $types.Allocations.e_allocation_replenishment_quantity.SmallestAvailableLicensePlate, tooltip: 'Replenish using the smallest available license plate(s)' },
              { value: $types.Allocations.e_allocation_replenishment_quantity.LargestAvailableLicensePlate, tooltip: 'Replenish using the largest available license plate(s)' }
          ]
      }
  }
  }
  on_change_strategy_base(event = null) {
    return this.on_change_strategy_baseInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_strategy_baseInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_strategy_base');
  $hub.vars.tooltips.strategy_base = $hub.vars.tooltip_definitions.context_allocation.strategy_base?.find(t => t.value == $hub.filters.strategy_base.control.value)?.tooltip
  }
  on_change_days_remaining_date_field(event = null) {
    return this.on_change_days_remaining_date_fieldInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_days_remaining_date_fieldInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_days_remaining_date_field');
  $hub.vars.tooltips.days_remaining_date_field = $hub.vars.tooltip_definitions.context_allocation.days_remaining_date_field?.find(t => t.value == $hub.filters.days_remaining_date_field.control.value)?.tooltip
  }
  on_change_strategy_packaging(event = null) {
    return this.on_change_strategy_packagingInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_strategy_packagingInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_strategy_packaging');
  $hub.vars.tooltips.strategy_packaging = $hub.vars.tooltip_definitions.context_allocation.strategy_packaging?.find(t => t.value == $hub.filters.strategy_packaging.control.value)?.tooltip
  }
  on_change_replenishments_context(event = null) {
    return this.on_change_replenishments_contextInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_replenishments_contextInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_replenishments_context');
  $hub.vars.tooltips.replenishments_context = $hub.vars.tooltip_definitions.context_allocation.replenishments_context?.find(t => t.value == $hub.filters.replenishments_context.control.value)?.tooltip
  }
  on_change_replenishments_quantity(event = null) {
    return this.on_change_replenishments_quantityInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_replenishments_quantityInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_replenishments_quantity');
  $hub.vars.tooltips.replenishments_quantity = $hub.vars.tooltip_definitions.context_allocation.replenishments_quantity?.find(t => t.value == $hub.filters.replenishments_quantity.control.value)?.tooltip
  }
  on_change_full_pallets_first(event = null) {
    return this.on_change_full_pallets_firstInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_full_pallets_firstInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_full_pallets_first');
  $hub.vars.tooltips.full_pallets_first = $hub.vars.tooltip_definitions.context_allocation.full_pallets_first?.find(t => t.value == $hub.filters.full_pallets_first.control.value)?.tooltip
  }
  on_change_allocation_entity(event = null) {
    return this.on_change_allocation_entityInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_allocation_entityInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_allocation_entity');
  $hub.vars.applicable_config_ids = [];
  $hub.vars.entity_details = {};
  
  await $hub.get_entity_details();
  await set_entity_filters();
  $hub.get_configuration();
  
  /******************************************
   * FUNCTIONS
  *******************************************/
  async function set_entity_filters() {
      hide_all_allocation_filters();
  
      if ($utils.isDefined($hub.vars.entity_details?.filters_to_show)) {
          for (let filterToShow of $hub.vars.entity_details.filters_to_show) {
              filterToShow.hidden = false;
          }
      }
  }
  
  function hide_all_allocation_filters() {
      for (let key in $hub.filters) {
          if (key.startsWith('entity_')) {
              $hub.filters[key].hidden = true;
              $hub.filters[key].control.value = null;
          }
      }
  }
  }
  get_configuration(event = null) {
    return this.get_configurationInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async get_configurationInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.get_configuration');
  set_fieldsets_visible(false);
  clear_config_values();
  $hub.toolbar.edit.hidden = true;
  $hub.toolbar.save.hidden = true;
  
  await $hub.get_entity_details();
  
  if ($hub.vars.entity_details?.is_valid_entity && $hub.vars.entity_details?.has_required_filters) {
      get_configuration();
  }
  
  
  /***************************************** 
   * FUNCTIONS
  ******************************************/
  async function get_configuration() {
      $hub.filtersets.configurations_allocation.label = 'Allocation configurations';
      let configurations = await $flows.Allocations.get_allocation_configurations_flow({
          context: $hub.filters.context_allocation.control.value,
          allow_inherited_configs: $hub.filters.show_inherited_values.control.value,
          filters: {
              material_id: $hub.filters.entity_material.control.value,
              project_id: $hub.filters.entity_project.control.value,
              owner_id: $hub.filters.entity_owner.control.value,
              warehouse_id: $hub.filters.entity_warehouse.control.value,
              account_id: $hub.filters.entity_account.control.value,
              order_class_id: $hub.filters.entity_order_class.control.value,
              material_group_id: $hub.filters.entity_material_group.control.value
          }
      });
  
      // Check if config is for selected entity
      if (check_filters_match(configurations.config_filters)) {
          $hub.vars.config_id = configurations.config_id;
      } else {
          $hub.vars.config_id = null;
      }
  
      $hub.vars.applicable_config_ids = configurations.applicable_config_ids ?? [];
      $hub.vars.loaded_configuration_source = configurations.config_source;
      $hub.vars.loaded_configuration = configurations.config;
  
      if ($utils.isDefined(configurations.reasons)) {
          await $shell.Allocations.openErrorDialog(`Error retrieving configuration`, configurations.reasons.join(', '));
          set_fieldsets_visible(false);
          return;
      }
  
      $hub.set_hub_state();
  
      set_fieldsets_visible(true);
  }
  
  function set_fieldsets_visible(visible: boolean) {
      $hub.filtersets.configurations_allocation.hidden = !visible;
      $hub.filtersets.strategy.hidden = !visible;
      $hub.filtersets.days_remaining.hidden = !visible;
      $hub.filtersets.replenishments.hidden = !visible;
      $hub.filtersets.common.hidden = !visible;
  }
  
  function clear_config_values() {
      $hub.filters.allow_expired.control.value = null;
      $hub.filters.allow_inactive.control.value = null;
      $hub.filters.use_primary_pick_locations.control.value = null;
      $hub.filters.can_break_license_plates.control.value = null;
      $hub.filters.full_pallets_first.control.value = null;
      $hub.filters.strategy_base.control.value = null;
      $hub.filters.strategy_packaging.control.value = null;
      $hub.filters.days_remaining_date_field.control.value = null;
      $hub.filters.days_remaining_threshold.control.value = null;
      $hub.filters.replenishments_context.control.value = null;
      $hub.filters.replenishments_quantity.control.value = null;
      $hub.filters.priority.control.value = null;
  }
  
  function check_filters_match(filters: { name: string; value: string }[]) {
      return filters.length === $hub.vars.entity_details?.entities.length && filters.every(f => $hub.vars.entity_details?.entities.some(e => e.name === f.name.trim().toLowerCase() && e.value == f.value));
  }
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_save');
  $hub.vars.is_edit = false;
  
  // Construct new config from fields
  let newContent: $types.Allocations.i_allocation_config = {
      strategy: {
          base: check_add_config($hub.filters.strategy_base),
          packaging: check_add_config($hub.filters.strategy_packaging),
          can_break_packagings: check_add_config($hub.filters.can_break_packagings),
          can_break_license_plates: check_add_config($hub.filters.can_break_license_plates),
          use_primary_pick_locations: check_add_config($hub.filters.use_primary_pick_locations),
          full_pallets_first: check_add_config($hub.filters.full_pallets_first)
      },
      replenishments: {
          context: check_add_config($hub.filters.replenishments_context),
          quantity: check_add_config($hub.filters.replenishments_quantity)
      },
      days_remaining: {
          date_field: check_add_config($hub.filters.days_remaining_date_field),
          threshold: check_add_config($hub.filters.days_remaining_threshold)
      },
      restrictions: {
          allow_expired: check_add_config($hub.filters.allow_expired),
          allow_inactive: check_add_config($hub.filters.allow_inactive),
          allowed_status_ids: check_add_config($hub.filters.allowed_status_ids)
      }
  };
  
  check_remove_config(newContent);
  
  
  // Create config
  let errors: string[] = [];
  if ($hub.vars.config_id) {
      const result = await $flows.Allocations.update_allocation_configuration_flow({ config_id: $hub.vars.config_id, content: newContent });
  
      if ($utils.isDefined(result.reasons)) {
          errors.push(...result.reasons);
      }
  } else {
      const result = await $flows.Allocations.create_allocation_configuration_flow({
          content: newContent,
          filters: $hub.vars.entity_details?.entities?.map(e => ({
              name: e.name,
              value: e.value.toString()
          })),
          context: $hub.filters.context_allocation.control.value
      });
  
      if ($utils.isDefined(result.reasons)) {
          errors.push(...result.reasons);
      }
  
      $hub.vars.config_id = result.config_id;
  }
  
  // Handle results
  if ($utils.isDefined(errors)) {
      $shell.Allocations.openToaster('Failed to save config', errors.join(', '), EToasterType.Error, { closeButton: true, disableTimeOut: true, positionClass: EToasterPosition.bottomRight });
  } else {
      $shell.Allocations.openToaster('Configuration saved', null, EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
      $hub.toolbar.save.hidden = true;
      $hub.toolbar.edit.hidden = false;
  
      $hub.refresh();
      $hub.get_configuration();
  }
  
  $hub.refresh();
  /********************************************
   * FUNCTIONS
  *********************************************/
  
  function check_add_config(filter: any) {
      return !filter.control.readOnly && $utils.isDefined(filter.control.value) ? filter.control.value : undefined;
  }
  
  function check_remove_config(obj: any) {
      for (let key in obj) {
          const property = obj[key];
  
          // If undefined, remove
          if (property === undefined) {
              delete obj[key];
              continue;
          }
  
          // Use NULL to override, so leave in
          if (property === null) {
              continue;
          }
  
          // Check objects
          if (typeof property !== 'object') { continue; }
  
          check_remove_config(property);
  
          if (Object.keys(property).length === 0) {
              delete obj[key];
              continue;
          }
      }
  }
  }
  on_edit_clicked(event = null) {
    return this.on_edit_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_edit_clickedInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_edit_clicked');
  $hub.vars.is_edit = true;
  
  $hub.set_hub_state();
  }
  set_hub_state(event = null) {
    return this.set_hub_stateInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_hub_stateInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.set_hub_state');
  $hub.filtersets.configurations_allocation.label = $hub.vars.config_id ?
      `Configuration: ${$hub.vars.config_id}` :
      `New configuration${$utils.isDefined($hub.vars.entity_details?.entities) ? ` for ${$hub.vars.entity_details.entities.map(e => `${e.name} ${e.value}`).join(', ')}` : ' for global'}`;
  
  if (!$utils.isDefined($hub.vars.config_id)) {
      $hub.vars.is_edit = true;
  }
  
  set_all_fields_read_only(false);
  
  if ($hub.vars.config_id) {
      // Define mapping between configuration properties and hub filters
      const fieldMappings = get_field_mappings();
  
      // Helper function to get value from nested object using path string like "strategy.base"
      const getNestedValue = (obj, path) => {
          return path.split('.').reduce((prev, curr) =>
              prev && !['null','undefined'].includes(prev) && prev[curr] !== undefined ? prev[curr] : undefined, obj);
      };
  
      const config = $hub.vars.loaded_configuration as $types.Allocations.i_allocation_config;
      const configSource = $hub.vars.loaded_configuration_source as $types.Allocations.i_allocation_config;
  
      // Process each field mapping
      fieldMappings.forEach(mapping => {
          const configValue = getNestedValue(config, mapping.configPath);
          const sourceValue = getNestedValue(configSource, mapping.configPath);
          const hubFilter = $hub.filters[mapping.hubFilter];
  
          // Set the value
          hubFilter.control.value = configValue ?? null;
          if ($utils.isDefined($hub[`on_change_${mapping.hubFilter}`])) {
              $hub[`on_change_${mapping.hubFilter}`]();
          }
  
          // Reset label
          if ($utils.isDefined(hubFilter.control.label)) {
              hubFilter.control.label = hubFilter.control.label.split(' (Inherited')[0];
          } else {
              hubFilter.label = hubFilter.label.split(' (Inherited')[0];
          }
  
          // Check if it's inherited
          if (sourceValue) {
              const sourceId = +sourceValue.split(':')[0];
              if (sourceId && sourceId !== $hub.vars.config_id) {
                  // It's inherited
                  if ($utils.isDefined(hubFilter.control.label)) {
                      hubFilter.control.label = `${hubFilter.control.label} (Inherited from ${sourceValue})`;
                  } else {
                      hubFilter.label = `${hubFilter.label} (Inherited from ${sourceValue})`;
                  }
  
                  hubFilter.control.readOnly = true;
              } else {
                  hubFilter.control.readOnly = false;
              }
          } else {
              hubFilter.control.readOnly = false;
          }
  
          if (!$hub.vars.is_edit) {
              hubFilter.control.readOnly = true;
          }
      });
  }
  
  $hub.toolbar.edit.hidden = $hub.vars.is_edit;
  $hub.toolbar.save.hidden = !$hub.vars.is_edit;
  
  $hub.on_change_days_remaining_date_field();
  $hub.on_change_full_pallets_first();
  $hub.on_change_replenishments_context();
  $hub.on_change_replenishments_quantity();
  $hub.on_change_strategy_base();
  $hub.on_change_strategy_packaging();
  
  
  
  
  /***********************************
   * FUNCTIONS
   */
  function get_field_mappings() {
      return [
          {
              configPath: "strategy.base",
              hubFilter: "strategy_base"
          },
          {
              configPath: "strategy.packaging",
              hubFilter: "strategy_packaging"
          },
          {
              configPath: "strategy.can_break_packagings",
              hubFilter: "can_break_packagings"
          },
          {
              configPath: "strategy.can_break_license_plates",
              hubFilter: "can_break_license_plates"
          },
          {
              configPath: "strategy.full_pallets_first",
              hubFilter: "full_pallets_first"
          },
          {
              configPath: "strategy.use_primary_pick_locations",
              hubFilter: "use_primary_pick_locations"
          },
          {
              configPath: "days_remaining.date_field",
              hubFilter: "days_remaining_date_field"
          },
          {
              configPath: "days_remaining.threshold",
              hubFilter: "days_remaining_threshold"
          },
          {
              configPath: "replenishments.context",
              hubFilter: "replenishments_context"
          },
          {
              configPath: "replenishments.quantity",
              hubFilter: "replenishments_quantity"
          },
          {
              configPath: "restrictions.allow_expired",
              hubFilter: "allow_expired"
          },
          {
              configPath: "restrictions.allow_inactive",
              hubFilter: "allow_inactive"
          },
          {
              configPath: "restrictions.allowed_status_ids",
              hubFilter: "allowed_status_ids"
          }
      ];
  }
  
  function set_all_fields_read_only(isReadOnly: boolean) {
      let fieldMappings = get_field_mappings();
  
      for (let field of fieldMappings) {
          $hub.filters[field.hubFilter].control.readOnly = isReadOnly;
      }
  }
  }
  on_change_entity_project(event = null) {
    return this.on_change_entity_projectInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_entity_projectInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_entity_project');
  if ($utils.isDefined($hub.filters.entity_project.control.value) && !$utils.isDefined($hub.filters.entity_owner.control.value)) {
      const project = (await $datasources.Owners.ds_get_project_by_projectId.get({ projectId: $hub.filters.entity_project.control.value })).result[0];
  
      $hub.filters.entity_owner.control.value = project.OwnerId;
  }
  
  if ($hub.vars.is_entity_change_pending) {
      return;
  }
  
  try {
      $hub.vars.is_entity_change_pending = true;
  
      await $hub.get_configuration();
  } finally {
      $hub.vars.is_entity_change_pending = false;
  }
  
  }
  on_change_entity_owner(event = null) {
    return this.on_change_entity_ownerInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_entity_ownerInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_entity_owner');
  $hub.filters.entity_project.control.value = null;
  $hub.filters.entity_account.control.value = null;
  
  if ($hub.vars.is_entity_change_pending) {
      $hub.on_change_entity_project();
      return;
  }
  
  try {
      $hub.vars.is_entity_change_pending = true;
  
      await $hub.get_configuration();
      $hub.on_change_entity_project();
  } finally {
      $hub.vars.is_entity_change_pending = false;
  }
  
  }
  on_change_entity_account_type(event = null) {
    return this.on_change_entity_account_typeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_entity_account_typeInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_entity_account_type');
  $hub.filters.entity_account.control.value = null;
  
  if ($hub.vars.is_entity_change_pending) {
      return;
  }
  
  try {
      $hub.vars.is_entity_change_pending = true;
  
      await $hub.get_configuration();
  } finally {
      $hub.vars.is_entity_change_pending = false;
  }
  
  }
  on_change_entity_order_class_type(event = null) {
    return this.on_change_entity_order_class_typeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_change_entity_order_class_typeInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.on_change_entity_order_class_type');
  $hub.filters.entity_order_class.control.value = null;
  
  if ($hub.vars.is_entity_change_pending) {
      return;
  }
  
  try {
      $hub.vars.is_entity_change_pending = true;
  
      await $hub.get_configuration();
  } finally {
      $hub.vars.is_entity_change_pending = false;
  }
  
  }
  get_entity_details(event = null) {
    return this.get_entity_detailsInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async get_entity_detailsInternal(
    $hub: FootprintManager_allocation_configurations_hubComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_allocation_configurations_hub_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'allocation_configurations_hub.get_entity_details');
  let entities: { name: string; value: string; }[] = [];
  let is_valid_entity = true;
  let filters_to_show: any[] = [];
  let has_required_filters = false;
  
  const entityName = $types.Allocations.e_allocation_configuration_entity[$hub.filters.allocation_entity.control.value]?.toLowerCase();
  let value: string;
  let filterCount: number;
  
  
  switch (entityName) {
      case 'material':
          value = $hub.filters.entity_material.control.value;
          has_required_filters = $utils.isDefined(value);
  
          filters_to_show.push($hub.filters.entity_owner);
          filters_to_show.push($hub.filters.entity_project);
          filters_to_show.push($hub.filters.entity_material);
          break;
      case 'materialgroup':
          value = $hub.filters.entity_material_group.control.value;
          has_required_filters = $utils.isDefined(value);
  
          filters_to_show.push($hub.filters.entity_material_group);
          break;
      case 'account':
          value = $hub.filters.entity_account.control.value;
          has_required_filters = $utils.isDefined(value);
  
          filters_to_show.push($hub.filters.entity_owner);
          filters_to_show.push($hub.filters.entity_account);
          filters_to_show.push($hub.filters.entity_account_type);
          break;
      case 'orderclass':
          value = $hub.filters.entity_order_class.control.value;
          has_required_filters = $utils.isDefined(value);
  
          filters_to_show.push($hub.filters.entity_order_class);
          filters_to_show.push($hub.filters.entity_order_class_type);
          break;
      case 'project':
          value = $hub.filters.entity_project.control.value;
          has_required_filters = $utils.isDefined(value);
  
          filters_to_show.push($hub.filters.entity_owner);
          filters_to_show.push($hub.filters.entity_project);
          break;
      case 'owner':
          value = $hub.filters.entity_owner.control.value;
          has_required_filters = $utils.isDefined(value);
  
          filters_to_show.push($hub.filters.entity_owner);
          break;
      case 'warehouse':
          value = $hub.filters.entity_warehouse.control.value;
          has_required_filters = $utils.isDefined(value);
  
          filters_to_show.push($hub.filters.entity_warehouse);
          break;
      case 'global': ;
          has_required_filters = true;
  
          break;
      case 'globalmaterial':
      default:
          is_valid_entity = false;
          break;
  }
  
  if ($utils.isDefined(value)) {
      entities.push({ name: entityName, value });
  }
  
  $hub.vars.entity_details = {
      is_valid_entity,
      entities,
      filters_to_show,
      has_required_filters
  };
  }
  //#endregion private flows
}
