import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Catalogs.frontend.types'

@Injectable({ providedIn: 'root' })
export class Catalogs_create_source_material_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { catalogId: number, lookupcode: string, lotControlled: boolean, serialControlled: boolean, materialGroupId: number, description: string, name?: string, allocationStrategyId: number, isFixedWeight: boolean, isFixedLength: boolean, isFixedVolume: boolean, shelfLifeSpan: number, basePackagingId: number, netWeight?: number, shippingWeight?: number, weightUomId?: number, height?: number, length?: number, width?: number, dimensionUomId?: number, netVolume?: number, shippingVolume?: number, volumeUomId?: number, palletTie?: number, palletHigh?: number, upcCode?: string, quantityPrecisionScale?: number, storageCategoryRuleId?: number, allocationStrategyWorkflowId: string }): Promise<{ reasons?: string[], materialId?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.catalogId)) {
      missingRequiredInParams.push('\'catalogId\'');
    }
    if (isNil(inParams.lookupcode)) {
      missingRequiredInParams.push('\'lookupcode\'');
    }
    if (isNil(inParams.lotControlled)) {
      missingRequiredInParams.push('\'lotControlled\'');
    }
    if (isNil(inParams.serialControlled)) {
      missingRequiredInParams.push('\'serialControlled\'');
    }
    if (isNil(inParams.materialGroupId)) {
      missingRequiredInParams.push('\'materialGroupId\'');
    }
    if (isNil(inParams.description)) {
      missingRequiredInParams.push('\'description\'');
    }
    if (isNil(inParams.allocationStrategyId)) {
      missingRequiredInParams.push('\'allocationStrategyId\'');
    }
    if (isNil(inParams.isFixedWeight)) {
      missingRequiredInParams.push('\'isFixedWeight\'');
    }
    if (isNil(inParams.isFixedLength)) {
      missingRequiredInParams.push('\'isFixedLength\'');
    }
    if (isNil(inParams.isFixedVolume)) {
      missingRequiredInParams.push('\'isFixedVolume\'');
    }
    if (isNil(inParams.shelfLifeSpan)) {
      missingRequiredInParams.push('\'shelfLifeSpan\'');
    }
    if (isNil(inParams.basePackagingId)) {
      missingRequiredInParams.push('\'basePackagingId\'');
    }
    if (isNil(inParams.allocationStrategyWorkflowId)) {
      missingRequiredInParams.push('\'allocationStrategyWorkflowId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Catalogs/functions/create_source_material_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

