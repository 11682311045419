import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Manufacturing_ds_manufacturing_order_production_confirmation_data_components_extendedService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { manufacturing_order_id?: number, task_chain_head?: number, warehouse_id?: number, $top?: number, $skip?: number }): Promise<{ result?: { id?: number }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Manufacturing/datasources/ds_manufacturing_order_production_confirmation_data_components_extended/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      manufacturing_order_id?: number, task_chain_head?: number, warehouse_id?: number    }) {
      return false;
    }
}
