import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_projects_grid_ComponentContextService } from './FootprintManager.projects_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_projects_gridComponentEntity {
  Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Name?: string, Notes?: string, StatusId?: number, Status?: { Label?: string }, Owner?: { LookupCode?: string, Name?: string }, LicenseplateCount?: { TotalLicenseplates?: number, Lot?: { Material?: { ProjectId?: number } } }}

interface IFootprintManager_projects_gridComponentInParams {
  projectStatusId?: number, ownerId?: number, projectIds?: number[]}


class FootprintManager_projects_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_projects_gridComponent;
  entity: IFootprintManager_projects_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: null,
    status: null,
    name: null,
    owner_lookupcode: null,
    owner_name: null,
    notes: null,
    licenseplate_count: null,
    created_on: null,
    created_by: null,
  }



  constructor(
    grid: FootprintManager_projects_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_projects_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.lookupcode = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.owner_lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.owner_lookupcode,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.owner_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.owner_name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.notes = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.notes,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.licenseplate_count = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.licenseplate_count,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.created_on = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.created_on,
      new TextModel(null, null,  'datetime', 'MM/DD/YYYY', null)
,
null
      );
    
    this.cells.created_by = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.created_by,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_projects_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_projects_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      projectStatusId:  null ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectIds:  $grid.inParams.projectIds ,
    };
    const data = await this.$datasources.Owners.ds_projects_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_projects_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Label;
    (this.cells.name.displayControl as TextModel).text = $row.entity.Name;
    (this.cells.owner_lookupcode.displayControl as TextModel).text = $row.entity.Owner.LookupCode;
    (this.cells.owner_name.displayControl as TextModel).text = $row.entity.Owner.Name;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.licenseplate_count.displayControl as TextModel).text = $utils.isDefined($row.entity.LicenseplateCount?.TotalLicenseplates) ? $row.entity.LicenseplateCount?.TotalLicenseplates?.toString(): '0';
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.CreatedSysUser;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: FootprintManager_projects_gridComponentRowModel,
  $grid: FootprintManager_projects_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_projects_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  const dialogResults = await $shell.FootprintManager.opensingle_project_hub({ projectId: $row.entity.Id });
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-projects_grid',
  templateUrl: './FootprintManager.projects_grid.component.html'
})
export class FootprintManager_projects_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_projects_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_projects_gridComponentInParams = { projectStatusId: null, ownerId: null, projectIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'lookupcode', 'Project', false, false, null, false),       status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       name: new GridHeaderModel(new HeaderStyles(null, null), 'name', 'Name', false, false, null, false),       owner_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'owner_lookupcode', 'Owner code', false, false, null, false),       owner_name: new GridHeaderModel(new HeaderStyles(null, null), 'owner_name', 'Owner name', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),       licenseplate_count: new GridHeaderModel(new HeaderStyles(null, null), 'licenseplate_count', 'License plates', false, false, null, false),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'created_on', 'Created on', false, false, null, false),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'created_by', 'Created by', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_projects_gridComponentRowModel[] = [];

  @Input('projectStatusId') set $inParams_projectStatusId(value: any) {
    this.inParams['projectStatusId'] = value;
  }
  get $inParams_projectStatusId(): any {
    return this.inParams['projectStatusId'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectIds') set $inParams_projectIds(value: any) {
    this.inParams['projectIds'] = value;
  }
  get $inParams_projectIds(): any {
    return this.inParams['projectIds'] ;
  }

  topToolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, false, false, 'Change status', 'icon-ic_fluent_arrow_sync_circle_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_projects_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Projects';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      projectStatusId:  null ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectIds:  $grid.inParams.projectIds ,
    };
    try {
      const data = await this.$datasources.Owners.ds_projects_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_projects_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_projects_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_projects_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_project_clicked(event = null) {
    return this.on_create_project_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_project_clickedInternal(
    $grid: FootprintManager_projects_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_projects_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'projects_grid.on_create_project_clicked');
  
  
  
  
  
  const dialogResult = await $shell.FootprintManager.openproject_creation_formDialog({ownerId: $grid.inParams.ownerId},'flyout');
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      await $grid.refresh()
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_projects_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_projects_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
      $grid.topToolbar.update.control.readOnly = false;
  }
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.update.control.readOnly = true;
  }
  
  
  $grid.topToolbar.on_delete.hidden = (await $operations.FootprintManager.Disable_Project_Deletion.isAuthorized());
  $grid.topToolbar.update.hidden =(await $operations.FootprintManager.Disable_Project_Editing.isAuthorized());
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootprintManager_projects_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_projects_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootprintManager.openErrorDialog('Project Delete Error', 'No projects selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              projectId: row.entity.Id
          };
          const result = await $flows.Owners.is_project_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Project ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete project errors';
          const errorMessage = `Project(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootprintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Project(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once deleted, the project(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some project(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following project(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootprintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          projectId: candidate.entity.Id
                      }
                      const result = (await $flows.Owners.delete_project_flow(flowParams));
  
                      if ($utils.isDefined(result?.reason)) {
                          processFailures.push(candidate);
                          errorMsgList.push(result?.reason);
                      }
                      else {
  
                          processSuccess.push(candidate);
                      }
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Project ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All project(s) deleted';
                  const message = `Project(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} deleted`;
                  await $shell.FootprintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All project(s) failed to delete';
                      const message = `Project(s) ${processFailures.map(c => c.entity.LookupCode).join(',')} could not be deleted`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some projects could not be deleted';
                      const deleted = `Project(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} were deleted.`;
                      const errors = `The following project(s) could not be deleted: ${processFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.FootprintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_update(event = null) {
    return this.on_updateInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_updateInternal(
    $grid: FootprintManager_projects_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_projects_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootprintManager.openErrorDialog('Owner Update Error', 'No owners selected.');
      return;
  } else {
  
      const dialogResult = await $shell.FootprintManager.openproject_update_formDialog();
      const userConfirmed = dialogResult.confirm;
      const projectStatusId = dialogResult.projectStatusId;
  
      let payload: any = {};
  
      if (userConfirmed) {
          for (const row of selectedRows) {
  
              if ($utils.isDefined(projectStatusId)) {
                  payload.StatusId = projectStatusId;
              }
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'Projects', id: row.entity.Id, entity: payload });
          }
          await $grid.refresh();
      }
  }
  }
  on_excel_export_flow(event = null) {
    return this.on_excel_export_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_export_flowInternal(
    $grid: FootprintManager_projects_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_projects_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const wb = $event;
  const clientExportLimit = $settings.FootprintManager.ExcelExportLimit;;
  const serverLimit = 5000;
  const records = [];
  
  try {
      // Call the api with the same filters(grid inputs)
      // Get the count of the query
      // First time call is the call the decides if we need more calls or not based on the server side limit.
      const { totalCount: requiredCount } = (await $datasources.Owners.ds_projects_grid.getList({
          //projectStatusId: $grid.filters.project_status.control.value,
          ownerId: $grid.inParams.ownerId,
          projectIds: $grid.inParams.projectIds,
          fullTextSearch: $grid.fullTextSearch,
          $top: 0
      }));
  
  
      if (requiredCount > clientExportLimit) {
          await $shell.FootprintManager.openInfoDialog(`Export limitation of ${clientExportLimit} exceeded`, `Excel will only export the first ${clientExportLimit} records`);
      }
  
      if (serverLimit < clientExportLimit) {
          // Generate a list of promises for concurrent requests
          const fetchPromises = [];
          let currentOffset = 0;
          let currentRemainingCounter = clientExportLimit;
  
          while (currentRemainingCounter > 0) {
              const limit = Math.min(serverLimit, currentRemainingCounter);
              fetchPromises.push(fetchRecords(currentOffset, limit));
              currentOffset += limit;
              currentRemainingCounter -= limit;
          }
  
          // Wait for all promises to resolve
          const additionalResponses = await Promise.all(fetchPromises);
  
          // Combine results from all additional calls
          additionalResponses.forEach((apiResponse) => {
              records.push(...apiResponse.result);
          });
      } else {
          // Make a normal call to get all the required records
          const { result: response } =
              await $datasources.Owners.ds_projects_grid.getList({
                  //projectStatusId: $grid.filters.project_status.control.value,
                  ownerId: $grid.inParams.ownerId,
                  projectIds: $grid.inParams.projectIds,
                  fullTextSearch: $grid.fullTextSearch,
                  $top: clientExportLimit
              });
          records.push(...response);
      }
  
      // Compile all data into JSON
      const formatedResults = records.map(record => {
          return {
              "Lookupcode": record.LookupCode,
              "Name": record.Name,
              "Owner lookup": record.Owner.LookupCode,
              "Owner name": record.Owner.Name,
              "Status": record.Status?.Label,
              "LP count": $utils.isDefined(record.LicenseplateCount?.TotalLicenseplates) ? record.LicenseplateCount?.TotalLicenseplates?.toString(): '0',
              "Created on": record.CreatedSysDateTime?.toString(),
              "Created by": record.CreatedSysUser,
              "Notes": record.Notes
          };
      });
  
  
      // Generate the excel sheet from the JSON
      const ws = $utils.excel.json_to_sheet(formatedResults);
      $utils.excel.book_append_sheet(wb, ws, "Projects");
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  // Helpers
  
  // Function to fetch records with offset and limit
  async function fetchRecords(offset: number, limit: number): Promise<any> {
      return await $datasources.Owners.ds_projects_grid.getList({
          //projectStatusId:$grid.filters.project_status.control.value,
          ownerId: $grid.inParams.ownerId,
          projectIds: $grid.inParams.projectIds,
          fullTextSearch: $grid.fullTextSearch,
          $skip: offset,
          $top: limit
      });
  };
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_projects_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_projects_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  $grid.topToolbar.on_delete.hidden = (await $operations.FootprintManager.Disable_Project_Deletion.isAuthorized());
  $grid.topToolbar.update.hidden = (await $operations.FootprintManager.Disable_Project_Editing.isAuthorized());
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export_flow(wb);

    writeExcelFile(wb, 'FootprintManager_projects_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
