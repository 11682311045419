<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-details"
                     *ngIf="!fieldsets.details.hidden && !fieldsets.details.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.details.collapsible }">
                    <div *ngIf="!fieldsets.details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.details.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.details.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.details.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.details.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-description" *ngIf="!fields.description.hidden && !fields.description.removed" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}} {{fields.description.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.description.label + (fields.description.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.description.label}}<span *ngIf="fields.description.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.description.control.styles.style"
                              [ngClass]="fields.description.control.styles.classes"
                              [matTooltip]="fields.description.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.description.control.text }}</div>
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-produced_serials" *ngIf="!fields.produced_serials.hidden && !fields.produced_serials.removed" 
                            class="field-container full {{fields.produced_serials.invalid ? 'invalid' : ''}} {{fields.produced_serials.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.produced_serials.styles.style"
                            [ngClass]="fields.produced_serials.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.produced_serials.label + (fields.produced_serials.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.produced_serials.label}}<span *ngIf="fields.produced_serials.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.produced_serials.control.styles.style"
                              [ngClass]="fields.produced_serials.control.styles.classes"
                              [matTooltip]="fields.produced_serials.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.produced_serials.control.text }}</div>
                        <ng-container *ngIf="fields.produced_serials.invalid">
                          <ng-container *ngFor="let error of fields.produced_serials.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.details.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.serial_numbers_create_grid.hasTabToShow" class="tab" data-cy="tab-serial_numbers_create_grid">
              <h2 [className]="tabs.serial_numbers_create_grid.active? 'active': ''" (click)="tabs.serial_numbers_create_grid.activate()">{{tabs.serial_numbers_create_grid.title}}</h2>
            </div>
          </div>
        
              <Manufacturing-serialnumbers_creation_grid *ngIf="tabs.serial_numbers_create_grid.active"
              #$tabs_serial_numbers_create_grid
              [materialId]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_materialId"
              [packagingId]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_packagingId"
              [packagedAmount]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_packagedAmount"
              [averageGross]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageGross"
              [averageNet]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageNet"
              [weightUomId]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_weightUomId"
              [averageLength]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageLength"
              [averageWidth]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageWidth"
              [averageHeight]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_averageHeight"
              [dimensionUomId]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_dimensionUomId"
              [lotId]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_lotId"
              [licensePlateId]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_licensePlateId"
              [close_grid_on_confirm]="$tabs_serial_numbers_create_grid_serialnumbers_creation_grid_inParams_close_grid_on_confirm"
              (confirmSerials)="handle_serial_numbers_grid_confirm_event($event)"
              (cancelClicked)="handle_serial_numbers_grid_cancel_event($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_serial_numbers_create_grid')">
              </Manufacturing-serialnumbers_creation_grid>
        </div>
      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>