import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryCounts_ds_vendorlot_selector_batchedService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { projectId: number, vendorlotIds: number[], isExclude: boolean, storageCategoryRuleId?: number, statusId?: number, fullTextSearch?: string, $top?: number, $skip?: number }): Promise<{ result?: { LookupCode?: string, Id?: number, Description?: string, Status_Id?: number, Status_Name?: string, TemperatureCategoryRule_Id?: number, TemperatureCategoryRule_Name?: string, Material_LookupCode?: string }[], totalCount?: number }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.vendorlotIds)) {
      missingRequiredInParams.push('\'vendorlotIds\'');
    }
    if (isNil(inParams.isExclude)) {
      missingRequiredInParams.push('\'isExclude\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_vendorlot_selector_batched/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, vendorlotIds?: number[], isExclude?: boolean, storageCategoryRuleId?: number, statusId?: number, fullTextSearch?: string, $keys: number[] }): Promise<{ result?: { LookupCode?: string, Id?: number, Description?: string, Status_Id?: number, Status_Name?: string, TemperatureCategoryRule_Id?: number, TemperatureCategoryRule_Name?: string, Material_LookupCode?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_vendorlot_selector_batched/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      projectId: number, vendorlotIds: number[], isExclude: boolean, storageCategoryRuleId?: number, statusId?: number, fullTextSearch?: string    }) {
        if(isNil(inParams.projectId)) {
          return true; 
        }
        if(isNil(inParams.vendorlotIds)) {
          return true; 
        }
        if(isNil(inParams.isExclude)) {
          return true; 
        }
      return false;
    }
}
