import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_manufacturing_workshifts_grid_ComponentContextService } from './FootprintManager.manufacturing_workshifts_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_warehouses_dd_singleComponent } from './Manufacturing.warehouses_dd_single.component'


interface IFootprintManager_manufacturing_workshifts_gridComponentEntity {
  Id?: number, Code?: string, DurationMinutes?: number, Friday?: boolean, Monday?: boolean, Name?: string, Saturday?: boolean, ShiftStartOffsetMinutes?: number, Sunday?: boolean, Thursday?: boolean, Tuesday?: boolean, WarehouseId?: number, Wednesday?: boolean}

interface IFootprintManager_manufacturing_workshifts_gridComponentInParams {
  warehouse: number}


class FootprintManager_manufacturing_workshifts_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_manufacturing_workshifts_gridComponent;
  entity: IFootprintManager_manufacturing_workshifts_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    warehouse_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    name: null,
    start_time: null,
    end_time: null,
    duration: null,
    days: null,
    warehouse: null,
  }



  constructor(
    grid: FootprintManager_manufacturing_workshifts_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_manufacturing_workshifts_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.name = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.start_time = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.start_time,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.end_time = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.end_time,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.duration = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.duration,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.days = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.days,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.warehouse = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.warehouse,
      new SelectBoxModel(
  this.formGroup.controls['warehouse_display'] as DatexFormControl, 
  null, null,
  true,
  false,
  '', null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_manufacturing_workshifts_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_manufacturing_workshifts_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      warehouse_id:  $grid.inParams.warehouse ,
    };
    const data = await this.$datasources.Manufacturing.ds_get_manufacturing_workshifts_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_manufacturing_workshifts_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.name.displayControl as TextModel).text = $row.entity.Name;
    (this.cells.days.displayControl as TextModel).text = '';
    (this.cells.warehouse.displayControl as SelectBoxModel).reset($row.entity.WarehouseId);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      this.isNew = false;
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'name' : {
        this.on_name_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootprintManager_manufacturing_workshifts_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_workshifts_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_workshifts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_workshifts_grid.on_row_data_loaded');
  
  // Start time
  const {hours: hoursStartShift, minutes: minutesStartShift} = getMinutesToHoursAndMinutes(Math.abs($row.entity.ShiftStartOffsetMinutes));
  
  if( hoursStartShift < 10 )
  {
      $row.cells.start_time.displayControl.text = `0${hoursStartShift}:${minutesStartShift < 10 ? '0' : ''}${minutesStartShift}`;
  }
  else
  {
      $row.cells.start_time.displayControl.text = `${hoursStartShift}:${minutesStartShift < 10 ? '0' : ''}${minutesStartShift}`;
  }
  
  // End time
  const {hours: hoursEndShift, minutes: minutesEndShift} = getMinutesToHoursAndMinutes(Math.abs($row.entity.ShiftStartOffsetMinutes) + Math.abs($row.entity.DurationMinutes));
  
  if( hoursEndShift < 10 )
  {
      $row.cells.end_time.displayControl.text = `0${hoursEndShift}:${minutesEndShift < 10 ? '0' : ''}${minutesEndShift}`;
  }
  else
  {
      $row.cells.end_time.displayControl.text = `${hoursEndShift}:${minutesEndShift < 10 ? '0' : ''}${minutesEndShift}`;
  }
  
  // Duration
  const {hours: hoursDuration, minutes: minutesDuration} = getMinutesToHoursAndMinutes(Math.abs($row.entity.DurationMinutes));
  
  if( hoursDuration < 10 )
  {
      $row.cells.duration.displayControl.text = `0${hoursDuration}:${minutesDuration < 10 ? '0' : ''}${minutesDuration}`;
  }
  else
  {
      $row.cells.duration.displayControl.text = `${hoursDuration}:${minutesDuration < 10 ? '0' : ''}${minutesDuration}`;
  }
  
  // Days
  const mondayText = $row.entity.Monday ? 'Mon' : '';
  const tuesdayText = $row.entity.Tuesday ? 'Tue' : '';
  const wednesdayText = $row.entity.Wednesday ? 'Wed' : '';
  const thursdayText = $row.entity.Thursday ? 'Thu' : '';
  const fridayText = $row.entity.Friday ? 'Fri' : '';
  const saturdayText = $row.entity.Saturday ? 'Sat' : '';
  const sundayText = $row.entity.Sunday ? 'Sun' : '';
  
  $row.cells.days.displayControl.text += `${mondayText}`;
  $row.cells.days.displayControl.text += `${tuesdayText.length > 0 ? `${$row.cells.days.displayControl.text?.length > 0 ? `, ${tuesdayText}` : tuesdayText}` : ''}`;
  $row.cells.days.displayControl.text += `${wednesdayText.length > 0  ? `${$row.cells.days.displayControl.text?.length > 0 ? `, ${wednesdayText}` : wednesdayText}` : ''}`;
  $row.cells.days.displayControl.text += `${thursdayText.length > 0  ? `${$row.cells.days.displayControl.text?.length > 0 ? `, ${thursdayText}` : thursdayText}` : ''}`;
  $row.cells.days.displayControl.text += `${fridayText.length > 0  ? `${$row.cells.days.displayControl.text?.length > 0 ? `, ${fridayText}` : fridayText}` : ''}`;
  $row.cells.days.displayControl.text += `${saturdayText.length > 0  ? `${$row.cells.days.displayControl.text?.length > 0 ? `, ${saturdayText}` : saturdayText}` : ''}`;
  $row.cells.days.displayControl.text += `${sundayText.length > 0  ? `${$row.cells.days.displayControl.text?.length > 0 ? `, ${sundayText}` : sundayText}` : ''}`;
  
  // FUNCTIONS
  function getMinutesToHoursAndMinutes(totalMinutes)
  {
    let hours = Math.floor(totalMinutes / 60);
  
    while( hours >= 24 )
    {
      hours -= 24;
    }
  
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  }
  }
  on_name_clicked(event = null) {
    return this.on_name_clickedInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_name_clickedInternal(
    $row: FootprintManager_manufacturing_workshifts_gridComponentRowModel,
  $grid: FootprintManager_manufacturing_workshifts_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_workshifts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_workshifts_grid.on_name_clicked');
  await $shell.FootprintManager.openworkshift_editorDialog({
          workshift_id: $row.entity.Id,
          is_new: false
  }, "flyout", EModalSize.Large)
  
  $grid.refresh();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_warehouses_dd_singleComponent),
  ],
  selector: 'FootprintManager-manufacturing_workshifts_grid',
  templateUrl: './FootprintManager.manufacturing_workshifts_grid.component.html'
})
export class FootprintManager_manufacturing_workshifts_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_manufacturing_workshifts_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_manufacturing_workshifts_gridComponentInParams = { warehouse: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     name: new GridHeaderModel(new HeaderStyles(null, null), 'name', 'Name', false, false, null, false),       start_time: new GridHeaderModel(new HeaderStyles(null, null), 'start_time', 'Start time', false, false, null, false),       end_time: new GridHeaderModel(new HeaderStyles(null, null), 'end_time', 'End time', false, false, null, false),       duration: new GridHeaderModel(new HeaderStyles(null, null), 'duration', 'Duration', false, false, null, false),       days: new GridHeaderModel(new HeaderStyles(null, null), 'days', 'Days', false, false, null, false),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'warehouse', 'Warehouse', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_manufacturing_workshifts_gridComponentRowModel[] = [];

  @Input('warehouse') set $inParams_warehouse(value: any) {
    this.inParams['warehouse'] = value;
  }
  get $inParams_warehouse(): any {
    return this.inParams['warehouse'] ;
  }

  topToolbar = {
      new_shift: new ToolModel(new ButtonModel('new_shift', new ButtonStyles(null, null), false, false, false, 'New shift', 'icon-ic_fluent_add_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      delete_shifts: new ToolModel(new ButtonModel('delete_shifts', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_manufacturing_workshifts_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Workshifts';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouse)) {
        this.$missingRequiredInParams.push('warehouse');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      warehouse_id:  $grid.inParams.warehouse ,
    };
    try {
      const data = await this.$datasources.Manufacturing.ds_get_manufacturing_workshifts_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_manufacturing_workshifts_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_manufacturing_workshifts_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_manufacturing_workshifts_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintManager_manufacturing_workshifts_gridComponentEntity) {
    const row = new FootprintManager_manufacturing_workshifts_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootprintManager_manufacturing_workshifts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_workshifts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_workshifts_grid.on_grid_data_loaded');
  $grid.bottomToolbar.addLine.hidden = true;
  $grid.canEdit = true;
  $grid.topToolbar.delete_shifts.control.readOnly = false;
  $grid.topToolbar.new_shift.control.readOnly = false;
  }
  on_delete_click(event = null) {
    return this.on_delete_clickInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickInternal(
    $grid: FootprintManager_manufacturing_workshifts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_workshifts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_workshifts_grid.on_delete_click');
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Utilities.openErrorDialog('Workshifts Delete Error', 'No workshifts selected.');
      return;
  }
  
  const candidates = [];
  for (const row of selectedRows) {
      candidates.push(row);
  }
  
  const confirmCandidates = `${candidates.map(c => c.entity.Name.toString()).join(', ')}`
  
  const title = `Delete workshift${candidates.length > 1 ? 's' : ''} ${confirmCandidates}?`;
  const message = `Once deleted, the workshift${candidates.length > 1 ? 's' : ''} can not be restored`;
  let confirmDeletion = await $shell.Utilities.openConfirmationDialog(title, message, `Delete workshift${candidates.length > 1 ? 's' : ''}`);
  
  if (confirmDeletion) {
      const processSuccess = [];
      const processFailures = [];
      const errorMsgList = [];
      const errorMsgListDetails = [];
  
      for (const candidate of candidates) {
          try {
              const flowParams = {
                  workshift_id: candidate.entity.Id
              }
              const result = await $flows.Manufacturing.delete_manufacturing_workshifts_flow(flowParams);
              const reason = result.reason;
              if ($utils.isDefined(reason)) {
                  processFailures.push(candidate);
                  errorMsgList.push(`Workshift ${candidate.entity.Name} - ${reason}`);
              } else {
                  processSuccess.push(candidate);
              }
  
          } catch (error) {
              processFailures.push(candidate);
              const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
              const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
              const errorDescription = `Workshift ${candidate.entity.Name.toString()} - ${errorMessage}`;
              errorMsgList.push(errorDescription);
              errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
          }
      }
  
      // all succeeded
      if (processSuccess.length === candidates.length) {
          const title = 'All workshift(s) deleted';
          const message = `Workshift(s) ${processSuccess.map(c => c.entity.Name.toString()).join(',')} deleted`;
          $shell.Utilities.openToaster(title, message, EToasterType.Success, {timeOut: 5000, positionClass: EToasterPosition.bottomRight, progressBar: false, tapToDismiss: true });
          await $grid.refresh();
      } else {
          // all failures
          if (processFailures.length === candidates.length) {
              const title = 'All workshift(s) failed to delete';
              const message = `Workshift(s) ${processFailures.map(c => c.entity.Name.toString()).join(',')} could not be deleted`;
              await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
          } else {
              const title = 'Some workshifts could not be deleted';
              const success = `Workshift(s) ${processSuccess.map(c => c.entity.Name.toString()).join(',')} were deleted.`;
              const errors = `The following workshift(s) could not be deleted: ${processFailures.map(c => c.entity.Name.toString()).join(',')}`;
              const message = `${success} \r\n\r\n${errors}`;
              await $shell.Utilities.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
              await $grid.refresh();
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_manufacturing_workshifts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_workshifts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_workshifts_grid.on_row_selected');
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected and order status is created
  // TODO: Add authorization check
  if (selectedRowsCount > 0 /*&& !(await $operations.FootPrintManager.Disable_SalesOrder_Editing.isAuthorized())*/) {
      $grid.topToolbar.delete_shifts.control.readOnly = false;
  }
  else {
      $grid.topToolbar.delete_shifts.control.readOnly = true;
  }
  }
  on_new_shift(event = null) {
    return this.on_new_shiftInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_shiftInternal(
    $grid: FootprintManager_manufacturing_workshifts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_manufacturing_workshifts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'manufacturing_workshifts_grid.on_new_shift');
  
  try {
      const workshiftCreate = (await $flows.Utilities.crud_create_flow({
      entitySet: "Workshifts",
      entity:
      {
          Code: "",
          Name: "",
          ShiftStartOffsetMinutes: 0,
          WarehouseId: $grid.inParams.warehouse,
          DurationMinutes: 0,
          Monday: false,
          Tuesday: false,
          Wednesday: false,
          Thursday: false,
          Friday: false,
          Saturday: false,
          Sunday: false,
      }}));
  
      const result = await $shell.FootprintManager.openworkshift_editorDialog({
          workshift_id: workshiftCreate.result.Id,
          is_new: true
      }, "flyout", EModalSize.Large)
  
      $grid.refresh();
  }
  catch (error) {
      // Get inner-most error message
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) {
          targetError = targetError.error;
      }
  
      let message = targetError.message;
  
      if ($utils.isDefined(message) )
      {
          $shell.Utilities.openErrorDialog('Error creating workshift', message);
          throw new Error(`Error creating workshift - ${message}`);
      }
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
