import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Utilities_ShellService } from './Utilities.shell.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { Utilities_schedule_frequency_form_ComponentContextService } from './Utilities.schedule_frequency_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Utilities.frontend.types'
import { $frontendTypes as $types} from './Utilities.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Utilities_frequency_interval_dd_singleComponent } from './Utilities.frequency_interval_dd_single.component'
import { Utilities_weekly_frequency_dd_multiComponent } from './Utilities.weekly_frequency_dd_multi.component'
import { Utilities_monthly_frequency_dd_multiComponent } from './Utilities.monthly_frequency_dd_multi.component'
import { Utilities_time_frequency_dd_singleComponent } from './Utilities.time_frequency_dd_single.component'
import { Utilities_time_selector_dd_singleComponent } from './Utilities.time_selector_dd_single.component'
import { Utilities_time_meridiem_dd_singleComponent } from './Utilities.time_meridiem_dd_single.component'
import { Utilities_time_interval_dd_singleComponent } from './Utilities.time_interval_dd_single.component'
import { Utilities_time_zone_dd_singleComponent } from './Utilities.time_zone_dd_single.component'
import { Utilities_country_time_zone_dd_singleComponent } from './Utilities.country_time_zone_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Utilities_frequency_interval_dd_singleComponent),
    forwardRef(() => Utilities_weekly_frequency_dd_multiComponent),
    forwardRef(() => Utilities_monthly_frequency_dd_multiComponent),
    forwardRef(() => Utilities_time_frequency_dd_singleComponent),
    forwardRef(() => Utilities_time_selector_dd_singleComponent),
    forwardRef(() => Utilities_time_meridiem_dd_singleComponent),
    forwardRef(() => Utilities_time_interval_dd_singleComponent),
    forwardRef(() => Utilities_time_zone_dd_singleComponent),
    forwardRef(() => Utilities_country_time_zone_dd_singleComponent),
  ],
  selector: 'Utilities-schedule_frequency_form',
  templateUrl: './Utilities.schedule_frequency_form.component.html'
})
export class Utilities_schedule_frequency_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { schedule?: { date_option?: string, date_interval?: any[], time_option?: string, time_interval?: any, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } }, minimum_minutes?: number, display_start_end_dates?: boolean, start_date?: string, end_date?: string } = { schedule: { date_option: null, date_interval: [], time_option: null, time_interval: null, time_offset: null, time_zone: { country: null, utc: null, time_zone: null } }, minimum_minutes: null, display_start_end_dates: null, start_date: null, end_date: null };
  //#region Inputs
  @Input('schedule') set $inParams_schedule(v: { date_option?: string, date_interval?: any[], time_option?: string, time_interval?: any, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } }) {
    this.inParams.schedule = v;
  }
  get $inParams_schedule(): { date_option?: string, date_interval?: any[], time_option?: string, time_interval?: any, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } } {
    return this.inParams.schedule;
  }
  @Input('minimum_minutes') set $inParams_minimum_minutes(v: number) {
    this.inParams.minimum_minutes = v;
  }
  get $inParams_minimum_minutes(): number {
    return this.inParams.minimum_minutes;
  }
  @Input('display_start_end_dates') set $inParams_display_start_end_dates(v: boolean) {
    this.inParams.display_start_end_dates = v;
  }
  get $inParams_display_start_end_dates(): boolean {
    return this.inParams.display_start_end_dates;
  }
  @Input('start_date') set $inParams_start_date(v: string) {
    this.inParams.start_date = v;
  }
  get $inParams_start_date(): string {
    return this.inParams.start_date;
  }
  @Input('end_date') set $inParams_end_date(v: string) {
    this.inParams.end_date = v;
  }
  get $inParams_end_date(): string {
    return this.inParams.end_date;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { schedule?: { date_option?: string, date_interval?: string[], time_option?: string, time_interval?: string, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } }, cron_expression?: string, start_date?: string, end_date?: string } = { schedule: { date_option: null, date_interval: [], time_option: null, time_interval: null, time_offset: null, time_zone: { country: null, utc: null, time_zone: null } }, cron_expression: null, start_date: null, end_date: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ schedule?: { date_option?: string, date_interval?: string[], time_option?: string, time_interval?: string, time_offset?: number, time_zone?: { country?: string, utc?: string, time_zone?: string } }, cron_expression?: string, start_date?: string, end_date?: string }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    frequency_interval: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    start_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    end_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    number_of_days: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    weekly_days_of_week: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    monthly_day_of_month: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    time_frequency: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_selector: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_meridiem: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_interval: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_interval_options: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    time_zone: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    country_time_zone_dd: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, false, false, 'Confirm', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    frequency_interval: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['frequency_interval'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Frequency', true, false),
    start_date: new FieldModel(new DateBoxModel(this.formGroup.controls['start_date'] as DatexFormControl, null, false, false, 'MM/DD/YYYY', 'date', () => { const $utils = this.$utils; const $form = this; return `Date schedule will start executing.`; })
, new ControlContainerStyles(null, null), 'Start date', false, false),
    end_date: new FieldModel(new DateBoxModel(this.formGroup.controls['end_date'] as DatexFormControl, null, false, false, 'MM/DD/YYYY', 'date', () => { const $utils = this.$utils; const $form = this; return `Date schedule will last execute.`; })
, new ControlContainerStyles(null, null), 'End date', false, false),
    number_of_days: new FieldModel(new TextBoxModel(this.formGroup.controls['number_of_days'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Repeat every (Days)', true, false),
    weekly_days_of_week: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weekly_days_of_week'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Days of week', true, false),
    monthly_day_of_month: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['monthly_day_of_month'] as DatexFormControl, 
  null, null,
  false,
  false,
  '', () => { const $utils = this.$utils; const $form = this; return 'If the month doesn’t have 31 days (for example, February, April, June, September, etc.), the scheleded job will simply not run that month.'; })
, new ControlContainerStyles(null, null), 'Days of month', true, false),
    time_frequency: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_frequency'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), '', false, false),
    time_selector: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_selector'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), '', false, false),
    time_meridiem: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_meridiem'] as DatexFormControl, 
  ESelectBoxType.chips, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), '', false, false),
    time_interval: new FieldModel(new NumberBoxModel(this.formGroup.controls['time_interval'] as DatexFormControl, null, false, false, '0', '', null)
, new ControlContainerStyles(null, null), '', false, false),
    time_interval_options: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_interval_options'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), '', false, false),
    time_zone: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['time_zone'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Time zone', true, false),
    country_time_zone_dd: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['country_time_zone_dd'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Country', true, false),
  };

  fieldsets = {
    header: new FieldsetModel(
      'Header',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    daily_frequency: new FieldsetModel(
      'Daily frequency',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    weekly_frequency: new FieldsetModel(
      'Weekly frequency',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    monthly_frequency: new FieldsetModel(
      'Monthly frequency',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
    time_frequency: new FieldsetModel(
      'Time frequency',
      false,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_time_selector_selector_inParams_intervalMinutes(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 5;
    
    return expr;
  }

  get $fields_time_zone_selector_inParams_country(): string {
    const $form = this;
    const $utils = this.$utils;
    const expr = $utils.isDefined($form.fields.country_time_zone_dd.control.value) ? $form.fields.country_time_zone_dd.control.value.country : null;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    frequency_interval: this.fields.frequency_interval.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    start_date: this.fields.start_date.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    end_date: this.fields.end_date.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    number_of_days: this.fields.number_of_days.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    weekly_days_of_week: this.fields.weekly_days_of_week.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    monthly_day_of_month: this.fields.monthly_day_of_month.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    time_frequency: this.fields.time_frequency.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    time_selector: this.fields.time_selector.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    time_meridiem: this.fields.time_meridiem.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    time_interval: this.fields.time_interval.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    time_interval_options: this.fields.time_interval_options.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    time_zone: this.fields.time_zone.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    country_time_zone_dd: this.fields.country_time_zone_dd.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Utilities_ShellService,
private $datasources: Utilities_DatasourceService,
private $flows: Utilities_FlowService,
private $reports: Utilities_ReportService,
private $localization: Utilities_LocalizationService,
private $operations: Utilities_OperationService,
private $logger: CleanupLoggerService,
private $context: Utilities_schedule_frequency_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Frequency';
  
    const $form = this;
    const $utils = this.$utils;

    (this.fields.start_date.control as DateBoxModel).reset($utils.isDefined($form.inParams.start_date) ? $form.inParams.start_date : null);
    (this.fields.end_date.control as DateBoxModel).reset($utils.isDefined($form.inParams.end_date) ? $form.inParams.end_date : null);
    (this.fields.number_of_days.control as TextBoxModel).reset('1');
    (this.fields.time_frequency.control as SelectBoxModel).reset('Occurs at');
    (this.fields.time_selector.control as SelectBoxModel).reset('06:00');
    (this.fields.time_meridiem.control as SelectBoxModel).reset('AM');
    (this.fields.time_interval.control as NumberBoxModel).reset($utils.isDefined($form.inParams.minimum_minutes) ? $form.inParams.minimum_minutes : 5);
    (this.fields.time_interval_options.control as SelectBoxModel).reset('Minutes');

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .frequency_interval
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_frequency_changed();
      });
    this.$formGroupFieldValidationObservables
      .start_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .end_date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .number_of_days
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_time_interval_changed();
      });
    this.$formGroupFieldValidationObservables
      .weekly_days_of_week
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .monthly_day_of_month
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .time_frequency
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_time_frequency_changed();
      });
    this.$formGroupFieldValidationObservables
      .time_selector
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .time_meridiem
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .time_interval
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_time_interval_changed();
      });
    this.$formGroupFieldValidationObservables
      .time_interval_options
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_time_interval_changed();
      });
    this.$formGroupFieldValidationObservables
      .time_zone
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_time_zone_changed();
      });
    this.$formGroupFieldValidationObservables
      .country_time_zone_dd
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_country_time_zone_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_frequency_changed(event = null) {
    return this.on_frequency_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_frequency_changedInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  await $form.set_state();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  
  $form.outParams.schedule = $form.inParams.schedule;
  
  if ($utils.isDefined($form.inParams.schedule)) {
      
      $form.fields.frequency_interval.control.value = $form.inParams.schedule.date_option;
  
      switch ($form.fields.frequency_interval.control.value) {
          case 'DAILY': {
              $form.fields.number_of_days.control.value = $form.inParams.schedule.date_interval;
              break;
          }
          case 'WEEKLY': {
              $form.fields.weekly_days_of_week.control.value = $form.inParams.schedule.date_interval;
              break;
          }
          case 'MONTHLY': {
              $form.fields.monthly_day_of_month.control.value = $form.inParams.schedule.date_interval;
              break;
          }
      }
  
      $form.fields.time_frequency.control.value = $form.inParams.schedule.time_option;
  
      switch ($form.fields.time_frequency.control.value.trim().toUpperCase()) {
          case 'OCCURS AT': {
              $form.fields.time_selector.control.value = $form.inParams.schedule.time_interval.split(' ')[0];
              $form.fields.time_meridiem.control.value = $form.inParams.schedule.time_interval.split(' ')[1];
              break;
          }
          case 'OCCURS EVERY': {
              $form.fields.time_interval.control.value = $form.inParams.schedule.time_interval.split(' ')[0];
              $form.fields.time_interval_options.control.value = $form.inParams.schedule.time_interval.split(' ')[1];
              break;
          }
      }
      $form.fields.time_zone.control.value = {country: $form.inParams.schedule.time_zone.country, UTC: $form.inParams.schedule.time_zone.utc, time_zone: $form.inParams.schedule.time_zone.time_zone }
      $form.fields.country_time_zone_dd.control.value = $form.fields.time_zone.control.value;
  }
  
  await $form.set_state();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  let option: string[];
  switch ($form.fields.frequency_interval.control.value) {
      case 'DAILY': {
          option = ensureSingleBrackets([$form.fields.number_of_days.control.value]);
          break;
      }
      case 'WEEKLY': {
          let weekDaySortOrder = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
  
          option = $form.fields.weekly_days_of_week.control.value;
  
          option.sort((a, b) => weekDaySortOrder.indexOf(a) - weekDaySortOrder.indexOf(b));
  
          break;
      }
      case 'MONTHLY': {
          option = $form.fields.monthly_day_of_month.control.value
          break;
      }
      default: {
          break;
      }
  }
  
  if ($utils.isDefined(option)) {
      let country = $utils.isDefined($form.fields.time_zone.control.value) ? $form.fields.time_zone.control.value.country : 'United States'
      let utc = $utils.isDefined($form.fields.time_zone.control.value) ? $form.fields.time_zone.control.value.UTC : '-5:00'
      let timeZone = $utils.isDefined($form.fields.time_zone.control.value) ? $form.fields.time_zone.control.value.time_zone : 'Eastern Standard Time (EST)'
  
      $form.outParams.schedule = {
          date_option: $form.fields.frequency_interval.control.value,
          date_interval: option,
          time_option: $form.fields.time_frequency.control.value,
          time_interval: $form.fields.time_frequency.control.value.trim().toUpperCase() === 'OCCURS AT' ? `${$form.fields.time_selector.control.value} ${$form.fields.time_meridiem.control.value}` :
              $form.fields.time_frequency.control.value.trim().toUpperCase() === 'OCCURS EVERY' ? `${$form.fields.time_interval.control.value} ${$form.fields.time_interval_options.control.value}` : '',
          time_offset: new Date().getTimezoneOffset(),
          time_zone: {
              country: $form.fields.time_frequency.control.value.trim().toUpperCase() === 'OCCURS EVERY' ? country : $form.fields.time_zone.control.value.country,
              utc: $form.fields.time_frequency.control.value.trim().toUpperCase() === 'OCCURS EVERY' ? utc : $form.fields.time_zone.control.value.UTC,
              time_zone: $form.fields.time_frequency.control.value.trim().toUpperCase() === 'OCCURS EVERY' ? timeZone : $form.fields.time_zone.control.value.time_zone
          }
      };
      $form.outParams.cron_expression = (await $flows.Utilities.convert_to_cron({ schedule: $form.outParams.schedule })).cron_expression
  
      if($utils.isDefined($form.fields.start_date.control.value)){
          $form.outParams.start_date = $form.fields.start_date.control.value
      }
      if($utils.isDefined($form.fields.end_date.control.value)){
          $form.outParams.end_date = $form.fields.end_date.control.value
      }
      
  }
  
  $form.close();
  
  
  function ensureSingleBrackets(input: any): any[] {
      const flatten = (arr: any): any[] => {
          return arr.reduce((acc, val) => {
              if (Array.isArray(val)) {
                  acc.push(...flatten(val));
              } else {
                  acc.push(val);
              }
              return acc;
          }, []);
      };
  
      const flattened = flatten(input);
  
      return flattened.length === 1 ? [flattened[0]] : flattened;
  }
  
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  set_state(event = null) {
    return this.set_stateInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_stateInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  if($form.inParams.display_start_end_dates){
      $form.fields.start_date.hidden = false; 
      $form.fields.end_date.hidden = false;
  } else {
      $form.fields.start_date.hidden = true; 
      $form.fields.end_date.hidden = true;
  }
  
  switch ($form.fields.frequency_interval.control.value) {
      case 'DAILY': {
          $form.fieldsets.daily_frequency.hidden = false;
          $form.fieldsets.weekly_frequency.hidden = true;
          $form.fieldsets.monthly_frequency.hidden = true;
  
          $form.fields.time_frequency.hidden = false;
          $form.fields.time_selector.hidden = false;
          $form.fields.time_meridiem.hidden = false;
          $form.fields.time_interval.hidden = false;
          $form.fields.time_interval_options.hidden = false;
          $form.fields.country_time_zone_dd.hidden = false; 
          $form.fields.time_zone.hidden = false;
  
          await $form.on_time_frequency_changed();
  
          break;
      }
      case 'WEEKLY': {
          $form.fieldsets.daily_frequency.hidden = true;
          $form.fieldsets.weekly_frequency.hidden = false;
          $form.fieldsets.monthly_frequency.hidden = true;
  
          $form.fields.time_frequency.control.value = 'Occurs at';
          $form.fields.time_frequency.hidden = true;
          $form.fields.time_selector.hidden = false;
          $form.fields.time_meridiem.hidden = false;
          $form.fields.time_interval.hidden = true;
          $form.fields.time_interval_options.hidden = true;
  
          $form.fields.country_time_zone_dd.hidden = false; 
          $form.fields.time_zone.hidden = false;
          break;
      }
      case 'MONTHLY': {
          $form.fieldsets.daily_frequency.hidden = true;
          $form.fieldsets.weekly_frequency.hidden = true;
          $form.fieldsets.monthly_frequency.hidden = false;
  
          $form.fields.time_frequency.control.value = 'Occurs at';
          $form.fields.time_frequency.hidden = true;
          $form.fields.time_selector.hidden = false;
          $form.fields.time_meridiem.hidden = false;
          $form.fields.time_interval.hidden = true;
          $form.fields.time_interval_options.hidden = true;
          $form.fields.country_time_zone_dd.hidden = false; 
          $form.fields.time_zone.hidden = false;
  
          break;
      }
      default: {
          $form.fieldsets.daily_frequency.hidden = true;
          $form.fieldsets.weekly_frequency.hidden = true;
          $form.fieldsets.monthly_frequency.hidden = true;
  
          $form.fields.time_frequency.hidden = true;
          $form.fields.time_selector.hidden = true;
          $form.fields.time_meridiem.hidden = true;
          $form.fields.time_interval.hidden = true;
          $form.fields.time_interval_options.hidden = true;
          $form.fields.country_time_zone_dd.hidden = true; 
          $form.fields.time_zone.hidden = true;
  
          break;
      }
  }
  }
  on_time_frequency_changed(event = null) {
    return this.on_time_frequency_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_time_frequency_changedInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  switch ($form.fields.time_frequency.control.value.trim().toUpperCase()) {
      case 'OCCURS AT': {
          $form.fields.time_interval.hidden = true;
          $form.fields.time_interval_options.hidden = true;
          $form.fields.time_selector.hidden = false;
          $form.fields.time_meridiem.hidden = false;
          
          $form.fields.country_time_zone_dd.hidden = false;
          $form.fields.time_zone.hidden = false;
          
          break;
      }
      case 'OCCURS EVERY': {
          $form.fields.time_interval.hidden = false;
          $form.fields.time_interval_options.hidden = false;
          $form.fields.time_selector.hidden = true;
          $form.fields.time_meridiem.hidden = true;
  
          $form.fields.country_time_zone_dd.hidden = true;
          $form.fields.time_zone.hidden = true;
  
          break;
      }
  }
  }
  on_time_interval_changed(event = null) {
    return this.on_time_interval_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_time_interval_changedInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  let time_interval
  let day_interval
  let minimumMinutes = 5
  
  if ($utils.isDefined($form.inParams.minimum_minutes)) {
      minimumMinutes = $form.inParams.minimum_minutes
  }
  
  if($form.fields.time_interval_options.control.value == 'Hours'){
      minimumMinutes = Math.ceil(minimumMinutes/60)
  }
  
  time_interval = toIntegerRoundDownPositive($form.fields.time_interval.control.value)
  
  if (time_interval < minimumMinutes) {
      $form.fields.time_interval.control.value = minimumMinutes;
  } else {
      $form.fields.time_interval.control.value = time_interval
  }
  
  
  day_interval = toIntegerRoundDownPositive($form.fields.number_of_days.control.value)
  
  $form.fields.number_of_days.control.value = day_interval
  
  
  
  
  
  
  function toIntegerRoundDownPositive(decimal: number | string): number {
      // Ensure the input is a valid number, whether it's a number or a string representation of a number
      const num = typeof decimal === "string" ? parseFloat(decimal) : decimal;
  
      if (isNaN(num)) {
          throw new Error("Input must be a valid number.");
      }
  
      // Remove the minus sign and round down
      return Math.floor(Math.abs(num));
  }
  }
  on_country_time_zone_changed(event = null) {
    return this.on_country_time_zone_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_country_time_zone_changedInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  $form.fields.time_zone.control.value = null;
  }
  on_time_zone_changed(event = null) {
    return this.on_time_zone_changedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_time_zone_changedInternal(
    $form: Utilities_schedule_frequency_formComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    $context: Utilities_schedule_frequency_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  //alert(JSON.stringify($form.fields.time_zone.control.value))
  
  if (!$utils.isDefined($form.fields.country_time_zone_dd.control.value)) {
      $form.fields.country_time_zone_dd.control.value = $form.fields.time_zone.control.value
  }
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form(fieldErrors: { frequency_interval: Pick<string[], 'push'>,start_date: Pick<string[], 'push'>,end_date: Pick<string[], 'push'>,number_of_days: Pick<string[], 'push'>,weekly_days_of_week: Pick<string[], 'push'>,monthly_day_of_month: Pick<string[], 'push'>,time_frequency: Pick<string[], 'push'>,time_selector: Pick<string[], 'push'>,time_meridiem: Pick<string[], 'push'>,time_interval: Pick<string[], 'push'>,time_interval_options: Pick<string[], 'push'>,time_zone: Pick<string[], 'push'>,country_time_zone_dd: Pick<string[], 'push'>, } = null) {
    const emptyResult = { frequency_interval:[],start_date:[],end_date:[],number_of_days:[],weekly_days_of_week:[],monthly_day_of_month:[],time_frequency:[],time_selector:[],time_meridiem:[],time_interval:[],time_interval_options:[],time_zone:[],country_time_zone_dd:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async validate_formInternal(
    $form: Utilities_schedule_frequency_formComponent,
    $validation:{
      fieldErrors: { frequency_interval: Pick<string[], 'push'>,start_date: Pick<string[], 'push'>,end_date: Pick<string[], 'push'>,number_of_days: Pick<string[], 'push'>,weekly_days_of_week: Pick<string[], 'push'>,monthly_day_of_month: Pick<string[], 'push'>,time_frequency: Pick<string[], 'push'>,time_selector: Pick<string[], 'push'>,time_meridiem: Pick<string[], 'push'>,time_interval: Pick<string[], 'push'>,time_interval_options: Pick<string[], 'push'>,time_zone: Pick<string[], 'push'>,country_time_zone_dd: Pick<string[], 'push'>, }
    },
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $context: Utilities_schedule_frequency_form_ComponentContextService
  ) {
    setToolbarVisibility();
  
  
  async function setToolbarVisibility() {
      await new Promise(result => setTimeout(result, 100));
      let isReadOnly = false;
      if (!$utils.isDefined($form.fields.frequency_interval.control.value)) {
          isReadOnly = true;
      }
  
      if ($form.fieldsets.daily_frequency.hidden && $form.fieldsets.weekly_frequency.hidden && $form.fields.monthly_day_of_month.hidden) {
          isReadOnly = true;
      }
  
      if (!$form.fieldsets.daily_frequency.hidden && !$utils.isDefined($form.fields.number_of_days.control.value)) {
          isReadOnly = true;
      }
  
      if (!$form.fieldsets.weekly_frequency.hidden && !$utils.isDefined($form.fields.weekly_days_of_week.control.value)) {
          isReadOnly = true;
      }
  
      if (!$form.fieldsets.monthly_frequency.hidden && !$utils.isDefined($form.fields.monthly_day_of_month.control.value)) {
          isReadOnly = true;
      }
      
      if(!$utils.isDefined($form.fields.time_zone.control.value) && !$form.fields.time_zone.hidden){
          isReadOnly = true;
      }
  
      if(!$utils.isDefined($form.fields.country_time_zone_dd.control.value) && !$form.fields.country_time_zone_dd.hidden){
          isReadOnly = true;
      }
  
      $form.toolbar.confirm.control.readOnly = isReadOnly;
  }
    return $validation.fieldErrors as { frequency_interval:[],start_date:[],end_date:[],number_of_days:[],weekly_days_of_week:[],monthly_day_of_month:[],time_frequency:[],time_selector:[],time_meridiem:[],time_interval:[],time_interval_options:[],time_zone:[],country_time_zone_dd:[], };
  }
  //#endregion validation flows
}
