import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_ownersaccounts_grid_ComponentContextService } from './FootprintManager.ownersaccounts_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Addresses_contact_types_dd_singleComponent } from './Addresses.contact_types_dd_single.component'


interface IFootprintManager_ownersaccounts_gridComponentEntity {
  OwnerId?: number, AccountId?: number, Account?: { Id?: number, LookupCode?: string, Name?: string, TypeId?: number, AccountsContactsLookup?: { AccountId?: number, ContactId?: number, Contact?: { AddressId?: number, FirstName?: string, LastName?: string, Notes?: string, PrimaryEmail?: string, PrimaryTelephone?: string, TypeId?: number, Type?: { Name?: string }, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }}

interface IFootprintManager_ownersaccounts_gridComponentInParams {
  ownerId: number, accountTypeId: number, typeIds: number[], allowSelection: boolean}

interface IFootprintManager_ownersaccounts_gridComponentOutParams {
  accountId?: number, contactId?: number, addressId?: number, confirm?: boolean}

class FootprintManager_ownersaccounts_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_ownersaccounts_gridComponent;
  entity: IFootprintManager_ownersaccounts_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    account_lookupcode_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    account_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    first_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    last_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    line1_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    line2_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    city_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    state_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    postal_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    country_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    attention_of_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    primary_telephone_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    primary_email_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    type: null,
    account_lookupcode: null,
    account_name: null,
    first_name: null,
    last_name: null,
    line1: null,
    line2: null,
    city: null,
    state: null,
    postal_code: null,
    country: null,
    attention_of: null,
    primary_telephone: null,
    primary_email: null,
    notes: null,
    error: null,
  }

  cacheValueFor_$fields_type_selector_inParams_typeIds: number[];
  get $fields_type_selector_inParams_typeIds(): number[] {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.$utils;
    const $grid = this.grid;
    const expr = $grid.inParams.typeIds;
    
    if(!isEqual(this.cacheValueFor_$fields_type_selector_inParams_typeIds, expr)) {
      this.cacheValueFor_$fields_type_selector_inParams_typeIds = expr;
    }
    return this.cacheValueFor_$fields_type_selector_inParams_typeIds;
  }


  constructor(
    grid: FootprintManager_ownersaccounts_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.type = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.type,
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['type_edit'] as DatexFormControl, 
        null, null,
        false,
        false,
        '', null)
      );
    
    this.cells.account_lookupcode = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.account_lookupcode,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['account_lookupcode_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.account_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.account_name,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['account_name_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.first_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.first_name,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['first_name_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.last_name = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.last_name,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['last_name_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.line1 = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.line1,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['line1_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.line2 = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.line2,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['line2_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.city = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.city,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['city_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.state = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.state,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['state_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.postal_code = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.postal_code,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['postal_code_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.country = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.country,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['country_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.attention_of = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.attention_of,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['attention_of_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.primary_telephone = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.primary_telephone,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['primary_telephone_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.primary_email = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.primary_email,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['primary_email_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.notes = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.notes,
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, false, '', null)
      );
    
    this.cells.error = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.error,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_ownersaccounts_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.OwnerId,this.entity.AccountId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_ownersaccounts_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.OwnerId,this.entity.AccountId].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'OwnerId',this.entity.OwnerId);
    set($resultKey, 'AccountId',this.entity.AccountId);
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      typeIds:  $grid.inParams.typeIds ,
      accountTypeId:  $grid.inParams.accountTypeId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.$datasources.Addresses.ds_ownersaccounts_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_ownersaccounts_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.type.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Type?.Name;
    (this.cells.type.editControl as SelectBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.TypeId);
    (this.cells.account_lookupcode.displayControl as TextModel).text = $row.entity.Account?.LookupCode;
    (this.cells.account_lookupcode.editControl as TextBoxModel).reset($row.entity.Account?.LookupCode);
    (this.cells.account_name.displayControl as TextModel).text = $row.entity.Account?.Name;
    (this.cells.account_name.editControl as TextBoxModel).reset($row.entity.Account?.Name);
    (this.cells.first_name.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.FirstName;
    (this.cells.first_name.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.FirstName);
    (this.cells.last_name.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.LastName;
    (this.cells.last_name.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.LastName);
    (this.cells.line1.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Line1;
    (this.cells.line1.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Line1);
    (this.cells.line2.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Line2;
    (this.cells.line2.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Line2);
    (this.cells.city.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.City;
    (this.cells.city.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.City);
    (this.cells.state.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.State;
    (this.cells.state.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.State);
    (this.cells.postal_code.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.PostalCode;
    (this.cells.postal_code.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.PostalCode);
    (this.cells.country.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Country;
    (this.cells.country.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.Country);
    (this.cells.attention_of.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.AttentionOf;
    (this.cells.attention_of.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Address?.AttentionOf);
    (this.cells.primary_telephone.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.PrimaryTelephone;
    (this.cells.primary_telephone.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.PrimaryTelephone);
    (this.cells.primary_email.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.PrimaryEmail;
    (this.cells.primary_email.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.PrimaryEmail);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Account?.AccountsContactsLookup[0]?.Contact?.Notes);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootprintManager_ownersaccounts_gridComponentRowModel,
  $grid: FootprintManager_ownersaccounts_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  
  $row.entity = {
      OwnerId: $grid.inParams.ownerId
  };
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootprintManager_ownersaccounts_gridComponentRowModel,
  $grid: FootprintManager_ownersaccounts_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  // Update carrier service type
  let payload: any = {};
  let payloadAddress: any = {};
  let payloadAccount: any = {};
  
  /** Validate Duplicates ***/
  // Duplicate Address
  if ($grid.rows.find(r => r.entity.Account?.AccountsContactsLookup?.find(cl =>
      r !== $row
      && trim(cl?.Contact?.Address?.Line1?.trim().toUpperCase(), 128) === trim($row.cells.line1.editControl.value?.trim().toUpperCase(), 128)
      && trim(cl?.Contact?.Address?.Line2?.trim().toUpperCase(), 128) === trim($row.cells.line2.editControl.value?.trim().toUpperCase(), 128)
      && trim(cl?.Contact?.Address?.City?.trim().toUpperCase(), 64) === trim($row.cells.city.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.Contact?.Address?.State?.trim().toUpperCase(), 64) === trim($row.cells.state.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.Contact?.Address?.PostalCode?.trim().toUpperCase(), 64) === trim($row.cells.postal_code.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.Contact?.Address?.Country?.trim().toUpperCase(), 32) === trim($row.cells.country.editControl.value?.trim().toUpperCase(), 32)
  ))) {
      let message = `Entered address already exists.`;
      $shell.Addresses.openErrorDialog('Save', message);
      throw new Error(message);
  }
  
  // Duplicate Account
  if ($grid.rows.find(r => r !== $row && trim(r.entity.Account?.Name?.trim().toUpperCase(), 256) === trim($row.cells.account_name.editControl.value.trim().toUpperCase(), 256))) {
      let message = `Account with name '${$row.cells.account_name.editControl.value}' already exists.`;
      $shell.Addresses.openErrorDialog('Save', message);
      throw new Error(message);
  }
  
  // Address Validation
  let errors = (await $flows.Addresses.validate_address_flow({
      line1: $row.cells.line1.editControl.value,
      line2: $row.cells.line2.editControl.value,
      city: $row.cells.city.editControl.value,
      state: $row.cells.state.editControl.value,
      postalCode: $row.cells.postal_code.editControl.value,
      country: $row.cells.country.editControl.value
  })).errors;
  
  if ($utils.isDefined(errors)) {
      $shell.Addresses.openErrorDialog('Save', errors.join(' & '));
      throw new Error(errors.join(' & '));
  }
  
  if ($row.cells.account_lookupcode.editControl.isChanged) {
      payloadAccount.LookupCode = trim($row.cells.account_lookupcode.editControl.value, 128);
  }
  if ($row.cells.account_name.editControl.isChanged) {
      payloadAccount.Name = trim($row.cells.account_name.editControl.value, 256);
  }
  if ($row.cells.first_name.editControl.isChanged) {
      payload.FirstName = trim($row.cells.first_name.editControl.value, 32);
  }
  if ($row.cells.last_name.editControl.isChanged) {
      payload.LastName = trim($row.cells.last_name.editControl.value, 32);
  }
  if ($row.cells.primary_email.editControl.isChanged) {
      payload.PrimaryEmail = trim($row.cells.primary_email.editControl.value, 128);
  }
  if ($row.cells.primary_telephone.editControl.isChanged) {
      payload.PrimaryTelephone = trim($row.cells.primary_telephone.editControl.value, 50);
  }
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  
  
  if ($row.cells.line1.editControl.isChanged) {
      payloadAddress.Line1 = trim($row.cells.line1.editControl.value, 128);
  }
  if ($row.cells.line2.editControl.isChanged) {
      payloadAddress.Line2 = trim($row.cells.line2.editControl.value, 128);
  }
  if ($row.cells.attention_of.editControl.isChanged) {
      payloadAddress.AttentionOf = trim($row.cells.attention_of.editControl.value, 64);
  }
  if ($row.cells.city.editControl.isChanged) {
      payloadAddress.City = trim($row.cells.city.editControl.value, 64);
  }
  if ($row.cells.state.editControl.isChanged) {
      payloadAddress.State = trim($row.cells.state.editControl.value, 64);
  }
  if ($row.cells.postal_code.editControl.isChanged) {
      payloadAddress.PostalCode = trim($row.cells.postal_code.editControl.value, 64);
  }
  if ($row.cells.country.editControl.isChanged) {
      payloadAddress.Country = trim($row.cells.country.editControl.value, 32);
  }
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Contacts', id: $row.entity.Account.AccountsContactsLookup[0].ContactId, entity: payload });
      await $flows.Utilities.crud_update_flow({ entitySet: 'Addresses', id: $row.entity.Account.AccountsContactsLookup[0].Contact.AddressId, entity: payloadAddress });
      await $flows.Utilities.crud_update_flow({ entitySet: 'Accounts', id: $row.entity.AccountId, entity: payloadAccount });
      await $row.refresh();
  
  }
  
  catch (error) {
      $shell.Addresses.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  function trim(candidate: any, length: number) {
  
      if ((length ?? 0) > 0) {
          if ($utils.isDefined(candidate)) {
              if (typeof candidate === 'string') {
                  let string_candidate = candidate?.toString();
                  if (string_candidate.length > length) {
                      string_candidate = string_candidate.substring(0, length);
                      candidate = string_candidate;
                  }
              }
          }
      }
      return candidate;
  }
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootprintManager_ownersaccounts_gridComponentRowModel,
  $grid: FootprintManager_ownersaccounts_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $row.cells.type.editControl.value,
      $row.cells.first_name.editControl.value,
      $row.cells.last_name.editControl.value,
      $row.cells.line1.editControl.value
  );
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Addresses.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  /** Validations  ***/
  // Duplicate Address
  if ($grid.rows.find(r => r.entity.Account?.AccountsContactsLookup?.find(cl =>
      r !== $row
      && trim(cl?.Contact?.Address?.Line1?.trim().toUpperCase(), 128) === trim($row.cells.line1.editControl.value?.trim().toUpperCase(), 128)
      && trim(cl?.Contact?.Address?.Line2?.trim().toUpperCase(), 128) === trim($row.cells.line2.editControl.value?.trim().toUpperCase(), 128)
      && trim(cl?.Contact?.Address?.City?.trim().toUpperCase(), 64) === trim($row.cells.city.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.Contact?.Address?.State?.trim().toUpperCase(), 64) === trim($row.cells.state.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.Contact?.Address?.PostalCode?.trim().toUpperCase(), 64) === trim($row.cells.postal_code.editControl.value?.trim().toUpperCase(), 64)
      && trim(cl?.Contact?.Address?.Country?.trim().toUpperCase(), 32) === trim($row.cells.country.editControl.value?.trim().toUpperCase(), 32)
  ))) {
      let message = `Entered address already exists.`;
      $shell.Addresses.openErrorDialog('Save', message);
      throw new Error(message);
  }
  
  // Duplicate Account
  if ($grid.rows.find(r => r !== trim($row && r.entity.Account?.Name?.trim().toUpperCase(), 256) === trim($row.cells.account_name.editControl.value?.trim().toUpperCase(), 256))) {
      let message = `Account with name '${$row.cells.account_name.editControl.value}' already exists.`;
      $shell.Addresses.openErrorDialog('Save', message);
      throw new Error(message);
  }
  
  // Address Validation
  let errors = (await $flows.Addresses.validate_address_flow({
      line1: $row.cells.line1.editControl.value,
      line2: $row.cells.line2.editControl.value,
      city: $row.cells.city.editControl.value,
      state: $row.cells.state.editControl.value,
      postalCode: $row.cells.postal_code.editControl.value,
      country: $row.cells.country.editControl.value
  })).errors;
  
  if ($utils.isDefined(errors)) {
      $shell.Addresses.openErrorDialog('Save', errors.join(' & '));
      throw new Error(errors.join(' & '));
  }
  
  try {
  
      const nextAccountId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Account' })).nextId;
  
      // Set Account.LookupCode
      let accountLookupcode: string;
      if ($utils.isDefinedTrimmed($row.cells.account_lookupcode.editControl.value)) {
          accountLookupcode = String($row.cells.account_lookupcode.editControl.value).trim();
      }
      else {
          accountLookupcode = nextAccountId.toString()
  
          let duplicateCount = $grid.rows.filter(r => r.entity.Account?.LookupCode.trim() === accountLookupcode).length;
  
          if (duplicateCount > 0) {
              accountLookupcode += `_${duplicateCount + 1}`;
          }
      }
  
      // Account
      const payloadAccount = {
          "Id": nextAccountId,
          "EnterpriseId": 1,
          "TypeId": $grid.inParams.accountTypeId,
          "LookupCode": trim(accountLookupcode, 128),
          "Name": trim($row.cells.account_name.editControl.value ?? accountLookupcode, 256),
          "Active": true,
          "AllocationPriority": 0,
          "AllowReceiving": false,
          "AllowShipping": false,
          "AllowReturnsIn": false,
          "RequiresReceivingRma": false,
          "TaxScheduleId": 0,
          "RequiresShippingRma": false
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'Accounts', entity: payloadAccount });
  
  
      // Address
      const nextAddressId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Address' })).nextId;
      const payloadAddress = {
          "Id": nextAddressId,
          "Line1": trim($row.cells.line1.editControl.value, 128),
          "Line2": trim($row.cells.line2?.editControl.value, 128),
          "City": trim($row.cells.city?.editControl.value, 64),
          "State": trim($row.cells.state?.editControl.value, 64),
          "PostalCode": trim($row.cells.postal_code?.editControl.value, 64),
          "Country": trim($row.cells.country?.editControl.value, 32),
          "AttentionOf": trim($row.cells.attention_of?.editControl.value, 64),
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'Addresses', entity: payloadAddress });
  
  
      // Contact
      const nextContactId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Contact' })).nextId;
      const payloadContacts = {
          "Id": nextContactId,
          "OwnerEntity": "Account",
          "OwnerId": nextAccountId,
          "TypeId": $row.cells.type.editControl.value,
          "AddressId": nextAddressId,
          "FirstName": trim($row.cells.first_name.editControl.value, 32),
          "LastName": trim($row.cells.last_name.editControl.value, 32),
          "PrimaryEmail": trim($row.cells.primary_email?.editControl.value, 128),
          "PrimaryTelephone": trim($row.cells.primary_telephone?.editControl.value, 50),
          "Notes": $row.cells.notes?.editControl.value
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'Contacts', entity: payloadContacts });
  
      // OwnersAccounts
      const payload = {
          "OwnerId": $row.entity.OwnerId,
          "AccountId": nextAccountId,
          "IsDefaultAccount": false
      }
      await $flows.Utilities.crud_create_flow({ entitySet: 'OwnersAccountsLookup', entity: payload });
  
      $row.entity.AccountId = nextAccountId;
      await $row.refresh();
  
  } catch (error) {
      $shell.Addresses.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  function trim(candidate: any, length: number) {
  
      if ((length ?? 0) > 0) {
          if ($utils.isDefined(candidate)) {
              if (typeof candidate === 'string') {
                  let string_candidate = candidate?.toString();
                  if (string_candidate.length > length) {
                      string_candidate = string_candidate.substring(0, length);
                      candidate = string_candidate;
                  }
              }
          }
      }
      return candidate;
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Addresses_contact_types_dd_singleComponent),
  ],
  selector: 'FootprintManager-ownersaccounts_grid',
  templateUrl: './FootprintManager.ownersaccounts_grid.component.html'
})
export class FootprintManager_ownersaccounts_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_ownersaccounts_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootprintManager_ownersaccounts_gridComponentInParams = { ownerId: null, accountTypeId: null, typeIds: [], allowSelection: null };

  outParams: IFootprintManager_ownersaccounts_gridComponentOutParams = { accountId: null, contactId: null, addressId: null, confirm: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ accountId?: number, contactId?: number, addressId?: number, confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     type: new GridHeaderModel(new HeaderStyles(null, null), 'type', 'Type', true, false, null, false),       account_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'account_lookupcode', 'Account', false, false, null, false),       account_name: new GridHeaderModel(new HeaderStyles(null, null), 'account_name', 'Company name', false, false, null, false),       first_name: new GridHeaderModel(new HeaderStyles(null, null), 'first_name', 'First name', true, false, null, false),       last_name: new GridHeaderModel(new HeaderStyles(null, null), 'last_name', 'Last name', true, false, null, false),       line1: new GridHeaderModel(new HeaderStyles(null, null), 'line1', 'Line 1', true, false, null, false),       line2: new GridHeaderModel(new HeaderStyles(null, null), 'line2', 'Line 2', false, false, null, false),       city: new GridHeaderModel(new HeaderStyles(null, null), 'city', 'City', false, false, null, false),       state: new GridHeaderModel(new HeaderStyles(null, null), 'state', 'State', false, false, null, false),       postal_code: new GridHeaderModel(new HeaderStyles(null, null), 'postal_code', 'Postal code', false, false, null, false),       country: new GridHeaderModel(new HeaderStyles(null, null), 'country', 'Country', false, false, null, false),       attention_of: new GridHeaderModel(new HeaderStyles(null, null), 'attention_of', 'Attention of', false, false, null, false),       primary_telephone: new GridHeaderModel(new HeaderStyles(null, null), 'primary_telephone', 'Phone', false, false, null, false),       primary_email: new GridHeaderModel(new HeaderStyles(null, null), 'primary_email', 'Email', false, false, null, false),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'notes', 'Notes', false, false, null, false),       error: new GridHeaderModel(new HeaderStyles(null, null), 'error', 'Error', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_ownersaccounts_gridComponentRowModel[] = [];

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('accountTypeId') set $inParams_accountTypeId(value: any) {
    this.inParams['accountTypeId'] = value;
  }
  get $inParams_accountTypeId(): any {
    return this.inParams['accountTypeId'] ;
  }
  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }
  @Input('allowSelection') set $inParams_allowSelection(value: any) {
    this.inParams['allowSelection'] = value;
  }
  get $inParams_allowSelection(): any {
    return this.inParams['allowSelection'] ;
  }

  topToolbar = {
      select: new ToolModel(new ButtonModel('select', new ButtonStyles(['primary'], null), false, false, false, 'Select', 'ms-Icon ms-Icon--Completed', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      linecount: new ToolModel(new ButtonModel('linecount', new ButtonStyles(null, null), false, false, false, 'Line count', '', null)
    , false)
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, false, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Addresses';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.ownerId)) {
        this.$missingRequiredInParams.push('ownerId');
      }
      if(isNil(this.inParams.accountTypeId)) {
        this.$missingRequiredInParams.push('accountTypeId');
      }
      if(isNil(this.inParams.typeIds)) {
        this.$missingRequiredInParams.push('typeIds');
      }
      if(isNil(this.inParams.allowSelection)) {
        this.$missingRequiredInParams.push('allowSelection');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      typeIds:  $grid.inParams.typeIds ,
      accountTypeId:  $grid.inParams.accountTypeId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
      const data = await this.$datasources.Addresses.ds_ownersaccounts_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_ownersaccounts_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_ownersaccounts_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows: FootprintManager_ownersaccounts_gridComponentRowModel[] = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  async $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    await this.on_row_selected();
  }

  async addRow(entity?: IFootprintManager_ownersaccounts_gridComponentEntity) {
    const row = new FootprintManager_ownersaccounts_gridComponentRowModel(
      this,
      this.$utils,
      this.$settings,
      this.$shell, 
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$localization,
      this.$operations,
      this.$logger,
      this.$context);
    await row.$initializeNew(entity);
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootprintManager_ownersaccounts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Addresses.openErrorDialog('Address Delete Error', 'No Address selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              addressId: row.entity.Account.AccountsContactsLookup[0].Contact.AddressId
          };
          const result = await $flows.Addresses.is_address_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Address ${row.entity.Account.AccountsContactsLookup[0].Contact.FirstName} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete Address errors';
          const errorMessage = `Address ${failures.map(c => c.entity.Account?.AccountsContactsLookup[0]?.Contact?.FirstName).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.Addresses.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Address ${candidates.map(c => c.entity.Account?.AccountsContactsLookup[0]?.Contact?.FirstName).join(',')} - Once deleted, the Address cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some Addresses cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.Addresses.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following Addresses';
              const message = confirmCandidates;
              confirm = await $shell.Addresses.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams =  {
                      addressId: candidate.entity.Account.AccountsContactsLookup[0].Contact.AddressId,
                      contactId: candidate.entity.Account.AccountsContactsLookup[0].ContactId,
                      accountId: candidate.entity.AccountId,
                      ownerId: candidate.entity.OwnerId
                      }
                      const result = await $flows.Addresses.delete_owneraccount_flow(flowParams);
                    
                    processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Address ${candidate.entity.Account.AccountsContactsLookup[0].Contact.FirstName} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All the selected Addresses deleted';
                  const message = `Addresses ${processSuccess.map(c => c.entity.Account.AccountsContactsLookup[0].Contact.FirstName).join(',')} deleted`;
                  await $shell.Addresses.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All the selected Addresses failed to delete';
                      const message = `Addresses ${processFailures.map(c => c.entity.Account.AccountsContactsLookup[0].Contact.FirstName).join(',')} could not be deleted`;
                      await $shell.Addresses.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some Addresses could not be deleted';
                      const deleted = `Addresses ${processSuccess.map(c => c.entity.Account.AccountsContactsLookup[0].Contact.FirstName).join(',')} were deleted.`;
                      const errors = `The following Addresses could not be deleted: ${processFailures.map(c => c.entity.Account.AccountsContactsLookup[0].FirstName).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.Addresses.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootprintManager_ownersaccounts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  }
  else{
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  
  
  if (selectedRowsCount == 1) {
      $grid.topToolbar.select.control.readOnly = false;
  }
  else{
      $grid.topToolbar.select.control.readOnly = true;
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_ownersaccounts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.linecount.hidden = true;
  
  
  $grid.outParams.confirm = false;
  
  // Hide select toolbar button based on allowSelection input
  if ($grid.inParams.allowSelection == true) {
      $grid.topToolbar.select.hidden = false;
  } else {
      $grid.topToolbar.select.hidden = true;
  }
  
  
  }
  on_select(event = null) {
    return this.on_selectInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_selectInternal(
    $grid: FootprintManager_ownersaccounts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Addresses.openErrorDialog('Address Selection Error', 'No Address selected.');
      return;
  }
  else if (selectedRows.length > 1) {
      $shell.Addresses.openErrorDialog('Address Selection Error', 'More than one Address selected.');
      return;
  }
  else {
  
      $grid.outParams.accountId = selectedRows[0].entity.AccountId;
      $grid.outParams.contactId = selectedRows[0].entity.Account.AccountsContactsLookup[0].ContactId;
      $grid.outParams.addressId = selectedRows[0].entity.Account.AccountsContactsLookup[0].Contact.AddressId;
      $grid.outParams.confirm = true;
      $grid.close();
  
  
  }
  
  }
  on_excel_import(event = null) {
    return this.on_excel_importInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_importInternal(
    $grid: FootprintManager_ownersaccounts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  let requiredFields: string[] = []
  
  for (let key in $grid.headers) {
      if ($grid.headers[key].required == true) {
          requiredFields.push(key)
      }
  }
  
  const wb = $event;
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  
  const _batchSize = 500;
  
  $grid.topToolbar.linecount.hidden = false;
  $grid.topToolbar.linecount.control.icon = "icon datex-default-spinner";
  $grid.topToolbar.linecount.control.label = 'Extracting Data..';
  
  const Addresses = $utils.excel.sheet_to_json<{
      Type: string,
      LookupCode: string,
      CompanyName: string,
      FirstName: string,
      LastName: string,
      Line1: string,
      Line2: string,
      ReferenceCode: string,
      City: string,
      State: string,
      PostalCode: string,
      Country: string,
      AttentionOf: string,
      Phone: string,
      Email: string,
      Notes: string
  
  }>(ws);
  
  $grid.topToolbar.linecount.control.label = 'Preparing Data...';
  
  // Initialize grid and data
  const candidates = [];
  var linecount = 0;
  var confirmLineCount = 0;
  var totalLineCount = Addresses.length;
  
  
  for (const Address of Addresses) {
      let Address_mapping = {
          type: Address.Type,
          account_lookupcode: Address.LookupCode,
          account_name: Address.CompanyName,
          reference_code: Address.ReferenceCode || '',  // Providing default value if missing
          first_name: Address.FirstName,
          last_name: Address.LastName,
          line1: Address.Line1,
          line2: Address.Line2,
          city: Address.City,
          state: Address.State,
          postal_code: Address.PostalCode,
          country: Address.Country,
          attention_of: Address.AttentionOf,
          primary_telephone: Address.Phone,
          primary_email: Address.Email,
          notes: Address.Notes
      };
  
  
      // Create a mapping of field names to user-friendly names
      const fieldNameMapping = {
          line1: "Line1",
          line2: "Line2",
          city: "City",
          state: "State",
          postal_code: "PostalCode",
          country: "Country",
          attention_of: "AttentionOf",
          primary_telephone: "Phone",
          primary_email: "Email",
          notes: "Notes",
          account_lookupcode: "LookupCode",
          account_name: "CompanyName",
          reference_code: "ReferenceCode",
          first_name: "FirstName",
          last_name: "LastName",
          type: "Type"
      };
  
  
      linecount = linecount + 1;
      $grid.topToolbar.linecount.control.label = 'Validating Line ' + linecount.toString() + ' of ' + totalLineCount.toString();
  
      var errors = '';
  
      
      // Check for missing required fields
      requiredFields.forEach(field => {
          if (!$utils.isDefined(Address_mapping[field]) || Address_mapping[field].trim() === '') {
              const fieldName = fieldNameMapping[field] || field;
              errors += `${fieldName} is required. `;
          }
      });
  
  
      const row = await $grid.addRow({
          OwnerId: $grid.inParams.ownerId
      });
  
      if ($utils.isDefined(Address.Type)) {
          const addressType = Address.Type;
          const types = (await $datasources.Addresses.ds_get_contact_types_by_name.get({ name: addressType })).result;
          if ($utils.isDefined(types)) {
  
              row.cells.type.editControl.value = types[0].Id;
          }
          else {
              errors += ' Address type not valid. '
          }
  
      }
      else {
          errors += ' Address type required. '
      }
  
  
  
      // Address Validation
      let validationErrors = (await $flows.Addresses.validate_address_flow({
          line1: row.cells.line1.editControl.value,
          line2: row.cells.line2.editControl.value,
          city: row.cells.city.editControl.value,
          state: row.cells.state.editControl.value,
          postalCode: row.cells.postal_code.editControl.value,
          country: row.cells.country.editControl.value
      })).errors;
  
      if ($utils.isDefined(validationErrors)) {
          errors += validationErrors.join(' & ')
      }
  
      row.cells.account_lookupcode.editControl.value = $utils.isDefined(Address_mapping.account_lookupcode) ? String(Address_mapping.account_lookupcode).trim() : ""
      row.cells.account_name.editControl.value = $utils.isDefined(Address_mapping.account_name) ? String(Address_mapping.account_name).trim() : ""
      row.cells.first_name.editControl.value = $utils.isDefined(Address_mapping.first_name) ? String(Address_mapping.first_name).trim() : ""
      row.cells.last_name.editControl.value = $utils.isDefined(Address_mapping.last_name) ? String(Address_mapping.last_name).trim() : ""
  
      row.cells.line1.editControl.value = $utils.isDefined(Address_mapping.line1) ? String(Address_mapping.line1).trim() : "";
      row.cells.line2.editControl.value = $utils.isDefined(Address_mapping.line2) ? String(Address_mapping.line2).trim() : "";
      row.cells.city.editControl.value = $utils.isDefined(Address_mapping.city)? String(Address_mapping.city).trim() : "";
      row.cells.state.editControl.value = $utils.isDefined(Address_mapping.state) ? String(Address_mapping.state).trim() : "";
      row.cells.postal_code.editControl.value = $utils.isDefined(Address_mapping.postal_code) ? String(Address_mapping.postal_code).trim() : "";
      row.cells.country.editControl.value = $utils.isDefined(Address_mapping.country) ? String(Address_mapping.country).trim() : "";
  
      row.cells.attention_of.editControl.value = $utils.isDefined(Address_mapping.attention_of) ? String(Address_mapping.attention_of).trim() : "";
      row.cells.primary_telephone.editControl.value = $utils.isDefined(Address_mapping.primary_telephone) ? String(Address_mapping.primary_telephone).trim() : "";
      row.cells.primary_email.editControl.value = $utils.isDefined(Address_mapping.primary_email) ? String(Address_mapping.primary_telephone).trim() : "";
      row.cells.notes.editControl.value = $utils.isDefined(Address_mapping.notes) ? String(Address_mapping.notes).trim() : "";
  
  
  
  
      row.cells.error.displayControl.text = errors;
      candidates.push({ row: row, errors: errors });
  }
  
  
  var totalConfirmLineCount = candidates.length;
  
  for (const candidate of candidates) {
      const noErrors = candidate.errors === '';
      if (noErrors) {
  
  
          try {
              confirmLineCount = confirmLineCount + 1;
              $grid.topToolbar.linecount.control.label = 'Creating Address ' + confirmLineCount.toString() + ' of ' + totalConfirmLineCount.toString();
  
  
              const nextAccountId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Account' })).nextId;
  
              // Set Account.LookupCode
              let accountLookupcode: string;
              if ($utils.isDefinedTrimmed(candidate.row.cells.account_lookupcode.editControl.value)) {
                  accountLookupcode = String(candidate.row.cells.account_lookupcode.editControl.value).trim();
              }
              else {
                  accountLookupcode = nextAccountId.toString();
              }
  
              // Account
              const payloadAccount = {
                  "Id": nextAccountId,
                  "EnterpriseId": 1,
                  "TypeId": $grid.inParams.accountTypeId,
                  "LookupCode": accountLookupcode,
                  "Name": candidate.row.cells.account_name.editControl.value ?? accountLookupcode,
                  "Active": true,
                  "AllocationPriority": 0,
                  "AllowReceiving": false,
                  "AllowShipping": false,
                  "AllowReturnsIn": false,
                  "RequiresReceivingRma": false,
                  "TaxScheduleId": 0,
                  "RequiresShippingRma": false
              };
              await $flows.Utilities.crud_create_flow({ entitySet: 'Accounts', entity: payloadAccount });
  
  
              // Address
              const nextAddressId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Address' })).nextId;
              const payloadAddress = {
                  "Id": nextAddressId,
                  "Line1": candidate.row.cells.line1.editControl.value,
                  "Line2": candidate.row.cells.line2?.editControl.value,
                  "City": candidate.row.cells.city?.editControl.value,
                  "State": candidate.row.cells.state?.editControl.value,
                  "PostalCode": candidate.row.cells.postal_code?.editControl.value,
                  "Country": candidate.row.cells.country?.editControl.value,
                  "AttentionOf": candidate.row.cells.attention_of?.editControl.value,
              };
              await $flows.Utilities.crud_create_flow({ entitySet: 'Addresses', entity: payloadAddress });
  
  
              // Contact
              const nextContactId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Contact' })).nextId;
              const payloadContacts = {
                  "Id": nextContactId,
                  "OwnerEntity": "Account",
                  "OwnerId": nextAccountId,
                  "TypeId": candidate.row.cells.type.editControl.value,
                  "AddressId": nextAddressId,
                  "FirstName": candidate.row.cells.first_name.editControl.value,
                  "LastName": candidate.row.cells.last_name.editControl.value,
                  "PrimaryEmail": candidate.row.cells.primary_email?.editControl.value,
                  "PrimaryTelephone": candidate.row.cells.primary_telephone?.editControl.value,
                  "Notes": candidate.row.cells.notes?.editControl.value
              };
              await $flows.Utilities.crud_create_flow({ entitySet: 'Contacts', entity: payloadContacts });
  
              // OwnersAccounts
              const payload = {
                  "OwnerId": candidate.row.entity.OwnerId,
                  "AccountId": nextAccountId,
                  "IsDefaultAccount": false
              }
              await $flows.Utilities.crud_create_flow({ entitySet: 'OwnersAccountsLookup', entity: payload });
  
  
              candidate.row.entity.AccountId = nextAccountId;
  
              candidate.row.refresh();
              candidate.row.setDisplayMode();
              candidate.row.isNew = false;
              // Lock down read only cells upon creation
              candidate.row.cells.type.editControl.readOnly = true;
  
  
          }
  
          catch (error) {
              errors += 'Error calling calling crud Api'
              candidate.row.cells.error.displayControl.text = errors;
  
              $grid.topToolbar.linecount.hidden = true;
              throw error; // to prevent displayMode 
  
          }
  
  
  
  
      }
  
  }
  
  
  $grid.topToolbar.linecount.hidden = true;
  
  
  
  
  
  }
  on_excel_export(event = null) {
    return this.on_excel_exportInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_exportInternal(
    $grid: FootprintManager_ownersaccounts_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_ownersaccounts_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  const wb = $event;
  
  const addresses = [];
  
  addresses.push({
      Type: 'Required',
      LookupCode: '',
      CompanyName: '',
      FirstName: 'Required',
      LastName: 'Required',
      Line1: 'Required',
      Line2: '',
      City: '',
      State: '',
      PostalCode: '',
      Country: '',
      AttentionOf: '',
      Phone: '',
      Email: '',
      Notes: ''
  
  });
  
  
  
  const ws = $utils.excel.json_to_sheet(addresses);
  
  $utils.excel.book_append_sheet(wb, ws, 'AddressesImport');
  
  }
  //#endregion private flows

  async $importExcel(fileInput: HTMLInputElement) {
    if(fileInput.files?.length !== 1) {
      throw new Error('Cannot import multiple files');
    }
    const file = fileInput.files[0];
    if(file.size > 15 * 1000 * 1000) {
      throw new Error('Cannot import a file with size greater than 15MB');
    }
    const reader: FileReader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const ab: ArrayBuffer = e.target?.result as ArrayBuffer;
      const wb: WorkBook = readExcelFile(ab);
      await this.on_excel_import(wb);
    };
    reader.readAsArrayBuffer(file);
    fileInput.value = '';
  }

  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export(wb);

    writeExcelFile(wb, 'FootprintManager_ownersaccounts_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
