import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootprintManager_ds_get_status_count_locationsService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { status_id?: number }): 
  Promise<{ result: { nmb?: number, StatusId?: number } }> 
  {
    let url = `${environment.backendUrl}api/FootprintManager/datasources/ds_get_status_count_locations/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      status_id?: number    }) {
      return false;
    }
}
