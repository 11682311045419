import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { FootprintManager_ModuleContextService } from './FootprintManager.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class FootprintManager_sales_orders_processing_options_form_ComponentContextService {
  private readonly moduleContext: FootprintManager_ModuleContextService;

  constructor(moduleContext: FootprintManager_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'sales_orders_processing_options_form',
      type: ComponentType.form
    }
  }
}