import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Allocations.frontend.types'

@Injectable({ providedIn: 'root' })
export class Allocations_create_allocation_configuration_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { content: $frontendTypes.Allocations.i_allocation_config, filters: { name: string, value: string }[], context: number[] }): Promise<{ config_id?: number, reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.content)) {
      missingRequiredInParams.push('\'content\'');
    }
    if (isNil(inParams.filters)) {
      missingRequiredInParams.push('\'filters\'');
    }
    if (isNil(inParams.context)) {
      missingRequiredInParams.push('\'context\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Allocations/functions/create_allocation_configuration_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

