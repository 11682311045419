import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_pallet_inventory_by_class_grid_ComponentContextService } from './FootprintManager.pallet_inventory_by_class_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { FootprintManager_pallet_classes_dd_singleComponent } from './FootprintManager.pallet_classes_dd_single.component'


interface IFootprintManager_pallet_inventory_by_class_gridComponentEntity {
  Amount?: number, Id?: number, Count?: number, PalletClassId?: number, PalletClass?: { Name?: string }}



class FootprintManager_pallet_inventory_by_class_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_pallet_inventory_by_class_gridComponent;
  entity: IFootprintManager_pallet_inventory_by_class_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    PalletClass: null,
    Amount: null,
    Projects: null,
  }



  constructor(
    grid: FootprintManager_pallet_inventory_by_class_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_pallet_inventory_by_class_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.PalletClass = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.PalletClass,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Amount = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Amount,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.Projects = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.Projects,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_pallet_inventory_by_class_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.PalletClassId,this.entity.PalletClass.Name].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_pallet_inventory_by_class_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.PalletClassId,this.entity.PalletClass.Name].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = {};
    set($resultKey, 'PalletClassId',this.entity.PalletClassId);
    set($resultKey, 'PalletClass.Name',this.entity.PalletClass.Name);
    const inParams = {
      $keys:[$resultKey],
      pallet_class_ids:  $grid.filters.PalletClass.control.value ? [$grid.filters.PalletClass.control.value] : null ,
      project_ids:  $grid.filters.Project.control.value ? [$grid.filters.Project.control.value] : null ,
      account_ids:  null ,
      carrier_ids:  null ,
      shipment_ids:  null ,
      full_text_search:  null ,
    };
    const data = await this.$datasources.PalletTransactions.ds_pallet_inventory_by_class_grid.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_pallet_inventory_by_class_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.PalletClass.displayControl as TextModel).text = $row.entity.PalletClass.Name;
    (this.cells.Amount.displayControl as TextModel).text = $row.entity.Amount?.toLocaleString();

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }


}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => FootprintManager_pallet_classes_dd_singleComponent),
  ],
  selector: 'FootprintManager-pallet_inventory_by_class_grid',
  templateUrl: './FootprintManager.pallet_inventory_by_class_grid.component.html'
})
export class FootprintManager_pallet_inventory_by_class_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_pallet_inventory_by_class_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;



  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     PalletClass: new GridHeaderModel(new HeaderStyles(null, null), 'PalletClass', 'Pallet class', false, false, null, false),       Amount: new GridHeaderModel(new HeaderStyles(null, null), 'Amount', 'Amount', false, false, null, false),       Projects: new GridHeaderModel(new HeaderStyles(null, null), 'Projects', 'Associated projects', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_pallet_inventory_by_class_gridComponentRowModel[] = [];


  topToolbar = {
      new_transaction: new ToolModel(new ButtonModel('new_transaction', new ButtonStyles(['creation'], null), false, false, false, 'New pallet transaction', 'icon-ic_fluent_add_20_regular', null)
    , false)
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    Project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    PalletClass: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    Project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['Project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', false, false),
    PalletClass: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['PalletClass'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Pallet class', false, false),
  }

  //#region filters inParams
  get $fields_Project_selector_inParams_statusId(): number {
    const $grid = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_PalletClass_selector_inParams_project_id(): number {
    const $grid = this;
    const $utils = this.$utils;
    const expr = $grid.filters.Project.control.value;
    
    return expr;
  }

  //#endregion filters inParams


  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_pallet_inventory_by_class_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Pallet inventory by class grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 25;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      pallet_class_ids:  $grid.filters.PalletClass.control.value ? [$grid.filters.PalletClass.control.value] : null ,
      project_ids:  $grid.filters.Project.control.value ? [$grid.filters.Project.control.value] : null ,
      account_ids:  null ,
      carrier_ids:  null ,
      shipment_ids:  null ,
      full_text_search:  null ,
    };
    try {
      const data = await this.$datasources.PalletTransactions.ds_pallet_inventory_by_class_grid.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_pallet_inventory_by_class_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_pallet_inventory_by_class_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootprintManager_pallet_inventory_by_class_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_inventory_by_class_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootprintManager_pallet_inventory_by_class_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_inventory_by_class_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/07/2024
  
  let PalletClassIds = $grid.rows.map(r => r.entity.PalletClassId);
  
  let Inventory = (await $datasources.PalletTransactions.ds_pallet_inventory_by_project_grid.get({
      pallet_class_ids: PalletClassIds,
      project_ids: $grid.filters.Project.control.value ? [$grid.filters.Project.control.value] : null
  })).result;
  
  for (let row of $grid.rows) {
  
      let Projects = Inventory.filter(I => I.PalletClassId === row.entity.PalletClassId).map(I => I.Project.LookupCode);
      Projects = Projects.sort((a, b) => a.localeCompare(b));
  
      let ProjectsText: string;
      let ProjectsCount: number = Projects.length;
  
      for (let Project of Projects) {
  
          let NewProjectsText = ProjectsText ? `${ProjectsText}, ${Project}` : Project;
  
          if (NewProjectsText.length >= 75 && $utils.isDefined(ProjectsText)) {
              break;
          }
          ProjectsText = NewProjectsText;
          ProjectsCount--;
      }
  
      if (ProjectsCount > 0) {
          ProjectsText = `${ProjectsText} (${ProjectsCount} more)`
      }
  
      row.cells.Projects.displayControl.text = ProjectsText;
  }
  }
  on_create_clicked(event = null) {
    return this.on_create_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_clickedInternal(
    $grid: FootprintManager_pallet_inventory_by_class_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_pallet_inventory_by_class_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/09/2024
  
  await $shell.FootprintManager.openpallet_transaction_creation_formDialog({
      project_id: $grid.filters.Project.control.value,
      pallet_class_id: $grid.filters.PalletClass.control.value
  }, "flyout")
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
