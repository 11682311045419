import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_licenseplate_navigation_listService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lotId?: number, materialId?: number, locationId?: number, projectId?: number }): 
  Promise<{ result: { TotalPackagedAmount?: number, TotalGrossWeight?: number, TotalNetWeight?: number, LicensePlateId?: number, licenseplate?: { Id?: number, LookupCode?: string, Location?: { Name?: string } } }[] }> 
  {
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_licenseplate_navigation_list/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { lotId?: number, materialId?: number, locationId?: number, projectId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { TotalPackagedAmount?: number, TotalGrossWeight?: number, TotalNetWeight?: number, LicensePlateId?: number, licenseplate?: { Id?: number, LookupCode?: string, Location?: { Name?: string } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_licenseplate_navigation_list/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { lotId?: number, materialId?: number, locationId?: number, projectId?: number, $keys: number[] }): 
  Promise<{ result: { TotalPackagedAmount?: number, TotalGrossWeight?: number, TotalNetWeight?: number, LicensePlateId?: number, licenseplate?: { Id?: number, LookupCode?: string, Location?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_licenseplate_navigation_list/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      lotId?: number, materialId?: number, locationId?: number, projectId?: number    }) {
      return false;
    }
}
