<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"

[rowCanExpand]="true"
>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="unpick" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_unpick_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="transfer" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_transfer_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="image_preview">

    <ng-template gridCellDisplayControlDef let-row>
    <a (click)="openImageViewer(row.cells.image_preview.displayControl.src)">
    <img [src]="row.cells.image_preview.displayControl.src"
            data-cy="image" class="datex-image"
            [ngStyle]="row.cells.image_preview.displayControl.styles.style"
            [ngClass]="row.cells.image_preview.displayControl.styles.classes"
            onerror="this.style.display='none'"
            onload="this.style.display='inline'"
            [matTooltip]="row.cells.image_preview.displayControl.tooltip"
            matTooltipClass="datex-control-tooltip">
    </a>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes"
          [matTooltip]="row.cells.material.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes"
          [matTooltip]="row.cells.lot.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes"
          [matTooltip]="row.cells.packaging.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="verified_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.verified_quantity.displayControl.styles.style"
          [ngClass]="row.cells.verified_quantity.displayControl.styles.classes"
          [matTooltip]="row.cells.verified_quantity.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.verified_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox" [formControl]="row.formGroup.controls['verified_quantity_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{ (row.cells.verified_quantity.editControl.readOnly || row.cells.verified_quantity.editControl.disabled) ? 'readonly ' : ''}}"
            placeholder="{{row.cells.verified_quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.verified_quantity.editControl.styles.style"
            [ngClass]="row.cells.verified_quantity.editControl.styles.classes"
            [matTooltip]="row.cells.verified_quantity.editControl.tooltip"
            matTooltipClass="datex-control-tooltip"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="remove_verified">

    <ng-template gridCellDisplayControlDef let-row>
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="row.cells.remove_verified.displayControl.readOnly || row.cells.remove_verified.displayControl.disabled"
            [ngStyle]="row.cells.remove_verified.displayControl.styles.style"
            [ngClass]="row.cells.remove_verified.displayControl.styles.classes"
            [matTooltip]="row.cells.remove_verified.displayControl.tooltip"
            matTooltipClass="datex-control-tooltip"
            (click)="row.on_remove_verified_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="row.cells.remove_verified.displayControl.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{row.cells.remove_verified.displayControl.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{row.cells.remove_verified.displayControl.label}}</div>
      </div>
    
    </button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="add_verified">

    <ng-template gridCellDisplayControlDef let-row>
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="row.cells.add_verified.displayControl.readOnly || row.cells.add_verified.displayControl.disabled"
            [ngStyle]="row.cells.add_verified.displayControl.styles.style"
            [ngClass]="row.cells.add_verified.displayControl.styles.classes"
            [matTooltip]="row.cells.add_verified.displayControl.tooltip"
            matTooltipClass="datex-control-tooltip"
            (click)="row.on_add_verified_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="row.cells.add_verified.displayControl.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{row.cells.add_verified.displayControl.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{row.cells.add_verified.displayControl.label}}</div>
      </div>
    
    </button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootprintManager-scanned_values_grid
    [scannedValues]="row.$rowExpand_FootprintManager_scanned_values_grid_inParams_scannedValues"
    [expectedValues]="row.$rowExpand_FootprintManager_scanned_values_grid_inParams_expectedValues"
    ($refreshEvent)="row.refresh()">>
  </FootprintManager-scanned_values_grid>
</ng-template>

</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
