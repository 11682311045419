import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './ExcelInventoryImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_inventory_import_staging_runService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { InventoryImportRecords?: $frontendTypes.ExcelInventoryImport.InventoryImportRecord[], TransactionId?: string, Owners?: any[], Projects?: any[], Warehouses?: any[], Locations?: any[], Materials?: any[], ReasonCodes?: any[], Weights?: any[], Statuses?: any[], ContainerTypes?: any[] }): Promise<{ messages?: any[] }> 
  {
    let url = `${environment.backendUrl}api/ExcelInventoryImport/functions/inventory_import_staging_run`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

