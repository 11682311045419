import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_total_appt_not_checked_in_by_date_widgetService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[], warehouseIds?: number[] }): 
  Promise<{ result: { TotalAppointments?: number } }> 
  {
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_total_appt_not_checked_in_by_date_widget/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      typeIds?: number[], ownerId?: number, projectId?: number, carrierId?: number, statusIds?: number[], timeZoneDates?: { timeZoneId?: string, viewDate?: string }[], warehouseIds?: number[]    }) {
      return false;
    }
}
