import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Returns_returns_library_homeComponent } from './Returns.returns_library_home.component';
import { Returns_return_shipping_containers_gridComponent } from './Returns.return_shipping_containers_grid.component';
import { Returns_condition_dd_singleComponent } from './Returns.condition_dd_single.component';
import { Returns_license_plates_on_shipping_container_dd_singleComponent } from './Returns.license_plates_on_shipping_container_dd_single.component';
import { Returns_material_packagings_dd_singleComponent } from './Returns.material_packagings_dd_single.component';
import { Returns_materials_by_project_dd_singleComponent } from './Returns.materials_by_project_dd_single.component';
import { Returns_order_classes_dd_singleComponent } from './Returns.order_classes_dd_single.component';
import { Returns_order_statuses_dd_singleComponent } from './Returns.order_statuses_dd_single.component';
import { Returns_projects_dd_singleComponent } from './Returns.projects_dd_single.component';
import { Returns_return_license_plates_by_shippingcontainer_dd_singleComponent } from './Returns.return_license_plates_by_shippingcontainer_dd_single.component';
import { Returns_returns_location_dd_singleComponent } from './Returns.returns_location_dd_single.component';
import { Returns_shipping_container_return_states_dd_singleComponent } from './Returns.shipping_container_return_states_dd_single.component';
import { Returns_condition_dd_multiComponent } from './Returns.condition_dd_multi.component';
import { Returns_license_plates_on_shipping_container_dd_multiComponent } from './Returns.license_plates_on_shipping_container_dd_multi.component';
import { Returns_material_packagings_dd_multiComponent } from './Returns.material_packagings_dd_multi.component';
import { Returns_materials_by_project_dd_multiComponent } from './Returns.materials_by_project_dd_multi.component';
import { Returns_order_classes_dd_multiComponent } from './Returns.order_classes_dd_multi.component';
import { Returns_order_statuses_dd_multiComponent } from './Returns.order_statuses_dd_multi.component';
import { Returns_projects_dd_multiComponent } from './Returns.projects_dd_multi.component';
import { Returns_return_license_plates_by_shippingcontainer_dd_multiComponent } from './Returns.return_license_plates_by_shippingcontainer_dd_multi.component';
import { Returns_returns_location_dd_multiComponent } from './Returns.returns_location_dd_multi.component';
import { Returns_shipping_container_return_states_dd_multiComponent } from './Returns.shipping_container_return_states_dd_multi.component';
import { Returns_orderline_total_amount_widgetComponent } from './Returns.orderline_total_amount_widget.component';
import { Returns_orderline_total_gross_received_widgetComponent } from './Returns.orderline_total_gross_received_widget.component';
import { Returns_orderline_total_received_widgetComponent } from './Returns.orderline_total_received_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Returns_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Returns: Returns_ShellService = this;

  public openreturns_library_home(replaceCurrentView?: boolean) {
    this.$logger.log('Returns', 'returns_library_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Returns_returns_library_home',
        component: Returns_returns_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openreturns_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Returns', 'returns_library_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Returns_returns_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openreturn_shipping_containers_grid(inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], orderClassIds?: number[] }, replaceCurrentView?: boolean) {
    this.$logger.log('Returns', 'return_shipping_containers_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Return shipping containers',
        referenceName: 'Returns_return_shipping_containers_grid',
        component: Returns_return_shipping_containers_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openreturn_shipping_containers_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], orderClassIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Returns', 'return_shipping_containers_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Returns_return_shipping_containers_gridComponent,
      'Return shipping containers',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Returns_returns_library_home') {
      this.$logger.log('Returns', 'returns_library_home');
      const title = 'Home';
      const component = Returns_returns_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_return_shipping_containers_grid') {
      this.$logger.log('Returns', 'return_shipping_containers_grid');
      const title = 'Return shipping containers';
      const component = Returns_return_shipping_containers_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseIds?: number[], orderStatusIds?: number[], orderClassIds?: number[] } = { ownerId: null, projectId: null, warehouseIds: [], orderStatusIds: [], orderClassIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseIds'))) {
        const paramValueString = params.get('warehouseIds');
        inParams.warehouseIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderStatusIds'))) {
        const paramValueString = params.get('orderStatusIds');
        inParams.orderStatusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('orderClassIds'))) {
        const paramValueString = params.get('orderClassIds');
        inParams.orderClassIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_condition_dd_single') {
      const title = 'condition_dd';
      const component = Returns_condition_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_license_plates_on_shipping_container_dd_single') {
      this.$logger.log('Returns', 'license_plates_on_shipping_container_dd_single');
      const title = 'license_plates_on_shipping_container_dd';
      const component = Returns_license_plates_on_shipping_container_dd_singleComponent;
      const inParams:{ shippingContainerId: number, archived?: boolean } = { shippingContainerId: null, archived: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_material_packagings_dd_single') {
      const title = 'Material Packagings dropdown';
      const component = Returns_material_packagings_dd_singleComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_materials_by_project_dd_single') {
      this.$logger.log('Returns', 'materials_by_project_dd_single');
      const title = 'Project Materials dropdown';
      const component = Returns_materials_by_project_dd_singleComponent;
      const inParams:{ projectId?: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_order_classes_dd_single') {
      const title = 'order_classes_dd';
      const component = Returns_order_classes_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_order_statuses_dd_single') {
      const title = 'order_statuses_dd';
      const component = Returns_order_statuses_dd_singleComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = Returns_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number, gtin?: string } = { statusId: null, ownerId: null, gtin: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('gtin'))) {
        const paramValueString = params.get('gtin');
        // TODO: date
        inParams.gtin = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_return_license_plates_by_shippingcontainer_dd_single') {
      this.$logger.log('Returns', 'return_license_plates_by_shippingcontainer_dd_single');
      const title = 'return_license_plates_by_shippingcontainer_dd';
      const component = Returns_return_license_plates_by_shippingcontainer_dd_singleComponent;
      const inParams:{ shippingContainerId: number, warehouseId: number, locationId: number } = { shippingContainerId: null, warehouseId: null, locationId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_returns_location_dd_single') {
      const title = 'Returns location dropdown';
      const component = Returns_returns_location_dd_singleComponent;
      const inParams:{ warehouseId: number, typeId: number, isLoose?: boolean, eligibleForAllocation?: boolean } = { warehouseId: null, typeId: null, isLoose: null, eligibleForAllocation: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('isLoose'))) {
        const paramValueString = params.get('isLoose');
        inParams.isLoose = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('eligibleForAllocation'))) {
        const paramValueString = params.get('eligibleForAllocation');
        inParams.eligibleForAllocation = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_shipping_container_return_states_dd_single') {
      const title = 'shipping_container_return_states_dd';
      const component = Returns_shipping_container_return_states_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_condition_dd_multi') {
      const title = 'condition_dd';
      const component = Returns_condition_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_license_plates_on_shipping_container_dd_multi') {
      this.$logger.log('Returns', 'license_plates_on_shipping_container_dd_multi');
      const title = 'license_plates_on_shipping_container_dd';
      const component = Returns_license_plates_on_shipping_container_dd_multiComponent;
      const inParams:{ shippingContainerId: number, archived?: boolean } = { shippingContainerId: null, archived: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('archived'))) {
        const paramValueString = params.get('archived');
        inParams.archived = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_material_packagings_dd_multi') {
      const title = 'Material Packagings dropdown';
      const component = Returns_material_packagings_dd_multiComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_materials_by_project_dd_multi') {
      this.$logger.log('Returns', 'materials_by_project_dd_multi');
      const title = 'Project Materials dropdown';
      const component = Returns_materials_by_project_dd_multiComponent;
      const inParams:{ projectId?: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_order_classes_dd_multi') {
      const title = 'order_classes_dd';
      const component = Returns_order_classes_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_order_statuses_dd_multi') {
      const title = 'order_statuses_dd';
      const component = Returns_order_statuses_dd_multiComponent;
      const inParams:{ statusIds?: number[] } = { statusIds: [] };
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = Returns_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number, gtin?: string } = { statusId: null, ownerId: null, gtin: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('gtin'))) {
        const paramValueString = params.get('gtin');
        // TODO: date
        inParams.gtin = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_return_license_plates_by_shippingcontainer_dd_multi') {
      this.$logger.log('Returns', 'return_license_plates_by_shippingcontainer_dd_multi');
      const title = 'return_license_plates_by_shippingcontainer_dd';
      const component = Returns_return_license_plates_by_shippingcontainer_dd_multiComponent;
      const inParams:{ shippingContainerId: number, warehouseId: number, locationId: number } = { shippingContainerId: null, warehouseId: null, locationId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_returns_location_dd_multi') {
      const title = 'Returns location dropdown';
      const component = Returns_returns_location_dd_multiComponent;
      const inParams:{ warehouseId: number, typeId: number, isLoose?: boolean, eligibleForAllocation?: boolean } = { warehouseId: null, typeId: null, isLoose: null, eligibleForAllocation: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('isLoose'))) {
        const paramValueString = params.get('isLoose');
        inParams.isLoose = this.convertToBoolean(paramValueString);
      }
      if (!isNil(params.get('eligibleForAllocation'))) {
        const paramValueString = params.get('eligibleForAllocation');
        inParams.eligibleForAllocation = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_shipping_container_return_states_dd_multi') {
      const title = 'shipping_container_return_states_dd';
      const component = Returns_shipping_container_return_states_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_orderline_total_amount_widget') {
      const title = 'Total amount';
      const component = Returns_orderline_total_amount_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_orderline_total_gross_received_widget') {
      const title = 'Total gross recv';
      const component = Returns_orderline_total_gross_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Returns_orderline_total_received_widget') {
      const title = 'Total received';
      const component = Returns_orderline_total_received_widgetComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
