import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_inventory_transformation_editor_ComponentContextService } from './FootprintManager.inventory_transformation_editor.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { FootprintManager_inventory_transformation_source_lines_gridComponent } from './FootprintManager.inventory_transformation_source_lines_grid.component';
import { InventoryTransformations_total_inventory_transformation_source_material_widgetComponent } from './InventoryTransformations.total_inventory_transformation_source_material_widget.component';
import { InventoryTransformations_total_inventory_transformation_target_material_widgetComponent } from './InventoryTransformations.total_inventory_transformation_target_material_widget.component';
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Locations_warehouses_dd_singleComponent } from './Locations.warehouses_dd_single.component'

type EntityType = { 
    Id?: number, LookupCode?: string, WarehouseId?: number, Project?: { Id?: number, OwnerId?: number }, InventoryTransformationStatus?: { Id?: number, Name?: string }, InventoryTransformationSourceLineListForInventoryTransformation?: { Id?: number }[]}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootprintManager_inventory_transformation_source_lines_gridComponent),
    forwardRef(() => InventoryTransformations_total_inventory_transformation_source_material_widgetComponent),
    forwardRef(() => InventoryTransformations_total_inventory_transformation_target_material_widgetComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Locations_warehouses_dd_singleComponent),
  ],
  selector: 'FootprintManager-inventory_transformation_editor',
  templateUrl: './FootprintManager.inventory_transformation_editor.component.html'
})
export class FootprintManager_inventory_transformation_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { inventory_transformation_id: number } = { inventory_transformation_id: null };
  //#region Inputs
  @Input('inventory_transformation_id') set $inParams_inventory_transformation_id(v: number) {
    this.inParams.inventory_transformation_id = v;
  }
  get $inParams_inventory_transformation_id(): number {
    return this.inParams.inventory_transformation_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { is_read_only?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      process: new ToolModel(new ButtonModel('process', new ButtonStyles(['primary'], null), false, false, false, 'Process', 'icon-ic_fluent_arrow_clockwise_dashes_20_regular', null)
    , false),
      execute: new ToolModel(new ButtonModel('execute', new ButtonStyles(['primary'], null), false, false, false, 'Transform and complete', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    , false),
      pick: new ToolModel(new ButtonModel('pick', new ButtonStyles(['primary'], null), false, false, false, 'Pick', 'icon-datex-Pick', null)
    , false),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      revert: new ToolModel(new ButtonModel('revert', new ButtonStyles(null, null), false, false, false, 'Revert', 'icon-ic_fluent_arrow_undo_20_regular', null)
    , false),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, false, false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    , false),
      discard: new ToolModel(new ButtonModel('discard', new ButtonStyles(['destructive'], null), false, false, false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    , false),
      separator3: new ToolModel(new SeparatorModel(new Styles(null, null))
    , false),
      print: new ToolModel(new ButtonModel('print', new ButtonStyles(null, null), false, false, false, ' ', 'icon-ic_fluent_print_20_regular', null)
    , false)
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Lookupcode', false, false),
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Owner', true, false),
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Project', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', true, false),
    status: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Status', false, false),
  };

  fieldsets = {
    inventory_transformation: new FieldsetModel(
      'Inventory transformation',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $editor = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inventory_source_lines: new TabItemModel(
        this.rootTabGroup, 
        'Transformation lines', 
        false,
        ),
    };
  
  
    //#region tabs inParams
    get $tabs_inventory_source_lines_inventory_transformation_source_lines_grid_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.inParams.inventory_transformation_id;
      
      return expr;
    }
  
    get $tabs_inventory_source_lines_inventory_transformation_source_lines_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.project.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_source_lines_inventory_transformation_source_lines_grid_inParams_warehouse_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.fields.warehouse.control.value;
      
      return expr;
    }
  
    get $tabs_inventory_source_lines_inventory_transformation_source_lines_grid_inParams_inventory_transformation_status_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.InventoryTransformationStatus?.Id;
      
      return expr;
    }
  
    get $tabs_inventory_source_lines_inventory_transformation_source_lines_grid_inParams_source_read_only(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.vars.is_read_only;
      
      return expr;
    }
  
    get $tabs_inventory_source_lines_inventory_transformation_source_lines_grid_inParams_target_read_only(): boolean {
      const $editor = this;
      const $utils = this.$utils;
      const expr = true;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inventory_source_lines', { read: FootprintManager_inventory_transformation_source_lines_gridComponent }) $tabs_inventory_source_lines: FootprintManager_inventory_transformation_source_lines_gridComponent;
    //#endregion tabs children
    widgets = {
      total_source_inventory_widget: new WidgetModel(false),
      total_completed_target_inventory: new WidgetModel(false),
    };
  
    //#region widgets inParams
    get $widgets_total_source_inventory_widget_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    get $widgets_total_completed_target_inventory_inParams_inventory_transformation_id(): number {
      const $editor = this;
      const $utils = this.$utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_total_source_inventory_widget', { read:  InventoryTransformations_total_inventory_transformation_source_material_widgetComponent }) $widgets_total_source_inventory_widget: InventoryTransformations_total_inventory_transformation_source_material_widgetComponent;
      @ViewChild('$widgets_total_completed_target_inventory', { read:  InventoryTransformations_total_inventory_transformation_target_material_widgetComponent }) $widgets_total_completed_target_inventory: InventoryTransformations_total_inventory_transformation_target_material_widgetComponent;
    //#endregion widgets children

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.$utils;
    const expr = $editor.fields.owner.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    warehouse: this.fields.warehouse.control.valueChanges
    ,
  }
  

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inventory_source_lines,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.inventory_transformation_id)) {
        this.$missingRequiredInParams.push('inventory_transformation_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit inventory transformation';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.$utils;

    const dsParams = {
      inventory_transformation_id:  $editor.inParams.inventory_transformation_id 
    };

    const data = await this.$datasources.InventoryTransformations.ds_inventory_transformation_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.$utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.owner.control as SelectBoxModel).reset($editor.entity.Project.OwnerId);
    (this.fields.project.control as SelectBoxModel).reset($editor.entity.Project?.Id);
    (this.fields.warehouse.control as SelectBoxModel).reset($editor.entity.WarehouseId);
    (this.fields.status.control as TextModel).text = $editor.entity.InventoryTransformationStatus.Name;

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_total_source_inventory_widget') {
      if (!isNil(this.$widgets_total_source_inventory_widget) && !this.widgets.total_source_inventory_widget.hidden && !this.widgets.total_source_inventory_widget.removed) {
        this.$widgets_total_source_inventory_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_total_completed_target_inventory') {
      if (!isNil(this.$widgets_total_completed_target_inventory) && !this.widgets.total_completed_target_inventory.hidden && !this.widgets.total_completed_target_inventory.removed) {
        this.$widgets_total_completed_target_inventory.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
      if (childToSkip !== '$tabs_inventory_source_lines') {
        if (!isNil(this.$tabs_inventory_source_lines) && !this.tabs.inventory_source_lines.hidden && !this.tabs.inventory_source_lines.removed) {
          this.$tabs_inventory_source_lines.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_save();
      });
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_data_loaded');
  // Set editor title
  $editor.title = `Inventory transformation ${$editor?.entity?.LookupCode}`;
  
  $editor.toolbar.pick.hidden = true
  $editor.toolbar.print.hidden = true
  $editor.vars.is_read_only = false
  
  //Completed
  if($editor?.entity?.InventoryTransformationStatus?.Id === 3)
  {
      $editor.toolbar.cancel.hidden = true
      $editor.toolbar.discard.hidden = true
      $editor.toolbar.execute.hidden = true
      $editor.toolbar.process.hidden = true
      $editor.toolbar.revert.hidden = true
      $editor.fields.lookupcode.control.readOnly = true
      $editor.vars.is_read_only = true
  }
  
  //Created
  if($editor?.entity?.InventoryTransformationStatus?.Id === 1)
  {
      $editor.toolbar.cancel.hidden = false
      $editor.toolbar.discard.hidden = false
      $editor.toolbar.execute.hidden = true
      $editor.toolbar.process.hidden = false
      $editor.toolbar.revert.hidden = true
  }
  
  //Processing
  if($editor?.entity?.InventoryTransformationStatus?.Id === 2)
  {
      $editor.toolbar.cancel.hidden = false
      $editor.toolbar.discard.hidden = false
      $editor.toolbar.execute.hidden = false
      $editor.toolbar.process.hidden = true
      $editor.toolbar.revert.hidden = false
      $editor.fields.lookupcode.control.readOnly = true
  }
  
  //Error
  if($editor?.entity?.InventoryTransformationStatus?.Id === 5)
  {
      $editor.toolbar.cancel.hidden = false
      $editor.toolbar.discard.hidden = false
      $editor.toolbar.execute.hidden = true
      $editor.toolbar.process.hidden = true
      $editor.toolbar.revert.hidden = false
      $editor.fields.lookupcode.control.readOnly = true
  }
  
  //Error
  if($editor?.entity?.InventoryTransformationStatus?.Id === 4)
  {
      $editor.toolbar.cancel.hidden = true
      $editor.toolbar.discard.hidden = false
      $editor.toolbar.execute.hidden = true
      $editor.toolbar.process.hidden = true
      $editor.toolbar.revert.hidden = false
      $editor.fields.lookupcode.control.readOnly = true
  }
  
  //lock down filters once transformation has lines
  if($editor?.entity?.InventoryTransformationSourceLineListForInventoryTransformation.length>0)
  {
      $editor.fields.owner.control.readOnly = true
      $editor.fields.project.control.readOnly = true
      $editor.fields.warehouse.control.readOnly = true
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_init');
  
  }
  on_process_clicked(event = null) {
    return this.on_process_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_process_clickedInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_process_clicked');
  
  
  
  try {
  
      let confirm_process = (await $shell.InventoryTransformations.openConfirmationDialog('Process inventory transformation.', `Confirm to process inventory transformation [${$editor.entity?.LookupCode}]?`, 'Confirm', 'Cancel'))
  
  
      if (!confirm_process) { return }
  
  
      $editor.toolbar.process.control.label = 'Processing'
      $editor.toolbar.process.control.icon = "icon datex-default-spinner"
      $editor.toolbar.process.control.readOnly = true
      $editor.toolbar.cancel.control.readOnly = true
      $editor.toolbar.discard.control.readOnly = true
      $editor.toolbar.revert.control.readOnly = true
      $editor.toolbar.execute.control.readOnly = true
  
  
      let process_transformation = (await $flows.InventoryTransformations.process_inventory_transformation_flow({ inventory_transformation_id: $editor.inParams.inventory_transformation_id }))
  
      if (process_transformation?.reason) {
          throw Error(process_transformation?.reason)
      }
  
      $editor.toolbar.process.control.styles.resetStyle();
      $editor.toolbar.process.control.icon = "icon-ic_fluent_arrow_clockwise_20_regular";
      $editor.toolbar.process.control.label = 'Process';
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
      $editor.toolbar.execute.control.readOnly = false
  
  
      $shell.InventoryTransformations.openToaster(
          'Successfully processed inventory transformation.',
          `Inventory transformation ${$editor.entity.LookupCode} has been processed.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
      await $editor.refresh()
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on process.', error);
  
      $editor.toolbar.process.control.styles.resetStyle();
      $editor.toolbar.process.control.icon = "icon-ic_fluent_arrow_clockwise_20_regular";
      $editor.toolbar.process.control.label = 'Process';
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
      $editor.toolbar.execute.control.readOnly = false
  }
  }
  on_execute(event = null) {
    return this.on_executeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_executeInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_execute');
  
  
  
  
  
  try {
  
      let confirm_execute = (await $shell.InventoryTransformations.openConfirmationDialog('Process inventory transformation.', `Confirm to execute inventory transformation [${$editor.entity?.LookupCode}]?`, 'Confirm', 'Cancel'))
  
  
      if (!confirm_execute) { return }
  
      $editor.toolbar.execute.control.label = 'Completing'
      $editor.toolbar.execute.control.icon = "icon datex-default-spinner"
      $editor.toolbar.execute.control.readOnly = true
      $editor.toolbar.cancel.control.readOnly = true
      $editor.toolbar.discard.control.readOnly = true
      $editor.toolbar.revert.control.readOnly = true
      $editor.toolbar.process.control.readOnly = true
  
      let execute_transformation = (await $flows.InventoryTransformations.execute_inventory_transformation_flow({ inventory_transformation_id: $editor.inParams.inventory_transformation_id }))
  
      if (execute_transformation?.reason) {
          throw Error(execute_transformation?.reason)
      }
  
      await $editor.refresh()
  
      $editor.toolbar.execute.control.styles.resetStyle();
      $editor.toolbar.execute.control.icon = "icon-ic_fluent_checkmark_circle_20_regular";
      $editor.toolbar.execute.control.label = 'Complete';
      $editor.toolbar.execute.control.readOnly = false
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
  
  
      $shell.InventoryTransformations.openToaster(
          'Successfully executed inventory transformation.',
          `Inventory transformation ${$editor.entity.LookupCode} is being completed.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on execute.', error);
  
      $editor.toolbar.execute.control.styles.resetStyle();
      $editor.toolbar.execute.control.icon = "icon-ic_fluent_checkmark_circle_20_regular";
      $editor.toolbar.execute.control.label = 'Complete';
      $editor.toolbar.execute.control.readOnly = false
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
  
  
  }
  }
  on_revert_clicked(event = null) {
    return this.on_revert_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_revert_clickedInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_revert_clicked');
  
  
  
  try {
  
      let confirm_reset = (await $shell.InventoryTransformations.openConfirmationDialog('Revert inventory transformation.', `Confirm to reset inventory transformation [${$editor.entity.LookupCode}] to Created status?`, 'Confirm', 'Cancel'))
  
  
      if (!confirm_reset) { return; }
  
      $editor.toolbar.revert.control.label = 'Reverting'
      $editor.toolbar.revert.control.icon = "icon datex-default-spinner"
      $editor.toolbar.process.control.readOnly = true
      $editor.toolbar.cancel.control.readOnly = true
      $editor.toolbar.discard.control.readOnly = true
      $editor.toolbar.revert.control.readOnly = true
      $editor.toolbar.execute.control.readOnly = true
  
      //if source line status is in Completed status, throw error.
      if ($editor.entity?.InventoryTransformationStatus?.Id === 3) {
          throw Error(`Inventory transformation is in [${$editor.entity?.InventoryTransformationStatus?.Name}] status.`)
      }
  
      //check for source lines
      let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
          inventory_transformation_id: $editor.inParams.inventory_transformation_id,
      }))?.result
  
  
      if ($utils.isDefined(source_line_check)) {
  
          for (let source_line of source_line_check) {
  
              //if source line status is completed, throw error.
              if (source_line_check[0]?.StatusId === 3) {
                  throw Error(`Source line is in [${source_line_check[0]?.InventoryTransformationLineStatus?.Name}] status.`)
              }
  
              //check for any hard allocations
              let hard_allocation_check = (await $datasources.InventoryTransformations.ds_get_hardallocationdetails_by_inventorytransformationsourcelineid.get({
                  inventory_transformation_source_line_id: source_line?.Id,
              }))?.result
  
              //delete hard allocation if it exists
              if ($utils.isDefined(hard_allocation_check)) {
  
                  for (let hard_allocation_detail of hard_allocation_check[0]?.Details) {
                      let delete_hard_allocation_details = (await $flows.Utilities.crud_delete_flow({
                          entitySet: 'HardAllocationDetails',
                          id: hard_allocation_detail?.Id
                      }))
  
                      if (delete_hard_allocation_details?.reason) {
                          throw Error(delete_hard_allocation_details?.reason)
                      }
                  }
  
                  let delete_hard_allocation = (await $flows.Utilities.crud_delete_flow({
                      entitySet: 'HardAllocations',
                      id: hard_allocation_check[0]?.Id,
                  }))
  
                  if (delete_hard_allocation?.reason) {
                      throw Error(delete_hard_allocation?.reason)
                  }
  
              }
  
              let inventory_source_line_payload: any = {}
              inventory_source_line_payload.StatusId = 1
              inventory_source_line_payload.ErrorMessage = null
  
              //update status to 1 and notes to null after deleting any hard allocations if any
              let update_row = (await $flows.Utilities.crud_update_flow({
                  entitySet: 'InventoryTransformationSourceLines',
                  id: source_line?.Id,
                  entity: inventory_source_line_payload
              }))
  
              if (update_row?.reason) {
                  throw Error(update_row?.reason)
              }
  
  
          }
  
          let inventory_transformation_payload: any = {}
          inventory_transformation_payload.StatusId = 1
  
          //update status to 1 and notes to null after updating source lines
          let update_row = (await $flows.Utilities.crud_update_flow({
              entitySet: 'InventoryTransformations',
              id: $editor?.entity?.Id,
              entity: inventory_transformation_payload
          }))
  
          if (update_row?.reason) {
              throw Error(update_row?.reason)
          }
  
  
      }
      else {
  
          let inventory_transformation_payload: any = {}
          inventory_transformation_payload.StatusId = 1
  
          //update status to 1 and notes to null after updating source lines
          let update_row = (await $flows.Utilities.crud_update_flow({
              entitySet: 'InventoryTransformations',
              id: $editor?.entity?.Id,
              entity: inventory_transformation_payload
          }))
  
          if (update_row?.reason) {
              throw Error(update_row?.reason)
          }
      }
  
      $editor.toolbar.revert.control.styles.resetStyle();
      $editor.toolbar.revert.control.icon = "icon-ic_fluent_arrow_undo_20_regular";
      $editor.toolbar.revert.control.label = 'Revert';
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
      $editor.toolbar.execute.control.readOnly = false
  
      await $editor.refresh()
      $shell.InventoryTransformations.openToaster(
          'Successfully reverted inventory transformation.',
          `Inventory transformation ${$editor.entity.LookupCode} has been reverted.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on revert.', error);
  
      $editor.toolbar.revert.control.styles.resetStyle();
      $editor.toolbar.revert.control.icon = "icon-ic_fluent_arrow_undo_20_regular";
      $editor.toolbar.revert.control.label = 'Revert';
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
      $editor.toolbar.execute.control.readOnly = false
  
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_cancel_clicked');
  
  
  
  
  $editor.toolbar.cancel.control.styles.resetStyle();
  $editor.toolbar.cancel.control.icon = "icon-ic_fluent_dismiss_circle_20_regular";
  $editor.toolbar.cancel.control.label = 'Process';
  $editor.toolbar.process.control.readOnly = false
  $editor.toolbar.cancel.control.readOnly = false
  $editor.toolbar.discard.control.readOnly = false
  $editor.toolbar.revert.control.readOnly = false
  $editor.toolbar.execute.control.readOnly = false
  
  
  try {
  
      let confirm_cancel = (await $shell.InventoryTransformations.openConfirmationDialog('Cancel inventory transformation.', `Confirm to cancel inventory transformation [${$editor.entity.LookupCode}]?`, 'Confirm', 'Cancel'))
  
  
      if (!confirm_cancel) { return }
  
      $editor.toolbar.cancel.control.label = 'Cancelling'
      $editor.toolbar.cancel.control.icon = "icon datex-default-spinner"
      $editor.toolbar.process.control.readOnly = true
      $editor.toolbar.cancel.control.readOnly = true
      $editor.toolbar.discard.control.readOnly = true
      $editor.toolbar.revert.control.readOnly = true
      $editor.toolbar.execute.control.readOnly = true
  
  
      //if source line status is in Completed status, throw error.
      if ($editor.entity?.InventoryTransformationStatus?.Id === 3) {
          throw Error(`Inventory transformation is in [${$editor.entity?.InventoryTransformationStatus?.Name}] status.`)
      }
  
      //check for source lines
      let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
          inventory_transformation_id: $editor.inParams.inventory_transformation_id,
      }))?.result
  
  
      //target line check
      let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
          inventory_transformation_id: $editor.inParams.inventory_transformation_id,
      }))?.result
  
  
      if ($utils.isDefined(target_line_check)) {
          for (let target_line of target_line_check) {
  
              let inventory_target_line_payload: any = {}
              inventory_target_line_payload.StatusId = 4
  
              //update status to 4 for targets
              let update_row = (await $flows.Utilities.crud_update_flow({
                  entitySet: 'InventoryTransformationTargetLines',
                  id: target_line?.Id,
                  entity: inventory_target_line_payload
              }))
  
              if (update_row?.reason) {
                  throw Error(update_row?.reason)
              }
  
          }
      }
  
  
      if ($utils.isDefined(source_line_check)) {
  
          for (let source_line of source_line_check) {
  
              //check for any hard allocations
              let hard_allocation_check = (await $datasources.InventoryTransformations.ds_get_hardallocationdetails_by_inventorytransformationsourcelineid.get({
                  inventory_transformation_source_line_id: source_line?.Id,
              }))?.result
  
              //if source line status is completed, throw error.
              if (source_line_check[0]?.StatusId === 3) {
                  throw Error(`Source line is in [${source_line_check[0]?.InventoryTransformationLineStatus?.Name}] status.`)
              }
              //delete hard allocation if it exists
              if ($utils.isDefined(hard_allocation_check)) {
  
                  for (let hard_allocation_detail of hard_allocation_check[0]?.Details) {
                      let delete_hard_allocation_details = (await $flows.Utilities.crud_delete_flow({
                          entitySet: 'HardAllocationDetails',
                          id: hard_allocation_detail?.Id
                      }))
  
                      if (delete_hard_allocation_details?.reason) {
                          throw Error(delete_hard_allocation_details?.reason)
                      }
                  }
  
                  let delete_hard_allocation = (await $flows.Utilities.crud_delete_flow({
                      entitySet: 'HardAllocations',
                      id: hard_allocation_check[0]?.Id,
                  }))
  
                  if (delete_hard_allocation?.reason) {
                      throw Error(delete_hard_allocation?.reason)
                  }
  
  
              }
  
              let inventory_source_line_payload: any = {}
              inventory_source_line_payload.StatusId = 4
              inventory_source_line_payload.ErrorMessage = null
  
              //update status to 1 and notes to null after deleting any hard allocations if any
              let update_row = (await $flows.Utilities.crud_update_flow({
                  entitySet: 'InventoryTransformationSourceLines',
                  id: source_line?.Id,
                  entity: inventory_source_line_payload
              }))
  
              if (update_row?.reason) {
                  throw Error(update_row?.reason)
              }
          }
      }
  
      //cancel transformation
      let inventory_transformation_payload: any = {}
      inventory_transformation_payload.StatusId = 4
      inventory_transformation_payload.Notes = `Cancelled on ${(new Date()).toISOString}`
  
      //update status to 4 and notes to null after updating source lines
      let update_row = (await $flows.Utilities.crud_update_flow({
          entitySet: 'InventoryTransformations',
          id: $editor?.entity?.Id,
          entity: inventory_transformation_payload
      }))
  
      if (update_row?.reason) {
          throw Error(update_row?.reason)
      }
  
  
  
      $editor.toolbar.cancel.control.styles.resetStyle();
      $editor.toolbar.cancel.control.icon = "icon-ic_fluent_dismiss_circle_20_regular";
      $editor.toolbar.cancel.control.label = 'Cancel';
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
      $editor.toolbar.execute.control.readOnly = false
  
      $shell.InventoryTransformations.openToaster(
          'Successfully cancelled inventory transformation.',
          `Inventory transformation ${$editor.entity.LookupCode} has been cancelled.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
      await $editor.refresh()
  
  
  
  
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on cancel row.', error);
  
      $editor.toolbar.cancel.control.styles.resetStyle();
      $editor.toolbar.cancel.control.icon = "icon-ic_fluent_dismiss_circle_20_regular";
      $editor.toolbar.cancel.control.label = 'Cancel';
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
      $editor.toolbar.execute.control.readOnly = false
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_delete_clicked');
  
  
  try {
  
      let valid_rows: number[] = []
      let invalid_rows: number[] = []
  
      //if transformation is completed, invalid to delete
      if ($editor.entity?.InventoryTransformationStatus?.Id === 3) {
          throw Error('Failed to delete inventory transformation. Inventory transformation is Completed')
      }
  
      else {
  
          //source line check
          let source_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_source_line_by_id_material_lot_vl_lppackaging.get({
              inventory_transformation_id: $editor.inParams.inventory_transformation_id,
          }))?.result
  
          //chheck source lines
          for (let source_line of source_line_check) {
  
              //if source line is completed invalid to delete
              if (source_line?.StatusId === 3) {
                  invalid_rows.push(source_line?.Id)
              }
  
              //check target lines
              else {
                  //target line check
                  let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
                      inventory_transformation_id: $editor.entity?.Id,
                  }))?.result
                  if ($utils.isDefined(target_line_check)) {
  
                      //if target line is not completed, valid
                      const valid_target = target_line_check.filter(tlc => tlc.StatusId != 3);
                      if ($utils.isDefined(valid_target)) {
                          valid_rows.push(...valid_target.map(v => v?.SourceLineId));
                      }
  
                      //if target line is completed, invalid
                      const invalid_target = target_line_check.filter(tlc => tlc.StatusId === 3);
                      if ($utils.isDefined(invalid_target)) {
                          invalid_rows.push(...invalid_target.map(v => v?.SourceLineId));
                      }
                  }
                  //if target does not exist, valid to delete source line
                  else {
                      valid_rows.push(source_line?.Id)
                  }
              }
  
          }
  
      }
  
      //remove duplicates if any
      valid_rows = Array.from(new Set(valid_rows));
      invalid_rows = Array.from(new Set(invalid_rows));
  
      let delete_lines: boolean
  
      if (invalid_rows?.length > 0) {
  
          throw Error('Cannot delete inventory transformation as their target/source lines is Completed.')
      }
      else {
          delete_lines = (await $shell.InventoryTransformations.openConfirmationDialog('Delete inventory transformation.', `Confirm to delete the inventory transformation?`, 'Confirm', 'Cancel'))
      }
  
      if (!delete_lines) { return }
  
  
      $editor.toolbar.discard.control.label = 'Deleting'
      $editor.toolbar.discard.control.icon = "icon datex-default-spinner"
      $editor.toolbar.process.control.readOnly = true
      $editor.toolbar.cancel.control.readOnly = true
      $editor.toolbar.discard.control.readOnly = true
      $editor.toolbar.revert.control.readOnly = true
      $editor.toolbar.execute.control.readOnly = true
  
  
      if ($utils.isDefined(valid_rows)) {
          for (let row of valid_rows) {
  
              //target line check
              let target_line_check = (await $datasources.InventoryTransformations.ds_inventory_transformation_target_lines_grid.get({
                  inventory_transformation_id: $editor.entity.Id,
                  source_line_number: row
              }))?.result
  
              if ($utils.isDefined(target_line_check)) {
                  for (let row of target_line_check) {
  
                      let delete_row = (await $flows.Utilities.crud_delete_flow({
                          entitySet: 'InventoryTransformationTargetLines',
                          id: row?.Id
                      }))
                      if ($utils.isDefined(delete_row?.reason)) {
                          throw Error(delete_row?.reason)
                      }
                  }
              }
  
              let delete_source_lines = (await $flows.InventoryTransformations.delete_inventory_transformation_source_line_flow({ source_line_id: row }))
              if ($utils.isDefined(delete_source_lines?.reason)) {
                  throw Error(delete_source_lines?.reason)
              }
          }
      }
  
  
      let delete_row = (await $flows.Utilities.crud_delete_flow({
          entitySet: 'InventoryTransformations',
          id: $editor.entity.Id
      }))
  
      if ($utils.isDefined(delete_row?.reason)) {
          throw Error(delete_row?.reason)
      }
  
  
      await $editor.close()
  
      $shell.InventoryTransformations.openToaster(
          'Successfully deleted inventory transformation.',
          `Inventory transformation ${$editor.entity.LookupCode} has been deleted.`,
          EToasterType.Success,
          {
              positionClass: EToasterPosition.topRight,
              tapToDismiss: true,
              timeOut: 5000,
              progressBar: true,
              newestOnTop: true
          });
  
  
      
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on delete.', error);
  
      $editor.toolbar.discard.control.styles.resetStyle();
      $editor.toolbar.discard.control.icon = "icon-ic_fluent_delete_20_regular";
      $editor.toolbar.discard.control.label = 'Delete';
      $editor.toolbar.execute.control.readOnly = false
      $editor.toolbar.process.control.readOnly = false
      $editor.toolbar.cancel.control.readOnly = false
      $editor.toolbar.discard.control.readOnly = false
      $editor.toolbar.revert.control.readOnly = false
  
  }
  
  
  }
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_save');
  
  
  
  
  try {
  
      let inventory_transformation_payload: any = {}
  
      if ($editor?.fields?.project?.control.isChanged) {
          inventory_transformation_payload.ProjectId = $editor?.fields?.project?.control.value
      }
  
      if ($editor?.fields?.lookupcode?.control.isChanged) {
          let lookupcode_check = (await $datasources.InventoryTransformations.ds_get_inventory_transformation_by_id_lookupcode.get({ lookup_code: $editor.fields.lookupcode.control.value }))?.result[0]?.Id
  
          if ($utils.isDefined(lookupcode_check)) {
              throw ('Lookupcode already exists. Please choose a different lookupcode.')
          }
  
          inventory_transformation_payload.Lookupcode = $editor?.fields?.lookupcode?.control.value
      }
  
      if ($editor?.fields?.warehouse?.control.isChanged) {
          inventory_transformation_payload.WarehouseId = $editor?.fields?.warehouse?.control.value
      }
  
      if (!$utils.isAllDefined($editor?.fields?.project?.control.value, $editor?.fields?.warehouse?.control.value, $editor?.fields?.lookupcode?.control.value)) { return; }
  
  
      let update_row = (await $flows.Utilities.crud_update_flow({
          entitySet: 'InventoryTransformations',
          id: $editor.entity.Id,
          entity: inventory_transformation_payload
      }));
  
      if ($utils.isDefined(update_row?.reason)) {
          throw Error(update_row?.reason)
      }
      await $editor.refresh();
  
  
  } catch (error) {
      $shell.InventoryTransformations.showErrorDetails('Save', 'Error on save.', error);
  }
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $editor: FootprintManager_inventory_transformation_editorComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_inventory_transformation_editor_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'inventory_transformation_editor.on_owner_change');
  $editor.fields.project.control.value = null
  }
  //#endregion private flows
  
}
