import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Attachments_ds_get_attachments_by_entityService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { entityType: string, entityKeys: { name?: string, value?: any }[], $top?: number, $skip?: number }): Promise<{ result?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number }[], totalCount?: number }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.entityType)) {
      missingRequiredInParams.push('\'entityType\'');
    }
    if (isNil(inParams.entityKeys)) {
      missingRequiredInParams.push('\'entityKeys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Attachments/datasources/ds_get_attachments_by_entity/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { entityType?: string, entityKeys?: { name?: string, value?: any }[], $keys: number[] }): Promise<{ result?: { Id?: number, FileName?: string, FileExtension?: string, Description?: string, CreatedSysDateTime?: string, ModifiedSysDateTime?: string, FileSize?: number }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Attachments/datasources/ds_get_attachments_by_entity/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      entityType: string, entityKeys: { name?: string, value?: any }[]    }) {
        if(isNil(inParams.entityType)) {
          return true; 
        }
        if(isNil(inParams.entityKeys)) {
          return true; 
        }
      return false;
    }
}
