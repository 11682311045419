import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './ExcelMaterialImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelMaterialImport_material_import_staging_checkService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { requests?: { Id?: string, ImportRequestId?: string, ImportErrorMessage?: string }[] }): Promise<{ messages?: any[], success?: boolean }> 
  {
    let url = `${environment.backendUrl}api/ExcelMaterialImport/functions/material_import_staging_check`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

