<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden && !filtersets.newGroup1.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden && !filters.owner.removed" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}} {{filters.owner.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.owner.label + (filters.owner.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.owner.label}}<span *ngIf="filters.owner.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                  [tooltip]="filters.owner.control.tooltip"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden && !filters.project.removed" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}} {{filters.project.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                  [tooltip]="filters.project.control.tooltip"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-materials" *ngIf="!filters.materials.hidden && !filters.materials.removed" 
                                  class="field-container standard {{filters.materials.invalid ? 'invalid' : ''}} {{filters.materials.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.materials.styles.style"
                                  [ngClass]="filters.materials.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.materials.label + (filters.materials.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.materials.label}}<span *ngIf="filters.materials.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Materials-materials_by_project_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.materials.control.type"
                                  formControlName="materials"
                                  (displayTextChange)="filters.materials.control.displayText=$event"
                                  [placeholder]="filters.materials.control.placeholder"
                                  [styles]="filters.materials.control.styles"
                                  [tooltip]="filters.materials.control.tooltip"
                                [projectId]="$fields_materials_selector_inParams_projectId"
                              >
                              </Materials-materials_by_project_dd_multi>
                              <ng-container *ngIf="filters.materials.invalid">
                                <ng-container *ngFor="let error of filters.materials.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden && !filters.warehouse.removed" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}} {{filters.warehouse.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                                  [tooltip]="filters.warehouse.control.tooltip"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-include_cancelled" *ngIf="!filters.include_cancelled.hidden && !filters.include_cancelled.removed" 
                                  class="field-container standard {{filters.include_cancelled.invalid ? 'invalid' : ''}} {{filters.include_cancelled.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.include_cancelled.styles.style"
                                  [ngClass]="filters.include_cancelled.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.include_cancelled.label + (filters.include_cancelled.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.include_cancelled.label}}<span *ngIf="filters.include_cancelled.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-slide-toggle data-cy="slideToggle" formControlName="include_cancelled"
                                            color="primary"
                                            class="datex-toggle"
                                            [ngStyle]="filters.include_cancelled.control.styles.style"
                                            [ngClass]="filters.include_cancelled.control.styles.classes"
                                            [matTooltip]="filters.include_cancelled.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.include_cancelled.control.label}}</mat-slide-toggle>
                              <ng-container *ngIf="filters.include_cancelled.invalid">
                                <ng-container *ngFor="let error of filters.include_cancelled.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-include_invoiced" *ngIf="!filters.include_invoiced.hidden && !filters.include_invoiced.removed" 
                                  class="field-container standard {{filters.include_invoiced.invalid ? 'invalid' : ''}} {{filters.include_invoiced.control.disabled ? 'field-container-disabled' : ''}}"
                                  [ngStyle]="filters.include_invoiced.styles.style"
                                  [ngClass]="filters.include_invoiced.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.include_invoiced.label + (filters.include_invoiced.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.include_invoiced.label}}<span *ngIf="filters.include_invoiced.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <mat-slide-toggle data-cy="slideToggle" formControlName="include_invoiced"
                                            color="primary"
                                            class="datex-toggle"
                                            [ngStyle]="filters.include_invoiced.control.styles.style"
                                            [ngClass]="filters.include_invoiced.control.styles.classes"
                                            [matTooltip]="filters.include_invoiced.control.tooltip"
                                            matTooltipClass="datex-control-tooltip">{{filters.include_invoiced.control.label}}</mat-slide-toggle>
                              <ng-container *ngIf="filters.include_invoiced.invalid">
                                <ng-container *ngFor="let error of filters.include_invoiced.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
              
                          <div class="fieldset-details" *ngIf="!filtersets.newGroup1.expanded">
              
              
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.billing_records_total_invoiced_by_date_widget.hidden && !widgets.billing_records_total_invoiced_by_date_widget.removed" class="widget-container">
            <Invoices-billing_records_total_invoiced_by_date_widget
            #$widgets_billing_records_total_invoiced_by_date_widget 
              [date]="$widgets_billing_records_total_invoiced_by_date_widget_inParams_date"
              [ownerId]="$widgets_billing_records_total_invoiced_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_billing_records_total_invoiced_by_date_widget_inParams_projectId"
              [warehouseIds]="$widgets_billing_records_total_invoiced_by_date_widget_inParams_warehouseIds"
              [materialIds]="$widgets_billing_records_total_invoiced_by_date_widget_inParams_materialIds"
            ($refreshEvent)="refresh(false, false, '$widgets_billing_records_total_invoiced_by_date_widget')"
            >
            </Invoices-billing_records_total_invoiced_by_date_widget>
          </div>
          <div *ngIf="!widgets.billing_records_total_billed_by_date_widget.hidden && !widgets.billing_records_total_billed_by_date_widget.removed" class="widget-container">
            <Invoices-billing_record_total_billed_by_date_widget
            #$widgets_billing_records_total_billed_by_date_widget 
              [date]="$widgets_billing_records_total_billed_by_date_widget_inParams_date"
              [ownerId]="$widgets_billing_records_total_billed_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_billing_records_total_billed_by_date_widget_inParams_projectId"
              [warehouseIds]="$widgets_billing_records_total_billed_by_date_widget_inParams_warehouseIds"
              [materialIds]="$widgets_billing_records_total_billed_by_date_widget_inParams_materialIds"
            ($refreshEvent)="refresh(false, false, '$widgets_billing_records_total_billed_by_date_widget')"
            >
            </Invoices-billing_record_total_billed_by_date_widget>
          </div>
          <div *ngIf="!widgets.billing_record_total_billed_by_current_month.hidden && !widgets.billing_record_total_billed_by_current_month.removed" class="widget-container">
            <Invoices-billing_record_total_billed_by_current_month_widget
            #$widgets_billing_record_total_billed_by_current_month 
              [date]="$widgets_billing_record_total_billed_by_current_month_inParams_date"
              [owner_id]="$widgets_billing_record_total_billed_by_current_month_inParams_owner_id"
              [project_id]="$widgets_billing_record_total_billed_by_current_month_inParams_project_id"
              [warehouse_ids]="$widgets_billing_record_total_billed_by_current_month_inParams_warehouse_ids"
              [material_ids]="$widgets_billing_record_total_billed_by_current_month_inParams_material_ids"
            ($refreshEvent)="refresh(false, false, '$widgets_billing_record_total_billed_by_current_month')"
            >
            </Invoices-billing_record_total_billed_by_current_month_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="tabs.billing_records.hasTabToShow" class="tab" data-cy="tab-billing_records">
              <h2 [className]="tabs.billing_records.active? 'active': ''" (click)="tabs.billing_records.activate()">{{tabs.billing_records.title}}</h2>
            </div>
          </div>
        
              <FootprintManager-billing_records_grid *ngIf="tabs.billing_records.active"
              #$tabs_billing_records
              [ownerId]="$tabs_billing_records_billing_records_grid_inParams_ownerId"
              [projectId]="$tabs_billing_records_billing_records_grid_inParams_projectId"
              [materialIds]="$tabs_billing_records_billing_records_grid_inParams_materialIds"
              [warehouseIds]="$tabs_billing_records_billing_records_grid_inParams_warehouseIds"
              [includeCancelled]="$tabs_billing_records_billing_records_grid_inParams_includeCancelled"
              [includeInvoiced]="$tabs_billing_records_billing_records_grid_inParams_includeInvoiced"
              ($refreshEvent)="refresh(false, false, '$tabs_billing_records')">
              </FootprintManager-billing_records_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>