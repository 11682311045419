import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { BarTender_azure_blob_storage_create_directory_flowService } from './BarTender.flow.index';
import { BarTender_azure_blob_storage_delete_item_flowService } from './BarTender.flow.index';
import { BarTender_azure_blob_storage_get_file_content_flowService } from './BarTender.flow.index';
import { BarTender_azure_blob_storage_upload_file_flowService } from './BarTender.flow.index';
import { BarTender_azure_blob_storage_upload_file_string_flowService } from './BarTender.flow.index';
import { BarTender_generate_label_to_sftp_flowService } from './BarTender.flow.index';
import { BarTender_initialize_configurationsService } from './BarTender.flow.index';
import { BarTender_print_asn_license_plate_labels_flowService } from './BarTender.flow.index';

import { $frontendTypes } from './BarTender.frontend.types'

@Injectable({ providedIn: 'root' })
export class BarTender_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public BarTender: BarTender_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _azure_blob_storage_create_directory_flow: BarTender_azure_blob_storage_create_directory_flowService;
  public async azure_blob_storage_create_directory_flow(inParams: { directory_path: string, directory_name: string }): Promise< { reasons?: string[] }> {
    if(!this._azure_blob_storage_create_directory_flow) { 
      this._azure_blob_storage_create_directory_flow = this.injector.get(BarTender_azure_blob_storage_create_directory_flowService);
    }
    return this._azure_blob_storage_create_directory_flow.run(inParams);
  }
   
   

   
 
  private _azure_blob_storage_delete_item_flow: BarTender_azure_blob_storage_delete_item_flowService;
  public async azure_blob_storage_delete_item_flow(inParams: { file_path: string, file_name: string, use_explicit_path?: boolean }): Promise< { reasons?: string[] }> {
    if(!this._azure_blob_storage_delete_item_flow) { 
      this._azure_blob_storage_delete_item_flow = this.injector.get(BarTender_azure_blob_storage_delete_item_flowService);
    }
    return this._azure_blob_storage_delete_item_flow.run(inParams);
  }
   
   

   
 
  private _azure_blob_storage_get_file_content_flow: BarTender_azure_blob_storage_get_file_content_flowService;
  public async azure_blob_storage_get_file_content_flow(inParams: { file_path: string, file_name: string, use_explicit_path?: boolean }): Promise< { file_content?: Blob }> {
    if(!this._azure_blob_storage_get_file_content_flow) { 
      this._azure_blob_storage_get_file_content_flow = this.injector.get(BarTender_azure_blob_storage_get_file_content_flowService);
    }
    return this._azure_blob_storage_get_file_content_flow.run(inParams);
  }
   
   

   
 
  private _azure_blob_storage_upload_file_flow: BarTender_azure_blob_storage_upload_file_flowService;
  public async azure_blob_storage_upload_file_flow(inParams: { file_path: string, file_name: string, file_content: Blob }): Promise< { reasons?: string[] }> {
    if(!this._azure_blob_storage_upload_file_flow) { 
      this._azure_blob_storage_upload_file_flow = this.injector.get(BarTender_azure_blob_storage_upload_file_flowService);
    }
    return this._azure_blob_storage_upload_file_flow.run(inParams);
  }
   
   

   
 
  private _azure_blob_storage_upload_file_string_flow: BarTender_azure_blob_storage_upload_file_string_flowService;
  public async azure_blob_storage_upload_file_string_flow(inParams: { file_path: string, file_name: string, file_content: string, file_type: string }): Promise< { reasons?: string[] }> {
    if(!this._azure_blob_storage_upload_file_string_flow) { 
      this._azure_blob_storage_upload_file_string_flow = this.injector.get(BarTender_azure_blob_storage_upload_file_string_flowService);
    }
    return this._azure_blob_storage_upload_file_string_flow.run(inParams);
  }
   
   

   
 
  private _generate_label_to_sftp_flow: BarTender_generate_label_to_sftp_flowService;
  public async generate_label_to_sftp_flow(inParams: { options: { label_path: string, printer_name: string, quantity?: number, file_name?: string }, variables: { name: string, value: any }[] }): Promise< { reasons?: string[] }> {
    if(!this._generate_label_to_sftp_flow) { 
      this._generate_label_to_sftp_flow = this.injector.get(BarTender_generate_label_to_sftp_flowService);
    }
    return this._generate_label_to_sftp_flow.run(inParams);
  }
   
   

   
 
  private _initialize_configurations: BarTender_initialize_configurationsService;
  public async initialize_configurations(inParams: {  }): Promise<void> {
    if(!this._initialize_configurations) { 
      this._initialize_configurations = this.injector.get(BarTender_initialize_configurationsService);
    }
    return this._initialize_configurations.run(inParams);
  }
   
   

   
 
  private _print_asn_license_plate_labels_flow: BarTender_print_asn_license_plate_labels_flowService;
  public async print_asn_license_plate_labels_flow(inParams: { orderId: number, licensePlateId?: number, options?: { printerName?: string, labelPath?: string, quantity?: number } }): Promise< { reasons?: string[] }> {
    if(!this._print_asn_license_plate_labels_flow) { 
      this._print_asn_license_plate_labels_flow = this.injector.get(BarTender_print_asn_license_plate_labels_flowService);
    }
    return this._print_asn_license_plate_labels_flow.run(inParams);
  }
   
   

   
}
