import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './FootprintManager.frontend.types'

@Injectable({ providedIn: 'root' })
export class FootprintManager_get_inventory_count_status_flow_altService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { inventoryCountIds: number[], optimalInputs?: { inventoryCountId?: number, tasks?: { operationCodeId?: number, statusId?: number }[], statusId?: number, statusName?: string }[] }): Promise<{ inventoryCounts?: { id?: number, actualStatusId?: number, actualStatusName?: string, descriptiveStatusName?: string, actionValidations?: { failReleaseReasons?: string[], failHoldReasons?: string[], failDuplicateReasons?: string[], failCompleteReasons?: string[], failCancelReasons?: string[], failDeleteReasons?: string[], allowEdit?: boolean } }[], errors?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventoryCountIds)) {
      missingRequiredInParams.push('\'inventoryCountIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootprintManager/functions/get_inventory_count_status_flow_alt`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

