import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Returns_ShellService } from './Returns.shell.service';
import { Returns_OperationService } from './Returns.operation.service';
import { Returns_DatasourceService } from './Returns.datasource.index';
import { Returns_FlowService } from './Returns.flow.index';
import { Returns_ReportService } from './Returns.report.index';
import { Returns_LocalizationService } from './Returns.localization.service';
import { Returns_return_license_plates_by_shippingcontainer_dd_ComponentContextService } from './Returns.return_license_plates_by_shippingcontainer_dd.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Returns.frontend.types'
import { $frontendTypes as $types} from './Returns.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Returns-return_license_plates_by_shippingcontainer_dd_multi',
  templateUrl: './Returns.return_license_plates_by_shippingcontainer_dd_multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Returns_return_license_plates_by_shippingcontainer_dd_multiComponent),
      multi: true
    }
  ]
})
export class Returns_return_license_plates_by_shippingcontainer_dd_multiComponent extends BaseComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      || this.$hasMissingRequiredInParams
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;
  @Input() tooltip = '';

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;

  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return true;
  }

  get isSingle() {
    return false;
  }


  inParams: { shippingContainerId: number, warehouseId: number, locationId: number } = { shippingContainerId: null, warehouseId: null, locationId: null };
  @Input('shippingContainerId') set $inParams_shippingContainerId(value: any) {
    this.inParams['shippingContainerId'] = value;
  }
  get $inParams_shippingContainerId(): any {
    return this.inParams['shippingContainerId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('locationId') set $inParams_locationId(value: any) {
    this.inParams['locationId'] = value;
  }
  get $inParams_locationId(): any {
    return this.inParams['locationId'] ;
  }

  fullTextSearch: string;

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: Returns_ShellService,
    private $datasources: Returns_DatasourceService,
    private $flows: Returns_FlowService,
    private $reports: Returns_ReportService,
    private $localization: Returns_LocalizationService,
    private $operations: Returns_OperationService,
    private $logger: CleanupLoggerService,
    private $context: Returns_return_license_plates_by_shippingcontainer_dd_ComponentContextService,
    private _elementRef: ElementRef
    ) {
      super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.shippingContainerId)) {
        this.$missingRequiredInParams.push('shippingContainerId');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
      if(isNil(this.inParams.locationId)) {
        this.$missingRequiredInParams.push('locationId');
      }
  }

  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): number | number[] {
    return this._innerValue;
  }

  set value(val:  number | number[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  displayWithFn(value: any): Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}> {
    const promise = new Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}>((resolve, reject) => {

      const $selector = this;
      const $utils = this.$utils;

      this.fullTextSearch = null;
      const $fullTextSearch = null;
      this.$datasources.Returns.ds_return_license_plates_by_shippingcontainer_dd.getByKeys({
        $keys: value,
        shippingContainerId:  $selector.inParams.shippingContainerId ,
        fullTextSearch:  $selector.fullTextSearch ,
      }).then(result => {
        const selected = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          const e: { Id?: number, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string } } = found 
            ?? { 
              Id : k
            };

          const $option = { entity: e };
          const $entity = e;
          const $resultOption = { key: null, name: found ? $option.entity.ActualTargetLicensePlate.LookupCode : 'No data to display', disabled: false, selected:true };
          $resultOption.key = $option.entity.Id;
          return $resultOption;
        });
        const displayText = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          if (found) {
            const $option = { entity: found };
            const $entity = found;
            return $option.entity.ActualTargetLicensePlate.LookupCode;
          } else {
            return 'No data to display';
          }
        })
        .join(
           ', '
        );
        this.displayTextChange.emit(displayText);
        resolve({displayText, selected});
      });
    });
    return promise;
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {

    const promise = new Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>((resolve, reject) => {

      const $selector = this;
      const $utils = this.$utils;

      this.fullTextSearch = filterText;
      const $fullTextSearch = filterText;
      this.$datasources.Returns.ds_return_license_plates_by_shippingcontainer_dd.getList({
        $top: 10,
        $skip: 0,
        shippingContainerId:  $selector.inParams.shippingContainerId ,
        fullTextSearch:  $selector.fullTextSearch ,
      }).then(result => {
        resolve({
          list: result.result.map(item => {
            const $option = { entity: item };
            const $entity = item;
            const $resultOption = { key: null, name: $option.entity.ActualTargetLicensePlate.LookupCode, disabled: null };
            $resultOption.key = $option.entity.Id;
            return $resultOption;
          }),
          totalCount: result.totalCount,
          top: 10
        });
      });

    });

    return promise;

  }

  //#region private flows
  on_action_clicked(event = null) {
    return this.on_action_clickedInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_action_clickedInternal(
    $selector: Returns_return_license_plates_by_shippingcontainer_dd_multiComponent,
  
    $shell: Returns_ShellService,
    $datasources: Returns_DatasourceService,
    $flows: Returns_FlowService,
    $reports: Returns_ReportService,
    $settings: SettingsValuesService,
    $operations: Returns_OperationService,
    $utils: UtilsService,
    $context: Returns_return_license_plates_by_shippingcontainer_dd_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Returns_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Returns', 'return_license_plates_by_shippingcontainer_dd.on_action_clicked');
  let licenseplateId: number;
  
  // Create Planned ASN License plate on provided order/shipment
  // Get LicensePlate
  let licenseplate = (await $datasources.Returns.ds_get_license_plate_by_licensePlateCode.get({ licensePlateCode: $selector.fullTextSearch, warehouseId: $selector.inParams.warehouseId })).result;
  
  if ($utils.isDefined(licenseplate)) {
      throw new Error(`License plate '${$selector.fullTextSearch}' already exists within the warehouse.`);
  }
  else {
      let licenseplateId = (await $flows.Returns.create_licenseplate_flow({
          properties: {
              "Archived": false,
              "ContainsPortalVisibleAttachments": false,
              "LocationId": $selector.inParams.locationId,
              "LookupCode": $selector.fullTextSearch,
              "ShipmentId": null,
              "StatusId": 1,
              "TypeId": 5,
              "WarehouseId": $selector.inParams.warehouseId
          }
      })).licenseplateId;
  
      $selector.value = licenseplateId;
  }
  }
  //#endregion private flows
}
