import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { Dynamics365BusinessCentral_homeComponent } from './Dynamics365BusinessCentral.home.component';
import { Dynamics365BusinessCentral_initial_setup_formComponent } from './Dynamics365BusinessCentral.initial_setup_form.component';
import { Dynamics365BusinessCentral_bc_editorComponent } from './Dynamics365BusinessCentral.bc_editor.component';
import { Dynamics365BusinessCentral_invoice_line_gridComponent } from './Dynamics365BusinessCentral.invoice_line_grid.component';
import { Dynamics365BusinessCentral_invoice_line_grouped_gridComponent } from './Dynamics365BusinessCentral.invoice_line_grouped_grid.component';
import { Dynamics365BusinessCentral_invoices_gridComponent } from './Dynamics365BusinessCentral.invoices_grid.component';
import { Dynamics365BusinessCentral_local_configuration_gridComponent } from './Dynamics365BusinessCentral.local_configuration_grid.component';
import { Dynamics365BusinessCentral_material_gridComponent } from './Dynamics365BusinessCentral.material_grid.component';
import { Dynamics365BusinessCentral_material_update_gridComponent } from './Dynamics365BusinessCentral.material_update_grid.component';
import { Dynamics365BusinessCentral_messages_fail_gridComponent } from './Dynamics365BusinessCentral.messages_fail_grid.component';
import { Dynamics365BusinessCentral_messages_success_gridComponent } from './Dynamics365BusinessCentral.messages_success_grid.component';
import { Dynamics365BusinessCentral_order_po_queue_gridComponent } from './Dynamics365BusinessCentral.order_po_queue_grid.component';
import { Dynamics365BusinessCentral_order_po_queue_update_gridComponent } from './Dynamics365BusinessCentral.order_po_queue_update_grid.component';
import { Dynamics365BusinessCentral_Invoice_export_hubComponent } from './Dynamics365BusinessCentral.Invoice_export_hub.component';
import { Dynamics365BusinessCentral_bc_hubComponent } from './Dynamics365BusinessCentral.bc_hub.component';
import { Dynamics365BusinessCentral_date_type_singleComponent } from './Dynamics365BusinessCentral.date_type_single.component';
import { Dynamics365BusinessCentral_enviroment_singleComponent } from './Dynamics365BusinessCentral.enviroment_single.component';
import { Dynamics365BusinessCentral_operation_singleComponent } from './Dynamics365BusinessCentral.operation_single.component';
import { Dynamics365BusinessCentral_date_type_multiComponent } from './Dynamics365BusinessCentral.date_type_multi.component';
import { Dynamics365BusinessCentral_enviroment_multiComponent } from './Dynamics365BusinessCentral.enviroment_multi.component';
import { Dynamics365BusinessCentral_operation_multiComponent } from './Dynamics365BusinessCentral.operation_multi.component';
import { Dynamics365BusinessCentral_count_by_groups_widgetComponent } from './Dynamics365BusinessCentral.count_by_groups_widget.component';
import { Dynamics365BusinessCentral_inbound_order_todayComponent } from './Dynamics365BusinessCentral.inbound_order_today.component';
import { Dynamics365BusinessCentral_materials_todayComponent } from './Dynamics365BusinessCentral.materials_today.component';
import { Dynamics365BusinessCentral_number_of_errors_widgetComponent } from './Dynamics365BusinessCentral.number_of_errors_widget.component';
import { Dynamics365BusinessCentral_orders_fail_countComponent } from './Dynamics365BusinessCentral.orders_fail_count.component';
import { Dynamics365BusinessCentral_outbound_orders_todayComponent } from './Dynamics365BusinessCentral.outbound_orders_today.component';
import { Dynamics365BusinessCentral_cardComponent } from './Dynamics365BusinessCentral.card.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private $logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Dynamics365BusinessCentral: Dynamics365BusinessCentral_ShellService = this;

  public openhome(replaceCurrentView?: boolean) {
    this.$logger.log('Dynamics365BusinessCentral', 'home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'home',
        referenceName: 'Dynamics365BusinessCentral_home',
        component: Dynamics365BusinessCentral_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Dynamics365BusinessCentral', 'home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_homeComponent,
      'home',
      mode,
      dialogSize
    )
  }
  public openinitial_setup_form(replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Initial setup',
        referenceName: 'Dynamics365BusinessCentral_initial_setup_form',
        component: Dynamics365BusinessCentral_initial_setup_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openinitial_setup_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_initial_setup_formComponent,
      'Initial setup',
      mode,
      dialogSize
    )
  }
  public openbc_editor(replaceCurrentView?: boolean) {
    this.$logger.log('Dynamics365BusinessCentral', 'bc_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'bc_editor',
        referenceName: 'Dynamics365BusinessCentral_bc_editor',
        component: Dynamics365BusinessCentral_bc_editorComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openbc_editorDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Dynamics365BusinessCentral', 'bc_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_bc_editorComponent,
      'bc_editor',
      mode,
      dialogSize
    )
  }
  public openinvoice_line_grid(inParams:{ InvoiceId: number, line?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Invoice lines',
        referenceName: 'Dynamics365BusinessCentral_invoice_line_grid',
        component: Dynamics365BusinessCentral_invoice_line_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_line_gridDialog(
    inParams:{ InvoiceId: number, line?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_invoice_line_gridComponent,
      'Invoice lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoice_line_grouped_grid(inParams:{ InvoiceId?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'invoice_line_grouped_grid',
        referenceName: 'Dynamics365BusinessCentral_invoice_line_grouped_grid',
        component: Dynamics365BusinessCentral_invoice_line_grouped_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoice_line_grouped_gridDialog(
    inParams:{ InvoiceId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_invoice_line_grouped_gridComponent,
      'invoice_line_grouped_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openinvoices_grid(inParams:{ ownerId?: number, projectId?: number, statusIds?: number, retryInsert?: string, transaction_id?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Invoices',
        referenceName: 'Dynamics365BusinessCentral_invoices_grid',
        component: Dynamics365BusinessCentral_invoices_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openinvoices_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, statusIds?: number, retryInsert?: string, transaction_id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ transaction_id?: string, errmessage?: string }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_invoices_gridComponent,
      'Invoices',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openlocal_configuration_grid(inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string } }, replaceCurrentView?: boolean) {
    this.$logger.log('Dynamics365BusinessCentral', 'local_configuration_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'local_configuration_grid',
        referenceName: 'Dynamics365BusinessCentral_local_configuration_grid',
        component: Dynamics365BusinessCentral_local_configuration_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openlocal_configuration_gridDialog(
    inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Dynamics365BusinessCentral', 'local_configuration_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_local_configuration_gridComponent,
      'local_configuration_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmaterial_grid(replaceCurrentView?: boolean) {
    this.$logger.log('Dynamics365BusinessCentral', 'material_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'material_grid',
        referenceName: 'Dynamics365BusinessCentral_material_grid',
        component: Dynamics365BusinessCentral_material_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openmaterial_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Dynamics365BusinessCentral', 'material_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_material_gridComponent,
      'material_grid',
      mode,
      dialogSize
    )
  }
  public openmaterial_update_grid(inParams:{ id?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Dynamics365BusinessCentral', 'material_update_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'material_update_grid',
        referenceName: 'Dynamics365BusinessCentral_material_update_grid',
        component: Dynamics365BusinessCentral_material_update_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmaterial_update_gridDialog(
    inParams:{ id?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Dynamics365BusinessCentral', 'material_update_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_material_update_gridComponent,
      'material_update_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmessages_fail_grid(inParams:{ application_name?: string, transaction_id?: string, customerr?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Post fail',
        referenceName: 'Dynamics365BusinessCentral_messages_fail_grid',
        component: Dynamics365BusinessCentral_messages_fail_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessages_fail_gridDialog(
    inParams:{ application_name?: string, transaction_id?: string, customerr?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ InvoiceId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_messages_fail_gridComponent,
      'Post fail',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openmessages_success_grid(inParams:{ transaction_id?: string, application_name?: string, customerr?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Post success',
        referenceName: 'Dynamics365BusinessCentral_messages_success_grid',
        component: Dynamics365BusinessCentral_messages_success_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openmessages_success_gridDialog(
    inParams:{ transaction_id?: string, application_name?: string, customerr?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ InvoiceId?: number }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_messages_success_gridComponent,
      'Post success',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openorder_po_queue_grid(replaceCurrentView?: boolean) {
    this.$logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'order_po_queue_grid',
        referenceName: 'Dynamics365BusinessCentral_order_po_queue_grid',
        component: Dynamics365BusinessCentral_order_po_queue_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openorder_po_queue_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_order_po_queue_gridComponent,
      'order_po_queue_grid',
      mode,
      dialogSize
    )
  }
  public openorder_po_queue_update_grid(inParams:{ orderid?: string }, replaceCurrentView?: boolean) {
    this.$logger.log('Dynamics365BusinessCentral', 'order_po_queue_update_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'order_po_queue_update_grid',
        referenceName: 'Dynamics365BusinessCentral_order_po_queue_update_grid',
        component: Dynamics365BusinessCentral_order_po_queue_update_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openorder_po_queue_update_gridDialog(
    inParams:{ orderid?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Dynamics365BusinessCentral', 'order_po_queue_update_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_order_po_queue_update_gridComponent,
      'order_po_queue_update_grid',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openInvoice_export_hub(inParams:{ Customerr?: string }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: ' Invoice_export_hub',
        referenceName: 'Dynamics365BusinessCentral_Invoice_export_hub',
        component: Dynamics365BusinessCentral_Invoice_export_hubComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openInvoice_export_hubDialog(
    inParams:{ Customerr?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_Invoice_export_hubComponent,
      ' Invoice_export_hub',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openbc_hub(replaceCurrentView?: boolean) {
    this.$logger.log('Dynamics365BusinessCentral', 'bc_hub');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'BC',
        referenceName: 'Dynamics365BusinessCentral_bc_hub',
        component: Dynamics365BusinessCentral_bc_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openbc_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.$logger.log('Dynamics365BusinessCentral', 'bc_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Dynamics365BusinessCentral_bc_hubComponent,
      'BC',
      mode,
      dialogSize
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Dynamics365BusinessCentral_home') {
      this.$logger.log('Dynamics365BusinessCentral', 'home');
      const title = 'home';
      const component = Dynamics365BusinessCentral_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_initial_setup_form') {
      const title = 'Initial setup';
      const component = Dynamics365BusinessCentral_initial_setup_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_bc_editor') {
      this.$logger.log('Dynamics365BusinessCentral', 'bc_editor');
      const title = 'bc_editor';
      const component = Dynamics365BusinessCentral_bc_editorComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_invoice_line_grid') {
      const title = 'Invoice lines';
      const component = Dynamics365BusinessCentral_invoice_line_gridComponent;
      const inParams:{ InvoiceId: number, line?: number } = { InvoiceId: null, line: null };
      if (!isNil(params.get('InvoiceId'))) {
        const paramValueString = params.get('InvoiceId');
        inParams.InvoiceId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('line'))) {
        const paramValueString = params.get('line');
        inParams.line = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_invoice_line_grouped_grid') {
      const title = 'invoice_line_grouped_grid';
      const component = Dynamics365BusinessCentral_invoice_line_grouped_gridComponent;
      const inParams:{ InvoiceId?: number } = { InvoiceId: null };
      if (!isNil(params.get('InvoiceId'))) {
        const paramValueString = params.get('InvoiceId');
        inParams.InvoiceId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_invoices_grid') {
      const title = 'Invoices';
      const component = Dynamics365BusinessCentral_invoices_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, statusIds?: number, retryInsert?: string, transaction_id?: string } = { ownerId: null, projectId: null, statusIds: null, retryInsert: null, transaction_id: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('retryInsert'))) {
        const paramValueString = params.get('retryInsert');
        // TODO: date
        inParams.retryInsert = paramValueString;
              }
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_local_configuration_grid') {
      this.$logger.log('Dynamics365BusinessCentral', 'local_configuration_grid');
      const title = 'local_configuration_grid';
      const component = Dynamics365BusinessCentral_local_configuration_gridComponent;
      const inParams:{ application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string } } = { application_name: null, user_name: null, column_names: { string_1: null, string_2: null, string_3: null }, filter_values: { string_1: null, string_2: null, string_3: null } };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('user_name'))) {
        const paramValueString = params.get('user_name');
        // TODO: date
        inParams.user_name = paramValueString;
              }
      if (!isNil(params.get('column_names'))) {
        const paramValueString = params.get('column_names');
        inParams.column_names = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('filter_values'))) {
        const paramValueString = params.get('filter_values');
        inParams.filter_values = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_material_grid') {
      this.$logger.log('Dynamics365BusinessCentral', 'material_grid');
      const title = 'material_grid';
      const component = Dynamics365BusinessCentral_material_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_material_update_grid') {
      this.$logger.log('Dynamics365BusinessCentral', 'material_update_grid');
      const title = 'material_update_grid';
      const component = Dynamics365BusinessCentral_material_update_gridComponent;
      const inParams:{ id?: string } = { id: null };
      if (!isNil(params.get('id'))) {
        const paramValueString = params.get('id');
        // TODO: date
        inParams.id = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_messages_fail_grid') {
      const title = 'Post fail';
      const component = Dynamics365BusinessCentral_messages_fail_gridComponent;
      const inParams:{ application_name?: string, transaction_id?: string, customerr?: string } = { application_name: null, transaction_id: null, customerr: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('customerr'))) {
        const paramValueString = params.get('customerr');
        // TODO: date
        inParams.customerr = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_messages_success_grid') {
      const title = 'Post success';
      const component = Dynamics365BusinessCentral_messages_success_gridComponent;
      const inParams:{ transaction_id?: string, application_name?: string, customerr?: string } = { transaction_id: null, application_name: null, customerr: null };
      if (!isNil(params.get('transaction_id'))) {
        const paramValueString = params.get('transaction_id');
        // TODO: date
        inParams.transaction_id = paramValueString;
              }
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('customerr'))) {
        const paramValueString = params.get('customerr');
        // TODO: date
        inParams.customerr = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_order_po_queue_grid') {
      this.$logger.log('Dynamics365BusinessCentral', 'order_po_queue_grid');
      const title = 'order_po_queue_grid';
      const component = Dynamics365BusinessCentral_order_po_queue_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_order_po_queue_update_grid') {
      this.$logger.log('Dynamics365BusinessCentral', 'order_po_queue_update_grid');
      const title = 'order_po_queue_update_grid';
      const component = Dynamics365BusinessCentral_order_po_queue_update_gridComponent;
      const inParams:{ orderid?: string } = { orderid: null };
      if (!isNil(params.get('orderid'))) {
        const paramValueString = params.get('orderid');
        // TODO: date
        inParams.orderid = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_Invoice_export_hub') {
      const title = ' Invoice_export_hub';
      const component = Dynamics365BusinessCentral_Invoice_export_hubComponent;
      const inParams:{ Customerr?: string } = { Customerr: null };
      if (!isNil(params.get('Customerr'))) {
        const paramValueString = params.get('Customerr');
        // TODO: date
        inParams.Customerr = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_bc_hub') {
      this.$logger.log('Dynamics365BusinessCentral', 'bc_hub');
      const title = 'BC';
      const component = Dynamics365BusinessCentral_bc_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_date_type_single') {
      this.$logger.log('Dynamics365BusinessCentral', 'date_type_single');
      const title = 'date_type';
      const component = Dynamics365BusinessCentral_date_type_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_enviroment_single') {
      this.$logger.log('Dynamics365BusinessCentral', 'enviroment_single');
      const title = 'enviroment';
      const component = Dynamics365BusinessCentral_enviroment_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_operation_single') {
      this.$logger.log('Dynamics365BusinessCentral', 'operation_single');
      const title = 'operation';
      const component = Dynamics365BusinessCentral_operation_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_date_type_multi') {
      this.$logger.log('Dynamics365BusinessCentral', 'date_type_multi');
      const title = 'date_type';
      const component = Dynamics365BusinessCentral_date_type_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_enviroment_multi') {
      this.$logger.log('Dynamics365BusinessCentral', 'enviroment_multi');
      const title = 'enviroment';
      const component = Dynamics365BusinessCentral_enviroment_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_operation_multi') {
      this.$logger.log('Dynamics365BusinessCentral', 'operation_multi');
      const title = 'operation';
      const component = Dynamics365BusinessCentral_operation_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_count_by_groups_widget') {
      const title = '       Billing status';
      const component = Dynamics365BusinessCentral_count_by_groups_widgetComponent;
      const inParams:{ customerr?: string, application_name?: string, refresh?: boolean } = { customerr: null, application_name: null, refresh: null };
      if (!isNil(params.get('customerr'))) {
        const paramValueString = params.get('customerr');
        // TODO: date
        inParams.customerr = paramValueString;
              }
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('refresh'))) {
        const paramValueString = params.get('refresh');
        inParams.refresh = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_inbound_order_today') {
      this.$logger.log('Dynamics365BusinessCentral', 'inbound_order_today');
      const title = 'Inbound orders today';
      const component = Dynamics365BusinessCentral_inbound_order_todayComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_materials_today') {
      this.$logger.log('Dynamics365BusinessCentral', 'materials_today');
      const title = '     Materials today';
      const component = Dynamics365BusinessCentral_materials_todayComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_number_of_errors_widget') {
      const title = 'Failed';
      const component = Dynamics365BusinessCentral_number_of_errors_widgetComponent;
      const inParams:{ application_name?: string, customerr?: string, refresh_control?: boolean } = { application_name: null, customerr: null, refresh_control: null };
      if (!isNil(params.get('application_name'))) {
        const paramValueString = params.get('application_name');
        // TODO: date
        inParams.application_name = paramValueString;
              }
      if (!isNil(params.get('customerr'))) {
        const paramValueString = params.get('customerr');
        // TODO: date
        inParams.customerr = paramValueString;
              }
      if (!isNil(params.get('refresh_control'))) {
        const paramValueString = params.get('refresh_control');
        inParams.refresh_control = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_orders_fail_count') {
      this.$logger.log('Dynamics365BusinessCentral', 'orders_fail_count');
      const title = 'Orders fail to sync';
      const component = Dynamics365BusinessCentral_orders_fail_countComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_outbound_orders_today') {
      this.$logger.log('Dynamics365BusinessCentral', 'outbound_orders_today');
      const title = 'Outbound orders today';
      const component = Dynamics365BusinessCentral_outbound_orders_todayComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Dynamics365BusinessCentral_card') {
      this.$logger.log('Dynamics365BusinessCentral', 'card');
      const title = 'card';
      const component = Dynamics365BusinessCentral_cardComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
