import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootprintManager_ShellService } from './FootprintManager.shell.service';
import { FootprintManager_OperationService } from './FootprintManager.operation.service';
import { FootprintManager_DatasourceService } from './FootprintManager.datasource.index';
import { FootprintManager_FlowService } from './FootprintManager.flow.index';
import { FootprintManager_ReportService } from './FootprintManager.report.index';
import { FootprintManager_LocalizationService } from './FootprintManager.localization.service';
import { FootprintManager_plans_grid_ComponentContextService } from './FootprintManager.plans_grid.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootprintManager.frontend.types'
import { $frontendTypes as $types} from './FootprintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


interface IFootprintManager_plans_gridComponentEntity {
  plan_id?: string, status?: boolean, name?: string, template_order_id?: number, template_order_lookup_code?: string, finished_product?: string, plan_setup?: string, plan_schedule?: string, next_execution?: string, last_run?: string, last_run_state?: string}



class FootprintManager_plans_gridComponentRowModel extends GridRowModel {
  grid: FootprintManager_plans_gridComponent;
  entity: IFootprintManager_plans_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    next_execution_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    last_run_display: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  override cells = {
    status: null,
    name: null,
    template_order_id: null,
    finished_product: null,
    plan_setup: null,
    plan_schedule: null,
    next_execution: null,
    last_run: null,
    last_run_state: null,
  }



  constructor(
    grid: FootprintManager_plans_gridComponent,
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: FootprintManager_ShellService,
private $datasources: FootprintManager_DatasourceService,
private $flows: FootprintManager_FlowService,
private $reports: FootprintManager_ReportService,
private $localization: FootprintManager_LocalizationService,
private $operations: FootprintManager_OperationService,
private $logger: CleanupLoggerService,
private $context: FootprintManager_plans_grid_ComponentContextService,
) {
    super();

    this.grid = grid;

    this.cells.status = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.status,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.name = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.name,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.template_order_id = new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      this.grid.headers.template_order_id,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.finished_product = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.finished_product,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.plan_setup = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.plan_setup,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.plan_schedule = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.plan_schedule,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    this.cells.next_execution = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.next_execution,
      new DateBoxModel(this.formGroup.controls['next_execution_display'] as DatexFormControl, null, true, false, '', 'datetime', null)
,
null
      );
    
    this.cells.last_run = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.last_run,
      new DateBoxModel(this.formGroup.controls['last_run_display'] as DatexFormControl, null, true, false, '', 'datetime', null)
,
null
      );
    
    this.cells.last_run_state = new GridCellModel(
      new CellStyles(null, null),
      this.grid.headers.last_run_state,
      new TextModel(null, null, null, null, null)
,
null
      );
    
    
  }

  async $initializeExisting(entity: IFootprintManager_plans_gridComponentEntity) {
    this.entity = entity;

    this.rowId = [this.entity.plan_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(entity?: IFootprintManager_plans_gridComponentEntity) {
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.plan_id].join('-');
    await this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    const $resultKey = this.entity.plan_id;
    const inParams = {
      $keys:[$resultKey],
    };
    const data = await this.$datasources.FootprintManager.ds_get_plans.getByKeys(inParams);
    
    await this.grid.$applyLinkedDatasourcesAndCustomColumns(inParams, data);
      
    this.entity = data.result[0] as IFootprintManager_plans_gridComponentEntity;
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.$utils;
    (this.cells.status.displayControl as TextModel).text = $row.entity.status ? "Active" : "Inactive";
    (this.cells.name.displayControl as TextModel).text = $row.entity.name;
    (this.cells.template_order_id.displayControl as TextModel).text = $row.entity.template_order_lookup_code;
    (this.cells.finished_product.displayControl as TextModel).text = $row.entity.finished_product;
    (this.cells.plan_setup.displayControl as TextModel).text = $row.entity.plan_setup;
    (this.cells.plan_schedule.displayControl as TextModel).text = $row.entity.plan_schedule;
    (this.cells.next_execution.displayControl as DateBoxModel).reset($row.entity.next_execution);
    (this.cells.last_run.displayControl as DateBoxModel).reset($row.entity.last_run);
    (this.cells.last_run_state.displayControl as TextModel).text = $row.entity.last_run_state;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'name' : {
        this.open_plan();
        break;
      }
      case 'template_order_id' : {
        this.open_order();
        break;
      }
    }
  }

  //#region private flows
  open_order(event = null) {
    return this.open_orderInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async open_orderInternal(
    $row: FootprintManager_plans_gridComponentRowModel,
  $grid: FootprintManager_plans_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_plans_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'plans_grid.open_order');
  
  $shell.FootprintManager.openmanufacturing_order_editor({
      manufacturing_order_id: $row.entity.template_order_id
  })
  }
  open_plan(event = null) {
    return this.open_planInternal(
      this,
  this.grid, this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async open_planInternal(
    $row: FootprintManager_plans_gridComponentRowModel,
  $grid: FootprintManager_plans_gridComponent, 
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_plans_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'plans_grid.open_plan');
  
  $shell.FootprintManager.openplan_editor({plan_id: $row.entity.plan_id});
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootprintManager-plans_grid',
  templateUrl: './FootprintManager.plans_grid.component.html'
})
export class FootprintManager_plans_gridComponent extends BaseComponent implements OnInit, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootprintManager_plans_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);




  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     status: new GridHeaderModel(new HeaderStyles(null, null), 'status', 'Status', false, false, null, false),       name: new GridHeaderModel(new HeaderStyles(null, null), 'name', 'Plan name', false, false, null, false),       template_order_id: new GridHeaderModel(new HeaderStyles(null, null), 'template_order_id', 'Source order', false, false, null, false),       finished_product: new GridHeaderModel(new HeaderStyles(null, null), 'finished_product', 'Finished product', false, false, null, false),       plan_setup: new GridHeaderModel(new HeaderStyles(null, null), 'plan_setup', 'Plan setup', false, false, null, false),       plan_schedule: new GridHeaderModel(new HeaderStyles(null, null), 'plan_schedule', 'Order schedule', false, false, null, false),       next_execution: new GridHeaderModel(new HeaderStyles(null, null), 'next_execution', 'Next execution', false, false, null, false),       last_run: new GridHeaderModel(new HeaderStyles(null, null), 'last_run', 'Last run', false, false, null, false),       last_run_state: new GridHeaderModel(new HeaderStyles(null, null), 'last_run_state', 'Last run status', false, false, null, false),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootprintManager_plans_gridComponentRowModel[] = [];


  topToolbar = {
      new_plan: new ToolModel(new ButtonModel('new_plan', new ButtonStyles(['creation'], null), false, false, false, 'New plan', 'ms-Icon ms-Icon--Add', null)
    , false)
  };

  bottomToolbar = {
  };




  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: FootprintManager_ShellService,
    private $datasources: FootprintManager_DatasourceService,
    private $flows: FootprintManager_FlowService,
    private $reports: FootprintManager_ReportService,
    private $localization: FootprintManager_LocalizationService,
    private $operations: FootprintManager_OperationService,
    private $logger: CleanupLoggerService,
    private $context: FootprintManager_plans_grid_ComponentContextService,
    ) {
    super();
    this.title = 'Plans';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.$utils;

    this.pageSize = 10;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.$utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
    };
    try {
      const data = await this.$datasources.FootprintManager.ds_get_plans.getList(inParams);
      
      await this.$applyLinkedDatasourcesAndCustomColumns(inParams, data);

      this.entities = data.result as IFootprintManager_plans_gridComponentEntity[];
      this.totalCount = data.totalCount;

      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.$utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootprintManager_plans_gridComponentRowModel(
          this,
          this.$utils,
          this.$settings,
          this.$shell, 
          this.$datasources,
          this.$flows,
          this.$reports,
          this.$localization,
          this.$operations,
          this.$logger,
          this.$context);
        rowLoadPromises.push(row.$initializeExisting(entity));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  
    async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.$utils;
  
    }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }


  
  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  new_plan(event = null) {
    return this.new_planInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async new_planInternal(
    $grid: FootprintManager_plans_gridComponent,
  
    $shell: FootprintManager_ShellService,
    $datasources: FootprintManager_DatasourceService,
    $flows: FootprintManager_FlowService,
    $reports: FootprintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootprintManager_OperationService,
    $utils: UtilsService,
    $context: FootprintManager_plans_grid_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootprintManager_LocalizationService,
    $event: any
  ) {
    this.$logger.log('FootprintManager', 'plans_grid.new_plan');
  
  $shell.FootprintManager.openplan_editorDialog({plan_id: null}, 'modal');
  
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
