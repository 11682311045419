<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div class="query-filter">
      <input
        matInput
        [(ngModel)]="fullTextSearch" 
        (ngModelChange)="reload()" 
        [ngModelOptions]="{updateOn: 'blur'}"
        autocomplete="off"
        class="datex-textbox query-search"
        spellcheck="false"
        placeholder="Filter"
        [matTooltip]="fullTextSearch"
        matTooltipClass="datex-control-tooltip">
    </div>
  </ng-container>


  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="captureDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.captureDate.displayControl.styles.style"
          [ngClass]="row.cells.captureDate.displayControl.styles.classes"
          [matTooltip]="row.cells.captureDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.captureDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="materialId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.materialId.displayControl.styles.style"
          [ngClass]="row.cells.materialId.displayControl.styles.classes"
          [matTooltip]="row.cells.materialId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.materialId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packagingId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packagingId.displayControl.styles.style"
          [ngClass]="row.cells.packagingId.displayControl.styles.classes"
          [matTooltip]="row.cells.packagingId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packagingId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouseId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouseId.displayControl.styles.style"
          [ngClass]="row.cells.warehouseId.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouseId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouseId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="totalPackagedAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.totalPackagedAmount.displayControl.styles.style"
          [ngClass]="row.cells.totalPackagedAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.totalPackagedAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.totalPackagedAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="totalBaseAmount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.totalBaseAmount.displayControl.styles.style"
          [ngClass]="row.cells.totalBaseAmount.displayControl.styles.classes"
          [matTooltip]="row.cells.totalBaseAmount.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.totalBaseAmount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="projectId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.projectId.displayControl.styles.style"
          [ngClass]="row.cells.projectId.displayControl.styles.classes"
          [matTooltip]="row.cells.projectId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.projectId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="materialLookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.materialLookupcode.displayControl.styles.style"
          [ngClass]="row.cells.materialLookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.materialLookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.materialLookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packagingShortName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.packagingShortName.displayControl.styles.style"
          [ngClass]="row.cells.packagingShortName.displayControl.styles.classes"
          [matTooltip]="row.cells.packagingShortName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.packagingShortName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouseName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.warehouseName.displayControl.styles.style"
          [ngClass]="row.cells.warehouseName.displayControl.styles.classes"
          [matTooltip]="row.cells.warehouseName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.warehouseName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="totalNetWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.totalNetWeight.displayControl.styles.style"
          [ngClass]="row.cells.totalNetWeight.displayControl.styles.classes"
          [matTooltip]="row.cells.totalNetWeight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.totalNetWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="totalGrossWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.totalGrossWeight.displayControl.styles.style"
          [ngClass]="row.cells.totalGrossWeight.displayControl.styles.classes"
          [matTooltip]="row.cells.totalGrossWeight.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.totalGrossWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weightShortName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weightShortName.displayControl.styles.style"
          [ngClass]="row.cells.weightShortName.displayControl.styles.classes"
          [matTooltip]="row.cells.weightShortName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weightShortName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weightUomId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.weightUomId.displayControl.styles.style"
          [ngClass]="row.cells.weightUomId.displayControl.styles.classes"
          [matTooltip]="row.cells.weightUomId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.weightUomId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lotId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lotId.displayControl.styles.style"
          [ngClass]="row.cells.lotId.displayControl.styles.classes"
          [matTooltip]="row.cells.lotId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lotId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lotLookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lotLookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lotLookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.lotLookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lotLookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplateId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplateId.displayControl.styles.style"
          [ngClass]="row.cells.licenseplateId.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplateId.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplateId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplateLookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.licenseplateLookupcode.displayControl.styles.style"
          [ngClass]="row.cells.licenseplateLookupcode.displayControl.styles.classes"
          [matTooltip]="row.cells.licenseplateLookupcode.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.licenseplateLookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serialNumbers">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.serialNumbers.displayControl.styles.style"
          [ngClass]="row.cells.serialNumbers.displayControl.styles.classes"
          [matTooltip]="row.cells.serialNumbers.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.serialNumbers.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="receiveDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.receiveDate.displayControl.styles.style"
          [ngClass]="row.cells.receiveDate.displayControl.styles.classes"
          [matTooltip]="row.cells.receiveDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.receiveDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lastReceiveDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.lastReceiveDate.displayControl.styles.style"
          [ngClass]="row.cells.lastReceiveDate.displayControl.styles.classes"
          [matTooltip]="row.cells.lastReceiveDate.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.lastReceiveDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="locationName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.locationName.displayControl.styles.style"
          [ngClass]="row.cells.locationName.displayControl.styles.classes"
          [matTooltip]="row.cells.locationName.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.locationName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
