import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './PalletTransactions.frontend.types'

@Injectable({ providedIn: 'root' })
export class PalletTransactions_is_pallet_class_name_validService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { name?: string, id?: number }): Promise<{ valid?: boolean, exceptions?: string }> 
  {
    let url = `${environment.backendUrl}api/PalletTransactions/functions/is_pallet_class_name_valid`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

