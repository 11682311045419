<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template 
              toolbarToolDef="confirm" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="cancel" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="separator1" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <div class="tool-separator"></div>
              </ng-container>
            </ng-template>
            <ng-template 
              toolbarToolDef="delete_all_configs" let-tool>
              <ng-container  *ngIf="tool.hasButtonsToShow">
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly || tool.control.disabled"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="delete_all_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
              </ng-container>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-azure_blob_storage"
                     *ngIf="!fieldsets.azure_blob_storage.hidden && !fieldsets.azure_blob_storage.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.azure_blob_storage.collapsible }">
                    <div *ngIf="!fieldsets.azure_blob_storage.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.azure_blob_storage.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.azure_blob_storage.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.azure_blob_storage.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.azure_blob_storage.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.azure_blob_storage.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.azure_blob_storage.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-azure_blob_connection_string" *ngIf="!fields.azure_blob_connection_string.hidden && !fields.azure_blob_connection_string.removed" 
                            class="field-container full {{fields.azure_blob_connection_string.invalid ? 'invalid' : ''}} {{fields.azure_blob_connection_string.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.azure_blob_connection_string.styles.style"
                            [ngClass]="fields.azure_blob_connection_string.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.azure_blob_connection_string.label + (fields.azure_blob_connection_string.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.azure_blob_connection_string.label}}<span *ngIf="fields.azure_blob_connection_string.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="azure_blob_connection_string"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.azure_blob_connection_string.control.readOnly || fields.azure_blob_connection_string.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.azure_blob_connection_string.control.placeholder}}"
                                [ngStyle]="fields.azure_blob_connection_string.control.styles.style"
                                [ngClass]="fields.azure_blob_connection_string.control.styles.classes"
                                [matTooltip]="fields.azure_blob_connection_string.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.azure_blob_connection_string.invalid">
                          <ng-container *ngFor="let error of fields.azure_blob_connection_string.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-azure_blob_sas_token" *ngIf="!fields.azure_blob_sas_token.hidden && !fields.azure_blob_sas_token.removed" 
                            class="field-container full {{fields.azure_blob_sas_token.invalid ? 'invalid' : ''}} {{fields.azure_blob_sas_token.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.azure_blob_sas_token.styles.style"
                            [ngClass]="fields.azure_blob_sas_token.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.azure_blob_sas_token.label + (fields.azure_blob_sas_token.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.azure_blob_sas_token.label}}<span *ngIf="fields.azure_blob_sas_token.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="azure_blob_sas_token"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.azure_blob_sas_token.control.readOnly || fields.azure_blob_sas_token.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.azure_blob_sas_token.control.placeholder}}"
                                [ngStyle]="fields.azure_blob_sas_token.control.styles.style"
                                [ngClass]="fields.azure_blob_sas_token.control.styles.classes"
                                [matTooltip]="fields.azure_blob_sas_token.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.azure_blob_sas_token.invalid">
                          <ng-container *ngFor="let error of fields.azure_blob_sas_token.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-azure_blob_root_path" *ngIf="!fields.azure_blob_root_path.hidden && !fields.azure_blob_root_path.removed" 
                            class="field-container double {{fields.azure_blob_root_path.invalid ? 'invalid' : ''}} {{fields.azure_blob_root_path.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.azure_blob_root_path.styles.style"
                            [ngClass]="fields.azure_blob_root_path.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.azure_blob_root_path.label + (fields.azure_blob_root_path.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.azure_blob_root_path.label}}<span *ngIf="fields.azure_blob_root_path.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="azure_blob_root_path"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{ (fields.azure_blob_root_path.control.readOnly || fields.azure_blob_root_path.control.disabled) ? 'readonly ' : ''}}"
                                placeholder="{{fields.azure_blob_root_path.control.placeholder}}"
                                [ngStyle]="fields.azure_blob_root_path.control.styles.style"
                                [ngClass]="fields.azure_blob_root_path.control.styles.classes"
                                [matTooltip]="fields.azure_blob_root_path.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.azure_blob_root_path.invalid">
                          <ng-container *ngFor="let error of fields.azure_blob_root_path.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.azure_blob_storage.expanded">
        
        
                    </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-printers"
                     *ngIf="!fieldsets.printers.hidden && !fieldsets.printers.removed"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.printers.collapsible }">
                    <div *ngIf="!fieldsets.printers.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.printers.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.printers.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.printers.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.printers.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.printers.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.printers.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-delete_selected_printers" *ngIf="!fields.delete_selected_printers.hidden && !fields.delete_selected_printers.removed" 
                            class="field-container standard {{fields.delete_selected_printers.invalid ? 'invalid' : ''}} {{fields.delete_selected_printers.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.delete_selected_printers.styles.style"
                            [ngClass]="fields.delete_selected_printers.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.delete_selected_printers.label + (fields.delete_selected_printers.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.delete_selected_printers.label}}<span *ngIf="fields.delete_selected_printers.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.delete_selected_printers.control.readOnly || fields.delete_selected_printers.control.disabled"
                                [ngStyle]="fields.delete_selected_printers.control.styles.style"
                                [ngClass]="fields.delete_selected_printers.control.styles.classes"
                                [matTooltip]="fields.delete_selected_printers.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_delete_selected_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.delete_selected_printers.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.delete_selected_printers.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.delete_selected_printers.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.delete_selected_printers.invalid">
                          <ng-container *ngFor="let error of fields.delete_selected_printers.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-printers" *ngIf="!fields.printers.hidden && !fields.printers.removed" 
                            class="field-container full {{fields.printers.invalid ? 'invalid' : ''}} {{fields.printers.control.disabled ? 'field-container-disabled' : ''}}"
                            [ngStyle]="fields.printers.styles.style"
                            [ngClass]="fields.printers.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.printers.label + (fields.printers.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.printers.label}}<span *ngIf="fields.printers.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <BarTender-reflect_strings_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.printers.control.type"
                            formControlName="printers"
                            (displayTextChange)="fields.printers.control.displayText=$event"
                            [placeholder]="fields.printers.control.placeholder"
                            [styles]="fields.printers.control.styles"
                            [tooltip]="fields.printers.control.tooltip"
                          [values]="$fields_printers_selector_inParams_values"
                        >
                        </BarTender-reflect_strings_dd_multi>
                        <ng-container *ngIf="fields.printers.invalid">
                          <ng-container *ngFor="let error of fields.printers.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
        
                    <div class="fieldset-details" *ngIf="!fieldsets.printers.expanded">
        
        
                    </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>