import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Manufacturing_ShellService } from './Manufacturing.shell.service';
import { Manufacturing_OperationService } from './Manufacturing.operation.service';
import { Manufacturing_DatasourceService } from './Manufacturing.datasource.index';
import { Manufacturing_FlowService } from './Manufacturing.flow.index';
import { Manufacturing_ReportService } from './Manufacturing.report.index';
import { Manufacturing_LocalizationService } from './Manufacturing.localization.service';
import { Manufacturing_workshift_form_ComponentContextService } from './Manufacturing.workshift_form.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Manufacturing.frontend.types'
import { $frontendTypes as $types} from './Manufacturing.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';
import { Manufacturing_warehouse_dd_singleComponent } from './Manufacturing.warehouse_dd_single.component'
import { Manufacturing_time_selector_dd_singleComponent } from './Manufacturing.time_selector_dd_single.component'
import { Manufacturing_days_dd_multiComponent } from './Manufacturing.days_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Manufacturing_warehouse_dd_singleComponent),
    forwardRef(() => Manufacturing_time_selector_dd_singleComponent),
    forwardRef(() => Manufacturing_days_dd_multiComponent),
  ],
  selector: 'Manufacturing-workshift_form',
  templateUrl: './Manufacturing.workshift_form.component.html'
})
export class Manufacturing_workshift_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouse_id: number } = { warehouse_id: null };
  //#region Inputs
  @Input('warehouse_id') set $inParams_warehouse_id(v: number) {
    this.inParams.warehouse_id = v;
  }
  get $inParams_warehouse_id(): number {
    return this.inParams.warehouse_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    workshift_name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    warehouse: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    start_time: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    end_time: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    shift_duration: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    days: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      create: new ToolModel(new ButtonModel('create', new ButtonStyles(['primary'], null), true, false, false, 'Create', '', null)
    , false),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, false, false, 'Cancel', '', null)
    , false)
  };

  fields = {
    workshift_name: new FieldModel(new TextBoxModel(this.formGroup.controls['workshift_name'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Workshift name', true, false),
    warehouse: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  true,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Warehouse', false, false),
    start_time: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['start_time'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Start time', true, false),
    end_time: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['end_time'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'End time', true, false),
    shift_duration: new FieldModel(new TextBoxModel(this.formGroup.controls['shift_duration'] as DatexFormControl, null, true, false, '', null)
, new ControlContainerStyles(null, null), 'Shift duration (in hours)', false, false),
    code: new FieldModel(new TextBoxModel(this.formGroup.controls['code'] as DatexFormControl, null, false, false, '', null)
, new ControlContainerStyles(null, null), 'Code', true, false),
    days: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['days'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false,
  false,
  '', null)
, new ControlContainerStyles(null, null), 'Days', true, false),
  };

  fieldsets = {
    group: new FieldsetModel(
      '',
      true,
      false,
      true,
      false,
        null
      ,
      () => {
        const $form = this;
        const $utils = this.$utils;
        return '';
      }
    ),
};

  //#region fields inParams
  get $fields_warehouse_selector_inParams_warehouse_id(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = $form.inParams.warehouse_id;
    
    return expr;
  }

  get $fields_start_time_selector_inParams_intervalMinutes(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 5;
    
    return expr;
  }

  get $fields_end_time_selector_inParams_intervalMinutes(): number {
    const $form = this;
    const $utils = this.$utils;
    const expr = 5;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    workshift_name: this.fields.workshift_name.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    warehouse: this.fields.warehouse.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    start_time: this.fields.start_time.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    end_time: this.fields.end_time.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    shift_duration: this.fields.shift_duration.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    code: this.fields.code.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
    days: this.fields.days.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate(data);
  }
  ))),
  }
  

  constructor(
    private $utils: UtilsService,
private $settings: SettingsValuesService,
private $shell: Manufacturing_ShellService,
private $datasources: Manufacturing_DatasourceService,
private $flows: Manufacturing_FlowService,
private $reports: Manufacturing_ReportService,
private $localization: Manufacturing_LocalizationService,
private $operations: Manufacturing_OperationService,
private $logger: CleanupLoggerService,
private $context: Manufacturing_workshift_form_ComponentContextService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouse_id)) {
        this.$missingRequiredInParams.push('warehouse_id');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'workshift_form';
  
    const $form = this;
    const $utils = this.$utils;

    
    (this.fields.warehouse.control as SelectBoxModel).reset($form.inParams.warehouse_id);
    
    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .workshift_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .warehouse
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .start_time
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.change_time_flow();
      });
    this.$formGroupFieldValidationObservables
      .end_time
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.change_time_flow();
      });
    this.$formGroupFieldValidationObservables
      .shift_duration
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .days
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.$shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  change_time_flow(event = null) {
    return this.change_time_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async change_time_flowInternal(
    $form: Manufacturing_workshift_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_workshift_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'workshift_form.change_time_flow');
  
  const start_time = $form.fields.start_time.control.value;
  const end_time = $form.fields.end_time.control.value;
  
  if (start_time && end_time) {
      const hours = (await $flows.Manufacturing.minutes_between_times({start_time, end_time})).minutes / 60;
      $form.fields.shift_duration.control.value = (await $flows.Manufacturing.round_to_first_decimal_digit({num: hours})).roundedNum;
  }
  
  }
  cancel_flow(event = null) {
    return this.cancel_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async cancel_flowInternal(
    $form: Manufacturing_workshift_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_workshift_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'workshift_form.cancel_flow');
  
  $form.close();
  }
  create_workshift_flow(event = null) {
    return this.create_workshift_flowInternal(
      this,
  this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      this.$context,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async create_workshift_flowInternal(
    $form: Manufacturing_workshift_formComponent,
  
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    $context: Manufacturing_workshift_form_ComponentContextService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $event: any
  ) {
    this.$logger.log('Manufacturing', 'workshift_form.create_workshift_flow');
  
  const shiftStartOffsetMinutes = (await $flows.Manufacturing.get_total_minutes({ time: $form.fields.start_time.control.value })).totalMinutes;
  let endTotalMinutes = (await $flows.Manufacturing.get_total_minutes({ time: $form.fields.end_time.control.value })).totalMinutes;
  if (endTotalMinutes < shiftStartOffsetMinutes) {
      endTotalMinutes += 24*60;
  }
  const diffInMinutes = endTotalMinutes - shiftStartOffsetMinutes;
  const days = $form.fields.days.control.value ?? [];
  const payload = {
      Name: $form.fields.workshift_name.control.value,
      Code: $form.fields.code.control.value,
      WarehouseId: $form.inParams.warehouse_id,
      ShiftStartOffsetMinutes: shiftStartOffsetMinutes,
      DurationMinutes: diffInMinutes,
      Monday: days.includes(1),
      Tuesday: days.includes(2),
      Wednesday: days.includes(3),
      Thursday: days.includes(4),
      Friday: days.includes(5),
      Saturday: days.includes(6),
      Sunday: days.includes(7),
  }
  
  var result = await $flows.Utilities.crud_create_flow({
      entitySet: 'WorkShifts',
      entity: payload
  });
  
  if (!result.reason) {
      $form.close();
  } else {
      $shell.Utilities.openErrorDialog("Create workShift", result.reason);
  }
  }
  //#endregion private flows
  //#region validation flows
  
  validate(fieldErrors: { workshift_name: Pick<string[], 'push'>,warehouse: Pick<string[], 'push'>,start_time: Pick<string[], 'push'>,end_time: Pick<string[], 'push'>,shift_duration: Pick<string[], 'push'>,code: Pick<string[], 'push'>,days: Pick<string[], 'push'>, } = null) {
    const emptyResult = { workshift_name:[],warehouse:[],start_time:[],end_time:[],shift_duration:[],code:[],days:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validateInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.$shell,
      this.$datasources,
      this.$flows,
      this.$reports,
      this.$settings,
      this.$operations,
      this.$utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      this.$context
      );
  }
  async validateInternal(
    $form: Manufacturing_workshift_formComponent,
    $validation:{
      fieldErrors: { workshift_name: Pick<string[], 'push'>,warehouse: Pick<string[], 'push'>,start_time: Pick<string[], 'push'>,end_time: Pick<string[], 'push'>,shift_duration: Pick<string[], 'push'>,code: Pick<string[], 'push'>,days: Pick<string[], 'push'>, }
    },
    $shell: Manufacturing_ShellService,
    $datasources: Manufacturing_DatasourceService,
    $flows: Manufacturing_FlowService,
    $reports: Manufacturing_ReportService,
    $settings: SettingsValuesService,
    $operations: Manufacturing_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Manufacturing_LocalizationService,
    $context: Manufacturing_workshift_form_ComponentContextService
  ) {
    this.$logger.log('Manufacturing', 'workshift_form.validate');
    
  let isValid = true;
  
  const daysAreSelected = $form.fields.days.control.value?.length;
  if (!daysAreSelected) {
      $validation.fieldErrors.days.push(`Select at least one day of the week`);
      isValid = false;
  }
  
  if (!$form.fields.workshift_name.control.value
  || !$form.fields.start_time.control.value
  || !$form.fields.end_time.control.value
  || !$form.fields.code.control.value) {
      isValid = false;
  }
  
  if ($form.fields.code.control.value.length > 15) {
      $validation.fieldErrors.code.push(`Code length must be less than 16 characters`);
      isValid = false;
  }
  
  $form.toolbar.create.control.readOnly = !isValid;
  
    return $validation.fieldErrors as { workshift_name:[],warehouse:[],start_time:[],end_time:[],shift_duration:[],code:[],days:[], };
  }
  //#endregion validation flows
}
