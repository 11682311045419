import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './ExcelOrderImport.frontend.types'

@Injectable({ providedIn: 'root' })
export class ExcelOrderImport_set_asn_receiving_task_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { licenseplateId: number, lotId: number, packagingId: number, orderId: number, vendorlotId: number, packagedAmount: number, serialNumbersToCreate?: { DimensionUOM?: number, GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number, LicensePlateId?: number, LotId?: number, PackagingId?: number, MaterialId?: number }[], serialNumberIdsToDelete?: number[], materialId: number, existingTaskId?: number }): Promise<{ taskId?: number, orderlineNumber?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateId)) {
      missingRequiredInParams.push('\'licenseplateId\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.vendorlotId)) {
      missingRequiredInParams.push('\'vendorlotId\'');
    }
    if (isNil(inParams.packagedAmount)) {
      missingRequiredInParams.push('\'packagedAmount\'');
    }
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelOrderImport/functions/set_asn_receiving_task_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

