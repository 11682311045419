import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './Catalogs.frontend.types'

@Injectable({ providedIn: 'root' })
export class Catalogs_copy_source_material_flowService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { materialId?: number, targetProjectId?: number }): Promise<{ nextMaterialId?: number, reason?: string }> 
  {
    let url = `${environment.backendUrl}api/Catalogs/functions/copy_source_material_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

}

