import { IEnvironmentInfo, IComponentInfo, IPackageInfo, IApplicationInfo, ComponentType } from './app-context.service';
import { Materials_ModuleContextService } from './Materials.context.service';

import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class Materials_materials_library_home_ComponentContextService {
  private readonly moduleContext: Materials_ModuleContextService;

  constructor(moduleContext: Materials_ModuleContextService) {
    this.moduleContext = moduleContext;
  }

  public get app(): IApplicationInfo {
    return this.moduleContext.app;
  }

  public get env(): IEnvironmentInfo {
    return this.moduleContext.env;
  }

  public get package(): IPackageInfo {
    return this.moduleContext.package;
  }

  public get component(): IComponentInfo {
    return {
      name: 'materials_library_home',
      type: ComponentType.form
    }
  }
}