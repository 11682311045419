import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Dynamics365BusinessCentral_ds_flow_order_lines_updateService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { orderid?: string, sequence?: string, operation?: string, $top?: number, $skip?: number }): Promise<{ result?: { Id?: string, documentId?: string, sequence?: string, itemId?: string, accountId?: string, lineType?: string, lineObjectNumber?: string, description?: string, description2?: string, unitOfMeasureId?: string, unitOfMeasureCode?: string, quantity?: string, directUnitCost?: string, discountAmount?: string, discountPercent?: string, discountAppliedBeforeTax?: string, amountExcludingTax?: string, taxCode?: string, taxPercent?: string, totalTaxAmount?: string, amountIncludingTax?: string, invoiceDiscountAllocation?: string, netAmount?: string, netTaxAmount?: string, netAmountIncludingTax?: string, expectedReceiptDate?: string, receivedQuantity?: string, invoicedQuantity?: string, invoiceQuantity?: string, receiveQuantity?: string, itemVariantId?: string, locationId?: string, insert_date?: string }[], totalCount?: number }> {
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_flow_order_lines_update/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderid?: string, sequence?: string, operation?: string, $keys: string[] }): Promise<{ result?: { Id?: string, documentId?: string, sequence?: string, itemId?: string, accountId?: string, lineType?: string, lineObjectNumber?: string, description?: string, description2?: string, unitOfMeasureId?: string, unitOfMeasureCode?: string, quantity?: string, directUnitCost?: string, discountAmount?: string, discountPercent?: string, discountAppliedBeforeTax?: string, amountExcludingTax?: string, taxCode?: string, taxPercent?: string, totalTaxAmount?: string, amountIncludingTax?: string, invoiceDiscountAllocation?: string, netAmount?: string, netTaxAmount?: string, netAmountIncludingTax?: string, expectedReceiptDate?: string, receivedQuantity?: string, invoicedQuantity?: string, invoiceQuantity?: string, receiveQuantity?: string, itemVariantId?: string, locationId?: string, insert_date?: string }[] }> {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Dynamics365BusinessCentral/datasources/ds_flow_order_lines_update/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }

    
    hasMissingRequiredInParams(inParams: { 
      orderid?: string, sequence?: string, operation?: string    }) {
      return false;
    }
}
