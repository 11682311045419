import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_ds_container_types_assignment_by_ownerService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId: number }): 
  Promise<{ result: { Id?: number, ContainerTypeId?: number, Enabled?: boolean, OwnerId?: number, ProjectId?: number, ContainerType?: { Id?: number, Active?: boolean, CatchAll?: boolean, Description?: string, Height?: number, InnerHeight?: number, InnerLength?: number, InnerWidth?: number, Length?: number, Name?: string, VolumeCapacity?: number, Weight?: number, WeightCapacity?: number, Width?: number, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, DimensionUom?: { Short_name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerId)) {
      missingRequiredInParams.push('\'ownerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_container_types_assignment_by_owner/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ContainerTypeId?: number, Enabled?: boolean, OwnerId?: number, ProjectId?: number, ContainerType?: { Id?: number, Active?: boolean, CatchAll?: boolean, Description?: string, Height?: number, InnerHeight?: number, InnerLength?: number, InnerWidth?: number, Length?: number, Name?: string, VolumeCapacity?: number, Weight?: number, WeightCapacity?: number, Width?: number, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, DimensionUom?: { Short_name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerId)) {
      missingRequiredInParams.push('\'ownerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_container_types_assignment_by_owner/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ContainerTypeId?: number, Enabled?: boolean, OwnerId?: number, ProjectId?: number, ContainerType?: { Id?: number, Active?: boolean, CatchAll?: boolean, Description?: string, Height?: number, InnerHeight?: number, InnerLength?: number, InnerWidth?: number, Length?: number, Name?: string, VolumeCapacity?: number, Weight?: number, WeightCapacity?: number, Width?: number, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, DimensionUom?: { Short_name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.ownerId)) {
      missingRequiredInParams.push('\'ownerId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_container_types_assignment_by_owner/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      ownerId: number    }) {
        if(isNil(inParams.ownerId)) {
          return true; 
        }
      return false;
    }
}
