import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class InventoryCounts_ds_inventory_counts_gridService {

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, onlyCountsWithDiscrepancies?: boolean }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, LookupCode?: string, Status?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Discrepancies?: { Id?: number }[] }[] }> 
  {
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_inventory_counts_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, onlyCountsWithDiscrepancies?: boolean, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, LookupCode?: string, Status?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Discrepancies?: { Id?: number }[] }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_inventory_counts_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, onlyCountsWithDiscrepancies?: boolean, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Description?: string, LookupCode?: string, Status?: { Id?: number, Name?: string }, Warehouse?: { Name?: string }, Discrepancies?: { Id?: number }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryCounts/datasources/ds_inventory_counts_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.$utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      warehouseIds?: number[], statusIds?: number[], fullTextSearch?: string, onlyCountsWithDiscrepancies?: boolean    }) {
      return false;
    }
}
