import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { ExcelOrderImport_ShellService } from './ExcelOrderImport.shell.service';
import { ExcelOrderImport_OperationService } from './ExcelOrderImport.operation.service';
import { ExcelOrderImport_DatasourceService } from './ExcelOrderImport.datasource.index';
import { ExcelOrderImport_FlowService } from './ExcelOrderImport.flow.index';
import { ExcelOrderImport_ReportService } from './ExcelOrderImport.report.index';
import { ExcelOrderImport_LocalizationService } from './ExcelOrderImport.localization.service';
import { ExcelOrderImport_materials_by_project_dd_ComponentContextService } from './ExcelOrderImport.materials_by_project_dd.component.context.service';
import { Language } from './localization.service';
import { JobStatus } from './common-interfaces'
import { ApplicationType, ComponentType } from './app-context.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './ExcelOrderImport.frontend.types'
import { $frontendTypes as $types} from './ExcelOrderImport.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'ExcelOrderImport-materials_by_project_dd_multi',
  templateUrl: './ExcelOrderImport.materials_by_project_dd_multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExcelOrderImport_materials_by_project_dd_multiComponent),
      multi: true
    }
  ]
})
export class ExcelOrderImport_materials_by_project_dd_multiComponent extends BaseComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      || this.$hasMissingRequiredInParams
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;
  @Input() tooltip = '';

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;

  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return true;
  }

  get isSingle() {
    return false;
  }


  inParams: { projectId: number } = { projectId: null };
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }

  fullTextSearch: string;

  constructor(
    private $utils: UtilsService,
    private $settings: SettingsValuesService,
    private $shell: ExcelOrderImport_ShellService,
    private $datasources: ExcelOrderImport_DatasourceService,
    private $flows: ExcelOrderImport_FlowService,
    private $reports: ExcelOrderImport_ReportService,
    private $localization: ExcelOrderImport_LocalizationService,
    private $operations: ExcelOrderImport_OperationService,
    private $logger: CleanupLoggerService,
    private $context: ExcelOrderImport_materials_by_project_dd_ComponentContextService,
    private _elementRef: ElementRef
    ) {
      super();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
  }

  async $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): number | number[] {
    return this._innerValue;
  }

  set value(val:  number | number[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  displayWithFn(value: any): Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}> {
    const promise = new Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}>((resolve, reject) => {

      const $selector = this;
      const $utils = this.$utils;

      this.fullTextSearch = null;
      const $fullTextSearch = null;
      this.$datasources.ExcelOrderImport.ds_materials_by_project_dd.getByKeys({
        $keys: value,
        projectId:  $selector.inParams.projectId ,
        fullTextSearch:  $selector.fullTextSearch ,
      }).then(result => {
        const selected = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          const e: { Id?: number, LookupCode?: string } = found 
            ?? { 
              Id : k
            };

          const $option = { entity: e };
          const $entity = e;
          const $resultOption = { key: null, name: found ? $option.entity.LookupCode : 'No data to display', disabled: false, selected:true };
          $resultOption.key = $option.entity.Id;
          return $resultOption;
        });
        const displayText = value.map(k => {
          const found = result.result
            .find(i => i.Id === k);
          if (found) {
            const $option = { entity: found };
            const $entity = found;
            return $option.entity.LookupCode;
          } else {
            return 'No data to display';
          }
        })
        .join(
           ', '
        );
        this.displayTextChange.emit(displayText);
        resolve({displayText, selected});
      });
    });
    return promise;
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {

    const promise = new Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>((resolve, reject) => {

      const $selector = this;
      const $utils = this.$utils;

      this.fullTextSearch = filterText;
      const $fullTextSearch = filterText;
      this.$datasources.ExcelOrderImport.ds_materials_by_project_dd.getList({
        $top: 15,
        $skip: 0,
        projectId:  $selector.inParams.projectId ,
        fullTextSearch:  $selector.fullTextSearch ,
      }).then(result => {
        resolve({
          list: result.result.map(item => {
            const $option = { entity: item };
            const $entity = item;
            const $resultOption = { key: null, name: $option.entity.LookupCode, disabled: null };
            $resultOption.key = $option.entity.Id;
            return $resultOption;
          }),
          totalCount: result.totalCount,
          top: 15
        });
      });

    });

    return promise;

  }

}
