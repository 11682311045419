<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
      <app-toolbar [toolbar]="topToolbar">
          <ng-template 
            toolbarToolDef="elapsed" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
            >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="separator1" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <div class="tool-separator"></div>
            </ng-container>
          </ng-template>
          <ng-template 
            toolbarToolDef="refresh_grid" let-tool>
            <ng-container  *ngIf="tool.hasButtonsToShow">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="tool.control.readOnly || tool.control.disabled"
                    [ngStyle]="tool.control.styles.style"
                    [ngClass]="tool.control.styles.classes"
                    [matTooltip]="tool.control.tooltip"
                    matTooltipClass="datex-control-tooltip"
                    (click)="on_refresh($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
              </div>
            
            </button>
            </ng-container>
          </ng-template>
      </app-toolbar>
  </ng-container>


  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes"
          [matTooltip]="row.cells.id.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="test_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.test_name.displayControl.styles.style"
          [ngClass]="row.cells.test_name.displayControl.styles.classes"
          [matTooltip]="row.cells.test_name.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.test_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="records_available">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.records_available.displayControl.styles.style"
          [ngClass]="row.cells.records_available.displayControl.styles.classes"
          [matTooltip]="row.cells.records_available.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.records_available.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="records_returned">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.records_returned.displayControl.styles.style"
          [ngClass]="row.cells.records_returned.displayControl.styles.classes"
          [matTooltip]="row.cells.records_returned.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.records_returned.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="elapsed_seconds">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.elapsed_seconds.displayControl.styles.style"
          [ngClass]="row.cells.elapsed_seconds.displayControl.styles.classes"
          [matTooltip]="row.cells.elapsed_seconds.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.elapsed_seconds.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="seconds_per_record">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.seconds_per_record.displayControl.styles.style"
          [ngClass]="row.cells.seconds_per_record.displayControl.styles.classes"
          [matTooltip]="row.cells.seconds_per_record.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.seconds_per_record.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text" 
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes"
          [matTooltip]="row.cells.notes.displayControl.tooltip"
          matTooltipClass="datex-control-tooltip">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
